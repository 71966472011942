export enum ClientSessionFields {
  APPS_FLYER_ID = "appsflyerId",
  BROWSER = "browser",
  BROWSER_VERSION = "browserVersion",
  DEVICE_CATEGORY = "deviceCategory",
  DEVICE_COUNTRY = "deviceCountry",
  DEVICE_ID = "device_id",
  DEVICE_LANGUAGE = "deviceLanguage",
  DEVICE_MANUFACTURER = "deviceManufacturer",
  DEVICE_MODEL = "deviceModel",
  PLATFORM = "platform",
  TANGO_VERSION = "tangoVersion",
}

export enum StorageKeys {
  SESSION_START_DATE = "session_start_date",
}

export enum EventNames {
  CLIENT_SESSION = "client-session",
}
