/**
 * combineReducers doesn't allow to access common state
 * this one passes common state as a third parameter to reducers
 * context is always full _previous_ state and is undefined when state is undefined
 **/
import invariant from "fbjs/lib/invariant";

export default (reducers) => {
  const flatReducers = Object.keys(reducers).map((key) => ({
    key,
    fun: reducers[key],
  }));
  return (state, action) =>
    flatReducers.reduce((a, { key, fun }) => {
      const stateSlice = fun(state ? state[key] : undefined, action, state);
      invariant(
        stateSlice !== undefined,
        `slice reducer ${key} must return some existing state!`
      );
      a[key] = stateSlice;
      return a;
    }, {});
};
