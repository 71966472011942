import { useCallback, useMemo } from "react";
import { useBodyScrollLockEnabled } from "ui/hooks/useBodyScrollLock";

export const MOBILE_ROUTER_ROOT_ID = "mobile-router-root";

const useWindowScroll = () => {
  const isBodyScrollEnabled = useBodyScrollLockEnabled();
  const mobileRouterRef = document.getElementById(MOBILE_ROUTER_ROOT_ID);

  const scrollToTop = useCallback(() => {
    if (mobileRouterRef && isBodyScrollEnabled) {
      mobileRouterRef.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [mobileRouterRef, isBodyScrollEnabled]);

  const scrollTo = useCallback(
    (options: ScrollToOptions) => {
      if (mobileRouterRef && isBodyScrollEnabled) {
        mobileRouterRef.scrollTo(options);
      } else {
        window.scrollTo(options);
      }
    },
    [mobileRouterRef, isBodyScrollEnabled]
  );

  const getScrollY = useCallback(() => {
    if (mobileRouterRef && isBodyScrollEnabled) {
      return mobileRouterRef.scrollTop;
    }

    return window.scrollY;
  }, [mobileRouterRef, isBodyScrollEnabled]);

  const rootScrollableContainer = useMemo(
    () => (isBodyScrollEnabled && mobileRouterRef ? mobileRouterRef : window),
    [mobileRouterRef, isBodyScrollEnabled]
  );

  return {
    scrollToTop,
    scrollTo,
    getScrollY,
    rootScrollableContainer,
  };
};

export default useWindowScroll;
