import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadServerOwnedConfig } from "state/flows/loadConfiguration";
import { deviceInfoSelectors, loginSelectors } from "state/selectors";

const useLoadServerOwnedConfig = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const isAuthorized = useSelector(loginSelectors.isAuthorized);
  const locale = useSelector(deviceInfoSelectors.getDeviceLocale);

  useEffect(() => {
    dispatch(loadServerOwnedConfig(locale));
  }, [dispatch, isLoggedIn, isAuthorized, locale]);
};

export default useLoadServerOwnedConfig;
