export enum TikTokEventNames {
  COMPLETE_PAYMENT = "CompletePayment",
  COMPLETE_REGISTRATION = "CompleteRegistration",
}

export enum TikTokEventFields {
  CONTENT_ID = "content_id",
  CONTENT_TYPE = "content_type",
  CURRENCY = "currency",
  EMAIL = "email",
  EVENT_ID = "event_id",
  PHONE_NUMBER = "phone_number",
  VALUE = "value",
}

export const TOK_TOK_CONTENT_TYPE = "product";
