export enum AxonMethods {
  INIT = "init",
  TRACK = "track",
}

export enum AxonEventNames {
  COMPLETED_LOGIN = "login",
  COMPLETED_REGISTRATION = "sign_up",
  PAGE_VIEW = "page_view",
  PURCHASE = "purchase",
}

export enum AxonEventFields {
  CURRENCY = "currency",
  ITEM_ID = "item_id",
  ITEM_NAME = "item_name",
  ITEMS = "items",
  METHOD = "method",
  PRICE = "price",
  QUANTITY = "quantity",
  TRANSACTION_ID = "transaction_id",
  USER_ID = "user_id",
  VALUE = "value",
}

export const AXON_OFFER_QUANTITY = 1;
