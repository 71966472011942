import { DARK_THEME, IS_NEW_USER_STORAGE_KEY } from "src/constants";
import { ThemeType } from "src/enums";

const THEME_MODE_QUERY_KEY = "themeMode";

export const initializeLocalStorage = () => {
  if (!localStorage.length) {
    const urlThemeMode = new URLSearchParams(window.location.search).get(
      THEME_MODE_QUERY_KEY
    );
    const isUrlDarkMode = urlThemeMode === ThemeType.DARK;
    const isMediaDarkMode = window.matchMedia?.(
      "(prefers-color-scheme: dark)"
    ).matches;

    localStorage.setItem(
      DARK_THEME,
      String(urlThemeMode ? isUrlDarkMode : isMediaDarkMode)
    );

    localStorage.setItem(IS_NEW_USER_STORAGE_KEY, "true");
  } else {
    localStorage.setItem(IS_NEW_USER_STORAGE_KEY, "false");
  }
};
