import React, { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import IdentityValidationMessageHeader from "chat/components/common/IdentityValidationMessageHeader";
import { messages } from "chat/components/common/messages";
import { Button, Typography } from "chat/imports/components";
import {
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { StoredMessage } from "chat/state/reducer";
import styles from "./IdentityValidationMessage.scss";

interface IdentityValidationMessageProps {
  message: StoredMessage;
}

const IdentityValidationMessage: React.FC<IdentityValidationMessageProps> = ({
  message,
}) => {
  const { body } = message;
  const handleButtonClick = useCallback(() => {
    body && window.open(body, "_blank");
  }, [body]);

  return (
    <div className={styles.root}>
      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH2} className={styles.header}>
        <IdentityValidationMessageHeader />
      </Typography>
      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH2}>
        <FormattedMessage {...messages.text} />
      </Typography>
      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH2} className={styles.bodyText}>
        <FormattedMessage
          {...messages.instruction}
          values={{
            link: (
              <a
                href="https://help.tango.me/en/articles/5370197-how-to-verify-your-account"
                className={styles.link}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage {...messages.linkText} />
              </a>
            ),
          }}
        />
      </Typography>
      <Button
        onClick={handleButtonClick}
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.BIG_48}
        className={styles.button}
      >
        <FormattedMessage {...messages.buttonText} />
      </Button>
    </div>
  );
};

export default memo(IdentityValidationMessage);
