import React, { memo } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import styles from "./Skeleton.scss";

const Skeleton: React.FC = () => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      {isDesktop ? (
        <>
          <div className={classnames(styles.container, styles.top)}>
            <div className={styles.row}>
              <div className={styles.circle} />
              <div className={classnames(styles.rect, styles.sm)} />
            </div>
            <div className={styles.row}>
              <div className={styles.circle} />
              <div className={styles.circle} />
              <div className={styles.circle} />
            </div>
          </div>
          <div className={styles.player}>
            <div className={styles.profile} />
          </div>
          <div className={classnames(styles.container, styles.bottom)}>
            <div className={styles.rect} />
            <div className={styles.row}>
              <div className={styles.circle} />
              <div className={styles.circle} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classnames(styles.container, styles.top)}>
            <div className={styles.row}>
              <div className={classnames(styles.circle, styles.sm)} />
              <div className={classnames(styles.rect, styles.sm)} />
              <div className={classnames(styles.rect, styles.xs)} />
            </div>
            <div className={styles.row}>
              <div className={classnames(styles.circle, styles.sm)} />
              <div className={classnames(styles.circle, styles.sm)} />
              <div className={classnames(styles.circle, styles.sm)} />
            </div>
          </div>
          <div className={classnames(styles.container, styles.bottom)}>
            <div className={classnames(styles.rect, styles.lg)} />
            <div className={styles.row}>
              <div className={classnames(styles.circle, styles.lg)} />
              <div className={styles.profile} />
              <div className={classnames(styles.circle, styles.lg)} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Skeleton);
