const countries = require("i18n-iso-countries");
module.exports = function(cb) {
    require.ensure([
      "i18n-iso-countries/langs/ta.json",
      "date-fns/locale/ta",
      "translations/ta.json",
    ], (require) => {
      countries.registerLocale(require("i18n-iso-countries/langs/ta.json"));
      cb({
        translatedMessages: require("translations/ta.json"),
        dateFnsLocale: require("date-fns/locale/ta").default
      });
    }, "locale-ta-no-intl");
};