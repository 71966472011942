import React, { memo, useCallback, useState } from "react";
import { FormattedDisplayName, FormattedMessage } from "react-intl";
import classnames from "classnames";
import { remoteLogout } from "chat/api/remoteLogout";
import { FormattedDate } from "chat/components/FormattedDate/FormattedDate";
import { Button, DisplayName, Typography } from "chat/imports/components";
import {
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import {
  useBreakpoint,
  useIsMountedRef,
  useMyProfile,
} from "chat/imports/hooks";
import {
  NewDeviceLoginMessageProto,
  parseMessageFromBase64,
} from "chat/imports/proto";
import { StoredMessage } from "chat/state/reducer";
import styles from "./NewDeviceLoginMessage.scss";

interface NewDeviceLoginMessageProps {
  message: StoredMessage;
}

const NewDeviceLoginMessage: React.FC<NewDeviceLoginMessageProps> = ({
  message,
}) => {
  const isMountedRef = useIsMountedRef();
  const breakpoint = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const { basicProfile } = useMyProfile();
  const { deviceName, isoCountryCode, loginTime, accountId, username } =
    parseMessageFromBase64(message.body, NewDeviceLoginMessageProto);

  const handleClick = useCallback(() => {
    setLoading(true);
    remoteLogout({ accountId, username })
      .catch(() => {
        // noop
      })
      .finally(() => {
        if (isMountedRef.current) {
          setLoading(false);
        }
      });
  }, [accountId, username, isMountedRef]);

  return (
    <Typography
      type={TYPOGRAPHY_TYPE.PARAGRAPH2}
      className={classnames(styles.root, styles[breakpoint])}
      as="div"
    >
      <FormattedMessage
        id="device.login_info.message.title"
        defaultMessage="Dear {username}, we detected a login to your account from a new device"
        values={{
          username: <DisplayName basicProfile={basicProfile} />,
        }}
      />
      <div className={styles.loginInfo}>
        {`${deviceName} • `}
        <FormattedDisplayName
          type="region"
          value={isoCountryCode.toUpperCase()}
        />
        {". "}
        <FormattedDate
          value={new Date(loginTime)}
          dateStyle="long"
          timeStyle="short"
        />
      </div>
      <div className={styles.description}>
        <FormattedMessage
          id="device.login_info.message_info"
          defaultMessage="If this was you, you can safely disregard this message. If this wasn’t you, you can disconnect that user."
        />
      </div>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.PRIMARY}
        onClick={handleClick}
        disabled={loading}
      >
        <FormattedMessage
          id="disconnect.user"
          defaultMessage="Disconnect user"
        />
      </Button>
    </Typography>
  );
};

export default memo(NewDeviceLoginMessage);
