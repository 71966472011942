import React, { FC, memo } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Typography } from "legal/imports/components";
import { TYPOGRAPHY_TYPE } from "legal/imports/constants";
import styles from "./LegalNavigationItem.scss";

interface LegalNavigationItemProps {
  dataTestId?: string;
  message: MessageDescriptor;
  selected?: boolean;
  to: string;
}

const LegalNavigationItem: FC<LegalNavigationItemProps> = ({
  to,
  message,
  selected,
  dataTestId,
}) => (
  <Typography
    type={selected ? TYPOGRAPHY_TYPE.HEADLINE4 : TYPOGRAPHY_TYPE.PARAGRAPH2}
    className={classnames(
      styles.tab,
      selected ? styles.active : styles.inactive
    )}
    as={Link}
    data-testid={dataTestId}
    to={to}
  >
    <FormattedMessage {...message} />
  </Typography>
);

export default memo(LegalNavigationItem);
