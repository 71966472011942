import { differenceInYears } from "date-fns";
import { Nullable } from "src/types/common";

const MILLISECONDS = 1000;
const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;
const AVERAGE_DAYS_IN_MONTH = 30;

const DAYS_IN_MS = MILLISECONDS * SECONDS * MINUTES * HOURS;
const HOURS_IN_MS = MILLISECONDS * SECONDS * MINUTES;

export const currentTimeMillis = () => new Date().getTime();

export const calculateAgeFromDate = (
  dateString: number | string,
  referenceDate: Date | number | string = Date.now()
) =>
  differenceInYears(
    typeof referenceDate === "string" ? new Date(referenceDate) : referenceDate,
    new Date(dateString)
  );

export const isStaleByTimestamp = (
  timestampMillis: number,
  timeToLive: number
) => !timestampMillis || currentTimeMillis() - timestampMillis >= timeToLive;

export const getDaysFromEndedDate = (endedAt: Nullable<number>) =>
  endedAt ? (endedAt - Date.now()) / DAYS_IN_MS : 0;

export const getHoursFromEndedDate = (endedAt: Nullable<number>) =>
  endedAt ? (endedAt - Date.now()) / HOURS_IN_MS : 0;

export const getDiffFromCurrentAndStartedDate = (startedAt: number) =>
  Math.ceil((Date.now() - startedAt) / (DAYS_IN_MS * AVERAGE_DAYS_IN_MONTH));
