import type { RealTimeRecommendationsClientEventRequestContext } from "src/features/recommendations/common/types";
import { RealTimeRecommendationsPlatform } from "src/features/recommendations/common/enums";

export class RealTimeRecommendationsContextService {
  private context: RealTimeRecommendationsClientEventRequestContext = {
    accountId: "",
    platform: RealTimeRecommendationsPlatform.WEB,
    tangoVersion: "",
  };

  public build(): RealTimeRecommendationsClientEventRequestContext {
    return this.context;
  }

  public setAccountId(accountId: string): this {
    this.context.accountId = accountId;
    return this;
  }

  public setPlatform(platform: number): this {
    this.context.platform = platform;
    return this;
  }

  public setTangoVersion(tangoVersion: string): this {
    this.context.tangoVersion = tangoVersion;
    return this;
  }
}
