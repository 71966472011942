import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGiftMessage } from "chat/components/currentConversation/useGiftMessage";
import {
  RootState,
  giftsCacheSelectors,
  loadSingleGift,
} from "chat/imports/state";
import { Gift } from "chat/imports/types";
import { StoredMessage } from "chat/state/reducer";

export const useGiftFromMessage = (message: StoredMessage) => {
  const dispatch = useDispatch();

  const { giftId } = useGiftMessage(message);

  const gift: Gift | undefined = useSelector(
    useCallback(
      (state: RootState) => giftsCacheSelectors.getGiftById(state, giftId),
      [giftId]
    )
  );

  useEffect(() => {
    if (!gift) {
      dispatch(loadSingleGift(giftId));
    }
  }, [dispatch, gift, giftId]);

  return gift;
};
