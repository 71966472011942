import React, { FC, memo } from "react";
import { PostDate } from "legal/components";
import { Headline, Paragraph } from "legal/components/typography";
import { SCREEN_NAME, ScreenViewReporter } from "legal/imports/analytics";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import { MailToCopyright, MailToSupport } from "legal/links";
import styles from "legal/common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals */
const ContactBlock: FC = () => (
  <>
    <Paragraph>Then send this notice to:</Paragraph>
    <Paragraph>By Mail:</Paragraph>
    <Paragraph>
      Designated Copyright Agent <br />
      TangoMe, Inc. <br />
      615 National Ave, <br />
      Mountain View, CA 94043 <br />
    </Paragraph>
    <Paragraph>By Email:</Paragraph>
    <Paragraph>
      Email: <MailToCopyright />
    </Paragraph>
    <Paragraph>
      For other questions or issues, please email <MailToSupport />
    </Paragraph>
  </>
);

const Copyright: FC = () => (
  <>
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_COPYRIGHT} />
    <FormattedMessageTitle {...legalMessages.copyright} />

    <article className={styles.article}>
      <PostDate>Last Modified: 16.07.2014</PostDate>

      <Headline level={1}>Copyright</Headline>

      <Paragraph>
        PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
      </Paragraph>
      <Paragraph>
        Tango respects the intellectual property rights of others, and requests
        that users of the Tango Service do the same. Our Terms and Conditions
        prohibit the posting or uploading of content that violates the
        intellectual property rights of a third party. If we receive a notice of
        infringement in accordance with the requirements set forth below, we
        will promptly remove or disable access to the allegedly infringing
        content.
      </Paragraph>
      <Paragraph>Notice of Infringing Material</Paragraph>
      <Paragraph>
        If you are a right holder, and you believe that your copyrighted work
        has been copied and is available through our Service in a way that
        constitutes copyright infringement, you may send Tango a notice of claim
        of copyright infringement pursuant to The Digital Millennium Copyright
        Act of 1998 (DMCA), 17 USC Â§512(c). To be effective, the notice must be
        in writing, sent to the address below, and must contain the following
        details: A description of the specific copyrighted work that you claim
        has been infringed, with reasonably sufficient details to enable us to
        identify the work claimed to be infringed or, if multiple works are
        claimed to be infringed, a representative list of such works (for
        example: title, author, any registration or tracking number); A
        description of where the work that you claim is infringing is located on
        the Tango Service, with reasonably sufficient detail to enable us to
        identify and locate the material that is claimed to be infringing; Your
        contact information so that we can contact you (including your full
        legal name, mailing address, telephone number, and email address); If
        possible, provide information sufficient to permit us to notify the
        user(s) who posted the content that allegedly contains infringing
        material. If possible, provide screenshots or other materials that are
        helpful to identify the works in question. This is for identification
        only, not to “prove” substantive claims. A statement by you that you
        have good faith belief that the disputed use of the copyrighted work is
        not authorized by the copyright owner, its agent or the law. (“I have
        good faith belief that the disputed use of the copyrighted materials
        described above and contained on the Tango Service is not authorized by
        the copyright owner, its agent, or the law.”) A statement by you, made
        under penalty of perjury, that all information in your notice is
        accurate and that you are the copyright owner or authorized to act on
        behalf of the copyright owner. (“I swear, under penalty of perjury, that
        the information in the notification is accurate and that I am the
        copyright owner or am authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed.”) The electronic or
        physical signature of the copyright owner, or the person to act on
        behalf of the copyright owner.
      </Paragraph>

      <ContactBlock />

      <Paragraph>
        Tango will respond to notices of alleged copyright infringement. If a
        Tango user infringes the rights of a copyright owner, Tango may block,
        suspend, or terminate the user’s access to the Tango Service.
        Counter-Notification <br />
        If material that you have posted to a service controlled or operated by
        Tango has been taken down, you may file a counter-notification pursuant
        to DMCA Section 17 USC Â§512(g). To be effective, the
        counter-notification must be in writing, and sent to the address below,
        and contains the following details: <br />
        Identification of the specific material that has been removed or to
        which access has been disabled and the specific location at which the
        material appeared before it was removed or disabled (An optional screen
        shot is recommended.); <br />
        Your contact information so that we can contact you (including your full
        legal name, mailing address, telephone number, and email address);{" "}
        <br />
        Include the following statement: “I swear, under penalty of perjury,
        that I have a good faith belief that the material identified above was
        removed or disabled as a result of a mistake or misidentification of the
        material so removed or disabled.” <br />
        Include a statement by you, made under penalty of perjury, that all
        information in your counter notice is accurate and that you are the
        copyright owner or authorized to act on behalf of the copyright owner.
        (“I hereby state that the information in this notice is accurate and,
        under penalty of perjury, that I am the owner, or authorized to act on
        behalf of the owner, of the material or of an exclusive right in the
        material that has been removed or to which access has been disabled”);{" "}
        <br />
        Include a statement that you consent to the jurisdiction of the Federal
        District Court for judicial district in which your address is located
        or, if your address is outside the USA, for any judicial district in
        which Tango may be found and that you will accept service of process
        from the person who submitted a notice in compliance with section 17 USC
        Â§ 512(c)(1)(C) of the DMCA, or an agent of such person, as generally
        described above; <br />
        Your electronic or physical signature.
      </Paragraph>

      <ContactBlock />
    </article>
  </>
);

export default memo(Copyright);
