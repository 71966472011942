import React, { forwardRef, memo, useEffect } from "react";
import classnames from "classnames";
import { CustomContentProps, SnackbarContent } from "notistack";
import { ReactComponent as IconSuccessful } from "img/ic-successful.svg";
import styles from "./common.scss";

const SuccessToast = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ message, className, style }, ref) => {
    useEffect(() => {
      let timeoutId: number | undefined;
      if (window.tangoAndroidWebview) {
        timeoutId = window.setTimeout(() => {
          window.tangoAndroidWebview.onPaymentSuccess();
        }, 2000);
      }
      return () => {
        window.clearTimeout(timeoutId);
      };
    }, []);

    return (
      <SnackbarContent ref={ref} className={className} style={style}>
        <div
          data-testid="toaster-payment-success"
          className={classnames(styles.root, styles.success)}
          ref={ref}
        >
          <>
            <div className={classnames(styles.message, styles.successMessage)}>
              <IconSuccessful className={styles.iconSuccessful} />
              <p>{message}</p>
            </div>
          </>
        </div>
      </SnackbarContent>
    );
  }
);

SuccessToast.displayName = "SuccessToast";

export default memo(SuccessToast);
