import { createReducer } from "@reduxjs/toolkit";
import {
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import { SuspensionStatusState } from "state/types";
import { ACME_RECEIVED } from "state/actionTypes";
import { getStatus } from "state/actionCreators/suspentionStatus";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";

export const persistConfig = {
  whitelist: ["data"],
};

const initialState = {
  data: false,
  meta: { ...initialFetcherStateMeta, stale: true },
};

const reducer = createReducer<SuspensionStatusState>(
  initialState,
  (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        builder,
        action: getStatus,
        prepareData: (oldStatus, newStatus) => newStatus,
        initialData: initialState.data,
      }),
      () => initialState
    ).addMatcher(
      (action) => action.type === ACME_RECEIVED,
      (state, action) => {
        const { serviceName, serviceIdentifier } = action.payload;
        if (
          serviceName === "facilitator" &&
          serviceIdentifier === "suspentionStatusChange" /* not a typo! */
        ) {
          state.meta.stale = true;
        }
      }
    );
  }
);

export default reducer;
