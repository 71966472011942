import {
  HomePageStreamsResponse,
  ShowRoomStream,
} from "src/features/homePage/shared/constants/types";
import { ExtendedStreamInfo } from "src/features/homePage/v2/imports/types";

const mapToOutputStream = ({ result, entities }: HomePageStreamsResponse) => {
  const streams: ExtendedStreamInfo[] = [];
  const broadcasterIds: string[] = [];

  result.forEach((streamId) => {
    const stream = entities.stream[streamId];
    const {
      viewerCount,
      title,
      ticketPrice,
      thumbnail,
      tags,
      broadcasterId,
      hashtags,
      id,
      kind,
      landscape,
      playlistUrl,
      relatedStreams,
      restrictions,
      status,
    } = stream;

    streams.push({
      startTime: 0,
      broadcasterId,
      hashtags,
      id,
      kind,
      landscape,
      playlistUrl,
      relatedStreams,
      restrictions,
      status,
      tags,
      thumbnail,
      ticketPrice,
      title,
      viewerCount,
    });

    broadcasterIds.push(broadcasterId);
  });

  return { streams, broadcasterIds };
};

export const mapToOutputVisitorStream = (entities: Array<ShowRoomStream>) => {
  const streams: Partial<ExtendedStreamInfo>[] = [];

  entities.forEach((stream) => {
    streams.push({
      viewerCount: stream.viewers,
      thumbnail: stream.thumbnail,
      broadcasterId: stream.broadcaster.encryptedId,
      id: stream.encryptedStreamId,
      playlistUrl: stream.masterListUrl,
      startTime: 0,
    });
  });

  return streams;
};

export default mapToOutputStream;
