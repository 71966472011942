import React, { FC, memo } from "react";
import { PostDate } from "legal/components";
import { Headline, Paragraph } from "legal/components/typography";
import { SCREEN_NAME, ScreenViewReporter } from "legal/imports/analytics";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import { LinkToTermsOfUse } from "legal/links";
import styles from "legal/common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals */
const AgencyProgramAgreement: FC = () => (
  <>
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_AGENCY_PROGRAM_AGREEMENT} />
    <FormattedMessageTitle {...legalMessages.agencyProgramAgreement} />

    <article className={styles.article}>
      <PostDate>Last Modified: 19.11.2024</PostDate>

      <Headline level={1}>
        {legalMessages.agencyProgramAgreement.defaultMessage}
      </Headline>

      <Headline level={2}>Introduction</Headline>
      <Paragraph>
        Welcome to TangoMe&#39;s Agency Program (<b>&quot;Program&quot;</b>).
        These Terms of Use (<b>&quot;Terms&quot;</b>) constitute a binding legal
        agreement between TangoMe Inc. (<b>&quot;Company&quot;</b>,{" "}
        <b>&quot;we&quot;</b>, <b>&quot;our&quot;</b>, or <b>&quot;us&quot;</b>)
        and you (<b>&quot;Agent&quot;</b>, <b>&quot;you&quot;</b>, or{" "}
        <b>&quot;your&quot;</b>). These Terms govern your participation in the
        Program, where you may recruit new content creators (
        <b>&quot;Broadcasters&quot;</b>) and new users (
        <b>&quot;Supporters&quot;</b>) to our live streaming social platform (
        <b>&quot;Platform&quot;</b>) to increase content creation, enhance
        engagement, broaden exposure of the Platform, and acquire additional
        users and Broadcasters (<b>&quot;Agent&#39;s Services&quot;</b>).
      </Paragraph>
      <Paragraph>
        These Terms are supplemental to the Platform&#39;s{" "}
        <LinkToTermsOfUse>Terms of Use</LinkToTermsOfUse> (
        <b>&quot;Platform Terms&quot;</b>). By participating in the Program, you
        acknowledge that the Platform Terms fully apply to your role as an
        Agent, and that in the event of any conflict between these Terms and the
        Platform Terms, these Terms shall prevail.
      </Paragraph>

      <Headline level={2}>Participation in the Program</Headline>
      <Paragraph subParagraph>
        The Program is available to all existing registered users of the
        Platform. As an Agent, you can earn Diamonds for each successful
        recruitment of a new Broadcaster or Supporter to the Platform. These
        Diamonds can be redeemed in accordance with the terms set forth in these
        Terms, applicable Policies, and the Company&#39;s policy and discretion.
      </Paragraph>
      <Paragraph subParagraph>
        To be eligible to earn Diamonds, you must recruit new Broadcasters or
        Supporters using a unique, dedicated link specifically assigned to your
        profile on the Platform. This link or code is personal,
        non-transferable, and is provided solely for your use in recruiting new
        Broadcasters or new Supporters.
      </Paragraph>
      <Paragraph subParagraph>
        The Company reserves the right, at its sole discretion, to reject any
        Broadcaster or Supporter referred by you if their registration or
        conduct does not comply with any applicable Policies. This includes, but
        is not limited to, cases where the referred Broadcaster or Supporter
        engages in activities that the Company deems harmful, fraudulent, or
        otherwise contrary to the interests of the Platform or its community.
      </Paragraph>
      <Paragraph subParagraph>
        You undertake to conduct yourself with the utmost integrity and good
        faith in all interactions related to the Program. This includes, but is
        not limited to, ensuring that your actions do not harm the reputation,
        business interests, or operations of the Company. You are expected to
        uphold the Company&#39;s standards of conduct and engage in fair
        competition at all times. This commitment involves refraining from any
        actions that could be perceived as unfair competition or unethical
        behavior that may negatively impact the Company&#39;s market position,
        brand reputation, or relationships with Broadcasters and other
        stakeholders.
        <br />
        <br />
        Failure to adhere to these requirements may result in serious
        consequences, including immediate termination of your account, removal
        from the Program, and potential legal action. The Company reserves the
        right to take any necessary measures to protect its interests and ensure
        compliance with these obligations.
      </Paragraph>

      <Headline level={2}>Commission and Performance Objectives</Headline>
      <Paragraph subParagraph>
        Agents may be eligible to receive commission fees as detailed below:
      </Paragraph>
      <Paragraph subSubParagraph>
        For each new Broadcaster referred by the Agent, who is approved and
        joins the Platform, the Agent may receive a percentage of the total
        Diamond withdrawals made by the Broadcaster during the 24 months
        following their registration. Such percentage may vary depending on
        meeting certain performance objectives as may be communicated from time
        to time, and may never exceed 15%
      </Paragraph>
      <Paragraph subSubParagraph>
        For each new Supporter referred by the Agent, the Agent will be entitled
        to 5% of the total purchases of in-app virtual items made by the
        referred Supporter on the Platform during the 6 months following their
        registration.
      </Paragraph>
      <Paragraph subSubParagraph>
        Any new user referred by the Agent may not be considered both a
        Broadcaster and a Supporter for the purposes of computing the associated
        commission, and will depend on the link with which the user was referred
        by the Agent.
      </Paragraph>
      <Paragraph subParagraph>
        The Company may modify commission structures, performance objectives,
        and fees at any time. Changes communicated by posting a notice on the
        Platform, or by sending you an email to the address associated with your
        account, will take effect immediately unless stated otherwise. It is the
        Agent’s responsibility to ensure they have the latest version of our
        Platform installed to view any changes. Continued participation
        constitutes acceptance of the revised terms. If you disagree, your only
        option is to stop participating in the Program.
      </Paragraph>
      <Paragraph subParagraph>
        As applicable, Agents are required to meet the performance objectives
        set by the Company. Failure to achieve these objectives may result,
        among others, in a reduction of the Agent's commission, at the Company's
        sole discretion. These objectives may be updated periodically, and any
        changes will be communicated to Agents by the Company
      </Paragraph>
      <Paragraph subParagraph>
        Any applicable taxes, such as VAT, are included in the agreed fees. You
        are responsible for reporting and paying taxes, with each party bearing
        their own obligations. The Company will not withhold taxes on your
        behalf.
      </Paragraph>

      <Headline level={2}>Limitation of Liability</Headline>
      <Paragraph subParagraph>
        The Program, Platform, and related services are provided "as is" and "as
        available" without warranties of any kind. To the fullest extent allowed
        by law, the Company disclaims all express and implied warranties,
        including those of merchantability, fitness for a purpose, and
        non-infringement. The Company does not guarantee that the Program or
        Platform will meet your needs, be uninterrupted, secure, or error-free.
      </Paragraph>
      <Paragraph subParagraph>
        To the fullest extent permitted by law, the Company's total liability to
        you or any third party for claims related to the Program or Platform is
        limited to $500.
      </Paragraph>
      <Paragraph subParagraph>
        Any claim related to your participation in the Program or provision of
        Agent's Services must be filed within one year of its occurrence, or it
        is permanently barred. This applies to all claims, regardless of legal
        theory.
      </Paragraph>

      <Headline level={2}>Non-compete and Non-solicitation</Headline>
      <Paragraph subParagraph>
        During your participation in the Program and for twelve (12) months
        thereafter, you agree not to solicit, encourage, or induce any
        Broadcaster referred by you or affiliated with the Company to end their
        relationship with the Company or move their content to a competing
        platform. This includes offering any form of direct or indirect
        compensation, gifts, incentives, or other benefits to persuade
        Broadcasters.
      </Paragraph>
      <Paragraph subParagraph>
        You acknowledge and agree that these restrictions are necessary to
        protect the Company’s interests. The Company may seek injunctive relief
        and other remedies, including damages and legal costs, for any breach.
      </Paragraph>
      <Paragraph subParagraph>
        If any part of this section is unenforceable, the rest remains
        effective, with modifications to make it as enforceable as possible
        under the law.
      </Paragraph>

      <Headline level={2}>Termination</Headline>
      <Paragraph subParagraph>
        The Company may terminate your participation in the Program at any time,
        with or without cause or notice. Termination may occur if you breach
        these Terms, fail to meet performance goals, harm the Company’s
        interests, or if the Company discontinues the Program or your
        participation.
      </Paragraph>
      <Paragraph subParagraph>
        Any commission fees earned before termination will be paid according to
        the Company’s schedule, provided no Terms are breached. Unpaid fees will
        be forfeited if termination results from your breach of these Terms.
      </Paragraph>
      <Paragraph subParagraph>
        Provisions intended to survive termination will remain in effect after
        termination.
      </Paragraph>

      <Headline level={2}>Governing Law and Arbitration</Headline>
      <Paragraph subParagraph>
        These Terms and any related disputes are governed by New York State and
        U.S. federal law, excluding conflict of law principles. The United
        Nations Convention on Contracts for the International Sale of Goods does
        not apply. The Services are deemed based in New York and do not
        establish jurisdiction outside of New York.
      </Paragraph>
      <Paragraph subParagraph>
        Any claim or dispute arising from these Terms or your participation in
        the Program will be resolved as outlined in Section 22 of the Platform’s
        Terms of Use. By agreeing to these Terms, you consent to these dispute
        resolution conditions.
      </Paragraph>

      <Headline level={2}>Miscellaneous</Headline>
      <Paragraph subParagraph>
        Nothing in these Terms shall be construed to create a partnership, joint
        venture, employer-employee, or agency relationship between you and the
        Company. You are an independent contractor and are not authorized to
        make any representations or commitments on behalf of the Company unless
        expressly authorized in writing.
      </Paragraph>
    </article>
  </>
);

export default memo(AgencyProgramAgreement);
