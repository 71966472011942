import React, { FC } from "react";
import ContentController from "src/features/signin/components/ContentController/ContentController";
import Disclaimer from "src/features/signin/components/Disclaimer/Disclaimer";
import BackButton from "src/features/signin/components/buttons/BackButton";
import { useBackButtonData } from "src/features/signin/hooks";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import { ButtonSize, ButtonVariant } from "src/features/signin/imports/ui";
import { ReactComponent as TangoLogo } from "img/tango-logo-new.svg";
import styles from "src/features/signin/SignUp.scss";

interface SignUpMobileProps {
  registrationSource: RegistrationSource;
}

const SignUpMobile: FC<SignUpMobileProps> = ({ registrationSource }) => {
  const { isBackButtonVisible, navigateBack } = useBackButtonData();

  return (
    <>
      <div className={styles.header}>
        {isBackButtonVisible && (
          <BackButton
            className={styles.back}
            onClick={navigateBack}
            size={ButtonSize.MEDIUM_32}
            variant={ButtonVariant.ICON_ONLY}
          />
        )}

        <TangoLogo className={styles.logo} />
      </div>

      <div className={styles.signin}>
        <ContentController registrationSource={registrationSource} />
      </div>

      <Disclaimer className={styles.disclaimer} />
    </>
  );
};

export default SignUpMobile;
