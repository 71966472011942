import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addUserSessionScopeReducer } from "chat/imports/state";
import {
  PremiumMessageDetails,
  PremiumMessageDetailsMap,
} from "chat/premiumMessage/types";
import { UnlockPremiumMessagePayload } from "chat/types";
import { unlockPremiumMessage } from "./asyncAction";

export type PremiumMessageState = {
  details: PremiumMessageDetailsMap;
  pending: Record<PremiumMessageDetails["messageId"], boolean>;
};

const initialState: PremiumMessageState = {
  details: {},
  pending: {},
};

const slice = createSlice({
  name: "premiumMessage",
  initialState,
  reducers: {
    unlockPremiumMessageStart(
      state,
      action: PayloadAction<UnlockPremiumMessagePayload>
    ) {
      const { messageId } = action.payload;
      state.pending[messageId] = true;
    },
    unlockPremiumMessageEnd(
      state,
      action: PayloadAction<UnlockPremiumMessagePayload>
    ) {
      const { messageId } = action.payload;
      delete state.pending[messageId];
    },
    setPremiumMessageDetails(
      state,
      action: PayloadAction<PremiumMessageDetailsMap>
    ) {
      state.details = action.payload;
    },
  },
  extraReducers(builder) {
    addUserSessionScopeReducer(
      builder.addCase(unlockPremiumMessage.rejected, (state, action) => {
        const { messageId } = action.meta.arg;
        delete state.pending[messageId];
      }),
      () => initialState
    );
  },
});

export const {
  unlockPremiumMessageStart,
  unlockPremiumMessageEnd,
  setPremiumMessageDetails,
} = slice.actions;

export const premiumMessageReducer = slice.reducer;
