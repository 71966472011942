import { useDispatch, batch } from "react-redux";
import useGiftsDrawerLayout from "ui/hooks/useGiftsDrawerLayout";

import { openGiftsDrawerModal } from "state/actionCreators/modal";
import { actionCreators as settingsActionCreators } from "state/tree/settings";
import { actionCreators as giftsCacheActionCreators } from "state/tree/giftsCache";
import { openGiftsDrawerInStreamBottomScreen } from "state/actionCreators/bottomScreen";
import { GiftsDrawerLayout } from "src/enums";

export const getShowGiftsDrawer =
  ({ giftsDrawerLayout, dispatch }) =>
  ({ tabId } = {}) => {
    switch (giftsDrawerLayout) {
      case GiftsDrawerLayout.MODAL:
        dispatch(openGiftsDrawerModal({ tabId }));
        break;

      case GiftsDrawerLayout.BOTTOM_SCREEN:
        batch(() => {
          dispatch(giftsCacheActionCreators.setGiftsDrawerTabId(tabId));
          dispatch(settingsActionCreators.setGiftsDrawerVisibility(true));
          dispatch(
            openGiftsDrawerInStreamBottomScreen({
              onDismiss: () => {
                dispatch(
                  settingsActionCreators.setGiftsDrawerVisibility(false)
                );
              },
            })
          );
        });
        break;

      case GiftsDrawerLayout.RIGHT_SIDE:
        batch(() => {
          dispatch(giftsCacheActionCreators.setGiftsDrawerTabId(tabId));
          dispatch(settingsActionCreators.setGiftsDrawerVisibility(true));
        });
        break;

      default:
        throw Error(`Unallowed giftsDrawerLayout: '${giftsDrawerLayout}'`);
    }
  };

const useShowGiftsDrawer = () => {
  const dispatch = useDispatch();
  const giftsDrawerLayout = useGiftsDrawerLayout();
  return getShowGiftsDrawer({ giftsDrawerLayout, dispatch });
};

export default useShowGiftsDrawer;
