import { GIFT_PLAYED, GIFT_SENT, GIFT_SENT_FAILED } from "state/actionTypes";

export const sentGift = ({
  requestId,
  giftId,
  priceInCredit,
  finalCredit,
  finalPoint,
  additionInPoint,
  postId,
  streamId,
  userId,
  currentUserId,
  momentId,
  type = "",
  withPoint,
  bonusPercentage,
  realTimeGiftFromType,
}) => ({
  type: GIFT_SENT,
  meta: { currentUserId, requestId },
  payload: {
    priceInCredit,
    giftId,
    finalCredit,
    finalPoint,
    postId,
    streamId,
    momentId,
    userId,
    additionInPoint,
    type,
    withPoint,
    bonusPercentage,
    realTimeGiftFromType,
  },
});

export const processSendGiftFail = ({
  requestId,
  giftId,
  priceInCredit,
  finalCredit,
  finalPoint,
  additionInPoint,
  postId,
  streamId,
  userId,
  currentUserId,
  momentId,
  type = "",
  withPoint,
}) => ({
  type: GIFT_SENT_FAILED,
  meta: { currentUserId, requestId },
  payload: {
    priceInCredit,
    giftId,
    finalCredit,
    finalPoint,
    postId,
    streamId,
    momentId,
    userId,
    additionInPoint,
    type,
    withPoint,
  },
});

export const giftPlayed = (eventId) => ({
  type: GIFT_PLAYED,
  payload: {
    eventId,
  },
});
