import React, { memo, useMemo } from "react";
import { getParsedExternalMessagePayload } from "chat/imports/utils";

interface ExternalMessageTextProps {
  className?: string;
  protobufPayload: string;
}
const ExternalMessageText: React.FC<ExternalMessageTextProps> = ({
  protobufPayload,
  className,
}) => {
  const parsedPayload = useMemo(
    () => getParsedExternalMessagePayload(protobufPayload),
    [protobufPayload]
  );

  return (
    parsedPayload && (
      <span className={className}>{parsedPayload.messageText}</span>
    )
  );
};

export default memo(ExternalMessageText);
