import { LOCATION_CHANGE } from "connected-react-router";
import { Action, Dispatch, Store } from "redux";
import { getCashierSasEnabled } from "environment";
import { PURCHASE_SOURCE } from "src/analytics/enums";
import { FAIL_AUTOLOGIN_OPEN_CAHSIER_STORAGE_KEY } from "src/constants";
import { CASHIER } from "src/state/enums/cashier";
import { openRefillDrawerBottomScreen } from "state/actionCreators/bottomScreen";
import { openBuyCoinsModal } from "state/actionCreators/modal";
import { deviceInfoSelectors } from "state/selectors";

export default (store: Store) => (next: Dispatch) => (action: Action) => {
  if (action.type === LOCATION_CHANGE) {
    const storedValue = localStorage.getItem(
      FAIL_AUTOLOGIN_OPEN_CAHSIER_STORAGE_KEY
    );
    const openCashier = storedValue === "true";
    const currentPath = window.location.pathname;
    const isLinkToLiveStream = currentPath?.includes("live/");

    if (isLinkToLiveStream && openCashier) {
      const state = store.getState();
      const isMobile = deviceInfoSelectors.isAnyMobileDevice(state);
      const modalData = {
        viewType: CASHIER,
        purchaseSource: PURCHASE_SOURCE.CASHIER,
        withBonus: getCashierSasEnabled(),
      };

      if (isMobile) {
        store.dispatch(
          openRefillDrawerBottomScreen({
            screenData: modalData,
          })
        );
      } else {
        // @ts-ignore ToDo: refactor modal
        store.dispatch(openBuyCoinsModal(modalData));
      }
      localStorage.removeItem(FAIL_AUTOLOGIN_OPEN_CAHSIER_STORAGE_KEY);
    }
  }

  return next(action);
};
