import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ROOT_PATH } from "src/constants";
import { LiveFeedType } from "src/enums";
import { RootState } from "state/delegate";
import exploreTabsConfigsSelectorsFactory from "state/selectorFactories/exploreTabsConfig";
import { liveStreamsFeedSelectors, navigationSelectors } from "state/selectors";
import { actionCreators } from "state/tree/liveStreamsFeed";
import {
  linkToLiveStreamMatch,
  linkToRecommended,
  makeLinkToLiveStreamsFeed,
} from "ui/navigation/links";
import { getExploreNavigationMode } from "ui/scenes/live/shared/getExploreNavigationMode";

const selector = (state: RootState) => ({
  currentRoute: navigationSelectors.getCurrentRoute(state),
  latestMode: liveStreamsFeedSelectors.getLatestSelectedCommonMode(state),
});

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modes = useSelector(
    exploreTabsConfigsSelectorsFactory.tags,
    shallowEqual
  );
  const { currentRoute, latestMode } = useSelector(selector, shallowEqual);
  const match = useRouteMatch<{ mode: LiveFeedType }>(linkToLiveStreamMatch);
  const routeMatchMode = match?.params.mode;

  const defaultModeValue = routeMatchMode || modes[0];
  const mode = getExploreNavigationMode(currentRoute, defaultModeValue);
  useEffect(() => {
    if (
      (routeMatchMode === undefined || !modes.includes(routeMatchMode)) &&
      currentRoute !== ROOT_PATH
    ) {
      const hasMode = modes.includes(latestMode);
      const recommendedMode = currentRoute === linkToRecommended;

      const isEmptyMode = !hasMode && !recommendedMode && modes[0];
      const shouldUseLatestMode = hasMode && !recommendedMode && latestMode;

      const selectedMode =
        shouldUseLatestMode || isEmptyMode || LiveFeedType.RECOMMENDED;

      const link = makeLinkToLiveStreamsFeed(selectedMode);
      history.replace({
        pathname: link,
        search: history.location.search,
      });
    }
    if (mode !== LiveFeedType.RECOMMENDED) {
      dispatch(actionCreators.feedTypeSelected(mode));
    }
  }, [mode]);

  return mode;
};
