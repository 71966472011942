import { loadScript } from "src/utils/scriptUtils";
import { getIntercomAppId } from "environment";

type IntercomArgs<Command extends Intercom_.IntercomCommand> = [
  command: Command,
  ...params: Parameters<Intercom_.IntercomCommandSignature[Command]>,
];

interface IntercomFallback {
  <Command extends Intercom_.IntercomCommand>(
    ...args: IntercomArgs<Command>
  ): void;
  c: <Command extends Intercom_.IntercomCommand>(
    argument: IntercomArgs<Command>
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  q: IntercomArgs<any>[];
}

const i: IntercomFallback = (...args) => {
  i.c(args);
};

i.q = [];
i.c = (argument) => {
  i.q.push(argument);
};

window.Intercom = window.Intercom || i;

export default () => {
  const intercomAppId = getIntercomAppId();
  if (!intercomAppId) {
    return;
  }
  const ic = window.Intercom;
  window.intercomSettings = {
    app_id: intercomAppId,
    hide_default_launcher: true,
  };
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", window.intercomSettings);
  }

  loadScript({
    src: `https://widget.intercom.io/widget/${intercomAppId}`,
    async: true,
    delay: 7000,
    nodeId: "intercom-script",
  });
};
