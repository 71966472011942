import isEmpty from "lodash.isempty";
import { AcmeServiceIdentifier, AcmeServiceName } from "src/enums";
import {
  AUTHORIZATION_END,
  LOGIN_END,
} from "src/features/signin/exports/state/actionTypes";
import {
  ACCOUNT_TAGS_UPDATE,
  ACME_RECEIVED,
  BALANCE_BEGIN_FETCH,
  BALANCE_END_FETCH,
  GIFT_SENT,
  GIFTS_BALANCE_BEGIN_FETCH,
  GIFTS_BALANCE_END_FETCH,
  SINGLE_PROFILE_BEGIN_FETCH,
  SINGLE_PROFILE_END_FETCH,
  TOTAL_CREDITS_UPDATE,
  VIEWER_SESSION_BOUGHT_TICKET,
} from "state/actionTypes";
import withUserSessionScope from "state/hor/withUserSessionScope";

export const selectors = {
  getMyAccountId: (state) => state.accountId,
  isMyProfileUpToDate: (state) => !state.profileIsStale,
  isLoadingMyProfile: (state) => state.isLoadingMyProfile,
  getPointsBalance: (state) => state.pointsBalance,
  getCoinsBalance: (state) => state.coinsBalance,
  getCoinsTotal: (state) => state.coinsTotal,
  getVipStatus: (state) => state.vipStatus,
  getAccountTags: (state) => state.accountTags,
  isCoinsBalanceUpToDate: (state) => !state.coinsStale,
  isCoinsTotalBalanceUpToDate: (state) => !state.coinsTotalStale,
  isPointsBalanceUpToDate: (state) => !state.pointsStale,
  isFetchingBalance: (state) => state.fetchingBalance,
  getAccountTagsUpdateTs: (state) => state.accountTagsUpdateTs,
  getGiftsBalance: (state) => state.giftsBalance,
};

const defaultState = {
  accountId: "",
  profileIsStale: true,
  isLoadingMyProfile: false,
  pointsBalance: 0,
  coinsBalance: 0,
  coinsTotal: 0,
  vipStatus: null,
  accountTags: [],
  fetchingBalance: false,
  coinsStale: true,
  pointsStale: true,
  coinsTotalStale: true,
  accountTagsUpdateTs: 0,
  giftsBalance: [],
  fetchingGiftsBalance: false,
};

export const persistConfig = {
  whitelist: [
    "accountId",
    "pointsBalance",
    "coinsBalance",
    "coinsTotal",
    "accountTags",
    "giftsBalance",
  ],
};

export default withUserSessionScope((state = defaultState, action) => {
  switch (action.type) {
    case SINGLE_PROFILE_BEGIN_FETCH: {
      if (action.meta.isMe) {
        return {
          ...state,
          isLoadingMyProfile: true,
        };
      }

      return state;
    }
    case SINGLE_PROFILE_END_FETCH: {
      if (!action.meta.isMe) {
        return state;
      }
      if (action.error) {
        return { ...state, isLoadingMyProfile: false };
      }

      return {
        ...state,
        accountId: action.payload.encryptedAccountId || state.accountId,
        profileIsStale: false,
        isLoadingMyProfile: false,
      };
    }
    case ACME_RECEIVED: {
      const { serviceName, serviceIdentifier } = action.payload;
      if (
        serviceName === "facilitator" &&
        (serviceIdentifier === "syncAccountInfo" ||
          serviceIdentifier === "updateOwnProfile" ||
          serviceIdentifier === "update_profile")
      ) {
        return { ...state, profileIsStale: true };
      }

      if (serviceName === AcmeServiceName.FINANCE && action.payload.isEnabled) {
        if (serviceIdentifier === AcmeServiceIdentifier.SYNC_CREDIT) {
          return {
            ...state,
            coinsStale: true,
            coinsTotalStale: true,
          };
        }
      }
      if (serviceName === AcmeServiceName.GIFT) {
        if (serviceIdentifier === AcmeServiceIdentifier.SYNC_CREDIT) {
          return {
            ...state,
            coinsStale: true,
            coinsTotalStale: true,
          };
        }
        if (serviceIdentifier === "syncPoint") {
          return {
            ...state,
            pointsStale: true,
          };
        }
      }
      break;
    }
    case VIEWER_SESSION_BOUGHT_TICKET: {
      if (!action.error) {
        return { ...state, coinsBalance: action.payload.balance };
      }

      return state;
    }
    case BALANCE_BEGIN_FETCH: {
      return {
        ...state,
        fetchingBalance: true,
      };
    }
    case BALANCE_END_FETCH: {
      if (action.error) {
        return {
          ...state,
          fetchingBalance: false,
        };
      }
      const {
        credits: coinsBalance,
        points: pointsBalance,
        vipStatus,
      } = action.payload;

      return {
        ...state,
        fetchingBalance: false,
        pointsBalance,
        coinsBalance,
        vipStatus,
        pointsStale: false,
        coinsStale: false,
        coinsTotalStale:
          state.coinsTotalStale || state.coinsBalance !== coinsBalance,
      };
    }
    case GIFTS_BALANCE_BEGIN_FETCH: {
      return {
        ...state,
        fetchingGiftsBalance: true,
      };
    }
    case GIFTS_BALANCE_END_FETCH: {
      if (action.error) {
        return {
          ...state,
          fetchingGiftsBalance: false,
        };
      }

      return {
        ...state,
        fetchingBalance: false,
        giftsBalance: action.payload,
      };
    }
    case TOTAL_CREDITS_UPDATE: {
      return {
        ...state,
        coinsTotal: action.payload,
        coinsTotalStale: false,
      };
    }
    case GIFT_SENT: {
      const { finalCredit, finalPoint, giftId } = action.payload;
      const giftsBalance = state.giftsBalance.map((giftBalance) =>
        giftBalance.gift === giftId
          ? { ...giftBalance, amount: Math.max(giftBalance.amount - 1, 0) }
          : giftBalance
      );
      const userCoins = {};

      if (finalCredit !== undefined) {
        userCoins.coinsBalance = finalCredit;
      }

      if (finalPoint !== undefined) {
        userCoins.pointsBalance = finalPoint;
      }

      if (isEmpty(userCoins)) {
        return state;
      }

      return {
        ...state,
        ...userCoins,
        giftsBalance,
      };
    }
    case AUTHORIZATION_END: {
      if (action.error) {
        return state;
      }
      const { accountId } = action.payload;

      return { ...state, accountId };
    }
    case LOGIN_END: {
      if (action.error) {
        return state;
      }
      const { clientCapabilities, accountId } = action.payload;

      return {
        ...state,
        accountId,
        clientCapabilities,
        profileIsStale: true,
        coinsTotalStale: true,
      };
    }
    case ACCOUNT_TAGS_UPDATE: {
      return {
        ...state,
        accountTags: action.payload,
        accountTagsUpdateTs: action.meta.updateTime,
      };
    }
  }

  return state;
});
