import { defineMessages } from "react-intl";

export const ofacMessages = defineMessages({
  readTermsAndConditions: {
    id: "please.refer.to.our.terms.and.conditions.for.more.details",
    defaultMessage: "Please refer to our Terms & Conditions for more details.",
  },
  tangoUnavailableInYourCountry: {
    id: "tango.is.unavailable.in.your.country",
    defaultMessage: "Tango is unavailable in your country.",
  },
  termsAndConditions: {
    id: "terms_and_conditions",
    defaultMessage: "Terms & Conditions",
  },
  websiteNotAvailable: {
    id: "website.not.available",
    defaultMessage: "Website Not Available",
  },
});
