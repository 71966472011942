import type { Dispatch, MiddlewareAPI } from "redux";
import { parseMessageFromBase64 } from "chat/imports/proto";
import { RootState } from "chat/imports/state";
import { getPremiumMessageDetails } from "chat/premiumMessage/api/premiumMessageApi";
import { premiumMessageSelectors } from "chat/premiumMessage/state/selectors";
import { Message, MessageType } from "chat/types";
import { ContentSharedPayload } from "generated/proto/PremiumMessage";
import { ContentSharedPayloadMessage, DetailsResponseCode } from "../types";
import { updateUnlockDetails } from "./updateUnlockDetails";

export const processMessagesToGetDetails = async (
  messages: Message[],
  { getState, dispatch }: MiddlewareAPI<Dispatch, RootState>
) => {
  const state = getState();

  const details = premiumMessageSelectors.getDetails(state);

  const messagesIdsNeedToGetDetails = messages.reduce(
    (messageIds, message) => {
      if (message.type === MessageType.PREMIUM_MESSAGE_SHARED) {
        const { messageId } = parseMessageFromBase64(
          message.payload,
          ContentSharedPayload
        ) as ContentSharedPayloadMessage;

        if (!details[messageId]) {
          messageIds.push(messageId);
        }
      }

      return messageIds;
    },
    [] as ContentSharedPayloadMessage["messageId"][]
  );

  if (messagesIdsNeedToGetDetails.length === 0) {
    return;
  }

  try {
    const messageDetails = await getPremiumMessageDetails(
      messagesIdsNeedToGetDetails
    );

    if (messageDetails.responseCode !== DetailsResponseCode.DETAILS_SUCCESS) {
      return;
    }

    updateUnlockDetails(details, messageDetails.details, dispatch);
  } catch (error) {}
};
