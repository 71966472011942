import React, { FC, memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  EventFields,
  RegistrationSource,
  SCREEN_NAME,
  SIGNIN_TARGET,
  emitUiAction,
} from "src/features/signin/imports/analytics";
import { LoginProvider } from "src/features/signin/imports/enums";
import { ButtonVariant } from "src/features/signin/imports/ui";
import ProviderButton from "src/features/signin/modal/login/components/ProviderButton/ProviderButton";
import { loginContinueWithPhone } from "src/features/signin/state/login/actionCreators";

interface ProviderButtonPhoneProps {
  registrationSource?: RegistrationSource;
}

const ProviderButtonPhone: FC<ProviderButtonPhoneProps> = ({
  registrationSource,
}) => {
  const dispatch = useDispatch();

  const continueWithPhone = useCallback(() => {
    emitUiAction({
      target: SIGNIN_TARGET.SIGNIN_WITH_PHONE,
      tango_screen: {
        reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
      },
      additionalParams: {
        [EventFields.SOURCE]: registrationSource,
      },
    });
    dispatch(loginContinueWithPhone());
  }, [dispatch, registrationSource]);

  return (
    <ProviderButton
      onClick={continueWithPhone}
      provider={LoginProvider.TANGO_PHONE_NUMBER}
      buttonVariant={ButtonVariant.SECONDARY}
    />
  );
};

export default memo(ProviderButtonPhone);
