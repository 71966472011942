import React from "react";
import classnames from "classnames";
import { Spinner } from "chat/imports/components";
import { ComponentWithClassName } from "chat/imports/types";
import styles from "./ChatSpinner.scss";

const ChatSpinner: ComponentWithClassName = ({ className }) => (
  <Spinner
    size={32}
    circularProps={{ size: 24 }}
    className={classnames(styles.root, className)}
  />
);

export default ChatSpinner;
