import { createAction } from "@reduxjs/toolkit";
import { CaptchaState } from "state/tree/captcha";

export type NetworkErrorType = {
  error: unknown;
  config: CaptchaState["config"];
};

export const showCaptcha = createAction<NetworkErrorType["error"]>(
  "lwc/genericNetworkError/showCaptcha"
);

export const setCaptchaConfig = createAction<CaptchaState["config"]>(
  "lwc/genericNetworkError/setConfig"
);

export const genericNetworkError = createAction(
  "lwc/genericNetworkError",
  (error: NetworkErrorType["error"]) => ({
    payload: {
      error,
    },
    error: true,
  })
);
