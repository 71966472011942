import { memo } from "react";
import useGoogleOneTap from "src/features/signin/components/GoogleOneTapSignIn/useGoogleOneTap";

const GoogleOneTapInitializer = () => {
  useGoogleOneTap();

  return null;
};

export default memo(GoogleOneTapInitializer);
