import { IntlShape, defineMessages } from "react-intl";
import { parseDuration } from "chat/utils/parseDuration";

const readableDurationMessages = defineMessages({
  hours: {
    id: "duration.short.single.and.plural.hours",
    defaultMessage: "{hours, plural, one {# hr} other {# hrs}}",
  },
  minutes: {
    id: "duration.short.single.and.plural.minutes",
    defaultMessage: "{minutes, plural, one {# min} other {# mins}}",
  },
  seconds: {
    id: "duration.short.single.and.plural.seconds",
    defaultMessage: "{seconds, plural, one {# sec} other {# secs}}",
  },
});

export const formatReadableDuration = (
  durationInMilliseconds: number,
  intl: IntlShape
) => {
  const { hours, minutes, seconds } = parseDuration(durationInMilliseconds);
  const readableParts = [];

  if (hours) {
    readableParts.push(
      intl.formatMessage(readableDurationMessages.hours, { hours })
    );
  }

  if (minutes) {
    readableParts.push(
      intl.formatMessage(readableDurationMessages.minutes, { minutes })
    );
  }

  if (seconds) {
    readableParts.push(
      intl.formatMessage(readableDurationMessages.seconds, { seconds })
    );
  }

  return readableParts.join(" ");
};
