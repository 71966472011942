import { batch } from "react-redux";
import {
  fetchFeedByTag,
  fetchFollowingFeed,
  fetchFollowingRecommendationsFeed,
  fetchHottestFeed,
  fetchLatestFeed,
  fetchNearbyFeed,
  fetchRecommendationsFeed,
} from "api/stream";
import { LiveFeedType, PersonalFeedType } from "src/enums";
import { currentTimeMillis } from "src/utils/dateUtils";
import {
  getFollowSuggestionsModerationLevel,
  getLiveSortAlgorithmVersion,
} from "state/abTests";
import {
  getLocaleCountryCode,
  getRegion,
  liveStreamsFeedSelectors,
  loginSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/liveStreamsFeed";

export const PAGE_SIZE = 48;

const makeTaggedFetcher = (mode) => (params) =>
  fetchFeedByTag({ tag: mode, ...params });

const fetchersMap = {
  [LiveFeedType.POPULAR]: fetchHottestFeed,
  [LiveFeedType.NEW]: fetchLatestFeed,
  [LiveFeedType.NEARBY]: fetchNearbyFeed,
  [LiveFeedType.RECOMMENDED]: fetchRecommendationsFeed,
  [LiveFeedType.CREATORS]: makeTaggedFetcher(LiveFeedType.CREATORS),
  [PersonalFeedType.OLD_FOLLOWING]: fetchFollowingFeed,
  [PersonalFeedType.FOLLOWING]: fetchFollowingRecommendationsFeed,
  [PersonalFeedType.RECOMMENDED]: fetchFollowingRecommendationsFeed,
  [PersonalFeedType.ALL]: fetchFollowingRecommendationsFeed,
};

const getFetcher = (mode) => fetchersMap[mode] || makeTaggedFetcher(mode);

export const loadMore =
  (mode, pageSize = PAGE_SIZE) =>
  (dispatch, getState) => {
    const state = getState();

    if (
      !liveStreamsFeedSelectors.canLoadMore(state, mode) ||
      liveStreamsFeedSelectors.isLoadingMode(state, mode)
    ) {
      return Promise.resolve();
    }
    const moderationLevel = getFollowSuggestionsModerationLevel(state);
    const pageCount = liveStreamsFeedSelectors.getPageCount(state, mode);
    const sessionId = liveStreamsFeedSelectors.getSessionId(state, mode);
    const sortVersion = getLiveSortAlgorithmVersion(state);
    const region = getRegion(state);
    const locale = getLocaleCountryCode(state);
    const guest = !loginSelectors.isLoggedIn(state);

    dispatch(actionCreators.fetchBegan({ mode }));
    return getFetcher(mode)({
      moderationLevel,
      pageSize,
      pageCount,
      sessionId,
      sortVersion,
      region,
      locale,
      guest,
    })
      .then((posts) =>
        batch(() => {
          dispatch(
            actionCreators.fetchCompleted({
              mode,
              data: { ...posts, timestamp: currentTimeMillis() },
              replaceAll: false,
            })
          );
          // HOTFIX
          // ToDo: https://tango-me.atlassian.net/browse/WEB-5413
          if (
            posts &&
            posts.categoryInfoList &&
            posts.categoryInfoList.find(
              (item) => item.tag === PersonalFeedType.RECOMMENDED
            )
          ) {
            dispatch(
              actionCreators.fetchCompleted({
                mode: PersonalFeedType.RECOMMENDED,
                data: { ...posts, timestamp: currentTimeMillis() },
                replaceAll: false,
              })
            );
          }
        })
      )
      .catch((error) =>
        dispatch(
          actionCreators.fetchFailed({
            mode,
            error,
            removeAll: false,
          })
        )
      );
  };

export const refresh =
  (mode, utm = "") =>
  (dispatch, getState) => {
    const state = getState();
    const moderationLevel = getFollowSuggestionsModerationLevel(state);
    const sortVersion = getLiveSortAlgorithmVersion(state);
    const region = getRegion(state);
    const locale = getLocaleCountryCode(state);
    const guest = !loginSelectors.isLoggedIn(state);
    dispatch(actionCreators.fetchBegan({ mode }));
    return getFetcher(mode)({
      pageSize: PAGE_SIZE,
      moderationLevel,
      sortVersion,
      region,
      locale,
      guest,
      utm,
    })
      .then((data) => {
        // HOTFIX
        // ToDo: https://tango-me.atlassian.net/browse/WEB-5413
        if (
          data &&
          data.categoryInfoList &&
          data.categoryInfoList.find(
            (item) => item.tag === PersonalFeedType.RECOMMENDED
          )
        ) {
          dispatch(
            actionCreators.fetchCompleted({
              mode: PersonalFeedType.RECOMMENDED,
              data: { ...data, timestamp: currentTimeMillis() },
              replaceAll: false,
            })
          );
        }

        return dispatch(
          actionCreators.fetchCompleted({
            mode,
            data: { ...data, timestamp: currentTimeMillis() },
            replaceAll: true,
          })
        );
      })
      .catch((error) =>
        dispatch(
          actionCreators.fetchFailed({
            mode,
            error,
            removeAll: false,
          })
        )
      );
  };
