import React, { forwardRef, memo } from "react";
import { CustomContentProps, SnackbarContent } from "notistack";
import { ReactComponent as ClipboardIcon } from "img/ic_clipboard.svg";
import styles from "./ClipboardToast.scss";

const ClipboardToast = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ message, className, style }, ref) => (
    <SnackbarContent ref={ref} className={className} style={style}>
      <div className={styles.root}>
        <ClipboardIcon className={styles.icon} />
        {message}
      </div>
    </SnackbarContent>
  )
);

ClipboardToast.displayName = "ClipboardToast";

export default memo(ClipboardToast);
