import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { CodeRecipientMessage } from "src/features/signin/components/CodeRecipientMessage";
import {
  BottomScreenType,
  Breakpoints,
} from "src/features/signin/imports/enums";
import { useBreakpointPrecise } from "src/features/signin/imports/ui";
import LoginModalDisclaimer from "src/features/signin/modal/LoginModalDisclaimer";
import LoginModalHeader from "src/features/signin/modal/LoginModalHeader";
import CodeInputPanel from "src/features/signin/modal/login/components/CodeInputPanel";
import { VoidCallback } from "src/types/common";

export interface VerificationCodeStepParams {
  onLoginSuccess?: VoidCallback;
  screenType?: BottomScreenType;
}

const VerificationCodeStep: FC<VerificationCodeStepParams> = ({
  screenType,
  onLoginSuccess,
}) => {
  const breakpoint = useBreakpointPrecise();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;

  return (
    <>
      <LoginModalHeader
        description={<CodeRecipientMessage />}
        title={
          <FormattedMessage
            id="modal.login.phone.verification.code.title"
            defaultMessage="Enter verification code"
          />
        }
      />

      <CodeInputPanel
        screenType={screenType}
        isNested
        onLoginSuccess={onLoginSuccess}
      />
      {!isMobile && <LoginModalDisclaimer />}
    </>
  );
};

export default VerificationCodeStep;
