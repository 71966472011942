import { DeviceType } from "src/enums";

export const activateClipboard = (
  textToCopy: string,
  deviceType: DeviceType
) => {
  const textArea = document.createElement("textArea") as HTMLTextAreaElement;
  textArea.id = "tempClipboardElement";
  textArea.readOnly = true;
  textArea.contentEditable = "true";
  textArea.value = textToCopy;
  document.body.appendChild(textArea);

  if (deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD) {
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }
};

export function copyAndRemove() {
  const el = document.querySelector("#tempClipboardElement");
  if (el) {
    document.execCommand("Copy");
    document.body.removeChild(el);
  }
}
