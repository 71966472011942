import { useCallback, MouseEvent, TouchEvent } from "react";

type ClickCb<T> = (event: T) => void;

const useExclusiveClickHandler = <T extends MouseEvent | TouchEvent>(
  onClick?: ClickCb<T>
) =>
  useCallback<ClickCb<T>>(
    (event) => {
      if (onClick) {
        onClick(event);
      }
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }
    },
    [onClick]
  );

export default useExclusiveClickHandler;
