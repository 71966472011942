export enum BroadcastExternalStatus {
  ACTIVE = "BROADCAST_EXTERNAL_STATUS_ACTIVE",
  FAILED = "BROADCAST_EXTERNAL_STATUS_FAILED",
  INITIALIZED = "BROADCAST_EXTERNAL_STATUS_INITIALIZED",
  SUSPENDED = "BROADCAST_EXTERNAL_STATUS_SUSPENDED",
  TERMINATED = "BROADCAST_EXTERNAL_STATUS_TERMINATED",
}

export enum BroadcastExternalError {
  STREAM_KEY_ERROR_UNKNOWN = "BROADCAST_EXTERNAL_STREAM_KEY_ERROR_UNKNOWN",
}

export enum StreamType {
  PRIVATE = 2,
  PUBLIC = 1,
}

export enum InitStreamResponseCode {
  BAD_REQUEST = 2,
  EXISTING_LIVE_STREAM = 4,
  FAILED = 3,
  NOT_FOUND = 5,
  SUCCESS = 1,
  UNAUTHORIZED = 6,
  UPGRADE_DISABLED = 7,
}

export enum StreamKindExternal {
  OBS = "OBS",
}
