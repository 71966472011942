import emptyFunction from "fbjs/lib/emptyFunction";
import {
  PERSONAL_OFFERS_SOURCE,
  PURCHASE_ORIGIN,
  PURCHASE_SOURCE,
  UI_COMPONENT,
} from "@analytics/enums";
import { CASHIER, REFILL } from "enums/cashier";
import { ModalType } from "src/enums";
import { getEnabledPaymentProviders } from "src/environment";
import { PricePoint } from "src/types/personalOffer";
import {
  openCashierV2DrawerBottomScreen,
  openCheckoutBottomScreen,
  openRefillDrawerBottomScreen,
  openRefillV2DrawerBottomScreen,
} from "state/actionCreators/bottomScreen";
import {
  openBuyCoinsModal,
  openCannotSendGiftModal,
  openCashierModal,
  openCheckoutModal,
  openRefillModal,
} from "state/actionCreators/modal";

interface OpenBuyCoinsProps {
  analyticsParams?: Record<string, unknown>;
  isCashierV2DesktopEnabled: boolean;
  isCashierV2MobileEnabled: boolean;
  isMobile: boolean;
  isPaymentFlowV2Enabled: boolean;
  isRefillV2DesktopEnabled: boolean;
  isRefillV2MobileEnabled: boolean;
  modalType?: ModalType;
  offer?: PricePoint;
  onDismiss?: VoidFunction;
  onError?: VoidFunction;
  onRefillV2BottomScreenClose?: VoidFunction;
  onSuccess: VoidFunction;
  previousUiComponent: UI_COMPONENT;
  purchaseOrigin?: PERSONAL_OFFERS_SOURCE | PURCHASE_ORIGIN;
  purchaseSource?: PURCHASE_SOURCE;
  shouldDismissOnBack?: boolean;
  streamerId?: string;
  uiComponent: UI_COMPONENT;
  viewType: string;
}

export const openBuyCoins = ({
  purchaseSource,
  purchaseOrigin,
  offer,
  streamerId,
  isMobile,
  viewType,
  uiComponent,
  previousUiComponent,
  onSuccess,
  onError,
  shouldDismissOnBack = false,
  analyticsParams = {},
  onDismiss = emptyFunction,
  modalType,
  isRefillV2MobileEnabled,
  onRefillV2BottomScreenClose,
  isRefillV2DesktopEnabled,
  isCashierV2MobileEnabled,
  isCashierV2DesktopEnabled,
  isPaymentFlowV2Enabled,
}: OpenBuyCoinsProps) => {
  const providers = getEnabledPaymentProviders();
  if (providers.length === 0) {
    return openCannotSendGiftModal();
  }

  const modalData = {
    purchaseSource,
    offer,
    viewType,
    uiComponent,
    previousUiComponent,
    streamerId,
    onSuccess,
    onError,
    shouldDismissOnBack,
    purchaseOrigin,
    analyticsParams,
    onDismiss,
  };

  if (isMobile) {
    if (!offer && viewType === CASHIER && isCashierV2MobileEnabled) {
      return openCashierV2DrawerBottomScreen({
        screenData: {
          ...modalData,
          onClose: onRefillV2BottomScreenClose,
          onOutsideClickDismiss: onRefillV2BottomScreenClose,
        },
      });
    }

    if (!offer && viewType === REFILL && isRefillV2MobileEnabled) {
      return openRefillV2DrawerBottomScreen({
        screenData: {
          ...modalData,
          onClose: onRefillV2BottomScreenClose,
          onOutsideClickDismiss: onRefillV2BottomScreenClose,
        },
      });
    }

    if (offer && isPaymentFlowV2Enabled) {
      return openCheckoutBottomScreen({
        screenData: modalData,
      });
    }

    return openRefillDrawerBottomScreen({
      screenData: modalData,
    });
  }

  const modalParams = {
    ...modalData,
    modalType,
  };

  if (!offer && viewType === CASHIER && isCashierV2DesktopEnabled) {
    return openCashierModal(modalParams);
  }

  if (!offer && viewType === REFILL && isRefillV2DesktopEnabled) {
    return openRefillModal(modalParams);
  }

  if (offer && isPaymentFlowV2Enabled) {
    return openCheckoutModal(modalParams);
  }

  // TODO: https://tango-me.atlassian.net/browse/WEB-5885
  // @ts-ignore
  return openBuyCoinsModal(modalParams);
};
