import React, { useCallback } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { useDispatch } from "react-redux";
import { MessageDescriptor } from "@formatjs/intl";
import classnames from "classnames";
import { Breakpoints, LoginProvider } from "src/features/signin/imports/enums";
import {
  ComponentWithClassName,
  VoidCallback,
} from "src/features/signin/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
} from "src/features/signin/imports/ui";
import { LoginTypeButton } from "src/features/signin/modal/login/components/ProviderButton/types";
import { loginWithProvider } from "src/features/signin/state/flows/login";
import { useBreakpointPrecise } from "src/ui/hooks/useBreakpoint";
import { sharedMessages } from "src/ui/scenes/homePage/sharedMessages";
import { ReactComponent as AppleIcon } from "img/login/sign-up-apple_32.svg";
import { ReactComponent as FacebookIcon } from "img/login/sign-up-facebook_32.svg";
import { ReactComponent as GoogleIcon } from "img/login/sign-up-google_32.svg";
import { ReactComponent as PhoneIcon } from "img/login/sign-up-phone_32.svg";
import styles from "src/features/signin/modal/LoginModalContent.scss";

const ICONS: Record<
  LoginProvider,
  React.SFC<React.SVGProps<SVGSVGElement>> | undefined
> = {
  [LoginProvider.APPLE]: AppleIcon,
  [LoginProvider.GOOGLE]: GoogleIcon,
  [LoginProvider.FACEBOOK]: FacebookIcon,
  [LoginProvider.TANGO_PHONE_NUMBER]: PhoneIcon,
  [LoginProvider.TANGO_DISPOSABLE_TOKEN]: undefined,
};

const buttonMessages = defineMessages({
  [LoginProvider.TANGO_DISPOSABLE_TOKEN]: {
    id: "modal.login.button.phone",
    defaultMessage: "Continue with phone",
  },
  [LoginProvider.TANGO_PHONE_NUMBER]: {
    id: "modal.login.button.phone",
    defaultMessage: "Continue with phone",
  },
  [LoginProvider.FACEBOOK]: {
    id: "modal.login.button.facebook",
    defaultMessage: "Continue with Facebook",
  },
  [LoginProvider.GOOGLE]: {
    id: "modal.login.button.google",
    defaultMessage: "Continue with Google",
  },
  [LoginProvider.APPLE]: {
    id: "modal.login.button.apple",
    defaultMessage: "Continue with Apple",
  },
});

export interface ProviderButtonProps {
  buttonText?: MessageDescriptor;
  buttonVariant?: ButtonVariant.PRIMARY | ButtonVariant.SECONDARY;
  loginTypeButtonVariant?: ButtonVariant;
  onClick?: {
    (callback: VoidCallback): void;
    (provider: LoginProvider): void;
  };
  onLoginSuccess?: VoidCallback;
  provider: LoginProvider;
  type?: LoginTypeButton;
}

const ProviderButton: ComponentWithClassName<ProviderButtonProps> = ({
  provider,
  onClick,
  buttonVariant = ButtonVariant.SECONDARY,
  type = LoginTypeButton.DEFAULT,
  loginTypeButtonVariant = ButtonVariant.PRIMARY_ON_SHADER,
  className,
  buttonText,
  onLoginSuccess,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const isApple = provider === LoginProvider.APPLE;

  const IconComponent = ICONS[provider];

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(provider);
    } else {
      // TODO: move login.js to TypeScript in https://tango-me.atlassian.net/browse/WEB-6884
      // @ts-ignore
      dispatch(loginWithProvider({ provider, onLoginSuccess }));
    }
  }, [dispatch, provider, onClick, onLoginSuccess]);

  if (!provider) {
    return null;
  }

  if (type === LoginTypeButton.HOME_BIG) {
    return (
      <Button
        variant={loginTypeButtonVariant}
        size={isDesktop ? ButtonSize.EXTRABIG_64 : ButtonSize.BIG_48}
        leftIcon={IconComponent}
        className={classnames(
          styles.signInMainButton,
          styles[breakpoint],
          className
        )}
        data-testid={provider}
        onClick={handleOnClick}
      >
        <Typography
          type={
            isDesktop ? TYPOGRAPHY_TYPE.HEADLINE1 : TYPOGRAPHY_TYPE.HEADLINE4
          }
        >
          <FormattedMessage
            {...(buttonText ||
              (isApple
                ? sharedMessages.signInWithApple
                : sharedMessages.signInWithGoogle))}
          />
        </Typography>
      </Button>
    );
  } else if (type === LoginTypeButton.HOME_CIRCLE) {
    return (
      <Button
        variant={loginTypeButtonVariant}
        size={ButtonSize.CIRCLE_EXTRABIG_56}
        className={classnames(styles.iconButton, className)}
        data-testid={provider}
        onClick={handleOnClick}
      >
        {IconComponent && <IconComponent />}
      </Button>
    );
  }

  return (
    <Button
      size={ButtonSize.BIG_48}
      variant={buttonVariant}
      className={styles.button}
      onClick={handleOnClick}
      data-testid={provider}
      leftIcon={ICONS[provider]}
    >
      <FormattedMessage {...buttonMessages[provider]} />
    </Button>
  );
};

export default ProviderButton;
