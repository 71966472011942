import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { differenceInSeconds, differenceInYears } from "date-fns";
import countries from "i18n-iso-countries";
import { getIntercomAppId } from "environment";
import { CUSTOMER_SUPPORT_LINK } from "src/constants";
import { VipName } from "src/enums";
import { RootState } from "state/delegate";
import loadAccountTags from "state/flows/loadAccountTags";
import { refreshCoinsTotal } from "state/flows/loadBalances";
import {
  intercomSelectors,
  loginSelectors,
  userSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/intercom";
import { useHistoryChange } from "ui/hooks/useHistoryChange";
import useMyProfile from "ui/hooks/useMyProfile";
import useVipInfo from "ui/hooks/useVipInfo";

const vipStatusToIntercomString = {
  [VipName.NONE]: "NONE",
  [VipName.BRONZE]: "BRONZE",
  [VipName.SILVER]: "SILVER",
  [VipName.GOLD]: "GOLD",
  [VipName.ELITE]: "PLATINUM",
  [VipName.DIAMOND]: "DIAMOND",
  [VipName.ROYAL]: "ROYAL",
  [VipName.BLACK_DIAMOND]: "BLACK_DIAMOND",
  [VipName.LEGEND]: "LEGEND",
  [VipName.MEMBER]: "MEMBER",
  [VipName.ROOKIE]: "ROOKIE",
} satisfies Record<VipName, string>;

const getInternalSupporterLinkToProfile = (accountId: string) =>
  `https://internal.tangome.cloud/internalsupporter/api/profileRedirect/${accountId}`;

const dataSelector = (state: RootState) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
  coinsBalance: userSelectors.getCoinsBalance(state),
  coinsTotal: userSelectors.getCoinsTotal(state),
  pointsBalance: userSelectors.getPointsBalance(state),
  accountTags: userSelectors.getAccountTags(state),
});

const intercomDataSelector = (state: RootState) => ({
  updateTs: intercomSelectors.getUpdateTs(state),
  isBooted: intercomSelectors.getIsBooted(state),
});

export default () => {
  const dispatch = useDispatch();
  const { accountId, basicProfile, profileDetails, followStats, liveStats } =
    useMyProfile(false);
  const { isLoggedIn, coinsBalance, coinsTotal, pointsBalance, accountTags } =
    useSelector(dataSelector, shallowEqual);
  const { updateTs, isBooted } = useSelector(
    intercomDataSelector,
    shallowEqual
  );
  const { vipName } = useVipInfo({ vipLevel: liveStats?.vipStatus });
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    dispatch(refreshCoinsTotal());
    dispatch(loadAccountTags());
  }, [dispatch, isLoggedIn]);

  useHistoryChange(() => {
    window.Intercom("hide");
  });

  return useCallback(() => {
    if (!getIntercomAppId() || !isLoggedIn || !isBooted) {
      window.open(CUSTOMER_SUPPORT_LINK, "_blank", "noopener");
      return;
    }

    if (!updateTs || differenceInSeconds(new Date(), new Date(updateTs)) > 90) {
      window.Intercom("update", {
        Guest: false,
        Sex: basicProfile?.gender,
        "Date of birthday": profileDetails?.birthday || "",
        Followers: followStats?.followersCount || 0,
        Followings: followStats?.followingCount || 0,
        "Diamonds Total": liveStats?.points || 0,
        "Diamonds ATM": pointsBalance,
        VIP: vipStatusToIntercomString[vipName],
        name: `${basicProfile?.firstName || ""} ${
          basicProfile?.lastName || ""
        }`.trim(),
        Coins: coinsBalance,
        "Coins total": coinsTotal,
        Age: profileDetails?.birthday
          ? `${differenceInYears(
              new Date(),
              new Date(profileDetails.birthday)
            )}`
          : "",
        "Country Tango": profileDetails?.iso2CountryCode
          ? countries.getName(profileDetails.iso2CountryCode, "en")
          : "",
        user_id: accountId,
        link: getInternalSupporterLinkToProfile(accountId),
        tag_tango: accountTags.join(", "),
      });
      dispatch(actionCreators.setIntercomTimestamp(Date.now()));
    }
    window.Intercom("show");
  }, [
    updateTs,
    isBooted,
    accountId,
    basicProfile,
    profileDetails,
    liveStats,
    followStats,
    coinsBalance,
    coinsTotal,
    isLoggedIn,
    pointsBalance,
    accountTags,
    vipName,
    dispatch,
  ]);
};
