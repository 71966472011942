import invariant from "fbjs/lib/invariant";
import * as notificationsPermission from "enums/notificationsPermission";
export const allNotificationsPermissions = Object.values(notificationsPermission).filter(x => typeof x === 'string' || typeof x === 'number');
/* eslint-disable prefer-template */
if (process.env.NODE_ENV === "development") {
  import("lodash.uniq").then(({ default: uniq }) => 
    invariant(uniq(allNotificationsPermissions).length === allNotificationsPermissions.length, "notificationsPermission enum should contain unique values only!"));
}
export const isValidNotificationsPermission = x => allNotificationsPermissions.includes(x);
export const guardNotificationsPermission = x => {
  invariant(isValidNotificationsPermission(x), JSON.stringify(x) + " is not a valid notificationsPermission");
  return true;
};
export const validateMappingToNotificationsPermission = map => {
  if (process.env.NODE_ENV === "development") {
    allNotificationsPermissions.forEach(x => {
      invariant(map[x] !== undefined, JSON.stringify(x) + " of notificationsPermission is not mapped!");
   });
  }
};