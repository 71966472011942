import type { Dispatch, MiddlewareAPI } from "redux";
import { RootState } from "chat/imports/state";
import { getPremiumMessageDetails } from "chat/premiumMessage/api/premiumMessageApi";
import { premiumMessageSelectors } from "chat/premiumMessage/state/selectors";
import { ContentStatus, DetailsResponseCode } from "chat/premiumMessage/types";
import { updateUnlockDetails } from "./updateUnlockDetails";

export const processUnlockMessageAcmeEvent = async ({
  getState,
  dispatch,
}: MiddlewareAPI<Dispatch, RootState>) => {
  const state = getState();

  const details = premiumMessageSelectors.getDetails(state);

  const lockedMessageIds = Object.values(details)
    .filter(
      (detail) =>
        detail.status === ContentStatus.LOCKED ||
        detail.status === ContentStatus.PENDING
    )
    .map((detail) => detail.messageId);

  if (lockedMessageIds.length === 0) {
    return;
  }

  try {
    const updatedLockedDetails =
      await getPremiumMessageDetails(lockedMessageIds);

    if (
      updatedLockedDetails.responseCode !== DetailsResponseCode.DETAILS_SUCCESS
    ) {
      return;
    }

    const unlockedDetails = updatedLockedDetails.details.filter(
      (detail) => detail.status === ContentStatus.UNLOCKED
    );

    updateUnlockDetails(details, unlockedDetails, dispatch);
  } catch (error) {}
};
