export const GIFTING_RESULT_SUCCESS = 1;
export const GIFTING_RESULT_ERROR_NETWORK = -1;
export const GIFTING_RESULT_ERROR_UNKNOWN = -2;
export const GIFTING_RESULT_INSUFFICIENT_BALANCE_LOCAL = -3;
export const GIFTING_RESULT_INSUFFICIENT_BALANCE_SERVER = -4;
export const GIFTING_RESULT_ERROR_SERVER = -5;

export const mapErrorToGiftingResult = (error) => {
  if (!error) {
    return GIFTING_RESULT_ERROR_UNKNOWN;
  }
  const { status } = error;
  switch (status) {
    case 0:
      return GIFTING_RESULT_ERROR_NETWORK;
    case 402:
      return GIFTING_RESULT_INSUFFICIENT_BALANCE_SERVER;
  }
  return GIFTING_RESULT_ERROR_SERVER;
};
