import { createSlice } from "@reduxjs/toolkit";
import { AppealState } from "src/features/appeal/AppealPage/state/appealTypes";
import { checkAppealAction } from "src/features/appeal/AppealPage/state/checkAppealAction";
import { sendAppealAction } from "src/features/appeal/AppealPage/state/sendAppealAction";
import { AppealError } from "src/features/appeal/AppealPage/types";

const initialState: AppealState = {
  error: null,
  loading: false,
  status: null,
  tokenData: null,
};

const slice = createSlice({
  name: "appeal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkAppealAction.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(checkAppealAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
        state.tokenData = payload.tokenData;
      })
      .addCase(checkAppealAction.rejected, (state, { payload }) => {
        state.error = payload?.message || AppealError.SOMETHING_WENT_WRONG;
        state.loading = false;
      })
      .addCase(sendAppealAction.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(sendAppealAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
      })
      .addCase(sendAppealAction.rejected, (state, { payload }) => {
        state.error = payload?.message || AppealError.SOMETHING_WENT_WRONG;
        state.loading = false;
      });
  },
});

export const appealSliceReducer = slice.reducer;
