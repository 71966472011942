import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  currenciesSelectors,
  loginSelectors,
  taxSelectors,
} from "state/selectors";
import { getTaxes } from "state/actionCreators/taxes";
import { fetchSalesTaxV2 } from "api/taxes";
import { Currency } from "src/enums";

const MAX_RETRY_COUNT = 3;
const RETRY_DELAY = 2000;

interface UseLoadTaxesV2Params {
  marketOfferId: string;
  enabled?: boolean;
  currency?: Currency;
}

export const useLoadTaxesV2 = ({
  marketOfferId,
  enabled,
  currency: preferredCurrency,
}: UseLoadTaxesV2Params): number => {
  const dispatch = useDispatch();
  const userCurrency = useSelector(currenciesSelectors.getCurrentCurrency);
  const currency = preferredCurrency || userCurrency;
  const [tax, setTax] = useState(0);

  useEffect(() => {
    let mounted = true;
    if (currency && marketOfferId && enabled) {
      fetchSalesTaxV2(currency, marketOfferId).then(({ taxPercentage }) => {
        if (mounted) {
          setTax(taxPercentage);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [enabled, currency, dispatch, marketOfferId]);

  return tax;
};

export default () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const currency = useSelector(currenciesSelectors.getCurrentCurrency);
  const retryCount = useRef(0);
  const { loading, error } = useSelector(taxSelectors.meta, shallowEqual);

  useEffect(() => {
    if (currency && isLoggedIn) {
      dispatch(getTaxes({ currency }));
    }
  }, [currency, isLoggedIn]);

  useEffect(() => {
    let timerId: number;
    if (!error && !loading) {
      retryCount.current = 0;
      return;
    }
    if (
      error &&
      currency &&
      isLoggedIn &&
      !loading &&
      retryCount.current < MAX_RETRY_COUNT
    ) {
      timerId = window.setTimeout(() => {
        dispatch(getTaxes({ currency }));
        retryCount.current += 1;
      }, RETRY_DELAY);
    }
    return () => {
      if (timerId) {
        window.clearTimeout(timerId);
      }
    };
  }, [error]);
};
