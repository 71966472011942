import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hasValidAccount } from "state/selectors";
import { externalReferralRegistration } from "api/referrals";

export default () => {
  const query = new URLSearchParams(window.location.search);
  const hasValidAccountState = useSelector(hasValidAccount);
  const [guestRegistrationWasRequired, setGuestRegistrationWasRequired] =
    useState(false);
  useEffect(() => {
    if (!hasValidAccountState) {
      if (!guestRegistrationWasRequired) {
        setGuestRegistrationWasRequired(true);
      }
      return;
    }
    if (guestRegistrationWasRequired) {
      const referralId = query.get("referral_id");
      const referralSystem = query.get("referral_system");
      if (referralId && referralSystem) {
        externalReferralRegistration({
          referralId,
          referralSystem,
        });
      }
    }
  }, [hasValidAccountState, guestRegistrationWasRequired]);
};
