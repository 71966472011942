import { createReducer } from "@reduxjs/toolkit";
import {
  addAsyncCasesToBuilderV2,
  AsyncState,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import { loadBlackList } from "state/actionCreators/guardian";

export type GuardianState = AsyncState<string[] | string>;

const initialState = {
  data: [],
  meta: initialFetcherStateMeta,
};

export default createReducer<GuardianState>(initialState, (builder) => {
  addAsyncCasesToBuilderV2({
    builder,
    action: loadBlackList,
    prepareData: (oldData, newData) => newData,
    initialData: initialState.data,
  });
});
