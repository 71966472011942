import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";

const baseUrl = () => `${getProxycadorHost()}/proxycador/api/public/v1`;

const fetchWithBaseUrl = (url, init) => fetch(`${baseUrl()}${url}`, init);

export const getNftAuctionsList = (ids = []) =>
  fetchWithBaseUrl("/auction/auctions/list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ids }),
  }).then((resp) => resp.json());

export const getNftLotsList = ({ auctionId, params }) =>
  fetchWithBaseUrl("/auction/lots/list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ auctionId, params }),
  }).then((resp) => resp.json());

export const getLotListByTag = ({ params, signal }) =>
  fetchWithBaseUrl("/auction/lots/lotsByTag", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ params }),
    signal,
  }).then((resp) => resp.json());

export const searchNftLots = ({ auctionId, searchTerm }) =>
  fetchWithBaseUrl("/auction/lots/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ auctionId, searchTerm }),
  }).then((resp) => resp.json());

export const bidNftLot = ({ lotId, amount }) =>
  fetchWithBaseUrl("/auction/lots/bids/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ lotId, amount }),
  }).then((resp) => resp.json());

export const getAuctionProfile = ({ accountId, includeNft = false }) =>
  fetchWithBaseUrl("/auction/profile", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ accountId, includeNft }),
  }).then((resp) => resp.json());

export const removeNFTCard = ({ lotId }) =>
  fetchWithBaseUrl(`/auction/lots/${lotId}/cancel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((resp) => resp.json());
