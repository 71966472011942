import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import { RootState } from "state/delegate";
import { getVipConfigs } from "state/flows/loadVipConfigs";
import { getAssetsForVipConfigs } from "state/flows/utils/assetConfig";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { vipConfigsSelectors } from "state/selectors";
import { VipConfigsState } from "state/tree/vipConfigs";

const actions = {
  fetchVipConfigs: createAsyncThunk<
    Data<VipConfigsState>,
    FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/vipConfigs/fetch",
    async (args, api) => {
      try {
        const vipConfigs = await getVipConfigs();
        const assetConfigs = await getAssetsForVipConfigs(vipConfigs);

        return vipConfigs.map((config) => ({
          ...config,
          assetConfig: assetConfigs.find(({ name }) => name === config.label)!,
        }));
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();
        const meta = vipConfigsSelectors.meta(state);

        return !meta.loading;
      },
    }
  ),
};

export default actions;
