import invariant from "fbjs/lib/invariant";
import * as giftRecipientType from "enums/giftRecipientType";
export const allGiftRecipientTypes = Object.values(giftRecipientType).filter(x => typeof x === 'string' || typeof x === 'number');
/* eslint-disable prefer-template */
if (process.env.NODE_ENV === "development") {
  import("lodash.uniq").then(({ default: uniq }) => 
    invariant(uniq(allGiftRecipientTypes).length === allGiftRecipientTypes.length, "giftRecipientType enum should contain unique values only!"));
}
export const isValidGiftRecipientType = x => allGiftRecipientTypes.includes(x);
export const guardGiftRecipientType = x => {
  invariant(isValidGiftRecipientType(x), JSON.stringify(x) + " is not a valid giftRecipientType");
  return true;
};
export const validateMappingToGiftRecipientType = map => {
  if (process.env.NODE_ENV === "development") {
    allGiftRecipientTypes.forEach(x => {
      invariant(map[x] !== undefined, JSON.stringify(x) + " of giftRecipientType is not mapped!");
   });
  }
};