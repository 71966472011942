export enum AutoLoginResult {
  FAILURE = "failure",
  SUCCESS = "success",
  UNKNOWN = "unknown",
}

export interface AutoLoginState {
  inProgress: boolean;
  isAutoLoginSession: boolean;
  result: AutoLoginResult;
}
