import React, { forwardRef, memo } from "react";
import classnames from "classnames";
import { CustomContentProps, SnackbarContent } from "notistack";
import { ReactComponent as IconPending } from "img/ic-info.svg";
import styles from "./common.scss";

const PendingToast = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ message, className, style }, ref) => (
    <SnackbarContent ref={ref} className={className} style={style}>
      <div className={styles.root} data-testid="toaster-payment-pending">
        <div className={classnames(styles.message, styles.pendingMessage)}>
          <IconPending className={styles.iconPending} />
          <p>{message}</p>
        </div>
      </div>
    </SnackbarContent>
  )
);

PendingToast.displayName = "PendingToast";

export default memo(PendingToast);
