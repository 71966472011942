import { createReducer } from "@reduxjs/toolkit";
import { DeviceType } from "src/enums";
import { DeviceInfoState } from "state/types";
import * as actionCreators from "../actionCreators/deviceInfo";

export const persistConfig = {
  whitelist: ["fingerprint"],
};

export { actionCreators };

const reducer = createReducer<DeviceInfoState>(
  {
    type: DeviceType.UNKNOWN,
    os: "",
    browserName: "",
    version: "",
    locale: "en",
    fingerprint: "",
    fingerprintingInProgress: false,
    webview: false,
  },
  (builder) => {
    builder
      .addCase(actionCreators.setLocale, (state, action) => {
        state.locale = action.payload;
      })
      .addCase(actionCreators.setWebview, (state, action) => {
        state.webview = action.payload;
      })
      .addCase(actionCreators.deviceTypeDetected, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(actionCreators.getFingerprint.pending, (state) => {
        state.fingerprintingInProgress = true;
      })
      .addCase(actionCreators.getFingerprint.fulfilled, (state, action) => {
        state.fingerprint = action.payload;
        state.fingerprintingInProgress = false;
      })
      .addCase(actionCreators.getFingerprint.rejected, (state) => {
        state.fingerprint = "";
        state.fingerprintingInProgress = false;
      });
  }
);

export default reducer;
