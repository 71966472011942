import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import {
  fetchAccountInfo,
  sendValidationSMS,
  unlinkSocialIdentifier,
  updateAccountInfo,
} from "src/features/profile/settings/api/profileApi";
import { ProfileSettingsState } from "src/features/profile/settings/state/profileSettingsSlice";
import {
  SendValidationSMSRequestParams,
  SendValidationSMSResponse,
  SendValidationSMSStatusType,
  TangoRegistrationResponseType,
  UpdateAccountInfoRequest,
} from "src/features/profile/settings/types";
import {
  ResponseCode,
  UnlinkSocialIdentifierRequestType,
  UnlinkSocialIdentifierResponseType,
} from "src/features/profile/settings/types/unlinkSocialIdentifier.type";
import { RootState } from "state/delegate";
import { Data } from "state/hor/addFetcherActionsToBuilder";
import { userSelectors } from "state/selectors";

let lastFetchTimestamp = 0;
const THROTTLE_TIME = 1000;

export const fetchProfileSettingsAction = createAsyncThunk<
  Data<ProfileSettingsState>
>(
  "lwc/profile/settings/fetch",
  async (_, api) => {
    try {
      const config = await fetchAccountInfo();

      if (config) {
        return config;
      }

      return api.rejectWithValue("NOT FOUND");
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition() {
      const hasToFetch = Date.now() - lastFetchTimestamp >= THROTTLE_TIME;

      if (hasToFetch) {
        lastFetchTimestamp = Date.now();
      }

      return hasToFetch;
    },
  }
);

export const updateAccountInfoAction = createAsyncThunk<
  TangoRegistrationResponseType,
  UpdateAccountInfoRequest
>("lwc/profile/settings/updateAccountInfo", async (data, api) => {
  try {
    return await updateAccountInfo(data);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const disconnectSocialIdentifier = createAsyncThunk<
  UnlinkSocialIdentifierResponseType,
  UnlinkSocialIdentifierRequestType,
  {
    rejectValue: UnlinkSocialIdentifierResponseType;
    state: RootState;
  }
>(
  "lwc/profile-settings/unlinkSocialIdentifier",
  async (payload, { rejectWithValue, getState }) => {
    const accountId = userSelectors.getMyAccountId(getState());

    const response = await unlinkSocialIdentifier({ ...payload, accountId });

    if (response.responseCode !== ResponseCode.ACCEPTED) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const sendValidationSMSAction = createAsyncThunk<
  SendValidationSMSResponse,
  SendValidationSMSRequestParams,
  { rejectValue: SendValidationSMSResponse }
>("lwc/profile/settings/sendValidationSMS", async (payload, thunkAPI) => {
  const response = await sendValidationSMS(payload);

  if (response.type !== SendValidationSMSStatusType.OK) {
    return thunkAPI.rejectWithValue(response);
  }

  return response;
});
