import {
  STREAM_MAX_EVENTS_TO_KEEP_DEFAULT,
  TANGO_ACCOUNT_ID,
} from "src/constants";
import { StreamEvents } from "src/enums";
import { reverse } from "src/utils/immutableArrayUtils";
import {
  GIFT_PLAYED,
  GIFT_SENT,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  VIEWER_SESSION_CLEAR_QUEUED_GIFT_EVENT,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";
import { getStreamId } from "state/tree/viewerSession/commonSelectors";
import createFakeGiftEventFromSentGiftAction from "./createFakeGiftEventFromSentGiftAction";

const initialState = {
  queue: [],
  processedEvents: [],
  playedEvents: [],
};

const rebuildQueue = (state, giftEvents) => {
  const newQueue = [...state.queue];
  let queueChanged = false;

  giftEvents.forEach((rawEvent) => {
    if (state.processedEvents.includes(rawEvent.id)) {
      return;
    }

    queueChanged = true;
    const {
      type,
      accountId,
      id,
      data: {
        giftId,
        songId = {},
        mediaGift = {},
        points,
        percent,
        withPoint,
        bonusPercentage,
        percentage,
      },
    } = rawEvent;
    const existingQueuedEventIndex = newQueue.findIndex(
      (existingEvent) =>
        existingEvent.type === type &&
        existingEvent.percent === percent &&
        existingEvent.points === points &&
        existingEvent.accountId === accountId &&
        existingEvent.giftId === giftId &&
        existingEvent.withPoint === withPoint &&
        existingEvent.songId.id === songId.id &&
        existingEvent.mediaGift.gfyId === mediaGift.gfyId
    );

    if (existingQueuedEventIndex >= 0) {
      const existingQueuedEvent = newQueue.splice(
        existingQueuedEventIndex,
        1
      )[0];
      newQueue.push({
        ...existingQueuedEvent,
        eventIds: [...existingQueuedEvent.eventIds, id],
        multiplier: existingQueuedEvent.multiplier + 1,
      });
    } else {
      newQueue.push({
        type,
        accountId,
        eventIds: [id],
        giftId,
        songId,
        mediaGift,
        multiplier: 1,
        points,
        percent,
        withPoint,
        bonusPercentage: bonusPercentage || percentage,
      });
    }
  });

  if (!queueChanged) {
    return state;
  }

  const processedEvents = [
    ...reverse(giftEvents).map((giftEvent) => giftEvent.id),
    ...state.processedEvents,
  ].slice(0, STREAM_MAX_EVENTS_TO_KEEP_DEFAULT);

  return {
    ...state,
    queue: newQueue,
    processedEvents,
  };
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      if (action.payload === getStreamId(context)) {
        return state;
      }
      return initialState;
    }
    case GIFT_SENT: {
      const event = createFakeGiftEventFromSentGiftAction(action);
      return rebuildQueue(state, [event]);
    }
    case GIFT_PLAYED: {
      return {
        ...state,
        playedEvents: [...state.playedEvents, action.payload.eventId],
      };
    }
    case VIEWER_SESSION_UPDATE:
    case LIVE_RICH_NOTIFICATION_RECEIVED:
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }
      const {
        payload: { entities: { events } = {}, eventIds },
        meta: { currentUserId },
      } = action;
      if (!events || !eventIds || !eventIds.length) {
        return state;
      }
      const giftEvents = Object.values(events).filter(
        (event) =>
          (event.type === StreamEvents.MULTI_BROADCAST_GIFT &&
            event.accountId === TANGO_ACCOUNT_ID &&
            event.data.percent &&
            event.data.points) ||
          (event.type === StreamEvents.GIFT &&
            event.data?.giftId &&
            event.accountId !== currentUserId)
      ); // skipping own gifts because they are preappended
      if (!giftEvents.length) {
        return state;
      }
      return rebuildQueue(state, giftEvents);
    }
    case VIEWER_SESSION_CLEAR_QUEUED_GIFT_EVENT: {
      const { payload: giftEvent } = action;
      const { queue } = state;
      if (!queue.length) {
        return state;
      }
      const indexOfMatch = queue.findIndex(
        (queueItem) => queueItem.eventIds[0] === giftEvent.eventIds[0]
      );
      if (indexOfMatch >= 0) {
        return {
          ...state,
          queue: [
            ...queue.slice(0, indexOfMatch),
            ...queue.slice(indexOfMatch + 1),
          ],
        };
      }
      return state;
    }
  }
  return state;
};

export const selectors = {
  getGiftsDisplayQueue: (state) => state.queue,
  getGiftToPlay: (state) => {
    const playedGiftEvents = selectors.getPlayedGiftEvents(state);

    return state.queue
      .flatMap(({ eventIds }) => eventIds)
      .filter((id) => !playedGiftEvents.includes(id))[0];
  },
  getPlayedGiftEvents: (state) => state.playedEvents,
  getGiftsByEventId: (state, eventId) =>
    state.queue.find(({ eventIds }) => eventIds.includes(eventId)),
};
