import { linkToBasePath } from "ui/navigation/links";

const createUnauthorizedErrorHandler = () => () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("persist:")) {
      localStorage.removeItem(key);
    }
  }, {});
  window.location.href = linkToBasePath;
};

export default createUnauthorizedErrorHandler;
