import { PlatformsTypes } from "@analytics/enums";
import fetch, { urlAndParams } from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { CashierPersonalOfferResponse as Offer } from "generated/proto/CashierPersonalOffer";
import { PersonalOffersConfig } from "src/types/personalOffer";
import { ShopOffer } from "ui/scenes/landingPage/types";

const CREDITS_SUBSCRIPTION_OFFER = "streamer-subscription-credits";

export interface UpgradeOffersResponse {
  offers: ShopOffer[];
}

export const fetchUpgradeOffers = ({
  marketOfferId,
  streamerId,
}: {
  marketOfferId: string;
  streamerId: string;
}): Promise<UpgradeOffersResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/subscriptions/upgrade`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        marketOfferId,
        maxSize: 1000,
        offset: 0,
        userId: streamerId,
        clientVersion: 4,
      }),
    }
  ).then((resp) => resp.json());

export interface StreamerPlan {
  level: number;
  name: string;
  perks: { perkDefaultValue: string; perkKey: string }[];
  prices: { price: number; selected: boolean; sku: string }[];
  sku: string;
}

export interface StreamerPlansResponse {
  plans: StreamerPlan[];
}

export const fetchStreamerPlans = ({
  streamerId,
  includeDisabled = false,
}: {
  includeDisabled?: boolean;
  streamerId: string;
}): Promise<StreamerPlansResponse> =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/subscription/plans`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ streamerId, includeDisabled }),
  }).then((resp) => resp.json());

export const fetchSubscriptionOffers = ({
  userId,
}: {
  userId: string;
}): Promise<ShopOffer[]> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/subscriptions`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        platforms: [CREDITS_SUBSCRIPTION_OFFER],
        maxSize: 100,
        offset: 0,
        userId,
        clientVersion: 4,
      }),
    }
  ).then((resp) => resp.json());

export const fetchPersonalSubscriptionOffers = async (
  streamerId: string
): Promise<PersonalOffersConfig> => {
  const resp = await fetch(
    urlAndParams(
      `${getProxycadorHost()}/personal-offers/client/offer/subscription`,
      {
        platform: PlatformsTypes.WEB,
        platformTypes: CREDITS_SUBSCRIPTION_OFFER,
        streamerId,
      }
    )
  );
  const buffer = await resp.arrayBuffer();

  return Offer.decode(new Uint8Array(buffer), undefined);
};

export const fetchPersonalUpgradeOffers = async (
  streamerId: string,
  marketOfferId: string
): Promise<PersonalOffersConfig> => {
  const resp = await fetch(
    urlAndParams(
      `${getProxycadorHost()}/personal-offers/client/offer/subscription/upgraded`,
      {
        platform: PlatformsTypes.WEB,
        platformTypes: CREDITS_SUBSCRIPTION_OFFER,
        streamerId,
        marketOfferId,
      }
    )
  );
  const buffer = await resp.arrayBuffer();

  return Offer.decode(new Uint8Array(buffer), undefined);
};
