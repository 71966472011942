import { useEffect, useState } from "react";
import intersectionObserver, {
  IntersectionObserverRef,
} from "src/utils/intersectionObserver";

export type Options = {
  initialValue?: boolean;
  updateOn?: boolean;
} & IntersectionObserverInit;

export default (
  ref: IntersectionObserverRef,
  options: Options = {
    rootMargin: "300px 0px 600px 0px",
  }
) => {
  const { initialValue = false, updateOn, ...observerOptions } = options;
  const [isInView, setInView] = useState(
    initialValue || !window.IntersectionObserver
  );

  useEffect(() => {
    const savedRef = ref.current;
    if (!savedRef) {
      return;
    }
    const observer = intersectionObserver((entries) => {
      const lastElement = entries[entries.length - 1];
      if (!lastElement) {
        return;
      }
      setInView(lastElement.isIntersecting);
    }, observerOptions);
    if (!observer) {
      return;
    }

    observer.observe(savedRef);

    return () => observer.unobserve(savedRef);
  }, [observerOptions, ref, updateOn]);

  return isInView;
};
