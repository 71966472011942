export const getMediaDimensionsStyle = (width: number, height: number) =>
  width > height
    ? {
        width: "var(--mediaMaxDimen)",
        height: `max(calc(var(--mediaMaxDimen) * ${height} / ${width}), var(--mediaMinDimen))`,
      }
    : {
        width: `max(calc(var(--mediaMaxDimen) * ${width} / ${height}), var(--mediaMinDimen))`,
        height: "var(--mediaMaxDimen)",
      };
