type CacheParams = {
  defaultVersionKey?: string;
  localStorageKey: string;
  value: string;
  versionKey: string;
};

export const withLocalStorageCache = ({
  localStorageKey,
  value,
  versionKey,
  defaultVersionKey,
}: CacheParams): string => {
  const cachedItem = localStorage.getItem(localStorageKey) || "";
  const [cachedValue, cachedVersionKey] = cachedItem.split(",");

  const isCacheEmpty = !cachedVersionKey;
  const isVersionMismatch = cachedVersionKey !== versionKey;
  const isDefaultVersion = versionKey === defaultVersionKey;

  const shouldInitializeCache = isCacheEmpty && isDefaultVersion;
  const shouldUpdateCache = isVersionMismatch && !isDefaultVersion;

  if (shouldInitializeCache || shouldUpdateCache) {
    const newCacheItem = `${value},${versionKey}`;
    localStorage.setItem(localStorageKey, newCacheItem);

    return value;
  }

  return cachedValue;
};
