import { enhancedFetch } from "src/features/signin/imports/api";
import { CLIENT_CAPABILITIES_ARRAY } from "src/features/signin/imports/constants";
import { getProxycadorHost } from "src/features/signin/imports/environment";

interface ValidationParams {
  deviceToken: string;
  limitedSessionCredentials?: {
    accountId: string;
    authToken: string;
  };
  locale: string;
  osVersion: string;
}

export const validation = ({
  deviceToken,
  limitedSessionCredentials,
  locale,
  osVersion,
}: ValidationParams) =>
  enhancedFetch(`${getProxycadorHost()}/foreground/validation/lwc`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      locale,
      osVersion,
      ...(!!limitedSessionCredentials && { limitedSessionCredentials }),
      ...(!!deviceToken && { deviceToken }),
      clientCapabilities: CLIENT_CAPABILITIES_ARRAY,
      clientVersion: GENERATED_APP_INFO.fullVersion,
    }),
  });
