import { VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT } from "state/actionTypes";
import { TANGO_ACCOUNT_ID } from "src/constants";
import { StreamEvents } from "src/enums";

const initialState = {
  gifGifts: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }
      const {
        payload: { entities: { events } = {}, eventIds },
        meta: { currentUserId },
      } = action;
      if (!events || !eventIds || !eventIds.length) {
        return state;
      }
      const giftEvents = Object.values(events).filter(
        (event) =>
          (event.type === StreamEvents.MULTI_BROADCAST_GIFT &&
            event.accountId === TANGO_ACCOUNT_ID &&
            event.data.percent &&
            event.data.points) ||
          (event.type === StreamEvents.GIFT &&
            event.data.giftId &&
            event.accountId !== currentUserId)
      ); // skipping own gifts because they are preappended
      if (!giftEvents.length) {
        return state;
      }
      const mediaGifts = giftEvents.reduce((acc, event) => {
        const {
          data: { giftId, mediaGift },
        } = event;
        if (mediaGift?.gfyId) {
          const combinedGiftId = `${giftId}-${mediaGift.gfyId}`;
          acc[combinedGiftId] = mediaGift;
          return acc;
        }
        return acc;
      }, {});
      if (Object.keys(mediaGifts).length === 0) {
        return state;
      }

      return {
        ...state,
        gifGifts: {
          ...state.gifGifts,
          ...mediaGifts,
        },
      };
    }
  }
  return state;
};

export const selectors = {
  getGifGifts: (state) => state.gifGifts,
};
