import { useRouteMatch } from "react-router-dom";
import {
  WEBVIEW_ONLY_ROUTES,
  WEBVIEW_QUERY_PARAM,
} from "src/core/webview/WebViewController/constants";
import useQuery from "ui/navigation/useQuery";

/*
Necessary to improve WebView detection by finding simpler and more stable
methods instead of relying on routes and query parameters.
*/
export const useWebViewDetector = () => {
  const query = useQuery();
  const isWebViewOnlyRoute = !!useRouteMatch(WEBVIEW_ONLY_ROUTES);
  const hasWebViewQueryParam = query.has(WEBVIEW_QUERY_PARAM);
  const isWebView = isWebViewOnlyRoute || hasWebViewQueryParam;

  return isWebView;
};
