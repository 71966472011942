import React, { FC, useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  RootState,
  autoLoginSelectors,
  calculateDeviceFingerprint,
  deviceInfoSelectors,
} from "src/features/autoLogin/imports/state";
import { performAutoLogin } from "src/features/autoLogin/state/flow";
import { checkAutoLoginQueryParams } from "src/features/autoLogin/utils/checkAutoLoginQueryParams";
import { parseAutoLoginQueryParams } from "src/features/autoLogin/utils/parseAutoLoginQueryParams";

const autoLoginGateSelector = (state: RootState) => ({
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  fingerprintingInProgress:
    deviceInfoSelectors.isDeviceFingerprintingInProgress(state),
  isAutoLoginFailure: autoLoginSelectors.getIsResultFailure(state),
  isAutoLoginSuccess: autoLoginSelectors.getIsResultSuccess(state),
  locale: deviceInfoSelectors.getDeviceLocale(state),
});

export const AutoLoginGate: FC = ({ children }) => {
  const hasExecuted = useRef(false);

  const dispatch = useDispatch();

  const {
    fingerprint,
    fingerprintingInProgress,
    isAutoLoginFailure,
    isAutoLoginSuccess,
    locale,
  } = useSelector(autoLoginGateSelector, shallowEqual);

  const { accountId, shouldFollowAutoLoginFlow, token } = useMemo(() => {
    const params = parseAutoLoginQueryParams(window.location.search);
    const shouldFollowAutoLoginFlow = checkAutoLoginQueryParams(params);

    return {
      accountId: params.accountId,
      shouldFollowAutoLoginFlow,
      token: params.token,
    };
  }, []);

  useEffect(() => {
    if (!shouldFollowAutoLoginFlow) {
      return;
    }

    if (!hasExecuted.current && accountId && fingerprint && locale && token) {
      hasExecuted.current = true;
      dispatch(performAutoLogin({ accountId, fingerprint, locale, token }));
    }
  }, [
    accountId,
    dispatch,
    fingerprint,
    locale,
    shouldFollowAutoLoginFlow,
    token,
  ]);

  /*
   * Handles when the user opens a direct link with auto-login query params in a
   * browser with clean storage data
   */
  useEffect(() => {
    if (!shouldFollowAutoLoginFlow) {
      return;
    }

    if (!fingerprint && !fingerprintingInProgress) {
      dispatch(calculateDeviceFingerprint());
    }
  }, [
    dispatch,
    fingerprint,
    fingerprintingInProgress,
    shouldFollowAutoLoginFlow,
  ]);

  if (!shouldFollowAutoLoginFlow || isAutoLoginFailure || isAutoLoginSuccess) {
    return <>{children}</>;
  }

  return null;
};
