import React from "react";
import { FormattedMessage } from "react-intl";
import { CodeRecipientMessage } from "src/features/signin/components/CodeRecipientMessage";
import Title from "src/features/signin/components/Title/Title";
import { BottomScreenType } from "src/features/signin/imports/enums";
import CodeInputPanel from "src/features/signin/modal/login/components/CodeInputPanel";
import styles from "./CodeVerification.scss";

export interface VerificationCodeStepParams {
  screenType?: BottomScreenType;
}

const CodeVerification = ({ screenType }: VerificationCodeStepParams) => (
  <>
    <Title
      description={<CodeRecipientMessage />}
      title={
        <FormattedMessage
          id="modal.login.phone.verification.code.title"
          defaultMessage="Enter verification code"
        />
      }
    />

    <CodeInputPanel className={styles.root} screenType={screenType} />
  </>
);

export default CodeVerification;
