import React, { FC, useMemo } from "react";
import { AnalyticsScreenDataContextProvider } from "chat/components/AnalyticsScreenDataContext";
import { EventFields, SCREEN_NAME } from "chat/imports/analytics";
import { ScreenViewReporter } from "chat/imports/components";

interface ConversationListTangoScreenViewAnalyticsProps {
  isChatRequestConversationList: boolean;
}

const tangoScreenViewConfig = {
  activeConversationList: {
    key: SCREEN_NAME.MAIN_CONVERSATIONS,
    name: SCREEN_NAME.MAIN_CONVERSATIONS,
  },
  chatRequestConversationList: {
    key: SCREEN_NAME.CHAT_REQUESTS,
    name: SCREEN_NAME.CHAT_REQUESTS,
  },
};

export const ConversationListTangoScreenViewAnalytics: FC<
  ConversationListTangoScreenViewAnalyticsProps
> = ({ children, isChatRequestConversationList }) => {
  const config = useMemo(
    () =>
      isChatRequestConversationList
        ? tangoScreenViewConfig.chatRequestConversationList
        : tangoScreenViewConfig.activeConversationList,
    [isChatRequestConversationList]
  );

  const screenData = useMemo(
    () => ({ [EventFields.TANGO_SCREEN]: config.name }),
    [config.name]
  );

  return (
    <>
      <ScreenViewReporter {...config} />
      <AnalyticsScreenDataContextProvider value={screenData}>
        {children}
      </AnalyticsScreenDataContextProvider>
    </>
  );
};
