import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchChatBlackList } from "api/blackList";
import { guardianSelectors } from "state/selectors";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { RootState } from "state/delegate";

export const loadBlackList = createAsyncThunk<
  string[],
  FetcherMetaV2,
  { state: RootState; rejectValue: string }
>(
  "lwc/blackListWords/fetch",
  async () => {
    const data = await fetchChatBlackList();
    return data.split("\n");
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta = guardianSelectors.meta(state);

      return !meta.loading && meta.stale;
    },
  }
);
