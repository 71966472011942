import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toastSelectors } from "state/selectors";
import { addToast, removeToast, removeToastsByType } from "state/tree/toast";

export const useToast = () => {
  const dispatch = useDispatch();
  const list = useSelector(toastSelectors.getToastList, shallowEqual);
  const notify = useCallback(
    (toast) => {
      dispatch(addToast(toast));
    },
    [dispatch]
  );

  const remove = useCallback(
    (id) => {
      dispatch(removeToast(id));
    },
    [dispatch]
  );

  const removeByType = useCallback(
    (type) => {
      dispatch(removeToastsByType(type));
    },
    [dispatch]
  );

  return { list, notify, remove, removeByType };
};
