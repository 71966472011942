import { enhancedFetch } from "src/features/signin/imports/api";
import { getProxycadorHost } from "src/features/signin/imports/environment";

export const performVisitorCheckIn = () =>
  enhancedFetch(
    `${getProxycadorHost()}/visitor-lobby/api/public/visitors/v1/check-in`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }
  ).then((resp) => resp.json());
