import { useState, useLayoutEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export default (rootNode) => {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const [
        {
          contentRect: { width, height },
        },
      ] = entries;
      // to prevent ResizeObserver loop limit exceeded in some ui tests
      window.requestAnimationFrame(() => setContainerSize({ width, height }));
    });
    resizeObserver.observe(rootNode.current);
    return () => resizeObserver.unobserve(rootNode.current);
  }, [rootNode.current]);
  return containerSize;
};
