import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";
import { LeaderBoardState } from "state/tree/leaderBoardLanding";

const { data: landingLeaderBoardByPath, meta: metaLandingLeaderBoardByPath } =
  generateAsyncSelectors<LeaderBoardState>();

export const selectors = {
  landingLeaderBoardByPath: (state: LeaderBoardState) =>
    landingLeaderBoardByPath(state),
  metaLandingLeaderBoardByPath: (state: LeaderBoardState) =>
    metaLandingLeaderBoardByPath(state),
};
