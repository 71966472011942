export enum HomePageV2NearbyRegion {
  AFRICA = "AFRICA",
  AMERICA = "AMERICA",
  ASIA = "ASIA",
  EUROPE = "EUROPE",
  OCEANIA = "OCEANIA",
}

export enum HomePageV2ScreenState {
  CREATORS = "creators",
  GAMES = "games",
  MAIN = "main",
  TOP_CREATORS = "top_creators",
}
