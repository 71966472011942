import mapKeys from "lodash.mapkeys";
import capitalize from "src/utils/capitalize";

export const initialFetcherState = {
  isLoadFailed: false,
  isLoadInProgress: false,
};

export const fetcherSelectors = {
  getIsLoadFailed: (state) => state.isLoadFailed,
  getIsLoadInProgress: (state) => state.isLoadInProgress,
};

export const createFetcherActions = ({
  beginFetchActionType,
  endFetchActionType,
  metaAdder = (x) => x,
  funcNamePrefix,
}) => {
  const result = {
    fetchBegan: (rest) => ({
      type: beginFetchActionType,
      meta: metaAdder(rest),
    }),
    fetchFailed: ({ error, removeAll = false, ...rest }) => ({
      type: endFetchActionType,
      meta: { ...metaAdder(rest), removeAll },
      error: true,
      payload: error,
    }),
    fetchCompleted: ({ data, replaceAll = false, ...rest }) => ({
      type: endFetchActionType,
      meta: { ...metaAdder(rest), replaceAll },
      payload: data,
    }),
  };
  if (!funcNamePrefix) {
    return result;
  }

  return mapKeys(result, (_, key) => `${funcNamePrefix}${capitalize(key)}`);
};

export default ({
    beginFetchActionType,
    endFetchActionType,
    initialData,
    extractData,
    mergeData,
  }) =>
  (reducer) =>
  (state = { ...initialData, ...initialFetcherState }, action) => {
    switch (action.type) {
      case beginFetchActionType: {
        return {
          ...state,
          isLoadInProgress: true,
        };
      }
      case endFetchActionType: {
        if (action.error) {
          const dataState = action.meta.removeAll ? initialData : {};

          return {
            ...state,
            ...dataState,
            isLoadFailed: true,
            isLoadInProgress: false,
          };
        }

        const newData = extractData(action.payload, action.meta, state);
        const dataState = action.meta.replaceAll
          ? newData
          : mergeData(state, newData, action.meta);

        return {
          ...state,
          ...dataState,
          isLoadFailed: false,
          isLoadInProgress: false,
        };
      }
    }

    return reducer(state, action) || state;
  };
