import { useCallback } from "react";
import {
  acceptBattleInvite,
  acceptInvite as acceptInviteAPI,
  rejectBattleInvite,
  rejectInvite as rejectInviteApi,
} from "api/stream";
import { MultiBroadcastInvite } from "state/tree/broadcast";

interface useLivePartyInvitesProps {
  accountId: string;
  invite: MultiBroadcastInvite;
  streamId: string;
}

const useLivePartyInvites = ({
  invite,
  accountId,
  streamId,
}: useLivePartyInvitesProps) => {
  const {
    hostAccountId,
    hostStreamId,
    isBattleRequest,
    requestId,
    sourceUuid,
    id: inviteId,
  } = invite;

  const acceptInvite = useCallback(async () => {
    if (isBattleRequest) {
      await acceptBattleInvite({
        id: inviteId,
        recipientStreamId: streamId,
        requestId,
        sourceUuid,
      });
    } else {
      await acceptInviteAPI({
        host: {
          accountId: hostAccountId,
          streamId: hostStreamId,
        },
        guest: {
          accountId,
          streamId,
        },
      });
    }
  }, [
    accountId,
    hostAccountId,
    hostStreamId,
    inviteId,
    isBattleRequest,
    requestId,
    sourceUuid,
    streamId,
  ]);

  const rejectInvite = useCallback(async () => {
    if (isBattleRequest) {
      await rejectBattleInvite(inviteId);
    } else {
      await rejectInviteApi({
        host: {
          accountId: hostAccountId,
          streamId: hostStreamId,
        },
        guestAccountId: accountId,
        requestId,
      });
    }
  }, [
    accountId,
    hostAccountId,
    hostStreamId,
    inviteId,
    isBattleRequest,
    requestId,
  ]);

  return { acceptInvite, rejectInvite };
};

export default useLivePartyInvites;
