import React, { SyntheticEvent, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { Typography } from "chat/imports/components";
import {
  Breakpoints,
  TYPOGRAPHY_TYPE,
  linkToMessageRequestMatch,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { updateChatMessageBlur } from "chat/state/actionCreators";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import styles from "./MediaBackdrop.scss";

interface MediaBackdropProps {
  conversationId: string;
  isBlurred: boolean;
  messageConfig: MessageConfig;
  messageId: number;
  onClick?: VoidFunction;
}

const MediaBackdrop = ({
  conversationId,
  messageId,
  messageConfig,
  isBlurred,
  onClick,
}: MediaBackdropProps) => {
  const dispatch = useDispatch();
  const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;
  const isChatRequest = useRouteMatch(linkToMessageRequestMatch);

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      onClick?.();

      dispatch(
        updateChatMessageBlur({ conversationId, messageId, isBlurred: false })
      );
    },
    [onClick, dispatch]
  );

  if (!isChatRequest || !isBlurred) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.root,
        getGroupMessageClassnames(styles, messageConfig)
      )}
      onClick={handleClick}
    >
      <Typography
        type={TYPOGRAPHY_TYPE.HEADLINE5}
        className={styles.text}
        as="div"
      >
        {isDesktop ? (
          <FormattedMessage
            id="click.to.see.media"
            defaultMessage="Click to see media"
          />
        ) : (
          <FormattedMessage
            id="tap_to_see_image"
            defaultMessage="Tap to see media"
          />
        )}
      </Typography>
    </div>
  );
};

export default MediaBackdrop;
