import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import setupDatadogRUM from "src/analytics/setupDatadogRUM";
import { getDatadogRUMEnabled } from "src/environment";
import { RootState } from "src/state/delegate";
import { userSelectors } from "src/state/selectors";

const selector = (state: RootState) => userSelectors.getMyAccountId(state);

const useDatadogRUMSetup = () => {
  const accountId = useSelector(selector);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!accountId || isInitialized) {
      return;
    }

    if (getDatadogRUMEnabled()) {
      setupDatadogRUM(accountId);
      setIsInitialized(true);
    }
  }, [accountId, isInitialized]);
};

export default useDatadogRUMSetup;
