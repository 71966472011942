import React from "react";
import classnames from "classnames";
import placeholderStyles from "./ConversationPlaceholder.scss";
import styles from "./ConversationPlaceholderSkeleton.scss";

const ConversationPlaceholderSkeleton = () => (
  <div
    className={classnames(styles.root, placeholderStyles.selectConversation)}
  >
    <div className={styles.circle} />
    <div className={styles.title} />
    <div className={styles.placeholder} />
  </div>
);

export default ConversationPlaceholderSkeleton;
