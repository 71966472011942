import { getLogAnalytics } from "environment";

const queue = [];

/* eslint-disable no-console */
window.appsFlyerLoaded = (AF) => {
  getLogAnalytics() && console.log("AF SDK loaded, consuming queue", queue);
  queue.forEach((x) => AF(...x));
};

export const callAF = (...args) => {
  if (window.AF) {
    getLogAnalytics() && console.log("AF SDK ready, logging", args);
    window.AF(...args);
  } else {
    getLogAnalytics() && console.log("AF SDK not ready, enqueuing", args);
    queue.push([...args]);
  }
};
