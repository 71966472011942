import React, { CSSProperties, useCallback, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { createLocationStateForChat } from "chat/analytics/createLocationStateForChat";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { StreamSourceChatType } from "chat/enums";
import { linkToFunctionIsNotAvailable } from "chat/imports/constants";
import { isMoveOneToOneFromChatEnabled } from "chat/imports/environment";
import { useIsOnStream } from "chat/imports/hooks";
import { RootState } from "chat/imports/state";
import { Nullable } from "chat/imports/types";
import { ensureHttps, makeLinkToLiveStream } from "chat/imports/utils";
import chatSelectors from "chat/state/selectors";
import { MessageMedia } from "chat/types";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import styles from "./LiveStreamMessage.scss";

interface LiveStreamMessageProps {
  conversationId: string;
  media: MessageMedia;
  messageConfig: MessageConfig;
}

const LiveStreamMessage: React.FC<LiveStreamMessageProps> = ({
  media,
  conversationId,
  messageConfig,
}) => {
  const { media_id, thumbnail_url } = media;
  const ref = useRef<Nullable<HTMLAnchorElement>>(null);
  const chatType = useSelector(
    useCallback(
      (state: RootState) => {
        const conversation = chatSelectors.getConversation(
          state,
          conversationId
        );
        if (conversation?.group_info) {
          return conversation.group_info.familyId
            ? StreamSourceChatType.LIVE_FAMILY
            : StreamSourceChatType.GROUP;
        }

        return StreamSourceChatType.SINGLE;
      },
      [conversationId]
    )
  );

  const isOnStream = useIsOnStream();
  const linkTarget = useMemo(() => {
    if (media_id && isMoveOneToOneFromChatEnabled()) {
      return makeLinkToLiveStream(
        media_id,
        createLocationStateForChat(chatType)
      );
    }

    return linkToFunctionIsNotAvailable;
  }, [media_id, chatType]);
  const testId = `live-stream-message-${media_id}`;

  return (
    <Link
      to={linkTarget}
      className={classnames(
        styles.root,
        getGroupMessageClassnames(styles, messageConfig)
      )}
      style={
        thumbnail_url
          ? ({
              "--thumbnail": `url(${ensureHttps(thumbnail_url)})`,
            } as CSSProperties)
          : undefined
      }
      data-testid={testId}
      replace={isOnStream}
      ref={ref}
    >
      <div className={styles.title}>
        <FormattedMessage
          id="home-page.header-hav.btn.watch-now"
          defaultMessage="Watch Now"
        />
      </div>
    </Link>
  );
};

export default LiveStreamMessage;
