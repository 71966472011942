import { getProxycadorHost } from "environment";
import fetch from "./utils/enhancedFetch";

export const externalReferralRegistration = ({ referralId, referralSystem }) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/registerReferralExternal?referralId=${referralId}&referralSystem=${referralSystem}`,
    {
      method: "POST",
      body: "",
    }
  );

export const fetchReferralsList = ({ pageToLoad, pageSize }) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/referralsList?page=${pageToLoad}&pageSize=${pageSize}&sortBy=id&order=desc`
  );
