import { createReducer } from "@reduxjs/toolkit";
import * as actionCreators from "../actionCreators/validation";
import { Nullable } from "src/types/common";

const initialState = {
  isNeedToValidate: true,
  lastValidationTime: null,
};

export interface ValidationState {
  isNeedToValidate: boolean;
  lastValidationTime: Nullable<number>;
}

export const selectors = {
  isNeedToValidate: (state: ValidationState) => state.isNeedToValidate,
  lastValidationTime: (state: ValidationState) => state.lastValidationTime,
};

const reducer = createReducer<ValidationState>(initialState, (builder) => {
  builder.addCase(actionCreators.validationEnd, (state) => {
    state.isNeedToValidate = false;
    state.lastValidationTime = Date.now();
  });
});

export default reducer;
