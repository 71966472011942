import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";

export const emitMakeBet = ({
  target,
  screenName,
  source,
  screenState,
  itemId,
  itemType,
  increaseAmount,
  totalAmount,
  balance,
}) => {
  emitEvent(EventNames.MAKE_BET, {
    [EventFields.TARGET]: target,
    [EventFields.SCREEN_NAME]: screenName,
    [EventFields.SOURCE]: source,
    [EventFields.SCREEN_STATE]: screenState,
    [EventFields.ITEM_ID]: itemId,
    [EventFields.ITEM_TYPE]: itemType,
    [EventFields.INCREASE_AMOUNT]: increaseAmount,
    [EventFields.TOTAL_AMOUNT]: totalAmount,
    [EventFields.BALANCE]: balance,
  });
};
