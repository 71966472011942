import React, { memo } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { useGiftFromMessage } from "chat/components/currentConversation/useGiftFromMessage";
import { addGiftToPlay } from "chat/giftAnimation/state/slice";
import { CoinIcon } from "chat/imports/assets";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { ensureHttps } from "chat/imports/utils";
import { StoredMessage } from "chat/state/reducer";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { MessageConfig } from "./Message";
import { useGiftMessage } from "./useGiftMessage";
import styles from "./GiftMessage.scss";

interface GiftMessageProps {
  message: StoredMessage;
  messageConfig: MessageConfig;
}

const GiftMessage: React.FC<GiftMessageProps> = ({
  message,
  messageConfig,
}) => {
  const dispatch = useDispatch();

  const { giftId, iconUrl } = useGiftMessage(message);
  const gift = useGiftFromMessage(message);

  const handleGiftClick = () => {
    dispatch(addGiftToPlay(giftId));
  };

  return (
    <div
      className={classnames(getGroupMessageClassnames(styles, messageConfig))}
    >
      <img
        src={ensureHttps(iconUrl)}
        className={styles.giftIcon}
        data-testid="gift-message"
        alt="gift"
        onClick={handleGiftClick}
      />
      {gift && (
        <div className={styles.info}>
          {!!gift.priceInCredit && (
            <div className={styles.price}>
              <CoinIcon />
              <Typography
                className={styles.priceValue}
                type={TYPOGRAPHY_TYPE.HEADLINE6}
              >
                {gift.priceInCredit}
              </Typography>
            </div>
          )}
          <Typography
            type={TYPOGRAPHY_TYPE.PARAGRAPH5}
            className={styles.giftName}
          >
            {gift.name}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default memo(GiftMessage);
