import React, { ElementType, useMemo } from "react";
import classnames from "classnames";
import { MAX_NOTIFICATIONS } from "src/constants";
import { ToastType } from "src/enums";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { useToast } from "ui/hooks/useToast";
import {
  ToastHeadline,
  ToastInvite,
  ToastRegular,
  ToastTimer,
} from "ui/toast/Toast";
import styles from "./ToastController.scss";

const toastComponentByType: {
  [key: string]: ElementType;
} = {
  [ToastType.REGULAR]: ToastRegular,
  [ToastType.TIMER]: ToastTimer,
  [ToastType.HEADLINE]: ToastHeadline,
  [ToastType.INVITE]: ToastInvite,
};

const ToastLimits: Record<string, number> = {
  [ToastType.INVITE]: 1,
};

export const ToastController = () => {
  const { list } = useToast();
  const breakpoint = useBreakpointPrecise();
  const listSlice = useMemo(() => {
    const filterCounts: Record<string, number> = {};
    const listFilter = list.filter((item) => {
      if (!filterCounts[item.type]) {
        filterCounts[item.type] = 1;
      } else {
        filterCounts[item.type] += 1;
      }

      return (
        !ToastLimits[item.type] ||
        ToastLimits[item.type] >= filterCounts[item.type]
      );
    });

    return listFilter?.slice(0, MAX_NOTIFICATIONS).reverse();
  }, [list]);

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      {listSlice?.length > 0 && (
        <div className={styles.list}>
          {listSlice.map(({ id, type, ...rest }) => {
            const ToastComponent = toastComponentByType[type];

            return <ToastComponent key={id} id={id} type={type} {...rest} />;
          })}
        </div>
      )}
    </div>
  );
};
