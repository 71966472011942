import { RegistrationSource } from "src/features/signin/imports/analytics";
import {
  deviceInfoSelectors,
  loginSelectors,
  retryAfterCaptcha,
  setCaptchaConfig,
} from "src/features/signin/imports/state";
import { CaptchaType } from "src/features/signin/imports/ui";
import { isLockedByCaptcha } from "src/features/signin/imports/utils";
import { loginWithProviderSelectors } from "src/features/signin/state/flows/login";
import { openLoginView } from "src/features/signin/state/flows/openLoginView";
import {
  askForSms,
  loginFailed,
  specifyPhoneNumberForPhoneLogin,
} from "src/features/signin/state/login/actionCreators";

export default (state, dispatch, { code, callback }) =>
  (error) => {
    if (isLockedByCaptcha(error)) {
      const { phoneNumber, allowOnlySmsValidation } =
        loginSelectors.getPhoneNumberAuthenticationState(state);
      const { fingerprint } = loginWithProviderSelectors(state);
      const isMobile = deviceInfoSelectors.isAnyMobileDevice(state);

      dispatch(
        setCaptchaConfig({
          type: CaptchaType.PHONE,
          phoneNumber,
          fingerprint,
        })
      );

      retryAfterCaptcha(error, specifyPhoneNumberForPhoneLogin(phoneNumber));

      if (allowOnlySmsValidation) {
        retryAfterCaptcha(error, askForSms());
      }

      if (isMobile) {
        retryAfterCaptcha(
          error,
          openLoginView({
            registrationSource: RegistrationSource.SELF_PROFILE_ICON,
            isShowPhoneNumberLoginFlow: true,
          })
        );
      }

      retryAfterCaptcha(error, callback({ code }));
    }

    dispatch(loginFailed(error));
    throw error;
  };
