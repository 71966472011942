import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import {
  LoginModalTitleType,
  ModalType,
} from "src/features/signin/imports/enums";
import {
  OVERLAY_CLICK_OR_ESCAPE_PRESS,
  RootState,
  dismissTopModal,
  getIsHomePageEnabled,
  getIsHomePageV2Enabled,
  getIsHomePageV3Enabled,
  loginSelectors,
  modalSelectors,
} from "src/features/signin/imports/state";
import { VoidCallback } from "src/features/signin/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  linkToBasePath,
  linkToHomePageV3,
} from "src/features/signin/imports/ui";
import { useUnmount } from "src/features/signin/imports/utils";
import LoginModalContent from "src/features/signin/modal/LoginModalContent";
import {
  loginSetSelectCountryStep,
  loginWaitingForCode,
  resetPhoneLogin,
} from "src/features/signin/state/login/actionCreators";
import { ReactComponent as ArrowIcon } from "img/arrow_24.svg";
import { ReactComponent as CloseIcon } from "img/close_24.svg";
import styles from "./LoginModal.scss";

const wasDismissedExternallySelector = (state: RootState) =>
  modalSelectors.getLastDismissedModalType(state) === ModalType.LOGIN &&
  modalSelectors.getLastModalDismissReason(state) ===
    OVERLAY_CLICK_OR_ESCAPE_PRESS &&
  !loginSelectors.isLoggedIn(state);

const selector = (state: RootState) => ({
  isHomePageEnabled: getIsHomePageEnabled(state),
  isHomePageV2Enabled: getIsHomePageV2Enabled(state),
  isHomePageV3Enabled: getIsHomePageV3Enabled(state),
  phoneNumberAuthenticationState:
    loginSelectors.getPhoneNumberAuthenticationState(state),
  isLoginFailed: loginSelectors.isLoginFailed(state),
});

interface LoginModalParams {
  modalData: {
    isBackButtonHidden: boolean;
    onDismiss?: VoidCallback;
    onLoginSuccess?: VoidCallback;
    onOutsideClickDismiss?: VoidCallback;
    redirectOnDismiss: string;
    registrationSource: RegistrationSource;
    title: LoginModalTitleType;
  };
}

const LoginModal: React.FC<LoginModalParams> = ({
  modalData: {
    redirectOnDismiss,
    onDismiss,
    title,
    onOutsideClickDismiss,
    onLoginSuccess,
    isBackButtonHidden,
    registrationSource,
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const wasDismissedExternally = useSelector(wasDismissedExternallySelector);
  const {
    isHomePageEnabled,
    isHomePageV2Enabled,
    isHomePageV3Enabled,
    phoneNumberAuthenticationState,
    isLoginFailed,
  } = useSelector(selector, shallowEqual);
  const {
    selectCountry,
    waitingForCode,
    continueWithPhone,
    phoneVerificationUnavailable,
  } = phoneNumberAuthenticationState;

  const isPathToHomePage = useRouteMatch([linkToBasePath, linkToHomePageV3]);
  const isHomePageVisible =
    isPathToHomePage &&
    (isHomePageEnabled || isHomePageV2Enabled || isHomePageV3Enabled);

  const isBackButtonVisible = Boolean(
    isHomePageVisible && isBackButtonHidden
      ? Object.keys(phoneNumberAuthenticationState).length > 1
      : Object.keys(phoneNumberAuthenticationState).length &&
          !phoneVerificationUnavailable &&
          !isLoginFailed
  );

  useEffect(() => {
    if (!!redirectOnDismiss && wasDismissedExternally) {
      history.replace(redirectOnDismiss);
    }
  }, [wasDismissedExternally]);

  const navigateBack = useCallback(() => {
    if (selectCountry) {
      dispatch(loginSetSelectCountryStep(false));
    } else if (waitingForCode) {
      dispatch(loginWaitingForCode(false));
    } else if (continueWithPhone) {
      dispatch(resetPhoneLogin());
    }
  }, [dispatch, waitingForCode, selectCountry, continueWithPhone]);

  const onCloseClick = useCallback(() => {
    onDismiss?.();
    onOutsideClickDismiss?.();
    dispatch(dismissTopModal(OVERLAY_CLICK_OR_ESCAPE_PRESS));
  }, []);

  useUnmount(() => {
    dispatch(resetPhoneLogin());
  });

  return (
    <div className={styles.root} data-testid="login-modal">
      <div
        className={classnames(styles.loginHeader, {
          [styles.isBackButtonHidden]: !isBackButtonVisible,
        })}
      >
        {isBackButtonVisible && (
          <Button
            size={ButtonSize.CIRCLE_MEDIUM_40}
            variant={ButtonVariant.SECONDARY}
            onClick={navigateBack}
            className={styles.back}
          >
            <ArrowIcon />
          </Button>
        )}
        <Button
          size={ButtonSize.CIRCLE_MEDIUM_40}
          variant={ButtonVariant.SECONDARY}
          onClick={onCloseClick}
          className={styles.close}
          dataTestId="close-modal-button"
        >
          <CloseIcon />
        </Button>
      </div>

      <LoginModalContent
        title={title}
        onLoginSuccess={onLoginSuccess}
        registrationSource={registrationSource}
      />
    </div>
  );
};

export default LoginModal;
