import React, { memo, useCallback, useMemo } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { ReactComponent as SubscriptionStars } from "chat/assets/ic_subscription_stars.svg";
import { avatarLoginPlaceholder } from "chat/imports/assets";
import { Avatar, Typography } from "chat/imports/components";
import { AvatarSize, TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { useMyProfile } from "chat/imports/hooks";
import { Subscription, parseMessageFromBase64 } from "chat/imports/proto";
import { RootState, profilesCacheSelectors } from "chat/imports/state";
import {
  AccountInfo,
  Subscription as SubscriptionType,
} from "chat/imports/types";
import { ensureHttps, makeLinkToProfile } from "chat/imports/utils";
import { StoredMessage } from "chat/state/reducer";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { MessageConfig } from "./Message";
import styles from "./SubscriptionMessage.scss";

const messages = defineMessages({
  active: {
    id: "chat.message.subscription.active",
    defaultMessage: "I subscribed to you!",
  },
  inactive: {
    id: "chat.message.subscription.inactive",
    defaultMessage: "I unsubscribed from you!",
  },
  renew: {
    id: "chat.message.subscription.renew",
    defaultMessage:
      "I subscribed to you for {months} months in a row (+{coins} coins)",
  },
});

type SubscriptionFromBase64 = {
  status: number;
} & Omit<SubscriptionType, "status">;

const selectorFactory = (accountId: string) => (state: RootState) => ({
  profileDetails: profilesCacheSelectors.getBasicProfile(state, accountId),
});

interface SubscriptionMessageProps {
  accountInfo?: AccountInfo;
  conversationId: string;
  message: StoredMessage;
  messageConfig: MessageConfig;
}

const SubscriptionMessage: React.FC<SubscriptionMessageProps> = ({
  message,
  conversationId,
  messageConfig,
  accountInfo,
}) => {
  const { account_id } = accountInfo || {};
  const { basicProfile } = useMyProfile(true);
  const selector = useCallback(
    (conversationId) => selectorFactory(conversationId),
    []
  );
  const { profileDetails } = useSelector(
    selector(conversationId),
    shallowEqual
  );

  const senderBasicProfile = useSelector(
    useCallback(
      (state: RootState) =>
        profilesCacheSelectors.getBasicProfile(state, account_id),
      [account_id]
    ),
    shallowEqual
  );

  const data = useMemo(() => {
    try {
      const parsedBody = message.body ? JSON.parse(message.body) : {};
      const {
        subscriberProfile,
        status,
        times,
        credits,
      }: SubscriptionFromBase64 = parseMessageFromBase64(
        parsedBody.subscription,
        Subscription
      );
      const { accountId, thumbnailUrl, pictureUrl } = subscriberProfile;
      const isCancelled = status === 2;

      return {
        accountId,
        frontSrc: ensureHttps(
          thumbnailUrl || pictureUrl || avatarLoginPlaceholder
        ),
        times,
        credits,
        // eslint-disable-next-line no-nested-ternary
        bottomText: isCancelled
          ? messages.inactive
          : times > 1
            ? messages.renew
            : messages.active,
      };
    } catch (e) {
      return null;
    }
  }, [message.body]);

  if (!data) {
    return null;
  }

  const { frontSrc, times, credits, bottomText } = data;

  return (
    <Link
      to={makeLinkToProfile(account_id, senderBasicProfile)}
      className={classnames(
        styles.root,
        getGroupMessageClassnames(styles, messageConfig)
      )}
    >
      <SubscriptionStars className={styles.stars} />
      <div className={styles.avatars}>
        <Avatar
          className={classnames(styles.avatar, styles.subscribedAvatar)}
          imageUrl={
            messageConfig.isMyMessage
              ? profileDetails?.profileThumbnailUrl
              : basicProfile?.profileThumbnailUrl
          }
          size={AvatarSize.SMALL_PLUS}
        />
        <Avatar
          className={classnames(styles.avatar, styles.subscriberAvatar)}
          imageUrl={frontSrc}
          size={AvatarSize.MEDIUM_PLUS}
        />
      </div>
      <Typography
        type={TYPOGRAPHY_TYPE.HEADLINE5}
        className={styles.text}
        as="div"
      >
        <FormattedMessage
          {...bottomText}
          values={{ months: times, coins: Number(credits) }}
        />
      </Typography>
    </Link>
  );
};

export default memo(SubscriptionMessage);
