import { MINUTE } from "src/constants";
import tangoAnalytics from "src/core/analytics";
import { Platforms } from "src/core/analytics/enums";
import {
  ClientSessionFields,
  EventNames,
  StorageKeys,
} from "src/core/analytics/plugins/dataMesh/enums";
import { getInMemoryStore } from "utils/getStoreHandler";

export const addMinutesToDate = (date: Date, minutes: number) =>
  new Date(date.getTime() + minutes * MINUTE);

const analyticsSessionStart = (): void => {
  const getSessionStartTime = localStorage.getItem(
    StorageKeys.SESSION_START_DATE
  );

  const currentTime = new Date();
  const currentTimeMillis = currentTime.getTime();

  if (
    !!getSessionStartTime &&
    currentTimeMillis < Number(getSessionStartTime)
  ) {
    return;
  }

  const {
    deviceInfo: { type, browserName, version, locale },
  } = getInMemoryStore();

  const payload = {
    [ClientSessionFields.DEVICE_CATEGORY]: type,
    [ClientSessionFields.PLATFORM]: Platforms.WEB,
    [ClientSessionFields.BROWSER]: browserName,
    [ClientSessionFields.BROWSER_VERSION]: version,
    [ClientSessionFields.DEVICE_LANGUAGE]: locale,
    [ClientSessionFields.TANGO_VERSION]: GENERATED_APP_INFO.fullVersion,
  };

  tangoAnalytics.then((analytics) => {
    analytics.track(EventNames.CLIENT_SESSION, payload);
  });
};

export { analyticsSessionStart };
