import { createReducer } from "@reduxjs/toolkit";
import actions from "state/actionCreators/vipConfigs";
import { VipConfigWithAssetConfig } from "state/flows/loadVipConfigs";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";

export const vipConfigsPersistConfig = {
  whitelist: ["data"],
};

const initialState = {
  data: [],
  meta: initialFetcherStateMeta,
};

export type VipConfigsState = AsyncState<VipConfigWithAssetConfig[], string>;

export default createReducer<VipConfigsState>(initialState, (builder) => {
  addAsyncCasesToBuilderV2({
    builder,
    action: actions.fetchVipConfigs,
    prepareData: (oldConfigs, newConfigs) => newConfigs,
    initialData: initialState.data,
  });
});
