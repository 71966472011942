/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.PremiumMessage || ($protobuf.roots.PremiumMessage = {});

export const ConversationState = $root.ConversationState = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "ACTIVE"] = 0;
    values[valuesById[1] = "CHAT_REQUEST"] = 1;
    return values;
})();

export const ContentStatus = $root.ContentStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "LOCKED"] = 0;
    values[valuesById[1] = "PENDING"] = 1;
    values[valuesById[2] = "UNLOCKED"] = 2;
    return values;
})();

export const OriginType = $root.OriginType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "DIRECT"] = 0;
    values[valuesById[1] = "GROUP"] = 1;
    return values;
})();

export const ContentType = $root.ContentType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "PHOTO"] = 0;
    values[valuesById[1] = "VIDEO"] = 1;
    return values;
})();

export const PlatformType = $root.PlatformType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "WEB"] = 0;
    values[valuesById[1] = "MOBILE"] = 1;
    return values;
})();

export const MediaInfo = $root.MediaInfo = (() => {

    function MediaInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MediaInfo.prototype.width = 0;
    MediaInfo.prototype.height = 0;
    MediaInfo.prototype.duration = 0;
    MediaInfo.prototype.mediaId = "";
    MediaInfo.prototype.contentUrl = "";
    MediaInfo.prototype.thumbnailUrl = "";

    MediaInfo.create = function create(properties) {
        return new MediaInfo(properties);
    };

    MediaInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.width);
        writer.uint32(16).int32(message.height);
        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
            writer.uint32(24).int32(message.duration);
        if (message.mediaId != null && Object.hasOwnProperty.call(message, "mediaId"))
            writer.uint32(34).string(message.mediaId);
        if (message.contentUrl != null && Object.hasOwnProperty.call(message, "contentUrl"))
            writer.uint32(42).string(message.contentUrl);
        if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
            writer.uint32(50).string(message.thumbnailUrl);
        return writer;
    };

    MediaInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MediaInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MediaInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.width = reader.int32();
                    break;
                }
            case 2: {
                    message.height = reader.int32();
                    break;
                }
            case 3: {
                    message.duration = reader.int32();
                    break;
                }
            case 4: {
                    message.mediaId = reader.string();
                    break;
                }
            case 5: {
                    message.contentUrl = reader.string();
                    break;
                }
            case 6: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("width"))
            throw $util.ProtocolError("missing required 'width'", { instance: message });
        if (!message.hasOwnProperty("height"))
            throw $util.ProtocolError("missing required 'height'", { instance: message });
        return message;
    };

    MediaInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MediaInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.MediaInfo)
            return object;
        let message = new $root.MediaInfo();
        if (object.width != null)
            message.width = object.width | 0;
        if (object.height != null)
            message.height = object.height | 0;
        if (object.duration != null)
            message.duration = object.duration | 0;
        if (object.mediaId != null)
            message.mediaId = String(object.mediaId);
        if (object.contentUrl != null)
            message.contentUrl = String(object.contentUrl);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        return message;
    };

    MediaInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.width = 0;
            object.height = 0;
            object.duration = 0;
            object.mediaId = "";
            object.contentUrl = "";
            object.thumbnailUrl = "";
        }
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        if (message.duration != null && message.hasOwnProperty("duration"))
            object.duration = message.duration;
        if (message.mediaId != null && message.hasOwnProperty("mediaId"))
            object.mediaId = message.mediaId;
        if (message.contentUrl != null && message.hasOwnProperty("contentUrl"))
            object.contentUrl = message.contentUrl;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        return object;
    };

    MediaInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MediaInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MediaInfo";
    };

    return MediaInfo;
})();

export const ShareResponseCode = $root.ShareResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "SHARE_SUCCESS"] = 0;
    values[valuesById[1] = "SHARE_SERVER_ERROR"] = 1;
    values[valuesById[2] = "SHARE_INVALID_REQUEST"] = 2;
    values[valuesById[3] = "SHARE_THRESHOLD_EXCEEDED"] = 3;
    return values;
})();

export const ShareRequest = $root.ShareRequest = (() => {

    function ShareRequest(properties) {
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareRequest.prototype.giftId = "";
    ShareRequest.prototype.recipientId = "";
    ShareRequest.prototype.items = $util.emptyArray;
    ShareRequest.prototype.exclusive = false;
    ShareRequest.prototype.platformType = 0;

    ShareRequest.create = function create(properties) {
        return new ShareRequest(properties);
    };

    ShareRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.giftId);
        writer.uint32(18).string(message.recipientId);
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.ShareItemRequest.encode(message.items[i], writer.uint32(26).fork()).ldelim();
        if (message.exclusive != null && Object.hasOwnProperty.call(message, "exclusive"))
            writer.uint32(32).bool(message.exclusive);
        if (message.platformType != null && Object.hasOwnProperty.call(message, "platformType"))
            writer.uint32(40).int32(message.platformType);
        return writer;
    };

    ShareRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.giftId = reader.string();
                    break;
                }
            case 2: {
                    message.recipientId = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.ShareItemRequest.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.exclusive = reader.bool();
                    break;
                }
            case 5: {
                    message.platformType = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("giftId"))
            throw $util.ProtocolError("missing required 'giftId'", { instance: message });
        if (!message.hasOwnProperty("recipientId"))
            throw $util.ProtocolError("missing required 'recipientId'", { instance: message });
        return message;
    };

    ShareRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareRequest)
            return object;
        let message = new $root.ShareRequest();
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.recipientId != null)
            message.recipientId = String(object.recipientId);
        if (object.items) {
            if (!Array.isArray(object.items))
                throw TypeError(".ShareRequest.items: array expected");
            message.items = [];
            for (let i = 0; i < object.items.length; ++i) {
                if (typeof object.items[i] !== "object")
                    throw TypeError(".ShareRequest.items: object expected");
                message.items[i] = $root.ShareItemRequest.fromObject(object.items[i]);
            }
        }
        if (object.exclusive != null)
            message.exclusive = Boolean(object.exclusive);
        switch (object.platformType) {
        default:
            if (typeof object.platformType === "number") {
                message.platformType = object.platformType;
                break;
            }
            break;
        case "WEB":
        case 0:
            message.platformType = 0;
            break;
        case "MOBILE":
        case 1:
            message.platformType = 1;
            break;
        }
        return message;
    };

    ShareRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.items = [];
        if (options.defaults) {
            object.giftId = "";
            object.recipientId = "";
            object.exclusive = false;
            object.platformType = options.enums === String ? "WEB" : 0;
        }
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.recipientId != null && message.hasOwnProperty("recipientId"))
            object.recipientId = message.recipientId;
        if (message.items && message.items.length) {
            object.items = [];
            for (let j = 0; j < message.items.length; ++j)
                object.items[j] = $root.ShareItemRequest.toObject(message.items[j], options);
        }
        if (message.exclusive != null && message.hasOwnProperty("exclusive"))
            object.exclusive = message.exclusive;
        if (message.platformType != null && message.hasOwnProperty("platformType"))
            object.platformType = options.enums === String ? $root.PlatformType[message.platformType] === undefined ? message.platformType : $root.PlatformType[message.platformType] : message.platformType;
        return object;
    };

    ShareRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareRequest";
    };

    return ShareRequest;
})();

export const ShareRequestMultiple = $root.ShareRequestMultiple = (() => {

    function ShareRequestMultiple(properties) {
        this.recipientIds = [];
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareRequestMultiple.prototype.giftId = "";
    ShareRequestMultiple.prototype.recipientIds = $util.emptyArray;
    ShareRequestMultiple.prototype.items = $util.emptyArray;
    ShareRequestMultiple.prototype.exclusive = false;
    ShareRequestMultiple.prototype.platformType = 0;

    ShareRequestMultiple.create = function create(properties) {
        return new ShareRequestMultiple(properties);
    };

    ShareRequestMultiple.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.giftId);
        if (message.recipientIds != null && message.recipientIds.length)
            for (let i = 0; i < message.recipientIds.length; ++i)
                writer.uint32(18).string(message.recipientIds[i]);
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.ShareItemRequest.encode(message.items[i], writer.uint32(26).fork()).ldelim();
        if (message.exclusive != null && Object.hasOwnProperty.call(message, "exclusive"))
            writer.uint32(32).bool(message.exclusive);
        if (message.platformType != null && Object.hasOwnProperty.call(message, "platformType"))
            writer.uint32(40).int32(message.platformType);
        return writer;
    };

    ShareRequestMultiple.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareRequestMultiple.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareRequestMultiple();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.giftId = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.recipientIds && message.recipientIds.length))
                        message.recipientIds = [];
                    message.recipientIds.push(reader.string());
                    break;
                }
            case 3: {
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.ShareItemRequest.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.exclusive = reader.bool();
                    break;
                }
            case 5: {
                    message.platformType = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("giftId"))
            throw $util.ProtocolError("missing required 'giftId'", { instance: message });
        return message;
    };

    ShareRequestMultiple.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareRequestMultiple.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareRequestMultiple)
            return object;
        let message = new $root.ShareRequestMultiple();
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.recipientIds) {
            if (!Array.isArray(object.recipientIds))
                throw TypeError(".ShareRequestMultiple.recipientIds: array expected");
            message.recipientIds = [];
            for (let i = 0; i < object.recipientIds.length; ++i)
                message.recipientIds[i] = String(object.recipientIds[i]);
        }
        if (object.items) {
            if (!Array.isArray(object.items))
                throw TypeError(".ShareRequestMultiple.items: array expected");
            message.items = [];
            for (let i = 0; i < object.items.length; ++i) {
                if (typeof object.items[i] !== "object")
                    throw TypeError(".ShareRequestMultiple.items: object expected");
                message.items[i] = $root.ShareItemRequest.fromObject(object.items[i]);
            }
        }
        if (object.exclusive != null)
            message.exclusive = Boolean(object.exclusive);
        switch (object.platformType) {
        default:
            if (typeof object.platformType === "number") {
                message.platformType = object.platformType;
                break;
            }
            break;
        case "WEB":
        case 0:
            message.platformType = 0;
            break;
        case "MOBILE":
        case 1:
            message.platformType = 1;
            break;
        }
        return message;
    };

    ShareRequestMultiple.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.recipientIds = [];
            object.items = [];
        }
        if (options.defaults) {
            object.giftId = "";
            object.exclusive = false;
            object.platformType = options.enums === String ? "WEB" : 0;
        }
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.recipientIds && message.recipientIds.length) {
            object.recipientIds = [];
            for (let j = 0; j < message.recipientIds.length; ++j)
                object.recipientIds[j] = message.recipientIds[j];
        }
        if (message.items && message.items.length) {
            object.items = [];
            for (let j = 0; j < message.items.length; ++j)
                object.items[j] = $root.ShareItemRequest.toObject(message.items[j], options);
        }
        if (message.exclusive != null && message.hasOwnProperty("exclusive"))
            object.exclusive = message.exclusive;
        if (message.platformType != null && message.hasOwnProperty("platformType"))
            object.platformType = options.enums === String ? $root.PlatformType[message.platformType] === undefined ? message.platformType : $root.PlatformType[message.platformType] : message.platformType;
        return object;
    };

    ShareRequestMultiple.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareRequestMultiple.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareRequestMultiple";
    };

    return ShareRequestMultiple;
})();

export const ShareResponse = $root.ShareResponse = (() => {

    function ShareResponse(properties) {
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareResponse.prototype.responseCode = 0;
    ShareResponse.prototype.items = $util.emptyArray;

    ShareResponse.create = function create(properties) {
        return new ShareResponse(properties);
    };

    ShareResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.responseCode);
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.ShareItemResponse.encode(message.items[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    ShareResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.responseCode = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.ShareItemResponse.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("responseCode"))
            throw $util.ProtocolError("missing required 'responseCode'", { instance: message });
        return message;
    };

    ShareResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareResponse)
            return object;
        let message = new $root.ShareResponse();
        switch (object.responseCode) {
        default:
            if (typeof object.responseCode === "number") {
                message.responseCode = object.responseCode;
                break;
            }
            break;
        case "SHARE_SUCCESS":
        case 0:
            message.responseCode = 0;
            break;
        case "SHARE_SERVER_ERROR":
        case 1:
            message.responseCode = 1;
            break;
        case "SHARE_INVALID_REQUEST":
        case 2:
            message.responseCode = 2;
            break;
        case "SHARE_THRESHOLD_EXCEEDED":
        case 3:
            message.responseCode = 3;
            break;
        }
        if (object.items) {
            if (!Array.isArray(object.items))
                throw TypeError(".ShareResponse.items: array expected");
            message.items = [];
            for (let i = 0; i < object.items.length; ++i) {
                if (typeof object.items[i] !== "object")
                    throw TypeError(".ShareResponse.items: object expected");
                message.items[i] = $root.ShareItemResponse.fromObject(object.items[i]);
            }
        }
        return message;
    };

    ShareResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.items = [];
        if (options.defaults)
            object.responseCode = options.enums === String ? "SHARE_SUCCESS" : 0;
        if (message.responseCode != null && message.hasOwnProperty("responseCode"))
            object.responseCode = options.enums === String ? $root.ShareResponseCode[message.responseCode] === undefined ? message.responseCode : $root.ShareResponseCode[message.responseCode] : message.responseCode;
        if (message.items && message.items.length) {
            object.items = [];
            for (let j = 0; j < message.items.length; ++j)
                object.items[j] = $root.ShareItemResponse.toObject(message.items[j], options);
        }
        return object;
    };

    ShareResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareResponse";
    };

    return ShareResponse;
})();

export const ShareResponseMultiple = $root.ShareResponseMultiple = (() => {

    function ShareResponseMultiple(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareResponseMultiple.prototype.responseCode = 0;

    ShareResponseMultiple.create = function create(properties) {
        return new ShareResponseMultiple(properties);
    };

    ShareResponseMultiple.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.responseCode);
        return writer;
    };

    ShareResponseMultiple.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareResponseMultiple.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareResponseMultiple();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.responseCode = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("responseCode"))
            throw $util.ProtocolError("missing required 'responseCode'", { instance: message });
        return message;
    };

    ShareResponseMultiple.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareResponseMultiple.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareResponseMultiple)
            return object;
        let message = new $root.ShareResponseMultiple();
        switch (object.responseCode) {
        default:
            if (typeof object.responseCode === "number") {
                message.responseCode = object.responseCode;
                break;
            }
            break;
        case "SHARE_SUCCESS":
        case 0:
            message.responseCode = 0;
            break;
        case "SHARE_SERVER_ERROR":
        case 1:
            message.responseCode = 1;
            break;
        case "SHARE_INVALID_REQUEST":
        case 2:
            message.responseCode = 2;
            break;
        case "SHARE_THRESHOLD_EXCEEDED":
        case 3:
            message.responseCode = 3;
            break;
        }
        return message;
    };

    ShareResponseMultiple.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.responseCode = options.enums === String ? "SHARE_SUCCESS" : 0;
        if (message.responseCode != null && message.hasOwnProperty("responseCode"))
            object.responseCode = options.enums === String ? $root.ShareResponseCode[message.responseCode] === undefined ? message.responseCode : $root.ShareResponseCode[message.responseCode] : message.responseCode;
        return object;
    };

    ShareResponseMultiple.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareResponseMultiple.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareResponseMultiple";
    };

    return ShareResponseMultiple;
})();

export const ShareItemRequest = $root.ShareItemRequest = (() => {

    function ShareItemRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareItemRequest.prototype.type = 0;
    ShareItemRequest.prototype.mediaInfo = null;

    ShareItemRequest.create = function create(properties) {
        return new ShareItemRequest(properties);
    };

    ShareItemRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.type);
        $root.MediaInfo.encode(message.mediaInfo, writer.uint32(34).fork()).ldelim();
        return writer;
    };

    ShareItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareItemRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareItemRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 4: {
                    message.mediaInfo = $root.MediaInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("mediaInfo"))
            throw $util.ProtocolError("missing required 'mediaInfo'", { instance: message });
        return message;
    };

    ShareItemRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareItemRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareItemRequest)
            return object;
        let message = new $root.ShareItemRequest();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "PHOTO":
        case 0:
            message.type = 0;
            break;
        case "VIDEO":
        case 1:
            message.type = 1;
            break;
        }
        if (object.mediaInfo != null) {
            if (typeof object.mediaInfo !== "object")
                throw TypeError(".ShareItemRequest.mediaInfo: object expected");
            message.mediaInfo = $root.MediaInfo.fromObject(object.mediaInfo);
        }
        return message;
    };

    ShareItemRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "PHOTO" : 0;
            object.mediaInfo = null;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ContentType[message.type] === undefined ? message.type : $root.ContentType[message.type] : message.type;
        if (message.mediaInfo != null && message.hasOwnProperty("mediaInfo"))
            object.mediaInfo = $root.MediaInfo.toObject(message.mediaInfo, options);
        return object;
    };

    ShareItemRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareItemRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareItemRequest";
    };

    return ShareItemRequest;
})();

export const ShareItemResponse = $root.ShareItemResponse = (() => {

    function ShareItemResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareItemResponse.prototype.messageId = "";
    ShareItemResponse.prototype.tc2Id = null;
    ShareItemResponse.prototype.state = 0;

    ShareItemResponse.create = function create(properties) {
        return new ShareItemResponse(properties);
    };

    ShareItemResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.messageId);
        $root.MessageIdentifier.encode(message.tc2Id, writer.uint32(18).fork()).ldelim();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(24).int32(message.state);
        return writer;
    };

    ShareItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareItemResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareItemResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.messageId = reader.string();
                    break;
                }
            case 2: {
                    message.tc2Id = $root.MessageIdentifier.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("messageId"))
            throw $util.ProtocolError("missing required 'messageId'", { instance: message });
        if (!message.hasOwnProperty("tc2Id"))
            throw $util.ProtocolError("missing required 'tc2Id'", { instance: message });
        return message;
    };

    ShareItemResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareItemResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareItemResponse)
            return object;
        let message = new $root.ShareItemResponse();
        if (object.messageId != null)
            message.messageId = String(object.messageId);
        if (object.tc2Id != null) {
            if (typeof object.tc2Id !== "object")
                throw TypeError(".ShareItemResponse.tc2Id: object expected");
            message.tc2Id = $root.MessageIdentifier.fromObject(object.tc2Id);
        }
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "ACTIVE":
        case 0:
            message.state = 0;
            break;
        case "CHAT_REQUEST":
        case 1:
            message.state = 1;
            break;
        }
        return message;
    };

    ShareItemResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.messageId = "";
            object.tc2Id = null;
            object.state = options.enums === String ? "ACTIVE" : 0;
        }
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            object.messageId = message.messageId;
        if (message.tc2Id != null && message.hasOwnProperty("tc2Id"))
            object.tc2Id = $root.MessageIdentifier.toObject(message.tc2Id, options);
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.ConversationState[message.state] === undefined ? message.state : $root.ConversationState[message.state] : message.state;
        return object;
    };

    ShareItemResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareItemResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareItemResponse";
    };

    return ShareItemResponse;
})();

export const ShareSequenceRequest = $root.ShareSequenceRequest = (() => {

    function ShareSequenceRequest(properties) {
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareSequenceRequest.prototype.giftId = "";
    ShareSequenceRequest.prototype.recipientId = "";
    ShareSequenceRequest.prototype.items = $util.emptyArray;
    ShareSequenceRequest.prototype.exclusive = false;
    ShareSequenceRequest.prototype.platformType = 0;

    ShareSequenceRequest.create = function create(properties) {
        return new ShareSequenceRequest(properties);
    };

    ShareSequenceRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.giftId);
        writer.uint32(18).string(message.recipientId);
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.ShareSequenceItemRequest.encode(message.items[i], writer.uint32(26).fork()).ldelim();
        if (message.exclusive != null && Object.hasOwnProperty.call(message, "exclusive"))
            writer.uint32(32).bool(message.exclusive);
        if (message.platformType != null && Object.hasOwnProperty.call(message, "platformType"))
            writer.uint32(40).int32(message.platformType);
        return writer;
    };

    ShareSequenceRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareSequenceRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareSequenceRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.giftId = reader.string();
                    break;
                }
            case 2: {
                    message.recipientId = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.ShareSequenceItemRequest.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.exclusive = reader.bool();
                    break;
                }
            case 5: {
                    message.platformType = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("giftId"))
            throw $util.ProtocolError("missing required 'giftId'", { instance: message });
        if (!message.hasOwnProperty("recipientId"))
            throw $util.ProtocolError("missing required 'recipientId'", { instance: message });
        return message;
    };

    ShareSequenceRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareSequenceRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareSequenceRequest)
            return object;
        let message = new $root.ShareSequenceRequest();
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.recipientId != null)
            message.recipientId = String(object.recipientId);
        if (object.items) {
            if (!Array.isArray(object.items))
                throw TypeError(".ShareSequenceRequest.items: array expected");
            message.items = [];
            for (let i = 0; i < object.items.length; ++i) {
                if (typeof object.items[i] !== "object")
                    throw TypeError(".ShareSequenceRequest.items: object expected");
                message.items[i] = $root.ShareSequenceItemRequest.fromObject(object.items[i]);
            }
        }
        if (object.exclusive != null)
            message.exclusive = Boolean(object.exclusive);
        switch (object.platformType) {
        default:
            if (typeof object.platformType === "number") {
                message.platformType = object.platformType;
                break;
            }
            break;
        case "WEB":
        case 0:
            message.platformType = 0;
            break;
        case "MOBILE":
        case 1:
            message.platformType = 1;
            break;
        }
        return message;
    };

    ShareSequenceRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.items = [];
        if (options.defaults) {
            object.giftId = "";
            object.recipientId = "";
            object.exclusive = false;
            object.platformType = options.enums === String ? "WEB" : 0;
        }
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.recipientId != null && message.hasOwnProperty("recipientId"))
            object.recipientId = message.recipientId;
        if (message.items && message.items.length) {
            object.items = [];
            for (let j = 0; j < message.items.length; ++j)
                object.items[j] = $root.ShareSequenceItemRequest.toObject(message.items[j], options);
        }
        if (message.exclusive != null && message.hasOwnProperty("exclusive"))
            object.exclusive = message.exclusive;
        if (message.platformType != null && message.hasOwnProperty("platformType"))
            object.platformType = options.enums === String ? $root.PlatformType[message.platformType] === undefined ? message.platformType : $root.PlatformType[message.platformType] : message.platformType;
        return object;
    };

    ShareSequenceRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareSequenceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareSequenceRequest";
    };

    return ShareSequenceRequest;
})();

export const ShareSequenceItemRequest = $root.ShareSequenceItemRequest = (() => {

    function ShareSequenceItemRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareSequenceItemRequest.prototype.type = 0;
    ShareSequenceItemRequest.prototype.mediaInfo = null;
    ShareSequenceItemRequest.prototype.sequence = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    ShareSequenceItemRequest.create = function create(properties) {
        return new ShareSequenceItemRequest(properties);
    };

    ShareSequenceItemRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.type);
        $root.MediaInfo.encode(message.mediaInfo, writer.uint32(18).fork()).ldelim();
        writer.uint32(24).int64(message.sequence);
        return writer;
    };

    ShareSequenceItemRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareSequenceItemRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareSequenceItemRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.mediaInfo = $root.MediaInfo.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.sequence = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("mediaInfo"))
            throw $util.ProtocolError("missing required 'mediaInfo'", { instance: message });
        if (!message.hasOwnProperty("sequence"))
            throw $util.ProtocolError("missing required 'sequence'", { instance: message });
        return message;
    };

    ShareSequenceItemRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareSequenceItemRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareSequenceItemRequest)
            return object;
        let message = new $root.ShareSequenceItemRequest();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "PHOTO":
        case 0:
            message.type = 0;
            break;
        case "VIDEO":
        case 1:
            message.type = 1;
            break;
        }
        if (object.mediaInfo != null) {
            if (typeof object.mediaInfo !== "object")
                throw TypeError(".ShareSequenceItemRequest.mediaInfo: object expected");
            message.mediaInfo = $root.MediaInfo.fromObject(object.mediaInfo);
        }
        if (object.sequence != null)
            if ($util.Long)
                (message.sequence = $util.Long.fromValue(object.sequence)).unsigned = false;
            else if (typeof object.sequence === "string")
                message.sequence = parseInt(object.sequence, 10);
            else if (typeof object.sequence === "number")
                message.sequence = object.sequence;
            else if (typeof object.sequence === "object")
                message.sequence = new $util.LongBits(object.sequence.low >>> 0, object.sequence.high >>> 0).toNumber();
        return message;
    };

    ShareSequenceItemRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "PHOTO" : 0;
            object.mediaInfo = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.sequence = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.sequence = options.longs === String ? "0" : 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ContentType[message.type] === undefined ? message.type : $root.ContentType[message.type] : message.type;
        if (message.mediaInfo != null && message.hasOwnProperty("mediaInfo"))
            object.mediaInfo = $root.MediaInfo.toObject(message.mediaInfo, options);
        if (message.sequence != null && message.hasOwnProperty("sequence"))
            if (typeof message.sequence === "number")
                object.sequence = options.longs === String ? String(message.sequence) : message.sequence;
            else
                object.sequence = options.longs === String ? $util.Long.prototype.toString.call(message.sequence) : options.longs === Number ? new $util.LongBits(message.sequence.low >>> 0, message.sequence.high >>> 0).toNumber() : message.sequence;
        return object;
    };

    ShareSequenceItemRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareSequenceItemRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareSequenceItemRequest";
    };

    return ShareSequenceItemRequest;
})();

export const ShareSequenceResponse = $root.ShareSequenceResponse = (() => {

    function ShareSequenceResponse(properties) {
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareSequenceResponse.prototype.responseCode = 0;
    ShareSequenceResponse.prototype.items = $util.emptyArray;

    ShareSequenceResponse.create = function create(properties) {
        return new ShareSequenceResponse(properties);
    };

    ShareSequenceResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.responseCode);
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.ShareSequenceItemResponse.encode(message.items[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    ShareSequenceResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareSequenceResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareSequenceResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.responseCode = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.ShareSequenceItemResponse.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("responseCode"))
            throw $util.ProtocolError("missing required 'responseCode'", { instance: message });
        return message;
    };

    ShareSequenceResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareSequenceResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareSequenceResponse)
            return object;
        let message = new $root.ShareSequenceResponse();
        switch (object.responseCode) {
        default:
            if (typeof object.responseCode === "number") {
                message.responseCode = object.responseCode;
                break;
            }
            break;
        case "SHARE_SUCCESS":
        case 0:
            message.responseCode = 0;
            break;
        case "SHARE_SERVER_ERROR":
        case 1:
            message.responseCode = 1;
            break;
        case "SHARE_INVALID_REQUEST":
        case 2:
            message.responseCode = 2;
            break;
        case "SHARE_THRESHOLD_EXCEEDED":
        case 3:
            message.responseCode = 3;
            break;
        }
        if (object.items) {
            if (!Array.isArray(object.items))
                throw TypeError(".ShareSequenceResponse.items: array expected");
            message.items = [];
            for (let i = 0; i < object.items.length; ++i) {
                if (typeof object.items[i] !== "object")
                    throw TypeError(".ShareSequenceResponse.items: object expected");
                message.items[i] = $root.ShareSequenceItemResponse.fromObject(object.items[i]);
            }
        }
        return message;
    };

    ShareSequenceResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.items = [];
        if (options.defaults)
            object.responseCode = options.enums === String ? "SHARE_SUCCESS" : 0;
        if (message.responseCode != null && message.hasOwnProperty("responseCode"))
            object.responseCode = options.enums === String ? $root.ShareResponseCode[message.responseCode] === undefined ? message.responseCode : $root.ShareResponseCode[message.responseCode] : message.responseCode;
        if (message.items && message.items.length) {
            object.items = [];
            for (let j = 0; j < message.items.length; ++j)
                object.items[j] = $root.ShareSequenceItemResponse.toObject(message.items[j], options);
        }
        return object;
    };

    ShareSequenceResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareSequenceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareSequenceResponse";
    };

    return ShareSequenceResponse;
})();

export const ShareSequenceItemResponse = $root.ShareSequenceItemResponse = (() => {

    function ShareSequenceItemResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ShareSequenceItemResponse.prototype.messageId = "";
    ShareSequenceItemResponse.prototype.tc2Id = null;
    ShareSequenceItemResponse.prototype.state = 0;
    ShareSequenceItemResponse.prototype.sequence = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    ShareSequenceItemResponse.create = function create(properties) {
        return new ShareSequenceItemResponse(properties);
    };

    ShareSequenceItemResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.messageId);
        $root.MessageIdentifier.encode(message.tc2Id, writer.uint32(18).fork()).ldelim();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(24).int32(message.state);
        writer.uint32(32).int64(message.sequence);
        return writer;
    };

    ShareSequenceItemResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ShareSequenceItemResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ShareSequenceItemResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.messageId = reader.string();
                    break;
                }
            case 2: {
                    message.tc2Id = $root.MessageIdentifier.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.state = reader.int32();
                    break;
                }
            case 4: {
                    message.sequence = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("messageId"))
            throw $util.ProtocolError("missing required 'messageId'", { instance: message });
        if (!message.hasOwnProperty("tc2Id"))
            throw $util.ProtocolError("missing required 'tc2Id'", { instance: message });
        if (!message.hasOwnProperty("sequence"))
            throw $util.ProtocolError("missing required 'sequence'", { instance: message });
        return message;
    };

    ShareSequenceItemResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ShareSequenceItemResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ShareSequenceItemResponse)
            return object;
        let message = new $root.ShareSequenceItemResponse();
        if (object.messageId != null)
            message.messageId = String(object.messageId);
        if (object.tc2Id != null) {
            if (typeof object.tc2Id !== "object")
                throw TypeError(".ShareSequenceItemResponse.tc2Id: object expected");
            message.tc2Id = $root.MessageIdentifier.fromObject(object.tc2Id);
        }
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "ACTIVE":
        case 0:
            message.state = 0;
            break;
        case "CHAT_REQUEST":
        case 1:
            message.state = 1;
            break;
        }
        if (object.sequence != null)
            if ($util.Long)
                (message.sequence = $util.Long.fromValue(object.sequence)).unsigned = false;
            else if (typeof object.sequence === "string")
                message.sequence = parseInt(object.sequence, 10);
            else if (typeof object.sequence === "number")
                message.sequence = object.sequence;
            else if (typeof object.sequence === "object")
                message.sequence = new $util.LongBits(object.sequence.low >>> 0, object.sequence.high >>> 0).toNumber();
        return message;
    };

    ShareSequenceItemResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.messageId = "";
            object.tc2Id = null;
            object.state = options.enums === String ? "ACTIVE" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.sequence = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.sequence = options.longs === String ? "0" : 0;
        }
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            object.messageId = message.messageId;
        if (message.tc2Id != null && message.hasOwnProperty("tc2Id"))
            object.tc2Id = $root.MessageIdentifier.toObject(message.tc2Id, options);
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.ConversationState[message.state] === undefined ? message.state : $root.ConversationState[message.state] : message.state;
        if (message.sequence != null && message.hasOwnProperty("sequence"))
            if (typeof message.sequence === "number")
                object.sequence = options.longs === String ? String(message.sequence) : message.sequence;
            else
                object.sequence = options.longs === String ? $util.Long.prototype.toString.call(message.sequence) : options.longs === Number ? new $util.LongBits(message.sequence.low >>> 0, message.sequence.high >>> 0).toNumber() : message.sequence;
        return object;
    };

    ShareSequenceItemResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ShareSequenceItemResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ShareSequenceItemResponse";
    };

    return ShareSequenceItemResponse;
})();

export const MessageIdentifier = $root.MessageIdentifier = (() => {

    function MessageIdentifier(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MessageIdentifier.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    MessageIdentifier.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    MessageIdentifier.create = function create(properties) {
        return new MessageIdentifier(properties);
    };

    MessageIdentifier.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int64(message.id);
        writer.uint32(16).int64(message.ts);
        return writer;
    };

    MessageIdentifier.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MessageIdentifier.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MessageIdentifier();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int64();
                    break;
                }
            case 2: {
                    message.ts = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("ts"))
            throw $util.ProtocolError("missing required 'ts'", { instance: message });
        return message;
    };

    MessageIdentifier.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MessageIdentifier.fromObject = function fromObject(object) {
        if (object instanceof $root.MessageIdentifier)
            return object;
        let message = new $root.MessageIdentifier();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
        if (object.ts != null)
            if ($util.Long)
                (message.ts = $util.Long.fromValue(object.ts)).unsigned = false;
            else if (typeof object.ts === "string")
                message.ts = parseInt(object.ts, 10);
            else if (typeof object.ts === "number")
                message.ts = object.ts;
            else if (typeof object.ts === "object")
                message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber();
        return message;
    };

    MessageIdentifier.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.ts = options.longs === String ? "0" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
        if (message.ts != null && message.hasOwnProperty("ts"))
            if (typeof message.ts === "number")
                object.ts = options.longs === String ? String(message.ts) : message.ts;
            else
                object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber() : message.ts;
        return object;
    };

    MessageIdentifier.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MessageIdentifier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MessageIdentifier";
    };

    return MessageIdentifier;
})();

export const UnlockResponseCode = $root.UnlockResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "UNLOCK_SUCCESS"] = 0;
    values[valuesById[1] = "UNLOCK_SERVER_ERROR"] = 1;
    values[valuesById[2] = "UNLOCK_INVALID_REQUEST"] = 2;
    values[valuesById[3] = "UNLOCK_ALREADY_UNLOCKED"] = 3;
    values[valuesById[4] = "UNLOCK_DUPLICATE_REQUEST"] = 4;
    values[valuesById[5] = "UNLOCK_INSUFFICIENT_FUNDS"] = 5;
    values[valuesById[6] = "UNLOCK_ACTION_NOT_PERMITTED"] = 6;
    return values;
})();

export const UnlockRequest = $root.UnlockRequest = (() => {

    function UnlockRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UnlockRequest.prototype.messageId = "";

    UnlockRequest.create = function create(properties) {
        return new UnlockRequest(properties);
    };

    UnlockRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.messageId);
        return writer;
    };

    UnlockRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UnlockRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UnlockRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.messageId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("messageId"))
            throw $util.ProtocolError("missing required 'messageId'", { instance: message });
        return message;
    };

    UnlockRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UnlockRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UnlockRequest)
            return object;
        let message = new $root.UnlockRequest();
        if (object.messageId != null)
            message.messageId = String(object.messageId);
        return message;
    };

    UnlockRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.messageId = "";
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            object.messageId = message.messageId;
        return object;
    };

    UnlockRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UnlockRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UnlockRequest";
    };

    return UnlockRequest;
})();

export const UnlockResponse = $root.UnlockResponse = (() => {

    function UnlockResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UnlockResponse.prototype.responseCode = 0;

    UnlockResponse.create = function create(properties) {
        return new UnlockResponse(properties);
    };

    UnlockResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.responseCode);
        return writer;
    };

    UnlockResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UnlockResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UnlockResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.responseCode = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("responseCode"))
            throw $util.ProtocolError("missing required 'responseCode'", { instance: message });
        return message;
    };

    UnlockResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UnlockResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UnlockResponse)
            return object;
        let message = new $root.UnlockResponse();
        switch (object.responseCode) {
        default:
            if (typeof object.responseCode === "number") {
                message.responseCode = object.responseCode;
                break;
            }
            break;
        case "UNLOCK_SUCCESS":
        case 0:
            message.responseCode = 0;
            break;
        case "UNLOCK_SERVER_ERROR":
        case 1:
            message.responseCode = 1;
            break;
        case "UNLOCK_INVALID_REQUEST":
        case 2:
            message.responseCode = 2;
            break;
        case "UNLOCK_ALREADY_UNLOCKED":
        case 3:
            message.responseCode = 3;
            break;
        case "UNLOCK_DUPLICATE_REQUEST":
        case 4:
            message.responseCode = 4;
            break;
        case "UNLOCK_INSUFFICIENT_FUNDS":
        case 5:
            message.responseCode = 5;
            break;
        case "UNLOCK_ACTION_NOT_PERMITTED":
        case 6:
            message.responseCode = 6;
            break;
        }
        return message;
    };

    UnlockResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.responseCode = options.enums === String ? "UNLOCK_SUCCESS" : 0;
        if (message.responseCode != null && message.hasOwnProperty("responseCode"))
            object.responseCode = options.enums === String ? $root.UnlockResponseCode[message.responseCode] === undefined ? message.responseCode : $root.UnlockResponseCode[message.responseCode] : message.responseCode;
        return object;
    };

    UnlockResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UnlockResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UnlockResponse";
    };

    return UnlockResponse;
})();

export const DetailsResponseCode = $root.DetailsResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "DETAILS_SUCCESS"] = 0;
    values[valuesById[1] = "DETAILS_SERVER_ERROR"] = 1;
    values[valuesById[2] = "DETAILS_INVALID_REQUEST"] = 2;
    values[valuesById[3] = "DETAILS_ACTION_NOT_PERMITTED"] = 3;
    return values;
})();

export const DetailsRequest = $root.DetailsRequest = (() => {

    function DetailsRequest(properties) {
        this.messageId = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    DetailsRequest.prototype.messageId = $util.emptyArray;

    DetailsRequest.create = function create(properties) {
        return new DetailsRequest(properties);
    };

    DetailsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.messageId != null && message.messageId.length)
            for (let i = 0; i < message.messageId.length; ++i)
                writer.uint32(10).string(message.messageId[i]);
        return writer;
    };

    DetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    DetailsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DetailsRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.messageId && message.messageId.length))
                        message.messageId = [];
                    message.messageId.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    DetailsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    DetailsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.DetailsRequest)
            return object;
        let message = new $root.DetailsRequest();
        if (object.messageId) {
            if (!Array.isArray(object.messageId))
                throw TypeError(".DetailsRequest.messageId: array expected");
            message.messageId = [];
            for (let i = 0; i < object.messageId.length; ++i)
                message.messageId[i] = String(object.messageId[i]);
        }
        return message;
    };

    DetailsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.messageId = [];
        if (message.messageId && message.messageId.length) {
            object.messageId = [];
            for (let j = 0; j < message.messageId.length; ++j)
                object.messageId[j] = message.messageId[j];
        }
        return object;
    };

    DetailsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    DetailsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DetailsRequest";
    };

    return DetailsRequest;
})();

export const DetailsResponse = $root.DetailsResponse = (() => {

    function DetailsResponse(properties) {
        this.details = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    DetailsResponse.prototype.responseCode = 0;
    DetailsResponse.prototype.details = $util.emptyArray;

    DetailsResponse.create = function create(properties) {
        return new DetailsResponse(properties);
    };

    DetailsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.responseCode);
        if (message.details != null && message.details.length)
            for (let i = 0; i < message.details.length; ++i)
                $root.PremiumMessageDetails.encode(message.details[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    DetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    DetailsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DetailsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.responseCode = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.details && message.details.length))
                        message.details = [];
                    message.details.push($root.PremiumMessageDetails.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("responseCode"))
            throw $util.ProtocolError("missing required 'responseCode'", { instance: message });
        return message;
    };

    DetailsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    DetailsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.DetailsResponse)
            return object;
        let message = new $root.DetailsResponse();
        switch (object.responseCode) {
        default:
            if (typeof object.responseCode === "number") {
                message.responseCode = object.responseCode;
                break;
            }
            break;
        case "DETAILS_SUCCESS":
        case 0:
            message.responseCode = 0;
            break;
        case "DETAILS_SERVER_ERROR":
        case 1:
            message.responseCode = 1;
            break;
        case "DETAILS_INVALID_REQUEST":
        case 2:
            message.responseCode = 2;
            break;
        case "DETAILS_ACTION_NOT_PERMITTED":
        case 3:
            message.responseCode = 3;
            break;
        }
        if (object.details) {
            if (!Array.isArray(object.details))
                throw TypeError(".DetailsResponse.details: array expected");
            message.details = [];
            for (let i = 0; i < object.details.length; ++i) {
                if (typeof object.details[i] !== "object")
                    throw TypeError(".DetailsResponse.details: object expected");
                message.details[i] = $root.PremiumMessageDetails.fromObject(object.details[i]);
            }
        }
        return message;
    };

    DetailsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.details = [];
        if (options.defaults)
            object.responseCode = options.enums === String ? "DETAILS_SUCCESS" : 0;
        if (message.responseCode != null && message.hasOwnProperty("responseCode"))
            object.responseCode = options.enums === String ? $root.DetailsResponseCode[message.responseCode] === undefined ? message.responseCode : $root.DetailsResponseCode[message.responseCode] : message.responseCode;
        if (message.details && message.details.length) {
            object.details = [];
            for (let j = 0; j < message.details.length; ++j)
                object.details[j] = $root.PremiumMessageDetails.toObject(message.details[j], options);
        }
        return object;
    };

    DetailsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    DetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DetailsResponse";
    };

    return DetailsResponse;
})();

export const PremiumMessageDetails = $root.PremiumMessageDetails = (() => {

    function PremiumMessageDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PremiumMessageDetails.prototype.messageId = "";
    PremiumMessageDetails.prototype.type = 0;
    PremiumMessageDetails.prototype.status = 0;
    PremiumMessageDetails.prototype.thumbnailBlurUrl = "";
    PremiumMessageDetails.prototype.mediaInfo = null;
    PremiumMessageDetails.prototype.origin = 0;
    PremiumMessageDetails.prototype.unlockCounter = 0;

    PremiumMessageDetails.create = function create(properties) {
        return new PremiumMessageDetails(properties);
    };

    PremiumMessageDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.messageId);
        writer.uint32(16).int32(message.type);
        writer.uint32(24).int32(message.status);
        writer.uint32(34).string(message.thumbnailBlurUrl);
        $root.MediaInfo.encode(message.mediaInfo, writer.uint32(42).fork()).ldelim();
        if (message.origin != null && Object.hasOwnProperty.call(message, "origin"))
            writer.uint32(48).int32(message.origin);
        if (message.unlockCounter != null && Object.hasOwnProperty.call(message, "unlockCounter"))
            writer.uint32(56).int32(message.unlockCounter);
        return writer;
    };

    PremiumMessageDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PremiumMessageDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PremiumMessageDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.messageId = reader.string();
                    break;
                }
            case 2: {
                    message.type = reader.int32();
                    break;
                }
            case 3: {
                    message.status = reader.int32();
                    break;
                }
            case 4: {
                    message.thumbnailBlurUrl = reader.string();
                    break;
                }
            case 5: {
                    message.mediaInfo = $root.MediaInfo.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.origin = reader.int32();
                    break;
                }
            case 7: {
                    message.unlockCounter = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("messageId"))
            throw $util.ProtocolError("missing required 'messageId'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("status"))
            throw $util.ProtocolError("missing required 'status'", { instance: message });
        if (!message.hasOwnProperty("thumbnailBlurUrl"))
            throw $util.ProtocolError("missing required 'thumbnailBlurUrl'", { instance: message });
        if (!message.hasOwnProperty("mediaInfo"))
            throw $util.ProtocolError("missing required 'mediaInfo'", { instance: message });
        return message;
    };

    PremiumMessageDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PremiumMessageDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.PremiumMessageDetails)
            return object;
        let message = new $root.PremiumMessageDetails();
        if (object.messageId != null)
            message.messageId = String(object.messageId);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "PHOTO":
        case 0:
            message.type = 0;
            break;
        case "VIDEO":
        case 1:
            message.type = 1;
            break;
        }
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "LOCKED":
        case 0:
            message.status = 0;
            break;
        case "PENDING":
        case 1:
            message.status = 1;
            break;
        case "UNLOCKED":
        case 2:
            message.status = 2;
            break;
        }
        if (object.thumbnailBlurUrl != null)
            message.thumbnailBlurUrl = String(object.thumbnailBlurUrl);
        if (object.mediaInfo != null) {
            if (typeof object.mediaInfo !== "object")
                throw TypeError(".PremiumMessageDetails.mediaInfo: object expected");
            message.mediaInfo = $root.MediaInfo.fromObject(object.mediaInfo);
        }
        switch (object.origin) {
        default:
            if (typeof object.origin === "number") {
                message.origin = object.origin;
                break;
            }
            break;
        case "DIRECT":
        case 0:
            message.origin = 0;
            break;
        case "GROUP":
        case 1:
            message.origin = 1;
            break;
        }
        if (object.unlockCounter != null)
            message.unlockCounter = object.unlockCounter | 0;
        return message;
    };

    PremiumMessageDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.messageId = "";
            object.type = options.enums === String ? "PHOTO" : 0;
            object.status = options.enums === String ? "LOCKED" : 0;
            object.thumbnailBlurUrl = "";
            object.mediaInfo = null;
            object.origin = options.enums === String ? "DIRECT" : 0;
            object.unlockCounter = 0;
        }
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            object.messageId = message.messageId;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ContentType[message.type] === undefined ? message.type : $root.ContentType[message.type] : message.type;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.ContentStatus[message.status] === undefined ? message.status : $root.ContentStatus[message.status] : message.status;
        if (message.thumbnailBlurUrl != null && message.hasOwnProperty("thumbnailBlurUrl"))
            object.thumbnailBlurUrl = message.thumbnailBlurUrl;
        if (message.mediaInfo != null && message.hasOwnProperty("mediaInfo"))
            object.mediaInfo = $root.MediaInfo.toObject(message.mediaInfo, options);
        if (message.origin != null && message.hasOwnProperty("origin"))
            object.origin = options.enums === String ? $root.OriginType[message.origin] === undefined ? message.origin : $root.OriginType[message.origin] : message.origin;
        if (message.unlockCounter != null && message.hasOwnProperty("unlockCounter"))
            object.unlockCounter = message.unlockCounter;
        return object;
    };

    PremiumMessageDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PremiumMessageDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PremiumMessageDetails";
    };

    return PremiumMessageDetails;
})();

export const ContentSharedPayload = $root.ContentSharedPayload = (() => {

    function ContentSharedPayload(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ContentSharedPayload.prototype.giftId = "";
    ContentSharedPayload.prototype.messageId = "";
    ContentSharedPayload.prototype.thumbnailBlurUrl = "";
    ContentSharedPayload.prototype.type = 0;
    ContentSharedPayload.prototype.mediaInfo = null;
    ContentSharedPayload.prototype.origin = 0;

    ContentSharedPayload.create = function create(properties) {
        return new ContentSharedPayload(properties);
    };

    ContentSharedPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.giftId);
        writer.uint32(18).string(message.messageId);
        writer.uint32(26).string(message.thumbnailBlurUrl);
        writer.uint32(32).int32(message.type);
        $root.MediaInfo.encode(message.mediaInfo, writer.uint32(42).fork()).ldelim();
        if (message.origin != null && Object.hasOwnProperty.call(message, "origin"))
            writer.uint32(48).int32(message.origin);
        return writer;
    };

    ContentSharedPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ContentSharedPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ContentSharedPayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.giftId = reader.string();
                    break;
                }
            case 2: {
                    message.messageId = reader.string();
                    break;
                }
            case 3: {
                    message.thumbnailBlurUrl = reader.string();
                    break;
                }
            case 4: {
                    message.type = reader.int32();
                    break;
                }
            case 5: {
                    message.mediaInfo = $root.MediaInfo.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.origin = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("giftId"))
            throw $util.ProtocolError("missing required 'giftId'", { instance: message });
        if (!message.hasOwnProperty("messageId"))
            throw $util.ProtocolError("missing required 'messageId'", { instance: message });
        if (!message.hasOwnProperty("thumbnailBlurUrl"))
            throw $util.ProtocolError("missing required 'thumbnailBlurUrl'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("mediaInfo"))
            throw $util.ProtocolError("missing required 'mediaInfo'", { instance: message });
        return message;
    };

    ContentSharedPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ContentSharedPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.ContentSharedPayload)
            return object;
        let message = new $root.ContentSharedPayload();
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.messageId != null)
            message.messageId = String(object.messageId);
        if (object.thumbnailBlurUrl != null)
            message.thumbnailBlurUrl = String(object.thumbnailBlurUrl);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "PHOTO":
        case 0:
            message.type = 0;
            break;
        case "VIDEO":
        case 1:
            message.type = 1;
            break;
        }
        if (object.mediaInfo != null) {
            if (typeof object.mediaInfo !== "object")
                throw TypeError(".ContentSharedPayload.mediaInfo: object expected");
            message.mediaInfo = $root.MediaInfo.fromObject(object.mediaInfo);
        }
        switch (object.origin) {
        default:
            if (typeof object.origin === "number") {
                message.origin = object.origin;
                break;
            }
            break;
        case "DIRECT":
        case 0:
            message.origin = 0;
            break;
        case "GROUP":
        case 1:
            message.origin = 1;
            break;
        }
        return message;
    };

    ContentSharedPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.giftId = "";
            object.messageId = "";
            object.thumbnailBlurUrl = "";
            object.type = options.enums === String ? "PHOTO" : 0;
            object.mediaInfo = null;
            object.origin = options.enums === String ? "DIRECT" : 0;
        }
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            object.messageId = message.messageId;
        if (message.thumbnailBlurUrl != null && message.hasOwnProperty("thumbnailBlurUrl"))
            object.thumbnailBlurUrl = message.thumbnailBlurUrl;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ContentType[message.type] === undefined ? message.type : $root.ContentType[message.type] : message.type;
        if (message.mediaInfo != null && message.hasOwnProperty("mediaInfo"))
            object.mediaInfo = $root.MediaInfo.toObject(message.mediaInfo, options);
        if (message.origin != null && message.hasOwnProperty("origin"))
            object.origin = options.enums === String ? $root.OriginType[message.origin] === undefined ? message.origin : $root.OriginType[message.origin] : message.origin;
        return object;
    };

    ContentSharedPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ContentSharedPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ContentSharedPayload";
    };

    return ContentSharedPayload;
})();

export const ContentUnlockCounterPayload = $root.ContentUnlockCounterPayload = (() => {

    function ContentUnlockCounterPayload(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ContentUnlockCounterPayload.prototype.tc2Id = null;
    ContentUnlockCounterPayload.prototype.messageId = "";
    ContentUnlockCounterPayload.prototype.unlockCounter = 0;

    ContentUnlockCounterPayload.create = function create(properties) {
        return new ContentUnlockCounterPayload(properties);
    };

    ContentUnlockCounterPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.MessageIdentifier.encode(message.tc2Id, writer.uint32(10).fork()).ldelim();
        writer.uint32(18).string(message.messageId);
        writer.uint32(24).int32(message.unlockCounter);
        return writer;
    };

    ContentUnlockCounterPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ContentUnlockCounterPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ContentUnlockCounterPayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.tc2Id = $root.MessageIdentifier.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.messageId = reader.string();
                    break;
                }
            case 3: {
                    message.unlockCounter = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("tc2Id"))
            throw $util.ProtocolError("missing required 'tc2Id'", { instance: message });
        if (!message.hasOwnProperty("messageId"))
            throw $util.ProtocolError("missing required 'messageId'", { instance: message });
        if (!message.hasOwnProperty("unlockCounter"))
            throw $util.ProtocolError("missing required 'unlockCounter'", { instance: message });
        return message;
    };

    ContentUnlockCounterPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ContentUnlockCounterPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.ContentUnlockCounterPayload)
            return object;
        let message = new $root.ContentUnlockCounterPayload();
        if (object.tc2Id != null) {
            if (typeof object.tc2Id !== "object")
                throw TypeError(".ContentUnlockCounterPayload.tc2Id: object expected");
            message.tc2Id = $root.MessageIdentifier.fromObject(object.tc2Id);
        }
        if (object.messageId != null)
            message.messageId = String(object.messageId);
        if (object.unlockCounter != null)
            message.unlockCounter = object.unlockCounter | 0;
        return message;
    };

    ContentUnlockCounterPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.tc2Id = null;
            object.messageId = "";
            object.unlockCounter = 0;
        }
        if (message.tc2Id != null && message.hasOwnProperty("tc2Id"))
            object.tc2Id = $root.MessageIdentifier.toObject(message.tc2Id, options);
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            object.messageId = message.messageId;
        if (message.unlockCounter != null && message.hasOwnProperty("unlockCounter"))
            object.unlockCounter = message.unlockCounter;
        return object;
    };

    ContentUnlockCounterPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ContentUnlockCounterPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ContentUnlockCounterPayload";
    };

    return ContentUnlockCounterPayload;
})();

export { $root as default };
