import { createSlice } from "@reduxjs/toolkit";
import { PhoneNumberVerificationSteps } from "src/features/profile/settings/common/enums";
import { getNextSMSResendTimestamp } from "src/features/profile/settings/components/PhoneNumberEditModal/ConnectPhoneStep/utils";
import { sendValidationSMSAction } from "src/features/profile/settings/state/fetchProfileSettingsAction";
import { Nullable } from "src/types/common";
import { Country } from "state/tree/countries";

export interface PhoneNumberSettingsState {
  codeInputError: boolean;
  country: Nullable<Country>;
  phoneInputError: boolean;
  phoneNumberToVerify: string;
  phoneNumberVerificationStep: PhoneNumberVerificationSteps;
  timestampByPhone: Record<string, number>;
}

const initialState: PhoneNumberSettingsState = {
  phoneNumberVerificationStep: PhoneNumberVerificationSteps.NONE,
  timestampByPhone: {},
  phoneNumberToVerify: "",
  country: null,
  phoneInputError: false,
  codeInputError: false,
};

export const persistConfig = {
  whitelist: ["timestampByPhone"],
};

const slice = createSlice({
  name: "phoneNumberSettings",
  initialState,
  reducers: {
    setPhoneNumberToVerify(state, { payload }) {
      state.phoneNumberToVerify = payload;
    },
    setPhoneVerificationStep(state, { payload }) {
      state.phoneNumberVerificationStep = payload;
      state.codeInputError = false;
    },
    setSelectedCountryInfo(state, { payload }) {
      state.country = payload;
    },
    setPhoneInputError(state, { payload }) {
      state.phoneInputError = payload;
    },
    setCodeInputError(state, { payload }) {
      state.codeInputError = payload;
    },
    resetPhoneVerification() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendValidationSMSAction.fulfilled, (state, { payload }) => {
      const phone = state.phoneNumberToVerify;
      state.timestampByPhone[phone] = getNextSMSResendTimestamp(
        payload.resendDelay
      );
    });
  },
});

export const {
  setPhoneNumberToVerify,
  setPhoneVerificationStep,
  setSelectedCountryInfo,
  setPhoneInputError,
  setCodeInputError,
  resetPhoneVerification,
} = slice.actions;

export const phoneNumberSettingsReducer = slice.reducer;
