import React, { memo } from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/features/signin/imports/types";
import { useBreakpoint } from "src/features/signin/imports/ui";
import { ReactComponent as LogoIcon } from "img/login/logo.svg";
import styles from "./Logo.scss";

const Logo: ComponentWithClassName = ({ className }) => {
  const breakpoint = useBreakpoint();

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <LogoIcon className={className} />
    </div>
  );
};

export default memo(Logo);
