import { useRouteMatch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { streamsCacheSelectors, viewerSessionSelectors } from "state/selectors";

const useLandscapeStream = () => {
  const isStream = useRouteMatch({
    path: "/stream/",
    strict: false,
  });

  const stream = useSelector(
    (state) =>
      streamsCacheSelectors.getStreamById(
        state,
        viewerSessionSelectors.getStreamId(state) || null
      ),
    shallowEqual
  );

  if (!isStream || !stream) {
    return false;
  }

  return stream?.landscape;
};

export default useLandscapeStream;
