import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useWebViewDetector } from "src/core/webview/WebViewController/useWebViewDetector";
import { useWebViewEnvironmentPropertyController } from "src/core/webview/WebViewController/useWebViewEnvironmentPropertyController";
import { useWebViewQueryParamRemover } from "src/core/webview/WebViewController/useWebViewQueryParamRemover";
import { actionCreators } from "state/tree/deviceInfo";

const { setWebview } = actionCreators;

export const WebViewController = () => {
  const dispatch = useDispatch();
  const isWebView = useWebViewDetector();

  useLayoutEffect(() => {
    // Set the WebView mode till the end of the user's session
    if (isWebView) {
      dispatch(setWebview(isWebView));
    }
  }, [dispatch, isWebView]);

  useWebViewEnvironmentPropertyController();
  useWebViewQueryParamRemover();

  return null;
};
