import React from "react";
import { ComponentWithClassName } from "src/features/welcomeOnboardingOffer/imports/types";
import {
  FormattedNumber,
  TYPOGRAPHY_TYPE,
  Typography,
} from "src/features/welcomeOnboardingOffer/imports/ui";
import twoCoinSrc from "img/ic_two_coin.png";
import classes from "./CoinsAmount.scss";

interface CoinsAmountProps {
  amount: number;
}

export const CoinsAmount: ComponentWithClassName<CoinsAmountProps> = ({
  className,
  amount,
}) => (
  <div className={className} data-testid="coins-amount">
    <img className={classes.image} src={twoCoinSrc} />

    <Typography type={TYPOGRAPHY_TYPE.HERO4}>
      <FormattedNumber value={amount || 0} />
    </Typography>
  </div>
);
