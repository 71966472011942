import { useSelector } from "react-redux";
import { deviceInfoSelectors } from "chat/imports/state";

const useGetLocale = (localeOverride: string) => {
  const locale: string = useSelector(deviceInfoSelectors.getDeviceLocale);

  if (locale === "ar") {
    // wrong format date/time for AR locale
    return localeOverride;
  }

  return locale;
};

export default useGetLocale;
