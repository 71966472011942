import { createAction } from "@reduxjs/toolkit";
import { GiftsBatchResponse } from "api/typedGifts";
import { OneClickOfferTypes } from "enums/oneClickOffers";

export const giftsBatchReceived = createAction<GiftsBatchResponse>(
  "lwc/gifts/batchReceived"
);

export const setDefaultPremiumMessageGiftIds = createAction<string[]>(
  "lwc/gifts/setDefaultGiftIds"
);

export const selectPremiumMessageGift = createAction<string>(
  "lwc/gifts/selectPremiumMessageGift"
);

export const setOneClickFlowLocation = createAction<OneClickOfferTypes>(
  "lwc/gifts/setOneClickFlowLocation"
);
