import React, { forwardRef } from "react";
import classnames from "classnames";
import {
  NavigationComponent as LegalNavigationComponent,
  useBreakpointPrecise,
} from "src/features/ofac/imports/ui";
import {
  legalNavigationPrivacyPolicyConfig,
  legalNavigationTermsOfUseConfig,
} from "src/features/ofac/legalNavigationConfig";
import styles from "./LegalNavigation.scss";

const restrictedLegalNavigationConfig = [
  legalNavigationTermsOfUseConfig,
  legalNavigationPrivacyPolicyConfig,
];

export const LegalNavigation = forwardRef<HTMLElement>((_, ref) => {
  const breakpoint = useBreakpointPrecise();

  return (
    <nav
      className={classnames(styles[breakpoint], styles.nav)}
      data-testid="legal-navigation"
      ref={ref}
    >
      <LegalNavigationComponent
        navigationConfig={restrictedLegalNavigationConfig}
      />
    </nav>
  );
});

LegalNavigation.displayName = "LegalNavigation";
