import React from "react";
import SendMediaView from "chat/components/sendMediaView/SendMediaView";
import { VoidCallback } from "chat/imports/types";
import { SendMediaData } from "chat/types";

type SendMediaToChatBottomScreenViewProps = {
  dismiss: VoidCallback;
  screenData: SendMediaData;
};

const SendMediaToChatBottomScreenView = ({
  screenData,
  dismiss,
}: SendMediaToChatBottomScreenViewProps) => (
  <SendMediaView data={screenData} onDismiss={dismiss} />
);

export default SendMediaToChatBottomScreenView;
