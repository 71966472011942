import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import { IconArrowLeft } from "src/features/ofac/imports/img";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  useBreakpointPrecise,
  useWindowScroll,
} from "src/features/ofac/imports/ui";
import styles from "./ScrollToTopButton.scss";
import transition from "./ScrollToTopButtonTransition.scss";

interface ScrollToTopButtonProps {
  shouldShow: boolean;
}

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({
  shouldShow,
}) => {
  const breakpoint = useBreakpointPrecise();
  const { scrollToTop } = useWindowScroll();

  return (
    <CSSTransition
      classNames={transition}
      in={shouldShow}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <Button
        size={ButtonSize.CIRCLE_BIG_48}
        variant={ButtonVariant.SECONDARY}
        onClick={scrollToTop}
        className={classnames(styles[breakpoint], styles.button)}
        dataTestId="scroll-to-top-button"
      >
        <IconArrowLeft className={styles.rotate} />
      </Button>
    </CSSTransition>
  );
};
