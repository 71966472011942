import { getProxycadorHost } from "environment";
import fetch from "api/utils/enhancedFetch";
import { LeaderBoardLanding } from "src/types/leaderBoard";

export const fetchLeaderBoard = (name?: string): Promise<LeaderBoardLanding> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/leaderboard/v1/leaderboards/${name}`,
    {
      headers: {
        Accept: "application/json;charset=UTF-8",
      },
    }
  ).then((resp) => resp.json());
