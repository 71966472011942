import { happyMomentsResponseAdapter } from "api/utils/adapters";
import fetch, { urlAndParams } from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { FeedByAccountResponse } from "generated/proto/FeedApi";
import { DEFAULT_MOMENTS_POST_COUNT } from "src/constants";
import {
  BaseResponse,
  MomentsResponse,
  PostTypeCode,
  Story,
} from "src/types/happyMoments";

export const sendFreeLike = (id: string): Promise<BaseResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/happymoments/freeLike/${id}`,
    {
      method: "POST",
      body: "",
    }
  ).then((resp) => resp.json());

export const fetchBestByStreamer = (streamerId: string): Promise<Story> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/happymoments/bestByStreamer/${streamerId}?excludeUnmoderated=false&timestamp=-1`
  ).then((resp) => resp.json());

export const fetchStreamerMoments = ({
  streamerId,
  cursor = "",
  count = DEFAULT_MOMENTS_POST_COUNT,
}: {
  count?: number;
  cursor?: string;
  streamerId: string;
}): Promise<MomentsResponse> =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/feed/v9/feed/${streamerId}`, {
      include_post_types: PostTypeCode.MOMENTS,
      count,
      cursor,
    }),
    {
      method: "GET",
      headers: {
        Accept: "application/x-protobuf",
        "Content-Type": "application/json",
      },
    }
  )
    .then((resp) => resp.arrayBuffer())
    .then((buffer) =>
      happyMomentsResponseAdapter(
        FeedByAccountResponse.decode(new Uint8Array(buffer), undefined)
      )
    );

export const fetchBatchMomentsInfo = (
  ids: string[]
): Promise<{ stories: Story[] } & BaseResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/happymoments/infoBatch`,
    {
      method: "POST",
      body: JSON.stringify(ids),
      headers: { "Content-Type": "application/json" },
    }
  ).then((resp) => resp.json());

export const sendWatchedMomentIds = (ids: string[]): Promise<BaseResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/happymoments/watched`,
    {
      method: "POST",
      body: JSON.stringify(ids),
      headers: { "Content-Type": "application/json" },
    }
  ).then((resp) => resp.json());

export const deleteHappyMoment = (id: string): Promise<BaseResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/happymoments/hide/${id}`,
    {
      method: "POST",
      body: "",
    }
  ).then((resp) => resp.json());
