import { v4 as uuidv4 } from "uuid";
import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { ClientSessionEvent } from "generated/proto/ClientSessionEventModel";
import { ClientEventsRequest } from "generated/proto/EventGateway";
import { Platforms } from "src/core/analytics/enums";
import { currentTimeMillis } from "utils/dateUtils";

interface SendEventToGatewayType {
  isUserLoggedIn: boolean;
  name: string;
  payload: unknown;
}

export const sendEventToGateway = ({
  isUserLoggedIn,
  name,
  payload,
}: SendEventToGatewayType) => {
  const timestamp = currentTimeMillis();
  const uid = uuidv4();
  const eventGatewayUrl = isUserLoggedIn
    ? `${getProxycadorHost()}/event-gateway/api/v1/events`
    : `${getProxycadorHost()}/event-gateway/api/v1/anonymous-events`;

  const parameters = {
    uid, // required
    clientSessionId: uid, // required
    eventTime: timestamp, // required
    eventSource: Platforms.WEB,
    screenName: "",
    screenState: "",
  };

  return fetch(eventGatewayUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-protobuf;charset=UTF-8",
    },
    body: ClientEventsRequest.encode({
      events: [
        {
          name,
          payload: ClientSessionEvent.encode(payload).finish(),
          parameters,
        },
      ],
    }).finish(),
  });
};
