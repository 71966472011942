import { useSelector } from "react-redux";
import {
  ModalType,
  bottomScreenSelectors,
  modalSelectors,
} from "src/features/offers/shared/imports";

export const useIsCashierOrRefillOpen = () => {
  const topModalType = useSelector(modalSelectors.topModalType);
  const bottomScreenType = useSelector(bottomScreenSelectors.screenType);

  const isCashier =
    topModalType === ModalType.CASHIER_MODAL ||
    bottomScreenType === ModalType.CASHIER_MODAL;

  const isRefill =
    topModalType === ModalType.REFILL_MODAL ||
    bottomScreenType === ModalType.REFILL_MODAL;

  return {
    isCashier,
    isRefill,
    isCashierOrRefill: isCashier || isRefill,
  };
};
