import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Currency } from "src/enums";
import { VoidCallback } from "src/types/common";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { ReactComponent as CloseIcon } from "img/close-rounded.svg";
import { ReactComponent as CheckIcon } from "img/common/select/checked_24.svg";
import styles from "./CurrencyBottomScreen.scss";

interface CurrencyItem {
  label: string;
  value: Currency;
}

interface CurrencyItemProps {
  currency: CurrencyItem;
  isSelected: boolean;
  onClick: VoidCallback;
}

const CurrencyItem = ({ currency, onClick, isSelected }: CurrencyItemProps) => (
  <li className={styles.currencyItem} onClick={onClick}>
    <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH1} className={styles.language}>
      {currency.label}
    </Typography>

    {isSelected && (
      <CheckIcon className={styles.checkIcon} width={32} height={32} />
    )}
  </li>
);

export interface CurrencyBottomScreenProps {
  dismiss: (cb?: VoidCallback) => void;
  screenData: {
    currencies: CurrencyItem[];
    onChange: (currency: CurrencyItem) => void;
    onDismiss?: VoidCallback;
    selected: CurrencyItem;
    variant: "shader" | "solid";
  };
}

export const CurrencyBottomScreen: FC<CurrencyBottomScreenProps> = ({
  dismiss,
  screenData: { currencies, onChange, selected, variant, onDismiss },
}) => {
  const breakpoint = useBreakpointPrecise();

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], styles[variant])}
    >
      <div className={styles.header}>
        <Typography type={TYPOGRAPHY_TYPE.ALL_CAPS}>
          <FormattedMessage {...sharedMessages.currency} />
        </Typography>

        <Button
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.ICON_ONLY}
          onClick={() => dismiss(onDismiss)}
          className={styles.closeButton}
        >
          <CloseIcon />
        </Button>
      </div>

      <ul className={styles.currencyList}>
        {currencies.map((currencyItem) => (
          <CurrencyItem
            key={currencyItem.label}
            currency={currencyItem}
            isSelected={currencyItem.value === selected.value}
            onClick={() => {
              dismiss(onDismiss);
              onChange(currencyItem);
            }}
          />
        ))}
      </ul>
    </div>
  );
};
