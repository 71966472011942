import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { addUserSessionScopeReducer } from "chat/imports/state";
import { Gift } from "chat/imports/types";

type GiftAnimation = { giftId: Gift["id"]; id: string };

export type ChatGiftAnimationState = {
  giftsToPlay: GiftAnimation[];
};

const initialState: ChatGiftAnimationState = {
  giftsToPlay: [],
};

const slice = createSlice({
  name: "chatGiftAnimation",
  initialState,
  reducers: {
    addGiftToPlay(state, action: PayloadAction<Gift["id"]>) {
      state.giftsToPlay.push({ id: uuidv4(), giftId: action.payload });
    },
    giftPlayed(state) {
      state.giftsToPlay.shift();
    },
    clearGiftsToPlay(state) {
      state.giftsToPlay = [];
    },
  },
  extraReducers(builder) {
    addUserSessionScopeReducer(builder, () => initialState);
  },
});

export const { addGiftToPlay, giftPlayed, clearGiftsToPlay } = slice.actions;

export const chatGiftAnimationReducer = slice.reducer;
