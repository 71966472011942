import emojiRegex from "emoji-regex";

export const getEmojiFromString = (text: string, all = false) => {
  const matches: RegExpExecArray[] = [];
  const regex = emojiRegex();
  let result = regex.exec(text);
  result && matches.push(result);

  if (all) {
    while (result != null) {
      result = regex.exec(text);
      result && matches.push(result);
    }
  }
  return matches;
};

export const removeEmojiFromString = (text: string) => {
  if (!text.length) {
    return text;
  }

  const regex = emojiRegex();
  return text.replace(regex, "");
};
