import React from "react";
import { PaymentProvider } from "src/types/payment";
import useQueryToPatchEnvironment from "./hooks/useQueryToPatchEnvironment";
import { getEnabledPaymentProviders } from "environment";
import { uniq } from "src/utils/miniLodash";

const queryToProvider = {
  purchaseCoinsWithCheckout: PaymentProvider.CHECKOUT,
  purchaseCoinsWithSafeCharge: PaymentProvider.SAFECHARGE,
  purchaseCoinsWithSafeChargeUs: PaymentProvider.SAFECHARGE_US,
  purchaseCoinsWithTango: PaymentProvider.TANGO,
  purchaseCoinsWithAll: Object.values(PaymentProvider).join(","),
  purchaseCoinsReset: "",
};

const PaymentsTestController: React.FC = () => {
  useQueryToPatchEnvironment({
    propertyKey: "purchase.providers",
    queryMap: queryToProvider,
    preprocessValue: (value: string) => {
      if (getEnabledPaymentProviders().includes(PaymentProvider.MINTROUTE)) {
        return uniq([...value.split(","), PaymentProvider.MINTROUTE]).join(",");
      }
      return value;
    },
  });
  return null;
};

export default PaymentsTestController;
