import { enqueuePendingAction } from "state/middleware/pendingActionsListener";
import { captchaSelectors } from "state/selectors";
import isLockedByCaptcha from "src/utils/isLockedByCaptcha";

export default (error, action) => {
  if (error && isLockedByCaptcha(error)) {
    enqueuePendingAction({
      check: ({ oldState, newState }) =>
        captchaSelectors.isLockedByCaptcha(oldState) &&
        !captchaSelectors.isLockedByCaptcha(newState),
      actionCreator: () => action,
    });
  }
};
