import { getProxycadorHost } from "environment";
import { DEFAULT_REFERRED_USERS_LIST_REQUEST } from "src/features/referralProgram/common/constants";
import { ReferredUsersListResponse } from "src/features/referralProgram/common/types";
import {
  Params,
  enhancedFetch,
  urlAndParams,
} from "src/features/referralProgram/imports/utils";

export const loadReferredUsersListRequest = (
  params = DEFAULT_REFERRED_USERS_LIST_REQUEST
): Promise<ReferredUsersListResponse> => {
  const requestParams = {
    ...DEFAULT_REFERRED_USERS_LIST_REQUEST,
    ...params,
  };

  return enhancedFetch(
    urlAndParams(
      `${getProxycadorHost()}/referral/v2/statistics`,
      requestParams as Params
    ),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  ).then((resp) => resp.json());
};
