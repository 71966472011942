import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import classnames from "classnames";
import {
  defaultLocationState,
  emitEnteredLiveFamily,
} from "chat/analytics/emitEnteredLiveFamily";
import DragOverlay from "chat/components/DragOverlay/DragOverlay";
import EmptyStateWithError from "chat/components/common/EmptyStateWithError";
import { ConversationMuteContextProvider } from "chat/components/currentConversation/ConversationMuteContext";
import { ConversationTangoScreenViewAnalytics } from "chat/components/currentConversation/ConversationTangoScreenViewAnalytics";
import { CHAT_OLDER_MESSAGES_PAGE_SIZE } from "chat/constants";
import { Direction } from "chat/enums";
import { GiftChatAnimationOverlay } from "chat/giftAnimation/export/ui";
import {
  Breakpoints,
  OneClickOfferTypes,
  linkToMessageRequest,
} from "chat/imports/constants";
import { LightboxProvider } from "chat/imports/context";
import {
  getChatMaxMediaSize,
  getIsChatMediaEnabled,
} from "chat/imports/environment";
import { useBreakpointPrecise } from "chat/imports/hooks";
import {
  RootState,
  giftsCacheActionCreators as actionCreators,
  blockedUsersSelectors,
  getChatSendVideoEnabled,
  giftActionCreators,
  openSendMediaBottomScreen,
  openSendMediaToChatModal,
} from "chat/imports/state";
import { messageRequestSelectors } from "chat/messageRequest/exports/state";
import { removeMessagesRequest } from "chat/messageRequest/state/asyncAction";
import { fetchConversation } from "chat/state/actionCreators";
import { StoredConversation, StoredMessage } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import {
  ChatMessageSentFlags,
  ConversationScreenState,
  ConversationState,
  SingleChatMessageSentComment,
} from "chat/types";
import ConversationHeader from "./ConversationHeader";
import ConversationInputBar from "./ConversationInputBar";
import ConversationMessages from "./ConversationMessages";
import styles from "./CurrentConversation.scss";

const MEDIA_MAX_SIZE = getChatMaxMediaSize();

const acceptImages: Accept = {
  "image/png": [],
  "image/jpeg": [],
  "image/heic": [],
  "image/heif": [],
  "image/gif": [],
  "image/bmp": [],
};

const acceptVideos: Accept = {
  "video/mp4": [],
};

interface CurrentConversationProps {
  className?: string;
  conversation: StoredConversation;
  setIsRequestsVisible: (state: boolean) => void;
}

const selector =
  (conversation: StoredConversation, conversationId: string) =>
  (state: RootState) => {
    const { conversation_id } = conversation;

    return {
      isChatSendVideoEnabled: getChatSendVideoEnabled(state),
      isConversationLoadingFailed: chatSelectors.getConversationLoadingFailed(
        state,
        conversation_id
      ),
      isConversationLoading: chatSelectors.getConversation(
        state,
        conversation_id
      )?.isLoading,
      isBlocked: blockedUsersSelectors
        .getAllBlockedUsersArray(state)
        .includes(conversationId),
      latestMessageTimestamp: messageRequestSelectors.getConversation(
        state,
        conversationId
      )?.lastMessageTs,
      conversationMessagesByDates: chatSelectors.getConversationMessagesByDates(
        state,
        conversation_id
      ),
    };
  };

// key-ing ConversationMessages to reset scroll
const CurrentConversation: React.FC<CurrentConversationProps> = ({
  className,
  conversation,
  setIsRequestsVisible,
}) => {
  const { group_info, conversation_id, state, account_info, hidden } =
    conversation;
  const [isChatRequestDisclaimerHidden, setIsChatRequestDisclaimerHidden] =
    useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentMessageTimestamp, setCurrentMessageTimestamp] = useState(0);
  const isChatRequest = state === ConversationState.CHAT_REQUEST && !!hidden;
  const { state: locationState = defaultLocationState } = useLocation<
    { source: number } | undefined
  >();
  const {
    isChatSendVideoEnabled,
    isConversationLoadingFailed,
    isConversationLoading,
    isBlocked,
    latestMessageTimestamp,
    conversationMessagesByDates,
  } = useSelector(
    useCallback(selector(conversation, conversation_id), [conversation]),
    shallowEqual
  );
  const canSendMessage = !group_info || !group_info.left;
  const isChatMediaEnabled = getIsChatMediaEnabled();
  const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;
  const acceptMimeTypes = {
    ...acceptImages,
    ...(isChatSendVideoEnabled && acceptVideos),
  };

  const { dates } = conversationMessagesByDates;
  const isPreloadedMessageState =
    !dates.length || (dates.length === 1 && dates[0].messages.length === 1);

  const messages = dates.reduce((acc, item) => {
    acc.push(...item.messages);

    return acc;
  }, [] as StoredMessage[]);
  useEffect(() => {
    if (isChatRequest && isBlocked) {
      dispatch(removeMessagesRequest({ conversationId: conversation_id }));
      history.push(linkToMessageRequest);
    }
  }, [isChatRequest, isBlocked, conversation_id, dispatch]);

  const retryLoadConversation = useCallback(() => {
    dispatch(
      fetchConversation({
        conversationId: conversation_id,
        direction: Direction.FORWARD,
        start_timestamp: 0,
        include_group_info: true,
        include_group_members: true,
        limit: CHAT_OLDER_MESSAGES_PAGE_SIZE,
      })
    );
  }, [conversation_id, dispatch]);

  useEffect(() => {
    setCurrentMessageTimestamp(latestMessageTimestamp);
  }, [latestMessageTimestamp]);

  useEffect(() => {
    if (
      currentMessageTimestamp < latestMessageTimestamp &&
      conversation.state === ConversationState.CHAT_REQUEST
    ) {
      retryLoadConversation();
    }
  }, [currentMessageTimestamp, latestMessageTimestamp, conversation.state]);

  useEffect(() => {
    batch(() => {
      dispatch(
        actionCreators.setOneClickFlowLocation(
          OneClickOfferTypes.ONE_CLICK_MESSAGES
        )
      );

      dispatch(
        giftActionCreators.setChatGiftingContext({
          chatId: conversation_id,
          recipientId: account_info?.account_id,
        })
      );
    });
  }, [dispatch, account_info?.account_id, conversation_id]);

  const analyticsComment = useMemo(() => {
    if (isChatRequest) {
      return SingleChatMessageSentComment.CHAT_REQUEST_ACCEPTED;
    }

    if (messages.length === 0) {
      return SingleChatMessageSentComment.CHAT_REQUEST_SENT;
    }

    return SingleChatMessageSentComment.EMPTY;
  }, [isChatRequest, messages]);

  const analyticsParams = useMemo(
    () => ({
      recipientAccountId: conversation_id,
      chatId: conversation_id,
      peerId: conversation_id,
      flags: [ChatMessageSentFlags.TEXT],
      screenState: isChatRequest
        ? ConversationScreenState.CHAT_REQUEST
        : ConversationScreenState.ONE_ON_ONE_CHAT,
      comment: analyticsComment,
      membersCnt: conversation?.group_info?.groupMembersCount || 0,
    }),
    [conversation_id, conversation, isChatRequest]
  );

  const handleDropFiles = (files: File[]) => {
    if (!files.length) {
      return;
    }

    const data = {
      file: files[0],
      conversationId: conversation_id,
      analyticsParams,
    };
    const action = isDesktop
      ? openSendMediaToChatModal(data)
      : openSendMediaBottomScreen({ screenData: data });

    dispatch(action);
  };

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptMimeTypes,
    onDrop: handleDropFiles,
    disabled: !isChatMediaEnabled,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: MEDIA_MAX_SIZE,
  });

  useEffect(() => {
    group_info?.creatorId &&
      emitEnteredLiveFamily({
        ownerId: group_info.creatorId,
        locationState,
      });
  }, [group_info?.creatorId]);

  const renderMessagesList = useMemo(
    () =>
      isConversationLoadingFailed ||
      (isConversationLoadingFailed && isConversationLoading) ? (
        <EmptyStateWithError onClick={retryLoadConversation} />
      ) : (
        <ConversationMessages
          key={`list-${conversation_id}`}
          conversationId={conversation_id}
          accountInfo={account_info}
          isChatRequestDisclaimerHidden={isChatRequestDisclaimerHidden}
          isHidden={!!hidden}
          state={state}
          analyticsParams={analyticsParams}
        />
      ),
    [
      conversation_id,
      isConversationLoadingFailed,
      isConversationLoading,
      isChatRequestDisclaimerHidden,
      state,
      hidden,
    ]
  );

  useEffect(() => {
    const conversationInputBarHtmlElement = document.querySelector<HTMLElement>(
      "[data-testid='conversation-input-bar']"
    );

    const conversationMessageListElement = document.querySelector<HTMLElement>(
      "[data-testid='conversation-messages-list']"
    );

    const timerId = setTimeout(() => {
      if (conversationInputBarHtmlElement && conversationMessageListElement) {
        const height =
          conversationInputBarHtmlElement.getBoundingClientRect().height;

        conversationMessageListElement.style.setProperty(
          "--scrollable-container-padding-value",
          `${height}px`
        );

        conversationMessageListElement.style.setProperty(
          "--scrollable-container-disclaimer-padding-value",
          `${height}px`
        );
      }
    }, 0);

    return () => clearTimeout(timerId);
  }, [isConversationLoading, state]);

  return (
    <ConversationTangoScreenViewAnalytics
      conversation={conversation}
      isDesktop={isDesktop}
    >
      <LightboxProvider>
        <ConversationMuteContextProvider>
          <div
            {...getRootProps({
              className: classnames(className, styles.root),
              "data-testid": `conversation-${conversation_id}`,
            })}
          >
            <input {...getInputProps()} />
            <ConversationHeader conversation={conversation} />
            {renderMessagesList}
            {canSendMessage && (
              <ConversationInputBar
                isConversationLoadingFailed={isConversationLoadingFailed}
                isBlocked={isBlocked}
                autoFocus={isDesktop}
                key={`input-${conversation_id}`}
                conversationId={conversation_id}
                companionId={account_info?.account_id}
                openFileManager={open}
                setIsRequestsVisible={setIsRequestsVisible}
                isHidden={!!hidden}
                state={state}
                isStateMessageRequest={
                  conversation.state === ConversationState.CHAT_REQUEST
                }
                accountInfo={account_info}
                isChatRequestDisclaimerHidden={isChatRequestDisclaimerHidden}
                setIsChatRequestDisclaimerHidden={
                  setIsChatRequestDisclaimerHidden
                }
                isConversationLoading={
                  isPreloadedMessageState && isConversationLoading
                }
                analyticsParams={analyticsParams}
              />
            )}
          </div>
          <GiftChatAnimationOverlay />
        </ConversationMuteContextProvider>
      </LightboxProvider>
      {isDragActive && <DragOverlay />}
    </ConversationTangoScreenViewAnalytics>
  );
};

export default memo(CurrentConversation);
