import { VoidCallback } from "src/types/common";

export const jsonParse = <T>(
  value: string,
  onErrorCallback?: VoidCallback
): T | undefined => {
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    onErrorCallback?.();
  }
};
