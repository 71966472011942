import { batch } from "react-redux";
import { ModalType } from "src/features/signin/imports/enums";
import {
  FROM_FLOW,
  dismissModalWithType,
  openRegistrationDisabledModal,
  unlockTopModal,
} from "src/features/signin/imports/state";

export const dismissLoginModal = (dispatch) => () =>
  batch(() => {
    dispatch(unlockTopModal());
    dispatch(
      dismissModalWithType({
        modalType: ModalType.LOGIN,
        modalDismissReason: FROM_FLOW,
      })
    );
  });

export const handlePreconditionErrorAndDismissModal = (dispatch) => (err) => {
  batch(() => {
    dispatch(unlockTopModal());
    if (err.status === 412) {
      dispatch(
        dismissModalWithType({
          modalType: ModalType.LOGIN,
          modalDismissReason: FROM_FLOW,
        })
      );
      dispatch(openRegistrationDisabledModal());
    }
  });
};
