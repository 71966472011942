import React, { useCallback, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import {
  STOP_REASON_USER_LEFT,
  STREAM_LOGGING_EVENT_ERROR,
  STREAM_LOGGING_EVENT_EXPIRED,
  STREAM_LOGGING_EVENT_PAUSE,
  STREAM_LOGGING_EVENT_READY_FOR_DISPLAY,
  STREAM_LOGGING_EVENT_RESUME,
  STREAM_LOGGING_EVENT_SHARED_VIEWERS,
  STREAM_LOGGING_EVENT_START,
  STREAM_LOGGING_EVENT_STOP,
  STREAM_LOGGING_EVENTS_WITH_REASON,
  STREAM_LOGGING_STATE_STARTED,
  STREAM_LOGGING_STATE_STOPPED,
  STREAM_LOGGING_STATE_USER_LEFT,
} from "@analytics/playbackLogging";
import streamKindToAnalyticsStreamKind from "@analytics/streamKindToAnalyticsStreamKind";
import { streamsCacheSelectors, viewerSessionSelectors } from "state/selectors";
import { linkToStreamMatch } from "ui/navigation/links";
import { useStreamLocationState } from "./streamLocationState";

const Context = React.createContext({
  logStreamStarted: emptyFunction,
  logStreamPaused: emptyFunction,
  logStreamResumed: emptyFunction,
  logStreamStopped: emptyFunction,
  logStreamUserLeft: emptyFunction,
  logStreamExpired: emptyFunction,
  logStreamSharedViewers: emptyFunction,
  logStreamReadyForDisplay: emptyFunction,
  logStreamError: emptyFunction,
  logStreamBufferingEnded: emptyFunction,
  viewSessionId: undefined,
  setStreamDuration: emptyFunction,
});

const getStreamParams = (state, streamId) => {
  const reportedStreamId =
    streamId || viewerSessionSelectors.getStreamId(state);
  const { kind, broadcasterId } =
    streamsCacheSelectors.getStreamById(state, reportedStreamId) || {};

  return {
    id: reportedStreamId,
    kind,
    broadcasterId,
  };
};

/* eslint-disable no-console */
const logWithStoreData = (params, locationState) => (dispatch, getState) => {
  const { eventName, streamId, action, reason, duration, viewSessionId } =
    params;
  if (!eventName) {
    console.warn("'eventName' MUST be specified");

    return;
  }
  const { id, kind, broadcasterId } = getStreamParams(getState(), streamId);
  const eventParams = {
    [EventFields.STREAM_ID]: id,
    [EventFields.PEER_ID]: broadcasterId,
    [EventFields.STREAM_KIND]: streamKindToAnalyticsStreamKind(kind),
    [EventFields.VIEW_SESSION_ID]: viewSessionId,
    [EventFields.ACTION]: action,
  };
  if (STREAM_LOGGING_EVENTS_WITH_REASON.includes(action)) {
    if (reason) {
      eventParams[EventFields.REASON] = reason;
    } else {
      console.warn(`${action} action MUST have reason parameter specified`);
    }
    if (duration) {
      eventParams[EventFields.DURATION_QUALITY_HD] = duration.hdDuration;
      eventParams[EventFields.DURATION_QUALITY_SD] = duration.sdDuration;
      eventParams[EventFields.DURATION_QUALITY_LD] = duration.ldDuration;
    } else {
      console.warn(`${action} action MUST have duration parameter specified`);
    }
  }

  [
    EventFields.STREAM_RANK_IN_LIST,
    EventFields.STREAM_SOURCE,
    EventFields.STREAM_TAG,
    EventFields.STREAM_CHAT_SOURCE_TYPE,
  ].forEach((field) => {
    if (locationState[field] !== undefined) {
      eventParams[field] = locationState[field];
    }
  });
  if (
    action === STREAM_LOGGING_EVENT_START &&
    locationState[EventFields.PREVIOUS_STREAM_ID] !== undefined
  ) {
    eventParams[EventFields.PREVIOUS_STREAM_ID] =
      locationState[EventFields.PREVIOUS_STREAM_ID];
  }

  emitEvent(eventName, eventParams);
};
const logBufferingWithStoreData = (params) => (dispatch, getState) => {
  const { eventName, streamId, viewSessionId, duration } = params;
  if (!eventName) {
    console.warn("'eventName' MUST be specified");

    return;
  }
  const { kind, broadcasterId } = getStreamParams(getState(), streamId);
  const eventParams = {
    [EventFields.PEER_ID]: broadcasterId,
    [EventFields.STREAM_KIND]: streamKindToAnalyticsStreamKind(kind),
    [EventFields.SESSION_ID]: viewSessionId,
  };
  if (typeof duration === "number") {
    eventParams[EventFields.DURATION] = duration;
  }

  emitEvent(eventName, eventParams);
};

const generateRandomId = () =>
  Math.round(Math.random() * Math.pow(2, 48))
    .toString(16)
    .padStart(12, "0");

export const StreamAnalyticsContextProvider = ({ children }) => {
  const locationState = useStreamLocationState();
  const durationStream = useRef(null);
  const streamState = useRef(STREAM_LOGGING_STATE_USER_LEFT);
  const viewSessionIdRef = useRef(generateRandomId());
  const viewSessionId = viewSessionIdRef.current;
  const dispatch = useDispatch();

  const {
    params: { id: streamId },
  } = useRouteMatch(linkToStreamMatch);

  const logStreamEvent = useCallback(
    (params) =>
      dispatch(
        logWithStoreData(
          {
            ...params,
            viewSessionId,
          },
          locationState
        )
      ),
    [locationState, viewSessionId]
  );
  const logStreamBufferingEvent = useCallback(
    (params) =>
      dispatch(
        logBufferingWithStoreData({
          ...params,
          viewSessionId,
        })
      ),
    [viewSessionId]
  );

  const logStreamStarted = (/* no reason, just wanna see more poker */) => {
    switch (streamState.current) {
      case STREAM_LOGGING_STATE_STARTED:
        console.warn(
          `Trying to log 'start' for stream ${streamId} that is already started`
        );
        break;

      case STREAM_LOGGING_STATE_USER_LEFT:
      case STREAM_LOGGING_STATE_STOPPED:
        logStreamEvent({
          eventName: EventNames.LIVE_PLAY,
          action: STREAM_LOGGING_EVENT_START,
        });
        streamState.current = STREAM_LOGGING_STATE_STARTED;
    }
  };

  const logStreamPaused = (reason) => {
    switch (streamState.current) {
      case STREAM_LOGGING_STATE_STARTED:
        logStreamEvent({
          eventName: EventNames.LIVE_PLAY,
          action: STREAM_LOGGING_EVENT_PAUSE,
          reason,
        });
        break;

      case STREAM_LOGGING_STATE_USER_LEFT:
      case STREAM_LOGGING_STATE_STOPPED:
        console.warn(
          `Trying to log 'pause' for stream ${streamId} that is not started`
        );
    }
  };

  const logStreamResumed = (reason) => {
    switch (streamState.current) {
      case STREAM_LOGGING_STATE_STARTED:
        logStreamEvent({
          eventName: EventNames.LIVE_PLAY,
          action: STREAM_LOGGING_EVENT_RESUME,
          reason,
        });
        break;

      case STREAM_LOGGING_STATE_USER_LEFT:
      case STREAM_LOGGING_STATE_STOPPED:
        console.warn(
          `Trying to log 'resume' for stream ${streamId} that is not started`
        );
    }
  };

  const logStreamStopped = (reason) => {
    switch (streamState.current) {
      case STREAM_LOGGING_STATE_STARTED:
        logStreamEvent({
          eventName: EventNames.LIVE_PLAY,
          action: STREAM_LOGGING_EVENT_STOP,
          reason,
          duration: durationStream.current,
        });
        streamState.current = STREAM_LOGGING_STATE_STOPPED;
        break;
      case STREAM_LOGGING_STATE_USER_LEFT:
        console.warn(
          `Trying to log ${STREAM_LOGGING_EVENT_STOP} (reason=${reason}) for stream ${streamId} that is already abandoned`
        );
        break;
      case STREAM_LOGGING_STATE_STOPPED:
        console.warn(
          `Trying to log ${STREAM_LOGGING_EVENT_STOP} (reason=${reason}) for stream ${streamId} that is already stopped`
        );
        break;
    }
  };

  const logStreamUserLeft = () => {
    switch (streamState.current) {
      case STREAM_LOGGING_STATE_STARTED:
        logStreamEvent({
          eventName: EventNames.LIVE_PLAY,
          action: STREAM_LOGGING_EVENT_STOP,
          reason: STOP_REASON_USER_LEFT,
          duration: durationStream.current,
        });
        streamState.current = STREAM_LOGGING_STATE_USER_LEFT;
        break;
      case STREAM_LOGGING_STATE_USER_LEFT:
        console.warn(
          `Trying to log ${STREAM_LOGGING_EVENT_STOP} (reason=${STOP_REASON_USER_LEFT}) for stream ${streamId} that is already abandoned`
        );
        break;
      case STREAM_LOGGING_STATE_STOPPED:
        // noop, leaving stopped stream is OK
        break;
    }
  };

  const logStreamExpired = () => {
    logStreamEvent({
      eventName: EventNames.LIVE_PLAY,
      action: STREAM_LOGGING_EVENT_EXPIRED,
    });
  };

  const logStreamReadyForDisplay = () => {
    logStreamEvent({
      eventName: EventNames.LIVE_PLAY,
      action: STREAM_LOGGING_EVENT_READY_FOR_DISPLAY,
    });
  };

  const logStreamSharedViewers = () => {
    logStreamEvent({
      eventName: EventNames.LIVE_PLAY,
      action: STREAM_LOGGING_EVENT_SHARED_VIEWERS,
    });
  };

  const logStreamError = (reason) => {
    logStreamEvent({
      eventName: EventNames.LIVE_PLAY,
      action: STREAM_LOGGING_EVENT_ERROR,
      reason,
    });
  };

  const logStreamBufferingEnded = (duration) => {
    logStreamBufferingEvent({
      eventName: EventNames.LIVE_PLAY_BUFFERING_ENDED,
      streamId,
      duration,
    });
  };

  const setStreamDuration = (data) => {
    durationStream.current = data;
  };

  const streamLogging = useMemo(
    () => ({
      logStreamStarted,
      logStreamPaused,
      logStreamResumed,
      logStreamStopped,
      logStreamUserLeft,
      logStreamExpired,
      logStreamSharedViewers,
      logStreamReadyForDisplay,
      logStreamError,
      logStreamBufferingEnded,
      viewSessionId,
      setStreamDuration,
    }),
    [streamId, locationState, viewSessionId]
  );

  return <Context.Provider value={streamLogging}>{children}</Context.Provider>;
};

StreamAnalyticsContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Context;
