const countries = require("i18n-iso-countries");
module.exports = function(cb) {
    require.ensure([
      "i18n-iso-countries/langs/es.json",
      "date-fns/locale/es",
      "translations/es.json",
    ], (require) => {
      countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
      cb({
        translatedMessages: require("translations/es.json"),
        dateFnsLocale: require("date-fns/locale/es").default
      });
    }, "locale-es-no-intl");
};