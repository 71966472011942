import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SocialGamesResponseCode } from "src/features/socialGames/common/enums";
import {
  SocialGamesInStreamListResponse,
  SocialGamesListGameDto,
} from "src/features/socialGames/common/types";
import { Nullable } from "src/types/common";

export interface SocialGamesInStreamLobbyState {
  error: Nullable<string>;
  gameId: Nullable<string>;
  games: SocialGamesListGameDto[];
  loading: boolean;
  responseCode: Nullable<SocialGamesResponseCode>;
}

const initialState: SocialGamesInStreamLobbyState = {
  error: null,
  games: [],
  loading: false,
  responseCode: null,
  gameId: null,
};

const slice = createSlice({
  name: "socialGamesInStreamLobby",
  initialState,
  reducers: {
    loadSocialGamesInStreamLobbyStart(state) {
      state.loading = true;
      state.responseCode = null;
      state.error = null;
    },
    loadSocialGamesInStreamLobbyComplete(
      state,
      action: PayloadAction<SocialGamesInStreamListResponse>
    ) {
      state.loading = false;
      state.games = action.payload.games;
      state.responseCode = action.payload.responseCode;
    },
    loadSocialGamesInStreamLobbyFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateSocialGamesInStreamGameId(
      state,
      action: PayloadAction<Nullable<string>>
    ) {
      state.gameId = action.payload;
    },
  },
});

export const {
  loadSocialGamesInStreamLobbyStart,
  loadSocialGamesInStreamLobbyComplete,
  loadSocialGamesInStreamLobbyFailure,
  updateSocialGamesInStreamGameId,
} = slice.actions;

export const socialGamesInStreamLobbyReducer = slice.reducer;
