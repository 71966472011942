import { getEmojiFromString } from "chat/imports/utils";

const TWO_OR_MORE_WHITESPACES_IN_ROW_REG_EXP = /\s{2,}/g;

const makeInitialsFromName = (name: string) =>
  name
    .replace(TWO_OR_MORE_WHITESPACES_IN_ROW_REG_EXP, " ")
    .trim()
    .split(" ")
    .slice(0, 2)
    .reduce((acc, next) => {
      const emoji = getEmojiFromString(next);

      return `${acc}${emoji[0] && emoji[0].index === 0 ? emoji[0] : next[0]}`;
    }, "");

export default makeInitialsFromName;
