import { createReducer } from "@reduxjs/toolkit";
import { GroupPersonalOffersConfig } from "src/types/personalOffer";
import {
  addAsyncCasesToBuilderV2,
  AsyncState,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import actions from "state/actionCreators/personalOffers";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";
import { Nullable } from "src/types/common";

const personalOffersConfigInitialState = {
  data: null,
  meta: initialFetcherStateMeta,
};

export const persistConfig = {
  whitelist: ["personalOffers"],
};

export type PersonalOffersState = AsyncState<
  Nullable<GroupPersonalOffersConfig>,
  string
>;

const currentPersonalOffersConfig = createReducer<PersonalOffersState>(
  personalOffersConfigInitialState,
  (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        builder,
        action: actions.fetchPersonalOffersConfig,
        prepareData: (oldConfig, newConfig) => ({
          ...oldConfig,
          ...newConfig,
        }),
        initialData: personalOffersConfigInitialState.data,
      }).addCase(
        actions.clearPersonalOffersConfig,
        () => personalOffersConfigInitialState
      ),
      () => personalOffersConfigInitialState
    );
  }
);

export default currentPersonalOffersConfig;
