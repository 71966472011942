import React, { FC, memo } from "react";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { SECOND } from "src/constants";
import { Breakpoints } from "src/enums";
import { ToastTimerProps } from "src/types/toast";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import CircularTimer from "ui/modal/common/CircularTimer";
import useIsOnStream from "ui/navigation/useIsOnStream";
import ToastWrapper from "ui/toast/ToastWrapper/ToastWrapper";
import { renderTextOrFormattedMessage } from "utils/renderTextOrFormattedMessage";
import styles from "./Toast.scss";

const ToastTimer: FC<ToastTimerProps> = ({
  duration,
  handleButtonClick = emptyFunction,
  buttonText,
  title,
  type,
  id,
}) => {
  const breakpoint = useBreakpointPrecise();
  const isOnStream = useIsOnStream();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const typographyType = isMobile
    ? TYPOGRAPHY_TYPE.PARAGRAPH2
    : TYPOGRAPHY_TYPE.PARAGRAPH1;
  const buttonSize = isMobile ? ButtonSize.MEDIUM_32 : ButtonSize.BIG_48;
  const formattedTitle = renderTextOrFormattedMessage(title);
  const formattedButtonText = renderTextOrFormattedMessage(buttonText);

  return (
    <ToastWrapper id={id} lifetime={duration * SECOND} type={type}>
      <div className={styles.root}>
        <CircularTimer onShader={isOnStream} initialValue={duration} />
        <Typography type={typographyType} className={styles.timerTitle}>
          {formattedTitle}
        </Typography>
        {buttonText && (
          <Button
            className={classnames(styles.button, {
              [styles.shader]: isOnStream,
            })}
            onClick={handleButtonClick}
            size={buttonSize}
            variant={ButtonVariant.TERTIARY}
          >
            {formattedButtonText}
          </Button>
        )}
      </div>
    </ToastWrapper>
  );
};

export default memo(ToastTimer);
