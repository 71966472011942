/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.AlbumPost || ($protobuf.roots.AlbumPost = {});

export const AlbumPost = $root.AlbumPost = (() => {

    function AlbumPost(properties) {
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AlbumPost.prototype.caption = "";
    AlbumPost.prototype.items = $util.emptyArray;

    AlbumPost.create = function create(properties) {
        return new AlbumPost(properties);
    };

    AlbumPost.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
            writer.uint32(10).string(message.caption);
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.PictureAndThumbnail.encode(message.items[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    AlbumPost.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AlbumPost.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AlbumPost();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.caption = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PictureAndThumbnail.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    AlbumPost.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AlbumPost.fromObject = function fromObject(object) {
        if (object instanceof $root.AlbumPost)
            return object;
        let message = new $root.AlbumPost();
        if (object.caption != null)
            message.caption = String(object.caption);
        if (object.items) {
            if (!Array.isArray(object.items))
                throw TypeError(".AlbumPost.items: array expected");
            message.items = [];
            for (let i = 0; i < object.items.length; ++i) {
                if (typeof object.items[i] !== "object")
                    throw TypeError(".AlbumPost.items: object expected");
                message.items[i] = $root.PictureAndThumbnail.fromObject(object.items[i]);
            }
        }
        return message;
    };

    AlbumPost.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.items = [];
        if (options.defaults)
            object.caption = "";
        if (message.caption != null && message.hasOwnProperty("caption"))
            object.caption = message.caption;
        if (message.items && message.items.length) {
            object.items = [];
            for (let j = 0; j < message.items.length; ++j)
                object.items[j] = $root.PictureAndThumbnail.toObject(message.items[j], options);
        }
        return object;
    };

    AlbumPost.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AlbumPost.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AlbumPost";
    };

    return AlbumPost;
})();

export const PictureAndThumbnail = $root.PictureAndThumbnail = (() => {

    function PictureAndThumbnail(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PictureAndThumbnail.prototype.pictureUrl = "";
    PictureAndThumbnail.prototype.pictureWidth = 0;
    PictureAndThumbnail.prototype.pictureHeight = 0;
    PictureAndThumbnail.prototype.thumbnailUrl = "";
    PictureAndThumbnail.prototype.thumbnailWidth = 0;
    PictureAndThumbnail.prototype.thumbnailHeight = 0;
    PictureAndThumbnail.prototype.referenceUrl = "";
    PictureAndThumbnail.prototype.blurredPictureUrl = "";
    PictureAndThumbnail.prototype.blurredThumbnailUrl = "";
    PictureAndThumbnail.prototype.blurLevel = 0;
    PictureAndThumbnail.prototype.mediaId = "";
    PictureAndThumbnail.prototype.blurredMediaId = "";

    PictureAndThumbnail.create = function create(properties) {
        return new PictureAndThumbnail(properties);
    };

    PictureAndThumbnail.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.pictureUrl);
        writer.uint32(21).sfixed32(message.pictureWidth);
        writer.uint32(29).sfixed32(message.pictureHeight);
        writer.uint32(34).string(message.thumbnailUrl);
        writer.uint32(45).sfixed32(message.thumbnailWidth);
        writer.uint32(53).sfixed32(message.thumbnailHeight);
        if (message.referenceUrl != null && Object.hasOwnProperty.call(message, "referenceUrl"))
            writer.uint32(58).string(message.referenceUrl);
        if (message.blurredPictureUrl != null && Object.hasOwnProperty.call(message, "blurredPictureUrl"))
            writer.uint32(66).string(message.blurredPictureUrl);
        if (message.blurredThumbnailUrl != null && Object.hasOwnProperty.call(message, "blurredThumbnailUrl"))
            writer.uint32(74).string(message.blurredThumbnailUrl);
        if (message.blurLevel != null && Object.hasOwnProperty.call(message, "blurLevel"))
            writer.uint32(81).double(message.blurLevel);
        if (message.mediaId != null && Object.hasOwnProperty.call(message, "mediaId"))
            writer.uint32(90).string(message.mediaId);
        if (message.blurredMediaId != null && Object.hasOwnProperty.call(message, "blurredMediaId"))
            writer.uint32(98).string(message.blurredMediaId);
        return writer;
    };

    PictureAndThumbnail.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PictureAndThumbnail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PictureAndThumbnail();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.pictureUrl = reader.string();
                    break;
                }
            case 2: {
                    message.pictureWidth = reader.sfixed32();
                    break;
                }
            case 3: {
                    message.pictureHeight = reader.sfixed32();
                    break;
                }
            case 4: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            case 5: {
                    message.thumbnailWidth = reader.sfixed32();
                    break;
                }
            case 6: {
                    message.thumbnailHeight = reader.sfixed32();
                    break;
                }
            case 7: {
                    message.referenceUrl = reader.string();
                    break;
                }
            case 8: {
                    message.blurredPictureUrl = reader.string();
                    break;
                }
            case 9: {
                    message.blurredThumbnailUrl = reader.string();
                    break;
                }
            case 10: {
                    message.blurLevel = reader.double();
                    break;
                }
            case 11: {
                    message.mediaId = reader.string();
                    break;
                }
            case 12: {
                    message.blurredMediaId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("pictureUrl"))
            throw $util.ProtocolError("missing required 'pictureUrl'", { instance: message });
        if (!message.hasOwnProperty("pictureWidth"))
            throw $util.ProtocolError("missing required 'pictureWidth'", { instance: message });
        if (!message.hasOwnProperty("pictureHeight"))
            throw $util.ProtocolError("missing required 'pictureHeight'", { instance: message });
        if (!message.hasOwnProperty("thumbnailUrl"))
            throw $util.ProtocolError("missing required 'thumbnailUrl'", { instance: message });
        if (!message.hasOwnProperty("thumbnailWidth"))
            throw $util.ProtocolError("missing required 'thumbnailWidth'", { instance: message });
        if (!message.hasOwnProperty("thumbnailHeight"))
            throw $util.ProtocolError("missing required 'thumbnailHeight'", { instance: message });
        return message;
    };

    PictureAndThumbnail.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PictureAndThumbnail.fromObject = function fromObject(object) {
        if (object instanceof $root.PictureAndThumbnail)
            return object;
        let message = new $root.PictureAndThumbnail();
        if (object.pictureUrl != null)
            message.pictureUrl = String(object.pictureUrl);
        if (object.pictureWidth != null)
            message.pictureWidth = object.pictureWidth | 0;
        if (object.pictureHeight != null)
            message.pictureHeight = object.pictureHeight | 0;
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        if (object.thumbnailWidth != null)
            message.thumbnailWidth = object.thumbnailWidth | 0;
        if (object.thumbnailHeight != null)
            message.thumbnailHeight = object.thumbnailHeight | 0;
        if (object.referenceUrl != null)
            message.referenceUrl = String(object.referenceUrl);
        if (object.blurredPictureUrl != null)
            message.blurredPictureUrl = String(object.blurredPictureUrl);
        if (object.blurredThumbnailUrl != null)
            message.blurredThumbnailUrl = String(object.blurredThumbnailUrl);
        if (object.blurLevel != null)
            message.blurLevel = Number(object.blurLevel);
        if (object.mediaId != null)
            message.mediaId = String(object.mediaId);
        if (object.blurredMediaId != null)
            message.blurredMediaId = String(object.blurredMediaId);
        return message;
    };

    PictureAndThumbnail.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.pictureUrl = "";
            object.pictureWidth = 0;
            object.pictureHeight = 0;
            object.thumbnailUrl = "";
            object.thumbnailWidth = 0;
            object.thumbnailHeight = 0;
            object.referenceUrl = "";
            object.blurredPictureUrl = "";
            object.blurredThumbnailUrl = "";
            object.blurLevel = 0;
            object.mediaId = "";
            object.blurredMediaId = "";
        }
        if (message.pictureUrl != null && message.hasOwnProperty("pictureUrl"))
            object.pictureUrl = message.pictureUrl;
        if (message.pictureWidth != null && message.hasOwnProperty("pictureWidth"))
            object.pictureWidth = message.pictureWidth;
        if (message.pictureHeight != null && message.hasOwnProperty("pictureHeight"))
            object.pictureHeight = message.pictureHeight;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        if (message.thumbnailWidth != null && message.hasOwnProperty("thumbnailWidth"))
            object.thumbnailWidth = message.thumbnailWidth;
        if (message.thumbnailHeight != null && message.hasOwnProperty("thumbnailHeight"))
            object.thumbnailHeight = message.thumbnailHeight;
        if (message.referenceUrl != null && message.hasOwnProperty("referenceUrl"))
            object.referenceUrl = message.referenceUrl;
        if (message.blurredPictureUrl != null && message.hasOwnProperty("blurredPictureUrl"))
            object.blurredPictureUrl = message.blurredPictureUrl;
        if (message.blurredThumbnailUrl != null && message.hasOwnProperty("blurredThumbnailUrl"))
            object.blurredThumbnailUrl = message.blurredThumbnailUrl;
        if (message.blurLevel != null && message.hasOwnProperty("blurLevel"))
            object.blurLevel = options.json && !isFinite(message.blurLevel) ? String(message.blurLevel) : message.blurLevel;
        if (message.mediaId != null && message.hasOwnProperty("mediaId"))
            object.mediaId = message.mediaId;
        if (message.blurredMediaId != null && message.hasOwnProperty("blurredMediaId"))
            object.blurredMediaId = message.blurredMediaId;
        return object;
    };

    PictureAndThumbnail.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PictureAndThumbnail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PictureAndThumbnail";
    };

    return PictureAndThumbnail;
})();

export { $root as default };
