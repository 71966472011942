const countries = require("i18n-iso-countries");
module.exports = function(cb) {
    require.ensure([
      "i18n-iso-countries/langs/ro.json",
      "date-fns/locale/ro",
      "translations/ro.json",
    ], (require) => {
      countries.registerLocale(require("i18n-iso-countries/langs/ro.json"));
      cb({
        translatedMessages: require("translations/ro.json"),
        dateFnsLocale: require("date-fns/locale/ro").default
      });
    }, "locale-ro-no-intl");
};