import { createReducer } from "@reduxjs/toolkit";
import {
  addAsyncCasesToBuilderV2,
  AsyncState,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import { CurrencyInfo } from "ui/common/currencyPicker/types";
import {
  currenciesOverride,
  currenciesSetWhiteList,
  fetchCurrencies,
  selectCurrency,
  staleCurrencies,
} from "state/actionCreators/currencies";

const initialState: AsyncState<CurrencyInfo, string> = {
  data: {
    options: [],
    whiteList: [],
    override: [],
    recommended: undefined,
    selected: undefined,
  },
  meta: initialFetcherStateMeta,
};

export type CurrenciesState = AsyncState<CurrencyInfo, string>;

export default createReducer<CurrenciesState>(initialState, (builder) => {
  addAsyncCasesToBuilderV2({
    builder,
    action: fetchCurrencies,
    prepareData: (oldData, newData) => ({
      ...initialState.data,
      ...newData,
    }),
    initialData: initialState.data,
  })
    .addCase(selectCurrency, (state, action) => {
      state.data.selected = action.payload;
    })
    .addCase(currenciesOverride, (state, action) => {
      state.data.override = action.payload;
    })
    .addCase(currenciesSetWhiteList, (state, action) => {
      state.data.whiteList = action.payload;
    })
    .addCase(staleCurrencies, (state, action) => {
      state.meta.stale = action.payload;
    });
});
