import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLeaderBoard } from "api/leaderBoardLanding";
import { isApiError } from "api/utils/enhancedFetch";
import { LeaderBoardLanding } from "src/types/leaderBoard";
import { fetchAccountToStreamMap } from "state/actionCreators/userToStream";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { leaderBoardLandingSelectors } from "state/selectors";

export const fetchLeaderBoardLanding = createAsyncThunk<
  LeaderBoardLanding,
  { name?: string } & FetcherMetaV2,
  { rejectValue: string; state: RootState }
>(
  "lwc/leaderBoardLanding/fetch",
  async ({ name }, thunkAPI) => {
    try {
      const currentLeaderBoard = await fetchLeaderBoard(name);

      if (!currentLeaderBoard) {
        throw new Error("LeaderBoard not found!");
      }

      thunkAPI.dispatch(
        fetchAccountToStreamMap({
          accountIds:
            currentLeaderBoard.topParticipantsChart.sortedParticipantsProgress.map(
              ({ accountId }) => accountId
            ),
        })
      );

      return currentLeaderBoard;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return thunkAPI.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta =
        leaderBoardLandingSelectors.metaLandingLeaderBoardByPath(state);

      return !meta.loading;
    },
  }
);
