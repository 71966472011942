import React, { memo } from "react";
import LottieAnimation from "ui/common/lottie/LottieAnimation";
import { GiftAnimationComponentDefaultProps } from "src/types/gift";
import lottieDefaultConfig from "src/ui/animations/lottieDefaultConfig";
import styles from "./GiftAnimation.scss";

const GiftAnimation: React.FC<GiftAnimationComponentDefaultProps> = ({
  url,
  assetsPath,
  onComplete,
}) => (
  <LottieAnimation
    className={styles.root}
    lottieConfig={{
      ...lottieDefaultConfig,
      assetsPath,
      path: url,
    }}
    onComplete={onComplete}
  />
);

export default memo(GiftAnimation);
