import React, { FC } from "react";
import classnames from "classnames";
import { Typography } from "legal/imports/components";
import { TYPOGRAPHY_TYPE } from "legal/imports/constants";
import styles from "legal/common/LegalArticle.scss";

interface ListItemProps {
  checked?: boolean;
  unchecked?: boolean;
}

const ListItem: FC<ListItemProps> = ({ checked, unchecked, children }) => (
  <Typography
    type={TYPOGRAPHY_TYPE.PARAGRAPH4}
    className={classnames(styles.li, {
      [styles.checked]: checked,
      [styles.unchecked]: unchecked,
    })}
    as="li"
  >
    {children}
  </Typography>
);

export default ListItem;
