import React, { memo } from "react";
import { NotificationsMuter } from "chat/imports/components";
import { NotificationType } from "chat/imports/constants";
import { usePageVisibility } from "chat/imports/hooks";

const mutedTypes = [NotificationType.TC_MESSAGE];

const ChatNotificationsMuter: React.FC = () => {
  const isVisible = usePageVisibility();
  if (!isVisible) {
    return null;
  }

  return <NotificationsMuter mutedTypes={mutedTypes} />;
};

export default memo(ChatNotificationsMuter);
