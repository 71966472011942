import { CREATOR_APP_CHANGE_SUBMIT_STATUS } from "state/actionTypes";

export const SUBMIT_STATUS_IDLE = "IDLE";
export const SUBMIT_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const SUBMIT_STATUS_OK = "OK";
export const SUBMIT_STATUS_COOLDOWN = "COOLDOWN";

export const initialState = {
  submitStatus: SUBMIT_STATUS_IDLE,
  cooldownHours: 0,
  submissionDate: null,
};

export const actionCreators = {
  changeSubmitStatus: (payload) => ({
    type: CREATOR_APP_CHANGE_SUBMIT_STATUS,
    payload,
  }),
};

export const selectors = {
  getSubmitStatus: (state) => state.submitStatus,
  getCooldownHours: (state) => state.cooldownHours,
  getSubmissionDate: (state) => state.submissionDate,
};

export const persistConfig = {
  whitelist: ["submissionDate"],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATOR_APP_CHANGE_SUBMIT_STATUS:
      // eslint-disable-next-line no-case-declarations
      const { status, submissionDate, cooldownHours } = action.payload;
      return {
        ...state,
        submitStatus: status || state.submitStatus,
        submissionDate: submissionDate || state.submissionDate,
        cooldownHours: cooldownHours || state.cooldownHours,
      };
  }
  return state;
};
