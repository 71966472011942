import {
  OfferCardSizes,
  OfferCardTypes,
} from "ui/modal/modalViews/buyCoins/offers/card/enums";
import {
  AllTypographyTypes,
  TYPOGRAPHY_TYPE,
} from "ui/common/typography/types";

const generateTypographyMapBySize = (
  large: AllTypographyTypes,
  medium: AllTypographyTypes,
  small: AllTypographyTypes,
  xs: AllTypographyTypes
): Record<OfferCardSizes, AllTypographyTypes> => ({
  [OfferCardSizes.LARGE]: large,
  [OfferCardSizes.MEDIUM]: medium,
  [OfferCardSizes.SMALL]: small,
  [OfferCardSizes.EXTRA_SMALL]: xs,
});

type TypographyMap = Record<
  OfferCardTypes,
  Record<OfferCardSizes, AllTypographyTypes>
>;

export const CARD_PRICE_TYPOGRAPHY_MAP: TypographyMap = {
  [OfferCardTypes.CASHIER]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE6
  ),
  [OfferCardTypes.REFILL]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE6
  ),
  [OfferCardTypes.LANDING]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE6
  ),
  [OfferCardTypes.LANDING_LIGHT]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE3,
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE5
  ),
};
export const CARD_ORIGINAL_PRICE_TYPOGRAPHY_MAP: TypographyMap = {
  [OfferCardTypes.CASHIER]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.MINI
  ),
  [OfferCardTypes.REFILL]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.MINI
  ),
  [OfferCardTypes.LANDING]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.HEADLINE6,
    TYPOGRAPHY_TYPE.MINI
  ),
  [OfferCardTypes.LANDING_LIGHT]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.PARAGRAPH3,
    TYPOGRAPHY_TYPE.PARAGRAPH5,
    TYPOGRAPHY_TYPE.PARAGRAPH5,
    TYPOGRAPHY_TYPE.PARAGRAPH5
  ),
};

export const COINS_TYPOGRAPHY_MAP: TypographyMap = {
  [OfferCardTypes.CASHIER]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE3,
    TYPOGRAPHY_TYPE.HEADLINE3,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5
  ),
  [OfferCardTypes.REFILL]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE3,
    TYPOGRAPHY_TYPE.HEADLINE3,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5
  ),
  [OfferCardTypes.LANDING]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE3,
    TYPOGRAPHY_TYPE.HEADLINE3,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5
  ),
  [OfferCardTypes.LANDING_LIGHT]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HERO3,
    TYPOGRAPHY_TYPE.HEADLINE1,
    TYPOGRAPHY_TYPE.HEADLINE2,
    TYPOGRAPHY_TYPE.HEADLINE3
  ),
};

export const SPIN_TO_WIN_DESCRIPTION_MAP: TypographyMap = {
  [OfferCardTypes.CASHIER]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5
  ),
  [OfferCardTypes.REFILL]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5
  ),
  [OfferCardTypes.LANDING]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE5,
    TYPOGRAPHY_TYPE.HEADLINE5
  ),
  [OfferCardTypes.LANDING_LIGHT]: generateTypographyMapBySize(
    TYPOGRAPHY_TYPE.HERO3,
    TYPOGRAPHY_TYPE.HEADLINE1,
    TYPOGRAPHY_TYPE.HEADLINE4,
    TYPOGRAPHY_TYPE.HEADLINE4
  ),
};
