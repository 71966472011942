import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import SignInMainView from "src/features/signin/bottomScreen/components/SignInMainView";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import {
  bottomScreenSelectors,
  loginSelectors,
} from "src/features/signin/imports/state";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Spinner,
  useBreakpoint,
} from "src/features/signin/imports/ui";
import { useUnmount } from "src/features/signin/imports/utils";
import LoginUnavailable from "src/features/signin/modal/LoginUnavailable";
import LoginFailed, {
  GenericFailureReason,
  ReateLimitedFailureReason,
} from "src/features/signin/modal/login/layout/LoginFailed";
import CountrySelectStep from "src/features/signin/modal/login/steps/CountrySelectStep";
import PhoneNumberInput from "src/features/signin/modal/login/steps/PhoneNumberInput";
import VerificationCodeStep from "src/features/signin/modal/login/steps/VerificationCodeStep";
import { resetPhoneLogin } from "src/features/signin/state/login/actionCreators";
import styles from "./PhoneNumberLoginController.scss";

const ContinueButton = ({ buttonVariant, ...rest }) => (
  <Button size={ButtonSize.BIG_48} variant={ButtonVariant.PRIMARY} {...rest}>
    <FormattedMessage
      id="buy-coins.summary.blocked-new-window.continue"
      defaultMessage="Continue"
    />
  </Button>
);

ContinueButton.propTypes = {
  buttonVariant: PropTypes.string,
};

const selector = (state) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isLoginFailed: loginSelectors.isLoginFailed(state),
  loginInProgress: loginSelectors.isLoginInProgress(state),
  phoneNumberAuthenticationState:
    loginSelectors.getPhoneNumberAuthenticationState(state),
  screenType: bottomScreenSelectors.screenType(state),
  animation: bottomScreenSelectors.animation(state),
  isPreparingProviders: loginSelectors.isPreparingProviders(state),
  providers: loginSelectors.getAvailableProviders(state),
  errorMessage: loginSelectors.getLoginErrorMessage(state),
});

const PhoneNumberLoginController = ({
  className,
  isOnlyPhoneNumberLoginFlow,
  screenData,
  screenData: {
    onLoginSuccess = () => {},
    registrationSource = RegistrationSource.UNKNOWN,
  },
  bottomScreenStyle,
  ...rest
}) => {
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();

  const {
    isLoggedIn,
    loginInProgress,
    phoneNumberAuthenticationState,
    isLoginFailed,
    screenType,
    isPreparingProviders,
    providers,
    errorMessage,
  } = useSelector(selector, shallowEqual);

  useUnmount(() => dispatch(resetPhoneLogin()));

  const showSpinner =
    isLoggedIn ||
    loginInProgress ||
    (isPreparingProviders && providers.length === 0);

  let body;
  let isMainStep = false;

  if (showSpinner) {
    body = (
      <Spinner
        className={styles.spinner}
        data-testid="login-modal-spinner"
        color="inherit"
      />
    );
  } else if (isLoginFailed) {
    body = (
      <LoginFailed errorMessage={errorMessage}>
        <GenericFailureReason />
      </LoginFailed>
    );
  } else if (phoneNumberAuthenticationState.attemptLimitReached) {
    body = (
      <LoginFailed>
        <ReateLimitedFailureReason />
      </LoginFailed>
    );
  } else if (phoneNumberAuthenticationState.phoneVerificationUnavailable) {
    body = <LoginUnavailable registrationSource={registrationSource} />;
  } else if (phoneNumberAuthenticationState.selectCountry) {
    body = <CountrySelectStep />;
  } else if (phoneNumberAuthenticationState.waitingForCode) {
    body = (
      <VerificationCodeStep
        screenType={screenType}
        onLoginSuccess={onLoginSuccess}
      />
    );
  } else if (phoneNumberAuthenticationState.continueWithPhone) {
    body = (
      <PhoneNumberInput
        screenType={screenType}
        buttonComponent={ContinueButton}
        autoFocus
        onLoginSuccess={onLoginSuccess}
      />
    );
  } else {
    isMainStep = true;
    body = <SignInMainView screenData={screenData} screenType={screenType} />;
  }

  const classNames = useMemo(
    () =>
      classnames(
        className,
        styles.root,
        styles[breakpoint],
        bottomScreenStyle && styles[bottomScreenStyle],
        {
          [styles.countrySelectStep]:
            phoneNumberAuthenticationState.selectCountry,
          [styles.loginFailed]: isLoginFailed,
          [styles.loginUnavailable]:
            phoneNumberAuthenticationState.phoneVerificationUnavailable,
          [styles.isMainStep]: isMainStep,
        }
      ),
    [
      breakpoint,
      className,
      isLoginFailed,
      phoneNumberAuthenticationState.phoneVerificationUnavailable,
      phoneNumberAuthenticationState.selectCountry,
      isMainStep,
      bottomScreenStyle,
    ]
  );

  return (
    <div className={classNames} {...rest}>
      {body}
    </div>
  );
};

PhoneNumberLoginController.propTypes = {
  className: PropTypes.string,
  isOnlyPhoneNumberLoginFlow: PropTypes.bool,
  bottomScreenStyle: PropTypes.string,
  screenData: PropTypes.shape({
    promotionType: PropTypes.string,
    dark: PropTypes.bool,
    isShowPhoneNumberLoginFlow: PropTypes.bool,
    registrationSource: PropTypes.number,
    title: PropTypes.string,
    onLoginSuccess: PropTypes.func,
  }),
};

export default PhoneNumberLoginController;
