import { AssetConfig, VipConfig } from "state/flows/loadVipConfigs";

export function getRootPathForVipLevel(bundleZipUrl: string) {
  const bundleUrlSplitted = bundleZipUrl.split("/");
  const pathWithoutZipFolder = bundleUrlSplitted
    ?.splice(0, bundleUrlSplitted.length - 1)
    .join("/");

  return pathWithoutZipFolder;
}

export function getTimestamp(bundleZipUrl: string): number {
  return Number(bundleZipUrl.split("/").at(-2));
}

export async function getAssetsForVipConfigs(vipConfigs: VipConfig[]) {
  const configsSortedByTimstamps = [...vipConfigs];
  configsSortedByTimstamps.sort((config1, config2) => {
    const timestamp1 = getTimestamp(config1.bundleZipUrl ?? "");
    const timestamp2 = getTimestamp(config2.bundleZipUrl ?? "");

    return timestamp2 - timestamp1;
  });
  const configWithLatestTimeStamp = configsSortedByTimstamps[0];
  const pathWithoutZipFolder = getRootPathForVipLevel(
    configWithLatestTimeStamp.bundleZipUrl ?? ""
  );
  const webConfigsUrl = `${pathWithoutZipFolder}/web.configs.json`;
  const assetConfigs = await fetch(webConfigsUrl);

  return (await assetConfigs.json()) as AssetConfig[];
}
