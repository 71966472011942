import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  StreamerPlan,
  fetchPersonalSubscriptionOffers,
  fetchPersonalUpgradeOffers,
  fetchStreamerPlans,
  fetchSubscriptionOffers,
  fetchUpgradeOffers,
} from "api/subscriptionPerStreamer";
import { isApiError } from "api/utils/enhancedFetch";
import { PersonalOffersConfig } from "src/types/personalOffer";
import { BasicProfile } from "src/types/profile/profile";
import { getIsBecomeAFanPersonalOffersEnabled } from "state/abTests";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { subscriptionsPerStreamerSelectors } from "state/selectors";
import { ShopOffer } from "ui/scenes/landingPage/types";

const actions = {
  fetchUpgradeOffers: createAsyncThunk<
    PersonalOffersConfig | ShopOffer[],
    { marketOfferId: string; streamerId: string } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/upgradeOffers/fetch",
    async ({ streamerId, marketOfferId }, api) => {
      try {
        const state = api.getState();
        const isBecomeAFanPersonalOffersEnabled =
          getIsBecomeAFanPersonalOffersEnabled(state);

        if (isBecomeAFanPersonalOffersEnabled) {
          return await fetchPersonalUpgradeOffers(streamerId, marketOfferId);
        }

        const { offers } = await fetchUpgradeOffers({
          marketOfferId,
          streamerId,
        });

        return offers;
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();

        return !subscriptionsPerStreamerSelectors.getUpgradeOffersMeta(state)
          .loading;
      },
    }
  ),
  fetchStreamerPlans: createAsyncThunk<
    StreamerPlan[],
    { includeDisabled?: boolean; streamerId: string } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/streamerPlans/fetch",
    async ({ streamerId, includeDisabled }, api) => {
      try {
        const { plans } = await fetchStreamerPlans({
          streamerId,
          includeDisabled,
        });

        return plans;
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();

        return !subscriptionsPerStreamerSelectors.getStreamerPlansMeta(state)
          .loading;
      },
    }
  ),
  fetchCreditOffers: createAsyncThunk<
    PersonalOffersConfig | ShopOffer[],
    { userId: string } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/creditOffers/fetch",
    async ({ userId }, api) => {
      try {
        const state = api.getState();

        const isBecomeAFanPersonalOffersEnabled =
          getIsBecomeAFanPersonalOffersEnabled(state);

        if (isBecomeAFanPersonalOffersEnabled) {
          return await fetchPersonalSubscriptionOffers(userId);
        }

        return await fetchSubscriptionOffers({ userId });
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();

        return !subscriptionsPerStreamerSelectors.getCreditOffersMeta(state)
          .loading;
      },
    }
  ),
  showSubscriberAnimation: createAction<{
    profile: Partial<BasicProfile>;
    streamerAccountId: string;
    type: string;
  }>("lwc/subscriptionsOffers/showAnimation"),
  hideSubscriberAnimation: createAction(
    "lwc/subscriptionsOffers/hideAnimation"
  ),
};

export default actions;
