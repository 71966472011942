import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { MessageDescriptor } from "@formatjs/intl";

export function renderTextOrFormattedMessage(
  text?: MessageDescriptor | string
): ReactNode {
  if (text === undefined) {
    return null;
  }
  return typeof text === "string" ? text : <FormattedMessage {...text} />;
}
