import { combineReducers } from "redux";
import { uniq } from "src/utils/miniLodash";
import {
  ADD_LOT_TO_MY_LOT_LIST,
  AUCTIONS_LIST_FETCH_END,
  AUCTIONS_LIST_FETCH_START,
  AUCTIONS_LIST_SET_STALE,
  LOTS_CLEAR,
  LOTS_LIST_FETCH_END,
  LOTS_LIST_FETCH_START,
  NFT_ACCOUNT_FETCH_END,
  NFT_ACCOUNT_FETCH_START,
  NFT_PROFILE_SET_STALE,
  PROCESS_LOT_BID_END,
  PROCESS_LOT_BID_START,
  RESET_SCROLL,
  SET_LOT_LIST_STALE,
  SET_SCROLL,
  UPDATE_LOT_STATUS,
  UPDATE_LOTS,
  USER_LOTS_CLEAR,
} from "state/actionTypes";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
  initialFetcherState,
} from "state/hor/withFetcher";
import NftLotProcessor from "state/tree/utils/NftLotProcessor";
import { AuctionStatus, KnownListTags, LotClientStatus } from "ui/nft/enums";

const auctionsInitialState = {
  list: [],
  currentAuctionId: "",
  stale: true,
  ...initialFetcherState,
};

const lotsInitialState = {
  lotListMap: {},
  lotMap: {},
  lotStatusMap: {},
  stale: true,
  scroll: 1,
  ...initialFetcherState,
};

const profilesInitialState = {};

export const initialState = {
  auctions: auctionsInitialState,
  lots: lotsInitialState,
  profiles: profilesInitialState,
};

export const auctionsActionCreators = {
  ...createFetcherActions({
    beginFetchActionType: AUCTIONS_LIST_FETCH_START,
    endFetchActionType: AUCTIONS_LIST_FETCH_END,
  }),
  setAuctionStale: () => ({
    type: AUCTIONS_LIST_SET_STALE,
  }),
};

export const lotsActionCreators = {
  ...createFetcherActions({
    beginFetchActionType: LOTS_LIST_FETCH_START,
    endFetchActionType: LOTS_LIST_FETCH_END,
  }),
  processLotBidStart: (lotId) => ({
    type: PROCESS_LOT_BID_START,
    payload: lotId,
  }),
  setLotListStale: ({ withLotsClear }) => ({
    type: SET_LOT_LIST_STALE,
    payload: { withLotsClear },
  }),
  processLotBidEnd: ({ result, lot, lotId }) => ({
    type: PROCESS_LOT_BID_END,
    payload: { result, lot, lotId },
  }),
  updateLotStatus: ({ lotId, status }) => ({
    type: UPDATE_LOT_STATUS,
    payload: { lotId, status },
  }),
  updateLots: ({ lots }) => ({
    type: UPDATE_LOTS,
    payload: { lots },
  }),
  setScroll: ({ scroll }) => ({
    type: SET_SCROLL,
    payload: scroll,
  }),
  resetScroll: () => ({
    type: RESET_SCROLL,
    payload: lotsInitialState.scroll,
  }),
  addLotToMyLotList: ({ lotId, auctionId }) => ({
    type: ADD_LOT_TO_MY_LOT_LIST,
    payload: { lotId, auctionId },
  }),
  clearLots: () => ({
    type: LOTS_CLEAR,
  }),
};

export const profilesActionCreators = {
  loadProfileStart: ({ accountId }) => ({
    type: NFT_ACCOUNT_FETCH_START,
    payload: { accountId },
  }),
  loadProfileEnd: ({ accountId, lots, creatorNfts, ownedNfts }) => ({
    type: NFT_ACCOUNT_FETCH_END,
    payload: { lots, creatorNfts, ownedNfts, accountId },
  }),
  loadProfileError: ({ error, accountId }) => ({
    type: NFT_ACCOUNT_FETCH_END,
    payload: { error, accountId },
  }),
  clearLot: ({ accountId }) => ({
    type: USER_LOTS_CLEAR,
    payload: { accountId },
  }),
  setProfileStale: ({ accountId }) => ({
    type: NFT_PROFILE_SET_STALE,
    payload: { accountId },
  }),
};

const auctions = withFetcher({
  beginFetchActionType: AUCTIONS_LIST_FETCH_START,
  endFetchActionType: AUCTIONS_LIST_FETCH_END,
  initialData: auctionsInitialState,
  extractData: ({ auctions, overrideAuctionId }) => ({
    stale: false,
    list: auctions,
    overrideAuctionId,
  }),
  mergeData: (oldData, newData) => {
    const startedAuction = newData.list?.find(
      (auction) => auction.status === AuctionStatus.AUCTION_STARTED
    );
    const processingAuction = newData.list?.find(
      (auction) => auction.status === AuctionStatus.AUCTION_PROCESSING
    );
    const finishedAuction = newData.list?.find(
      (auction) => auction.status === AuctionStatus.AUCTION_FINISHED
    );

    const overridedAuction = newData.list?.find(
      ({ id }) => id === newData.overrideAuctionId
    );

    return {
      ...newData,
      currentAuctionId:
        overridedAuction?.id ||
        startedAuction?.id ||
        processingAuction?.id ||
        finishedAuction?.id ||
        newData.list?.[0]?.id,
    };
  },
})((state, action) => {
  switch (action.type) {
    case AUCTIONS_LIST_SET_STALE: {
      return {
        ...state,
        stale: true,
      };
    }
  }
});

const getLotList = (state, auctionId, tag) =>
  (state.lotListMap[auctionId] && state.lotListMap[auctionId][tag]) || [];

const lots = withFetcher({
  beginFetchActionType: LOTS_LIST_FETCH_START,
  endFetchActionType: LOTS_LIST_FETCH_END,
  initialData: lotsInitialState,
  extractData: ({ auctionId, lotListsByTag }) => ({
    ...NftLotProcessor.normalizeLotListByTagResponse({
      auctionId,
      lotListsByTag,
    }),
  }),
  mergeData: (state, newData) => ({
    ...state,
    lotListMap: NftLotProcessor.mergeLotListMaps(
      state.lotListMap,
      newData.lotListMap
    ),
    lotMap: {
      ...state.lotMap,
      ...newData.lotMap,
    },
    stale: false,
  }),
})((state, action) => {
  switch (action.type) {
    case PROCESS_LOT_BID_START: {
      const lotId = action.payload;

      return {
        ...state,
        lotStatusMap: NftLotProcessor.updateLotStatus(
          state.lotStatusMap,
          lotId,
          LotClientStatus.PROCESSING
        ),
      };
    }
    case PROCESS_LOT_BID_END: {
      const { result, lot, lotId } = action.payload;

      return {
        ...state,
        lotStatusMap: NftLotProcessor.updateLotStatus(
          state.lotStatusMap,
          lotId,
          result
        ),
        lotMap: NftLotProcessor.updateLot(state.lotMap, lot),
        stale: false,
      };
    }
    case UPDATE_LOT_STATUS: {
      const { lotId, status } = action.payload;

      return {
        ...state,
        lotStatusMap: NftLotProcessor.updateLotStatus(
          state.lotStatusMap,
          lotId,
          status
        ),
      };
    }
    case UPDATE_LOTS: {
      const { lots } = action.payload;

      return {
        ...state,
        lotMap: NftLotProcessor.updateLots(state.lotMap, lots),
      };
    }
    case SET_LOT_LIST_STALE: {
      const { withLotsClear } = action.payload;

      return {
        ...state,
        stale: true,
        lotListMap: withLotsClear ? {} : state.lotListMap,
      };
    }
    case SET_SCROLL: {
      return {
        ...state,
        scroll: Math.max(state.scroll, action.payload),
      };
    }
    case RESET_SCROLL: {
      return {
        ...state,
        scroll: action.payload,
      };
    }
    case ADD_LOT_TO_MY_LOT_LIST: {
      const { lotId, auctionId } = action.payload;

      const myLotList = getLotList(state, auctionId, KnownListTags.MY);

      const hasLotInMyList = myLotList.includes(lotId);

      if (hasLotInMyList) {
        return state;
      }

      return {
        ...state,
        lotListMap: NftLotProcessor.mergeLotListMaps(state.lotListMap, {
          [auctionId]: {
            [KnownListTags.MY]: [...myLotList, lotId],
          },
        }),
      };
    }
    case LOTS_CLEAR: {
      return {
        ...state,
        lotListMap: {},
        lotMap: {},
      };
    }
  }

  return state;
});

const profiles = (state = profilesInitialState, action) => {
  switch (action.type) {
    case NFT_ACCOUNT_FETCH_START: {
      const { accountId } = action.payload;
      const account = state[accountId] || { ...initialFetcherState };

      return { ...state, [accountId]: { ...account, isLoadInProgress: true } };
    }
    case NFT_ACCOUNT_FETCH_END: {
      const { accountId, error } = action.payload;
      const account = state[accountId];
      if (error) {
        return {
          ...state,
          [accountId]: {
            ...account,
            isLoadInProgress: false,
            isLoadFailed: true,
            stale: false,
          },
        };
      }

      const { lots, creatorNfts, ownedNfts } = action.payload;

      return {
        ...state,
        [accountId]: {
          ...account,
          lots: uniq(NftLotProcessor.mapLotIds(lots)),
          creatorNfts,
          ownedNfts,
          isLoadInProgress: false,
          isLoadFailed: false,
          stale: false,
        },
      };
    }
    case USER_LOTS_CLEAR: {
      const { accountId } = action.payload;
      const account = state[accountId];

      return {
        ...state,
        [accountId]: {
          ...account,
          lots: [],
        },
      };
    }

    case NFT_PROFILE_SET_STALE: {
      const { accountId } = action.payload;
      const account = state[accountId];

      return {
        ...state,
        [accountId]: {
          ...account,
          stale: true,
        },
      };
    }
  }

  return state;
};

export const selectors = {
  getAuction: (state) =>
    state.auctions.list?.find(
      ({ id }) => state.auctions.currentAuctionId === id
    ),
  getCurrentAuctionId: (state) => state.auctions.currentAuctionId,
  getAuctionById: (state, auctionId) =>
    state.auctions.list.find((auction) => auction.id === auctionId),
  getAuctionMillisLeft: (state, auctionId) =>
    selectors.getAuctionById(state, auctionId)?.millisLeft || 0,
  getAuctionStale: (state) => state.auctions.stale,
  isAuctionsLoading: (state) =>
    fetcherSelectors.getIsLoadInProgress(state.auctions),
  isAuctionsLoadFaild: (state) =>
    fetcherSelectors.getIsLoadFailed(state.auctions),

  isLotsLoading: (state) => fetcherSelectors.getIsLoadInProgress(state.lots),
  isLotsLoadFailed: (state) => fetcherSelectors.getIsLoadFailed(state.lots),
  getLotList: (state, auctionId, tag) => getLotList(state.lots, auctionId, tag),
  getLotById: (state, lotId) => state.lots.lotMap[lotId],
  getAccountLot: (state, accountId) => {
    const profile = selectors.getAuctionProfile(state, accountId);
    if (!profile) {
      return null;
    }

    const currentAuction = selectors.getCurrentAuctionId(state);
    const { lots } = profile;

    if (!currentAuction || !lots.length) {
      return null;
    }

    const lot = selectors.getLotById(
      state,
      lots.find(
        (lotId) =>
          currentAuction &&
          selectors.getLotById(state, lotId)?.auctionId === currentAuction
      ) || lots[0]
    );

    return lot || null;
  },
  getLotsScroll: (state) => state.lots.scroll,
  getLotStatus: (state, lotId) => state.lots.lotStatusMap[lotId],
  getLotListLength: (state, auctionId, tag) =>
    getLotList(state.lots, auctionId, tag).length,
  getLotsListStale: (state) => state.lots.stale,

  isAuctionProfileLoading: (state, accountId) =>
    !!state.profiles[accountId] &&
    fetcherSelectors.getIsLoadInProgress(state.profiles[accountId]),
  isAuctionProfileLoadFailed: (state, accountId) =>
    !!state.profiles[accountId] &&
    fetcherSelectors.getIsLoadFailed(state.profiles[accountId]),
  getAuctionProfile: (state, accountId) => state.profiles[accountId],
};

export default combineReducers({
  auctions,
  lots,
  profiles,
});
