const countries = require("i18n-iso-countries");
module.exports = function(cb) {
    require.ensure([
      "i18n-iso-countries/langs/vi.json",
      "date-fns/locale/vi",
      "translations/vi.json",
    ], (require) => {
      countries.registerLocale(require("i18n-iso-countries/langs/vi.json"));
      cb({
        translatedMessages: require("translations/vi.json"),
        dateFnsLocale: require("date-fns/locale/vi").default
      });
    }, "locale-vi-no-intl");
};