import { Dispatch } from "redux";
import { fetchStreamByEncryptedAccountIds } from "chat/imports/api";
import {
  RootState,
  getFollowSuggestionsModerationLevel,
  loadedUsersListCurrentStreams,
} from "chat/imports/state";
import { StoredConversation } from "chat/state/reducer";

export default (conversations: StoredConversation[]) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const accountIds = Array.from(
      new Set(
        conversations
          .filter(
            (conversation) =>
              conversation &&
              !conversation.group_info &&
              conversation.account_info &&
              conversation.account_info.account_id
          )
          .map((conversation) => conversation.account_info!.account_id)
      )
    );
    if (accountIds.length > 0) {
      const moderationLevel = getFollowSuggestionsModerationLevel(state);
      const json = await fetchStreamByEncryptedAccountIds({
        accountIds,
        moderationLevel,
      });

      dispatch(loadedUsersListCurrentStreams({ accountIds, data: json }));
    }
  };
