import React, { FC } from "react";
import classnames from "classnames";
import styles from "src/features/signin/bottomScreen/SignUp.scss";

const MainViewContentContainer: FC = ({ children }) => (
  <div className={classnames(styles.titleAndButtons, styles.isFullScreen)}>
    {children}
  </div>
);

export default MainViewContentContainer;
