import { EventFields } from "@analytics/enums";
import streamKindToAnalyticsStreamKind from "@analytics/streamKindToAnalyticsStreamKind";
import { GIFT_SOURCE_PRIVATE_TICKET } from "src/analytics/gifting/giftSource";
import { StreamKind } from "src/types/richFragment/Stream";
import { getCurrentStream } from "state/selectors";
import getGiftClosestToPriceSelector from "state/tree/viewerSession/getGiftClosestToPriceSelector";

export default (state) => {
  const { id: streamId, ticketPrice } = getCurrentStream(state);
  return {
    [EventFields.GIFT_SOURCE]: GIFT_SOURCE_PRIVATE_TICKET,
    [EventFields.STREAM_ID]: streamId,
    [EventFields.GIFT_ID]: getGiftClosestToPriceSelector(state).id,
    [EventFields.ONE_CLICK_GIFTING]: 0,
    [EventFields.STREAM_KIND]: streamKindToAnalyticsStreamKind(
      StreamKind.TICKET_PRIVATE
    ),
    [EventFields.TICKET_PRICE]: ticketPrice,
  };
};
