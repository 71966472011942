import { fetchCountryByIp } from "api/countryByIp";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CountryByIpState } from "state/tree/countryByIp";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { RootState } from "state/delegate";
import { countryByIpSelectors } from "state/selectors";
import { isApiError } from "api/utils/enhancedFetch";

export const getCountryByIp = createAsyncThunk<
  CountryByIpState["data"],
  FetcherMetaV2 | void,
  { state: RootState; rejectValue: string }
>(
  "lwc/countryByIp/fetch",
  async (_, api) => {
    try {
      return await fetchCountryByIp();
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;
      return api.rejectWithValue(error);
    }
  },
  {
    condition: (arg, api) => {
      const state = api.getState();
      const meta = countryByIpSelectors.meta(state);
      return !meta.loading && meta.stale;
    },
  }
);
