import { CurrencyPriceFromServer } from "src/types/personalOffer";

export const getCurrentCurrencyPrice = (
  { basePrice, price }: CurrencyPriceFromServer[0],
  isUseBasePriceFromOfferCurrentPriceEnabled: boolean
): number => {
  if (
    isUseBasePriceFromOfferCurrentPriceEnabled &&
    basePrice !== undefined &&
    basePrice !== null
  ) {
    return basePrice;
  }

  return price;
};
