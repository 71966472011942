import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { EventNames } from "@analytics/enums";
import ScreenViewReportingContext from "@analytics/screenView/ScreenViewReportingContext";
import { userSelectors } from "state/selectors";
import { emitEvent } from "./emit";

export const RESULT_SHARED = 1;
export const RESULT_CANCELLED = 0;

const useEmitLinkSharedEvent = ({
  linkType,
  linkTypeParam,
  shareType = "share_button",
  peerId,
  sessionId,
}) => {
  const { getStack } = useContext(ScreenViewReportingContext);
  const accountId = useSelector(userSelectors.getMyAccountId);
  return useCallback(
    (link, result) => {
      const [screen] = getStack();
      // eslint-disable-next-line camelcase
      const link_id = link.split("/").pop();
      emitEvent(EventNames.LINK_SHARED, {
        account_id: accountId,
        link_id,
        link_type: linkType,
        source: linkType,
        link_type_params: linkTypeParam,
        peer_id: peerId,
        screen: screen && screen.reportedName,
        session_id: sessionId,
        share_type: shareType,
        short_link: 0,
        ...(result !== undefined && { result_code: result }),
      });
    },
    [linkType, linkTypeParam, accountId, peerId, sessionId]
  );
};

export default useEmitLinkSharedEvent;
