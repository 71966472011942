/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.TransactionProtos || ($protobuf.roots.TransactionProtos = {});

export const TransactionStatusMessage = $root.TransactionStatusMessage = (() => {

    function TransactionStatusMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TransactionStatusMessage.prototype.transactionId = "";
    TransactionStatusMessage.prototype.status = 0;
    TransactionStatusMessage.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    TransactionStatusMessage.prototype.isFirst = false;

    TransactionStatusMessage.create = function create(properties) {
        return new TransactionStatusMessage(properties);
    };

    TransactionStatusMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
            writer.uint32(10).string(message.transactionId);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(16).int32(message.status);
        if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
            writer.uint32(24).uint64(message.timestamp);
        if (message.isFirst != null && Object.hasOwnProperty.call(message, "isFirst"))
            writer.uint32(32).bool(message.isFirst);
        return writer;
    };

    TransactionStatusMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TransactionStatusMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TransactionStatusMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.transactionId = reader.string();
                    break;
                }
            case 2: {
                    message.status = reader.int32();
                    break;
                }
            case 3: {
                    message.timestamp = reader.uint64();
                    break;
                }
            case 4: {
                    message.isFirst = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    TransactionStatusMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TransactionStatusMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.TransactionStatusMessage)
            return object;
        let message = new $root.TransactionStatusMessage();
        if (object.transactionId != null)
            message.transactionId = String(object.transactionId);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "TRANSACTION_STATUS_UNKNOWN":
        case 0:
            message.status = 0;
            break;
        case "SUCCESS":
        case 1:
            message.status = 1;
            break;
        case "FAILURE":
        case 2:
            message.status = 2;
            break;
        }
        if (object.timestamp != null)
            if ($util.Long)
                (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
            else if (typeof object.timestamp === "string")
                message.timestamp = parseInt(object.timestamp, 10);
            else if (typeof object.timestamp === "number")
                message.timestamp = object.timestamp;
            else if (typeof object.timestamp === "object")
                message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
        if (object.isFirst != null)
            message.isFirst = Boolean(object.isFirst);
        return message;
    };

    TransactionStatusMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.transactionId = "";
            object.status = options.enums === String ? "TRANSACTION_STATUS_UNKNOWN" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timestamp = options.longs === String ? "0" : 0;
            object.isFirst = false;
        }
        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
            object.transactionId = message.transactionId;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.TransactionStatus[message.status] === undefined ? message.status : $root.TransactionStatus[message.status] : message.status;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (typeof message.timestamp === "number")
                object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
            else
                object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
        if (message.isFirst != null && message.hasOwnProperty("isFirst"))
            object.isFirst = message.isFirst;
        return object;
    };

    TransactionStatusMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TransactionStatusMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TransactionStatusMessage";
    };

    return TransactionStatusMessage;
})();

export const TransactionStatus = $root.TransactionStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "TRANSACTION_STATUS_UNKNOWN"] = 0;
    values[valuesById[1] = "SUCCESS"] = 1;
    values[valuesById[2] = "FAILURE"] = 2;
    return values;
})();

export { $root as default };
