import { fetchPersonalOffers } from "api/personalOffers";
import { PersonalOfferType } from "src/enums";
import { Nullable } from "src/types/common";
import { PersonalOffersConfig } from "src/types/personalOffer";

export interface GetRemoteConfigArgs {
  campaignId: Nullable<string>;
  trackingId?: Nullable<string>;
}

export const getRemoteConfig = async ({
  campaignId,
}: GetRemoteConfigArgs): Promise<PersonalOffersConfig> =>
  await fetchPersonalOffers(PersonalOfferType.LANDING_PAGE, campaignId || "");
