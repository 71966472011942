import type {
  RealTimeRecommendationsClientEvent,
  RealTimeRecommendationsClientEventRequest,
  RealTimeRecommendationsSendEventsOptions,
} from "src/features/recommendations/common/types";
import type { Nullable } from "src/types/common";
import {
  getRealtimeRecommendationsInterval,
  getRealtimeRecommendationsRetryAttempts,
} from "environment";
import {
  sendRealTimeRecommendationsEventBeaconRequest,
  sendRealTimeRecommendationsEventRequest,
} from "src/features/recommendations/api/recommendationsApi";

import { RealTimeRecommendationsContextService } from "src/features/recommendations/services/RealTimeRecommendationsContextService";
import { RealTimeRecommendationsQueueService } from "src/features/recommendations/services/RealTimeRecommendationsQueueService";

export class RealTimeRecommendationsSenderService {
  private readonly contextService: RealTimeRecommendationsContextService;

  private enabled = false;

  private interval: number = 2000;

  private isInitialized = false;

  private maxRetryAttempts: number = 3;

  private readonly queueService: RealTimeRecommendationsQueueService;

  private retriesAttempts: number = 0;

  private timeoutInstance: Nullable<ReturnType<typeof setTimeout>> = null;

  constructor(
    queueService: RealTimeRecommendationsQueueService,
    contextService: RealTimeRecommendationsContextService
  ) {
    this.queueService = queueService;
    this.contextService = contextService;
  }

  private buildRequest(
    events: RealTimeRecommendationsClientEvent[]
  ): RealTimeRecommendationsClientEventRequest {
    const context = this.contextService.build();
    const sendTimestamp = Date.now();

    return {
      context,
      events,
      sendTimestamp,
    };
  }

  private isRetryInProgress() {
    return this.retriesAttempts < this.maxRetryAttempts;
  }

  private retryDelayedOperation() {
    if (this.retriesAttempts <= 1) {
      this.queueService.clearEvents();
      this.disable();
      return;
    }

    this.setRetryAttempts(this.retriesAttempts - 1);
    this.runDelayedOperation();
  }

  private runDelayedOperation(): void {
    if (!this.enabled) return;

    this.timeoutInstance = setTimeout(() => {
      const events = this.queueService.getEvents();

      if (events.length > 0) {
        this.queueService.clearEvents();
        this.sendEvents(events)
          .then(() => {
            if (this.isRetryInProgress()) {
              this.setRetryAttempts(this.maxRetryAttempts);
            }
          })
          .catch(() => {
            this.retryDelayedOperation();
          })
          .finally(() => {
            if (!this.isRetryInProgress()) {
              this.runDelayedOperation();
            }
          });

        return;
      }

      this.runDelayedOperation();
    }, this.interval);
  }

  private setRetryAttempts(retriesLeft: number) {
    this.retriesAttempts = retriesLeft;
  }

  public disable(): void {
    this.enabled = false;

    if (this.timeoutInstance) {
      clearTimeout(this.timeoutInstance);
    }
  }

  public enable(): void {
    this.enabled = true;
    this.retriesAttempts = this.maxRetryAttempts;

    this.runDelayedOperation();
  }

  public getInterval(): number {
    return this.interval;
  }

  public init() {
    const maxRetryAttempts = getRealtimeRecommendationsRetryAttempts();
    const interval = getRealtimeRecommendationsInterval();

    if (window.twcVersion) {
      this.contextService.setTangoVersion(window.twcVersion);
    }

    if (interval) {
      this.setInterval(interval);
    }

    if (maxRetryAttempts) {
      this.setMaxRetryAttempts(maxRetryAttempts);
    }

    if (!this.queueService.isEnabled()) {
      this.queueService.enable();
    }

    if (!this.enabled) {
      this.enable();
    }

    this.isInitialized = true;
  }

  public isEnabled(): boolean {
    return this.enabled;
  }

  public isInited() {
    return this.isInitialized;
  }

  public async sendEvents(
    events: RealTimeRecommendationsClientEvent[],
    options: RealTimeRecommendationsSendEventsOptions = {}
  ): Promise<boolean> {
    if (!this.enabled) return false;

    const { isForceSend = false } = options;
    const request = this.buildRequest(events);

    if (isForceSend) {
      return sendRealTimeRecommendationsEventBeaconRequest(request);
    }

    return sendRealTimeRecommendationsEventRequest(request);
  }

  public setInterval(interval: number): void {
    this.interval = interval;
  }

  public setMaxRetryAttempts(maxRetryAttempts: number): void {
    this.maxRetryAttempts = maxRetryAttempts;
  }
}
