import React, { ElementType, FC } from "react";
import classnames from "classnames";
import { Breakpoints } from "legal/enums";
import { Typography } from "legal/imports/components";
import { TYPOGRAPHY_TYPE } from "legal/imports/constants";
import { useBreakpointPrecise } from "legal/imports/hooks";
import styles from "./LegalTypography.scss";

const typographyType = {
  h1: {
    [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HERO3,
    [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE1,
    [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HEADLINE3,
    [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HEADLINE3,
  },
  h2: {
    [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE2,
    [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE3,
    [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HEADLINE4,
    [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HEADLINE4,
  },
};

type HeadlineProps = {
  counterReset?: boolean;
  level: 1 | 2;
};

const Headline: FC<HeadlineProps> = ({
  level = 1,
  children,
  counterReset = false,
}) => {
  const breakpoint = useBreakpointPrecise();
  const as: ElementType = `h${level}`;

  return (
    <Typography
      type={typographyType[as][breakpoint]}
      className={classnames(
        styles.headline,
        styles[as],
        styles[breakpoint],
        counterReset && styles.counterReset
      )}
      as={as}
    >
      {children}
    </Typography>
  );
};

export default Headline;
