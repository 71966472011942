import { Alias } from "src/types/common";
import { DeeplinkQueryField, Target } from "ui/navigation/deepLink/types";

export const createAgentViralityLink = (myAccountId: string) =>
  `${window.location.origin}/deeplink/q/?${DeeplinkQueryField.TARGET}=${Target.AGENT}&${DeeplinkQueryField.REFERRER_ID}=${myAccountId}`;

export const createSupporterViralityLink = (myAccountId: string) =>
  `${window.location.origin}/deeplink/q/?${DeeplinkQueryField.TARGET}=${Target.SUPPORTER}&${DeeplinkQueryField.REFERRER_ID}=${myAccountId}`;

export const createMomentViralityLink = (storyId: string) =>
  `${window.location.origin}/deeplink/q/?${DeeplinkQueryField.TARGET}=${Target.STORIES}&${DeeplinkQueryField.STORY_ID}=${storyId}`;

export const createProfileViralityLink = ({
  encryptedProfileId,
  alias,
}: {
  alias: Alias | undefined;
  encryptedProfileId: string;
}) =>
  `${window.location.origin}${
    alias
      ? `/${alias.alias}`
      : `/deeplink/q/?${DeeplinkQueryField.TARGET}=${Target.VIEW_PROFILE}&${DeeplinkQueryField.UID}=${encryptedProfileId}`
  }`;

export const createStreamViralityLink = ({
  streamId,
  streamerId,
  alias,
}: {
  alias: Alias | undefined;
  streamId: string;
  streamerId: string;
}) =>
  `${window.location.origin}${
    alias
      ? `/${alias.alias}`
      : `/deeplink/q/?${DeeplinkQueryField.TARGET}=${Target.STREAM}&${DeeplinkQueryField.STREAM_ID}=${streamId}&${DeeplinkQueryField.UID}=${streamerId}`
  }`;
