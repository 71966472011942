import isEmpty from "lodash.isempty";
import merge from "lodash.merge";
import { StreamEvents } from "src/enums";
import { reverse } from "src/utils/immutableArrayUtils";
import { mapValues, omit, pick, uniq, without } from "src/utils/miniLodash";
import {
  ACME_RECEIVED,
  GIFT_SENT,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  SEND_MESSAGE_TO_SESSION_BEGIN,
  SEND_MESSAGE_TO_SESSION_END,
  VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH,
  VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED,
  VIEWER_SESSION_LEFT,
  VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR,
  VIEWER_SESSION_NOTIFICATIONS,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_SET_DIRTY_FLAGS,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";
import { getMessageType } from "state/tree/utils/getMessageType";
import parseMessageDetails from "state/tree/utils/parseMessageDetails";
import ensureParameter from "../utils/ensureParameter";
import createFakeGiftEventFromSentGiftAction from "./createFakeGiftEventFromSentGiftAction";

const initialState = {
  notificationsShouldBeUpdated: true,
  eventIds: [],
  events: {},
  eventsNeedingReconcillation: [],
  lastNotificationId: "",
};

const getHiddenEventIds = (context, event) => {
  const {
    giftsDisplayQueue: { queue },
  } = context;

  const alreadySentGift = queue.find(({ accountId, giftId, mediaGift }) => {
    if (
      accountId === event?.accountId &&
      giftId === event.data?.giftId &&
      event.type !== StreamEvents.MULTI_BROADCAST_GIFT
    ) {
      if (!isEmpty(mediaGift)) {
        return mediaGift.gfyId === event.data.mediaGift?.gfyId;
      }

      return true;
    }

    return false;
  });

  const realEventIdsToBeRemoved = [];
  if (alreadySentGift) {
    alreadySentGift.eventIds.forEach((eventId) => {
      if (eventId !== event.id) {
        realEventIdsToBeRemoved.push(eventId);
      }
    });
  }

  return realEventIdsToBeRemoved;
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      const { streamId } = context;
      if (action.payload === streamId || action.meta.multibroadcastSwitch) {
        const { keepEventTypes } = action.meta;
        if (!keepEventTypes || !keepEventTypes.length) {
          return {
            ...initialState,
            lastNotificationId: state.lastNotificationId,
          };
        }
        const { eventIds, events, eventsNeedingReconcillation } = state;
        const eventIdsToRemove = eventIds.filter(
          (id) => !events[id] || !keepEventTypes.includes(events[id].type)
        );

        return {
          ...state,
          eventIds: without(eventIds, ...eventIdsToRemove),
          events: omit(events, ...eventIdsToRemove),
          notificationsShouldBeUpdated: true,
          eventsNeedingReconcillation: without(
            eventsNeedingReconcillation,
            ...eventIdsToRemove
          ),
        };
      }

      return { ...initialState, streamId: action.payload };
    }
    case VIEWER_SESSION_LEFT: {
      return {
        ...state,
        events: mapValues(state.events, (x) => ({ ...x, leftoverEvent: true })),
      };
    }

    case VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED: {
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload]: {
            ...state.events[action.payload],
            leftoverEvent: true,
          },
        },
      };
    }

    case VIEWER_SESSION_NOTIFICATIONS: {
      const {
        result,
        entities: { notifications },
      } = action.payload;
      if (!result || !result.length) {
        return state;
      }
      const existingNotificationIds = state.eventIds.filter((x) => {
        const { type } = state.events[x];

        return type === StreamEvents.WARNING || type === StreamEvents.PROMOTION;
      });
      const groupEventKey = (x) => `${x.data}.${x.type}`;
      const deduplicationMap = existingNotificationIds.reduce((a, x) => {
        const key = groupEventKey(state.events[x]);
        a[key] = x;

        return a;
      }, {});
      const deduplicatedEventIds = reverse(result).filter((x) => {
        if (existingNotificationIds.includes(x)) {
          return false;
        }
        const event = notifications[x];
        const key = groupEventKey(event);
        if (deduplicationMap[key]) {
          return false;
        }
        deduplicationMap[key] = x;

        return true;
      });
      if (!deduplicatedEventIds.length) {
        return {
          ...state,
          lastNotificationId: result[result.length - 1],
        };
      }

      return {
        ...state,
        eventIds: [...deduplicatedEventIds, ...state.eventIds],
        events: {
          ...state.events,
          ...pick(parseMessageDetails(notifications), ...deduplicatedEventIds),
        },
        lastNotificationId: result[result.length - 1],
      };
    }
    case GIFT_SENT: {
      const event = createFakeGiftEventFromSentGiftAction(action);
      const hiddenEventIds = getHiddenEventIds(context, event);

      return {
        ...state,
        eventIds: without([event.id, ...state.eventIds], ...hiddenEventIds),
        events: {
          ...state.events,
          [event.id]: event,
        },
      };
    }
    case SEND_MESSAGE_TO_SESSION_BEGIN: {
      const { streamId } = context;
      const { eventIds, events, eventsNeedingReconcillation } = state;
      const {
        payload: message,
        meta: { currentUserId, requestId },
      } = action;
      const event = {
        id: `FAKE:${streamId}:${currentUserId}:${requestId}`,
        accountId: currentUserId,
        clientEventId: JSON.stringify(requestId),
        data: { content: message },
        type: StreamEvents.MESSAGE,
        pending: true,
      };

      return {
        ...state,
        eventIds: [event.id, ...eventIds],
        events: {
          ...events,
          [event.id]: event,
        },
        eventsNeedingReconcillation: [...eventsNeedingReconcillation, event.id],
      };
    }
    case SEND_MESSAGE_TO_SESSION_END: {
      const { requestId, currentUserId, censored, failedToSend } = action.meta;
      const { events, eventIds, eventsNeedingReconcillation } = state;
      const clientEventId = JSON.stringify(requestId);
      const event = Object.values(events).find(
        (event) =>
          event.clientEventId === clientEventId &&
          event.accountId === currentUserId
      );
      if (event) {
        if (action.error) {
          return {
            ...state,
            eventIds: without(eventIds, event.id),
            events: omit(events, event.id),
            eventsNeedingReconcillation: without(
              eventsNeedingReconcillation,
              event.id
            ),
          };
        }

        return {
          ...state,
          eventsShouldBeUpdated: true,
          events: merge({}, events, {
            [event.id]: {
              pending: false,
              type: getMessageType({ failedToSend, censored }),
            },
          }),
        };
      }

      return state;
    }
    case VIEWER_SESSION_UPDATE:
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }
      let {
        payload: { entities: { events, basicProfile } = {}, eventIds },
        meta: { currentUserId },
      } = action;
      if (!events || !eventIds) {
        return state;
      }
      eventIds = without(eventIds, ...Object.keys(state.events)); // prevent duplicated resolved fake events
      if (!eventIds.length) {
        return state;
      }

      if (currentUserId) {
        eventIds = eventIds.filter((x) => {
          const event = events[x];

          return !(
            event.type === StreamEvents.GIFT &&
            event.accountId === currentUserId
          );
        }); // we append own gifts immediately
      }

      const eventsWithProfile = Object.entries(events).reduce(
        (acc, [eventId, event]) => ({
          ...acc,
          [eventId]: {
            ...event,
            profile: basicProfile?.[event.accountId],
          },
        }),
        {}
      );

      const reconciledEvents = [];
      const realEventIdsToBeRemoved = [];
      const listGift = {};
      const mergedEvents = merge({}, state.events, eventsWithProfile);
      const mergedEventIds = uniq(
        [...reverse(eventIds), ...state.eventIds].filter((id, index) => {
          const event = mergedEvents[id] || {};
          realEventIdsToBeRemoved.push(...getHiddenEventIds(context, event));

          if (!isEmpty(event) && event.type === StreamEvents.GIFT) {
            const { giftId = "", mediaGift: { gfyId = "" } = { gfyId: "" } } =
              event.data || {};
            const identify = `${giftId}-${gfyId}`;

            if (listGift[event.accountId] === identify) {
              realEventIdsToBeRemoved.push(id);
            } else {
              listGift[event.accountId] = identify;
            }
          }

          return index === 0 || event.type !== StreamEvents.JOIN;
        })
      );

      state.eventsNeedingReconcillation.forEach((id) => {
        const fakeEvent = state.events[id];
        const realEvent = Object.values(events).find(
          (event) =>
            event.clientEventId === fakeEvent.clientEventId &&
            event.accountId === fakeEvent.accountId
        );
        if (realEvent) {
          realEventIdsToBeRemoved.push(realEvent.id);
          mergedEvents[realEvent.id] = fakeEvent;
          reconciledEvents.push(id);
        }
      });

      return {
        ...state,
        notificationsShouldBeUpdated: false,
        events: mergedEvents,
        eventIds: without(mergedEventIds, ...realEventIdsToBeRemoved),
        eventsNeedingReconcillation: without(
          state.eventsNeedingReconcillation,
          ...reconciledEvents
        ),
      };
    }
    case ACME_RECEIVED: {
      const { serviceIdentifier } = action.payload;
      const [command] = serviceIdentifier.split(":");
      if (command === "notification") {
        return {
          ...state,
          notificationsShouldBeUpdated: true,
        };
      }

      return state;
    }
    case VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH: {
      const { maxSize } = action.payload;
      if (state.eventIds.length <= maxSize) {
        return state;
      }
      const discardedEvents = state.eventIds.slice(maxSize);

      return {
        ...state,
        eventIds: state.eventIds.slice(0, maxSize),
        events: omit(state.events, ...discardedEvents),
        eventsNeedingReconcillation: without(
          state.eventsNeedingReconcillation,
          ...discardedEvents
        ),
      };
    }
    case VIEWER_SESSION_SET_DIRTY_FLAGS: {
      const {
        notifications:
          notificationsShouldBeUpdated = state.notificationsShouldBeUpdated,
      } = action.payload;

      return {
        ...state,
        notificationsShouldBeUpdated,
      };
    }
    case LIVE_RICH_NOTIFICATION_RECEIVED: {
      const {
        payload: {
          entities: { events = {} },
          eventIds = [],
        },
        meta: { myAccountId },
      } = action;

      const hiddenEventIds = [];
      const newEventIds = eventIds.filter((x) => {
        const event = events[x];
        hiddenEventIds.push(...getHiddenEventIds(context, event));

        return !state.eventIds.includes(x) && event?.accountId !== myAccountId;
      });

      if (!newEventIds.length) {
        return state;
      }

      return {
        ...state,
        eventIds: without(
          [...newEventIds, ...state.eventIds],
          ...hiddenEventIds
        ),
        events: {
          ...state.events,
          ...parseMessageDetails(events),
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN: {
      const {
        payload: { event },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            isTranslated: false,
            isTranslating: true,
          },
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END: {
      const {
        payload: { event, language, locale, translated },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            language,
            translation: {
              ...state.events[id].translation,
              ...(translated && { [locale]: translated }),
            },
            isTranslated: true,
            isTranslating: false,
            ...(state.events[id].error && { error: undefined }),
          },
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR: {
      const {
        payload: { event, error },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            isTranslating: false,
            error,
          },
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED: {
      const {
        payload: { event, isTranslated },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            isTranslated,
          },
        },
      };
    }
  }

  return state;
};

const getEventIds = (state) =>
  state.eventIds.filter((x) => {
    const event = state.events[x];

    return event && !event.pending;
  });

export const selectors = {
  getEventIds,
  getEvent: (state, id) => ensureParameter(id, "id") && state.events[id],
  getEvents: (state) => state.events,
  shouldUpdateNotifications: (state) => state.notificationsShouldBeUpdated,
  getLastNotificationId: (state) => state.lastNotificationId,
  getNonReconciledEvents: (state) => state.eventsNeedingReconcillation, // events that were sent, but we didn't receive them from the server yet, and we don't know their real ids
};
