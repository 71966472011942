import React, { createContext, useCallback, useMemo, useRef } from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import { defineMessages, useIntl } from "react-intl";
import { MessageDescriptor } from "@formatjs/intl";
import emptyFunction from "fbjs/lib/emptyFunction";
import { RETRY_PAY_BUTTON_PORTAL_ID } from "src/constants";

interface PurchaseToasterContextValues {
  showSuccessPurchaseToaster: (options?: {
    onShow?: VoidFunction;
    withBanner?: boolean;
  }) => void;
  showFailurePurchaseToaster: (options?: {
    errorSummaryMessage?: MessageDescriptor;
  }) => void;
  closePurchaseToaster: (key?: SnackbarKey) => void;
  showPendingPurchaseToaster: (options: { onShow: () => void }) => void;
  retryPayButtonPortalId: string;
}

const PurchaseToasterContext = createContext<PurchaseToasterContextValues>({
  showSuccessPurchaseToaster: emptyFunction,
  showFailurePurchaseToaster: emptyFunction,
  closePurchaseToaster: emptyFunction,
  showPendingPurchaseToaster: emptyFunction,
  retryPayButtonPortalId: "",
});

const typeMessages = defineMessages({
  successful: {
    id: "purchase.one-click.successful",
    defaultMessage: "Payment Successful!",
  },
  pending: {
    id: "purchase-pending",
    defaultMessage:
      "Your transaction is being processed, it may take up to 1 minute.",
  },
  failure: {
    id: "purchase-failure",
    defaultMessage: "Payment Failure",
  },
});

const TYPE_SNACKBAR = {
  SUCCESS: 1,
  FAILURE: 2,
  PENDING: 3,
};

export const PurchaseToasterContextProvider: React.FC = ({ children }) => {
  const intl = useIntl();
  const snackbar = useRef<{
    id?: SnackbarKey;
    type?: number;
  }>({
    id: undefined,
    type: undefined,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closePurchaseToaster = useCallback(
    (key) => {
      if (key) {
        return closeSnackbar(key);
      }

      if (snackbar.current.type !== TYPE_SNACKBAR.SUCCESS) {
        return closeSnackbar(snackbar.current.id);
      }
    },
    [closeSnackbar]
  );

  const showSuccessPurchaseToaster = useCallback(
    ({ onShow = emptyFunction, withBanner = false } = {}) => {
      closePurchaseToaster(snackbar.current.id);

      const snackbarId = enqueueSnackbar(
        intl.formatMessage(typeMessages.successful),
        {
          autoHideDuration: withBanner ? 1500 : 3500,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          onEnter: () => {
            onShow();
          },
          variant: "purchaseSuccess",
        }
      );

      snackbar.current = {
        id: snackbarId,
        type: TYPE_SNACKBAR.SUCCESS,
      };
    },
    [closePurchaseToaster, enqueueSnackbar, intl]
  );

  const showFailurePurchaseToaster = useCallback(
    ({ errorSummaryMessage } = {}) => {
      closePurchaseToaster(snackbar.current.id);

      const snackbarId = enqueueSnackbar(
        intl.formatMessage(errorSummaryMessage || typeMessages.failure),
        {
          autoHideDuration: 7000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "purchaseFailure",
        }
      );

      snackbar.current = {
        id: snackbarId,
        type: TYPE_SNACKBAR.FAILURE,
      };
    },
    [closePurchaseToaster, enqueueSnackbar, intl]
  );

  const showPendingPurchaseToaster = useCallback(({ onShow }) => {
    closePurchaseToaster(snackbar.current.id);

    const snackbarId = enqueueSnackbar(
      intl.formatMessage(typeMessages.pending),
      {
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        onEnter: () => {
          onShow();
        },
        variant: "purchasePending",
      }
    );

    snackbar.current = {
      id: snackbarId,
      type: TYPE_SNACKBAR.PENDING,
    };
  }, []);
  const contextValues = useMemo<PurchaseToasterContextValues>(
    () => ({
      showSuccessPurchaseToaster,
      showFailurePurchaseToaster,
      closePurchaseToaster,
      retryPayButtonPortalId: RETRY_PAY_BUTTON_PORTAL_ID,
      showPendingPurchaseToaster,
    }),
    [
      showSuccessPurchaseToaster,
      showFailurePurchaseToaster,
      closePurchaseToaster,
      showPendingPurchaseToaster,
    ]
  );

  return (
    <PurchaseToasterContext.Provider value={contextValues}>
      {children}
    </PurchaseToasterContext.Provider>
  );
};

export default PurchaseToasterContext;
