import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { GIFT_SOURCE_PREMIUM_MESSAGE } from "chat/constants";
import { MessageStatusResult } from "chat/enums";
import {
  EventFields,
  EventNames,
  emitEvent,
  selectBaseGiftingParams,
} from "chat/imports/analytics";
import { RootState, userSelectors } from "chat/imports/state";
import { ApplicationThunk } from "chat/imports/types";
import { isApiError } from "chat/imports/utils";
import {
  EmitPremiumMessageSentParams,
  emitPremiumMessageSent,
} from "chat/premiumMessage/analytics/emitPremiumMessageSent";
import {
  EmitUnlockPremiumMessageParams,
  emitUnlockPremiumMessage,
} from "chat/premiumMessage/analytics/emitUnlockPremiumMessage";
import {
  sendPremiumMessage as sendPremiumMessageApi,
  unlockPremiumMessage as unlockPremiumMessageApi,
} from "chat/premiumMessage/api/premiumMessageApi";
import {
  ContentType,
  MediaInfo,
  PlatformType,
  ShareRequest,
  ShareResponse,
  ShareResponseCode,
} from "chat/premiumMessage/types";
import {
  BaseMessagePayload,
  uploadImage,
  uploadVideo,
} from "chat/state/actionCreators";
import { UnlockPremiumMessagePayload } from "chat/types";
import { getVideoInfo } from "chat/utils/getVideoInfo";

interface SendPremiumMessagePayload extends ShareRequest {
  conversationId: string;
  from: string;
  messageId: string;
  retryId?: string;
}

export const sendPremiumMessage = createAsyncThunk<
  ShareResponse,
  SendPremiumMessagePayload,
  { rejectValue: string; state: RootState }
>(
  "lwc/premiumMessage/sendPremiumMessage",
  async ({ conversationId, giftId, recipientId, items, platformType }, api) => {
    const myAccountId = userSelectors.getMyAccountId(api.getState());

    const emitParams: Omit<
      EmitPremiumMessageSentParams,
      "messageId" | "result"
    > = {
      accountId: myAccountId,
      chatId: conversationId,
      giftId,
      recipientAccountId: recipientId,
      type: items[0].type,
    };

    try {
      const result = await sendPremiumMessageApi({
        giftId,
        recipientId,
        items,
        platformType,
      });

      if (result.responseCode === ShareResponseCode.SHARE_SUCCESS) {
        emitPremiumMessageSent({
          ...emitParams,
          messageId: result.items[0].messageId,
          result: MessageStatusResult.SUCCESS,
        });

        return result;
      }

      emitPremiumMessageSent({
        ...emitParams,
        result: MessageStatusResult.FAILURE,
      });

      return api.rejectWithValue(result.responseCode);
    } catch (e) {
      emitPremiumMessageSent({
        ...emitParams,
        result: MessageStatusResult.FAILURE,
      });

      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const unlockPremiumMessage = createAsyncThunk<
  void,
  UnlockPremiumMessagePayload,
  { rejectValue: string; state: RootState }
>("lwc/premiumMessage/unlockPremiumMessage", async ({ messageId }, api) => {
  try {
    await unlockPremiumMessageApi(messageId);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const emitUnlockPremiumMessageAndSendGift =
  (
    params: EmitUnlockPremiumMessageParams,
    isOneClickPurchase: boolean = false
  ): ApplicationThunk =>
  (_, getState) => {
    emitUnlockPremiumMessage(params);

    if (params.result === MessageStatusResult.SUCCESS) {
      const analyticParams = selectBaseGiftingParams(getState(), params.giftId);

      emitEvent(EventNames.SEND_GIFT, {
        ...analyticParams,
        [EventFields.GIFT_SOURCE]: GIFT_SOURCE_PREMIUM_MESSAGE,
        [EventFields.ONE_CLICK_PURCHASE]: isOneClickPurchase ? 1 : 0,
      });
    }
  };

export const sendPremiumMediaMessage = createAsyncThunk<
  void,
  {
    conversationId: string;
    giftId: string;
    mediaFile: File;
    mediaType: ContentType;
    mediaUrl: string;
  } & BaseMessagePayload,
  { state: RootState }
>(
  "lwc/chat/sendPremiumMediaMessage",
  async (
    { mediaType, mediaFile, mediaUrl, giftId, conversationId, from },
    { dispatch, requestId }
  ) => {
    let mediaInfo: MediaInfo;

    if (mediaType === ContentType.PHOTO) {
      const {
        url: contentUrl,
        thumbnailUrl,
        mediaId,
        width,
        height,
      } = await dispatch(
        uploadImage({
          imageUrl: mediaUrl,
          conversationId,
          messageId: requestId,
        })
      ).unwrap();

      mediaInfo = { contentUrl, thumbnailUrl, mediaId, width, height };
    } else {
      const {
        url: contentUrl,
        thumbnailUrl,
        mediaId,
      } = await dispatch(
        uploadVideo({
          file: mediaFile,
          conversationId,
          messageId: uuidv4(),
        })
      ).unwrap();

      const videoInfo = await getVideoInfo(mediaFile);

      mediaInfo = { contentUrl, thumbnailUrl, mediaId, ...videoInfo };
    }

    dispatch(
      sendPremiumMessage({
        messageId: requestId,
        giftId,
        recipientId: conversationId,
        platformType: PlatformType.WEB,
        items: [
          {
            type: mediaType,
            mediaInfo,
          },
        ],
        conversationId,
        from,
      })
    );
  }
);
