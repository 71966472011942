import React, { useMemo } from "react";
import classnames from "classnames";
import { navigationConfig } from "legal/common/config";
import { NavigationComponent } from "legal/components";
import { Breakpoints } from "legal/enums";
import { linkToBugBountyProgram } from "legal/imports/constants";
import { getBugBountyPageEnabled } from "legal/imports/environment";
import { useBreakpointPrecise } from "legal/imports/hooks";
import styles from "./LegalNav.scss";

const LegalNav = () => {
  const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;
  const preparedNavigationConfig = useMemo(
    () =>
      navigationConfig.filter(({ to }) =>
        to === linkToBugBountyProgram ? getBugBountyPageEnabled() : true
      ),
    []
  );

  return (
    <nav className={classnames(styles.root, !isDesktop && styles.mobile)}>
      <NavigationComponent navigationConfig={preparedNavigationConfig} />
    </nav>
  );
};

export default LegalNav;
