import { combineReducers, compose } from "redux";
import merge from "lodash.merge";
import {
  TOP_BROADCASTERS_BEGIN_FETCH,
  TOP_BROADCASTERS_END_FETCH,
  TOP_BROADCASTERS_INVALIDATE,
} from "state/actionTypes";

import { TimeFrame } from "src/enums";
import ensureParameter from "./utils/ensureParameter";
import withActionsFilter from "state/hor/withActionsFilter";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
} from "state/hor/withFetcher";

export const actionCreators = {
  ...createFetcherActions({
    beginFetchActionType: TOP_BROADCASTERS_BEGIN_FETCH,
    endFetchActionType: TOP_BROADCASTERS_END_FETCH,
  }),
  invalidateByTimeframe: (timeframe) => ({
    type: TOP_BROADCASTERS_INVALIDATE,
    meta: { timeframe },
  }),
};

const withTopBroadcastersFetcher = withFetcher({
  beginFetchActionType: TOP_BROADCASTERS_BEGIN_FETCH,
  endFetchActionType: TOP_BROADCASTERS_END_FETCH,
  initialData: {
    top: [],
    receivedPoints: {},
    nextCursor: "",
    timestamp: 0,
  },
  mergeData: (state, newState) => ({
    top: [...state.top, ...newState.top],
    receivedPoints: merge({}, state.receivedPoints, newState.receivedPoints),
    nextCursor: newState.nextCursor,
    timestamp: newState.timestamp,
  }),
  extractData: ({
    cursor = "",
    result = [],
    entities: { receivedPoints = {} },
    timestamp,
  }) => ({
    top: result,
    receivedPoints,
    nextCursor: cursor,
    timestamp,
  }),
});

export const selectors = {
  getBroadcasters: (state, timeframe) => state[timeframe].top,
  getReceivedPoints: (state, timeframe, accountId) =>
    ensureParameter(accountId, "accountId") &&
    state[timeframe].receivedPoints[accountId],
  getIsLoadInProgress: (state, timeframe) =>
    fetcherSelectors.getIsLoadInProgress(state[timeframe]),
  getIsLoadFailed: (state, timeframe) =>
    fetcherSelectors.getIsLoadFailed(state[timeframe]),
  getNextCursor: (state, timeframe) => state[timeframe].nextCursor,
  getLastUpdatedTimestamp: (state, timeframe) => state[timeframe].timestamp,
};

const withTimeframeFilter = (timeframe) =>
  withActionsFilter(
    (action) => action.meta && action.meta.timeframe === timeframe
  );

const invalidatorReducer = (state, action) => {
  if (action.type === TOP_BROADCASTERS_INVALIDATE) {
    return {
      ...state,
      timestamp: 0,
    };
  }
  return state;
};

export default combineReducers({
  [TimeFrame.DAILY]: compose(
    withTimeframeFilter(TimeFrame.DAILY),
    withTopBroadcastersFetcher
  )(invalidatorReducer),
  [TimeFrame.THISWEEK]: compose(
    withTimeframeFilter(TimeFrame.THISWEEK),
    withTopBroadcastersFetcher
  )(invalidatorReducer),
  [TimeFrame.LIFETIME]: compose(
    withTimeframeFilter(TimeFrame.LIFETIME),
    withTopBroadcastersFetcher
  )(invalidatorReducer),
});
