import { MessageType } from "chat/types";

export const CHAT_MAX_TEXT_LENGTH = 3000;
export const CHAT_OLDER_MESSAGES_PAGE_SIZE = 20;
export const CHAT_CONVERSATIONS_PAGE_SIZE = 20;
export const CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT = 1;
export const CHAT_CONVERSATIONS_LIST_REFRESH_MESSAGES_LIMIT = 1;
export const CHAT_SOURCE_CHAT_LIST = 2;
export const GIFT_SOURCE_PREMIUM_MESSAGE = "Premium Message";

export const systemMessageTypes = [
  MessageType.GROUP_MEMBER_JOIN,
  MessageType.GROUP_MEMBER_LEAVE,
  MessageType.GROUP_NAME_CHANGE,
  MessageType.LIKE_MESSAGE,
  MessageType.SCREENSHOT_INFO_MESSAGE,
  MessageType.VIDEO_SAVED_INFO_MESSAGE,
  MessageType.PHOTO_SAVED_INFO_MESSAGE,
];

export const ignoredMessagesTypes = [MessageType.GROUP_UPDATE];
