import React from "react";
import {
  ComponentWithClassName,
  VoidCallback,
} from "src/features/signin/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "src/features/signin/imports/ui";

interface HeaderActionParams {
  onClick: VoidCallback;
}

const HeaderAction: ComponentWithClassName<HeaderActionParams> = ({
  onClick,
  className,
  children,
}) => (
  <Button
    size={ButtonSize.MEDIUM_32}
    variant={ButtonVariant.ICON_ONLY}
    onClick={onClick}
    className={className}
  >
    {children}
  </Button>
);

export default HeaderAction;
