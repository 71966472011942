export enum BidStatus {
  BID_CANCELLED = "BID_CANCELLED",
  BID_CHARGED = "BID_CHARGED",
  BID_HOLD = "BID_HOLD",
  BID_REVOKED = "BID_REVOKED",
}

export enum BidResult {
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  PRICE_CHANGED = "PRICE_CHANGED",
  SUCCESS = "SUCCESS",
  TIMEOUT = "TIMEOUT",
}

export enum AuctionStatus {
  AUCTION_CREATED = "AUCTION_CREATED",
  AUCTION_FINISHED = "AUCTION_FINISHED",
  AUCTION_PROCESSING = "AUCTION_PROCESSING",
  AUCTION_STARTED = "AUCTION_STARTED",
}

export enum LotStatus {
  LOT_CREATED = "LOT_CREATED",
  LOT_FINISHED = "LOT_FINISHED",
  LOT_PROCESSING = "LOT_PROCESSING",
  LOT_STARTED = "LOT_STARTED",
}

export enum LotClientStatus {
  ERROR = "ERROR",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  NONE = "NONE",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  PRICE_CHANGED = "PRICE_CHANGED",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  TIMEOUT = "TIMEOUT",
}

export enum AllListTags {
  ALL = "all",
  FOLLOW = "follow",
  MY = "my",
}

export enum KnownListTags {
  MY = "my",
}

export enum ResponseCode {
  FAILED = "FAILED",
  OK = "OK",
}

export enum LotBetTargets {
  BET = "bet",
  BUYOUT = "buyout",
  RAISE = "raise",
}
