import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import emptyFunction from "fbjs/lib/emptyFunction";
import Hls from "hls.js";
import PropTypes from "prop-types";
import StreamAnalyticsContext from "@analytics/livePlay/StreamAnalyticsContext";
import {
  getHdVideoEnabled,
  getLhlsEnabled,
  getShouldLogHls,
} from "environment";
import { deviceInfoSelectors } from "state/selectors";
import useCallbackRef from "ui/hooks/useCallbackRef";
import { useUnmount } from "utils/miniReactUse";

// we are printing some useful info here
/* eslint-disable no-console */

const TECH_PARAM = "tech";

const MEDIA_DECOMPRESSION_ERROR_CODE = 3;

const hlsConfig = {
  // debug: process.env.NODE_ENV === "development",
  startFragPrefetch: true,
  liveSyncDurationCount: 3,
  forceKeyFrameOnDiscontinuity: false,
  liveDurationInfinity: true,
  liveBackBufferLength: 2,
};

const refToCallback =
  (ref, node) =>
  (...args) => {
    if (ref.current) {
      ref.current(node, ...args);
    }
  };

export const printLevels = (hls) => {
  console.log(`hls available levels (${hls.levels.length}):`);
  hls.levels
    .map(({ width, height, url }) => ({
      width,
      height,
      url,
    }))
    .forEach((x, index) => {
      console.log(
        `hls ${index === hls.currentLevel ? "*" : " "} ${index}: `,
        x
      );
    });
};

export const getPlaylistUrl = (src) => {
  const isLhlsEnabled = getLhlsEnabled();
  try {
    const url = new URL(src) || "";
    const params = url.searchParams;

    if (isLhlsEnabled) {
      params.set(TECH_PARAM, !Hls.isSupported() ? "alhls" : "lhls");
    } else {
      params.delete(TECH_PARAM);
    }

    return url.toString();
  } catch (e) {
    return src;
  }
};

const Player = ({
  forwardedRef,
  src,
  onHlsPlaybackStartError,
  poster,
  muted,
  videoEventListeners,
  forceDisableHd,
  paused,
  ...rest
}) => {
  const [hls, setHls] = useState();
  const [playerInitialized, setPlayerInitialized] = useState(false);
  const onHlsPlaybackStartErrorRef = useCallbackRef(onHlsPlaybackStartError);
  const browserName = useSelector(deviceInfoSelectors.getBrowserName);
  const [reInit, setReInit] = useState(0);
  const { logStreamError } = useContext(StreamAnalyticsContext);
  const shouldLogHls = getShouldLogHls();

  const isNativeErrorSent = useRef(false);

  useEffect(() => {
    if (!playerInitialized) {
      return;
    }
    // To be in synergy with HLC we need to call pause and play only when video node in correct statuses
    const video = forwardedRef.current;
    // we need to check readyState to prevent this error https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    if (video && video.readyState >= video.HAVE_CURRENT_DATA) {
      const videoPaused = video.paused;
      if (paused && !videoPaused) {
        video.pause();
      } else if (!paused && videoPaused) {
        video.play().catch(emptyFunction);
      }
    }
  }, [paused, playerInitialized]);

  useLayoutEffect(() => {
    const video = forwardedRef.current;
    if (!videoEventListeners) {
      return;
    }
    Object.entries(videoEventListeners).forEach(([e, h]) =>
      video.addEventListener(e, h)
    );

    return () => {
      Object.entries(videoEventListeners).forEach(([e, h]) =>
        video.removeEventListener(e, h)
      );
    };
  }, [videoEventListeners]);
  const playWithNativePlayer = !Hls.isSupported();

  const playlistUrl = useMemo(() => getPlaylistUrl(src), [src]);

  useEffect(() => {
    const video = forwardedRef.current;
    if (playWithNativePlayer && video?.src !== playlistUrl) {
      video.src = playlistUrl;
    }
  }, [playWithNativePlayer, playlistUrl]);

  useEffect(() => {
    const video = forwardedRef.current;
    if (playWithNativePlayer) {
      if (video?.src !== playlistUrl) {
        video.src = playlistUrl;
      }
      video.play().catch(async (...args) => {
        console.log("NATIVE_ERROR_PLAY", ...args);
        refToCallback(onHlsPlaybackStartErrorRef, video);
      });
    }

    const hls = new Hls({
      ...hlsConfig,
      xhrSetup: (xhr, uri) => {
        // do send cookies for playlist - necessary for private vids to work
        // don't send cookies for fragments - content-server doesn't set correct headers
        xhr.withCredentials =
          !(uri.indexOf(".mp4") > -1) && !(uri.indexOf(".ts") > -1);
      },
    });
    hls.loadSource(playlistUrl);
    const hlsListeners = {
      [Hls.Events.LEVEL_SWITCHED]: () => {
        if (shouldLogHls) {
          console.group("HLS LEVEL_SWITCHED");
          printLevels(hls);
          console.groupEnd();
        }
      },
      [Hls.Events.MANIFEST_PARSED]: (_, { levels }) => {
        if (
          (!getHdVideoEnabled() || forceDisableHd) &&
          levels &&
          levels.length
        ) {
          // this will force player to play minimum available level
          const minLevel = levels.reduce(
            (a, x, i) => (levels[a].width < x.width ? a : i),
            0
          );
          hls.currentLevel = minLevel;
          if (shouldLogHls) {
            console.group("HLS MANIFEST_PARSED");
            console.info(
              "hls HD is disabled, selected level details:",
              hls.levels[minLevel]
            );
            printLevels(hls);
            console.groupEnd();
          }
        }

        return video
          .play()
          .catch(refToCallback(onHlsPlaybackStartErrorRef, video));
      },
      [Hls.Events.ERROR]: (_, data) => {
        const { fatal, type, details } = data;
        logStreamError?.(`HLS error: ${type}, ${details}`);

        if (!fatal) {
          return;
        }
        switch (type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            hls.recoverMediaError();
            break;
        }
      },
    };
    Object.entries(hlsListeners).forEach(([e, h]) => hls.on(e, h));
    hls.attachMedia(video);
    setPlayerInitialized(true);
    setHls(hls);

    return () => {
      Object.entries(hlsListeners).forEach(([e, h]) => hls.off(e, h));
      setPlayerInitialized(false);
      hls.destroy();
    };
  }, [reInit, playWithNativePlayer]);

  useUnmount(() => {
    const video = forwardedRef.current;
    if (playWithNativePlayer) {
      video.pause();
      video.src = "";
      video.load();
    }
  });

  useEffect(() => {
    if (!hls || playWithNativePlayer) {
      return;
    }

    const levels = hls.levelController._levels;
    levels.forEach((level) => {
      if (level.url) {
        for (let i = 0; i < level.url.length; i++) {
          const tempUrl = level.url[i];
          level.url[i] = getPlaylistUrl(tempUrl);
        }
      }
    });
  }, [hls, playWithNativePlayer]);

  useEffect(() => {
    const videoEl = forwardedRef.current;

    const onError = (error) => {
      console.info("Native error happened: ", error);

      if (videoEl.error) {
        console.info("Player error status: ", videoEl.error);
        const { code, message = "No message" } = videoEl.error;

        if (code === MEDIA_DECOMPRESSION_ERROR_CODE) {
          // Decompression errors can happen on each packet loss, only send error log on the first encounter
          // TODO: update error classification and handling flow in https://tango-me.atlassian.net/browse/CSDEV-7798
          if (!isNativeErrorSent.current) {
            logStreamError?.(`#${code}: ${message}`);

            isNativeErrorSent.current = true;
          }
        } else {
          logStreamError?.(`#${code}: ${message}`);
        }
      } else {
        console.info("Player has no error status");
      }
      console.info("Restoring video");
      setReInit((prev) => prev + 1);
    };
    videoEl.addEventListener("error", onError);

    return () => {
      videoEl.removeEventListener("error", onError);
    };
  }, [forwardedRef]);

  useEffect(() => {
    if (browserName !== "Firefox" || paused) {
      return;
    }
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1611186 - PiP close button should also pause the video
    // exiting picture-in-picture mode in firefox pauses video cuz they are idiots or something
    // this is a workaround to resume video, cuz no picture-in-picture events are sent
    const video = forwardedRef.current;
    const forceResumeOnce = () => {
      video.removeEventListener("pause", forceResumeOnce);
      video.play().catch(emptyFunction);
    };
    const togglePipListener = () => {
      video.addEventListener("pause", forceResumeOnce);
    };
    video.addEventListener("MozTogglePictureInPicture", togglePipListener);

    return () => {
      video.removeEventListener("pause", forceResumeOnce);
      video.removeEventListener("MozTogglePictureInPicture", togglePipListener);
    };
  }, [paused, browserName]);

  return (
    <video
      ref={forwardedRef}
      muted={muted}
      poster={poster}
      disablePictureInPicture
      playsInline
      {...rest}
      crossOrigin="use-credentials"
    />
  );
};

Player.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]),
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  muted: PropTypes.bool,
  onHlsPlaybackStartError: PropTypes.func,
  videoEventListeners: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  paused: PropTypes.bool,
  forceDisableHd: PropTypes.bool,
};

export default Player;
