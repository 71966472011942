import { BottomScreenType, LoginModalTitleType } from "src/enums";

export enum GetTheAppSource {
  BURGER_MENU = 3,
  HEADER = 1,
  JOIN_BUTTON = 2,
}

export enum GetTheAppTarget {
  LOG_IN = "log_in",
}

export const GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE = {
  [GetTheAppSource.HEADER]: BottomScreenType.SIGN_UP_BOTTOM_SCREEN_WHITE,
  [GetTheAppSource.BURGER_MENU]:
    BottomScreenType.SIGN_UP_BOTTOM_SCREEN_FULL_HEIGHT,
  [GetTheAppSource.JOIN_BUTTON]: BottomScreenType.SIGN_UP_BOTTOM_SCREEN_WHITE,
};

export const GET_THE_APP_SOURCE_TO_LOGIN_MODAL_TITLE_TYPE = {
  [GetTheAppSource.HEADER]: LoginModalTitleType.DO_MORE_WITH_TANGO,
  [GetTheAppSource.BURGER_MENU]: LoginModalTitleType.REGULAR,
  [GetTheAppSource.JOIN_BUTTON]: LoginModalTitleType.DO_MORE_WITH_TANGO,
};
