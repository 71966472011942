import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "src/types/common";

export interface CustomizationsState {
  bgVideo: string;
  // query param that make possible to show content with higher moderation level,
  // specially made to make hardly understandable in query params
  hMarketingCampaign: Nullable<boolean>;
}

const initialState: CustomizationsState = {
  bgVideo: "",
  hMarketingCampaign: null,
};

const customizationsSlice = createSlice({
  name: "customizations",
  initialState,
  reducers: {
    setBgVideo: (state, action) => {
      state.bgVideo = action.payload.bgVideo;
    },
    setModerationLevel: (state, action) => {
      state.hMarketingCampaign = action.payload.hMarketingCampaign;
    },
  },
});

export const {
  reducer: customizations,
  actions: { setBgVideo, setModerationLevel },
} = customizationsSlice;
