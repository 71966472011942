import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeviceType } from "src/enums";
import { deviceInfoSelectors } from "state/selectors";

const isLandscape = () => window.innerHeight < window.innerWidth;
const supportedDeviceTypes = [DeviceType.IOS, DeviceType.ANDROID];

const useLandscapeOrientation = () => {
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const [isLandscapeOrientation, setLandscapeOrientation] =
    useState(isLandscape());

  useEffect(() => {
    if (!supportedDeviceTypes.includes(deviceType)) {
      return;
    }
    const onOrientationChange = () =>
      setLandscapeOrientation(
        window.orientation !== 0 && window.orientation !== 180
      );
    window.addEventListener("orientationchange", onOrientationChange);
    return () =>
      window.removeEventListener("orientationchange", onOrientationChange);
  }, []);

  return isLandscapeOrientation;
};

export default useLandscapeOrientation;
