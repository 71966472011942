import { createReducer } from "@reduxjs/toolkit";
import { actionCreators } from "state/actionCreators/intercom";
import { selectors } from "state/selectors/intercom";
import { IntercomState } from "state/types";

const initialState = {
  booted: false,
  paused: false,
};

export { actionCreators, selectors };

const reducer = createReducer<IntercomState>(initialState, (builder) => {
  builder
    .addCase(actionCreators.bootIntercom, (state) => {
      state.booted = true;
      state.paused = false;
    })
    .addCase(actionCreators.shutdownIntercom, (state) => {
      state.booted = false;
      delete state.updateTs;
    })
    .addCase(actionCreators.setIntercomTimestamp, (state, action) => {
      state.updateTs = action.payload;
    })
    .addCase(actionCreators.resumeIntercom, (state) => {
      state.paused = false;
    })
    .addCase(actionCreators.pauseIntercom, (state) => {
      state.paused = true;
    });
});

export default reducer;
