import { EventFields, EventNames, emitEvent } from "chat/imports/analytics";

export enum PremiumMessageDisplayResult {
  DISPLAYED = 1,
  NOT_DISPLAYED = 0,
}

export type EmitPremiumMessageDisplayParams = {
  accountId: string;
  chatId: string;
  messageId: string;
  recipientAccountId: string;
  result: PremiumMessageDisplayResult;
};

export function emitPremiumMessageDisplay(
  params: EmitPremiumMessageDisplayParams
) {
  const { accountId, chatId, messageId, recipientAccountId, result } = params;

  emitEvent(EventNames.PREMIUM_MESSAGE_DISPLAY, {
    [EventFields.RECIPIENT_ACCOUNT_ID]: recipientAccountId,
    [EventFields.LOGGED_IN_ACCOUNT_ID]: accountId,
    [EventFields.CHAT_ID]: chatId,
    [EventFields.PREMIUM_MESSAGE_ID]: messageId,
    [EventFields.RESULT_CODE]: result,
  });
}
