export enum SocialMediaType {
  APPLE = 5,
  EMAIL = 2,
  GOOGLE = 4,
  PHONENUMBER = 1,
}

export enum ResponseCode {
  ACCEPTED = 1,
  AT_LEAST_ONE_IDENTIFIER_REQUIRED = 3,
  FAILED = 2,
}

export interface UnlinkSocialIdentifierRequestType {
  socialMediaType: SocialMediaType;
}

export interface UnlinkSocialIdentifierResponseType {
  responseCode: ResponseCode;
}
