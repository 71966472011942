import { getProxycadorHost } from "environment";
import { mapValues } from "src/utils/miniLodash";
import fetch, { urlAndParams } from "./utils/enhancedFetch";

export const fetchMyProfileV2 = ({
  basicProfile = true,
  liveStats = false,
  followStats = false,
} = {}) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/profiles/v2/me`,
      {
        basicProfile,
        liveStats,
        followStats,
      }
    )
  ).then((resp) => resp.json());

export const fetchSingleProfile = ({
  accountId,
  basicProfile = true,
  liveStats = false,
  followStats = false,
} = {}) =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/profiles/v2/single`, {
      id: accountId,
      basicProfile,
      liveStats,
      followStats,
    })
  ).then((resp) => resp.json());

export const fetchProfilesV2 = ({
  profileIds,
  basicProfile = true,
  liveStats = false,
  followStats = false,
} = {}) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/profiles/v2/batch`,
      {
        basicProfile,
        liveStats,
        followStats,
      }
    ),
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(profileIds),
    }
  )
    .then((resp) => resp.json())
    .then((json) => mapValues(json, ({ encryptedAccountId, ...rest }) => rest));

export const saveProfile = ({
  firstName,
  lastName,
  backgroundUrl,
  pictureUrl,
  thumbnailUrl,
  birthday,
  gender,
  status,
}) =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/profile/me`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      firstName,
      lastName,
      backgroundUrl,
      pictureUrl,
      thumbnailUrl,
      birthday,
      gender,
      status,
    }),
  });

export const reportProfile = ({ accountId, reasonType, reason }) =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/public/v1/reportUser`, {
      accountId,
      reasonType,
      reason,
    }),
    {
      method: "POST",
      body: "",
    }
  ).then((resp) => resp.json());

export const fetchProfileBlockList = () =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/blockList`).then(
    (resp) => resp.json()
  );

export const addUserToProfileBlockList = ({ accountId }) =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/public/v1/blockList`, {
      accountId,
    }),
    {
      method: "POST",
      body: "",
    }
  );

export const removeUserFromProfileBlockList = ({ accountId }) =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/public/v1/blockList`, {
      accountId,
    }),
    {
      method: "DELETE",
    }
  );

export const fetchDateRegistration = () =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/profiles/v2/account_info`
  ).then((resp) => resp.json());

export const deleteUserAccountRequest = (deleteAccountRequest) =>
  fetch(`${getProxycadorHost()}/accountDeletion/deleteRequest`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(deleteAccountRequest),
  });
