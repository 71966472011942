import { useEffect, useState } from "react";

// https://usehooks.com/useMedia/
const useMedia = <T>(queries: string[], values: T[], defaultValue: T): T => {
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  const getValue = () => {
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);
  useEffect(() => {
    setValue(getValue);
    const handler = () => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [defaultValue]);

  return value;
};

export default useMedia;
