import React, { FC } from "react";
import { ReactComponent as LockedStatusIcon } from "chat/assets/locked_status.svg";
import { ReactComponent as UnlockedStatusIcon } from "chat/assets/unlocked_status.svg";
import { ContentStatus } from "chat/premiumMessage/types";
import styles from "./common.scss";

export const MessageStatus: FC<{ status: ContentStatus }> = ({ status }) => {
  const isUnlocked = status === ContentStatus.UNLOCKED;

  return (
    <div className={styles.lockStatus}>
      {isUnlocked ? <UnlockedStatusIcon /> : <LockedStatusIcon />}
    </div>
  );
};
