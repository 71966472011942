import React, { FC, ImgHTMLAttributes, memo, useRef } from "react";
import classnames from "classnames";
import { Nullable } from "src/types/common";
import useLazyImage from "ui/hooks/useLazyImage";
import styles from "./Picture.scss";

const Picture: FC<ImgHTMLAttributes<HTMLImageElement>> = ({
  src,
  onClick,
  className,
  ...rest
}) => {
  const ref = useRef<Nullable<HTMLImageElement>>(null);

  useLazyImage(ref, src);

  return (
    <img
      {...rest}
      ref={ref}
      data-src={src}
      className={classnames(styles.root, className)}
      onClick={onClick}
      alt=""
    />
  );
};

export default memo(Picture);
