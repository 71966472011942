import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";

export const remoteLogout = (body: { accountId: string; username: string }) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/remote/logout/logoutdevice`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
