import { useCallback, useEffect, useState } from "react";
import { Nullable } from "src/types/common";

type NonString<T> = T extends string ? never : T;

type BaseParams = {
  key: string;
  listenForUpdates?: boolean;
  restoreOldValue?: boolean;
  session?: boolean;
};

function useStorage<TValue>(
  params: {
    isSkipParse?: false;
  } & BaseParams
): [Nullable<NonString<TValue>>, (value: NonString<TValue>) => void];

function useStorage<TValue extends string = string>(
  params: {
    isSkipParse: true;
  } & BaseParams
): [Nullable<TValue>, (value: TValue) => void];

function useStorage<TValue>(
  params: {
    initialValue: TValue;
    isSkipParse?: false;
  } & BaseParams
): [NonString<TValue>, (value: NonString<TValue>) => void];

function useStorage<TValue extends string = string>(
  params: {
    initialValue: TValue;
    isSkipParse: true;
  } & BaseParams
): [TValue, (value: TValue) => void];

function useStorage<TValue>({
  key,
  initialValue,
  session,
  listenForUpdates,
  restoreOldValue,
  isSkipParse,
}: {
  initialValue?: TValue;
  isSkipParse?: boolean;
} & BaseParams) {
  const storage = session ? sessionStorage : localStorage;

  const [storedValue, setStoredValue] = useState<TValue>(() => {
    const storedValue = storage.getItem(key);
    if (storedValue === null) {
      return initialValue !== undefined ? initialValue : null;
    }

    if (isSkipParse) {
      return storedValue;
    }
    try {
      return JSON.parse(storedValue);
    } catch (e) {
      return initialValue !== undefined ? initialValue : null;
    }
  });

  useEffect(() => {
    if (!listenForUpdates) {
      return;
    }
    const listener = ({
      key: changedKey,
      newValue,
      oldValue,
    }: StorageEvent) => {
      const value = restoreOldValue ? oldValue : newValue;
      if (key === changedKey) {
        setStoredValue(isSkipParse || !value ? value : JSON.parse(value));
      }
    };
    window.addEventListener("storage", listener);

    return () => window.removeEventListener("storage", listener);
  }, [key, listenForUpdates, isSkipParse]);

  const storeValue = useCallback(
    (value: TValue) => {
      setStoredValue(value);
      storage.setItem(
        key,
        isSkipParse && typeof value === "string" ? value : JSON.stringify(value)
      );
    },
    [key, isSkipParse]
  );

  return [storedValue, storeValue];
}

export default useStorage;
