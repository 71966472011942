import {
  getItem as getItemUtil,
  removeItem as removeItemUtil,
  setItem as setItemUtil,
} from "@tango-analytics/storage-utils";
import { ValueType } from "src/core/analytics/storage/types";

const PREFIX = "analytics_";
const SESSION_CONFIG = { storage: "sessionStorage" };
const LOCAL_CONFIG = { storage: "localStorage" };

abstract class Storage {
  abstract getItem(key: string): void;
  abstract setItem(key: string, value: ValueType): void;
}

class InMemory extends Storage {
  getItem(key: string) {
    return getItemUtil(key);
  }

  removeItem(key: string) {
    return removeItemUtil(key);
  }

  setItem(key: string, value: ValueType) {
    const savedValue = this.getItem(key);

    return setItemUtil(key, { ...savedValue, ...value });
  }
}

export class StorageAdvanced extends Storage {
  config: { storage: string };
  prefix: string;

  constructor(prefix: string, storageConfig: { storage: string }) {
    super();
    this.prefix = prefix;
    this.config = storageConfig;
  }

  getItem(key: string) {
    return getItemUtil(`${this.prefix}${key}`, this.config);
  }

  removeItem(key: string) {
    return removeItemUtil(`${this.prefix}${key}`, this.config);
  }

  setItem(key: string, value: ValueType) {
    const savedValue = this.getItem(key);

    return setItemUtil(
      `${this.prefix}${key}`,
      { ...savedValue, ...value },
      this.config
    );
  }
}

export const BIInMemoryStorage = new InMemory();

export const BIInSessionStorage = new StorageAdvanced(PREFIX, SESSION_CONFIG);

export const BIInLocalStorage = new StorageAdvanced(PREFIX, LOCAL_CONFIG);
