import { useCallback } from "react";
import emptyFunction from "fbjs/lib/emptyFunction";

export const initialActiveVideo = {
  unmuted: "",
  playing: "",
};

export const useOnPlaybackStartError = (
  muted,
  setMuted,
  isVideoMessage = false
) =>
  useCallback(
    (video) => {
      if (!muted) {
        // due to https://developers.google.com/web/updates/2017/09/autoplay-policy-changes may fail when opening link directly to stream
        // then retrying with mute
        setMuted(isVideoMessage ? initialActiveVideo : true);

        if (video) {
          video.muted = true;
          video.play().catch(emptyFunction);
        }
      }
    },
    [muted]
  );
