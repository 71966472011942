import { CredentialResponse } from "@react-oauth/google";
import { Dispatch } from "redux";
import {
  LoginProvider,
  LoginRegistrationCompletedAnchorType,
} from "src/features/signin/imports/enums";
import {
  RootState,
  loadMyProfile,
  loginSelectors,
} from "src/features/signin/imports/state";
import { handleSuccessLogin } from "src/features/signin/state/flows/utils/handleLoginResult";
import { handlePreconditionErrorAndDismissModal } from "src/features/signin/state/flows/utils/loginHelpers";
import registerVisitorViaGoogleOneTap from "src/features/signin/state/flows/utils/registerVisitorViaGoogleOneTap";
import { loginFailed } from "src/features/signin/state/login/actionCreators";

interface GoogleOneTapParams {
  credentialResponse: CredentialResponse;
}

export const loginWithGoogleOneTap =
  ({ credentialResponse }: GoogleOneTapParams) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { credential } = credentialResponse;

    const state: RootState = getState();
    const isLoggedIn = loginSelectors.isLoggedIn(state);

    if (isLoggedIn) {
      return Promise.resolve();
    }

    const prepareGoogleCredentials = () =>
      new Promise<string>((resolve, reject) => {
        if (!credential) {
          reject(new Error("Credential should be available"));

          return;
        }

        resolve(credential);
      });

    return (
      prepareGoogleCredentials()
        .then(registerVisitorViaGoogleOneTap(state))
        .catch((error) => {
          dispatch(loginFailed(error));
          throw error;
        })
        .then((credentialsData) =>
          handleSuccessLogin({
            anchorType: LoginRegistrationCompletedAnchorType.GOOGLE_ONE_TAP,
            getState,
            dispatch,
            provider: LoginProvider.GOOGLE,
            ...credentialsData,
          })
        )
        // @ts-ignore
        .then(() => dispatch(loadMyProfile()))
        .catch(handlePreconditionErrorAndDismissModal(dispatch))
    );
  };
