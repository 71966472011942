import { StreamEvents } from "src/enums";

export default ({
  meta: { requestId, currentUserId },
  payload: { giftId, priceInCredit, withPoint, bonusPercentage },
}) => ({
  id: requestId,
  accountId: currentUserId,
  type: StreamEvents.GIFT,
  data: { giftId, priceInCredit, withPoint, bonusPercentage },
});
