import { uniq } from "src/utils/miniLodash";

export default class NftLotProcessor {
  static mapLotIds(lots = []) {
    return lots.map((lot) => lot.id);
  }

  static extractLotIdsFromList(lotList) {
    return NftLotProcessor.mapLotIds(lotList.lots);
  }

  static createLotMapFromArray(lots = []) {
    return lots.reduce((map, lot) => ({ ...map, [lot.id]: lot }), {});
  }

  static addLotListToMap({ map, auctionId, tag, lotList }) {
    return {
      ...map,
      [auctionId]: {
        ...(map[auctionId] || {}),
        [tag]: NftLotProcessor.extractLotIdsFromList(lotList),
      },
    };
  }

  static normalizeLotListByTagResponse({ auctionId, lotListsByTag }) {
    return lotListsByTag.reduce(
      (result, { tag, lotList }) => ({
        lotListMap: NftLotProcessor.addLotListToMap({
          map: result.lotListMap,
          auctionId,
          tag,
          lotList,
        }),
        lotMap: Object.assign(
          result.lotMap,
          NftLotProcessor.createLotMapFromArray(lotList.lots)
        ),
      }),
      { lotListMap: {}, lotMap: {} }
    );
  }

  static mergeLotListMaps(map, newMap) {
    return Object.keys(newMap).reduce(
      (resultMap, auctionId) => {
        resultMap[auctionId] = {
          ...newMap[auctionId],
          ...Object.keys(map[auctionId] || {}).reduce(
            (acc, tag) => ({
              ...acc,
              [tag]: uniq(
                map[auctionId][tag]
                  .concat(newMap[auctionId][tag])
                  .filter(Boolean)
              ),
            }),
            {}
          ),
        };

        return resultMap;
      },
      { ...map }
    );
  }

  static updateLotStatus(map, lotId, status) {
    return { ...map, ...(lotId && { [lotId]: status }) };
  }

  static updateLot(map, lot) {
    if (!lot?.id) return map;

    return { ...map, [lot.id]: lot };
  }

  static updateLots(map, lots) {
    return lots.reduce(
      (newMap, lot) => {
        newMap[lot.id] = lot;

        return newMap;
      },
      { ...map }
    );
  }
}
