/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.SubscriptionApi || ($protobuf.roots.SubscriptionApi = {});

export const CancelSubscriptionResponse = $root.CancelSubscriptionResponse = (() => {

    function CancelSubscriptionResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CancelSubscriptionResponse.prototype.code = 1;

    CancelSubscriptionResponse.create = function create(properties) {
        return new CancelSubscriptionResponse(properties);
    };

    CancelSubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        return writer;
    };

    CancelSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CancelSubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CancelSubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    CancelSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CancelSubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CancelSubscriptionResponse)
            return object;
        let message = new $root.CancelSubscriptionResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        return message;
    };

    CancelSubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    CancelSubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CancelSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CancelSubscriptionResponse";
    };

    return CancelSubscriptionResponse;
})();

export const CapabilitiesResponse = $root.CapabilitiesResponse = (() => {

    function CapabilitiesResponse(properties) {
        this.capabilities = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CapabilitiesResponse.prototype.code = 1;
    CapabilitiesResponse.prototype.capabilities = $util.emptyArray;

    CapabilitiesResponse.create = function create(properties) {
        return new CapabilitiesResponse(properties);
    };

    CapabilitiesResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.capabilities != null && message.capabilities.length)
            for (let i = 0; i < message.capabilities.length; ++i)
                $root.Capabilities.encode(message.capabilities[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    CapabilitiesResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CapabilitiesResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CapabilitiesResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.capabilities && message.capabilities.length))
                        message.capabilities = [];
                    message.capabilities.push($root.Capabilities.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    CapabilitiesResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CapabilitiesResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CapabilitiesResponse)
            return object;
        let message = new $root.CapabilitiesResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        if (object.capabilities) {
            if (!Array.isArray(object.capabilities))
                throw TypeError(".CapabilitiesResponse.capabilities: array expected");
            message.capabilities = [];
            for (let i = 0; i < object.capabilities.length; ++i) {
                if (typeof object.capabilities[i] !== "object")
                    throw TypeError(".CapabilitiesResponse.capabilities: object expected");
                message.capabilities[i] = $root.Capabilities.fromObject(object.capabilities[i]);
            }
        }
        return message;
    };

    CapabilitiesResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.capabilities = [];
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.capabilities && message.capabilities.length) {
            object.capabilities = [];
            for (let j = 0; j < message.capabilities.length; ++j)
                object.capabilities[j] = $root.Capabilities.toObject(message.capabilities[j], options);
        }
        return object;
    };

    CapabilitiesResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CapabilitiesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CapabilitiesResponse";
    };

    return CapabilitiesResponse;
})();

export const CreateCheckoutSubscriptionRequest = $root.CreateCheckoutSubscriptionRequest = (() => {

    function CreateCheckoutSubscriptionRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CreateCheckoutSubscriptionRequest.prototype.externalOfferId = "";
    CreateCheckoutSubscriptionRequest.prototype.saveCard = false;
    CreateCheckoutSubscriptionRequest.prototype.failureUrl = "";
    CreateCheckoutSubscriptionRequest.prototype.cvv = "";
    CreateCheckoutSubscriptionRequest.prototype.type = "";
    CreateCheckoutSubscriptionRequest.prototype.currency = "";
    CreateCheckoutSubscriptionRequest.prototype.successUrl = "";
    CreateCheckoutSubscriptionRequest.prototype.token = "";
    CreateCheckoutSubscriptionRequest.prototype.details = null;

    CreateCheckoutSubscriptionRequest.create = function create(properties) {
        return new CreateCheckoutSubscriptionRequest(properties);
    };

    CreateCheckoutSubscriptionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.externalOfferId);
        if (message.saveCard != null && Object.hasOwnProperty.call(message, "saveCard"))
            writer.uint32(16).bool(message.saveCard);
        if (message.failureUrl != null && Object.hasOwnProperty.call(message, "failureUrl"))
            writer.uint32(26).string(message.failureUrl);
        if (message.cvv != null && Object.hasOwnProperty.call(message, "cvv"))
            writer.uint32(34).string(message.cvv);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(42).string(message.type);
        if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
            writer.uint32(50).string(message.currency);
        if (message.successUrl != null && Object.hasOwnProperty.call(message, "successUrl"))
            writer.uint32(58).string(message.successUrl);
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(66).string(message.token);
        $root.SubscriptionDetails.encode(message.details, writer.uint32(74).fork()).ldelim();
        return writer;
    };

    CreateCheckoutSubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CreateCheckoutSubscriptionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateCheckoutSubscriptionRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.externalOfferId = reader.string();
                    break;
                }
            case 2: {
                    message.saveCard = reader.bool();
                    break;
                }
            case 3: {
                    message.failureUrl = reader.string();
                    break;
                }
            case 4: {
                    message.cvv = reader.string();
                    break;
                }
            case 5: {
                    message.type = reader.string();
                    break;
                }
            case 6: {
                    message.currency = reader.string();
                    break;
                }
            case 7: {
                    message.successUrl = reader.string();
                    break;
                }
            case 8: {
                    message.token = reader.string();
                    break;
                }
            case 9: {
                    message.details = $root.SubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("externalOfferId"))
            throw $util.ProtocolError("missing required 'externalOfferId'", { instance: message });
        if (!message.hasOwnProperty("details"))
            throw $util.ProtocolError("missing required 'details'", { instance: message });
        return message;
    };

    CreateCheckoutSubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CreateCheckoutSubscriptionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateCheckoutSubscriptionRequest)
            return object;
        let message = new $root.CreateCheckoutSubscriptionRequest();
        if (object.externalOfferId != null)
            message.externalOfferId = String(object.externalOfferId);
        if (object.saveCard != null)
            message.saveCard = Boolean(object.saveCard);
        if (object.failureUrl != null)
            message.failureUrl = String(object.failureUrl);
        if (object.cvv != null)
            message.cvv = String(object.cvv);
        if (object.type != null)
            message.type = String(object.type);
        if (object.currency != null)
            message.currency = String(object.currency);
        if (object.successUrl != null)
            message.successUrl = String(object.successUrl);
        if (object.token != null)
            message.token = String(object.token);
        if (object.details != null) {
            if (typeof object.details !== "object")
                throw TypeError(".CreateCheckoutSubscriptionRequest.details: object expected");
            message.details = $root.SubscriptionDetails.fromObject(object.details);
        }
        return message;
    };

    CreateCheckoutSubscriptionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.externalOfferId = "";
            object.saveCard = false;
            object.failureUrl = "";
            object.cvv = "";
            object.type = "";
            object.currency = "";
            object.successUrl = "";
            object.token = "";
            object.details = null;
        }
        if (message.externalOfferId != null && message.hasOwnProperty("externalOfferId"))
            object.externalOfferId = message.externalOfferId;
        if (message.saveCard != null && message.hasOwnProperty("saveCard"))
            object.saveCard = message.saveCard;
        if (message.failureUrl != null && message.hasOwnProperty("failureUrl"))
            object.failureUrl = message.failureUrl;
        if (message.cvv != null && message.hasOwnProperty("cvv"))
            object.cvv = message.cvv;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.currency != null && message.hasOwnProperty("currency"))
            object.currency = message.currency;
        if (message.successUrl != null && message.hasOwnProperty("successUrl"))
            object.successUrl = message.successUrl;
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.details != null && message.hasOwnProperty("details"))
            object.details = $root.SubscriptionDetails.toObject(message.details, options);
        return object;
    };

    CreateCheckoutSubscriptionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CreateCheckoutSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateCheckoutSubscriptionRequest";
    };

    return CreateCheckoutSubscriptionRequest;
})();

export const CreateCheckoutSubscriptionResponse = $root.CreateCheckoutSubscriptionResponse = (() => {

    function CreateCheckoutSubscriptionResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CreateCheckoutSubscriptionResponse.prototype.code = 0;
    CreateCheckoutSubscriptionResponse.prototype.transactionId = "";
    CreateCheckoutSubscriptionResponse.prototype.redirectUrl = "";
    CreateCheckoutSubscriptionResponse.prototype.subscription = null;

    CreateCheckoutSubscriptionResponse.create = function create(properties) {
        return new CreateCheckoutSubscriptionResponse(properties);
    };

    CreateCheckoutSubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.code);
        if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
            writer.uint32(18).string(message.transactionId);
        if (message.redirectUrl != null && Object.hasOwnProperty.call(message, "redirectUrl"))
            writer.uint32(26).string(message.redirectUrl);
        if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
            $root.Subscription.encode(message.subscription, writer.uint32(34).fork()).ldelim();
        return writer;
    };

    CreateCheckoutSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CreateCheckoutSubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateCheckoutSubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.transactionId = reader.string();
                    break;
                }
            case 3: {
                    message.redirectUrl = reader.string();
                    break;
                }
            case 4: {
                    message.subscription = $root.Subscription.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    CreateCheckoutSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CreateCheckoutSubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateCheckoutSubscriptionResponse)
            return object;
        let message = new $root.CreateCheckoutSubscriptionResponse();
        if (object.code != null)
            message.code = object.code | 0;
        if (object.transactionId != null)
            message.transactionId = String(object.transactionId);
        if (object.redirectUrl != null)
            message.redirectUrl = String(object.redirectUrl);
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".CreateCheckoutSubscriptionResponse.subscription: object expected");
            message.subscription = $root.Subscription.fromObject(object.subscription);
        }
        return message;
    };

    CreateCheckoutSubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = 0;
            object.transactionId = "";
            object.redirectUrl = "";
            object.subscription = null;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
            object.transactionId = message.transactionId;
        if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
            object.redirectUrl = message.redirectUrl;
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.Subscription.toObject(message.subscription, options);
        return object;
    };

    CreateCheckoutSubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CreateCheckoutSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateCheckoutSubscriptionResponse";
    };

    return CreateCheckoutSubscriptionResponse;
})();

export const CreateSubscriptionRequest = $root.CreateSubscriptionRequest = (() => {

    function CreateSubscriptionRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CreateSubscriptionRequest.prototype.externalOfferId = "";
    CreateSubscriptionRequest.prototype.details = null;
    CreateSubscriptionRequest.prototype.triggerId = "";

    CreateSubscriptionRequest.create = function create(properties) {
        return new CreateSubscriptionRequest(properties);
    };

    CreateSubscriptionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.externalOfferId);
        $root.SubscriptionDetails.encode(message.details, writer.uint32(18).fork()).ldelim();
        if (message.triggerId != null && Object.hasOwnProperty.call(message, "triggerId"))
            writer.uint32(26).string(message.triggerId);
        return writer;
    };

    CreateSubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CreateSubscriptionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateSubscriptionRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.externalOfferId = reader.string();
                    break;
                }
            case 2: {
                    message.details = $root.SubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.triggerId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("externalOfferId"))
            throw $util.ProtocolError("missing required 'externalOfferId'", { instance: message });
        if (!message.hasOwnProperty("details"))
            throw $util.ProtocolError("missing required 'details'", { instance: message });
        return message;
    };

    CreateSubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CreateSubscriptionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateSubscriptionRequest)
            return object;
        let message = new $root.CreateSubscriptionRequest();
        if (object.externalOfferId != null)
            message.externalOfferId = String(object.externalOfferId);
        if (object.details != null) {
            if (typeof object.details !== "object")
                throw TypeError(".CreateSubscriptionRequest.details: object expected");
            message.details = $root.SubscriptionDetails.fromObject(object.details);
        }
        if (object.triggerId != null)
            message.triggerId = String(object.triggerId);
        return message;
    };

    CreateSubscriptionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.externalOfferId = "";
            object.details = null;
            object.triggerId = "";
        }
        if (message.externalOfferId != null && message.hasOwnProperty("externalOfferId"))
            object.externalOfferId = message.externalOfferId;
        if (message.details != null && message.hasOwnProperty("details"))
            object.details = $root.SubscriptionDetails.toObject(message.details, options);
        if (message.triggerId != null && message.hasOwnProperty("triggerId"))
            object.triggerId = message.triggerId;
        return object;
    };

    CreateSubscriptionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CreateSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateSubscriptionRequest";
    };

    return CreateSubscriptionRequest;
})();

export const CreateSubscriptionResponse = $root.CreateSubscriptionResponse = (() => {

    function CreateSubscriptionResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CreateSubscriptionResponse.prototype.code = 1;
    CreateSubscriptionResponse.prototype.subscription = null;

    CreateSubscriptionResponse.create = function create(properties) {
        return new CreateSubscriptionResponse(properties);
    };

    CreateSubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
            $root.Subscription.encode(message.subscription, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    CreateSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CreateSubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateSubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.subscription = $root.Subscription.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    CreateSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CreateSubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateSubscriptionResponse)
            return object;
        let message = new $root.CreateSubscriptionResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".CreateSubscriptionResponse.subscription: object expected");
            message.subscription = $root.Subscription.fromObject(object.subscription);
        }
        return message;
    };

    CreateSubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.subscription = null;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.Subscription.toObject(message.subscription, options);
        return object;
    };

    CreateSubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CreateSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateSubscriptionResponse";
    };

    return CreateSubscriptionResponse;
})();

export const DeleteSubscriptionResponse = $root.DeleteSubscriptionResponse = (() => {

    function DeleteSubscriptionResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    DeleteSubscriptionResponse.prototype.code = 1;

    DeleteSubscriptionResponse.create = function create(properties) {
        return new DeleteSubscriptionResponse(properties);
    };

    DeleteSubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        return writer;
    };

    DeleteSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    DeleteSubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeleteSubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    DeleteSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    DeleteSubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.DeleteSubscriptionResponse)
            return object;
        let message = new $root.DeleteSubscriptionResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        return message;
    };

    DeleteSubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    DeleteSubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    DeleteSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeleteSubscriptionResponse";
    };

    return DeleteSubscriptionResponse;
})();

export const ListSubscriptionRequest = $root.ListSubscriptionRequest = (() => {

    function ListSubscriptionRequest(properties) {
        this.subscriberIds = [];
        this.details = [];
        this.statuses = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ListSubscriptionRequest.prototype.status = 1;
    ListSubscriptionRequest.prototype.subscriberIds = $util.emptyArray;
    ListSubscriptionRequest.prototype.details = $util.emptyArray;
    ListSubscriptionRequest.prototype.pageRequest = null;
    ListSubscriptionRequest.prototype.statuses = $util.emptyArray;

    ListSubscriptionRequest.create = function create(properties) {
        return new ListSubscriptionRequest(properties);
    };

    ListSubscriptionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(8).int32(message.status);
        if (message.subscriberIds != null && message.subscriberIds.length)
            for (let i = 0; i < message.subscriberIds.length; ++i)
                writer.uint32(18).string(message.subscriberIds[i]);
        if (message.details != null && message.details.length)
            for (let i = 0; i < message.details.length; ++i)
                $root.SubscriptionDetails.encode(message.details[i], writer.uint32(26).fork()).ldelim();
        if (message.pageRequest != null && Object.hasOwnProperty.call(message, "pageRequest"))
            $root.PageRequest.encode(message.pageRequest, writer.uint32(34).fork()).ldelim();
        if (message.statuses != null && message.statuses.length)
            for (let i = 0; i < message.statuses.length; ++i)
                writer.uint32(40).int32(message.statuses[i]);
        return writer;
    };

    ListSubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ListSubscriptionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ListSubscriptionRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.status = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.subscriberIds && message.subscriberIds.length))
                        message.subscriberIds = [];
                    message.subscriberIds.push(reader.string());
                    break;
                }
            case 3: {
                    if (!(message.details && message.details.length))
                        message.details = [];
                    message.details.push($root.SubscriptionDetails.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.pageRequest = $root.PageRequest.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    if (!(message.statuses && message.statuses.length))
                        message.statuses = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.statuses.push(reader.int32());
                    } else
                        message.statuses.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ListSubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ListSubscriptionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ListSubscriptionRequest)
            return object;
        let message = new $root.ListSubscriptionRequest();
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "ACTIVE":
        case 1:
            message.status = 1;
            break;
        case "CANCELLED":
        case 2:
            message.status = 2;
            break;
        case "PENDING":
        case 3:
            message.status = 3;
            break;
        case "EXPIRED":
        case 4:
            message.status = 4;
            break;
        case "RENEWING":
        case 5:
            message.status = 5;
            break;
        case "HOLD":
        case 6:
            message.status = 6;
            break;
        case "PREAPPROVED":
        case 7:
            message.status = 7;
            break;
        case "FREE_TRIAL":
        case 8:
            message.status = 8;
            break;
        case "GRACE_PERIOD":
        case 9:
            message.status = 9;
            break;
        }
        if (object.subscriberIds) {
            if (!Array.isArray(object.subscriberIds))
                throw TypeError(".ListSubscriptionRequest.subscriberIds: array expected");
            message.subscriberIds = [];
            for (let i = 0; i < object.subscriberIds.length; ++i)
                message.subscriberIds[i] = String(object.subscriberIds[i]);
        }
        if (object.details) {
            if (!Array.isArray(object.details))
                throw TypeError(".ListSubscriptionRequest.details: array expected");
            message.details = [];
            for (let i = 0; i < object.details.length; ++i) {
                if (typeof object.details[i] !== "object")
                    throw TypeError(".ListSubscriptionRequest.details: object expected");
                message.details[i] = $root.SubscriptionDetails.fromObject(object.details[i]);
            }
        }
        if (object.pageRequest != null) {
            if (typeof object.pageRequest !== "object")
                throw TypeError(".ListSubscriptionRequest.pageRequest: object expected");
            message.pageRequest = $root.PageRequest.fromObject(object.pageRequest);
        }
        if (object.statuses) {
            if (!Array.isArray(object.statuses))
                throw TypeError(".ListSubscriptionRequest.statuses: array expected");
            message.statuses = [];
            for (let i = 0; i < object.statuses.length; ++i)
                switch (object.statuses[i]) {
                default:
                    if (typeof object.statuses[i] === "number") {
                        message.statuses[i] = object.statuses[i];
                        break;
                    }
                case "ACTIVE":
                case 1:
                    message.statuses[i] = 1;
                    break;
                case "CANCELLED":
                case 2:
                    message.statuses[i] = 2;
                    break;
                case "PENDING":
                case 3:
                    message.statuses[i] = 3;
                    break;
                case "EXPIRED":
                case 4:
                    message.statuses[i] = 4;
                    break;
                case "RENEWING":
                case 5:
                    message.statuses[i] = 5;
                    break;
                case "HOLD":
                case 6:
                    message.statuses[i] = 6;
                    break;
                case "PREAPPROVED":
                case 7:
                    message.statuses[i] = 7;
                    break;
                case "FREE_TRIAL":
                case 8:
                    message.statuses[i] = 8;
                    break;
                case "GRACE_PERIOD":
                case 9:
                    message.statuses[i] = 9;
                    break;
                }
        }
        return message;
    };

    ListSubscriptionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.subscriberIds = [];
            object.details = [];
            object.statuses = [];
        }
        if (options.defaults) {
            object.status = options.enums === String ? "ACTIVE" : 1;
            object.pageRequest = null;
        }
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.SubscriptionStatus[message.status] === undefined ? message.status : $root.SubscriptionStatus[message.status] : message.status;
        if (message.subscriberIds && message.subscriberIds.length) {
            object.subscriberIds = [];
            for (let j = 0; j < message.subscriberIds.length; ++j)
                object.subscriberIds[j] = message.subscriberIds[j];
        }
        if (message.details && message.details.length) {
            object.details = [];
            for (let j = 0; j < message.details.length; ++j)
                object.details[j] = $root.SubscriptionDetails.toObject(message.details[j], options);
        }
        if (message.pageRequest != null && message.hasOwnProperty("pageRequest"))
            object.pageRequest = $root.PageRequest.toObject(message.pageRequest, options);
        if (message.statuses && message.statuses.length) {
            object.statuses = [];
            for (let j = 0; j < message.statuses.length; ++j)
                object.statuses[j] = options.enums === String ? $root.SubscriptionStatus[message.statuses[j]] === undefined ? message.statuses[j] : $root.SubscriptionStatus[message.statuses[j]] : message.statuses[j];
        }
        return object;
    };

    ListSubscriptionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ListSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ListSubscriptionRequest";
    };

    return ListSubscriptionRequest;
})();

export const Page = $root.Page = (() => {

    function Page(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Page.prototype.limit = 0;
    Page.prototype.offset = 0;

    Page.create = function create(properties) {
        return new Page(properties);
    };

    Page.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
            writer.uint32(13).sfixed32(message.limit);
        if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
            writer.uint32(21).sfixed32(message.offset);
        return writer;
    };

    Page.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Page.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Page();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.limit = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.offset = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Page.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Page.fromObject = function fromObject(object) {
        if (object instanceof $root.Page)
            return object;
        let message = new $root.Page();
        if (object.limit != null)
            message.limit = object.limit | 0;
        if (object.offset != null)
            message.offset = object.offset | 0;
        return message;
    };

    Page.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.limit = 0;
            object.offset = 0;
        }
        if (message.limit != null && message.hasOwnProperty("limit"))
            object.limit = message.limit;
        if (message.offset != null && message.hasOwnProperty("offset"))
            object.offset = message.offset;
        return object;
    };

    Page.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Page.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Page";
    };

    return Page;
})();

export const PageRequest = $root.PageRequest = (() => {

    function PageRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PageRequest.prototype.page = null;
    PageRequest.prototype.sortDirection = 1;
    PageRequest.prototype.sortBy = "";

    PageRequest.create = function create(properties) {
        return new PageRequest(properties);
    };

    PageRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            $root.Page.encode(message.page, writer.uint32(10).fork()).ldelim();
        if (message.sortDirection != null && Object.hasOwnProperty.call(message, "sortDirection"))
            writer.uint32(16).int32(message.sortDirection);
        if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
            writer.uint32(26).string(message.sortBy);
        return writer;
    };

    PageRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PageRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PageRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.page = $root.Page.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.sortDirection = reader.int32();
                    break;
                }
            case 3: {
                    message.sortBy = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PageRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PageRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.PageRequest)
            return object;
        let message = new $root.PageRequest();
        if (object.page != null) {
            if (typeof object.page !== "object")
                throw TypeError(".PageRequest.page: object expected");
            message.page = $root.Page.fromObject(object.page);
        }
        switch (object.sortDirection) {
        default:
            if (typeof object.sortDirection === "number") {
                message.sortDirection = object.sortDirection;
                break;
            }
            break;
        case "ASC":
        case 1:
            message.sortDirection = 1;
            break;
        case "DESC":
        case 2:
            message.sortDirection = 2;
            break;
        }
        if (object.sortBy != null)
            message.sortBy = String(object.sortBy);
        return message;
    };

    PageRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.page = null;
            object.sortDirection = options.enums === String ? "ASC" : 1;
            object.sortBy = "";
        }
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = $root.Page.toObject(message.page, options);
        if (message.sortDirection != null && message.hasOwnProperty("sortDirection"))
            object.sortDirection = options.enums === String ? $root.SortDirection[message.sortDirection] === undefined ? message.sortDirection : $root.SortDirection[message.sortDirection] : message.sortDirection;
        if (message.sortBy != null && message.hasOwnProperty("sortBy"))
            object.sortBy = message.sortBy;
        return object;
    };

    PageRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PageRequest";
    };

    return PageRequest;
})();

export const PaginatedSubscriptionResponse = $root.PaginatedSubscriptionResponse = (() => {

    function PaginatedSubscriptionResponse(properties) {
        this.subscriptions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PaginatedSubscriptionResponse.prototype.code = 1;
    PaginatedSubscriptionResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PaginatedSubscriptionResponse.prototype.page = null;
    PaginatedSubscriptionResponse.prototype.subscriptions = $util.emptyArray;
    PaginatedSubscriptionResponse.prototype.estimatedIncomePoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    PaginatedSubscriptionResponse.create = function create(properties) {
        return new PaginatedSubscriptionResponse(properties);
    };

    PaginatedSubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.total != null && Object.hasOwnProperty.call(message, "total"))
            writer.uint32(17).sfixed64(message.total);
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            $root.Page.encode(message.page, writer.uint32(26).fork()).ldelim();
        if (message.subscriptions != null && message.subscriptions.length)
            for (let i = 0; i < message.subscriptions.length; ++i)
                $root.Subscription.encode(message.subscriptions[i], writer.uint32(34).fork()).ldelim();
        if (message.estimatedIncomePoints != null && Object.hasOwnProperty.call(message, "estimatedIncomePoints"))
            writer.uint32(41).sfixed64(message.estimatedIncomePoints);
        return writer;
    };

    PaginatedSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PaginatedSubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PaginatedSubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.total = reader.sfixed64();
                    break;
                }
            case 3: {
                    message.page = $root.Page.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    if (!(message.subscriptions && message.subscriptions.length))
                        message.subscriptions = [];
                    message.subscriptions.push($root.Subscription.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    message.estimatedIncomePoints = reader.sfixed64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    PaginatedSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PaginatedSubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.PaginatedSubscriptionResponse)
            return object;
        let message = new $root.PaginatedSubscriptionResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        if (object.total != null)
            if ($util.Long)
                (message.total = $util.Long.fromValue(object.total)).unsigned = false;
            else if (typeof object.total === "string")
                message.total = parseInt(object.total, 10);
            else if (typeof object.total === "number")
                message.total = object.total;
            else if (typeof object.total === "object")
                message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
        if (object.page != null) {
            if (typeof object.page !== "object")
                throw TypeError(".PaginatedSubscriptionResponse.page: object expected");
            message.page = $root.Page.fromObject(object.page);
        }
        if (object.subscriptions) {
            if (!Array.isArray(object.subscriptions))
                throw TypeError(".PaginatedSubscriptionResponse.subscriptions: array expected");
            message.subscriptions = [];
            for (let i = 0; i < object.subscriptions.length; ++i) {
                if (typeof object.subscriptions[i] !== "object")
                    throw TypeError(".PaginatedSubscriptionResponse.subscriptions: object expected");
                message.subscriptions[i] = $root.Subscription.fromObject(object.subscriptions[i]);
            }
        }
        if (object.estimatedIncomePoints != null)
            if ($util.Long)
                (message.estimatedIncomePoints = $util.Long.fromValue(object.estimatedIncomePoints)).unsigned = false;
            else if (typeof object.estimatedIncomePoints === "string")
                message.estimatedIncomePoints = parseInt(object.estimatedIncomePoints, 10);
            else if (typeof object.estimatedIncomePoints === "number")
                message.estimatedIncomePoints = object.estimatedIncomePoints;
            else if (typeof object.estimatedIncomePoints === "object")
                message.estimatedIncomePoints = new $util.LongBits(object.estimatedIncomePoints.low >>> 0, object.estimatedIncomePoints.high >>> 0).toNumber();
        return message;
    };

    PaginatedSubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.subscriptions = [];
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.total = options.longs === String ? "0" : 0;
            object.page = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.estimatedIncomePoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.estimatedIncomePoints = options.longs === String ? "0" : 0;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.total != null && message.hasOwnProperty("total"))
            if (typeof message.total === "number")
                object.total = options.longs === String ? String(message.total) : message.total;
            else
                object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = $root.Page.toObject(message.page, options);
        if (message.subscriptions && message.subscriptions.length) {
            object.subscriptions = [];
            for (let j = 0; j < message.subscriptions.length; ++j)
                object.subscriptions[j] = $root.Subscription.toObject(message.subscriptions[j], options);
        }
        if (message.estimatedIncomePoints != null && message.hasOwnProperty("estimatedIncomePoints"))
            if (typeof message.estimatedIncomePoints === "number")
                object.estimatedIncomePoints = options.longs === String ? String(message.estimatedIncomePoints) : message.estimatedIncomePoints;
            else
                object.estimatedIncomePoints = options.longs === String ? $util.Long.prototype.toString.call(message.estimatedIncomePoints) : options.longs === Number ? new $util.LongBits(message.estimatedIncomePoints.low >>> 0, message.estimatedIncomePoints.high >>> 0).toNumber() : message.estimatedIncomePoints;
        return object;
    };

    PaginatedSubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PaginatedSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PaginatedSubscriptionResponse";
    };

    return PaginatedSubscriptionResponse;
})();

export const RenewSubscriptionResponse = $root.RenewSubscriptionResponse = (() => {

    function RenewSubscriptionResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    RenewSubscriptionResponse.prototype.code = 1;

    RenewSubscriptionResponse.create = function create(properties) {
        return new RenewSubscriptionResponse(properties);
    };

    RenewSubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        return writer;
    };

    RenewSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    RenewSubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RenewSubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    RenewSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    RenewSubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.RenewSubscriptionResponse)
            return object;
        let message = new $root.RenewSubscriptionResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        return message;
    };

    RenewSubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    RenewSubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    RenewSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RenewSubscriptionResponse";
    };

    return RenewSubscriptionResponse;
})();

export const StreamerEstimatedIncomeResponse = $root.StreamerEstimatedIncomeResponse = (() => {

    function StreamerEstimatedIncomeResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamerEstimatedIncomeResponse.prototype.code = 1;
    StreamerEstimatedIncomeResponse.prototype.points = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    StreamerEstimatedIncomeResponse.create = function create(properties) {
        return new StreamerEstimatedIncomeResponse(properties);
    };

    StreamerEstimatedIncomeResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.points != null && Object.hasOwnProperty.call(message, "points"))
            writer.uint32(17).sfixed64(message.points);
        return writer;
    };

    StreamerEstimatedIncomeResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamerEstimatedIncomeResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamerEstimatedIncomeResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.points = reader.sfixed64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    StreamerEstimatedIncomeResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamerEstimatedIncomeResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamerEstimatedIncomeResponse)
            return object;
        let message = new $root.StreamerEstimatedIncomeResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        if (object.points != null)
            if ($util.Long)
                (message.points = $util.Long.fromValue(object.points)).unsigned = false;
            else if (typeof object.points === "string")
                message.points = parseInt(object.points, 10);
            else if (typeof object.points === "number")
                message.points = object.points;
            else if (typeof object.points === "object")
                message.points = new $util.LongBits(object.points.low >>> 0, object.points.high >>> 0).toNumber();
        return message;
    };

    StreamerEstimatedIncomeResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.points = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.points = options.longs === String ? "0" : 0;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.points != null && message.hasOwnProperty("points"))
            if (typeof message.points === "number")
                object.points = options.longs === String ? String(message.points) : message.points;
            else
                object.points = options.longs === String ? $util.Long.prototype.toString.call(message.points) : options.longs === Number ? new $util.LongBits(message.points.low >>> 0, message.points.high >>> 0).toNumber() : message.points;
        return object;
    };

    StreamerEstimatedIncomeResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamerEstimatedIncomeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamerEstimatedIncomeResponse";
    };

    return StreamerEstimatedIncomeResponse;
})();

export const SubscriptionPlan = $root.SubscriptionPlan = (() => {

    function SubscriptionPlan(properties) {
        this.perks = [];
        this.prices = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionPlan.prototype.id = "";
    SubscriptionPlan.prototype.name = "";
    SubscriptionPlan.prototype.level = "";
    SubscriptionPlan.prototype.enabled = false;
    SubscriptionPlan.prototype.perks = $util.emptyArray;
    SubscriptionPlan.prototype.prices = $util.emptyArray;

    SubscriptionPlan.create = function create(properties) {
        return new SubscriptionPlan(properties);
    };

    SubscriptionPlan.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(18).string(message.name);
        if (message.level != null && Object.hasOwnProperty.call(message, "level"))
            writer.uint32(26).string(message.level);
        if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
            writer.uint32(32).bool(message.enabled);
        if (message.perks != null && message.perks.length)
            for (let i = 0; i < message.perks.length; ++i)
                $root.SubscriptionPlanPerk.encode(message.perks[i], writer.uint32(42).fork()).ldelim();
        if (message.prices != null && message.prices.length)
            for (let i = 0; i < message.prices.length; ++i)
                $root.SubscriptionPlanPrice.encode(message.prices[i], writer.uint32(50).fork()).ldelim();
        return writer;
    };

    SubscriptionPlan.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionPlan.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionPlan();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.level = reader.string();
                    break;
                }
            case 4: {
                    message.enabled = reader.bool();
                    break;
                }
            case 5: {
                    if (!(message.perks && message.perks.length))
                        message.perks = [];
                    message.perks.push($root.SubscriptionPlanPerk.decode(reader, reader.uint32()));
                    break;
                }
            case 6: {
                    if (!(message.prices && message.prices.length))
                        message.prices = [];
                    message.prices.push($root.SubscriptionPlanPrice.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        return message;
    };

    SubscriptionPlan.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionPlan.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionPlan)
            return object;
        let message = new $root.SubscriptionPlan();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        if (object.level != null)
            message.level = String(object.level);
        if (object.enabled != null)
            message.enabled = Boolean(object.enabled);
        if (object.perks) {
            if (!Array.isArray(object.perks))
                throw TypeError(".SubscriptionPlan.perks: array expected");
            message.perks = [];
            for (let i = 0; i < object.perks.length; ++i) {
                if (typeof object.perks[i] !== "object")
                    throw TypeError(".SubscriptionPlan.perks: object expected");
                message.perks[i] = $root.SubscriptionPlanPerk.fromObject(object.perks[i]);
            }
        }
        if (object.prices) {
            if (!Array.isArray(object.prices))
                throw TypeError(".SubscriptionPlan.prices: array expected");
            message.prices = [];
            for (let i = 0; i < object.prices.length; ++i) {
                if (typeof object.prices[i] !== "object")
                    throw TypeError(".SubscriptionPlan.prices: object expected");
                message.prices[i] = $root.SubscriptionPlanPrice.fromObject(object.prices[i]);
            }
        }
        return message;
    };

    SubscriptionPlan.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.perks = [];
            object.prices = [];
        }
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.level = "";
            object.enabled = false;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        if (message.enabled != null && message.hasOwnProperty("enabled"))
            object.enabled = message.enabled;
        if (message.perks && message.perks.length) {
            object.perks = [];
            for (let j = 0; j < message.perks.length; ++j)
                object.perks[j] = $root.SubscriptionPlanPerk.toObject(message.perks[j], options);
        }
        if (message.prices && message.prices.length) {
            object.prices = [];
            for (let j = 0; j < message.prices.length; ++j)
                object.prices[j] = $root.SubscriptionPlanPrice.toObject(message.prices[j], options);
        }
        return object;
    };

    SubscriptionPlan.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionPlan.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionPlan";
    };

    return SubscriptionPlan;
})();

export const SubscriptionPlanPerk = $root.SubscriptionPlanPerk = (() => {

    function SubscriptionPlanPerk(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionPlanPerk.prototype.perkKey = "";
    SubscriptionPlanPerk.prototype.perkDefaultValue = "";

    SubscriptionPlanPerk.create = function create(properties) {
        return new SubscriptionPlanPerk(properties);
    };

    SubscriptionPlanPerk.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.perkKey != null && Object.hasOwnProperty.call(message, "perkKey"))
            writer.uint32(10).string(message.perkKey);
        if (message.perkDefaultValue != null && Object.hasOwnProperty.call(message, "perkDefaultValue"))
            writer.uint32(18).string(message.perkDefaultValue);
        return writer;
    };

    SubscriptionPlanPerk.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionPlanPerk.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionPlanPerk();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.perkKey = reader.string();
                    break;
                }
            case 2: {
                    message.perkDefaultValue = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionPlanPerk.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionPlanPerk.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionPlanPerk)
            return object;
        let message = new $root.SubscriptionPlanPerk();
        if (object.perkKey != null)
            message.perkKey = String(object.perkKey);
        if (object.perkDefaultValue != null)
            message.perkDefaultValue = String(object.perkDefaultValue);
        return message;
    };

    SubscriptionPlanPerk.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.perkKey = "";
            object.perkDefaultValue = "";
        }
        if (message.perkKey != null && message.hasOwnProperty("perkKey"))
            object.perkKey = message.perkKey;
        if (message.perkDefaultValue != null && message.hasOwnProperty("perkDefaultValue"))
            object.perkDefaultValue = message.perkDefaultValue;
        return object;
    };

    SubscriptionPlanPerk.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionPlanPerk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionPlanPerk";
    };

    return SubscriptionPlanPerk;
})();

export const SubscriptionPlanPrice = $root.SubscriptionPlanPrice = (() => {

    function SubscriptionPlanPrice(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionPlanPrice.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    SubscriptionPlanPrice.prototype.sku = "";
    SubscriptionPlanPrice.prototype.selected = false;

    SubscriptionPlanPrice.create = function create(properties) {
        return new SubscriptionPlanPrice(properties);
    };

    SubscriptionPlanPrice.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            writer.uint32(9).sfixed64(message.price);
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(18).string(message.sku);
        if (message.selected != null && Object.hasOwnProperty.call(message, "selected"))
            writer.uint32(24).bool(message.selected);
        return writer;
    };

    SubscriptionPlanPrice.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionPlanPrice.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionPlanPrice();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.price = reader.sfixed64();
                    break;
                }
            case 2: {
                    message.sku = reader.string();
                    break;
                }
            case 3: {
                    message.selected = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionPlanPrice.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionPlanPrice.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionPlanPrice)
            return object;
        let message = new $root.SubscriptionPlanPrice();
        if (object.price != null)
            if ($util.Long)
                (message.price = $util.Long.fromValue(object.price)).unsigned = false;
            else if (typeof object.price === "string")
                message.price = parseInt(object.price, 10);
            else if (typeof object.price === "number")
                message.price = object.price;
            else if (typeof object.price === "object")
                message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.selected != null)
            message.selected = Boolean(object.selected);
        return message;
    };

    SubscriptionPlanPrice.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.price = options.longs === String ? "0" : 0;
            object.sku = "";
            object.selected = false;
        }
        if (message.price != null && message.hasOwnProperty("price"))
            if (typeof message.price === "number")
                object.price = options.longs === String ? String(message.price) : message.price;
            else
                object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber() : message.price;
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.selected != null && message.hasOwnProperty("selected"))
            object.selected = message.selected;
        return object;
    };

    SubscriptionPlanPrice.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionPlanPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionPlanPrice";
    };

    return SubscriptionPlanPrice;
})();

export const SubscriptionPlanUpdateRequest = $root.SubscriptionPlanUpdateRequest = (() => {

    function SubscriptionPlanUpdateRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionPlanUpdateRequest.prototype.sku = "";
    SubscriptionPlanUpdateRequest.prototype.enabled = false;

    SubscriptionPlanUpdateRequest.create = function create(properties) {
        return new SubscriptionPlanUpdateRequest(properties);
    };

    SubscriptionPlanUpdateRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(10).string(message.sku);
        if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
            writer.uint32(16).bool(message.enabled);
        return writer;
    };

    SubscriptionPlanUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionPlanUpdateRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionPlanUpdateRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.enabled = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionPlanUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionPlanUpdateRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionPlanUpdateRequest)
            return object;
        let message = new $root.SubscriptionPlanUpdateRequest();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.enabled != null)
            message.enabled = Boolean(object.enabled);
        return message;
    };

    SubscriptionPlanUpdateRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.sku = "";
            object.enabled = false;
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.enabled != null && message.hasOwnProperty("enabled"))
            object.enabled = message.enabled;
        return object;
    };

    SubscriptionPlanUpdateRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionPlanUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionPlanUpdateRequest";
    };

    return SubscriptionPlanUpdateRequest;
})();

export const SubscriptionPlanUpdateResponse = $root.SubscriptionPlanUpdateResponse = (() => {

    function SubscriptionPlanUpdateResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionPlanUpdateResponse.prototype.code = 1;

    SubscriptionPlanUpdateResponse.create = function create(properties) {
        return new SubscriptionPlanUpdateResponse(properties);
    };

    SubscriptionPlanUpdateResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        return writer;
    };

    SubscriptionPlanUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionPlanUpdateResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionPlanUpdateResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    SubscriptionPlanUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionPlanUpdateResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionPlanUpdateResponse)
            return object;
        let message = new $root.SubscriptionPlanUpdateResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        return message;
    };

    SubscriptionPlanUpdateResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    SubscriptionPlanUpdateResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionPlanUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionPlanUpdateResponse";
    };

    return SubscriptionPlanUpdateResponse;
})();

export const SubscriptionPlansRequest = $root.SubscriptionPlansRequest = (() => {

    function SubscriptionPlansRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionPlansRequest.prototype.includeDisabled = false;
    SubscriptionPlansRequest.prototype.streamerId = "";
    SubscriptionPlansRequest.prototype.marketOfferId = "";

    SubscriptionPlansRequest.create = function create(properties) {
        return new SubscriptionPlansRequest(properties);
    };

    SubscriptionPlansRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.includeDisabled != null && Object.hasOwnProperty.call(message, "includeDisabled"))
            writer.uint32(8).bool(message.includeDisabled);
        if (message.streamerId != null && Object.hasOwnProperty.call(message, "streamerId"))
            writer.uint32(18).string(message.streamerId);
        if (message.marketOfferId != null && Object.hasOwnProperty.call(message, "marketOfferId"))
            writer.uint32(26).string(message.marketOfferId);
        return writer;
    };

    SubscriptionPlansRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionPlansRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionPlansRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.includeDisabled = reader.bool();
                    break;
                }
            case 2: {
                    message.streamerId = reader.string();
                    break;
                }
            case 3: {
                    message.marketOfferId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionPlansRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionPlansRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionPlansRequest)
            return object;
        let message = new $root.SubscriptionPlansRequest();
        if (object.includeDisabled != null)
            message.includeDisabled = Boolean(object.includeDisabled);
        if (object.streamerId != null)
            message.streamerId = String(object.streamerId);
        if (object.marketOfferId != null)
            message.marketOfferId = String(object.marketOfferId);
        return message;
    };

    SubscriptionPlansRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.includeDisabled = false;
            object.streamerId = "";
            object.marketOfferId = "";
        }
        if (message.includeDisabled != null && message.hasOwnProperty("includeDisabled"))
            object.includeDisabled = message.includeDisabled;
        if (message.streamerId != null && message.hasOwnProperty("streamerId"))
            object.streamerId = message.streamerId;
        if (message.marketOfferId != null && message.hasOwnProperty("marketOfferId"))
            object.marketOfferId = message.marketOfferId;
        return object;
    };

    SubscriptionPlansRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionPlansRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionPlansRequest";
    };

    return SubscriptionPlansRequest;
})();

export const SubscriptionPlansResponse = $root.SubscriptionPlansResponse = (() => {

    function SubscriptionPlansResponse(properties) {
        this.plans = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionPlansResponse.prototype.code = 1;
    SubscriptionPlansResponse.prototype.plans = $util.emptyArray;

    SubscriptionPlansResponse.create = function create(properties) {
        return new SubscriptionPlansResponse(properties);
    };

    SubscriptionPlansResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.plans != null && message.plans.length)
            for (let i = 0; i < message.plans.length; ++i)
                $root.SubscriptionPlan.encode(message.plans[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    SubscriptionPlansResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionPlansResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionPlansResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.plans && message.plans.length))
                        message.plans = [];
                    message.plans.push($root.SubscriptionPlan.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    SubscriptionPlansResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionPlansResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionPlansResponse)
            return object;
        let message = new $root.SubscriptionPlansResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        if (object.plans) {
            if (!Array.isArray(object.plans))
                throw TypeError(".SubscriptionPlansResponse.plans: array expected");
            message.plans = [];
            for (let i = 0; i < object.plans.length; ++i) {
                if (typeof object.plans[i] !== "object")
                    throw TypeError(".SubscriptionPlansResponse.plans: object expected");
                message.plans[i] = $root.SubscriptionPlan.fromObject(object.plans[i]);
            }
        }
        return message;
    };

    SubscriptionPlansResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.plans = [];
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.plans && message.plans.length) {
            object.plans = [];
            for (let j = 0; j < message.plans.length; ++j)
                object.plans[j] = $root.SubscriptionPlan.toObject(message.plans[j], options);
        }
        return object;
    };

    SubscriptionPlansResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionPlansResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionPlansResponse";
    };

    return SubscriptionPlansResponse;
})();

export const SubscriptionRequest = $root.SubscriptionRequest = (() => {

    function SubscriptionRequest(properties) {
        this.streamerId = [];
        this.subscriberId = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionRequest.prototype.streamerId = $util.emptyArray;
    SubscriptionRequest.prototype.subscriberId = $util.emptyArray;

    SubscriptionRequest.create = function create(properties) {
        return new SubscriptionRequest(properties);
    };

    SubscriptionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.streamerId != null && message.streamerId.length)
            for (let i = 0; i < message.streamerId.length; ++i)
                writer.uint32(10).string(message.streamerId[i]);
        if (message.subscriberId != null && message.subscriberId.length)
            for (let i = 0; i < message.subscriberId.length; ++i)
                writer.uint32(18).string(message.subscriberId[i]);
        return writer;
    };

    SubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.streamerId && message.streamerId.length))
                        message.streamerId = [];
                    message.streamerId.push(reader.string());
                    break;
                }
            case 2: {
                    if (!(message.subscriberId && message.subscriberId.length))
                        message.subscriberId = [];
                    message.subscriberId.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionRequest)
            return object;
        let message = new $root.SubscriptionRequest();
        if (object.streamerId) {
            if (!Array.isArray(object.streamerId))
                throw TypeError(".SubscriptionRequest.streamerId: array expected");
            message.streamerId = [];
            for (let i = 0; i < object.streamerId.length; ++i)
                message.streamerId[i] = String(object.streamerId[i]);
        }
        if (object.subscriberId) {
            if (!Array.isArray(object.subscriberId))
                throw TypeError(".SubscriptionRequest.subscriberId: array expected");
            message.subscriberId = [];
            for (let i = 0; i < object.subscriberId.length; ++i)
                message.subscriberId[i] = String(object.subscriberId[i]);
        }
        return message;
    };

    SubscriptionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.streamerId = [];
            object.subscriberId = [];
        }
        if (message.streamerId && message.streamerId.length) {
            object.streamerId = [];
            for (let j = 0; j < message.streamerId.length; ++j)
                object.streamerId[j] = message.streamerId[j];
        }
        if (message.subscriberId && message.subscriberId.length) {
            object.subscriberId = [];
            for (let j = 0; j < message.subscriberId.length; ++j)
                object.subscriberId[j] = message.subscriberId[j];
        }
        return object;
    };

    SubscriptionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionRequest";
    };

    return SubscriptionRequest;
})();

export const SubscriptionResponse = $root.SubscriptionResponse = (() => {

    function SubscriptionResponse(properties) {
        this.subscriptions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionResponse.prototype.code = 1;
    SubscriptionResponse.prototype.subscriptions = $util.emptyArray;
    SubscriptionResponse.prototype.page = null;

    SubscriptionResponse.create = function create(properties) {
        return new SubscriptionResponse(properties);
    };

    SubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.subscriptions != null && message.subscriptions.length)
            for (let i = 0; i < message.subscriptions.length; ++i)
                $root.Subscription.encode(message.subscriptions[i], writer.uint32(18).fork()).ldelim();
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            $root.Page.encode(message.page, writer.uint32(26).fork()).ldelim();
        return writer;
    };

    SubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.subscriptions && message.subscriptions.length))
                        message.subscriptions = [];
                    message.subscriptions.push($root.Subscription.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.page = $root.Page.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    SubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionResponse)
            return object;
        let message = new $root.SubscriptionResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        if (object.subscriptions) {
            if (!Array.isArray(object.subscriptions))
                throw TypeError(".SubscriptionResponse.subscriptions: array expected");
            message.subscriptions = [];
            for (let i = 0; i < object.subscriptions.length; ++i) {
                if (typeof object.subscriptions[i] !== "object")
                    throw TypeError(".SubscriptionResponse.subscriptions: object expected");
                message.subscriptions[i] = $root.Subscription.fromObject(object.subscriptions[i]);
            }
        }
        if (object.page != null) {
            if (typeof object.page !== "object")
                throw TypeError(".SubscriptionResponse.page: object expected");
            message.page = $root.Page.fromObject(object.page);
        }
        return message;
    };

    SubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.subscriptions = [];
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.page = null;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.subscriptions && message.subscriptions.length) {
            object.subscriptions = [];
            for (let j = 0; j < message.subscriptions.length; ++j)
                object.subscriptions[j] = $root.Subscription.toObject(message.subscriptions[j], options);
        }
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = $root.Page.toObject(message.page, options);
        return object;
    };

    SubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionResponse";
    };

    return SubscriptionResponse;
})();

export const UpgradeSubscriptionRequest = $root.UpgradeSubscriptionRequest = (() => {

    function UpgradeSubscriptionRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UpgradeSubscriptionRequest.prototype.externalOfferId = "";
    UpgradeSubscriptionRequest.prototype.details = null;
    UpgradeSubscriptionRequest.prototype.triggerId = "";

    UpgradeSubscriptionRequest.create = function create(properties) {
        return new UpgradeSubscriptionRequest(properties);
    };

    UpgradeSubscriptionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.externalOfferId);
        $root.SubscriptionDetails.encode(message.details, writer.uint32(18).fork()).ldelim();
        if (message.triggerId != null && Object.hasOwnProperty.call(message, "triggerId"))
            writer.uint32(26).string(message.triggerId);
        return writer;
    };

    UpgradeSubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UpgradeSubscriptionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpgradeSubscriptionRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.externalOfferId = reader.string();
                    break;
                }
            case 2: {
                    message.details = $root.SubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.triggerId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("externalOfferId"))
            throw $util.ProtocolError("missing required 'externalOfferId'", { instance: message });
        if (!message.hasOwnProperty("details"))
            throw $util.ProtocolError("missing required 'details'", { instance: message });
        return message;
    };

    UpgradeSubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UpgradeSubscriptionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpgradeSubscriptionRequest)
            return object;
        let message = new $root.UpgradeSubscriptionRequest();
        if (object.externalOfferId != null)
            message.externalOfferId = String(object.externalOfferId);
        if (object.details != null) {
            if (typeof object.details !== "object")
                throw TypeError(".UpgradeSubscriptionRequest.details: object expected");
            message.details = $root.SubscriptionDetails.fromObject(object.details);
        }
        if (object.triggerId != null)
            message.triggerId = String(object.triggerId);
        return message;
    };

    UpgradeSubscriptionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.externalOfferId = "";
            object.details = null;
            object.triggerId = "";
        }
        if (message.externalOfferId != null && message.hasOwnProperty("externalOfferId"))
            object.externalOfferId = message.externalOfferId;
        if (message.details != null && message.hasOwnProperty("details"))
            object.details = $root.SubscriptionDetails.toObject(message.details, options);
        if (message.triggerId != null && message.hasOwnProperty("triggerId"))
            object.triggerId = message.triggerId;
        return object;
    };

    UpgradeSubscriptionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UpgradeSubscriptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpgradeSubscriptionRequest";
    };

    return UpgradeSubscriptionRequest;
})();

export const UpgradeSubscriptionResponse = $root.UpgradeSubscriptionResponse = (() => {

    function UpgradeSubscriptionResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UpgradeSubscriptionResponse.prototype.code = 1;
    UpgradeSubscriptionResponse.prototype.subscription = null;

    UpgradeSubscriptionResponse.create = function create(properties) {
        return new UpgradeSubscriptionResponse(properties);
    };

    UpgradeSubscriptionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
            $root.Subscription.encode(message.subscription, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    UpgradeSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UpgradeSubscriptionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpgradeSubscriptionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.subscription = $root.Subscription.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    UpgradeSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UpgradeSubscriptionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpgradeSubscriptionResponse)
            return object;
        let message = new $root.UpgradeSubscriptionResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "NOT_FOUND":
        case 4:
            message.code = 4;
            break;
        case "FAILED_DUPLICATE":
        case 5:
            message.code = 5;
            break;
        case "FAILED_INVALID_STATE":
        case 6:
            message.code = 6;
            break;
        case "INSUFFICIENT_BALANCE":
        case 7:
            message.code = 7;
            break;
        }
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".UpgradeSubscriptionResponse.subscription: object expected");
            message.subscription = $root.Subscription.fromObject(object.subscription);
        }
        return message;
    };

    UpgradeSubscriptionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.subscription = null;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.Subscription.toObject(message.subscription, options);
        return object;
    };

    UpgradeSubscriptionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UpgradeSubscriptionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpgradeSubscriptionResponse";
    };

    return UpgradeSubscriptionResponse;
})();

export const ResponseCode = $root.ResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "OK"] = 1;
    values[valuesById[2] = "FAILED_GENERAL"] = 2;
    values[valuesById[3] = "FAILED_UNAUTHORIZED"] = 3;
    values[valuesById[4] = "NOT_FOUND"] = 4;
    values[valuesById[5] = "FAILED_DUPLICATE"] = 5;
    values[valuesById[6] = "FAILED_INVALID_STATE"] = 6;
    values[valuesById[7] = "INSUFFICIENT_BALANCE"] = 7;
    return values;
})();

export const SortDirection = $root.SortDirection = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "ASC"] = 1;
    values[valuesById[2] = "DESC"] = 2;
    return values;
})();

export const Capabilities = $root.Capabilities = (() => {

    function Capabilities(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Capabilities.prototype.level = 0;
    Capabilities.prototype.hasHighlights = false;
    Capabilities.prototype.directSupport = false;
    Capabilities.prototype.specialStatus = false;

    Capabilities.create = function create(properties) {
        return new Capabilities(properties);
    };

    Capabilities.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.level);
        writer.uint32(16).bool(message.hasHighlights);
        writer.uint32(24).bool(message.directSupport);
        writer.uint32(32).bool(message.specialStatus);
        return writer;
    };

    Capabilities.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Capabilities.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Capabilities();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.level = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.hasHighlights = reader.bool();
                    break;
                }
            case 3: {
                    message.directSupport = reader.bool();
                    break;
                }
            case 4: {
                    message.specialStatus = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("level"))
            throw $util.ProtocolError("missing required 'level'", { instance: message });
        if (!message.hasOwnProperty("hasHighlights"))
            throw $util.ProtocolError("missing required 'hasHighlights'", { instance: message });
        if (!message.hasOwnProperty("directSupport"))
            throw $util.ProtocolError("missing required 'directSupport'", { instance: message });
        if (!message.hasOwnProperty("specialStatus"))
            throw $util.ProtocolError("missing required 'specialStatus'", { instance: message });
        return message;
    };

    Capabilities.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Capabilities.fromObject = function fromObject(object) {
        if (object instanceof $root.Capabilities)
            return object;
        let message = new $root.Capabilities();
        if (object.level != null)
            message.level = object.level | 0;
        if (object.hasHighlights != null)
            message.hasHighlights = Boolean(object.hasHighlights);
        if (object.directSupport != null)
            message.directSupport = Boolean(object.directSupport);
        if (object.specialStatus != null)
            message.specialStatus = Boolean(object.specialStatus);
        return message;
    };

    Capabilities.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.level = 0;
            object.hasHighlights = false;
            object.directSupport = false;
            object.specialStatus = false;
        }
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        if (message.hasHighlights != null && message.hasOwnProperty("hasHighlights"))
            object.hasHighlights = message.hasHighlights;
        if (message.directSupport != null && message.hasOwnProperty("directSupport"))
            object.directSupport = message.directSupport;
        if (message.specialStatus != null && message.hasOwnProperty("specialStatus"))
            object.specialStatus = message.specialStatus;
        return object;
    };

    Capabilities.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Capabilities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Capabilities";
    };

    return Capabilities;
})();

export const ProfileInfo = $root.ProfileInfo = (() => {

    function ProfileInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ProfileInfo.prototype.accountId = "";
    ProfileInfo.prototype.firstName = "";
    ProfileInfo.prototype.lastName = "";
    ProfileInfo.prototype.pictureUrl = "";
    ProfileInfo.prototype.thumbnailUrl = "";

    ProfileInfo.create = function create(properties) {
        return new ProfileInfo(properties);
    };

    ProfileInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.accountId);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(18).string(message.firstName);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(26).string(message.lastName);
        if (message.pictureUrl != null && Object.hasOwnProperty.call(message, "pictureUrl"))
            writer.uint32(34).string(message.pictureUrl);
        if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
            writer.uint32(42).string(message.thumbnailUrl);
        return writer;
    };

    ProfileInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ProfileInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProfileInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.accountId = reader.string();
                    break;
                }
            case 2: {
                    message.firstName = reader.string();
                    break;
                }
            case 3: {
                    message.lastName = reader.string();
                    break;
                }
            case 4: {
                    message.pictureUrl = reader.string();
                    break;
                }
            case 5: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("accountId"))
            throw $util.ProtocolError("missing required 'accountId'", { instance: message });
        return message;
    };

    ProfileInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ProfileInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.ProfileInfo)
            return object;
        let message = new $root.ProfileInfo();
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.pictureUrl != null)
            message.pictureUrl = String(object.pictureUrl);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        return message;
    };

    ProfileInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.accountId = "";
            object.firstName = "";
            object.lastName = "";
            object.pictureUrl = "";
            object.thumbnailUrl = "";
        }
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.pictureUrl != null && message.hasOwnProperty("pictureUrl"))
            object.pictureUrl = message.pictureUrl;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        return object;
    };

    ProfileInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ProfileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProfileInfo";
    };

    return ProfileInfo;
})();

export const StreamerSubscriptionDetails = $root.StreamerSubscriptionDetails = (() => {

    function StreamerSubscriptionDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamerSubscriptionDetails.prototype.streamerProfile = null;
    StreamerSubscriptionDetails.prototype.level = 0;

    StreamerSubscriptionDetails.create = function create(properties) {
        return new StreamerSubscriptionDetails(properties);
    };

    StreamerSubscriptionDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.streamerProfile != null && Object.hasOwnProperty.call(message, "streamerProfile"))
            $root.ProfileInfo.encode(message.streamerProfile, writer.uint32(10).fork()).ldelim();
        if (message.level != null && Object.hasOwnProperty.call(message, "level"))
            writer.uint32(21).sfixed32(message.level);
        return writer;
    };

    StreamerSubscriptionDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamerSubscriptionDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamerSubscriptionDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.streamerProfile = $root.ProfileInfo.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.level = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamerSubscriptionDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamerSubscriptionDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamerSubscriptionDetails)
            return object;
        let message = new $root.StreamerSubscriptionDetails();
        if (object.streamerProfile != null) {
            if (typeof object.streamerProfile !== "object")
                throw TypeError(".StreamerSubscriptionDetails.streamerProfile: object expected");
            message.streamerProfile = $root.ProfileInfo.fromObject(object.streamerProfile);
        }
        if (object.level != null)
            message.level = object.level | 0;
        return message;
    };

    StreamerSubscriptionDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.streamerProfile = null;
            object.level = 0;
        }
        if (message.streamerProfile != null && message.hasOwnProperty("streamerProfile"))
            object.streamerProfile = $root.ProfileInfo.toObject(message.streamerProfile, options);
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        return object;
    };

    StreamerSubscriptionDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamerSubscriptionDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamerSubscriptionDetails";
    };

    return StreamerSubscriptionDetails;
})();

export const Subscription = $root.Subscription = (() => {

    function Subscription(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Subscription.prototype.id = "";
    Subscription.prototype.status = 1;
    Subscription.prototype.subscriberProfile = null;
    Subscription.prototype.externalOfferId = "";
    Subscription.prototype.startedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.endedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.details = null;
    Subscription.prototype.isRenew = false;
    Subscription.prototype.points = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.credits = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.origin = 1;
    Subscription.prototype.times = 0;

    Subscription.create = function create(properties) {
        return new Subscription(properties);
    };

    Subscription.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        writer.uint32(16).int32(message.status);
        if (message.subscriberProfile != null && Object.hasOwnProperty.call(message, "subscriberProfile"))
            $root.ProfileInfo.encode(message.subscriberProfile, writer.uint32(26).fork()).ldelim();
        if (message.externalOfferId != null && Object.hasOwnProperty.call(message, "externalOfferId"))
            writer.uint32(34).string(message.externalOfferId);
        if (message.startedAt != null && Object.hasOwnProperty.call(message, "startedAt"))
            writer.uint32(41).sfixed64(message.startedAt);
        if (message.endedAt != null && Object.hasOwnProperty.call(message, "endedAt"))
            writer.uint32(49).sfixed64(message.endedAt);
        if (message.details != null && Object.hasOwnProperty.call(message, "details"))
            $root.SubscriptionDetails.encode(message.details, writer.uint32(58).fork()).ldelim();
        if (message.isRenew != null && Object.hasOwnProperty.call(message, "isRenew"))
            writer.uint32(64).bool(message.isRenew);
        if (message.points != null && Object.hasOwnProperty.call(message, "points"))
            writer.uint32(73).sfixed64(message.points);
        if (message.credits != null && Object.hasOwnProperty.call(message, "credits"))
            writer.uint32(81).sfixed64(message.credits);
        if (message.origin != null && Object.hasOwnProperty.call(message, "origin"))
            writer.uint32(88).int32(message.origin);
        if (message.times != null && Object.hasOwnProperty.call(message, "times"))
            writer.uint32(101).sfixed32(message.times);
        return writer;
    };

    Subscription.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Subscription.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Subscription();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.status = reader.int32();
                    break;
                }
            case 3: {
                    message.subscriberProfile = $root.ProfileInfo.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.externalOfferId = reader.string();
                    break;
                }
            case 5: {
                    message.startedAt = reader.sfixed64();
                    break;
                }
            case 6: {
                    message.endedAt = reader.sfixed64();
                    break;
                }
            case 7: {
                    message.details = $root.SubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.isRenew = reader.bool();
                    break;
                }
            case 9: {
                    message.points = reader.sfixed64();
                    break;
                }
            case 10: {
                    message.credits = reader.sfixed64();
                    break;
                }
            case 11: {
                    message.origin = reader.int32();
                    break;
                }
            case 12: {
                    message.times = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("status"))
            throw $util.ProtocolError("missing required 'status'", { instance: message });
        return message;
    };

    Subscription.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Subscription.fromObject = function fromObject(object) {
        if (object instanceof $root.Subscription)
            return object;
        let message = new $root.Subscription();
        if (object.id != null)
            message.id = String(object.id);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "ACTIVE":
        case 1:
            message.status = 1;
            break;
        case "CANCELLED":
        case 2:
            message.status = 2;
            break;
        case "PENDING":
        case 3:
            message.status = 3;
            break;
        case "EXPIRED":
        case 4:
            message.status = 4;
            break;
        case "RENEWING":
        case 5:
            message.status = 5;
            break;
        case "HOLD":
        case 6:
            message.status = 6;
            break;
        case "PREAPPROVED":
        case 7:
            message.status = 7;
            break;
        case "FREE_TRIAL":
        case 8:
            message.status = 8;
            break;
        case "GRACE_PERIOD":
        case 9:
            message.status = 9;
            break;
        }
        if (object.subscriberProfile != null) {
            if (typeof object.subscriberProfile !== "object")
                throw TypeError(".Subscription.subscriberProfile: object expected");
            message.subscriberProfile = $root.ProfileInfo.fromObject(object.subscriberProfile);
        }
        if (object.externalOfferId != null)
            message.externalOfferId = String(object.externalOfferId);
        if (object.startedAt != null)
            if ($util.Long)
                (message.startedAt = $util.Long.fromValue(object.startedAt)).unsigned = false;
            else if (typeof object.startedAt === "string")
                message.startedAt = parseInt(object.startedAt, 10);
            else if (typeof object.startedAt === "number")
                message.startedAt = object.startedAt;
            else if (typeof object.startedAt === "object")
                message.startedAt = new $util.LongBits(object.startedAt.low >>> 0, object.startedAt.high >>> 0).toNumber();
        if (object.endedAt != null)
            if ($util.Long)
                (message.endedAt = $util.Long.fromValue(object.endedAt)).unsigned = false;
            else if (typeof object.endedAt === "string")
                message.endedAt = parseInt(object.endedAt, 10);
            else if (typeof object.endedAt === "number")
                message.endedAt = object.endedAt;
            else if (typeof object.endedAt === "object")
                message.endedAt = new $util.LongBits(object.endedAt.low >>> 0, object.endedAt.high >>> 0).toNumber();
        if (object.details != null) {
            if (typeof object.details !== "object")
                throw TypeError(".Subscription.details: object expected");
            message.details = $root.SubscriptionDetails.fromObject(object.details);
        }
        if (object.isRenew != null)
            message.isRenew = Boolean(object.isRenew);
        if (object.points != null)
            if ($util.Long)
                (message.points = $util.Long.fromValue(object.points)).unsigned = false;
            else if (typeof object.points === "string")
                message.points = parseInt(object.points, 10);
            else if (typeof object.points === "number")
                message.points = object.points;
            else if (typeof object.points === "object")
                message.points = new $util.LongBits(object.points.low >>> 0, object.points.high >>> 0).toNumber();
        if (object.credits != null)
            if ($util.Long)
                (message.credits = $util.Long.fromValue(object.credits)).unsigned = false;
            else if (typeof object.credits === "string")
                message.credits = parseInt(object.credits, 10);
            else if (typeof object.credits === "number")
                message.credits = object.credits;
            else if (typeof object.credits === "object")
                message.credits = new $util.LongBits(object.credits.low >>> 0, object.credits.high >>> 0).toNumber();
        switch (object.origin) {
        default:
            if (typeof object.origin === "number") {
                message.origin = object.origin;
                break;
            }
            break;
        case "GOOGLE":
        case 1:
            message.origin = 1;
            break;
        case "APPLE":
        case 2:
            message.origin = 2;
            break;
        case "WEB":
        case 3:
            message.origin = 3;
            break;
        case "CREDITS":
        case 4:
            message.origin = 4;
            break;
        }
        if (object.times != null)
            message.times = object.times | 0;
        return message;
    };

    Subscription.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.status = options.enums === String ? "ACTIVE" : 1;
            object.subscriberProfile = null;
            object.externalOfferId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.startedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.startedAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.endedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.endedAt = options.longs === String ? "0" : 0;
            object.details = null;
            object.isRenew = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.points = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.points = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.credits = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.credits = options.longs === String ? "0" : 0;
            object.origin = options.enums === String ? "GOOGLE" : 1;
            object.times = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.SubscriptionStatus[message.status] === undefined ? message.status : $root.SubscriptionStatus[message.status] : message.status;
        if (message.subscriberProfile != null && message.hasOwnProperty("subscriberProfile"))
            object.subscriberProfile = $root.ProfileInfo.toObject(message.subscriberProfile, options);
        if (message.externalOfferId != null && message.hasOwnProperty("externalOfferId"))
            object.externalOfferId = message.externalOfferId;
        if (message.startedAt != null && message.hasOwnProperty("startedAt"))
            if (typeof message.startedAt === "number")
                object.startedAt = options.longs === String ? String(message.startedAt) : message.startedAt;
            else
                object.startedAt = options.longs === String ? $util.Long.prototype.toString.call(message.startedAt) : options.longs === Number ? new $util.LongBits(message.startedAt.low >>> 0, message.startedAt.high >>> 0).toNumber() : message.startedAt;
        if (message.endedAt != null && message.hasOwnProperty("endedAt"))
            if (typeof message.endedAt === "number")
                object.endedAt = options.longs === String ? String(message.endedAt) : message.endedAt;
            else
                object.endedAt = options.longs === String ? $util.Long.prototype.toString.call(message.endedAt) : options.longs === Number ? new $util.LongBits(message.endedAt.low >>> 0, message.endedAt.high >>> 0).toNumber() : message.endedAt;
        if (message.details != null && message.hasOwnProperty("details"))
            object.details = $root.SubscriptionDetails.toObject(message.details, options);
        if (message.isRenew != null && message.hasOwnProperty("isRenew"))
            object.isRenew = message.isRenew;
        if (message.points != null && message.hasOwnProperty("points"))
            if (typeof message.points === "number")
                object.points = options.longs === String ? String(message.points) : message.points;
            else
                object.points = options.longs === String ? $util.Long.prototype.toString.call(message.points) : options.longs === Number ? new $util.LongBits(message.points.low >>> 0, message.points.high >>> 0).toNumber() : message.points;
        if (message.credits != null && message.hasOwnProperty("credits"))
            if (typeof message.credits === "number")
                object.credits = options.longs === String ? String(message.credits) : message.credits;
            else
                object.credits = options.longs === String ? $util.Long.prototype.toString.call(message.credits) : options.longs === Number ? new $util.LongBits(message.credits.low >>> 0, message.credits.high >>> 0).toNumber() : message.credits;
        if (message.origin != null && message.hasOwnProperty("origin"))
            object.origin = options.enums === String ? $root.SubscriptionOrigin[message.origin] === undefined ? message.origin : $root.SubscriptionOrigin[message.origin] : message.origin;
        if (message.times != null && message.hasOwnProperty("times"))
            object.times = message.times;
        return object;
    };

    Subscription.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Subscription.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Subscription";
    };

    return Subscription;
})();

export const SubscriptionDetails = $root.SubscriptionDetails = (() => {

    function SubscriptionDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionDetails.prototype.streamer = null;

    SubscriptionDetails.create = function create(properties) {
        return new SubscriptionDetails(properties);
    };

    SubscriptionDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.streamer != null && Object.hasOwnProperty.call(message, "streamer"))
            $root.StreamerSubscriptionDetails.encode(message.streamer, writer.uint32(10).fork()).ldelim();
        return writer;
    };

    SubscriptionDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.streamer = $root.StreamerSubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionDetails)
            return object;
        let message = new $root.SubscriptionDetails();
        if (object.streamer != null) {
            if (typeof object.streamer !== "object")
                throw TypeError(".SubscriptionDetails.streamer: object expected");
            message.streamer = $root.StreamerSubscriptionDetails.fromObject(object.streamer);
        }
        return message;
    };

    SubscriptionDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.streamer = null;
        if (message.streamer != null && message.hasOwnProperty("streamer"))
            object.streamer = $root.StreamerSubscriptionDetails.toObject(message.streamer, options);
        return object;
    };

    SubscriptionDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionDetails";
    };

    return SubscriptionDetails;
})();

export const SubscriptionStatus = $root.SubscriptionStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "ACTIVE"] = 1;
    values[valuesById[2] = "CANCELLED"] = 2;
    values[valuesById[3] = "PENDING"] = 3;
    values[valuesById[4] = "EXPIRED"] = 4;
    values[valuesById[5] = "RENEWING"] = 5;
    values[valuesById[6] = "HOLD"] = 6;
    values[valuesById[7] = "PREAPPROVED"] = 7;
    values[valuesById[8] = "FREE_TRIAL"] = 8;
    values[valuesById[9] = "GRACE_PERIOD"] = 9;
    return values;
})();

export const SubscriptionCategory = $root.SubscriptionCategory = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "STREAMER"] = 1;
    values[valuesById[2] = "VIP"] = 2;
    return values;
})();

export const SubscriptionOrigin = $root.SubscriptionOrigin = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "GOOGLE"] = 1;
    values[valuesById[2] = "APPLE"] = 2;
    values[valuesById[3] = "WEB"] = 3;
    values[valuesById[4] = "CREDITS"] = 4;
    return values;
})();

export { $root as default };
