import { createReducer } from "@reduxjs/toolkit";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Nullable } from "src/types/common";
import { refreshSessionToken } from "state/actionCreators/refreshSessionToken";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";

export const initialRefreshSessionTokenState = {
  data: null,
  meta: initialFetcherStateMeta,
};

export type RefreshSessionTokenState = AsyncState<Nullable<unknown>>;

const reducer = createReducer<RefreshSessionTokenState>(
  initialRefreshSessionTokenState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      initialData: initialRefreshSessionTokenState.data,
      builder,
      action: refreshSessionToken,
      prepareData: emptyFunction,
    });
  }
);

export default reducer;
