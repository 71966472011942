import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { removeConversation } from "chat/exports/state/actionCreators";
import { removeMessagesRequest } from "chat/messageRequest/exports/state";
import { Breakpoints, ModalType } from "src/enums";
import { openDeleteBlockRequestModalHandler } from "src/state/actionCreators/modal";
import { AccountInfo } from "src/types/common";
import DisplayName from "src/ui/common/DisplayName";
import { useBreakpointPrecise } from "src/ui/hooks/useBreakpoint";
import useProfileById from "src/ui/hooks/useProfileById";
import {
  linkToChat,
  linkToMessageRequest,
  linkToMessageRequestMatch,
} from "src/ui/navigation/links";
import sharedMessages from "ui/common/intl/sharedMessages";

export interface UseDeleteChatParams {
  accountInfo?: AccountInfo;
  conversationId?: string;
}

const useDeleteChat = ({
  accountInfo,
  conversationId,
}: UseDeleteChatParams) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpointPrecise();

  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const isChatRequest = useRouteMatch(linkToMessageRequestMatch);

  const {
    account_id: accountId,
    first_name: firstName,
    last_name: lastName,
  } = accountInfo || {};
  const { basicProfile } = useProfileById(accountId, true);

  return useCallback(() => {
    if (!accountInfo || !conversationId) {
      return null;
    }

    dispatch(
      openDeleteBlockRequestModalHandler({
        isMobile: !isDesktop,
        dismissText: sharedMessages.cancel,
        title: sharedMessages.deleteConversation,
        modalType: ModalType.DELETE_CONVERSATION_MODAL,
        basicProfile: isChatRequest ? undefined : basicProfile,
        body: (
          <FormattedMessage
            {...sharedMessages.deleteConversationBody}
            values={{
              name: (
                <b>
                  <DisplayName
                    basicProfile={basicProfile}
                    firstName={firstName}
                    lastName={lastName}
                  />
                </b>
              ),
            }}
          />
        ),
        confirmText: sharedMessages.deleteConversation,
        isConfirmButtonHighlighted: true,
        isShaderTheme: false,
        confirm: async () => {
          dispatch(
            isChatRequest
              ? removeMessagesRequest({ conversationId })
              : removeConversation({ conversationId })
          );
          history.push(isChatRequest ? linkToMessageRequest : linkToChat);
        },
      })
    );
  }, [
    dispatch,
    conversationId,
    history,
    basicProfile,
    isChatRequest,
    firstName,
    lastName,
  ]);
};

export default useDeleteChat;
