import { MutableRefObject, useEffect } from "react";
import intersectionObserver from "src/utils/intersectionObserver";
import { Nullable } from "src/types/common";

const observer = intersectionObserver(
  (entries, observer) => {
    entries.forEach((x) => {
      if (!x.isIntersecting) return;
      const target = x.target as HTMLImageElement;
      const src = target.dataset.src;
      if (src != null) {
        const img = new Image();
        img.onload = () => {
          target.src = src;
          target.removeAttribute("data-src");
        };
        img.onerror = () => {
          target.src = src;
        };
        img.src = src;
      }

      observer.unobserve(target);
    });
  },
  {
    rootMargin: "300px 0px 300px 0px",
  }
);

export default (
  ref: MutableRefObject<Nullable<HTMLImageElement>>,
  src: string | undefined = ""
) => {
  useEffect(() => {
    if (!observer || !ref.current) {
      return;
    }

    if (ref.current.src && ref.current.src !== src) {
      ref.current.src = src;
      ref.current.removeAttribute("data-src");
      return;
    }

    const savedRef = ref.current;
    observer.observe(savedRef);

    return () => {
      observer.unobserve(savedRef);
    };
  }, [ref, src]);
};
