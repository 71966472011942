import type { Middleware } from "redux";
import { ACME_RECEIVED, getPremiumMessagesEnabled } from "chat/imports/state";
import { ReceivedAcmeIdType } from "chat/imports/types";
import {
  fetchConversation,
  fetchConversations,
} from "chat/state/actionCreators";
import { processMessagesToGetDetails } from "./processMessagesToGetDetails";
import { processUnlockMessageAcmeEvent } from "./processUnlockMessageAcmeEvent";

const PREMIUM_MESSAGE_UNLOCKED_SIGNAL =
  "premium_message.content_unlocked_signal";

export const unlockListenerMiddleware: Middleware =
  (api) => (next) => (action) => {
    const isPremiumMessagesEnabled = getPremiumMessagesEnabled(api.getState());

    if (!isPremiumMessagesEnabled) {
      return next(action);
    }

    if (
      fetchConversation.fulfilled.match(action) &&
      action.payload.conversation
    ) {
      processMessagesToGetDetails(
        action.payload.conversation.messages || [],
        api
      );
    }

    if (
      fetchConversations.fulfilled.match(action) &&
      action.payload.conversations
    ) {
      const messages = action.payload.conversations.flatMap(
        (conversation) => conversation.messages || []
      );

      processMessagesToGetDetails(messages, api);
    }

    if (action.type === ACME_RECEIVED) {
      const { data } = action.payload as ReceivedAcmeIdType;

      const isUnlockEvent =
        data?.acmeId && data.acmeId.startsWith(PREMIUM_MESSAGE_UNLOCKED_SIGNAL);

      if (isUnlockEvent) {
        processUnlockMessageAcmeEvent(api);
      }
    }

    return next(action);
  };
