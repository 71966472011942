import { defineMessages } from "react-intl";

enum HelmetPageType {
  ABOUT_US = "/about-us",
  ARTISTS = "/artists",
  AUCTION = "/auction",
  BROADCASTER_AGREEMENT = "/broadcaster-agreement",
  CHAT = "/chat",
  COPYRIGHT = "/copyright",
  DEFAULT = "DEFAULT",
  LEADERS = "/leaders",
  LIVE_CREATORS = "/live/creators",
  LIVE_FOLLOWING = "/following",
  LIVE_MUSIC = "/live/music",
  LIVE_NEARBY = "/live/nearby",
  LIVE_NEW = "/live/new",
  LIVE_PARTY = "/live/liveparty",
  LIVE_PERSONAL = "/live/personal",
  LIVE_POPULAR = "/live/popular",
  LIVE_RECOMMENDED = "/live/recommended",
  LIVE_RISING_STARS = "/live/rising_stars",
  LIVE_SOCIAL = "/live/social",
  LOYALTY = "/loyalty",
  MOMENTS = "/moments",
  REGISTRATION = "/registration",
  TANGO_OFFICIAL = "/tango_official",
  TERMS_OF_USE = "/terms-of-use",
  THIRD_PARTY_LICENSE = "/third-party-license",
  TIMELINE = "/timeline",
  VOUCHER = "/voucher",
}

const m = defineMessages({
  defaultTitle: {
    id: "page.openGraph.title.default",
    defaultMessage: "Tango Live - Live Stream and Video Chat with Friends!",
  },
  defaultDescription: {
    id: "page.openGraph.desc.default",
    defaultMessage:
      "Watch incredible live streams, meet new people around the world, and watch amazing talents live before your eyes. Everything is happening right now on Tango!",
  },
  aboutUsTitle: {
    id: "page.openGraph.title.aboutUs",
    defaultMessage:
      "About Us at Tango Live, we're the #1 Livestream World Leader",
  },
  aboutUsDescription: {
    id: "page.openGraph.desc.aboutUs",
    defaultMessage:
      "Tango live is the #1 place to go live, meet new people, show off your talents, and turn your creative passion into a thriving business. See our mission in live!",
  },
  artistsTitle: {
    id: "page.openGraph.title.artists",
    defaultMessage: "Tango Artists - Meet Your Next Favorite Artists Live!",
  },
  artistsDescription: {
    id: "page.openGraph.desc.artists",
    defaultMessage:
      "See where the best artists come to go live! On Tango Live you can talk and chat while seeing firsthand some of the most incredible talents in the world!",
  },
  auctionTitle: {
    id: "page.openGraph.title.auction",
    defaultMessage: "Tango Auction - Buy, Sell, and Trade Unique Tango Cards!",
  },
  auctionDescription: {
    id: "page.openGraph.desc.auction",
    defaultMessage:
      "Tango cards are both a unique item and a great investment in the rapidly growing world of live. Head to the Tango Marketplace and discover how you can earn!",
  },
  broadcasterAgreementTitle: {
    id: "page.openGraph.title.broadcasterAgreement",
    defaultMessage: "Tango Live Broadcaster Agreement",
  },
  broadcasterAgreementDescription: {
    id: "page.openGraph.desc.broadcasterAgreement",
    defaultMessage:
      "The Tango Live Broadcast Agreement governs your usage of Tango Live's broadcasting services. Please read closely to fully understand this important agreement.",
  },
  chatTitle: {
    id: "page.openGraph.title.chat",
    defaultMessage: "Chat and Talk With Creators and Fans on Tango Live",
  },
  chatDescription: {
    id: "page.openGraph.seo.desc.chat",
    defaultMessage:
      "Engage in one-on-one live chats on Tango. Enjoy personal and real-time conversations with your favorite creators, friends and family through live video.",
  },
  copyrightTitle: {
    id: "page.openGraph.title.copyright",
    defaultMessage: "Tango Live - Copyright",
  },
  copyrightDescription: {
    id: "page.openGraph.desc.copyright",
    defaultMessage:
      "Tango Live respects the creative integrity of all creators. Tango Live prohibits the posting or uploading of any content that infringes on copyright laws.",
  },
  liveCreatorsTitle: {
    id: "page.openGraph.title.liveCreators",
    defaultMessage: "Tango Creators - See Live Talent",
  },
  liveCreatorsDescription: {
    id: "page.openGraph.seo.desc.liveCreators",
    defaultMessage:
      "Tango Live boasts some of the best and most talented creators, artists, and models in live streams. You can talk to and bond with them live!",
  },
  liveNearbyTitle: {
    id: "page.openGraph.title.liveNearby",
    defaultMessage: "See Who's Live on Tango Nearby You!",
  },
  liveNearbyDescription: {
    id: "page.openGraph.seo.desc.liveNearby",
    defaultMessage:
      "Discover live streams from nearby users on Tango. Meet people in your community or change your settings and pick which are you want to see new streamers!",
  },
  liveNewTitle: {
    id: "page.openGraph.title.liveNew",
    defaultMessage: "Meet New Creators Live On Tango",
  },
  liveNewDescription: {
    id: "page.openGraph.seo.desc.liveNew",
    defaultMessage:
      "Discover the new live streams on Tango Live! Hundreds of new, talented live streamers join Tango Live everyday to broadcast their talents.",
  },
  liveMusicTitle: {
    id: "page.openGraph.title.liveMusic",
    defaultMessage: "See and Hear Livestreams from Top Musicians",
  },
  liveMusicDescription: {
    id: "page.openGraph.desc.liveMusic",
    defaultMessage:
      "Discover the Voices and Talents of tomorrow with top Music around the world! Check out live-streams from thousands of talented musicians and singers.",
  },
  livePartyTitle: {
    id: "page.openGraph.title.liveparty",
    defaultMessage: "Enjoy Dual Livestreams from Talented Broadcasters",
  },
  livePartyDescription: {
    id: "page.openGraph.desc.liveparty",
    defaultMessage:
      "2 is better than 1: Enjoy multiple Broadcasters in one screen with Tango's Live Party dual streams!",
  },
  livePersonalTitle: {
    id: "page.openGraph.title.livePersonal",
    defaultMessage: "Follow Your Top Streamers on Tango Live",
  },
  livePersonalDescription: {
    id: "page.openGraph.desc.seo.livePersonal",
    defaultMessage:
      "Watch your favorite streamers on Tango! Watch incredible live streams, meet new people around the world, and watch amazing talents live before your eyes.",
  },
  livePopularTitle: {
    id: "page.openGraph.title.livePopular",
    defaultMessage: "Tango Live's Most Popular Live Streamers",
  },
  livePopularDescription: {
    id: "page.openGraph.seo.desc.livePopular",
    defaultMessage:
      "Not sure where to start on Tango Live? Come check out the biggest and most popular live streamers and stay updated with what's hot in the Tango community.",
  },
  liveRecommendedTitle: {
    id: "page.openGraph.title.liveRecommended",
    defaultMessage: "For You - Tango Live",
  },
  liveRecommendedDescription: {
    id: "page.openGraph.seo.desc.liveRecommended",
    defaultMessage:
      "Tango Live boasts some of the best and most talented creators, artists and models in live streams. You can talk to and bond with them live!",
  },
  liveRisingStarsTitle: {
    id: "page.openGraph.title.liveRisingStars",
    defaultMessage: "Rising Stars - Tango's Next Biggest Broadcasters",
  },
  liveRisingStarsDescription: {
    id: "page.openGraph.desc.liveRisingStars",
    defaultMessage:
      "Come meet the next biggest Tango broadcasters in the world! Chat and talk with Tango Live rising stars and meet the upcoming broadcasting talents!",
  },
  liveSocialTitle: {
    id: "page.openGraph.title.liveSocial",
    defaultMessage: "Tango Live Battles and Parties",
  },
  liveSocialDescription: {
    id: "page.openGraph.desc.liveSocial",
    defaultMessage:
      "Tango streamers team up with each other in battles and live parties. If you're looking to see the biggest and most exciting streams this is the place to start!",
  },
  loyaltyTitle: {
    id: "page.openGraph.title.loyalty",
    defaultMessage: "Tango Loyalty Program - Benefits and Rewards",
  },
  loyaltyDescription: {
    id: "page.openGraph.desc.loyalty",
    defaultMessage:
      "The Tango Loyalty Program rewards gifters with special discounts, rewards, and a unique status that sets them apart from the rest of the Tango community.",
  },
  tangoOfficialTitle: {
    id: "page.openGraph.title.tangoOfficial",
    defaultMessage: "Tango Live - Tango Official Page",
  },
  tangoOfficialDescription: {
    id: "page.openGraph.desc.tangoOfficial",
    defaultMessage:
      "The Tango Official Page is your number one place to get information about new events, parties, and updates. Make sure you're checking in to be first to know!",
  },
  termsOfUseTitle: {
    id: "page.openGraph.title.termsOfUse",
    defaultMessage: "Tango Live Terms of Use",
  },
  termsOfUseDescription: {
    id: "page.openGraph.desc.termsOfUse",
    defaultMessage:
      "Tango Live's Terms of Use Agreement determines how you can use the Tango Live Streaming App. Please make sure to read carefully before using Tango Live. ",
  },
  thirdPartyLicenseTitle: {
    id: "page.openGraph.title.thirdPartyLicense",
    defaultMessage: "Tango Third Party License",
  },
  thirdPartyLicenseDescription: {
    id: "page.openGraph.desc.thirdPartyLicense",
    defaultMessage:
      "Tango's Third Party License is written in regards to all business agreements regarding Tango software and other intellectual property.",
  },
  timelineTitle: {
    id: "page.openGraph.title.timeline",
    defaultMessage: "See What's New On Your Tango Feed",
  },
  timelineDescription: {
    id: "page.openGraph.desc.timeline",
    defaultMessage:
      "Your Tango feed keeps you up-to-date with the people in your Tango community. See and post status' and stay in the loop with what your friends are doing.",
  },
  momentsTitle: {
    id: "page.openGraph.title.moments",
    defaultMessage: "Enjoy Top Live Moments from Streams",
  },
  momentsDescription: {
    id: "page.openGraph.desc.moments",
    defaultMessage:
      "Tango loves Happy Moments! Catch Broadcasters' best Live Moments and reactions in short, engaging clips for users to view, enjoy, and share.",
  },
  leadersTitle: {
    id: "page.openGraph.title.leaders",
    defaultMessage: "Discover the Livestreaming Leaderboard",
  },
  leadersDescription: {
    id: "page.openGraph.desc.leaders",
    defaultMessage:
      "View the top daily, weekly and all-time Broadcaster rankings! See how the leaderboard changes and who rises the ranks to reach the top.",
  },
  voucherDescription: {
    id: "page.openGraph.seo.desc.voucher",
    defaultMessage:
      "Use Tango voucher to watch incredible live streams, meet new people around the world, and watch amazing streamers and models live before your eyes.\n",
  },
  registrationDescription: {
    id: "page.openGraph.seo.desc.registration",
    defaultMessage:
      "Tango Registration: Register for Tango Live to connect, stream, and enjoy interactive broadcasts. Sign up now for exclusive content and live interactions!",
  },
});

const HELMETS = {
  [HelmetPageType.DEFAULT]: {
    title: m.defaultTitle,
    description: m.defaultDescription,
  },
  [HelmetPageType.ABOUT_US]: {
    title: m.aboutUsTitle,
    description: m.aboutUsDescription,
  },
  [HelmetPageType.ARTISTS]: {
    title: m.artistsTitle,
    description: m.artistsDescription,
  },
  [HelmetPageType.AUCTION]: {
    title: m.auctionTitle,
    description: m.auctionDescription,
  },
  [HelmetPageType.BROADCASTER_AGREEMENT]: {
    title: m.broadcasterAgreementTitle,
    description: m.broadcasterAgreementDescription,
  },
  [HelmetPageType.CHAT]: {
    title: m.chatTitle,
    description: m.chatDescription,
  },
  [HelmetPageType.COPYRIGHT]: {
    title: m.copyrightTitle,
    description: m.copyrightDescription,
  },
  [HelmetPageType.LIVE_CREATORS]: {
    title: m.liveCreatorsTitle,
    description: m.liveCreatorsDescription,
  },
  [HelmetPageType.LIVE_MUSIC]: {
    title: m.liveMusicTitle,
    description: m.liveMusicDescription,
  },
  [HelmetPageType.LIVE_NEARBY]: {
    title: m.liveNearbyTitle,
    description: m.liveNearbyDescription,
  },
  [HelmetPageType.LIVE_NEW]: {
    title: m.liveNewTitle,
    description: m.liveNewDescription,
  },
  [HelmetPageType.LIVE_PARTY]: {
    title: m.livePartyTitle,
    description: m.livePartyDescription,
  },
  [HelmetPageType.LIVE_PERSONAL]: {
    title: m.livePersonalTitle,
    description: m.livePersonalDescription,
  },
  [HelmetPageType.LIVE_FOLLOWING]: {
    title: m.livePersonalTitle,
    description: m.livePersonalDescription,
  },
  [HelmetPageType.LIVE_POPULAR]: {
    title: m.livePopularTitle,
    description: m.livePopularDescription,
  },
  [HelmetPageType.LIVE_RECOMMENDED]: {
    title: m.liveRecommendedTitle,
    description: m.liveRecommendedDescription,
  },
  [HelmetPageType.LIVE_RISING_STARS]: {
    title: m.liveRisingStarsTitle,
    description: m.liveRisingStarsDescription,
  },
  [HelmetPageType.LIVE_SOCIAL]: {
    title: m.liveSocialTitle,
    description: m.liveSocialDescription,
  },
  [HelmetPageType.LOYALTY]: {
    title: m.loyaltyTitle,
    description: m.loyaltyDescription,
  },
  [HelmetPageType.TANGO_OFFICIAL]: {
    title: m.tangoOfficialTitle,
    description: m.tangoOfficialDescription,
  },
  [HelmetPageType.TERMS_OF_USE]: {
    title: m.termsOfUseTitle,
    description: m.termsOfUseDescription,
  },
  [HelmetPageType.THIRD_PARTY_LICENSE]: {
    title: m.thirdPartyLicenseTitle,
    description: m.thirdPartyLicenseDescription,
  },
  [HelmetPageType.TIMELINE]: {
    title: m.timelineTitle,
    description: m.timelineDescription,
  },
  [HelmetPageType.MOMENTS]: {
    title: m.momentsTitle,
    description: m.momentsDescription,
  },
  [HelmetPageType.LEADERS]: {
    title: m.leadersTitle,
    description: m.leadersDescription,
  },
  [HelmetPageType.VOUCHER]: {
    title: m.voucherDescription,
    description: m.voucherDescription,
  },
  [HelmetPageType.REGISTRATION]: {
    title: m.registrationDescription,
    description: m.registrationDescription,
  },
};

const useHelmet = (pathname: string) => {
  if (!pathname) {
    return HELMETS[HelmetPageType.DEFAULT];
  }
  const helmetType = Object.values(HelmetPageType).find((type) =>
    pathname.includes(type)
  );

  return helmetType
    ? HELMETS[helmetType as HelmetPageType]
    : HELMETS[HelmetPageType.DEFAULT];
};

export default useHelmet;
