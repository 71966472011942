import { enhancedFetch } from "src/features/mos/imports/api";
import { getProxycadorHost } from "src/features/mos/imports/environment";
import { FetchMosV2LineupResponse } from "src/features/mos/types";

export const fetchMosV2Lineup = ({
  streamId,
  streamerId,
  iso2CountryCode,
}: {
  iso2CountryCode: string;
  streamId: string;
  streamerId: string;
}): Promise<FetchMosV2LineupResponse> =>
  enhancedFetch(
    `${getProxycadorHost()}/gift-box/public/v2/client/lineup?streamId=${streamId}&streamerId=${streamerId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "tg-loc-language": iso2CountryCode,
      },
    }
  ).then((resp) => resp.json());
