import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendAppeal } from "src/features/appeal/AppealPage/api/appeal";
import {
  AppealError,
  AppealExtraStatus,
  SendAppealParams,
} from "src/features/appeal/AppealPage/types";

interface SuccessData {
  status: AppealExtraStatus.SUBMITTED;
}

interface ErrorData {
  message: AppealError.SOMETHING_WENT_WRONG;
}

interface RejectData {
  rejectValue: ErrorData;
}

export const sendAppealAction = createAsyncThunk<
  SuccessData,
  SendAppealParams,
  RejectData
>("/appeal", async (params, { rejectWithValue }) => {
  try {
    await sendAppeal(params);

    return { status: AppealExtraStatus.SUBMITTED };
  } catch (error) {
    return rejectWithValue({ message: AppealError.SOMETHING_WENT_WRONG });
  }
});
