import Compressor from "compressorjs";

const THUMBNAIL_DIMEN = 600;
const IMAGE_MAX_SIZE = 1024;

const urlToBlob = async (dataURI: string) =>
  await fetch(dataURI).then((resp) => resp.blob());

export const loadImage = (imageUrl: string, isCrossOrigin = false) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;

    if (isCrossOrigin) {
      img.setAttribute("crossOrigin", "anonymous");
    }

    img.src = imageUrl;
  });

const resizeImage = async ({
  imageElement,
  capSize,
  // value between 0 and 1 https://github.com/fengyuanchen/compressorjs#quality
  quality,
}: {
  imageElement: HTMLImageElement;
  capSize: number;
  quality: number;
}) => {
  let width = imageElement.width;
  let height = imageElement.height;
  if (imageElement.width > capSize || imageElement.height > capSize) {
    if (imageElement.width > imageElement.height) {
      height = Math.floor((imageElement.height / imageElement.width) * capSize);
      width = capSize;
    } else {
      width = Math.floor((imageElement.width / imageElement.height) * capSize);
      height = capSize;
    }
  }
  const imgBlob = await urlToBlob(imageElement.src);
  try {
    const data = await new Promise<Blob>((resolve, reject) => {
      /* eslint-disable-next-line no-new */
      new Compressor(imgBlob, {
        quality,
        checkOrientation: true,
        width,
        height,
        success: resolve,
        error: reject,
      });
    });
    return { data, width, height };
  } catch (e) {
    return { data: imgBlob, width, height };
  }
};

export const prepareImageAndThumbnailPromise = (
  imageElement: HTMLImageElement
) =>
  Promise.all([
    resizeImage({ imageElement, capSize: IMAGE_MAX_SIZE, quality: 0.8 }),
    resizeImage({ imageElement, capSize: THUMBNAIL_DIMEN, quality: 0.8 }),
  ]).then(([{ data: image, width, height }, { data: thumbnail }]) => ({
    image,
    thumbnail,
    width,
    height,
  }));

export const prepareImageForUpload = (imageUrl: string) =>
  loadImage(imageUrl).then(async (imageElement) => ({
    ...(await prepareImageAndThumbnailPromise(imageElement)),
    imageElement,
  }));
