import {
  RealTimeRecommendationUnfollowSource,
  RealTimeRecommendationsUnfollowFromType,
} from "src/features/recommendations/common/enums";

export const mapUnfollowSourceToUnfollowFrom = (
  source?: number
): RealTimeRecommendationsUnfollowFromType => {
  switch (source) {
    case RealTimeRecommendationUnfollowSource.STREAMER_BLOCKED:
      return RealTimeRecommendationsUnfollowFromType.STREAMER_BLOCKED;

    default:
      return RealTimeRecommendationsUnfollowFromType.STREAMER_MINI_PROFILE;
  }
};
