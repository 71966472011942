/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.Subscription || ($protobuf.roots.Subscription = {});

export const Capabilities = $root.Capabilities = (() => {

    function Capabilities(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Capabilities.prototype.level = 0;
    Capabilities.prototype.hasHighlights = false;
    Capabilities.prototype.directSupport = false;
    Capabilities.prototype.specialStatus = false;

    Capabilities.create = function create(properties) {
        return new Capabilities(properties);
    };

    Capabilities.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.level);
        writer.uint32(16).bool(message.hasHighlights);
        writer.uint32(24).bool(message.directSupport);
        writer.uint32(32).bool(message.specialStatus);
        return writer;
    };

    Capabilities.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Capabilities.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Capabilities();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.level = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.hasHighlights = reader.bool();
                    break;
                }
            case 3: {
                    message.directSupport = reader.bool();
                    break;
                }
            case 4: {
                    message.specialStatus = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("level"))
            throw $util.ProtocolError("missing required 'level'", { instance: message });
        if (!message.hasOwnProperty("hasHighlights"))
            throw $util.ProtocolError("missing required 'hasHighlights'", { instance: message });
        if (!message.hasOwnProperty("directSupport"))
            throw $util.ProtocolError("missing required 'directSupport'", { instance: message });
        if (!message.hasOwnProperty("specialStatus"))
            throw $util.ProtocolError("missing required 'specialStatus'", { instance: message });
        return message;
    };

    Capabilities.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Capabilities.fromObject = function fromObject(object) {
        if (object instanceof $root.Capabilities)
            return object;
        let message = new $root.Capabilities();
        if (object.level != null)
            message.level = object.level | 0;
        if (object.hasHighlights != null)
            message.hasHighlights = Boolean(object.hasHighlights);
        if (object.directSupport != null)
            message.directSupport = Boolean(object.directSupport);
        if (object.specialStatus != null)
            message.specialStatus = Boolean(object.specialStatus);
        return message;
    };

    Capabilities.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.level = 0;
            object.hasHighlights = false;
            object.directSupport = false;
            object.specialStatus = false;
        }
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        if (message.hasHighlights != null && message.hasOwnProperty("hasHighlights"))
            object.hasHighlights = message.hasHighlights;
        if (message.directSupport != null && message.hasOwnProperty("directSupport"))
            object.directSupport = message.directSupport;
        if (message.specialStatus != null && message.hasOwnProperty("specialStatus"))
            object.specialStatus = message.specialStatus;
        return object;
    };

    Capabilities.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Capabilities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Capabilities";
    };

    return Capabilities;
})();

export const ProfileInfo = $root.ProfileInfo = (() => {

    function ProfileInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ProfileInfo.prototype.accountId = "";
    ProfileInfo.prototype.firstName = "";
    ProfileInfo.prototype.lastName = "";
    ProfileInfo.prototype.pictureUrl = "";
    ProfileInfo.prototype.thumbnailUrl = "";

    ProfileInfo.create = function create(properties) {
        return new ProfileInfo(properties);
    };

    ProfileInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.accountId);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(18).string(message.firstName);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(26).string(message.lastName);
        if (message.pictureUrl != null && Object.hasOwnProperty.call(message, "pictureUrl"))
            writer.uint32(34).string(message.pictureUrl);
        if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
            writer.uint32(42).string(message.thumbnailUrl);
        return writer;
    };

    ProfileInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ProfileInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProfileInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.accountId = reader.string();
                    break;
                }
            case 2: {
                    message.firstName = reader.string();
                    break;
                }
            case 3: {
                    message.lastName = reader.string();
                    break;
                }
            case 4: {
                    message.pictureUrl = reader.string();
                    break;
                }
            case 5: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("accountId"))
            throw $util.ProtocolError("missing required 'accountId'", { instance: message });
        return message;
    };

    ProfileInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ProfileInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.ProfileInfo)
            return object;
        let message = new $root.ProfileInfo();
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.pictureUrl != null)
            message.pictureUrl = String(object.pictureUrl);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        return message;
    };

    ProfileInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.accountId = "";
            object.firstName = "";
            object.lastName = "";
            object.pictureUrl = "";
            object.thumbnailUrl = "";
        }
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.pictureUrl != null && message.hasOwnProperty("pictureUrl"))
            object.pictureUrl = message.pictureUrl;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        return object;
    };

    ProfileInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ProfileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProfileInfo";
    };

    return ProfileInfo;
})();

export const StreamerSubscriptionDetails = $root.StreamerSubscriptionDetails = (() => {

    function StreamerSubscriptionDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamerSubscriptionDetails.prototype.streamerProfile = null;
    StreamerSubscriptionDetails.prototype.level = 0;

    StreamerSubscriptionDetails.create = function create(properties) {
        return new StreamerSubscriptionDetails(properties);
    };

    StreamerSubscriptionDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.streamerProfile != null && Object.hasOwnProperty.call(message, "streamerProfile"))
            $root.ProfileInfo.encode(message.streamerProfile, writer.uint32(10).fork()).ldelim();
        if (message.level != null && Object.hasOwnProperty.call(message, "level"))
            writer.uint32(21).sfixed32(message.level);
        return writer;
    };

    StreamerSubscriptionDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamerSubscriptionDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamerSubscriptionDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.streamerProfile = $root.ProfileInfo.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.level = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamerSubscriptionDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamerSubscriptionDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamerSubscriptionDetails)
            return object;
        let message = new $root.StreamerSubscriptionDetails();
        if (object.streamerProfile != null) {
            if (typeof object.streamerProfile !== "object")
                throw TypeError(".StreamerSubscriptionDetails.streamerProfile: object expected");
            message.streamerProfile = $root.ProfileInfo.fromObject(object.streamerProfile);
        }
        if (object.level != null)
            message.level = object.level | 0;
        return message;
    };

    StreamerSubscriptionDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.streamerProfile = null;
            object.level = 0;
        }
        if (message.streamerProfile != null && message.hasOwnProperty("streamerProfile"))
            object.streamerProfile = $root.ProfileInfo.toObject(message.streamerProfile, options);
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        return object;
    };

    StreamerSubscriptionDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamerSubscriptionDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamerSubscriptionDetails";
    };

    return StreamerSubscriptionDetails;
})();

export const Subscription = $root.Subscription = (() => {

    function Subscription(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Subscription.prototype.id = "";
    Subscription.prototype.status = 1;
    Subscription.prototype.subscriberProfile = null;
    Subscription.prototype.externalOfferId = "";
    Subscription.prototype.startedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.endedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.details = null;
    Subscription.prototype.isRenew = false;
    Subscription.prototype.points = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.credits = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Subscription.prototype.origin = 1;
    Subscription.prototype.times = 0;

    Subscription.create = function create(properties) {
        return new Subscription(properties);
    };

    Subscription.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        writer.uint32(16).int32(message.status);
        if (message.subscriberProfile != null && Object.hasOwnProperty.call(message, "subscriberProfile"))
            $root.ProfileInfo.encode(message.subscriberProfile, writer.uint32(26).fork()).ldelim();
        if (message.externalOfferId != null && Object.hasOwnProperty.call(message, "externalOfferId"))
            writer.uint32(34).string(message.externalOfferId);
        if (message.startedAt != null && Object.hasOwnProperty.call(message, "startedAt"))
            writer.uint32(41).sfixed64(message.startedAt);
        if (message.endedAt != null && Object.hasOwnProperty.call(message, "endedAt"))
            writer.uint32(49).sfixed64(message.endedAt);
        if (message.details != null && Object.hasOwnProperty.call(message, "details"))
            $root.SubscriptionDetails.encode(message.details, writer.uint32(58).fork()).ldelim();
        if (message.isRenew != null && Object.hasOwnProperty.call(message, "isRenew"))
            writer.uint32(64).bool(message.isRenew);
        if (message.points != null && Object.hasOwnProperty.call(message, "points"))
            writer.uint32(73).sfixed64(message.points);
        if (message.credits != null && Object.hasOwnProperty.call(message, "credits"))
            writer.uint32(81).sfixed64(message.credits);
        if (message.origin != null && Object.hasOwnProperty.call(message, "origin"))
            writer.uint32(88).int32(message.origin);
        if (message.times != null && Object.hasOwnProperty.call(message, "times"))
            writer.uint32(101).sfixed32(message.times);
        return writer;
    };

    Subscription.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Subscription.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Subscription();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.status = reader.int32();
                    break;
                }
            case 3: {
                    message.subscriberProfile = $root.ProfileInfo.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.externalOfferId = reader.string();
                    break;
                }
            case 5: {
                    message.startedAt = reader.sfixed64();
                    break;
                }
            case 6: {
                    message.endedAt = reader.sfixed64();
                    break;
                }
            case 7: {
                    message.details = $root.SubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.isRenew = reader.bool();
                    break;
                }
            case 9: {
                    message.points = reader.sfixed64();
                    break;
                }
            case 10: {
                    message.credits = reader.sfixed64();
                    break;
                }
            case 11: {
                    message.origin = reader.int32();
                    break;
                }
            case 12: {
                    message.times = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("status"))
            throw $util.ProtocolError("missing required 'status'", { instance: message });
        return message;
    };

    Subscription.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Subscription.fromObject = function fromObject(object) {
        if (object instanceof $root.Subscription)
            return object;
        let message = new $root.Subscription();
        if (object.id != null)
            message.id = String(object.id);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "ACTIVE":
        case 1:
            message.status = 1;
            break;
        case "CANCELLED":
        case 2:
            message.status = 2;
            break;
        case "PENDING":
        case 3:
            message.status = 3;
            break;
        case "EXPIRED":
        case 4:
            message.status = 4;
            break;
        case "RENEWING":
        case 5:
            message.status = 5;
            break;
        case "HOLD":
        case 6:
            message.status = 6;
            break;
        case "PREAPPROVED":
        case 7:
            message.status = 7;
            break;
        case "FREE_TRIAL":
        case 8:
            message.status = 8;
            break;
        case "GRACE_PERIOD":
        case 9:
            message.status = 9;
            break;
        }
        if (object.subscriberProfile != null) {
            if (typeof object.subscriberProfile !== "object")
                throw TypeError(".Subscription.subscriberProfile: object expected");
            message.subscriberProfile = $root.ProfileInfo.fromObject(object.subscriberProfile);
        }
        if (object.externalOfferId != null)
            message.externalOfferId = String(object.externalOfferId);
        if (object.startedAt != null)
            if ($util.Long)
                (message.startedAt = $util.Long.fromValue(object.startedAt)).unsigned = false;
            else if (typeof object.startedAt === "string")
                message.startedAt = parseInt(object.startedAt, 10);
            else if (typeof object.startedAt === "number")
                message.startedAt = object.startedAt;
            else if (typeof object.startedAt === "object")
                message.startedAt = new $util.LongBits(object.startedAt.low >>> 0, object.startedAt.high >>> 0).toNumber();
        if (object.endedAt != null)
            if ($util.Long)
                (message.endedAt = $util.Long.fromValue(object.endedAt)).unsigned = false;
            else if (typeof object.endedAt === "string")
                message.endedAt = parseInt(object.endedAt, 10);
            else if (typeof object.endedAt === "number")
                message.endedAt = object.endedAt;
            else if (typeof object.endedAt === "object")
                message.endedAt = new $util.LongBits(object.endedAt.low >>> 0, object.endedAt.high >>> 0).toNumber();
        if (object.details != null) {
            if (typeof object.details !== "object")
                throw TypeError(".Subscription.details: object expected");
            message.details = $root.SubscriptionDetails.fromObject(object.details);
        }
        if (object.isRenew != null)
            message.isRenew = Boolean(object.isRenew);
        if (object.points != null)
            if ($util.Long)
                (message.points = $util.Long.fromValue(object.points)).unsigned = false;
            else if (typeof object.points === "string")
                message.points = parseInt(object.points, 10);
            else if (typeof object.points === "number")
                message.points = object.points;
            else if (typeof object.points === "object")
                message.points = new $util.LongBits(object.points.low >>> 0, object.points.high >>> 0).toNumber();
        if (object.credits != null)
            if ($util.Long)
                (message.credits = $util.Long.fromValue(object.credits)).unsigned = false;
            else if (typeof object.credits === "string")
                message.credits = parseInt(object.credits, 10);
            else if (typeof object.credits === "number")
                message.credits = object.credits;
            else if (typeof object.credits === "object")
                message.credits = new $util.LongBits(object.credits.low >>> 0, object.credits.high >>> 0).toNumber();
        switch (object.origin) {
        default:
            if (typeof object.origin === "number") {
                message.origin = object.origin;
                break;
            }
            break;
        case "GOOGLE":
        case 1:
            message.origin = 1;
            break;
        case "APPLE":
        case 2:
            message.origin = 2;
            break;
        case "WEB":
        case 3:
            message.origin = 3;
            break;
        case "CREDITS":
        case 4:
            message.origin = 4;
            break;
        }
        if (object.times != null)
            message.times = object.times | 0;
        return message;
    };

    Subscription.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.status = options.enums === String ? "ACTIVE" : 1;
            object.subscriberProfile = null;
            object.externalOfferId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.startedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.startedAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.endedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.endedAt = options.longs === String ? "0" : 0;
            object.details = null;
            object.isRenew = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.points = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.points = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.credits = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.credits = options.longs === String ? "0" : 0;
            object.origin = options.enums === String ? "GOOGLE" : 1;
            object.times = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.SubscriptionStatus[message.status] === undefined ? message.status : $root.SubscriptionStatus[message.status] : message.status;
        if (message.subscriberProfile != null && message.hasOwnProperty("subscriberProfile"))
            object.subscriberProfile = $root.ProfileInfo.toObject(message.subscriberProfile, options);
        if (message.externalOfferId != null && message.hasOwnProperty("externalOfferId"))
            object.externalOfferId = message.externalOfferId;
        if (message.startedAt != null && message.hasOwnProperty("startedAt"))
            if (typeof message.startedAt === "number")
                object.startedAt = options.longs === String ? String(message.startedAt) : message.startedAt;
            else
                object.startedAt = options.longs === String ? $util.Long.prototype.toString.call(message.startedAt) : options.longs === Number ? new $util.LongBits(message.startedAt.low >>> 0, message.startedAt.high >>> 0).toNumber() : message.startedAt;
        if (message.endedAt != null && message.hasOwnProperty("endedAt"))
            if (typeof message.endedAt === "number")
                object.endedAt = options.longs === String ? String(message.endedAt) : message.endedAt;
            else
                object.endedAt = options.longs === String ? $util.Long.prototype.toString.call(message.endedAt) : options.longs === Number ? new $util.LongBits(message.endedAt.low >>> 0, message.endedAt.high >>> 0).toNumber() : message.endedAt;
        if (message.details != null && message.hasOwnProperty("details"))
            object.details = $root.SubscriptionDetails.toObject(message.details, options);
        if (message.isRenew != null && message.hasOwnProperty("isRenew"))
            object.isRenew = message.isRenew;
        if (message.points != null && message.hasOwnProperty("points"))
            if (typeof message.points === "number")
                object.points = options.longs === String ? String(message.points) : message.points;
            else
                object.points = options.longs === String ? $util.Long.prototype.toString.call(message.points) : options.longs === Number ? new $util.LongBits(message.points.low >>> 0, message.points.high >>> 0).toNumber() : message.points;
        if (message.credits != null && message.hasOwnProperty("credits"))
            if (typeof message.credits === "number")
                object.credits = options.longs === String ? String(message.credits) : message.credits;
            else
                object.credits = options.longs === String ? $util.Long.prototype.toString.call(message.credits) : options.longs === Number ? new $util.LongBits(message.credits.low >>> 0, message.credits.high >>> 0).toNumber() : message.credits;
        if (message.origin != null && message.hasOwnProperty("origin"))
            object.origin = options.enums === String ? $root.SubscriptionOrigin[message.origin] === undefined ? message.origin : $root.SubscriptionOrigin[message.origin] : message.origin;
        if (message.times != null && message.hasOwnProperty("times"))
            object.times = message.times;
        return object;
    };

    Subscription.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Subscription.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Subscription";
    };

    return Subscription;
})();

export const SubscriptionDetails = $root.SubscriptionDetails = (() => {

    function SubscriptionDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionDetails.prototype.streamer = null;

    SubscriptionDetails.create = function create(properties) {
        return new SubscriptionDetails(properties);
    };

    SubscriptionDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.streamer != null && Object.hasOwnProperty.call(message, "streamer"))
            $root.StreamerSubscriptionDetails.encode(message.streamer, writer.uint32(10).fork()).ldelim();
        return writer;
    };

    SubscriptionDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.streamer = $root.StreamerSubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionDetails)
            return object;
        let message = new $root.SubscriptionDetails();
        if (object.streamer != null) {
            if (typeof object.streamer !== "object")
                throw TypeError(".SubscriptionDetails.streamer: object expected");
            message.streamer = $root.StreamerSubscriptionDetails.fromObject(object.streamer);
        }
        return message;
    };

    SubscriptionDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.streamer = null;
        if (message.streamer != null && message.hasOwnProperty("streamer"))
            object.streamer = $root.StreamerSubscriptionDetails.toObject(message.streamer, options);
        return object;
    };

    SubscriptionDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionDetails";
    };

    return SubscriptionDetails;
})();

export const SubscriptionStatus = $root.SubscriptionStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "ACTIVE"] = 1;
    values[valuesById[2] = "CANCELLED"] = 2;
    values[valuesById[3] = "PENDING"] = 3;
    values[valuesById[4] = "EXPIRED"] = 4;
    values[valuesById[5] = "RENEWING"] = 5;
    values[valuesById[6] = "HOLD"] = 6;
    values[valuesById[7] = "PREAPPROVED"] = 7;
    values[valuesById[8] = "FREE_TRIAL"] = 8;
    values[valuesById[9] = "GRACE_PERIOD"] = 9;
    return values;
})();

export const SubscriptionCategory = $root.SubscriptionCategory = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "STREAMER"] = 1;
    values[valuesById[2] = "VIP"] = 2;
    return values;
})();

export const SubscriptionOrigin = $root.SubscriptionOrigin = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "GOOGLE"] = 1;
    values[valuesById[2] = "APPLE"] = 2;
    values[valuesById[3] = "WEB"] = 3;
    values[valuesById[4] = "CREDITS"] = 4;
    return values;
})();

export { $root as default };
