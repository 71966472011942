/* eslint-disable no-restricted-imports, no-console, @typescript-eslint/no-explicit-any */
import { ComponentType, lazy } from "react";

const lazyWithPageReload = <T extends ComponentType<any>>(
  loader: () => Promise<{ default: T }>
) =>
  lazy(
    () =>
      loader().catch((e) => {
        console.error("lazy loader error:", e);
        if (process.env.NODE_ENV !== "development") {
          // dev check is to prevent infinite page reload on module build failure
          window.location.reload();
        }
      }) as Promise<{ default: T }>
  );

export default lazyWithPageReload;
