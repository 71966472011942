import {
  ReferralType,
  ReferredUsersOrder,
  ReferredUsersSortBy,
} from "src/features/referralProgram/common/enums";
import {
  ReferredUsersListRequest,
  TierCriteriaKey,
} from "src/features/referralProgram/common/types";
import { sharedMessages } from "src/features/referralProgram/imports/messages";
import { linkToReferralProgram } from "src/features/referralProgram/imports/navigation";

export const DEFAULT_PAGE_SIZE = 16;

export const DEFAULT_REFERRED_USERS_LIST_REQUEST: ReferredUsersListRequest = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  order: ReferredUsersOrder.DESC,
  sortBy: ReferredUsersSortBy.EARNED_POINTS,
};

export const TOP_USERS_NUM_MOBILE = 12;
export const TOP_USERS_NUM_DESKTOP = 7;

export const linkToHowItWorks = `${linkToReferralProgram}/how-it-works`;
export const linkToAgencyTiers = `${linkToReferralProgram}/agency-tiers`;
export const linkToUserList = `${linkToReferralProgram}/users`;

export const REFERRAL_TYPE_MAP = {
  [ReferralType.CREATORS]: {
    label: sharedMessages.creators,
    value: ReferralType.CREATORS,
  },
  [ReferralType.SUPPORTERS]: {
    label: sharedMessages.supporters,
    value: ReferralType.SUPPORTERS,
  },
};

export const REFERRAL_TYPE_OPTIONS = Object.values(REFERRAL_TYPE_MAP);

export const TIER_CRITERIA_KEYS: TierCriteriaKey[] = [
  "firstTimeRedeemersCriterion",
  "revenueCriterion",
  "revenueGrowthCriterion",
];

export const NUMBER_OF_DAYS = 10;

export const AGENCY_RESOURCES_BASE_URL =
  "https://resources.tango.me/web/agencies-assets/agencyIcons/withShadow";
