import { LiveFeedType } from "src/enums";
import { linkToBasePath, linkToRecommended } from "ui/navigation/links";

export const getExploreNavigationMode = (
  currentRoute: string,
  defaultModeValue: LiveFeedType
): LiveFeedType =>
  currentRoute === linkToBasePath || currentRoute === linkToRecommended
    ? LiveFeedType.RECOMMENDED
    : defaultModeValue;
