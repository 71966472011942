import { datadogRum } from "@datadog/browser-rum";
import {
  getDatadogAccountIdsWhitelist,
  getDatadogApiKey,
  getDatadogApplicationId,
  getDatadogEnv,
  getDatadogSessionReplaySampleRate,
  getDatadogSessionSampleRate,
  getDatadogSite,
} from "environment";

const setupDatadogRUM = (accountId: string) => {
  const clientToken = getDatadogApiKey();
  const applicationId = getDatadogApplicationId();
  const site = getDatadogSite();
  const env = getDatadogEnv();

  if (!clientToken || !site || !applicationId) {
    return;
  }

  const accountIdsWhitelist = getDatadogAccountIdsWhitelist();

  let sessionSampleRate;
  let sessionReplaySampleRate;

  if (accountIdsWhitelist?.includes(accountId)) {
    sessionSampleRate = 100;
    sessionReplaySampleRate = 100;
  } else {
    sessionSampleRate = getDatadogSessionSampleRate();
    sessionReplaySampleRate = getDatadogSessionReplaySampleRate();
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service: "lwc-b2c",
    env,
    version: twcVersion,
    sessionSampleRate,
    sessionReplaySampleRate,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
};

export default setupDatadogRUM;
