import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { MessageConfig } from "chat/components/currentConversation/Message";
import {
  useBreakpoint,
  useBreakpointMobileLayout,
  useLazyBackgroundImageImmutable,
  useMount,
} from "chat/imports/hooks";
import { parseMessageFromBase64 } from "chat/imports/proto";
import { RootState, userSelectors } from "chat/imports/state";
import { AccountInfo } from "chat/imports/types";
import { VoidCallback } from "chat/messageRequest/imports/types";
import {
  PremiumMessageDisplayResult,
  emitPremiumMessageDisplay,
} from "chat/premiumMessage/analytics/emitPremiumMessageDisplay";
import { unlockPremiumMessageFlow } from "chat/premiumMessage/state/flow";
import { premiumMessageSelectors } from "chat/premiumMessage/state/selectors";
import {
  ContentSharedPayloadMessage,
  ContentStatus,
} from "chat/premiumMessage/types";
import { StoredMessage } from "chat/state/reducer";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { ContentSharedPayload } from "generated/proto/PremiumMessage";
import SenderImageMessage from "./messages/SenderImageMessage";
import SenderVideoMessage from "./messages/SenderVideoMessage";
import ViewerUnlockedMessage from "./messages/ViewerUnlockedMessage";
import { LockedMessageOverlay } from "./overlays/LockedMessageOverlay";
import styles from "./PremiumMessage.scss";

const createSelector = (messageId: string) => (state: RootState) => ({
  myAccountId: userSelectors.getMyAccountId(state),
  isUnlockPending: premiumMessageSelectors.getPending(state, messageId),
  detail: premiumMessageSelectors.getDetail(state, messageId),
});

interface PremiumMessageProps {
  accountInfo?: AccountInfo;
  conversationId: string;
  message: StoredMessage;
  messageConfig: MessageConfig;
  onHeightUpdate: VoidCallback;
}

const PremiumMessage: FC<PremiumMessageProps> = ({
  message,
  conversationId,
  accountInfo,
  onHeightUpdate,
  messageConfig,
}) => {
  const dispatch = useDispatch();

  const { messageId, giftId, thumbnailBlurUrl, mediaInfo } = useMemo(
    () =>
      parseMessageFromBase64(
        message.payload,
        ContentSharedPayload
      ) as ContentSharedPayloadMessage,
    [message.payload]
  );

  const { myAccountId, isUnlockPending, detail } = useSelector(
    createSelector(messageId),
    shallowEqual
  );

  const { duration } = mediaInfo;

  const ref = useRef<HTMLDivElement>(null);

  const isMountedRef = useRef(false);

  useLazyBackgroundImageImmutable(ref);

  const breakpoint = useBreakpoint();

  const isMobile = useBreakpointMobileLayout();

  useEffect(() => {
    if (isMountedRef.current) {
      onHeightUpdate();
    }

    isMountedRef.current = true;
  }, [isUnlockPending, onHeightUpdate]);

  const handleUnlock = useCallback(() => {
    if (accountInfo && detail) {
      dispatch(
        unlockPremiumMessageFlow({
          isMobile,
          giftId,
          messageId,
          contentType: detail.type,
          conversationId,
          recipientId: conversationId,
        })
      );
    }
  }, [
    dispatch,
    detail,
    accountInfo,
    isMobile,
    giftId,
    conversationId,
    messageId,
  ]);

  const isFromSender = myAccountId === message.from;
  const isUnlocked = detail?.status === ContentStatus.UNLOCKED;

  useMount(() => {
    const isViewer = !isFromSender;

    if (isViewer && message.from) {
      emitPremiumMessageDisplay({
        accountId: myAccountId,
        chatId: conversationId,
        messageId,
        recipientAccountId: message.from,
        result: PremiumMessageDisplayResult.DISPLAYED,
      });
    }
  });

  if (message.isPending || message.error) {
    return Number(duration) > 0 ? (
      <SenderVideoMessage
        giftId={giftId}
        status={ContentStatus.LOCKED}
        mediaInfo={mediaInfo}
        messageConfig={messageConfig}
      />
    ) : (
      <SenderImageMessage
        giftId={giftId}
        status={ContentStatus.LOCKED}
        mediaInfo={mediaInfo}
        messageConfig={messageConfig}
      />
    );
  }
  if (isFromSender) {
    const media = detail?.mediaInfo || mediaInfo;
    const status = detail?.status || ContentStatus.LOCKED;

    return Number(duration) > 0 ? (
      <SenderVideoMessage
        giftId={giftId}
        status={status}
        mediaInfo={media}
        messageConfig={messageConfig}
      />
    ) : (
      <SenderImageMessage
        giftId={giftId}
        status={status}
        mediaInfo={media}
        messageConfig={messageConfig}
      />
    );
  }

  if (isUnlocked) {
    return (
      <ViewerUnlockedMessage detail={detail} messageConfig={messageConfig} />
    );
  }

  return (
    <div
      ref={ref}
      data-lazybg={thumbnailBlurUrl}
      data-testid="premium-message-viewer-locked"
      className={classnames(
        styles.root,
        styles[breakpoint],
        getGroupMessageClassnames(styles, messageConfig)
      )}
    >
      <LockedMessageOverlay
        duration={duration}
        message={message}
        giftId={giftId}
        conversationId={conversationId}
        accountInfo={accountInfo}
        isLoading={isUnlockPending}
        onUnlock={handleUnlock}
        messageConfig={messageConfig}
      />
    </div>
  );
};

export default memo(PremiumMessage);
