import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkAppeal } from "src/features/appeal/AppealPage/api/appeal";
import { SECOND } from "src/features/appeal/AppealPage/imports/constants";
import { AppealState } from "src/features/appeal/AppealPage/state/appealTypes";
import {
  AppealError,
  AppealStatus,
  CheckAppealParams,
} from "src/features/appeal/AppealPage/types";
import { parseToken } from "src/features/appeal/AppealPage/utils/parseToken";

interface SuccessData {
  status: AppealState["status"];
  tokenData: NonNullable<AppealState["tokenData"]>;
}

interface ErrorData {
  message: AppealError;
}

interface RejectData {
  rejectValue: ErrorData;
}

export const checkAppealAction = createAsyncThunk<
  SuccessData,
  CheckAppealParams,
  RejectData
>("/appeal/check", async ({ token }, { rejectWithValue }) => {
  try {
    if (!token) {
      return rejectWithValue({ message: AppealError.EXPIRED });
    }

    const tokenData = parseToken(token);
    if (!tokenData) {
      return rejectWithValue({ message: AppealError.EXPIRED });
    }

    const { exp } = tokenData;
    if (exp * SECOND < Date.now()) {
      return rejectWithValue({ message: AppealError.EXPIRED });
    }

    const { status } = await checkAppeal({ token });

    switch (status) {
      case AppealStatus.APPEAL_APPROVED:
      case AppealStatus.APPEAL_REJECTED:
        return { status, tokenData };
      case AppealStatus.BAD_TOKEN:
        return rejectWithValue({ message: AppealError.EXPIRED });
      default:
        return { status: null, tokenData };
    }
  } catch (error) {
    return rejectWithValue({ message: AppealError.SOMETHING_WENT_WRONG });
  }
});
