import React, { FC, ReactElement } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/features/signin/imports/enums";
import {
  TYPOGRAPHY_TYPE,
  Typography,
  useBreakpoint,
} from "src/features/signin/imports/ui";
import styles from "./Title.scss";

interface TitleProps {
  description?: ReactElement;
  isError?: boolean;
  title: ReactElement;
}

const TITLE_TYPOGRAPHY_TYPE = {
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HERO3,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HERO3,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HERO4,
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HERO4,
};

const Title: FC<TitleProps> = ({ title, description, isError = false }) => {
  const breakpoint = useBreakpoint();

  if (isError) {
    return <div className={styles.container} />;
  }

  return (
    <div className={classnames(styles.container, styles[breakpoint])}>
      <Typography type={TITLE_TYPOGRAPHY_TYPE[breakpoint]}>{title}</Typography>
      {description && (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
          className={styles.description}
        >
          {description}
        </Typography>
      )}
    </div>
  );
};

export default Title;
