import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { RestrictedLayout } from "src/features/ofac/components/RestrictedLayout";
import { IconRestricted } from "src/features/ofac/imports/img";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
  linkToTermsOfUse,
  useBreakpointPrecise,
} from "src/features/ofac/imports/ui";
import { ofacMessages } from "src/features/ofac/ofacMessages";
import styles from "./RestrictedPage.scss";

export const RestrictedPage: FC = () => {
  const breakpoint = useBreakpointPrecise();

  return (
    <RestrictedLayout className={classnames(styles[breakpoint], styles.page)}>
      <div className={styles.mainWrapper}>
        <main>
          <IconRestricted className={styles.mainIcon} />

          <Typography
            as="h1"
            type={TYPOGRAPHY_TYPE.HEADLINE3}
            className={styles.mainHeading}
          >
            <FormattedMessage {...ofacMessages.websiteNotAvailable} />
          </Typography>

          <Typography
            as="p"
            type={TYPOGRAPHY_TYPE.PARAGRAPH3}
            className={styles.mainParagraph}
          >
            <FormattedMessage {...ofacMessages.tangoUnavailableInYourCountry} />{" "}
            <FormattedMessage {...ofacMessages.readTermsAndConditions} />
          </Typography>
        </main>

        <Button
          size={ButtonSize.BIG_48}
          to={linkToTermsOfUse}
          variant={ButtonVariant.SECONDARY}
        >
          <Typography type={TYPOGRAPHY_TYPE.HEADLINE4}>
            <FormattedMessage {...ofacMessages.termsAndConditions} />
          </Typography>
        </Button>
      </div>
    </RestrictedLayout>
  );
};
