import { SINGLE_PROFILE_END_FETCH } from "src/features/signin/imports/actionTypes";
import { LoginResult } from "src/features/signin/imports/enums";
import {
  ADD_LOGIN_PROVIDER,
  AUTHORIZATION_END,
  AUTHORIZATION_START,
  LOGIN_CANCEL,
  LOGIN_CONTINUE_WITH_PHONE,
  LOGIN_END,
  LOGIN_ENTRY_POINT,
  LOGIN_HIDE_IN_PROGRESS_STATE,
  LOGIN_PROVIDERS_PREPARATION_ENDED,
  LOGIN_PROVIDERS_PREPARATION_STARTED,
  LOGIN_SELECT_COUNTRY,
  LOGIN_START,
  LOGIN_WAITING_FOR_CODE,
  LOGOUT_END,
  LOGOUT_START,
  MARK_SESSION_LIMITED,
  PHONE_LOGIN_ATTEMPT_LIMIT_REACHED,
  PHONE_LOGIN_METHOD_UNAVAILABLE_FOR_REGISTERED_USER,
  PHONE_LOGIN_PHONE_NUMBER_SPECIFIED,
  PHONE_LOGIN_PHONE_VERIFICATION_UNAVAILABLE,
  PHONE_LOGIN_RESET,
  PHONE_LOGIN_SET_COUNTRY_INFO,
  PHONE_LOGIN_SET_PREFERS_SMS,
  PHONE_LOGIN_SET_SMS_RESEND_ALLOWED_TIMESTAMP,
  PHONE_LOGIN_VERIFICATION_CODE_SPECIFIED,
  PHONE_LOGIN_VERIFICATION_FAILED,
  PHONE_LOGIN_VERIFICATION_REQUIRED,
  PHONE_LOGIN_VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE,
  SET_REFERRER_ACCOUNT_ID,
} from "src/features/signin/state/login/actionTypes";

const initialState = {
  loggedIn: false,
  authorized: false,
  isLimitedSession: false,
  loginInProgress: false,
  logoutInProgress: false,
  authorizationInProgress: false,
  loginFailed: false,
  errorMessage: null,
  authorizationFailed: false,
  defaultCountryCode: "+1",
  providers: [],
  loggedInProvider: null,
  phoneNumberAuthenticationState: {},
  resendAllowedTimestamps: {},
  isPreparingProviders: false,
  referrerAccountId: undefined,
  country: {},
  loginEntryPoint: null,
};

export const persistConfig = {
  whitelist: [
    "authorized",
    "loggedIn",
    "isLimitedSession",
    "loggedInProvider",
    "defaultCountryCode",
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START: {
      return {
        ...state,
        loggedIn: false,
        loginFailed: false,
        errorMessage: null,
        loginInProgress: true,
      };
    }
    case LOGIN_END: {
      if (action.error) {
        return {
          ...state,
          loggedIn: false,
          loginFailed: true,
          errorMessage: action.payload,
          loginInProgress: false,
          phoneNumberAuthenticationState: {},
        };
      }

      return {
        ...state,
        loggedIn: true,
        loginInProgress: false,
        isLimitedSession: action.payload.isLimitedSession,
        loggedInProvider: action.payload.provider,
        phoneNumberAuthenticationState: {},
      };
    }
    case LOGIN_CANCEL: {
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          attemptLimitReached: false,
        },
        loginFailed: false,
        errorMessage: null,
      };
    }
    case MARK_SESSION_LIMITED: {
      const { isSessionLimited } = action.payload;

      return {
        ...state,
        isLimitedSession: isSessionLimited,
      };
    }
    case AUTHORIZATION_START: {
      return { ...state, authorizationInProgress: true };
    }
    case AUTHORIZATION_END: {
      return {
        ...state,
        loggedIn: false,
        authorized: !action.error,
        isLimitedSession:
          action.payload.connectionManagerData?.loginResult ===
          LoginResult.LOGGED_IN_LIMITED,
        authorizationFailed: !!action.error,
        authorizationInProgress: false,
      };
    }
    case LOGOUT_START: {
      return { ...state, logoutInProgress: true };
    }
    case LOGOUT_END: {
      if (action.error) {
        return { ...state, logoutInProgress: false };
      }

      return {
        ...state,
        loggedIn: false,
        logoutInProgress: false,
        loggedInProvider: null,
        isLimitedSession: false,
        authorized: false,
      };
    }
    case PHONE_LOGIN_PHONE_VERIFICATION_UNAVAILABLE: {
      return {
        ...state,
        loginInProgress: false,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          phoneVerificationUnavailable: true,
        },
      };
    }
    case PHONE_LOGIN_ATTEMPT_LIMIT_REACHED: {
      return {
        ...state,
        loginInProgress: false,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          attemptLimitReached: true,
        },
      };
    }
    case PHONE_LOGIN_SET_SMS_RESEND_ALLOWED_TIMESTAMP: {
      return {
        ...state,
        loginInProgress: false,
        resendAllowedTimestamps: {
          ...state.resendAllowedTimestamps,
          [state.phoneNumberAuthenticationState.phoneNumber]:
            action.resendAllowedTimestamp,
        },
      };
    }
    case PHONE_LOGIN_VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE: {
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          verificationWithoutSMS: true,
        },
      };
    }
    case PHONE_LOGIN_VERIFICATION_REQUIRED: {
      return {
        ...state,
        loginInProgress: false,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          continueWithPhone: true,
          waitingForCode: true,
          allowOnlySmsValidation:
            !action.payload.deliveryMethod ||
            action.payload.deliveryMethod === "SMS",
          codeProvider: action.payload.codeProvider,
        },
      };
    }
    case PHONE_LOGIN_METHOD_UNAVAILABLE_FOR_REGISTERED_USER: {
      return {
        ...state,
        loginInProgress: false,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          continueWithPhone: true,
          waitingForCode: true,
          verificationWithoutSMS: true,
        },
      };
    }
    case PHONE_LOGIN_VERIFICATION_FAILED: {
      return {
        ...state,
        loginInProgress: false,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          verificationCode: null,
          errorMessage: action.errorMessage,
          continueWithPhone: true,
          waitingForCode: true,
        },
      };
    }
    case LOGIN_CONTINUE_WITH_PHONE:
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          continueWithPhone: true,
        },
      };
    case LOGIN_SELECT_COUNTRY:
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          selectCountry: action.payload,
        },
      };
    case LOGIN_WAITING_FOR_CODE:
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          waitingForCode: action.payload,
        },
      };
    case LOGIN_ENTRY_POINT:
      return {
        ...state,
        loginEntryPoint: action.payload,
      };
    case PHONE_LOGIN_SET_COUNTRY_INFO:
      return {
        ...state,
        country: {
          countryCode: action.countryCode,
          countryIcon: action.countryIcon,
        },
      };
    case PHONE_LOGIN_PHONE_NUMBER_SPECIFIED:
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          phoneNumber: action.phoneNumber,
        },
      };
    case PHONE_LOGIN_VERIFICATION_CODE_SPECIFIED:
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          verificationCode: action.verificationCode,
        },
      };
    case PHONE_LOGIN_SET_PREFERS_SMS:
      return {
        ...state,
        phoneNumberAuthenticationState: {
          ...state.phoneNumberAuthenticationState,
          allowOnlySmsValidation: true,
        },
      };
    case PHONE_LOGIN_RESET:
      return {
        ...state,
        phoneNumberAuthenticationState: {},
        loginFailed: false,
        errorMessage: null,
      };
    case SINGLE_PROFILE_END_FETCH: {
      if (action.error || !action.meta.isMe || !action.payload.profileDetails) {
        break;
      }
      const {
        profileDetails: { phoneCountryCode },
      } = action.payload;
      if (phoneCountryCode && parseInt(phoneCountryCode)) {
        return {
          ...state,
          defaultCountryCode: `+${phoneCountryCode}`,
        };
      }

      return state;
    }
    case ADD_LOGIN_PROVIDER: {
      if (action.error) {
        return state;
      }
      if (state.providers.includes(action.payload)) {
        return state;
      }

      return {
        ...state,
        providers: [...state.providers, action.payload].sort(),
      };
    }
    case LOGIN_PROVIDERS_PREPARATION_STARTED: {
      return { ...state, isPreparingProviders: true };
    }
    case LOGIN_PROVIDERS_PREPARATION_ENDED: {
      return { ...state, isPreparingProviders: false };
    }
    case SET_REFERRER_ACCOUNT_ID: {
      return { ...state, referrerAccountId: action.payload };
    }
    case LOGIN_HIDE_IN_PROGRESS_STATE: {
      return { ...state, loginInProgress: false };
    }
  }

  return state;
};
