import { useLayoutEffect, FC } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "state/tree/notifications";
import { NotificationType } from "src/enums";
import { useUnmount } from "src/utils/miniReactUse";

interface NotificationsMuterProps {
  mutedTypes: NotificationType[];
}

const NotificationsMuter: FC<NotificationsMuterProps> = ({ mutedTypes }) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(actionCreators.setMutedNotificationTypes(mutedTypes));
  }, [dispatch, mutedTypes]);
  useUnmount(() => {
    dispatch(actionCreators.setMutedNotificationTypes([]));
  });
  return null;
};

export default NotificationsMuter;
