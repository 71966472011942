import React, { FC } from "react";
import { FormattedTime } from "react-intl";

interface DateProps {
  value: number;
}

export const MessageDateNow: FC<DateProps> = ({ value }) => (
  <FormattedTime value={value} hour="2-digit" minute="2-digit" hour12={false} />
);
