import React, { FC, MouseEventHandler, memo, useRef } from "react";
import classnames from "classnames";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { useAlternativeDomainContentSupport } from "chat/hooks/useAlternativeDomainContentSupport";
import { useLazyBackgroundImageImmutable } from "chat/imports/hooks";
import { getMediaDimensionsStyle } from "chat/premiumMessage/common/getMediaDimensionsStyle";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import styles from "chat/components/currentConversation/ImageMessage.scss";

interface PremiumImageProps {
  height: number;
  messageConfig: MessageConfig;
  onClick?: MouseEventHandler<HTMLDivElement>;
  url: string;
  width: number;
}

const PremiumImage: FC<PremiumImageProps> = ({
  url,
  width,
  height,
  onClick,
  messageConfig,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const changedUrl = useAlternativeDomainContentSupport(url);

  useLazyBackgroundImageImmutable(ref);

  const style = getMediaDimensionsStyle(width, height);

  return (
    <div
      ref={ref}
      data-lazybg={changedUrl}
      data-testid="premium-image"
      className={classnames(
        styles.root,
        getGroupMessageClassnames(styles, messageConfig)
      )}
      style={style}
      onClick={onClick}
    />
  );
};

export default memo(PremiumImage);
