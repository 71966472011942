import { createAction } from "@reduxjs/toolkit";

export const serverOwnedConfigFailed = createAction(
  "lwc/serverConfig/failed",
  (error: Error) => ({
    payload: error,
  })
);

export const loadingServerOwnedConfig = createAction<Record<string, string>>(
  "lwc/serverConfig/loading"
);

export const loadedServerOwnedConfig = createAction<Record<string, string>>(
  "lwc/serverConfig/load"
);

export const overrideServerOwnedConfig = createAction(
  "lwc/serverConfig/override",
  (key: string, value: string) => ({
    payload: {
      [key]: value,
    },
  })
);
