import React, { Suspense, memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import lazy from "src/utils/lazyWithPageReload";
import { modalSelectors } from "state/selectors";

const ModalController = lazy(
  () => import(/* webpackChunkName: "modalController" */ "./ModalController")
);

const LazyModalController = () => {
  const modalControllerLazyLoaded = useRef(false);
  const shouldShowModal = useSelector(modalSelectors.shouldShowModal);

  useEffect(() => {
    if (!modalControllerLazyLoaded.current && !!shouldShowModal) {
      modalControllerLazyLoaded.current = true;
    }
  }, [shouldShowModal]);

  return (
    <Suspense fallback={null}>
      {(shouldShowModal || modalControllerLazyLoaded.current) && (
        <ModalController />
      )}
    </Suspense>
  );
};

export default memo(LazyModalController);
