import Long from "long";
import protobuf from "protobufjs/minimal";
import { enhancedFetch } from "src/features/feed/imports/api";
import { getProxycadorHost } from "src/features/feed/imports/environment";
import { GetPostByIdResponse as GetPostByIdResponseProto } from "src/features/feed/imports/generated";
import { convertLongToString } from "src/features/feed/imports/utils";
import { RawPost } from "src/features/feed/types/rawPost";
import { ResponseCode } from "src/features/feed/types/responseCode";

interface FetchPostResponse {
  code?: ResponseCode;
  post?: RawPost;
}

export const fetchPost = async (postId: string) => {
  const response = await enhancedFetch(
    `${getProxycadorHost()}/feed/v9/feed/post/${postId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/x-protobuf",
        "Content-Type": "application/json",
      },
    }
  );

  protobuf.util.Long = Long;
  protobuf.configure();

  const arrayBuffer = await response.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  const parsedWithLongs = GetPostByIdResponseProto.decode(uint8Array);
  const parsedWithLongStrings = convertLongToString(parsedWithLongs);

  return parsedWithLongStrings as FetchPostResponse;
};
