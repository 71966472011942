import type { RealTimeRecommendationsUnfollowEventParams } from "src/features/recommendations/common/types";
import { RealTimeRecommendationsEventBuilder } from "src/features/recommendations/builders/RealTimeRecommendationsEventBuilder";
import { realTimeRecommendationsQueueService } from "src/features/recommendations/client";
import {
  RealTimeRecommendationsEventType,
  RealTimeRecommendationsParamKey,
} from "src/features/recommendations/common/enums";

export const emitRealTimeRecommendationsUnfollowEvent = (
  params: RealTimeRecommendationsUnfollowEventParams
) => {
  const { streamerId, unfollowFrom } = params;

  const eventBuilder = new RealTimeRecommendationsEventBuilder(
    RealTimeRecommendationsEventType.UNFOLLOW
  );

  eventBuilder
    .addParam(RealTimeRecommendationsParamKey.STREAMER_ID, {
      stringValue: streamerId,
    })
    .addParam(RealTimeRecommendationsParamKey.UNFOLLOW_FROM, {
      stringValue: unfollowFrom,
    });

  realTimeRecommendationsQueueService.scheduleEvent(eventBuilder.build());
};
