import { SubscriptionPerStreamerListState } from "state/tree/subscriptionPerStreamerList";

export default {
  // user subscriptions
  getIsSubscribedByAccountId: (
    state: SubscriptionPerStreamerListState,
    accountId: string
  ) => state.userSubscriptions.data.accountIds.includes(accountId),
  getUserSubscribedStreamerIds: (state: SubscriptionPerStreamerListState) =>
    state.userSubscriptions.data.accountIds,
  getSubscriptionMap: (state: SubscriptionPerStreamerListState) =>
    state.userSubscriptions.data.map,
  getSubscriptionInfoFromUserMap: (
    state: SubscriptionPerStreamerListState,
    accountId: string
  ) => state.userSubscriptions.data.map[accountId],
  getUserSubscriptionsMeta: (state: SubscriptionPerStreamerListState) =>
    state.userSubscriptions.meta,

  // user subscribers
  getUserSubscribersIds: (state: SubscriptionPerStreamerListState) =>
    state.userSubscribers.data.accountIds,
  getUserSubscribersMap: (state: SubscriptionPerStreamerListState) =>
    state.userSubscribers.data.map,
  getUserSubscribersMeta: (state: SubscriptionPerStreamerListState) =>
    state.userSubscribers.meta,

  // streamer subscribers
  getIsStreamerSubscribersLoading: (state: SubscriptionPerStreamerListState) =>
    state.streamerSubscribers.meta.loading,
  getIsUserSubscribedOnStreamer: (
    state: SubscriptionPerStreamerListState,
    accountId: string,
    streamerId: string
  ) =>
    !!state.streamerSubscribers.data[streamerId]?.accountIds.includes(
      accountId
    ),
  getIsStreamerSubscribersUpToDate: (state: SubscriptionPerStreamerListState) =>
    !state.streamerSubscribers.meta.stale,
  getStreamerSubscribersIds: (
    state: SubscriptionPerStreamerListState,
    streamerId: string
  ) => state.streamerSubscribers.data[streamerId]?.accountIds,
  getSubscriptionInfoFromStreamerMap: (
    state: SubscriptionPerStreamerListState,
    accountId: string,
    streamerId: string
  ) => state.streamerSubscribers.data[streamerId]?.map[accountId],
  getStreamerSubscribersMap: (
    state: SubscriptionPerStreamerListState,
    streamerId: string
  ) => state.streamerSubscribers.data[streamerId]?.map,
  getStreamerSubscribersMeta: (state: SubscriptionPerStreamerListState) =>
    state.streamerSubscribers.meta,
};
