import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  LiveProfileData,
  ProfileData,
  UnsupportedProfileData,
} from "chat/components/hooks/useProfileAvatarClickTracker";
import { StreamKind } from "chat/imports/constants";
import { RootState, isSystemChat } from "chat/imports/state";
import { BasicStreamInfo } from "chat/imports/types";
import isGroupChatId from "chat/utils/isGroupChatId";

interface OfflineUserData {
  conversationId: string;
  isStreamAvailableForUser: false;
  messageSenderId: string | undefined;
  stream: undefined;
}

interface OnlineUserData {
  conversationId: string;
  isStreamAvailableForUser: boolean;
  messageSenderId: string | undefined;
  stream: Pick<BasicStreamInfo, "id" | "kind">;
}

const getUserId = ({
  conversationId,
  isGroup,
  isSystem,
  messageSenderId,
}: {
  conversationId: string;
  isGroup: boolean;
  isSystem: boolean;
  messageSenderId: string | undefined;
}) => {
  if (isGroup && messageSenderId) {
    return messageSenderId;
  }

  if (isGroup || isSystem) {
    return null;
  }

  return conversationId;
};

export const useProfileAvatarClickTrackerData = ({
  conversationId,
  isStreamAvailableForUser,
  messageSenderId,
  stream,
}: OfflineUserData | OnlineUserData) => {
  const isGroup = useMemo(
    () => isGroupChatId(conversationId),
    [conversationId]
  );

  const isSystem = useSelector((state: RootState) =>
    isSystemChat(state, conversationId)
  );

  return useMemo(() => {
    const userId = getUserId({
      conversationId,
      isGroup,
      isSystem,
      messageSenderId,
    });

    if (!userId) {
      const targetData: UnsupportedProfileData = { userId: undefined };

      return targetData;
    }

    const isUserInLive = isStreamAvailableForUser && !!stream;

    if (isUserInLive) {
      const isUserInPremium = [
        StreamKind.PRIVATE,
        StreamKind.TICKET_PRIVATE,
      ].includes(stream.kind);

      const targetData: LiveProfileData = {
        isUserInLive,
        isUserInPremium,
        streamId: stream.id,
        userId,
      };

      return targetData;
    }

    const targetData: ProfileData = { userId };

    return targetData;
  }, [
    conversationId,
    isGroup,
    isStreamAvailableForUser,
    isSystem,
    messageSenderId,
    stream,
  ]);
};
