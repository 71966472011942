import { compose } from "redux";
import merge from "lodash.merge";
import {
  REFERRALS_LIST_FETCH_END,
  REFERRALS_LIST_FETCH_START,
  ACME_RECEIVED,
} from "state/actionTypes";
import withFetcher, {
  fetcherSelectors,
  createFetcherActions,
} from "state/hor/withFetcher";
import withUserSessionScope from "state/hor/withUserSessionScope";

const initialState = {
  result: [],
  bonus: {},
  isStale: true,
  hasMore: false,
  loadedPage: -1,
};

export const actionCreators = {
  ...createFetcherActions({
    beginFetchActionType: REFERRALS_LIST_FETCH_START,
    endFetchActionType: REFERRALS_LIST_FETCH_END,
  }),
};

export default compose(
  withUserSessionScope,
  withFetcher({
    initialData: initialState,
    beginFetchActionType: REFERRALS_LIST_FETCH_START,
    endFetchActionType: REFERRALS_LIST_FETCH_END,
    extractData: ({ result, entities: { bonus } }, { page, pageSize }) => ({
      result,
      bonus,
      isStale: false,
      loadedPage: page,
      hasMore: result.length === pageSize,
    }),
    mergeData: (oldState, newState) => ({
      ...newState,
      result: [...oldState.result, ...newState.result],
      bonus: merge({}, oldState.bonus, newState.bonus),
    }),
  })
)((state, action) => {
  switch (action.type) {
    case ACME_RECEIVED: {
      const { serviceName, serviceIdentifier } = action.payload;
      if (
        serviceIdentifier === "referral_registration" &&
        serviceName === "facilitator" &&
        !state.isStale
      ) {
        return { ...state, isStale: true };
      }
      return state;
    }
  }
  return state;
});

export const selectors = {
  ...fetcherSelectors,
  canLoadMore: (state) => state.hasMore,
  isStale: (state) => state.isStale,
  getLoadedPage: (state) => state.loadedPage,
  getRegisteredAccounts: (state) => state.result,
  getBonusReceivedFromAccount: (state, accountId) => state.bonus[accountId],
};
