import React from "react";
import { useSelector } from "react-redux";
import { RouteProps } from "react-router";
import { Redirect, Route, useLocation } from "react-router-dom";
import { loginSelectors } from "state/selectors";
import { setRedirectAfterLogin } from "state/tree/navigation";
import { useAppDispatch } from "ui/hooks/useAppDispatch";
import { linkToBasePath } from "ui/navigation/links";
import { useMount } from "utils/miniReactUse";

const LoggedInRoute = (props: RouteProps) => {
  const { component: Component, path, ...rest } = props;
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  useMount(() => {
    if (isLoggedIn) {
      return;
    }

    dispatch(setRedirectAfterLogin({ redirectPath: `${pathname}${search}` }));
  });

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={linkToBasePath} />
      }
    />
  );
};

export default LoggedInRoute;
