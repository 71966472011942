import { UIActionTarget } from "chat/enums";
import { EventFields, SCREEN_NAME, emitUiAction } from "chat/imports/analytics";

export enum ConversationsListItemClickedScreen {
  CHAT_REQUESTS = SCREEN_NAME.CHAT_REQUESTS,
  MAIN_CONVERSATIONS = SCREEN_NAME.MAIN_CONVERSATIONS,
}

export enum ConversationsListItemClickedText {
  FAMILY = "family",
  GROUP = "group",
  SINGLE = "single",
  SYSTEM = "system",
}

interface EmitConversationsListItemClickedParams {
  chatId: string;
  screen: ConversationsListItemClickedScreen;
  size: number;
  text: ConversationsListItemClickedText;
}

export const emitConversationsListItemClicked = ({
  text,
  chatId,
  screen,
  size,
}: EmitConversationsListItemClickedParams) => {
  emitUiAction({
    target: UIActionTarget.OPEN_DIALOG,
    tango_screen: screen,
    additionalParams: {
      [EventFields.TEXT]: text,
      [EventFields.CHAT_ID]: chatId,
      [EventFields.SIZE]: size,
    },
  });
};
