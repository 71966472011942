import { RequestHistoryItem, getRequestHistory } from "./requestHistory";

interface ApiErrorConfig extends Pick<Response, "status" | "statusText"> {
  body?: Record<string, unknown>;
}

export const ApiErrorName = "ApiError";

class ApiError extends Error {
  body: ApiErrorConfig["body"];
  name = ApiErrorName;
  requestHistory: RequestHistoryItem[] = [];
  status: ApiErrorConfig["status"];
  statusText: ApiErrorConfig["statusText"];

  constructor({ status, statusText, body }: ApiErrorConfig) {
    super(statusText);
    this.status = status;
    this.statusText = statusText;
    this.body = body;
    this.requestHistory = getRequestHistory().slice().reverse();
  }

  getDatadogContext() {
    return {
      context: {
        status: this.status,
        statusText: this.statusText,
        body: this.body,
        requestHistory: this.requestHistory,
      },
    };
  }

  getMessage() {
    return `status: ${this.status}, statusText: ${this.statusText}`;
  }
}

export default ApiError;
