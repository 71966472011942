import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { ResponseCode } from "src/types/richFragment/ResponseCode";
import { CountryInfo } from "state/types";

// visible for testing
export const baseApiPath = "proxycador/api/public/v1/live/countrypicker/list";

export const fetchNearbyCountries = (locale: string): Promise<CountryInfo[]> =>
  fetch(
    `${getProxycadorHost()}/${baseApiPath}?locale=${locale}&returnOnlyActive=false`
  )
    .then((resp) => resp.json())
    .then(({ code, countries }) => {
      if (code !== ResponseCode.OK) {
        throw new Error(code);
      }

      return countries;
    });

export const fetchActiveCountries = (
  locale: string
): Promise<Array<CountryInfo["isoCode"]>> =>
  fetch(
    `${getProxycadorHost()}/${baseApiPath}?locale=${locale}&returnOnlyActive=true`
  )
    .then((resp) => resp.json())
    .then(({ code, countries }) => {
      if (code !== ResponseCode.OK) {
        throw new Error(code);
      }

      return (countries as CountryInfo[]).map((country) => country.isoCode);
    });

export const updateNearbyCountries = (activeCodes: string[]) =>
  fetch(`${getProxycadorHost()}/${baseApiPath}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ activeCodes }),
  }).then((resp) => resp.json());
