import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type {
  ExtendedStreamInfo,
  Nullable,
} from "src/features/homePage/v2/imports/types";
import {
  HomePageStreamsResponse,
  HomePageVisitorStreamsResponse,
} from "src/features/homePage/shared/constants/types";
import mapToOutputStream, {
  mapToOutputVisitorStream,
} from "src/features/homePage/v2/utils/mapStreamsToGridView";

export interface HomePageCreatorsState {
  broadcasterIds: string[];
  error: Nullable<string>;
  isLoading: boolean;
  streams: Partial<ExtendedStreamInfo>[];
}

const initialState: HomePageCreatorsState = {
  streams: [],
  isLoading: false,
  error: null,
  broadcasterIds: [],
};

const creatorsSlice = createSlice({
  name: "homePageCreators",
  initialState,
  reducers: {
    loadHomePageCreatorsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loadHomePageCreatorsComplete(
      state,
      action: PayloadAction<HomePageStreamsResponse>
    ) {
      const { streams, broadcasterIds } =
        mapToOutputStream(action.payload) || [];
      state.isLoading = false;
      state.streams = streams;
      state.broadcasterIds = broadcasterIds;
    },
    loadHomePageVisitorCreatorsComplete(
      state,
      action: PayloadAction<HomePageVisitorStreamsResponse>
    ) {
      const { catalogItems } = action.payload;
      state.streams = mapToOutputVisitorStream(catalogItems);
      state.isLoading = false;
    },
    loadHomePageCreatorsFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadHomePageCreatorsStart,
  loadHomePageCreatorsFailure,
  loadHomePageCreatorsComplete,
  loadHomePageVisitorCreatorsComplete,
} = creatorsSlice.actions;

export const homePageCreatorsReducer = creatorsSlice.reducer;
