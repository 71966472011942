import { enhancedFetch } from "src/features/appeal/AppealPage/imports/api";
import { getProxycadorHost } from "src/features/appeal/AppealPage/imports/environment";
import {
  AppealStatus,
  CheckAppealParams,
  SendAppealParams,
} from "src/features/appeal/AppealPage/types";

const getAppealEndpoint = () => `${getProxycadorHost()}/moderation/v1/appeal`;

interface CheckAppealResponse {
  status: AppealStatus;
}

export async function checkAppeal(
  params: CheckAppealParams
): Promise<CheckAppealResponse> {
  const response = await enhancedFetch(`${getAppealEndpoint()}/token/check`, {
    method: "POST",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(params),
  });

  return await response.json();
}

export async function sendAppeal(params: SendAppealParams): Promise<Response> {
  const response = await enhancedFetch(`${getAppealEndpoint()}/submit`, {
    method: "POST",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(params),
  });

  return response;
}
