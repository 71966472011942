import React, { FC, memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { RefreshIcon } from "chat/imports/assets";
import { Button } from "chat/imports/components";
import { ButtonSize, ButtonVariant } from "chat/imports/constants";
import { useMount } from "chat/imports/hooks";
import { genericNetworkError, retryAfterCaptcha } from "chat/imports/state";
import { retryMessage } from "chat/state/flows";
import { StoredMessage } from "chat/state/reducer";
import { ChatMessageAnalyticsParams } from "chat/types";
import styles from "./Resend.scss";

interface ResendProps {
  analyticsParams: ChatMessageAnalyticsParams;
  className?: string;
  message: StoredMessage;
}

const Resend: FC<ResendProps> = ({ message, className, analyticsParams }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleRetryMessage = useCallback(
    () => retryMessage({ message, formatMessage, analyticsParams }),
    [message, analyticsParams, formatMessage]
  );

  const onClick = useCallback(() => {
    dispatch(handleRetryMessage());
  }, [message]);

  useMount(() => {
    const { error } = message;
    dispatch(genericNetworkError(error));
    retryAfterCaptcha(error, handleRetryMessage());
  });

  return (
    <Button
      size={ButtonSize.CIRCLE_EXTRASMALL_24}
      variant={ButtonVariant.ICON_ONLY}
      data-testid="resend"
      className={classnames(styles.root, className)}
      onClick={onClick}
    >
      <RefreshIcon className={styles.icon} />
    </Button>
  );
};

export default memo(Resend);
