import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { DeviceType } from "src/enums";
import { getBodyScrollLockEnabled } from "state/abTests";
import { RootState } from "state/delegate";
import { deviceInfoSelectors } from "state/selectors";

const SMART_APP_BANNER_OFFSET = 56;
const SMALL_BODY_OFFSET = 1;
const SAFE_SCROLL_TIMEOUT = 300;
const BODY_SCROLL_LOCK_CLASSNAME = "body-scroll-lock";

const selector = (state: RootState) => ({
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isBodyScrollLockEnabled: getBodyScrollLockEnabled(state),
});

export const useBodyScrollLockEnabled = (): boolean => {
  const { deviceType, isBodyScrollLockEnabled } = useSelector(
    selector,
    shallowEqual
  );
  const isIosDevice =
    deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD;

  return isBodyScrollLockEnabled && isIosDevice;
};

export const useBodyScrollLock = (rootRef: HTMLDivElement) => {
  const shouldLockBodyScroll = useBodyScrollLockEnabled();

  useEffect(() => {
    if (!shouldLockBodyScroll || !rootRef) {
      return;
    }

    const { height, overflow } = document.body.style;

    const onScroll = () => {
      window.scrollTo(0, SMALL_BODY_OFFSET);
    };

    document.body.style.height = `calc(100dvh + ${SMART_APP_BANNER_OFFSET}px)`;
    document.body.style.overflow = "scroll";

    window.scrollTo({ top: SMART_APP_BANNER_OFFSET, behavior: "instant" });

    setTimeout(() => {
      onScroll();

      document.body.style.height = height;
      document.body.style.overflow = overflow;
      document.body.classList.add(BODY_SCROLL_LOCK_CLASSNAME);

      window.addEventListener("scroll", onScroll);
    }, SAFE_SCROLL_TIMEOUT);

    return () => {
      window.removeEventListener("scroll", onScroll);
      document.body.classList.remove(BODY_SCROLL_LOCK_CLASSNAME);
    };
  }, [rootRef, shouldLockBodyScroll]);
};
