import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormattedDate } from "chat/components/FormattedDate/FormattedDate";
import { sharedMessages } from "chat/imports/utils";
import capitalize from "chat/utils/capitalize";

interface DateProps {
  value: number;
}

export const DateSeparatorOneDayNow: FC<DateProps> = ({ value }) => {
  const intl = useIntl();
  const date = new Date(value);

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const dateTimestamp = new Date(date.setHours(0, 0, 0, 0)).getTime();

  if (date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    return <FormattedMessage id="date_format_today" defaultMessage="Today" />;
  } else if (dateTimestamp === yesterday.getTime()) {
    const yesterday = intl.formatMessage(sharedMessages.yesterday);

    return <>{capitalize(yesterday)}</>;
  } else if (dateTimestamp > oneWeekAgo.getTime()) {
    const weekday = intl.formatDate(value, { weekday: "long" });

    return <>{capitalize(weekday)}</>;
  }

  if (date.getFullYear() === new Date().getFullYear()) {
    return <FormattedDate day="numeric" month="long" value={value} />;
  }

  return (
    <FormattedDate day="numeric" month="short" value={value} year="numeric" />
  );
};
