import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import Title from "src/features/signin/components/Title/Title";
import {
  descriptionMessages,
  titleMessages,
} from "src/features/signin/constants";
import { providerToComponentMap } from "src/features/signin/enums";
import useGetLoginProviders from "src/features/signin/hooks/useGetLoginProviders";
import {
  Breakpoints,
  LoginModalTitleType,
  LoginProvider,
} from "src/features/signin/imports/enums";
import {
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
  useBreakpoint,
} from "src/features/signin/imports/ui";
import ProviderButtonPhone from "src/features/signin/modal/login/components/ProviderButtonPhone";
import styles from "./ProviderSelector.scss";

const Separator = () => (
  <div className={styles.separator}>
    <div className={styles.separatorLine} />
    <Typography
      type={TYPOGRAPHY_TYPE.ALL_CAPS}
      as="div"
      className={styles.separatorText}
    >
      <FormattedMessage id="OR" defaultMessage="OR" />
    </Typography>
    <div className={styles.separatorLine} />
  </div>
);

interface ProviderSelectorParams {
  title: LoginModalTitleType;
}

const ProviderSelector: FC<ProviderSelectorParams> = ({ title }) => {
  const breakpoint = useBreakpoint();
  const { mainProvider, otherProviders } = useGetLoginProviders();
  const areOtherProvidersAvailable = Boolean(otherProviders.length);
  const isDesktop = useBreakpoint() === Breakpoints.DESKTOP;

  const description =
    title in descriptionMessages ? (
      <FormattedMessage {...descriptionMessages[title]} />
    ) : undefined;

  return (
    <>
      <Title
        description={description}
        title={<FormattedMessage {...titleMessages[title]} />}
      />
      <div className={classnames(styles.loginMethods, styles[breakpoint])}>
        {areOtherProvidersAvailable && (
          <>
            <div className={styles.otherProviders}>
              {otherProviders.map((provider: LoginProvider, index) => {
                const Component = providerToComponentMap[provider];

                if (!Component) {
                  return null;
                }

                return (
                  <Component
                    key={provider}
                    buttonVariant={
                      index === 0
                        ? ButtonVariant.PRIMARY
                        : ButtonVariant.SECONDARY
                    }
                    provider={provider}
                  />
                );
              })}
            </div>

            {mainProvider && isDesktop && <Separator />}
          </>
        )}

        {mainProvider && <ProviderButtonPhone />}
      </div>
    </>
  );
};
export default ProviderSelector;
