import { match } from "path-to-regexp";
import { Dispatch } from "redux";
import {
  EventFields,
  EventNames,
  RegistrationSource,
  emitEvent,
} from "src/features/signin/imports/analytics";
import {
  BottomScreenType,
  DeviceType,
  LoginModalTitleType,
  LoginPromotionType,
} from "src/features/signin/imports/enums";
import {
  NEW_SIGN_UP_PROMOTION_LANDING_OFFERS,
  NEW_SIGN_UP_PROMOTION_TYPE_CHAT,
  NEW_SIGN_UP_PROMOTION_TYPE_GIFT_OR_LIKE,
  NEW_SIGN_UP_PROMOTION_TYPE_OTHER,
  NEW_SIGN_UP_PROMOTION_TYPE_TRANSITION_TO_PRIVATE_STREAM,
  RootState,
  deviceInfoSelectors,
  navigationSelectors,
  openLoginModal,
  openSignUpBottomScreen,
} from "src/features/signin/imports/state";
import { Nullable, VoidCallback } from "src/features/signin/imports/types";
import { linkToStreamMatch } from "src/features/signin/imports/ui";

interface BottomScreenData {
  isShowPhoneNumberLoginFlow?: boolean;
  newSignUpPromotionType?: NewSignUpPromotionType;
  screenType?: BottomScreenType;
}

interface CommonData {
  eventText?: Nullable<string>;
  omitGuestRegistrationAnalyticsEvent?: boolean;
  onDismiss?: VoidCallback;
  onOutsideClickDismiss?: VoidCallback;
  redirectOnDismiss?: string;
  registrationSource?: RegistrationSource;
  title?: LoginModalTitleType;
}

interface ModalData {
  accountId?: string;
  isBackButtonHidden?: boolean;
  onLoginSuccess?: VoidCallback;
  promotionType?: LoginPromotionType;
}

interface AnalyticsData {
  screenName?: string;
}

type NewSignUpPromotionType =
  | typeof NEW_SIGN_UP_PROMOTION_LANDING_OFFERS
  | typeof NEW_SIGN_UP_PROMOTION_TYPE_CHAT
  | typeof NEW_SIGN_UP_PROMOTION_TYPE_GIFT_OR_LIKE
  | typeof NEW_SIGN_UP_PROMOTION_TYPE_OTHER
  | typeof NEW_SIGN_UP_PROMOTION_TYPE_TRANSITION_TO_PRIVATE_STREAM;

interface OpenLoginViewData
  extends CommonData,
    BottomScreenData,
    ModalData,
    AnalyticsData {}

export const openLoginView = (data?: OpenLoginViewData) => {
  const {
    accountId,
    isShowPhoneNumberLoginFlow,
    newSignUpPromotionType,
    omitGuestRegistrationAnalyticsEvent = false,
    onDismiss,
    onOutsideClickDismiss,
    promotionType,
    redirectOnDismiss,
    registrationSource,
    screenType,
    title = LoginModalTitleType.REGULAR,
    eventText,
    onLoginSuccess,
    screenName,
    isBackButtonHidden = false,
  } = data || {};

  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const deviceType = deviceInfoSelectors.getDeviceType(state);
    const isMobile = deviceInfoSelectors.isAnyMobileDevice(state);
    const isLandscape = window.innerHeight < window.innerWidth;

    if (!omitGuestRegistrationAnalyticsEvent) {
      emitEvent(EventNames.GUEST_REGISTRATION, {
        [EventFields.GUEST_REGISTRATION_SOURCE]:
          registrationSource ?? RegistrationSource.UNKNOWN,
        ...(eventText && { text: eventText }),
        ...(screenName && { [EventFields.TANGO_SCREEN]: screenName }),
      });
    }

    if (isMobile || (deviceType === DeviceType.IPAD && !isLandscape)) {
      const currentRoute = navigationSelectors.getCurrentRoute(state);
      const isStream = match(linkToStreamMatch, { end: false })(currentRoute);

      const dark =
        isStream &&
        newSignUpPromotionType !==
          NEW_SIGN_UP_PROMOTION_TYPE_TRANSITION_TO_PRIVATE_STREAM;

      dispatch(
        openSignUpBottomScreen({
          dark,
          isShowPhoneNumberLoginFlow,
          onDismiss,
          onOutsideClickDismiss,
          promotionType: newSignUpPromotionType,
          redirectOnDismiss,
          registrationSource,
          screenType,
          title,
          onLoginSuccess,
        })
      );
    } else {
      dispatch(
        openLoginModal({
          accountId,
          onDismiss,
          onOutsideClickDismiss,
          promotionType,
          redirectOnDismiss,
          registrationSource,
          title,
          onLoginSuccess,
          isBackButtonHidden,
        })
      );
    }
  };
};
