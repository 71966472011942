/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.EventGateway || ($protobuf.roots.EventGateway = {});

export const ClientEventsRequest = $root.ClientEventsRequest = (() => {

    function ClientEventsRequest(properties) {
        this.events = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ClientEventsRequest.prototype.events = $util.emptyArray;

    ClientEventsRequest.create = function create(properties) {
        return new ClientEventsRequest(properties);
    };

    ClientEventsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.events != null && message.events.length)
            for (let i = 0; i < message.events.length; ++i)
                $root.ClientEvent.encode(message.events[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    ClientEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ClientEventsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientEventsRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.events && message.events.length))
                        message.events = [];
                    message.events.push($root.ClientEvent.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ClientEventsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ClientEventsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientEventsRequest)
            return object;
        let message = new $root.ClientEventsRequest();
        if (object.events) {
            if (!Array.isArray(object.events))
                throw TypeError(".ClientEventsRequest.events: array expected");
            message.events = [];
            for (let i = 0; i < object.events.length; ++i) {
                if (typeof object.events[i] !== "object")
                    throw TypeError(".ClientEventsRequest.events: object expected");
                message.events[i] = $root.ClientEvent.fromObject(object.events[i]);
            }
        }
        return message;
    };

    ClientEventsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.events = [];
        if (message.events && message.events.length) {
            object.events = [];
            for (let j = 0; j < message.events.length; ++j)
                object.events[j] = $root.ClientEvent.toObject(message.events[j], options);
        }
        return object;
    };

    ClientEventsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ClientEventsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientEventsRequest";
    };

    return ClientEventsRequest;
})();

export const ClientEvent = $root.ClientEvent = (() => {

    function ClientEvent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ClientEvent.prototype.name = "";
    ClientEvent.prototype.payload = $util.newBuffer([]);
    ClientEvent.prototype.parameters = null;
    ClientEvent.prototype.accountId = "";

    ClientEvent.create = function create(properties) {
        return new ClientEvent(properties);
    };

    ClientEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(10).string(message.name);
        if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
            writer.uint32(18).bytes(message.payload);
        if (message.parameters != null && Object.hasOwnProperty.call(message, "parameters"))
            $root.CommonParameters.encode(message.parameters, writer.uint32(26).fork()).ldelim();
        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
            writer.uint32(34).string(message.accountId);
        return writer;
    };

    ClientEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ClientEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    message.payload = reader.bytes();
                    break;
                }
            case 3: {
                    message.parameters = $root.CommonParameters.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.accountId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ClientEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ClientEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientEvent)
            return object;
        let message = new $root.ClientEvent();
        if (object.name != null)
            message.name = String(object.name);
        if (object.payload != null)
            if (typeof object.payload === "string")
                $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
            else if (object.payload.length >= 0)
                message.payload = object.payload;
        if (object.parameters != null) {
            if (typeof object.parameters !== "object")
                throw TypeError(".ClientEvent.parameters: object expected");
            message.parameters = $root.CommonParameters.fromObject(object.parameters);
        }
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        return message;
    };

    ClientEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.name = "";
            if (options.bytes === String)
                object.payload = "";
            else {
                object.payload = [];
                if (options.bytes !== Array)
                    object.payload = $util.newBuffer(object.payload);
            }
            object.parameters = null;
            object.accountId = "";
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.payload != null && message.hasOwnProperty("payload"))
            object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
        if (message.parameters != null && message.hasOwnProperty("parameters"))
            object.parameters = $root.CommonParameters.toObject(message.parameters, options);
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        return object;
    };

    ClientEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ClientEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientEvent";
    };

    return ClientEvent;
})();

export const CommonParameters = $root.CommonParameters = (() => {

    function CommonParameters(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CommonParameters.prototype.uid = "";
    CommonParameters.prototype.accountId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    CommonParameters.prototype.clientSessionId = "";
    CommonParameters.prototype.clientNetworkId = "";
    CommonParameters.prototype.eventTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    CommonParameters.prototype.eventSource = "";
    CommonParameters.prototype.screenName = "";
    CommonParameters.prototype.screenState = "";
    CommonParameters.prototype.env = "";

    CommonParameters.create = function create(properties) {
        return new CommonParameters(properties);
    };

    CommonParameters.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
            writer.uint32(10).string(message.uid);
        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
            writer.uint32(16).uint64(message.accountId);
        if (message.clientSessionId != null && Object.hasOwnProperty.call(message, "clientSessionId"))
            writer.uint32(26).string(message.clientSessionId);
        if (message.clientNetworkId != null && Object.hasOwnProperty.call(message, "clientNetworkId"))
            writer.uint32(34).string(message.clientNetworkId);
        if (message.eventTime != null && Object.hasOwnProperty.call(message, "eventTime"))
            writer.uint32(40).uint64(message.eventTime);
        if (message.eventSource != null && Object.hasOwnProperty.call(message, "eventSource"))
            writer.uint32(50).string(message.eventSource);
        if (message.screenName != null && Object.hasOwnProperty.call(message, "screenName"))
            writer.uint32(58).string(message.screenName);
        if (message.screenState != null && Object.hasOwnProperty.call(message, "screenState"))
            writer.uint32(66).string(message.screenState);
        if (message.env != null && Object.hasOwnProperty.call(message, "env"))
            writer.uint32(74).string(message.env);
        return writer;
    };

    CommonParameters.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CommonParameters.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CommonParameters();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.uid = reader.string();
                    break;
                }
            case 2: {
                    message.accountId = reader.uint64();
                    break;
                }
            case 3: {
                    message.clientSessionId = reader.string();
                    break;
                }
            case 4: {
                    message.clientNetworkId = reader.string();
                    break;
                }
            case 5: {
                    message.eventTime = reader.uint64();
                    break;
                }
            case 6: {
                    message.eventSource = reader.string();
                    break;
                }
            case 7: {
                    message.screenName = reader.string();
                    break;
                }
            case 8: {
                    message.screenState = reader.string();
                    break;
                }
            case 9: {
                    message.env = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    CommonParameters.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CommonParameters.fromObject = function fromObject(object) {
        if (object instanceof $root.CommonParameters)
            return object;
        let message = new $root.CommonParameters();
        if (object.uid != null)
            message.uid = String(object.uid);
        if (object.accountId != null)
            if ($util.Long)
                (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
            else if (typeof object.accountId === "string")
                message.accountId = parseInt(object.accountId, 10);
            else if (typeof object.accountId === "number")
                message.accountId = object.accountId;
            else if (typeof object.accountId === "object")
                message.accountId = new $util.LongBits(object.accountId.low >>> 0, object.accountId.high >>> 0).toNumber(true);
        if (object.clientSessionId != null)
            message.clientSessionId = String(object.clientSessionId);
        if (object.clientNetworkId != null)
            message.clientNetworkId = String(object.clientNetworkId);
        if (object.eventTime != null)
            if ($util.Long)
                (message.eventTime = $util.Long.fromValue(object.eventTime)).unsigned = true;
            else if (typeof object.eventTime === "string")
                message.eventTime = parseInt(object.eventTime, 10);
            else if (typeof object.eventTime === "number")
                message.eventTime = object.eventTime;
            else if (typeof object.eventTime === "object")
                message.eventTime = new $util.LongBits(object.eventTime.low >>> 0, object.eventTime.high >>> 0).toNumber(true);
        if (object.eventSource != null)
            message.eventSource = String(object.eventSource);
        if (object.screenName != null)
            message.screenName = String(object.screenName);
        if (object.screenState != null)
            message.screenState = String(object.screenState);
        if (object.env != null)
            message.env = String(object.env);
        return message;
    };

    CommonParameters.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.uid = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.accountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.accountId = options.longs === String ? "0" : 0;
            object.clientSessionId = "";
            object.clientNetworkId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.eventTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.eventTime = options.longs === String ? "0" : 0;
            object.eventSource = "";
            object.screenName = "";
            object.screenState = "";
            object.env = "";
        }
        if (message.uid != null && message.hasOwnProperty("uid"))
            object.uid = message.uid;
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            if (typeof message.accountId === "number")
                object.accountId = options.longs === String ? String(message.accountId) : message.accountId;
            else
                object.accountId = options.longs === String ? $util.Long.prototype.toString.call(message.accountId) : options.longs === Number ? new $util.LongBits(message.accountId.low >>> 0, message.accountId.high >>> 0).toNumber(true) : message.accountId;
        if (message.clientSessionId != null && message.hasOwnProperty("clientSessionId"))
            object.clientSessionId = message.clientSessionId;
        if (message.clientNetworkId != null && message.hasOwnProperty("clientNetworkId"))
            object.clientNetworkId = message.clientNetworkId;
        if (message.eventTime != null && message.hasOwnProperty("eventTime"))
            if (typeof message.eventTime === "number")
                object.eventTime = options.longs === String ? String(message.eventTime) : message.eventTime;
            else
                object.eventTime = options.longs === String ? $util.Long.prototype.toString.call(message.eventTime) : options.longs === Number ? new $util.LongBits(message.eventTime.low >>> 0, message.eventTime.high >>> 0).toNumber(true) : message.eventTime;
        if (message.eventSource != null && message.hasOwnProperty("eventSource"))
            object.eventSource = message.eventSource;
        if (message.screenName != null && message.hasOwnProperty("screenName"))
            object.screenName = message.screenName;
        if (message.screenState != null && message.hasOwnProperty("screenState"))
            object.screenState = message.screenState;
        if (message.env != null && message.hasOwnProperty("env"))
            object.env = message.env;
        return object;
    };

    CommonParameters.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CommonParameters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CommonParameters";
    };

    return CommonParameters;
})();

export { $root as default };
