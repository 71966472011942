import { createListenerMiddleware } from "@reduxjs/toolkit";
import { LOGIN_END } from "src/features/banners/imports";
import { bannerShownEventPersister } from "src/features/banners/utils/bannerShownEventPersister";

export const clearBannerShownEventAfterRegistrationMiddleware =
  createListenerMiddleware();

clearBannerShownEventAfterRegistrationMiddleware.startListening({
  type: LOGIN_END,
  effect: () => {
    bannerShownEventPersister.removeAll();
  },
});
