import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import PhoneNumberLoginController from "src/features/signin/bottomScreen/PhoneNumberLoginController";
import HeaderActions from "src/features/signin/bottomScreen/components/HeadersActions";
import {
  RegistrationSource,
  SCREEN_NAME,
  ScreenViewReporter,
} from "src/features/signin/imports/analytics";
import {
  BottomScreenType,
  LoginModalTitleType,
} from "src/features/signin/imports/enums";
import {
  NEW_SIGN_UP_PROMOTION_TYPE_TRANSITION_TO_PRIVATE_STREAM,
  loginSelectors,
} from "src/features/signin/imports/state";
import { VoidCallback } from "src/features/signin/imports/types";
import {
  ThemeContext,
  getIsHomePageEnabled,
  linkToBasePath,
  themeLockClassnames,
  useBreakpointPrecise,
} from "src/features/signin/imports/ui";
import { useUnmount } from "src/features/signin/imports/utils";
import {
  loginSetSelectCountryStep,
  loginWaitingForCode,
  resetPhoneLogin,
} from "src/features/signin/state/login/actionCreators";
import styles from "./SignUp.scss";

interface SignUpBottomScreenViewParams {
  bottomScreenStyle?: string;
  dismiss: {
    (): void;
    (callback: VoidCallback): void;
  };
  onOutsideClickDismiss?: VoidCallback;
  screenData: {
    dark: boolean;
    isShowPhoneNumberLoginFlow?: boolean;
    onLoginSuccess?: VoidFunction;
    promotionType: string;
    registrationSource?: RegistrationSource;
    title: LoginModalTitleType;
  };
  screenType: BottomScreenType;
}

const SignUp: React.FC<SignUpBottomScreenViewParams> = ({
  dismiss,
  onOutsideClickDismiss,
  screenData,
  bottomScreenStyle,
  screenType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpointPrecise();
  const { dark: isDarkTheme } = useContext(ThemeContext);
  const { isShowPhoneNumberLoginFlow, dark } = screenData;
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const isHomePageEnabled = useSelector(getIsHomePageEnabled);
  const isLoginFailed = useSelector(loginSelectors.isLoginFailed);
  const phoneNumberAuthenticationState = useSelector(
    loginSelectors.getPhoneNumberAuthenticationState
  );
  const { selectCountry, waitingForCode, continueWithPhone } =
    phoneNumberAuthenticationState;

  const routeMatch = useRouteMatch(linkToBasePath);
  const isRootPath = routeMatch?.isExact;

  useEffect(() => {
    if (isLoggedIn) {
      dismiss();
    }
  }, [isLoggedIn, dismiss]);

  useUnmount(() => {
    if (
      screenData.promotionType ===
      NEW_SIGN_UP_PROMOTION_TYPE_TRANSITION_TO_PRIVATE_STREAM
    ) {
      dismiss(() => {
        history.push("/");
      });
    }
  });

  const isFullScreen =
    screenType === BottomScreenType.SIGN_UP_BOTTOM_SCREEN_FULL_HEIGHT;

  const navigateBack = useCallback(() => {
    if (selectCountry) {
      dispatch(loginSetSelectCountryStep(false));
    } else if (waitingForCode) {
      dispatch(loginWaitingForCode(false));
    } else if (continueWithPhone) {
      dispatch(resetPhoneLogin());
    } else {
      if (isFullScreen) {
        dismiss();
      }
    }
  }, [
    dispatch,
    waitingForCode,
    selectCountry,
    isFullScreen,
    dismiss,
    continueWithPhone,
  ]);

  const isCloseButtonVisible = !isFullScreen;
  const isHomePageBackButtonVisible = isRootPath && isHomePageEnabled;
  const isBackButtonVisible = isHomePageBackButtonVisible
    ? phoneNumberAuthenticationState.selectCountry
    : (Object.keys(phoneNumberAuthenticationState).length && !isLoginFailed) ||
      (isFullScreen && !isLoginFailed) ||
      (isFullScreen &&
        !Object.keys(phoneNumberAuthenticationState).length &&
        isLoginFailed);

  const themeType = dark || isDarkTheme ? "dark" : "light";
  const className = classnames(
    styles.root,
    styles[breakpoint],
    {
      [styles.selectCountry]: phoneNumberAuthenticationState.selectCountry,
      [styles.phoneVerificationUnavailable]:
        phoneNumberAuthenticationState.phoneVerificationUnavailable,
    },
    bottomScreenStyle && styles[bottomScreenStyle],
    themeLockClassnames[themeType]
  );

  const onCloseClick = useCallback(() => {
    onOutsideClickDismiss?.();
    dismiss();
  }, [dismiss, onOutsideClickDismiss]);

  return (
    <div data-testid="sign-up-bottom-screen-options" className={className}>
      <ScreenViewReporter name={SCREEN_NAME.REGISTRATION_SIGN_IN} />
      <div
        className={classnames(styles.header, {
          [styles.isBackButtonHidden]: !isBackButtonVisible,
        })}
      >
        <HeaderActions
          navigateBack={navigateBack}
          onClose={onCloseClick}
          isBackButtonVisible={isBackButtonVisible}
          isCloseButtonVisible={isCloseButtonVisible}
          bottomScreenStyle={bottomScreenStyle}
        />
      </div>
      <PhoneNumberLoginController
        data-testid="sign-up-bottom-screen-phone"
        className={styles.phoneLoginContent}
        isOnlyPhoneNumberLoginFlow={isShowPhoneNumberLoginFlow}
        screenData={screenData}
        bottomScreenStyle={bottomScreenStyle}
      />
    </div>
  );
};

export default SignUp;
