import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  BI_LANDING_FIELD,
  EventFields,
  UIActionTarget,
} from "@analytics/enums";
import { currencyToSymbol } from "src/constants";
import { Currency } from "src/enums";
import { useUiAction } from "src/features/offers/cashier/imports/analytics";
import { userSelectors } from "state/selectors";

export const useCurrencyPickerBI = ({ triggerId }: { triggerId?: string }) => {
  const accountId = useSelector(userSelectors.getMyAccountId);

  const enrichUiAction = useCallback(
    (currency: Currency, prevCurrency: Currency) => ({
      position: currencyToSymbol[currency] || -1,
      [EventFields.USER_ID]: accountId,
      [BI_LANDING_FIELD.CURRENCY_CODE]: currency,
      [BI_LANDING_FIELD.PREVIOUS_CURRENCY_CODE]: prevCurrency,
      [BI_LANDING_FIELD.TRIGGER_ID]: triggerId,
    }),
    [accountId, triggerId]
  );

  const reportUiAction = useUiAction({
    target: UIActionTarget.CURRENCY_PICKER,
    additionalParams: enrichUiAction,
  });

  return {
    onChange: reportUiAction,
  };
};
