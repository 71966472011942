import { compose } from "redux";
import {
  ACME_RECEIVED,
  BLOCKED_USERS_LIST_BEGIN_FETCH,
  BLOCKED_USERS_LIST_END_FETCH,
} from "state/actionTypes";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
} from "state/hor/withFetcher";
import withUserSessionScope from "state/hor/withUserSessionScope";

export const actionCreators = createFetcherActions({
  beginFetchActionType: BLOCKED_USERS_LIST_BEGIN_FETCH,
  endFetchActionType: BLOCKED_USERS_LIST_END_FETCH,
});

export const selectors = {
  ...fetcherSelectors,
  getIsUpToDate: (state) => !state.isStale,
  getAllBlockedUsers: (state) => state.accountIds,
  getAllBlockedUsersArray: (state) => [...state.accountIds],
  isBlockedUser: (state, accountId) =>
    state.accountIds && state.accountIds.has(accountId),
};

const initialState = {
  accountIds: new Set(),
  isStale: true,
};

export default compose(
  withUserSessionScope,
  withFetcher({
    beginFetchActionType: BLOCKED_USERS_LIST_BEGIN_FETCH,
    endFetchActionType: BLOCKED_USERS_LIST_END_FETCH,
    initialData: initialState,
    extractData: (accounts) => ({
      isStale: false,
      accountIds: new Set(accounts),
    }),
    mergeData: (_, newData) => newData,
  })
)((state, action) => {
  switch (action.type) {
    case ACME_RECEIVED: {
      const { serviceName, serviceIdentifier } = action.payload;
      if (
        serviceIdentifier === "syncBlockList" &&
        serviceName === "abregistrar"
      ) {
        return { ...state, isStale: true };
      }
      return state;
    }
  }
  return state;
});
