import invariant from "fbjs/lib/invariant";
import * as modalDismissReason from "enums/modalDismissReason";
export const allModalDismissReasons = Object.values(modalDismissReason).filter(x => typeof x === 'string' || typeof x === 'number');
/* eslint-disable prefer-template */
if (process.env.NODE_ENV === "development") {
  import("lodash.uniq").then(({ default: uniq }) => 
    invariant(uniq(allModalDismissReasons).length === allModalDismissReasons.length, "modalDismissReason enum should contain unique values only!"));
}
export const isValidModalDismissReason = x => allModalDismissReasons.includes(x);
export const guardModalDismissReason = x => {
  invariant(isValidModalDismissReason(x), JSON.stringify(x) + " is not a valid modalDismissReason");
  return true;
};
export const validateMappingToModalDismissReason = map => {
  if (process.env.NODE_ENV === "development") {
    allModalDismissReasons.forEach(x => {
      invariant(map[x] !== undefined, JSON.stringify(x) + " of modalDismissReason is not mapped!");
   });
  }
};