export interface Story {
  approved?: number;
  approvedStatus?: number;
  created?: number;
  giftId: string;
  giftTimestamp?: number;
  gifterFirstName?: string;
  gifterId: string;
  gifterLastName?: string;
  gifterProfileThumbnailUrl?: string;
  gifterVipConfigId?: number;
  gifterVipLevel?: number;
  likedByMe?: boolean;
  likesCount?: number;
  score?: number;
  showGifter: boolean;
  storyId: string;
  streamerFirstName?: string;
  streamerId: string;
  streamerLastName?: string;
  streamerLiveStreamId?: string;
  streamerProfileThumbnailUrl?: string;
  streamerTotalDiamonds?: number;
  streamerTotalFollowers?: number;
  streamerVipConfigId: number;
  thumbnailLink?: string;
  videoLink?: string;
  watched?: boolean;
}

export enum ResponseCode {
  DUPLICATE_TRANSACTION = "DUPLICATE_TRANSACTION",
  FAILED_GENERAL = "FAILED_GENERAL",
  FAILED_UNAUTHORIZED = "FAILED_UNAUTHORIZED",
  FAILED_UNSUFFICIENT_BALANCE = "FAILED_UNSUFFICIENT_BALANCE",
  FAILED_WRONG_GIFT_ID = "FAILED_WRONG_GIFT_ID",
  OK = "OK",
}

export enum StoryType {
  BEST_BY_STREAMER = "bestByStreamer",
  BY_STREAMER = "byStreamer",
  FOLLOWING = "following",
  FOR_YOU = "forYou",
}

export enum PostTypeCode {
  MOMENTS = 62,
}

export interface BaseResponse {
  code: ResponseCode;
}

interface PostUserProfile {
  accountId: string;
  firstName?: string;
  lastName?: string;
  thumbnailUrl?: string;
  vipConfigId?: number;
}

interface PostGifts {
  pointsCount: number;
  topGifters: PostUserProfile[];
}

interface PostLikes {
  lastLikers: PostUserProfile[];
  likedByMe: boolean;
  totalCount: number;
}

interface SubscriptionDetails {
  streamer: {
    level: number;
  };
}

export interface Content {
  duration: string;
  giftId: string;
  giftTimestamp: number;
  gifterId: string;
  shownGifter: boolean;
  storyFolderName: string;
  storyId: string;
  subscribersOnly: boolean;
  thumbnailLink: string;
  videoLink: string;
}

export interface Post {
  commentsCount: number;
  content: Content;
  createdTimestamp: number;
  gifts: PostGifts;
  likes: PostLikes;
  owner: PostUserProfile;
  postId: number;
  subscriptionDetails: SubscriptionDetails;
  type: number;
  unlocked: boolean;
}

export interface MomentsResponseData extends BaseResponse {
  availableUnlockPostsCount: string;
  nextCursor: string;
  posts: Post[];
  previousCursor: string;
}

export interface MomentsResponse {
  details: Story[];
  nextCursor: string;
  previousCursor: string;
  stories: string[];
}
