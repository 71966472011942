export enum StickerType {
  GIFT = "GIFT",
  GOAL = "GOAL",
  IMAGE = "IMAGE",
  LUCKY_WHEEL = "LUCKY_WHEEL",
  UNKNOWN_TYPE = "UNKNOWN_TYPE",
  VOTE = "VOTE",
  WHEEL = "WHEEL",
}

export enum StickerStatus {
  ACTIVE = "ACTIVE",
  MODERATION_FAILED = "MODERATION_FAILED",
  PENDING_MODERATION = "PENDING_MODERATION",
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
}

export enum LiveStickerEventType {
  CREATED = "CREATED",
  DELETED = "DELETED",
  UPDATED = "UPDATED",
}

export type StickerImagePayload = {
  url: string;
};

export type StickerGoalPayload = {
  goal: number;
  progress?: number;
};

export type StickerGiftPayload = {
  collectionGoal?: number;
  collectionProgress?: number;
  giftId: string;
  gifterAccountId?: string;
};

export type StickerVoteOption = {
  id: string;
  lastVotedOrMyAccount?: {
    firstName?: string;
    guest?: boolean;
    id: string;
    lastName?: string;
    thumbnailUrl?: string;
    verified?: boolean;
    vipConfigId?: string;
  };
  numberOfVoters?: number;
  title?: string;
};

export type StickerVotePayload = {
  choiceLimit?: number;
  options?: StickerVoteOption[];
};

export type StickerWheelSegment = {
  emoji: string;
  id: string;
  text: string;
};

export type StickerWheelPayload = {
  configurationId: string;
  giftId: string;
  priceInCredits: number;
  segments: StickerWheelSegment[];
};

export type Sticker = {
  id: string;
  payload: string;
  posX?: number;
  posY?: number;
  rotation?: number;
  scale?: number;
  status: StickerStatus;
  text?: string;
  type: StickerType;
};

export type RichStickerEvent = {
  eventType: LiveStickerEventType;
  id: string;
  sticker: Sticker;
};

type BaseSticker = Omit<Sticker, "payload" | "type">;

export type ImageSticker = {
  payload: StickerImagePayload;
  type: StickerType.IMAGE;
} & BaseSticker;

export type GoalSticker = {
  payload: StickerGoalPayload;
  type: StickerType.GOAL;
} & BaseSticker;

export type GiftSticker = {
  payload: StickerGiftPayload;
  type: StickerType.GIFT;
} & BaseSticker;

export type VoteSticker = {
  payload: StickerVotePayload;
  type: StickerType.VOTE;
} & BaseSticker;

export type WheelSticker = {
  payload: StickerWheelPayload;
  type: StickerType.WHEEL;
} & BaseSticker;

export type StoredSticker =
  | GiftSticker
  | GoalSticker
  | ImageSticker
  | VoteSticker
  | WheelSticker;
