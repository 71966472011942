import { createListenerMiddleware } from "@reduxjs/toolkit";
import { LoginProvider, ModalType } from "src/enums";
import { loginSuccess } from "src/features/signin/state/login/actionCreators";
import { FETCH_WELCOME_ONBOARDING_OFFER_FULFILLED } from "src/features/welcomeOnboardingOffer/constants";
import { LOGIN_END } from "src/features/welcomeOnboardingOffer/imports/features";
import { loadWelcomeOnboardingOffer } from "src/features/welcomeOnboardingOffer/state/asyncAction";
import { GLOBAL } from "src/state/enums/modalScope";
import {
  getWelcomeOfferCampaignId,
  getWelcomeOfferEnable,
} from "state/abTests";
import { loadedServerOwnedConfig } from "state/actionCreators/configuration";
import { openModal } from "state/actionCreators/modal";
import { RootState } from "state/delegate";
import { loginSelectors } from "state/selectors";

export const openWelcomeOnboardingOfferAfterRegistrationMiddleware =
  createListenerMiddleware<RootState>();

openWelcomeOnboardingOfferAfterRegistrationMiddleware.startListening({
  type: LOGIN_END,
  effect: async (action, { dispatch, getState, condition }) => {
    if (await condition(loadedServerOwnedConfig.match)) {
      const state = getState();
      const isWelcomeOfferEnable = getWelcomeOfferEnable(state);
      const campaignId = getWelcomeOfferCampaignId(state);
      const isLoggedIn = loginSelectors.isLoggedIn(state);
      const isAutoLogin =
        (action as ReturnType<typeof loginSuccess>).payload.provider ===
        LoginProvider.TANGO_DISPOSABLE_TOKEN;

      if (isWelcomeOfferEnable && isLoggedIn && campaignId && !isAutoLogin) {
        dispatch(loadWelcomeOnboardingOffer({ campaignId })).then((data) => {
          if (
            data.type === FETCH_WELCOME_ONBOARDING_OFFER_FULFILLED &&
            typeof data.payload === "object"
          ) {
            if (data.payload?.campaignId !== campaignId) {
              return;
            }

            dispatch(
              openModal({
                modalType: ModalType.WELCOME_ONBOARDING_OFFER,
                modalData: {},
                modalScope: GLOBAL,
              })
            );
          }
        });
      }
    }
  },
});
