import { compose } from "redux";
import { ONE_CLICK_GIFTING } from "src/constants";
import { without } from "src/utils/miniLodash";
import {
  SET_GIFTS_DRAWER_VISIBILITY,
  SETTINGS_BEGIN_FETCH,
  SETTINGS_BEGIN_SAVE,
  SETTINGS_END_FETCH,
  SETTINGS_END_SAVE,
  TOGGLE_GIFTS_DRAWER,
} from "state/actionTypes";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
} from "state/hor/withFetcher";
import withUserSessionScope from "state/hor/withUserSessionScope";
export const persistConfig = {
  whitelist: ["giftsDrawerVisible", "settings"],
};

const defaultGiftsDrawerConfig = {
  canBackToGifts: true,
};

const initialState = {
  giftsDrawerVisible: true,
  giftsDrawerConfig: defaultGiftsDrawerConfig,
  settings: {},
  settingsPatches: [],
};

export const actionCreators = {
  ...createFetcherActions({
    beginFetchActionType: SETTINGS_BEGIN_FETCH,
    endFetchActionType: SETTINGS_END_FETCH,
  }),
  toggleGiftsDrawerVisibility: (meta = defaultGiftsDrawerConfig) => ({
    type: TOGGLE_GIFTS_DRAWER,
    meta,
  }),
  setGiftsDrawerVisibility: (state, meta = defaultGiftsDrawerConfig) => ({
    type: SET_GIFTS_DRAWER_VISIBILITY,
    payload: state,
    meta,
  }),
  beginSave: (patch) => ({ type: SETTINGS_BEGIN_SAVE, payload: patch }),
  endSave: (patch) => ({ type: SETTINGS_END_SAVE, payload: patch }),
  failedToSave: (error, patch) => ({
    type: SETTINGS_END_SAVE,
    payload: error,
    failedPatch: patch,
    error: true,
  }),
};

export default compose(
  withUserSessionScope,
  withFetcher({
    beginFetchActionType: SETTINGS_BEGIN_FETCH,
    endFetchActionType: SETTINGS_END_FETCH,
    initialData: initialState,
    extractData: (x) => ({ settings: x }),
    mergeData: (oldState, patch) => ({
      ...oldState,
      ...patch,
    }),
  })
)((state, action) => {
  switch (action.type) {
    case TOGGLE_GIFTS_DRAWER: {
      return {
        ...state,
        giftsDrawerVisible: !state.giftsDrawerVisible,
        giftsDrawerConfig: action.meta,
      };
    }
    case SET_GIFTS_DRAWER_VISIBILITY: {
      const visible = action.payload;
      if (state.giftsDrawerVisible === visible) {
        return state;
      }
      return {
        ...state,
        giftsDrawerVisible: visible,
        giftsDrawerConfig: action.meta,
      };
    }
    case SETTINGS_BEGIN_SAVE: {
      return {
        ...state,
        settingsPatches: [...state.settingsPatches, action.payload],
      };
    }
    case SETTINGS_END_SAVE: {
      if (action.error) {
        return {
          ...state,
          settingsPatches: without(state.settingsPatches, action.failedPatch),
        };
      }
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
        settingsPatches: without(state.settingsPatches, action.payload),
      };
    }
  }
  return state;
});

const mergedSettingsSelector = (state) =>
  Object.assign({}, state.settings, ...state.settingsPatches);

const getPreference = (state, key) => {
  const settings = mergedSettingsSelector(state);
  return settings[key];
};

export const selectors = {
  ...fetcherSelectors,
  getPreference,
  isSaveInProgress: (state) => state.settingsPatches.length > 0,
  isOneClickGiftingEnabled: (state) =>
    getPreference(state, ONE_CLICK_GIFTING) === "1",
  isGiftsDrawerVisible: (state) => state.giftsDrawerVisible,
  getAllSettingsMap: mergedSettingsSelector,
  getLocale: (state) => state.settings.locale,
  getGiftsDrawerConfig: (state) => state.giftsDrawerConfig,
};
