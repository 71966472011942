import once from "lodash.once";
import { getFacebookProfile } from "src/features/signin/api/login";
import { LoginProvider } from "src/features/signin/imports/enums";
import { getFacebookAppId } from "src/features/signin/imports/environment";
import { unlockTopModal } from "src/features/signin/imports/state";
import { loginHideInProgressFlag } from "src/features/signin/state/login/actionCreators";

const bindFacebookStorageListener = once(() => {
  const storageKey = "FACEBOOK_AUTH_RESULT";
  const storageListener = ({ key, newValue }) => {
    if (key === storageKey) {
      window.onFacebookAuthResult &&
        window.onFacebookAuthResult(JSON.parse(newValue));
      localStorage.removeItem(storageKey);
    }
  };
  window.addEventListener("storage", storageListener);
});

export const obtainFacebookCredential = (dispatch) => {
  const callbackUrl = `${document.location.origin}/socialNetworks/facebookCallback.html`;
  const facebookUrl = `https://www.facebook.com/v9.0/dialog/oauth?response_type=token&client_id=${getFacebookAppId()}&redirect_uri=${callbackUrl}&state=${
    document.location.pathname
  }&scope=public_profile,email`;
  bindFacebookStorageListener();
  const popup = window.open(facebookUrl);
  if (popup) {
    popup.onunload = () => {
      // fires at wrong time on chromium-based but still good enough probably - we don't get stuck with infinite loading
      // https://bugs.chromium.org/p/chromium/issues/detail?id=31262
      dispatch(unlockTopModal());
      dispatch(loginHideInProgressFlag());
    };
  } else {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject("Failed to open popup");
  }

  return new Promise((resolve, reject) => {
    window.onFacebookAuthResult = (authResult) => {
      window.onFacebookAuthResult = null;
      if (!authResult.token) {
        reject(authResult);

        return;
      }
      getFacebookProfile(authResult.token)
        .then((data) =>
          resolve({
            type: LoginProvider.FACEBOOK,
            token: authResult.token,
            facebookId: data.id,
            basicProfile: {
              firstName: data.first_name,
              lastName: data.last_name,
              email: decodeURIComponent(data.email),
            },
          })
        )
        .catch(reject);
    };
  });
};
