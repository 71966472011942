import React, { FC, memo } from "react";
import { useIntl } from "react-intl";
import { EmojiSpan } from "chat/imports/components";
import { BasicProfile } from "chat/imports/types";
import { formatDisplayName } from "chat/imports/utils";

interface MultiNameProps {
  members: BasicProfile[];
}
const MultiName: FC<MultiNameProps> = ({ members }) => {
  const intl = useIntl();

  return (
    <EmojiSpan>
      {members
        .map((basicProfile) =>
          formatDisplayName({
            intl,
            basicProfile,
          })
        )
        .join(", ")}
    </EmojiSpan>
  );
};

export default memo(MultiName);
