import { emitEvent } from "@analytics/emit";
import { LiveFeedType, PersonalFeedType } from "src/enums";
import { StoryType } from "src/types/happyMoments";

const A_NAME_HM_LIST_FETCH = "happy_moments_fetch";

const tabs = {
  [StoryType.FOLLOWING]: PersonalFeedType.FOLLOWING,
  [StoryType.FOR_YOU]: LiveFeedType.RECOMMENDED,
};
const convertTab = (type) => tabs[type];

export const emitSuccessfulListFetch = ({
  stories,
  details,
  nextCursor,
  previousCursor,
}) =>
  emitEvent(A_NAME_HM_LIST_FETCH, {
    result_code: 1,
    size: stories.length,
    nextCursor,
    previousCursor,
    unseen_count: details.filter((x) => !x.watched).length,
  });

export const emitFailedListFetch = (error) =>
  emitEvent(A_NAME_HM_LIST_FETCH, {
    result_code: error.status === 0 ? -1 : -5,
  });

export const emitPlayStart = ({ momentId, watched, streamerId, type }) =>
  emitEvent("happy_moment_play_start", {
    encrypted_happy_moment_id: momentId,
    unseen_count: +!watched,
    peer_id: streamerId,
    source: convertTab(type),
  });

export const emitPlayReady = ({ momentId, videoWidth, videoHeight }) =>
  emitEvent("happy_moment_play_ready", {
    encrypted_happy_moment_id: momentId,
    hd: +(Math.max(videoWidth, videoHeight) > 720),
  });

export const emitPlayLoop = ({ momentId }) =>
  emitEvent("happy_moment_play_cycle", {
    encrypted_happy_moment_id: momentId,
  });

export const emitPlayError = ({ momentId }) =>
  emitEvent("happy_moment_play_error", { encrypted_happy_moment_id: momentId });

export const emitPlayStop = ({
  momentId,
  duration,
  videoDuration,
  streamerId,
  type,
}) =>
  emitEvent("happy_moment_play_stop", {
    encrypted_happy_moment_id: momentId,
    result_code: duration ? (duration < videoDuration ? 1 : 2) : -1, // eslint-disable-line no-nested-ternary
    duration: Math.floor(duration),
    peer_id: streamerId,
    source: convertTab(type),
  });

export const emitMomentSeen = ({ momentId }) =>
  emitEvent("happy_moment_seen", { encrypted_happy_moment_id: momentId });

export const emitMomentNext = ({ momentId }) =>
  emitEvent("happy_moment_next", {
    encrypted_happy_moment_id: momentId,
    reason: "by_user",
  });

export const emitMomentHidden = ({ momentId, streamerId }) =>
  emitEvent("happy_moment_hidden", {
    encrypted_happy_moment_id: momentId,
    source: "profile",
    peer_id: streamerId,
  });
