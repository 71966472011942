import { createReducer } from "@reduxjs/toolkit";
import {
  initialFetcherStateMeta,
  AsyncState,
  addAsyncCasesToBuilderV2,
} from "state/hor/addFetcherActionsToBuilder";
import { getCountryByIp } from "state/actionCreators/countryByIp";
import { Nullable } from "src/types/common";

export type CountryByIpState = AsyncState<Nullable<string>, string>;

export const initialCountryByIpState = {
  data: null,
  meta: initialFetcherStateMeta,
};

export default createReducer<CountryByIpState>(
  initialCountryByIpState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      initialData: initialCountryByIpState.data,
      builder,
      action: getCountryByIp,
      prepareData: (oldData, newData) => newData,
    });
  }
);
