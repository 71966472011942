import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SCREEN_NAME } from "chat/imports/analytics";
import { ErrorIcon } from "chat/imports/assets";
import {
  Button,
  ScreenViewReporter,
  Typography,
} from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { sharedMessages } from "chat/imports/utils";
import styles from "./EmptyStateWithError.scss";

interface EmptyStateWithErrorProps {
  onClick: VoidFunction;
}

const EmptyStateWithError: FC<EmptyStateWithErrorProps> = ({ onClick }) => {
  const breakpoint = useBreakpointPrecise();
  const isMobile = [Breakpoints.MOBILE, Breakpoints.SMALL_MOBILE].includes(
    breakpoint
  );

  return (
    <div className={styles.root}>
      <ScreenViewReporter name={SCREEN_NAME.MAIN_CONVERSATIONS_ERROR} />
      <ErrorIcon className={styles.icon} />
      <div className={styles.text}>
        <Typography
          type={TYPOGRAPHY_TYPE.HEADLINE3}
          className={styles.title}
          as="div"
        >
          <FormattedMessage {...sharedMessages.oops} />
        </Typography>
        <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3} as="div">
          <FormattedMessage {...sharedMessages.feedSomethingWentWrong} />
        </Typography>
      </div>
      <Button
        size={isMobile ? ButtonSize.MEDIUM_32 : ButtonSize.BIG_48}
        variant={ButtonVariant.SECONDARY}
        onClick={onClick}
        className={styles.retry}
      >
        <FormattedMessage {...sharedMessages.retry} />
      </Button>
    </div>
  );
};

export default EmptyStateWithError;
