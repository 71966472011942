import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { ReactComponent as HandIcon } from "chat/assets/ic_hand.svg";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { useBreakpoint } from "chat/imports/hooks";
import styles from "./EmptyState.scss";

const EmptyState: React.FC = () => {
  const breakpoint = useBreakpoint();

  return (
    <Typography
      className={classnames(styles.root, styles[breakpoint])}
      type={TYPOGRAPHY_TYPE.PARAGRAPH3}
      as="div"
    >
      <div className={styles.container}>
        <HandIcon className={styles.hand} />
        <FormattedMessage
          id="chat.empty.state"
          defaultMessage="Share your thoughts and chat with friends!"
        />
      </div>
    </Typography>
  );
};

export default memo(EmptyState);
