import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import useEmitLinkSharedEvent, {
  RESULT_CANCELLED,
  RESULT_SHARED,
} from "@analytics/emitLinkSharedEvent";
import { BasicProfile } from "src/types/profile/profile";
import { formatDisplayName } from "ui/common/DisplayName";
import sharedMessages from "ui/common/intl/sharedMessages";
import { createProfileViralityLink } from "ui/utils/viralityLinkUtils";

export default ({
  accountId,
  basicProfile,
}: {
  accountId: string;
  basicProfile: BasicProfile;
}) => {
  const intl = useIntl();
  const emitLinkSharedEvent = useEmitLinkSharedEvent({
    linkType: "profile",
    linkTypeParam: accountId,
    peerId: accountId,
    sessionId: undefined,
  });

  const { firstName, lastName, aliases } = basicProfile;

  const title = useMemo(
    () =>
      intl.formatMessage(sharedMessages.profileViralityTitle, {
        name: formatDisplayName({
          intl,
          firstName,
          lastName,
        }),
      }),
    [firstName, lastName, intl]
  );

  const link = useMemo(
    () =>
      createProfileViralityLink({
        encryptedProfileId: accountId,
        alias: aliases?.[0],
      }),
    [accountId, aliases]
  );

  const afterShare = useCallback(
    () => emitLinkSharedEvent(link, RESULT_SHARED),
    [emitLinkSharedEvent, link]
  );

  const afterLinkCopied = useCallback(
    () => emitLinkSharedEvent(link),
    [emitLinkSharedEvent, link]
  );

  const onUnableToShare = useCallback(
    () => emitLinkSharedEvent(link, RESULT_CANCELLED),
    [emitLinkSharedEvent, link]
  );

  return useMemo(
    () => ({
      afterShare,
      afterLinkCopied,
      onUnableToShare,
      link,
      title,
    }),
    [afterShare, afterLinkCopied, onUnableToShare, link, title]
  );
};
