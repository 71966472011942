import { LoginProvider } from "src/enums";

const map = {
  [LoginProvider.TANGO_PHONE_NUMBER]: "phone",
  [LoginProvider.GOOGLE]: "google",
  [LoginProvider.FACEBOOK]: "facebook",
  [LoginProvider.APPLE]: "apple",
  [LoginProvider.TANGO_DISPOSABLE_TOKEN]: "autologin",
};

export default (loginProvider: LoginProvider) => map[loginProvider];
