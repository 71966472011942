interface LinkedDevices {
  clientOsVersion?: string;
  clientVersion?: string;
  deviceName?: string;
  platform?: string;
}

interface CtaStatusType {
  accountStatus: string;
  accountStatusMessage: string;
  accountStatusTitle: string;
}

interface SocialIdStatusType {
  iso2CountryCode: string;
  normalizedPrimaryNumber?: string;
  originalPrimaryNumber?: string;
  primaryEmail?: string;
}

enum RegistrationActionReturnType {
  DEVICE_LINKED = 9,
  PRE_REGISTER = 8,
  REGISTER = 2,
  REGISTER_VALIDATED = 7,
  UNDEFINED = 1,
  UPDATE = 3,
  VALIDATE = 4,
  VALIDATE_DEVICE = 5,
  VALIDATE_LINKING = 6,
}

interface JwtTokenData {
  token?: string;
  ttlSec?: number;
}

interface JwtAuthTokens {
  refreshToken?: JwtTokenData;
  sessionToken?: JwtTokenData;
}

enum VerificationProvider {
  VERIFICATION_PROVIDER_FB_ACCOUNT_KIT = 3,
  VERIFICATION_PROVIDER_TANGO_SMS = 1,
  VERIFICATION_PROVIDER_TWITTER = 2,
}

export interface TangoRegistrationResponseType {
  LinkedDevices: LinkedDevices;
  accountId?: string;
  authTokens?: string;
  cloudSecret?: string;
  code_emailed?: boolean;
  conflictAccountId?: string;
  ctaStatus: CtaStatusType;
  jwtAuthTokens?: JwtAuthTokens;
  preferredVerificationProvider?: VerificationProvider;
  reason?: string;
  registrationAction?: RegistrationActionReturnType;
  socialIdStatus?: SocialIdStatusType;
  type?: string;
}
