import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { callAF } from "@analytics/appsFlyerWrapper";
import { EventFields, EventNames, PlatformsTypes } from "@analytics/enums";
import {
  A_PARAM_LIVE_SESSION_START,
  A_PARAM_LIVE_SESSION_STOP,
} from "@analytics/eventEnums";
import {
  STREAM_LOGGING_EVENT_START,
  STREAM_LOGGING_EVENT_STOP,
} from "@analytics/playbackLogging";
import { getLogAnalytics } from "environment";
import { LoginProvider } from "src/enums";
import { currentTimeMillis } from "src/utils/dateUtils";
import { getInMemoryStore } from "src/utils/getStoreHandler";
import { getJoshApi } from "src/utils/joshUtils";
import { isStandalone } from "src/utils/locationUtils";

let userProperties = {};

export const EVENT = "event";
const DEFAULT_JOSH_SECTION_NAME = "tango";

const updateInteractionIdActions = [
  EventNames.SEND_GIFT,
  EventNames.IN_APP_PURCHASE,
  EventNames.FOLLOW_UNFOLLOW,
];
const liveSectionActionTypes = [
  A_PARAM_LIVE_SESSION_START,
  A_PARAM_LIVE_SESSION_STOP,
];

const watchStreamActionTypes = [
  STREAM_LOGGING_EVENT_START,
  STREAM_LOGGING_EVENT_STOP,
];

function emit() {
  /* eslint-disable prefer-rest-params, no-console */
  // Yes, we truly need 'arguments' here,
  // gtag processes various array entry differently (objects, arrays and arguments-arrays).
  getLogAnalytics() && console.log("analytics", ...arguments);
  window.dataLayer.push(arguments);

  // function onJTEvent(event_section:String, event_name: Sring, args : Map<String, String>){
  // event_section // can be Tango home feed , creation , purchase etc… if you guys are tracking as different section - otherwise default can be Tango
  // event_name // Name of the event
  // args // complete map which is needed to understand to the user action , navigation etc….

  getJoshApi()?.onJTEvent(
    DEFAULT_JOSH_SECTION_NAME,
    arguments[1],
    JSON.stringify(arguments)
  );
}

export const emitEvent = (action, params = {}) => {
  const eventParams = {
    [EventFields.TIMESTAMP]: currentTimeMillis(),
    [EventFields.DEVICE_SOURCE]: getInMemoryStore()?.deviceInfo?.type,
    [EventFields.ANALYTICS_KEY]: getInMemoryStore()?.marketing?.analyticsKey,
    [EventFields.PLATFORM_TYPE]:
      getInMemoryStore()?.login?.loggedInProvider ===
      LoginProvider.TANGO_DISPOSABLE_TOKEN
        ? PlatformsTypes.WEB_VIEW
        : PlatformsTypes.WEB,
    [EventFields.PLATFORM]: PlatformsTypes.WEB,
    [EventFields.TWC_VERSION]: GENERATED_APP_INFO.fullVersion,
    [EventFields.IS_STANDALONE]: isStandalone,
    ...userProperties,
    ...params,
  };

  if (typeof eventParams.username !== "string") {
    eventParams.username = "bad username";
  } else if (eventParams.username === "") {
    eventParams.username = "empty username";
  }

  /* This block adds analytics headers to all subsequent requests, also adds interaction_id field to params */
  const needInteractionId = updateInteractionIdActions.includes(action);
  const isStartStopStreamAction =
    action === EventNames.LIVE_SESSION &&
    liveSectionActionTypes.includes(params[EventFields.ACTION]);

  const isStartStopWatchStreamAction =
    action === EventNames.LIVE_PLAY &&
    watchStreamActionTypes.includes(params[EventFields.ACTION]);

  if (
    needInteractionId ||
    isStartStopStreamAction ||
    isStartStopWatchStreamAction
  ) {
    eventParams[EventFields.INTERACTION_ID] = AnalyticsUtils.interactionId;
  }

  emit(EVENT, action, eventParams);
};

export const emitAF = (params) => {
  getLogAnalytics() && console.log("AF event:", params);
  callAF("pba", "event", {
    eventType: "EVENT",
    ...params,
  });
};

export const setUserProperties = (newProps = {}) => {
  userProperties = newProps;
  emit("set", "user_properties", newProps);
};

export default emit;
