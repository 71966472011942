import { useSelector } from "react-redux";
import { getOfflineChatAlternativeDomainContentSupportEnabled } from "chat/imports/state";
import {
  isAlternativeDomain,
  replaceDomainWithHostname,
} from "chat/imports/utils";

export const useAlternativeDomainContentSupport = <
  T extends string | undefined,
>(
  url: T
) => {
  const isAlternativeDomainSupported = useSelector(
    getOfflineChatAlternativeDomainContentSupportEnabled
  );

  return url && isAlternativeDomain() && isAlternativeDomainSupported
    ? replaceDomainWithHostname(url)
    : url;
};
