import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSalesTax } from "api/taxes";
import { isApiError } from "api/utils/enhancedFetch";
import { Currency } from "src/enums";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { taxSelectors } from "state/selectors";
import { TaxesState } from "state/types";

export const getTaxes = createAsyncThunk<
  Data<TaxesState>,
  { currency: Currency } & FetcherMetaV2,
  { rejectValue: string; state: RootState }
>(
  "lwc/taxes/getTaxes",
  async ({ currency }, api) => {
    try {
      const { taxPercentage } = await fetchSalesTax(currency);

      return taxPercentage;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta = taxSelectors.meta(state);

      return !meta.loading;
    },
  }
);
