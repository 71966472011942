import { defineMessages } from "react-intl";
import { LiveFeedType, PersonalFeedType } from "src/enums";

export const feedModesTitles = defineMessages({
  [PersonalFeedType.FOLLOWING]: {
    id: "live-feed.mode.following",
    defaultMessage: "Following",
  },
  [LiveFeedType.RECOMMENDED]: {
    id: "home_fragment_for_you",
    defaultMessage: "For You",
  },
  [LiveFeedType.LANDSCAPE]: {
    id: "live-feed.chips.tab.landscape",
    defaultMessage: "Landscape",
  },
});
