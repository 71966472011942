import React, { FC, memo } from "react";
import { PostDate } from "legal/components";
import { Headline, ListItem, Paragraph } from "legal/components/typography";
import { SCREEN_NAME, ScreenViewReporter } from "legal/imports/analytics";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import {
  LinkToEurLex,
  LinkToEuropeanAdequacyDecisions,
  LinkToPrivacyPolicy,
} from "legal/links";
import styles from "legal/common/LegalArticle.scss";

const checkboxContent = {
  dataSubjects: [
    {
      label: "TangoMe's end-users (Broadcasters and Viewers)",
      checked: true,
    },
    { label: "TangoMe's employees", checked: false },
    { label: "TangoMe's customers", checked: false },
    { label: "Other: ________", checked: false },
  ],
  categoriesOfPersonalData: [
    {
      label:
        "Contact information (name, age, gender, address, telephone number, email address etc.)",
      checked: false,
    },
    {
      label:
        "Financial and payment data (e.g. credit card number, bank account, transactions)",
      checked: true,
    },
    { label: "Governmental IDs (passport, driver's license)", checked: false },
    {
      label:
        "Device identifiers and internet or electronic network activity (IP addresses, GAID/IDFA, browsing history, timestamps)",
      checked: true,
    },
    { label: "Geo-location information", checked: false },
    { label: "Other: ________", checked: false },
  ],
  specialCategoriesOfData: [
    {
      label: "None",
      checked: false,
    },
    { label: "Genetic or biometric data", checked: false },
    { label: "Racial or ethnic origin", checked: false },
    {
      label: "Political opinions, religious or philosophical beliefs",
      checked: false,
    },
    { label: "Other: ________", checked: false },
  ],
  theFrequencyOfTheTransfer: [
    { label: "One-off", checked: false },
    { label: "Continuous", checked: true },
    { label: "Other: ________", checked: false },
  ],
  natureOfTheProcessing: [
    {
      label: "Collection",
      checked: false,
    },
    { label: "Recording", checked: false },
    { label: "Organization or structuring", checked: false },
    {
      label: "Storage",
      checked: false,
    },
    { label: "Retrieval", checked: false },
    { label: "Consultation", checked: false },
    {
      label: "Disclosure, dissemination or otherwise making available",
      checked: false,
    },
    { label: "Analysis", checked: true },
    { label: "Erasure or destruction", checked: false },
    { label: "Other: ________", checked: false },
  ],
};

/* eslint-disable react/jsx-no-literals, react/no-unescaped-entities */
const PublisherTermsAndConditions: FC = () => (
  <>
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_PUBLISHER_TERMS_CONDITIONS} />
    <FormattedMessageTitle {...legalMessages.publisherTermsAndConditions} />

    <article className={styles.article}>
      <PostDate>Last Modified: 21.03.2022</PostDate>
      <Headline level={1}>Publisher Terms & Conditions</Headline>
      <Paragraph>
        Publisher Terms & ConditionsThis TangoMe Publisher Agreement ("
        Agreement") is entered by and between TangoMe Inc., a legal entity
        organized under the laws of the State of Delaware under registration
        number 4728891 and having its business and registered office located at
        3500 S. Dupont Highway, Dover, Delaware 19901, USA ( "TangoMe", "we",
        "our", "us" ), and the company or entity that you represent (
        "Publisher"). Each a "Party" and together the "Parties".By signing an IO
        with TangoMe, the Publisher agrees to be bound by this Agreement. If you
        do not agree to all of the terms of this Agreement, do not download, use
        or access TangoMe's services. In case the Publisher enters this
        agreement on behalf of a company or other legal entity, it represents
        and warrants that it has the full authority to bind such company or
        other legal entity to this Agreement.
      </Paragraph>
      <Headline level={2}>Definitions.</Headline>
      <Paragraph subParagraph>
        "Action" shall include any of the following: view, click, installation
        of any software or application, or any other legitimate human action, as
        applicable and agreed upon between the Parties, which is linked to an
        Ad, provided the foregoing was performed by an actual end user in the
        normal course of using any device. This excludes clicks, views, or any
        other action performed by robots, software or any Fraudulent Activities,
        or any other illegitimate tools and/or behaviors which are not within
        the scope of this Agreement.
      </Paragraph>
      <Paragraph subParagraph>
        "Ad(s)" or "Advertisement(s)" shall mean any promotional content, in
        whatever format (including without limitation text, graphics, native,
        in-text, video, audio, rich media and links), provided by TangoMe to be
        served through or displayed on the Media Properties.
      </Paragraph>
      <Paragraph subParagraph>
        "Approved Transactions" shall mean an Action, excluding any Action
        which: (i) resulted from or engaged with Fraudulent Activity, as
        determined by TangoMe in its sole discretion; (ii) was performed by the
        Publisher's employees, agents or contractors; or (iii) violates the
        Campaign Conditions.
      </Paragraph>
      <Paragraph subParagraph>
        "Campaign Conditions" means conditions and/or restrictions imposed by
        TangoMe for the promotion and distribution of the Ads.
      </Paragraph>
      <Paragraph subParagraph>
        "Confidential Information" shall mean any non-public, proprietary,
        confidential and/or trade secret information of a Party hereof, whether
        furnished before or after the Effective Date, and regardless of the
        manner in which it is furnished, and which given the totality of the
        circumstances, a reasonable person or entity should have reason to
        believe is proprietary, confidential, or competitively sensitive,
        including, without limitation, research and development, formulas,
        programming, know-how, proprietary knowledge, technology and any related
        documentation, engineering, production, operation and any enhancements
        or modifications relating thereto, logos, graphics, text, images, and
        other designs, drawings, engineering notebooks, industrial models,
        software and specifications, data and performance, financial and
        marketing information, business plans, business procedures, clients’
        list, business partners or other information disclosed by one of the
        Parties hereto (the “Disclosing Party“) to the other Party (the{" "}
        “Receiving Party“) either directly or indirectly in writing, orally or
        by drawings. Confidential Information shall not, however, include any
        information which: (i) was known to the Receiving Party or in its
        possession at the time of disclosure without any confidentiality
        obligation; (ii) becomes publicly known and made generally available
        after disclosure by the Disclosing Party to the Receiving Party through
        no action or inaction of the Receiving Party; (iii) is independently
        developed by the Receiving Party without reliance on or use of the
        Confidential Information or any part thereof and the Receiving Party can
        show written proof of such independent development; (iv) is required to
        be disclosed by applicable law, regulatory authority or a valid court
        order, provided that the Receiving Party shall provide the Disclosing
        Party with reasonable prior written notice of the required disclosure in
        order for the Disclosing Party to obtain a protective order and the
        disclosure shall be limited to the extent expressly required; (v) is
        approved for release by prior written authorization of the Disclosing
        Party; or (vi) the Receiving Party can demonstrate was disclosed by the
        Disclosing Party to a third party without any obligations of
        confidentiality. TangoMe’s Confidential Information includes the terms
        and pricing of the IO and this Agreement.
      </Paragraph>
      <Paragraph subParagraph>
        "CPA" means cost per Action or engagement and refers to the amount paid
        by TangoMe for each specific engagement, as shall be defined by TangoMe.
        For example, engagement shall mean purchase of in-app virtual goods,
        such as virtual coins. In this case, if any chargebacks or refunds are
        made by a user, TangoMe shall not pay Publisher for such engagement.
      </Paragraph>
      <Paragraph subParagraph>
        "CPC" means cost per click and refers to the amount paid by TangoMe for
        each click on an Ad.
      </Paragraph>
      <Paragraph subParagraph>
        "CPI" means cost per install and refers to the amount paid by TangoMe
        for each install of its Application followed by registration to the
        Application. If any of the above actions is not made by an end user,
        TangoMe shall not pay Publisher for such install. In target CPI
        campaigns TangoMe shall not divert from the agreed target, and any
        deviation of more than 10% of the agreed target, shall not be paid by
        TangoMe.
      </Paragraph>
      <Paragraph subParagraph>
        "CPM" means cost per mille and refers to the amount paid by TangoMe for
        one thousand (1,000) impressions of an Ad. It is agreed that the
        Publisher shall guarantee the number of impressions set forth in the
        Insertion Order or otherwise agreed by the Parties.
      </Paragraph>
      <Paragraph subParagraph>
        "Fraudulent Activity" shall mean (a) the display, promotion,
        distribution or interaction with the Advertisements in any manner which
        engages with anything other than natural persons viewing actually
        displayed Advertisements in the normal course of using any device,
        including, without limitation, browsing through online, mobile or any
        other technology or platform, as determined by TangoMe in its sole
        discretion which may lead to falsely generated or artificially-inflated
        revenues and performances; and/or (b) the automatic redirection of
        visitors, blind text links, misleading links, or forced clicks, from the
        Advertisements. Without limiting the foregoing, Fraudulent Activity
        shall include any of the following practices: (i) inclusion or counting
        of views or clicks: by a natural person who has been engaged for the
        purpose of viewing the Advertisements, whether exclusively or in
        conjunction with any other activities of that person (including, without
        limitation, employing any means to induce, encourage, incentivize or
        trick the end user into viewing or clicking on the Advertisements);
        and/or by non-human visitors (such as bots, phishing); and/or that are
        not actually visible to the human eye, discernible to human senses or
        perceived by a human being; (ii) masking or cloaking any URL, or
        employing any means to obscure the true source of traffic, or conceal
        conversions; (iii) generating automated, fraudulent or otherwise invalid
        impressions, inquiries, views, clicks or conversions, or artificially
        inflating impressions, inquiries, views, clicks, or conversions, or
        employing any misleading or practices (such as repeated manual clicks);
        (iv) Installing or transmitting Malicious Code; and (vi) any actions
        which contradicts, challenges or contravenes the end user's independent
        and free will to engage with any content.
      </Paragraph>
      <Paragraph subParagraph>
        "Insertion Order" or "IO" means a mutually agreed document which
        incorporates this Agreement by reference and contains specific
        instructions regarding the services provided herein.
      </Paragraph>
      <Paragraph subParagraph>
        "Malicious Code" shall mean viruses, worms, malware, spyware, adware,
        time bombs, Trojan horses, drive-by download applications or other
        harmful or malicious code, files, scripts, agents or programs, including
        code that: (i) is intended to or has the effect of misappropriating,
        hijacking, commandeering, or disrupting access to or use or operation of
        any information, device, hardware, system or network; or (ii) materially
        interferes with or disrupts the end users’ web or mobile navigation or
        intervenes with the end users’ control over the operating system,
        browser settings, browser functionality or webpage display.
      </Paragraph>
      <Paragraph subParagraph>
        "Media Properties" means any desktop websites, affiliate websites,
        mobile websites, and/or apps, owned by, aggregated by, or under the
        control of Publisher, or on which Publisher has a license to offer Ads'
        placement.
      </Paragraph>
      <Paragraph subParagraph>
        "Objectionable Content" shall mean content that promotes, contains,
        refers or has links to content that is: (i) pornographic, sexually
        explicit or obscene, (ii) harassing, threatening, abusive, inflammatory
        or racist; (iii) illegal, contrary to public policy or that could
        facilitate the violation of any applicable law, regulation or government
        policy; (iv) libelous or defamatory; (v) is misleading or deceptive;
        (vi) violates the Proprietary Rights, or the privacy, publicity, moral
        or any other right of any third party; (vii) offers or disseminates any
        counterfeit or fraudulent goods, or services, schemes, investment
        opportunities or promotions or advice not permitted by law; (viii)
        promotes the use of drugs or drug paraphernalia, illegal substances or
        dangerous products; (ix) promotes illegal products or services; or (x)
        harmful to TangoMe, or includes Malicious Code.
      </Paragraph>
      <Paragraph subParagraph>
        "Proprietary Rights" shall mean all intellectual property rights,
        including, without limitation: (a) all inventions, whether patentable or
        not, all improvements thereto and derivatives thereof, and all patents
        and patent applications; (b) all registered and unregistered: marks,
        trademarks, service marks, trade names, trade dress and associated
        logos, domain names and registrations and applications for registration
        thereof; (c) all copyrights in copyrightable works, all other rights of
        authorship, including without limitation moral rights, and all
        applications and registrations in connection therewith; (d) all trade
        secrets and Confidential Information; (e) all rights in databases and
        data compilations, whether or not copyrightable; and (f) all copies and
        tangible embodiments of any or all of the foregoing (in whatever form,
        including electronic media).
      </Paragraph>
      <Headline level={2}>Scope of the Campaign.</Headline>
      <Paragraph subParagraph>
        TangoMe hereby grants Publisher the right during this Agreement to place
        Advertisements as specifically identified and described in the Insertion
        Order. For that purpose, Publisher is responsible to comply with all the
        requirements and Ads placement restrictions specified therein (the
        "Campaign").
      </Paragraph>
      <Paragraph subParagraph>
        The Publisher undertakes that it will not place the Ads other than in
        the advertising space agreed upon in the IO. To the extent Publisher
        does not comply and/or respect its binding obligations as detailed in
        the IO, TangoMe shall have the right to discontinue the Campaign at any
        time with notice to Publisher and without further liability.
      </Paragraph>
      <Paragraph subParagraph>
        All Ads must be delivered in the proper format and geographical location
        as specified in the IO. TangoMe shall not be liable to pay for any Ads
        delivered outside the agreed geographic locations or for any Ads which
        do not correspond to the specifications of the IO. Publisher hereby
        understands and consents to not add, modify, alter, change or delete
        content, text or appearance of the Advertisement without the prior
        written approval of TangoMe, except where reasonably required for the
        purpose of performance of its obligations under this Agreement.
      </Paragraph>
      <Paragraph subParagraph>
        The Publisher agrees to undertake and place Ads in compliance with this
        Agreement and will make its best efforts to position Ads in such manner
        to assure that they are fully and clearly visible to end users and
        displayed in accordance with the terms of the IO and this Agreement and
        any other agreed upon specifications provided by TangoMe to the
        Publisher from time to time, at its sole discretion.
      </Paragraph>
      <Paragraph subParagraph>
        Publisher grants TangoMe the right during this Agreement to modify,
        replace or make any other changes to the Ads that are used, displayed,
        or placed by the Publisher to track impressions (either directly or
        through a third party), to conduct appropriate tests in order to verify
        the end user's interaction with the Publisher's content or any kind of
        content related to TangoMe.
      </Paragraph>
      <Headline level={2}>Restrictions.</Headline>
      <Paragraph subParagraph>
        Except as set forth expressly herein or as permitted by TangoMe,
        Publisher shall not knowingly permit itself or any third party to: (a)
        place Ads on any Media Properties that contains, promotes, refers or has
        links to Objectionable Content; (b) place stack Ads; (c) alter, modify,
        change, crop, create derivative works of, bypass, truncate, reorder the
        Ads in any way or any other creative and substantive materials, in
        whatever format, provided by TangoMe for the purpose of the delivery of
        the Ads without TangoMe’s prior written consent; (d) place Ads on
        non-approved Media Properties or websites; or (e) engage in any way with
        Fraudulent Activity.
      </Paragraph>
      <Paragraph subParagraph>
        To the extent any of the restrictions set forth above are not
        enforceable under applicable law, Publisher shall inform TangoMe in
        writing prior to engaging in any of the applicable activities.
      </Paragraph>
      <Paragraph subParagraph>
        Publisher may not use robots, spiders, scraping or other technology to
        access or use the Ads or to obtain any information beyond what TangoMe
        provides to Publisher under this Agreement.
      </Paragraph>
      <Paragraph subParagraph>
        Publisher may not use the Ads to syndicate, mediate or broker campaigns
        or the distribution of Ads through other third parties or affiliates,
        without the express written approval of TangoMe. Publisher shall make
        available to TangoMe, upon request, with any information relating to the
        Media Properties and any campaign, and shall ensure that the
        distribution or promotion of the Ads is in compliance with the Campaign
        Conditions.
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe reserves the right to withhold and/or offset any payments in
        case of failure of Publisher to observe the terms of this section.
      </Paragraph>
      <Headline level={2}>Warranties.</Headline>
      <Paragraph subParagraph>
        Mutual Warranties. Each Party represents and warrants that: (a) it is
        duly organized under applicable law and has sufficient authority to
        enter into this Agreement; and that (b) the execution and performance
        under this Agreement does not conflict with any other contractual
        obligations such Party has to any third party.
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe Warranties. TangoMe represents and warrants that the Ads: (a) do
        not, to the best of its knowledge, infringe the intellectual property
        rights of any third party; (b) comply with all applicable law and
        regulations (provided, that with respect to data provided by Publisher
        to TangoMe, TangoMe’s compliance with applicable law is subject to
        Publisher's full compliance with applicable law with respect to such
        data, including its transfer to, and processing by TangoMe).
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe reserves the right, in its sole discretion and without
        liability, to reject or remove any Ads from the Media Properties.
      </Paragraph>
      <Paragraph subParagraph>
        Publisher Warranties. Publisher represents and warrants that its Media
        Properties: (a) do not, to the best of its knowledge, infringe the
        intellectual property rights of any third party; (b) do not contain any
        Objectionable Content, and is not directed to or primarily appeals to
        children under the age of 13; (c) complies with all applicable laws and
        regulations, including applicable data protection laws; (d) do not
        collect, use or transfer the data of end users in any manner not clearly
        and accurately disclosed pursuant to a privacy policy that complies with
        applicable law and regulations; and (e) do not contain any Malicious
        Code. Publisher represents and warrants that it will: (a) obtain
        TangoMe's Ads only in a lawful manner and in accordance with the terms
        of this Agreement; and (b) it will delete any content, Ads, or any other
        information related to this Agreement upon TangoMe's request.
      </Paragraph>
      <Headline level={2}>Intellectual Property.</Headline>
      <Paragraph subParagraph>
        Publisher shall have all right, title and interest in its Media
        Property. TangoMe retains all right, title and interest in the
        Proprietary Rights of the Ads, as well as any derivative therefrom. If
        Publisher provides TangoMe with any feedback regarding the Ads, TangoMe
        may use all such feedback without restriction and without granting the
        Publisher any compensation for it. Nothing herein shall be interpreted
        to provide Publisher any rights in the Ads except the limited right to
        use and receive the Ads as set forth herein.
      </Paragraph>
      <Paragraph subParagraph>
        Nothing in this Agreement shall be construed as providing the Publisher
        a right to use any of TangoMe or its affiliates’ trade names,
        trademarks, service marks, logos, or other distinctive brand features.
      </Paragraph>
      <Headline level={2}>Payment.</Headline>
      <Paragraph subParagraph>
        Subject to the terms herein, TangoMe shall make payments to Publisher
        subject to the terms communicated to the Publisher and as set out in the
        IO, solely in consideration for Approved Transactions. The payments due
        to Publisher ( "Consideration") shall be in accordance with the pricing
        model determined in the IO (i.e. CPC, CPI, CPA, CPM) and the terms of
        this Agreements.
      </Paragraph>
      <Paragraph subParagraph>
        The Consideration shall be calculated and based on TangoMe’s tracking
        systems (provided by AppsFlyer), which shall be considered final and
        binding, and no other measurements or statistics of any kind shall be
        accepted or have any effect. TangoMe shall make available to Publisher
        such reports on a monthly basis. It is hereby clarified and agreed that
        TangoMe shall have no obligation to pay for any Action that is not an
        Approved Transaction.
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe reserves the right to request from Publisher provide its server
        log files which includes all the relevant information related to the Ads
        Campaign and may determine at its sole discretion whether Publisher has
        delivered Approved Transactions to TangoMe.
      </Paragraph>
      <Paragraph subParagraph>
        Notwithstanding anything to the contrary, Consideration shall be made
        solely for Approved Transactions, and TangoMe shall not be obligated to
        remit Consideration, and shall be entitled to withhold Consideration or
        demand a refund (in the event any such Consideration was already paid)
        if determined by TangoMe, at its sole discretion, that Publisher has
        engaged in Fraudulent Activity and/or was in breach of this Agreement or
        that Consideration was paid for Approved Transactions that are later
        determined to have not met the requirements set forth herein to be an
        Approved Transaction.
      </Paragraph>
      <Paragraph subParagraph>
        Publisher is solely responsible for providing and maintaining accurate
        contact and payment information associated with Publisher's account. Any
        bank fees and other commissions incurred by TangoMe due to any error or
        omission of contact or payment information may be deducted by TangoMe
        from any Consideration due to Publisher. It is hereby clarified that
        Publisher shall not be entitled to receive any additional payment except
        for the Consideration agreed upon by TangoMe and as communicated to
        Publisher.
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe reserves the right to deduct, set off, claw back or charge back
        any amounts Publisher may owe to TangoMe against any amounts payable or
        otherwise owing to Publisher.
      </Paragraph>
      <Paragraph subParagraph>
        All payments due to Publisher under this Agreement will be exclusive of
        taxes, duties, levies, tariffs, and other governmental charges
        (including, without limitation, VAT, if applicable) (collectively,{" "}
        "Taxes"). Publisher will be responsible for payment of all Taxes and any
        related interest and penalties resulting from any payments made
        hereunder, other than any taxes based on TangoMe’s net income. TangoMe
        may be obligated by law to obtain tax information from Publisher and
        payments to Publisher may be withheld until Publisher provides this
        information or otherwise satisfies TangoMe that Publisher is not a
        person from whom TangoMe is required to obtain tax information or, if
        required by applicable law, may be subject to tax withholding.
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe reserves the right to make payments only when the monthly
        payment due to the Publisher exceeds USD$500 (in the event Publisher
        elected to receive payment by wire transfer; in either case, "Minimum
        Balance").
      </Paragraph>
      <Paragraph subParagraph>
        If the monthly payment does not exceed the Minimum Balance, TangoMe
        shall have the right to roll over such payments to the following month
        until such time when the total payment owed to Publisher exceeds the
        Minimum Balance.
      </Paragraph>
      <Paragraph subParagraph>
        The Consideration shall be remitted to Publisher in USD within net
        thirty (30) days as of date of issuance of invoice and subject to its
        approval by TangoMe, by wire transfer to the account specified by the
        Publisher in its Account. All the fees and/or commissions related to the
        payment shall be at the exclusive charge of Publisher.
      </Paragraph>
      <Headline level={2}>Data Protection.</Headline>
      <Paragraph subParagraph>
        Publisher represents and warrants that: (a) Publisher is permitted to
        collect, use and transfer data, including personal data (as this term is
        defined under applicable laws and regulations; "Data") in the context of
        placing the Ads on the Media Properties; (b) it has provided its end
        users with sufficient notice (including through an adequate and
        accessible privacy policy) and obtained their permissions, as required
        by applicable laws and regulations, as well as any applicable mobile
        application marketplace’s policies and requirements (such as Google Play
        or the App Store, as applicable), in connection with the collection, use
        and disclosure of Data, including with respect to the use of any
        technological methods for the purpose of collecting such Data (such as
        cookies), including for the purpose of displaying interest-based or
        targeted content; and (c) it shall collect, use or disclose Data in
        accordance with any applicable laws and with its privacy policy.
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe may use the Data in accordance with applicable laws and
        regulations, and with its own privacy policy, available at{" "}
        <LinkToPrivacyPolicy>
          https://www.tango.me/privacy-policy.
        </LinkToPrivacyPolicy>
      </Paragraph>
      <Paragraph subParagraph>
        Publisher shall not provide to TangoMe any data regarding children under
        the age of 13, or any health, financial, or insurance data or other data
        which may be considered as of sensitive nature.
      </Paragraph>
      <Paragraph subParagraph>
        By entering into the Agreement, Publisher hereby agrees to the terms of
        the Data Protection Addendum, which is incorporated herein by reference.
      </Paragraph>
      <Headline level={2}>Confidentiality.</Headline>
      <Paragraph subParagraph>
        During the Term and thereafter, each Party agrees that it will not
        disclose or use the Confidential Information of the Disclosing Party
        without the Disclosing Party’s prior written consent. Each Party agrees
        that it will take reasonable steps, at least substantially equivalent to
        the steps it takes to protect its own Confidential Information, during
        the Term and for a period of three (3) years thereafter to prevent the
        disclosure of the Disclosing Party’s Confidential Information other than
        to its employees, affiliates, subsidiaries or other agents who must have
        access to such Confidential Information for such Party to perform its
        obligations or exercise its rights hereunder, who will each agree to
        comply with this section.
      </Paragraph>
      <Paragraph subParagraph>
        Upon termination or expiration of this Agreement, Receiving Party shall,
        upon written request, return to the Disclosing Party or destroy (at the
        Disclosing Party’s discretion) all materials containing Confidential
        Information (if feasible).
      </Paragraph>
      <Headline level={2}>Indemnification.</Headline>
      <Paragraph subParagraph>
        TangoMe Indemnification. TangoMe shall defend, indemnify and hold
        harmless Publisher (and its affiliates, officers, directors and
        employees) from and against any and all damages, costs, losses,
        liabilities or expenses (including court costs and reasonable attorneys’
        legal fees; (collectively Damages)) which Publisher may suffer or incur
        in connection with any actual claim, demand, action or other proceeding
        by any third party ( "Claim") arising from a claim that the Ads infringe
        the intellectual property rights of a third party. Notwithstanding the
        foregoing, TangoMe shall have no responsibility or liability in any
        manner whatsoever for any claim to the extent resulting from or arising
        out of: (a) the use of the Ads not in compliance with this Agreement or
        applicable law; and (b) the modification of the Ads by any party other
        than TangoMe.
      </Paragraph>
      <Paragraph subParagraph>
        Publisher Indemnification. Publisher shall defend and indemnify TangoMe
        (and its affiliates, officers, directors and employees) from and against
        any and all Damages that TangoMe may suffer or incur in connection with
        any Claim arising from: (a) any breach of Publisher's obligations,
        representations or warranties herein; or (b) any use of the Ads in
        violation of any applicable law or regulations.
      </Paragraph>
      <Paragraph subParagraph>
        Procedure. The obligations of either Party to provide indemnification
        under this Agreement will be contingent upon the indemnified party: (i)
        providing the indemnifying party with prompt written notice of any claim
        for which indemnification is sought (provided that the indemnified
        party’s failure to notify the indemnifying party will not diminish the
        indemnifying party’s obligations under this Section 9 except to the
        extent that the indemnifying party is materially prejudiced as a result
        of such failure); (ii) cooperating fully with the indemnifying party (at
        the indemnifying party’s expense); and (iii) allowing the indemnifying
        party to control the defense and settlement of such claim, provided that
        no settlement may be entered into without the consent of the indemnified
        party if such settlement would require any action on the part of the
        indemnified party other than to cease using any allegedly infringing or
        illegal content or services. Subject to the foregoing, an indemnified
        party will at all times have the option to participate in any matter or
        litigation through counsel of its own selection at its own expense.
      </Paragraph>
      <Headline level={2}>Disclaimer of Warranties.</Headline>
      <Paragraph subParagraph>
        Except as expressly provided herein, Publisher accepts the Ads are
        provided on an "AS IS" and "AS AVAILABLE" basis, and acknowledges that
        TangoMe makes no other warranty and disclaims all implied and statutory
        warranties, including, but not limited to, any implied warranty of
        merchantability, fitness for a particular purpose or non-infringement.
      </Paragraph>
      <Paragraph subParagraph>
        TangoMe does not guarantee that the Ads will always be complete,
        accurate, safe, secure, bug-free or error-free, or that the Ads will
        always function without disruptions, delays or imperfections. TangoMe
        may change, suspend or discontinue the Ads at any time, including the
        availability of any feature or database, without notice or liability. In
        addition, TangoMe may impose limits on certain features and services or
        restrict the Publisher's access to the Ads without notice or liability.
      </Paragraph>
      <Headline level={2}>Limitation of Liability.</Headline>
      <Paragraph subParagraph>
        In no event shall either Party be liable for any consequential,
        indirect, special or punitive damages, arising out of or relating to the
        Ads, IO, or the Agreement.
      </Paragraph>
      <Paragraph subParagraph>
        Except for intentional misconduct or gross negligence, TangoMe’s entire
        liability under this Agreement shall not exceed the amount of payment
        received by Publisher from TangoMe in the twelve (12) months preceding
        the applicable claim.
      </Paragraph>
      <Headline level={2}>Term and Termination.</Headline>
      <Paragraph subParagraph>
        The term of this Agreement shall commence on the Effective Date and
        shall continue until terminated by either Party pursuant to this
        Agreement ( "Term"). Either Party may terminate this Agreement at any
        time by providing within 48 (forty eight) hours prior written notice to
        the other Party, without liability to the other Party. Upon any
        termination or expiration of this Agreement, TangoMe will cease
        providing the service and rights provided herein shall be revoked. In
        the event of any termination (a) any outstanding amounts of Approved
        Transactions will be paid to Publisher within a net sixty (60) days
        period after such termination; (b) any outstanding debit balance shall
        be paid by Publisher within seven (7) business days after such
        termination. Any obligations of the Parties that by their nature are
        intended to survive the termination or expiration of this Agreement,
        including the obligations of the Parties in Sections 3 – 8 and 11 – 14
        of this Agreement, shall survive any termination thereof.
      </Paragraph>
      <Headline level={2}>Miscellaneous.</Headline>
      <Paragraph subParagraph>
        Publicity. During the Term, TangoMe may refer to Publisher as a customer
        of TangoMe, including by displaying Publisher's name and logo on
        TangoMe’s website and other marketing materials.
      </Paragraph>
      <Paragraph subParagraph>
        Entire Agreement and severability . This Agreement and any amendments
        thereto, and any additional agreements Publisher may enter into with
        TangoMe including the IO, represent the entire and complete agreement
        between the Parties regarding the subject matter hereof and supersedes
        any and all other agreements between the Parties, whether written or
        oral, regarding the subject matter hereof. If any provision of this
        Agreement is found to be unenforceable or invalid, that provision will
        be limited or eliminated to the minimum extent necessary so that this
        Agreement will otherwise remain in full force and effect and
        enforceable. Except as expressly set forth herein, this Agreement may
        not be modified or amended except in a writing executed by both Parties.
        This Agreement may be executed in two or more counterparts, each of
        which shall be deemed an original, but all of which together shall
        constitute one and the same instrument.
      </Paragraph>
      <Paragraph subParagraph>
        Headings. The sections and paragraphs headings in this Agreement are for
        convenience only and shall not affect their interpretation.
      </Paragraph>
      <Paragraph subParagraph>
        Relationship. No agency, partnership, joint venture, or employment
        relationship is created as a result of this Agreement, and neither Party
        has any authority of any kind to bind the other in any respect.
      </Paragraph>
      <Paragraph subParagraph>
        Force Majeure. Either Party shall not be liable for any failure to
        perform its obligations hereunder where such failure results from any
        cause beyond its reasonable control, including, without limitation,
        mechanical, electronic or communications failure or degradation.
      </Paragraph>
      <Paragraph subParagraph>
        No waiver. The failure of either Party to exercise any right provided
        for herein shall not be deemed a waiver of any further rights hereunder.
        All waivers must be in writing. A waiver of any default hereunder or of
        any of the terms and conditions of this Agreement shall not be deemed to
        be a continuing waiver or a waiver of any other default or of any other
        term or condition, but shall apply solely to the instance to which such
        waiver is directed. TangoMe may provide Publisher with notices required
        hereunder by contacting Publisher at any email address Publisher
        provided, including in its registration information.
      </Paragraph>
      <Paragraph subParagraph>
        Notices. TangoMe reserves the right to determine the form and means of
        providing notifications to Publisher, and Publisher agrees to receive
        legal notices electronically if TangoMe elects such means of
        communication. TangoMe may send Publisher marketing communications, from
        time to time, relating to its business or the businesses of carefully
        selected third parties. By providing us your contact details (whether
        through an online registration form, or in any other way) Publisher
        specifically agrees to this. Publisher may unsubscribe at any time from
        such marketing communication.
      </Paragraph>
      <Paragraph subParagraph>
        Amendments. TangoMe may revise this Agreement from time to time, in its
        sole discretion, and the most current version will always be posted on
        TangoMe’s website (as reflected in the "Last Revised" heading). By
        continuing to access or use the Ads after any revisions become
        effective, the Publisher agrees to be bound by the revised Agreement.
      </Paragraph>
      <Paragraph subParagraph>
        Assignment. Publisher must not assign any of its rights or obligations
        under this Agreement without the prior written consent of TangoMe,
        except in the event of an assignment by Publisher to a purchaser of all
        or substantially all of the Publisher’s assets or share capital, in
        which event the Publisher shall provide TangoMe with written notice of
        the assignment. Assignment in violation of the foregoing shall be void.
      </Paragraph>
      <Paragraph subParagraph>
        Governing law. This Agreement shall be governed by the laws of the State
        of Delaware, and the competent courts of the State of Delaware, US shall
        have exclusive jurisdiction to hear any disputes arising hereunder.
      </Paragraph>
      <Headline level={2} counterReset>
        Data Protection Agreement (Controller – Controller) .
      </Headline>
      Data Protection Agreement
      <hr className={styles.line} />
      <Paragraph>
        TangoMe Inc., a legal entity organized under the laws of the State of
        Delaware under registration number 4728891 and having its business and
        registered office located at 3500 S. Dupont Highway, Dover, Delaware
        19901, USA (“Publisher”) and the company or entity that you represent
        ("Publisher" each a "Party", and together the "Parties") have entered
        into a publisher agreement (“Agreement”), in the context of which
        Personal Data is disclosed to or processed by the Publisher, and are
        agreeing to these Data Protection Addendum (“DPA”). This DPA is entered
        into by TangoMe and Publisher and supplements the Agreement. This DPA
        will be effective, and replaces any previously applicable terms relating
        to its subject matter, from the Terms Effective Date. If you are
        accepting this DPA on behalf of Publisher, you warrant that: (a) you
        have full legal authority to bind Publisher to this DPA; (b) you have
        read and understand this DPA; and (c) you agree, on behalf of Publisher,
        to this DPA. If you do not have the legal authority to bind Publisher,
        please do not accept this DPA.
      </Paragraph>
      <Headline level={2}>INTRODUCTION.</Headline>
      <Paragraph subParagraph>
        This DPA reflect the Parties’ agreement on the processing of Personal
        Data in connection with the Data Protection Laws.
      </Paragraph>
      <Paragraph subParagraph>
        Any ambiguity in this DPA shall be resolved to permit the Parties to
        comply with all Data Protection Laws.
      </Paragraph>
      <Paragraph subParagraph>
        In the event and to the extent that the Data Protection Laws impose
        stricter obligations on the Parties than under this DPA, the Data
        Protection Laws shall prevail.
      </Paragraph>
      <Headline level={2}>DEFINITIONS AND INTERPRETATION.</Headline>
      <Paragraph subParagraph>In this DPA:</Paragraph>
      <Paragraph subSubParagraph>
        “Affiliate” means any person or entity directly or indirectly
        controlling, controlled by, or under common control with a Party. For
        the purpose of this definition, "control" (including, with correlative
        meanings, the terms "controlling", "controlled by" and "under common
        control with") means the power to manage or direct the affairs of the
        person or entity in question, whether by ownership of voting securities,
        by contract or otherwise.
      </Paragraph>
      <Paragraph subSubParagraph>
        "Approved Jurisdiction" means a jurisdiction approved as having adequate
        legal protections for data by the European Commission, currently found
        here: <LinkToEuropeanAdequacyDecisions />
      </Paragraph>
      <Paragraph subSubParagraph>
        “Data Protection Laws” means, as applicable, any and/or all applicable
        domestic and federal or national level, pertaining to data privacy, data
        security and/or the protection of Personal Data, including the Privacy
        and Electronic Communications Directive 2002/58/EC (and respective local
        implementing laws) concerning the processing of personal data and the
        protection of privacy in the electronic communications sector (Directive
        on privacy and electronic communications), including any amendments or
        replacements to them, including the Regulation (EU) 2016/679 of the
        European Parliament and of the Council of 27 April 2016 on the
        protection of natural persons with regard to the processing of personal
        data and on the free movement of such data ( "GDPR"), Data Protection
        Act 2018 and Regulation (EU) 2016/679 of the European Parliament and of
        the Council of 27th April 2016 on the protection of natural persons with
        regard to the processing of personal data and on the free movement of
        such data (General Data Protection Regulation) as it forms part of the
        law of England and Wales, Scotland and Northern Ireland by virtue of
        section 3 of the European Union (Withdrawal) Act 2018 ( "UK GDPR") and
        including the California Consumer Privacy Act, Cal. Civ. Code § 1798.100
        et seq. ( "CCPA") and any amendment or replacements to the foregoing.
      </Paragraph>
      <Paragraph subSubParagraph>
        “Data Subject” means a natural person to whom Personal Data relates.
      </Paragraph>
      <Paragraph subSubParagraph>
        “Personal Data” means any information which could be used, either
        directly or by employing additional means, to identify a natural person,
        and that is shared with or processed by the Publisher in the context of
        the performance of the Agreement.
      </Paragraph>
      <Paragraph subSubParagraph>
        “Security Incident“ shall mean any accidental or unlawful destruction,
        loss, alteration, unauthorized disclosure of, or access to, Personal
        Data. For the avoidance of doubt, any Personal Data Breach will comprise
        a Security Incident.
      </Paragraph>
      <Paragraph subSubParagraph>
        “Standard Contractual Clauses” the applicable module of the standard
        contractual clauses for the transfer of personal data to third countries
        pursuant to Regulation (EU) 2016/679 of the European Parliament and of
        the Council from June 4th 2021, as available here:
        <LinkToEurLex />
      </Paragraph>
      <Paragraph subSubParagraph>
        “Terms Effective Date” means the effective date of the Agreement.
      </Paragraph>
      <Paragraph subSubParagraph>
        The terms “controller”, “processing” and “processor” as used in this DPA
        have the meanings given to them in Data Protection Laws. Where
        applicable, controller shall be deemed as a “Business” and processor
        shall be deemed to be a “Service Provider”, as these terms are defined
        in the CCPA.
      </Paragraph>
      <Paragraph subSubParagraph>
        Any reference to a legal framework, statute or other legislative
        enactment is a reference to it as amended or re-enacted from time to
        time.
      </Paragraph>
      <Headline level={2}>APPLICATION OF THIS DPA</Headline>
      <Paragraph subParagraph>
        This DPA will only apply to the extent all of the following conditions
        are met:
      </Paragraph>
      <Paragraph subSubParagraph>
        Either Party processes Personal Data that is made available by the other
        Party in connection with the Agreement;
      </Paragraph>
      <Paragraph subSubParagraph>
        The Data Protection Laws apply to the processing of Personal Data.
      </Paragraph>
      <Paragraph subParagraph>
        This DPA will only apply to the services for which the Parties agreed to
        in the Agreement, which incorporates the DPA by reference.
      </Paragraph>
      <Headline level={2}>ROLES AND RESTRICTIONS ON PROCESSING</Headline>
      <Paragraph subParagraph>Independent Controllers. Each Party:</Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>
          is an independent controller of Personal Data under the Data
          Protection Laws;
        </ListItem>
        <ListItem>
          as required under the Data Protection Laws, maintain accurate written
          records of all the processing activities conducted by that Party in
          relation to any Personal Data for the purposes of performing its
          respective obligations under the Agreement;
        </ListItem>
        <ListItem>
          will individually determine the purposes and means of its processing
          of Personal Data;
        </ListItem>
        <ListItem>
          will be responsible to ensure that any Personal Data collected and
          processed by such Party is accurate and remains accurate for the
          duration of its processing;
        </ListItem>
        <ListItem>
          will comply with the obligations applicable to it under the Data
          Protection Laws with respect to the processing of Personal Data;
        </ListItem>
        <ListItem>
          will be responsible to exercise and respond to any requests by data
          subjects to exercise their rights under Data Protection Law, including
          (but not limited to) Articles 15-22 of the GDPR ( Data Subject
          Rights), and shall provide reasonable cooperation and assistance to
          the other Party in connection with exercising Data Subject Rights;
        </ListItem>
        <ListItem>
          will promptly notify the other Party of any circumstances in which
          such Party is unable or becomes unable to comply with this DPA or Data
          Protection Laws, or any actual or potential changes to Data Protection
          Laws, if this shall affect the other Party’s ability to comply with
          its obligations under this DPA or Data Protection Laws.
        </ListItem>
      </ol>
      <Paragraph subParagraph>
        Restrictions on Processing. Section 4.1 (Independent Controllers) will
        not affect any restrictions on either Party’s rights to use or otherwise
        process Personal Data under the Agreement.
      </Paragraph>
      <Paragraph subParagraph>
        Sharing of Personal Data. In performing its obligations under the
        Agreement, the Publisher shall process Personal Data provided by TangoMe
        (i) only for the purposes set forth in the Agreement or as otherwise
        agreed to in writing by the Parties, provided such processing strictly
        complies with (a) Data Protection Laws, and (b) its obligations under
        the Agreement (the “Permitted Purposes”), provided that it will not do
        or permit any act or omission which would cause TangoMe to incur any
        liability under Data Protection Laws, and (ii) solely during the term of
        the Agreement, and shall securely delete or return the copies of the
        disclosed Personal Data to TangoMe (by secure file transfer in such
        format as the TangoMe reasonably requests) and cease the processing of
        the disclosed Personal Data, and shall certify to TangoMe to that
        effect, unless and only insofar as the processing of the Personal Data
        is required for the fulfillment of the Permitted Purposes or is
        permissible under Data Protection Laws, and in which case the Publisher
        will inform TangoMe of any such requirement and only further process the
        Personal Data as necessary to comply with the foregoing.
      </Paragraph>
      <Paragraph subParagraph>
        Lawful grounds and transparency . Each Party shall maintain a
        publicly-accessible privacy notice that satisfies transparency
        disclosure requirements of Data Protection Laws, and warrants and
        represents that it has provided Data Subjects with appropriate
        transparency regarding data collection and use and all required notices,
        in accordance with Data Protection Law, including Articles 13 and 14 of
        the GDPR. Where either Party collects Personal Data and discloses such
        Personal Data to the other Party, then the disclosing Party shall ensure
        it has obtained and recorded any and all consents or permissions
        necessary under Data Protection Laws, or other applicable lawful
        grounds, in order for itself and the other Party to Process such
        Personal Data as set out herein. The foregoing shall not derogate from
        either Party’s responsibilities under the Data Protection Laws (such as
        the requirement to provide information to the data subject in connection
        with the processing of Personal Data). Both Parties will cooperate in
        good faith in order to identify the information disclosure requirements
        and each party hereby permits the other Party to identify it in the
        other Party’s privacy policy, and to provide a link to the other Party’s
        privacy policy in its privacy policy.
      </Paragraph>
      <Paragraph subParagraph>
        Subcontracting. Where either Party subcontracts the processing
        activities of Personal Data contemplated herein to a third party, it
        shall ensure that such third party enters into written contractual
        obligations which are (in the case of a third party controller) no less
        onerous than those imposed by this DPA or (in the case of a third party
        processor) compliant with Article 28 of the GDPR. Each Party shall be
        liable for the acts or omissions of its subcontractors to the same
        extent it is liable for its own actions or omissions under this DPA.
      </Paragraph>
      <Headline level={2}>PERSONAL DATA TRANSFERS</Headline>
      <Paragraph subParagraph>
        Where the GDPR is applicable, either Party may transfer Personal Data
        outside the European Economic Area or an Approved Jurisdiction, subject
        to one of the appropriate safeguards in Article 46 of the GDPR.
      </Paragraph>
      <Paragraph subParagraph>
        Where the GDPR is applicable, to the extent that Publisher processes
        Personal Data outside the EEA or an Approved Jurisdiction, then the
        Parties shall be deemed to enter into module 1 of the Standard
        Contractual Clauses, subject to any amendments contained in Schedule A,
        in which event: (i) the Standard Contractual Clauses are incorporated
        herein by reference; and (ii) TangoMe shall be deemed as the data
        exporter and the Publisher shall be deemed as the data importer (as
        these terms are defined therein).
      </Paragraph>
      <Headline level={2}>PROTECTION OF PERSONAL DATA.</Headline>
      <Paragraph subParagraph>
        The Parties will provide a level of protection for Personal Data that is
        at least equivalent to that required under Data Protection Laws. Both
        Parties shall implement appropriate technical and organizational
        measures to protect the Personal Data.
      </Paragraph>
      <Paragraph subParagraph>
        In the event that a Party suffers a confirmed Security Incident with
        respect to Personal Data disclosed from the other Party, such Party
        shall notify the other Party without undue delay and the Parties shall
        cooperate in good faith to agree and action such measures as may be
        necessary to mitigate or remedy the effects of the Security Incident. In
        the event that a Party suffers a confirmed Security Incident, then such
        Party shall be responsible to notify the supervisory authority and/or
        the Data Subjects with respect to such Security Incident, as required
        under Data Protection Laws.
      </Paragraph>
      <Headline level={2}>MUTUAL ASSISTANCE</Headline>
      <Paragraph subParagraph>Each Party shall:</Paragraph>
      <Paragraph subSubParagraph>
        appoint at least one representative as point of contact and responsible
        manager for all issues arising out of the Data Protection Laws (a
        "Designated Representative" ); the Designated Representative(s) of both
        Parties will work together in good faith to reach an agreement with
        regards to any issues arising from time to time in relation to the
        processing of Personal Data in connection with the Agreement and this
        DPA;
      </Paragraph>
      <Paragraph subSubParagraph>
        TangoMe’s Designated Representative is it’s General Counsel at
        leagal@tango.me. The Publisher’s Designated Representative is: [ fill in
        name and email].
      </Paragraph>
      <Paragraph subSubParagraph>
        use reasonable measures to consult with the other Party about any
        notices given to Data Subjects in relation to the processing of Personal
        Data under the Agreement;
      </Paragraph>
      <Paragraph subSubParagraph>
        inform the other Party (without undue delay) in the event that it
        receives a Data Subject request related solely and exclusively to the
        other Party's respective processing activities and provide all
        reasonable assistance to ensure Data Subject requests are completed
        within the timeframe set out in Data Protection Laws;
      </Paragraph>
      <Paragraph subSubParagraph>
        provide the other Party with reasonable assistance (having regard to the
        data available to it) to enable the other Party to comply with any Data
        Subject request received by the other Party and to respond to any other
        queries or complaints from Data Subjects;
      </Paragraph>
      <Paragraph subSubParagraph>
        provide the other Party with such assistance as the other Party may
        reasonably request from time to time to enable the other Party to comply
        with its obligations under the Data Protection Laws including (without
        limitation) in respect of security, breach notifications, impact
        assessments and consultations with supervisory authorities or other
        regulators;
      </Paragraph>
      <Paragraph subSubParagraph>
        provide the other Party with such information as it may reasonably
        request in order to: (a) monitor the technical and organizational
        measures being taken to ensure compliance with the Data Protection Laws,
        or (b) satisfy any legal or regulatory requirements, including
        information reporting, disclosure and other related obligations to any
        regulatory authority from time to time;
      </Paragraph>
      <Paragraph subSubParagraph>
        in the event of an actual or potential Security Incident which does or
        is reasonably likely to affect the respective processing activities of
        both Parties, liaise with the other Party in good faith to consider what
        action is required in order to resolve the issue in accordance with the
        Data Protection Laws, and provide such reasonable assistance as is
        necessary to the other Party to facilitate the handling of such Security
        Incident in an expeditious and compliant manner.
      </Paragraph>
      <Headline level={2}>OBLIGATIONS UNDER THE CCPA</Headline>
      <Paragraph subParagraph>
        To the extent that Publisher processes Personal Data of Californian
        residents for a Business Purpose (as it is defined under the CCPA), it
        shall be regarded as a Service Provider and be subject to the following
        obligations:
      </Paragraph>
      <Paragraph subSubParagraph>
        Publisher shall not sell such Personal Data (as the term "sell" is
        defined under the CCPA).
      </Paragraph>
      <Paragraph subSubParagraph>
        Publisher is prohibited from retaining, using, or disclosing such
        Personal Data for a commercial purpose other than providing the services
        to TangoMe under the Agreement and from retaining, using, or disclosing
        such Personal Data outside of the Agreement.
      </Paragraph>
      <Paragraph subSubParagraph>
        Publisher understands its obligations under this clause and will comply
        with them.
      </Paragraph>
      <Paragraph subParagraph>
        Notwithstanding the above, Publisher shall not sell Personal Data it
        received from or collected on behalf of TangoMe.
      </Paragraph>
      <Headline level={2}>
        RESOLUTION OF DISPUTES WITH DATA SUBJECTS OR SUPERVISORY AUTHORITIES
      </Headline>
      <Paragraph subParagraph>
        f either Party is the subject of a claim by a Data Subject or a
        supervisory authority or receives a notice or complaint from a
        supervisory authority relating to its respective processing activities
        (a "DP Claim"), it shall promptly inform the other Party of the DP Claim
        and provide the other Party with such information as it may reasonably
        request regarding the DP Claim.
      </Paragraph>
      <Paragraph subParagraph>
        here the DP Claim concerns the respective processing activities of one
        Party only, then that Party shall assume sole responsibility for
        disputing or settling the DP Claim.
      </Paragraph>
      <Paragraph subParagraph>
        here the DP Claim concerns the respective processing activities of both
        Parties, then the Parties shall use all reasonable endeavors to
        cooperate with a view to disputing or settling the DP Claim in a timely
        manner; provided always that neither Party shall make any admission or
        offer of settlement or compromise without using all reasonable endeavors
        to consult with the other Party in advance.
      </Paragraph>
      <Headline level={2}>LIABILITY</Headline>
      <Paragraph subParagraph>
        Notwithstanding anything else in the Agreement, the total liability of
        either Party towards the other party under or in connection with this
        DPA will be limited to the maximum monetary or payment-based amount at
        which that party’s liability is capped under the Agreement.
      </Paragraph>
      <Headline level={2}>PRIORITY</Headline>
      <Paragraph subParagraph>
        If there is any conflict or inconsistency between the terms of this DPA
        and the remainder of the Agreement then, the terms of this DPA will
        govern. Subject to the amendments in this DPA, the Agreement remains in
        full force and effect.
      </Paragraph>
      <Paragraph subParagraph>
        If there is any conflict or inconsistency between the terms of this DPA
        and the Standard Contractual Clauses, the terms of the Standard
        Contractual Clauses will govern.
      </Paragraph>
      <Headline level={2}>CHANGES TO THIS DPA.</Headline>
      <Paragraph subParagraph>
        No changes, modifications or amendments to this DPA shall be valid or
        binding, unless made in writing and signed by both Parties.
      </Paragraph>
      <Paragraph subParagraph>
        If any of the Data Protection Laws are superseded by new or modified
        Data Protection Laws (including any decisions or interpretations by a
        relevant court or governmental authority relating thereto), the new or
        modified Data Protection Laws shall be deemed to be incorporated into
        this DPA, and each Party will promptly begin complying with such Data
        Protection Laws in respect of its respective processing activities.
      </Paragraph>
      <Headline level={2} counterReset>
        Schedule A – SCC
      </Headline>
      <Paragraph>
        1 This Schedule A sets out the Parties' agreed interpretation of their
        respective obligations under Module One of the Standard Contractual
        Clauses.
      </Paragraph>
      <Paragraph>
        2 The Parties agree that for the purpose of transfer of Personal Data
        between TangoMe (Data Exporter) and the Publisher (Data Importer), the
        following shall apply:
      </Paragraph>
      <Paragraph>
        2.1 Clause 7 of the Standard Contractual Clauses shall not be
        applicable.
      </Paragraph>
      <Paragraph>
        2.2 In Clause 11, data subjects shall not be able to lodge a complaint
        with an independent dispute resolution body.
      </Paragraph>
      <Paragraph>
        2.3 In Clause 17, option 1 shall apply. The Parties agree that the
        clauses shall be governed by the law of Cyprus.
      </Paragraph>
      <Paragraph>
        2.4 In Clause 18(b) the Parties choose the courts of Cyprus as their
        choice of forum and jurisdiction.
      </Paragraph>
      <Paragraph>
        3 The Parties shall complete Annexes I–II below, which are incorporated
        in the Standard Contractual Clauses by reference.
      </Paragraph>
      <Headline level={2} counterReset>
        Annex I – Description of processing activities
      </Headline>
      <Paragraph>A. Identification of Parties</Paragraph>
      <Paragraph>
        <strong>"Data Exporter"</strong>: TangoMe;
      </Paragraph>
      <Paragraph>
        <strong>"Data Importer"</strong>: the Publisher.
      </Paragraph>
      <Paragraph>B. Description of Transfer</Paragraph>
      <Paragraph>Data Subjects:</Paragraph>
      <Paragraph>
        The Personal Data transferred concern the following categories of Data
        Subjects (please specify):
      </Paragraph>
      <ul className={styles.ul}>
        {checkboxContent.dataSubjects.map(({ label, checked }) => (
          <ListItem key={label} checked={checked} unchecked={!checked}>
            {label}
          </ListItem>
        ))}
      </ul>
      <Paragraph>Categories of Personal Data</Paragraph>
      <Paragraph>
        The Personal Data transferred concern the following categories of data
        (please specify):
      </Paragraph>
      <ul className={styles.ul}>
        {checkboxContent.categoriesOfPersonalData.map(({ label, checked }) => (
          <ListItem key={label} checked={checked} unchecked={!checked}>
            {label}
          </ListItem>
        ))}
      </ul>
      <Paragraph>Special Categories of Data (if appropriate)</Paragraph>
      <Paragraph>
        The Personal Data transferred concern the following special categories
        of data (please specify):
      </Paragraph>
      <ul className={styles.ul}>
        {checkboxContent.specialCategoriesOfData.map(({ label, checked }) => (
          <ListItem key={label} checked={checked} unchecked={!checked}>
            {label}
          </ListItem>
        ))}
      </ul>
      <Paragraph>The frequency of the transfer:</Paragraph>
      <ul className={styles.ul}>
        {checkboxContent.theFrequencyOfTheTransfer.map(({ label, checked }) => (
          <ListItem key={label} checked={checked} unchecked={!checked}>
            {label}
          </ListItem>
        ))}
      </ul>
      <Paragraph>Nature of the processing</Paragraph>
      <ul className={styles.ul}>
        {checkboxContent.natureOfTheProcessing.map(({ label, checked }) => (
          <ListItem key={label} checked={checked} unchecked={!checked}>
            {label}
          </ListItem>
        ))}
      </ul>
      <Headline level={2} counterReset>
        Annex II – Technical and Organizational Measures to Ensure the Security
        of the Data
      </Headline>
      <Paragraph>
        Description of the technical and organizational measures implemented by
        the data importer (including any relevant certifications) to ensure an
        appropriate level of security, taking into account the nature, scope,
        context and purpose of the processing, and the risks for the rights and
        freedoms of natural persons.
      </Paragraph>
      <Paragraph>
        Security Management <br />
        Publisher maintains a written information security management system
        (ISMS), in accordance with this Annex, that includes policies,
        processes, enforcement and controls governing all
        storage/processing/transmitting of Personal Data, designed to (a) secure
        Personal Data against accidental or unlawful loss, access or disclosure;
        (b) identify reasonable foreseeable and internal risks to security and
        authorized access to Publisher Network, and (c) minimize security risks,
        including through risk assessment and regular testing. The ISMS will
        include the following measures:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          Publisher actively follows information security trends and
          developments as well as legal developments with regards to the
          services provided and especially with regards to Personal Data and
          uses such insights to maintain its ISMS, as appropriate.
        </ListItem>
        <ListItem>
          To the extent Publisher process cardholder or payment data (such as
          payment or credit cards), Publisher will maintain its ISMS in
          accordance with the PCI DSS standard, augmented to cover Personal
          Data, or such other alternative standards that are substantially
          equivalent to PCI DSS for the establishment, implementation, and
          control of its ISMS. Additionally, Publisher will be assessed against
          PCI DSS annually by an on-site assessment carried out by an
          independent QSA (Qualified Security Assessor) and upon TangoMe's
          request, not to exceed once annually, Publisher will provide TangoMe
          with PCI DSS attestation of compliance.
        </ListItem>
      </ul>
      <Paragraph>
        Maintain an Information Security Policy <br />
        Publisher's ISMS is based on its security policies that are regularly
        reviewed (at least yearly) and maintained and disseminated to all
        relevant Parties, including all personnel. Security policies and derived
        procedures clearly define information security responsibilities
        including responsibilities for:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>Maintaining security policies and procedures;</ListItem>
        <ListItem>
          Secure development, operation and maintenance of software and systems;
        </ListItem>
        <ListItem>Security alert handling;</ListItem>
        <ListItem>
          Security incident response and escalation procedures;
        </ListItem>
        <ListItem>User account administration;</ListItem>
        <ListItem>
          Monitoring and control of all systems as well as access to Personal
          Data. <br />
          Personnel is screened prior to hire and trained (and tested) through a
          formal security awareness program upon hire and annually. For service
          providers with whom Personal Data is shared or that could affect the
          security of Personal Data a process has been set up that includes
          initial due diligence prior to engagement and regular (typically
          yearly) monitoring.
        </ListItem>
        <ListItem>
          Personal Data has implemented a risk-assessment process that is based
          on ISO 27005.
        </ListItem>
      </ul>
      <Paragraph>
        Secure Networks and Systems <br />
        Publisher has installed and maintains firewall configurations to protect
        Personal Data that controls all traffic allowed between Publisher's
        (internal) network and untrusted (external) networks, as well as traffic
        into and out of more sensitive areas within its internal network. This
        includes current documentation, change control and regular reviews.{" "}
        <br />
        Publisher does not use vendor-supplied defaults for system passwords and
        other security parameters on any systems and has developed configuration
        standards for all system components consistent with industry-accepted
        system hardening standards.
      </Paragraph>
      <Paragraph>
        Protection of Personal Data <br />
        Publisher keeps Personal Data storage to a minimum and implements data
        retention and disposal policies to limit data storage to that which is
        necessary, in accordance with the needs of its customers. <br />
        Publisher uses strong encryption and hashing for Personal Data anywhere
        it is stored. Publisher has documented and implemented all necessary
        procedures to protect (cryptographic) keys used to secure stored
        Personal Data against disclosure and misuse. All transmission of
        Personal Data across open, public networks is encrypted using strong
        cryptography and security protocols.
      </Paragraph>
      <Paragraph>
        Vulnerability Management Program <br />
        Publisher protects all systems against malware and regularly updates
        anti-virus software or programs to protect against malware – including
        viruses, worms, and Trojans. Anti-virus software is used on all systems
        commonly affected by malware to protect such systems from current and
        evolving malicious software threats. <br />
        Publisher develops and maintains secure systems and applications by:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          Having established and evolving a process to identify and fix (e.g.
          through patching) security vulnerabilities, that ensures that all
          systems components and software are protected from known
          vulnerabilities,
        </ListItem>
        <ListItem>
          Developing internal and external software applications, including
          web-applications, securely using a secure software development process
          based on best practices, e.g. such as code reviews and OWASP secure
          coding practices, that incorporates information security throughout
          the software-development lifecycle,
        </ListItem>
        <ListItem>
          Implementing a stringent change management process and procedures for
          all changes to system components that include strict separation of
          development and test environments from production environments and
          prevents the use of production data for testing or development.
        </ListItem>
      </ul>
      <Paragraph>
        Implementation of Strong Access Control Measures <br />
        "Publisher Network" means the Publisher's data center facilities,
        servers, networking equipment, and host software systems (e.g. virtual
        firewalls) as employed by the Publisher to process or store Personal
        Data. <br />
        The Publisher Network will be accessible to employees, contractors and
        any other person as necessary to provide the services to the TangoMe.
        Publisher will maintain access controls and policies to manage what
        access is allowed to the Publisher Network from each network connection
        and user, including the use of firewalls or functionally equivalent
        technology and authentication controls. Publisher will maintain
        corrective action and incident response plans to respond to potential
        security threats. <br />
        Publisher strictly restricts access to Personal Data on a need to know
        basis to ensure that critical data can only be accessed by authorized
        personnel. This is achieved by:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          Limiting access to system components and Personal Data to only those
          individuals whose job requires such access; and
        </ListItem>
        <ListItem>
          Establishing and maintaining an access control system for system
          components that restricts access based on a user’s need to know, with
          a default “deny-all” setting.
        </ListItem>
      </ul>
      <Paragraph>
        Publisher identifies and authenticates access to all systems components
        by assigning a unique identification to each person with access. This
        ensures that each individual is uniquely accountable for its actions and
        any actions taken on critical data and systems can be traced to known
        and authorized users and processes. Necessary processes to ensure proper
        user identification management, including control of
        addition/deletion/modification/revocation/disabling of IDs and/or
        credentials as well as lock out of users after repeated failed access
        attempts and timely termination of idling session, have been
        implemented. <br />
        User authentication utilizes at least passwords that have to meet
        complexity rules, which need to be changed on a regular basis and which
        are cryptographically secured during transmission and storage on all
        system components. All individual non-console and administrative access
        and all remote access use multi-factor authentication. <br />
        Authentication policies and procedures are communicated to all users and
        group, shared or generic IDs/passwords are strictly prohibited.
      </Paragraph>
      <Paragraph>
        Restriction of Physical Access to Personal Data <br />
        Any physical access to data or systems that house Personal Data are
        appropriately restricted using appropriate entry controls and procedures
        to distinguish between onsite personnel and visitors. Access to
        sensitive areas is controlled and includes processes for authorization
        based on job function and access revocation for personnel and visitors.{" "}
        <br />
        Media and backups are secured and (internal and external) distribution
        is strictly controlled. Media containing Personal Data no longer needed
        for business or legal reasons is rendered unrecoverable or physically
        destroyed.
      </Paragraph>
      <Paragraph>
        Regular Monitoring and Testing of Networks <br />
        All access to network resources and Personal Data is tracked and
        monitored using centralized logging mechanisms that allow thorough
        tracking, alerting, and analysis on a regular basis (at least daily) as
        well as when something does go wrong. All systems are provided with
        correct and consistent time and audit trails are secured and protected,
        including file-integrity monitoring to prevent change of existing log
        data and/or generate alerts in case. Audit trails for critical systems
        are kept for a year. <br />
        Security of systems and processes is regularly tested, at least yearly.
        This is to ensure that security controls for system components,
        processes and custom software continue to reflect a changing
        environment. Security testing includes:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>Processes to test rogue wireless access points,</ListItem>
        <ListItem>
          Internal and external network vulnerability tests that are carried out
          at least quarterly. An external, qualified party carries out the
          external network vulnerability tests.
        </ListItem>
        <ListItem>
          External and internal penetration tests using Publisher's penetration
          test methodology that is based on industry-accepted penetration
          testing approaches that cover all the relevant systems and include
          application-layer as well as network-layer tests
        </ListItem>
      </ul>
      <Paragraph>
        All test results are kept on record and any findings are remediated in a
        timely manner. <br />
        Publisher does not allow penetration tests carried out by or on behalf
        of its customers. <br />
        In daily operations IDS (intrusion detection system) is used to detect
        and alert on intrusions into the network and file-integrity monitoring
        has been deployed to alert personnel to unauthorized modification of
        critical systems.
      </Paragraph>
      <Paragraph>
        Incident Management <br />
        Publisher has implemented and maintains an incident response plan and is
        prepared to respond immediately to a system breach. Incident management
        includes:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          Definition of roles, responsibilities, and communication and contact
          strategies in the event of a compromise, including notification of
          customers,
        </ListItem>
        <ListItem>Specific incident response procedures,</ListItem>
        <ListItem>
          Analysis of legal requirements for reporting compromises,
        </ListItem>
        <ListItem>Coverage of all critical system components,</ListItem>
        <ListItem>Regular review and testing of the plan,</ListItem>
        <ListItem>
          Incident management personnel that is available 24/7,
        </ListItem>
        <ListItem>Training of staff,</ListItem>
        <ListItem>
          Inclusion of alerts from all security monitoring systems,
        </ListItem>
        <ListItem>
          Modification and evolution of the plan according to lessons learned
          and to incorporate industry developments.
        </ListItem>
      </ul>
      <Paragraph>
        Publisher has also implemented a business continuity process (BCP) and a
        disaster recovery process (DRP) that are maintained and regularly
        tested. Data backup processes have been implemented and are tested
        regularly.
      </Paragraph>
      <Paragraph>Physical Security</Paragraph>
      <Paragraph>
        Physical Access Controls <br />
        Physical components of the Publisher Network are housed in nondescript
        facilities ("Facilities"). Physical barrier controls are used to prevent
        unauthorized entrance to Facilities both at the perimeter and at
        building access points. Passage through the physical barriers at the
        Facilities requires either electronic access control validation (e.g.,
        card access systems, etc.) or validation by human security personnel
        (e.g., contract or in-house security guard service, receptionist, etc.).
        Employees and contractors are assigned photo-ID badges that must be worn
        while the employees and contractors are at any of the Facilities.
        Visitors are required to sign-in with designated personnel, must show
        appropriate identification, are assigned a visitor ID badge that must be
        worn while the visitor is at any of the Facilities, and are continually
        escorted by authorized employees or contractors while visiting the
        Facilities.
      </Paragraph>
      <Paragraph>
        Limited Employee and Contractor Access <br />
        Publisher provides access to the Facilities to those employees and
        contractors who have a legitimate business need for such access
        privileges. When an employee or contractor no longer has a business need
        for the access privileges assigned to him/her, the access privileges are
        promptly revoked, even if the employee or contractor continues to be an
        employee of Publisher or its affiliates.
      </Paragraph>
      <Paragraph>
        Physical Security Protections <br />
        All access points (other than main entry doors) are maintained in a
        secured (locked) state. Access points to the Facilities are monitored by
        video surveillance cameras designed to record all individuals accessing
        the Facilities. Publisher also maintains electronic intrusion detection
        systems designed to detect unauthorized access to the Facilities,
        including monitoring points of vulnerability (e.g., primary entry doors,
        emergency egress doors, etc.) with door contacts, or other devices
        designed to detect individuals attempting to gain access to the
        Facilities. All physical access to the Facilities by employees and
        contractors is logged and routinely audited.
      </Paragraph>
      <Paragraph>
        Continued Evaluation <br />
        Publisher will conduct periodic reviews of the Security of its Publisher
        Network and adequacy of its information security program as measured
        against industry security standards and its policies and procedures.
        Publisher will continually evaluate the security of its Publisher
        Network to determine whether additional or different security measures
        are required to respond to new security risks or findings generated by
        the periodic reviews.
      </Paragraph>
    </article>
  </>
);

export default memo(PublisherTermsAndConditions);
