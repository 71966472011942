import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  HomePageStreamsDetailsState,
  HomePageStreamsResponse,
  HomePageVisitorStreamsResponse,
} from "src/features/homePage/shared/constants/types";
import { Nullable } from "src/features/homePage/shared/imports/types";

export interface HomePageStreamsState {
  currentStream: string;
  error: Nullable<string>;
  loading: boolean;
  nextStream: string;
  prevStream: string;
  streamsDetails: HomePageStreamsDetailsState;
  streamsIds: Array<string>;
}

const initialState: HomePageStreamsState = {
  error: null,
  loading: false,
  streamsDetails: {},
  streamsIds: [],
  currentStream: "",
  prevStream: "",
  nextStream: "",
};

const streamsSlice = createSlice({
  name: "homePageStreams",
  initialState,
  reducers: {
    loadHomePageStreamsStart(state) {
      state.loading = true;
    },
    loadHomePageStreamsComplete(
      state,
      action: PayloadAction<HomePageStreamsResponse>
    ) {
      state.loading = false;
      const { result, entities } = action.payload;
      const streamsDetails: HomePageStreamsDetailsState = {};
      result.forEach((streamId) => {
        const broadcasterId = entities.stream[streamId].broadcasterId;
        streamsDetails[streamId] = {
          basicProfile: entities.basicProfile[broadcasterId],
          playlistUrl: entities.stream[streamId].playlistUrl,
          broadcasterId,
          viewers: entities.stream[streamId].viewerCount,
          id: streamId,
          diamonds: entities.liveStats[broadcasterId].points,
        };

        state.streamsIds = result;
        state.streamsDetails = streamsDetails;
        state.currentStream = result[0] ?? "";
        state.nextStream = result[1] ?? "";
      });
    },
    loadHomePageVisitorStreamsComplete(
      state,
      action: PayloadAction<HomePageVisitorStreamsResponse>
    ) {
      state.loading = false;
      const { catalogItems } = action.payload;

      const streamIds: Array<string> = [];
      const streamDetails: HomePageStreamsDetailsState = {};
      catalogItems.forEach((item) => {
        streamIds.push(item.encryptedStreamId);
        streamDetails[item.encryptedStreamId] = {
          basicProfile: {
            firstName: item.broadcaster.name,
            profilePictureUrl: item.broadcaster.avatarUrl,
            profileThumbnailUrl: item.broadcaster.avatarThumbnailUrl,
          },
          playlistUrl: item.masterListUrl,
          broadcasterId: item.broadcaster.encryptedId,
          viewers: item.viewers,
          id: item.encryptedStreamId,
          diamonds: item.broadcaster.diamonds,
        };
      });

      state.currentStream = streamIds[0] ?? "";
      state.nextStream = streamIds[1] ?? "";
      state.streamsIds = streamIds;
      state.streamsDetails = streamDetails;
    },
    goToNextStream(state, action: PayloadAction<number>) {
      state.prevStream = state.currentStream;
      state.currentStream = state.streamsIds[action.payload] ?? "";
      state.nextStream = state.streamsIds[action.payload + 1] ?? "";
    },
    goToPrevStream(state, action: PayloadAction<number>) {
      state.nextStream = state.currentStream;
      state.currentStream = state.streamsIds[action.payload] ?? "";
      state.prevStream = state.streamsIds[action.payload - 1] ?? "";
    },
    loadHomePageStreamsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadHomePageStreamsStart,
  loadHomePageStreamsFailure,
  loadHomePageStreamsComplete,
  loadHomePageVisitorStreamsComplete,
  goToNextStream,
  goToPrevStream,
} = streamsSlice.actions;

export const homePageStreams = streamsSlice.reducer;
