import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "state/delegate";
import {
  blockedUsersSelectors,
  followingListSelectors,
  loginSelectors,
  subscriptionPerStreamerListSelectors,
  userSelectors,
} from "state/selectors";

export default (accountId: string) =>
  useSelector(
    useCallback(
      (state: RootState) => ({
        isBlocked: blockedUsersSelectors.isBlockedUser(state, accountId),
        isLoggedIn: loginSelectors.isLoggedIn(state),
        isFollowed: followingListSelectors.getIsFollowingByAccountId(
          state,
          accountId
        ),
        isSubscribed:
          !!subscriptionPerStreamerListSelectors.getSubscriptionInfoFromUserMap(
            state,
            accountId
          ),
        isMe: userSelectors.getMyAccountId(state) === accountId,
      }),
      [accountId]
    ),
    shallowEqual
  );
