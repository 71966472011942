import { CountryAccessError } from "src/features/ofac/api/countryAccessError";
import { getProxycadorHost } from "src/features/ofac/imports/environment";
import { HTTP_CODE_UNAVAILABLE_FOR_LEGAL_REASONS } from "src/features/ofac/imports/state";

export const checkCountryAccess = async () => {
  const { status } = await fetch(
    `${getProxycadorHost()}/visitor-lobby/api/public/visitors/v1/country-access`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (status === HTTP_CODE_UNAVAILABLE_FOR_LEGAL_REASONS) {
    throw new CountryAccessError();
  }
};
