import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SCREEN_NAME } from "chat/imports/analytics";
import { ErrorIcon } from "chat/imports/assets";
import {
  Button,
  ScreenViewReporter,
  Typography,
} from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { sharedMessages } from "chat/imports/utils";
import { VoidCallback } from "chat/messageRequest/imports/types";
import styles from "./MessageRequestErrorPlaceholder.scss";

interface MessageRequestErrorProps {
  onClick: VoidCallback;
}

export const MessageRequestErrorPlaceholder: FC<MessageRequestErrorProps> = ({
  onClick,
}) => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  return (
    <div className={styles.container}>
      <ScreenViewReporter name={SCREEN_NAME.MAIN_CONVERSATIONS_ERROR} />
      <ErrorIcon className={styles.icon} />
      <div className={styles.text}>
        <Typography
          type={TYPOGRAPHY_TYPE.HEADLINE3}
          className={styles.title}
          as="div"
        >
          <FormattedMessage {...sharedMessages.oops} />
        </Typography>
        <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3} as="div">
          <FormattedMessage {...sharedMessages.feedSomethingWentWrong} />
        </Typography>
      </div>
      <Button
        size={isDesktop ? ButtonSize.BIG_48 : ButtonSize.MEDIUM_32}
        variant={ButtonVariant.SECONDARY}
        onClick={onClick}
        className={styles.retry}
        data-testid="message-request-error-retry"
      >
        <FormattedMessage {...sharedMessages.retry} />
      </Button>
    </div>
  );
};
