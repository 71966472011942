import { mapValues } from "src/utils/miniLodash";

/**
 * @template Selectors
 * @param {Selectors} selectors
 * @return {Selectors}
 */
const delegateSelectors = (selectors, globalSelector) =>
  mapValues(
    selectors,
    (selector) =>
      (state, ...rest) =>
        selector(globalSelector(state), ...rest)
  );

export default delegateSelectors;
