import { createListenerMiddleware } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import {
  LOGIN_END,
  RootState,
} from "src/features/profile/visitorProfile/imports/types";
import { clearVisitorProfile } from "src/features/profile/visitorProfile/state/slice";

export const clearVisitorProfileAfterLoginMiddleware =
  createListenerMiddleware<RootState>();

clearVisitorProfileAfterLoginMiddleware.startListening({
  type: LOGIN_END,
  effect: async (action: AnyAction, { dispatch }) => {
    if (action.error) {
      return;
    }
    dispatch(clearVisitorProfile());
  },
});
