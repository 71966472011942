import { createAction } from "@reduxjs/toolkit";
import { UserPostsState } from "src/state/tree/userPosts";

export const setIsUserPostsListVisible = createAction<boolean>(
  "lwc/userPosts/setIsUserPostsListVisible"
);

export const setUserPostsInfo = createAction<UserPostsState>(
  "lwc/userPosts/setUserPostsInfo"
);
