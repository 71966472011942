import { WEBVIEW_ENVIRONMENT_PROPERTY } from "src/core/webview/WebViewController/constants";
import useQueryToPatchEnvironment from "ui/hooks/useQueryToPatchEnvironment";

const queryMap = { webview: "true", webviewReset: "false" };

/*
This hook is intended for testing purposes only.
- Pass the query parameter 'webview' without any value and even without equal
  symbol to set the value of the environment property to true.
- Pass the query parameter 'webviewReset' without any value and even without
  equal value of the environment property to false.
*/
export const useWebViewEnvironmentPropertyController = () => {
  useQueryToPatchEnvironment({
    propertyKey: WEBVIEW_ENVIRONMENT_PROPERTY,
    queryMap,
  });
};
