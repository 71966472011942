import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  ConversationsListItemClickedScreen,
  ConversationsListItemClickedText,
  emitConversationsListItemClicked,
} from "chat/analytics/emitConversationsListItemClicked";
import { LastMessageDatePreview } from "chat/components/LastMessageDatePreview/LastMessageDatePreview";
import ChatAvatar from "chat/components/common/ChatAvatar";
import Preview from "chat/components/conversationsList/Preview";
import { CHAT_SOURCE_CHAT_LIST, ignoredMessagesTypes } from "chat/constants";
import { DisplayName, Typography } from "chat/imports/components";
import {
  MAX_DISPLAYED_MESSAGES_COUNT,
  TANGO_ACCOUNT_ID,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useBreakpoint } from "chat/imports/hooks";
import {
  RootState,
  isSystemChat as getIsSystemChat,
  profilesCacheSelectors,
} from "chat/imports/state";
import { AccountInfo } from "chat/imports/types";
import {
  formatDisplayName,
  makeLinkToChat,
  makeLinkToMessageRequest,
  makeLinkToProfile,
  sharedMessages,
} from "chat/imports/utils";
import { messageRequestSelectors } from "chat/messageRequest/exports/state";
import { removeRequestsConversation } from "chat/messageRequest/state/asyncAction";
import { StoredConversation } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import { ConversationState, Group, Message } from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import styles from "./ConversationListItem.scss";

interface GroupConversationContentProps {
  conversationId: string;
  groupInfo?: Group;
}

const LiveFamilyConversationContent = memo<GroupConversationContentProps>(
  ({ groupInfo, conversationId }) => {
    const intl = useIntl();

    const fallbackMessage = intl.formatMessage(sharedMessages.groupChat);

    return (
      <>
        <ChatAvatar
          conversationId={conversationId}
          className={styles.avatar}
          name={groupInfo?.name || fallbackMessage}
          pictureUrl={groupInfo?.pictureUrl}
          isGroupAvatar
        />
        <Typography className={styles.name} type={TYPOGRAPHY_TYPE.HEADLINE4}>
          {groupInfo?.name}
        </Typography>
      </>
    );
  }
);

LiveFamilyConversationContent.displayName = "LiveFamilyConversationContent";

const GroupConversationContent = memo<GroupConversationContentProps>(
  ({ groupInfo, conversationId }) => {
    const intl = useIntl();
    const fallbackMessage = intl.formatMessage(sharedMessages.groupChat);

    return (
      <>
        <ChatAvatar
          conversationId={conversationId}
          className={styles.avatar}
          name={groupInfo?.name || fallbackMessage}
          pictureUrl={groupInfo?.pictureUrl}
          isGroupAvatar
        />
        <Typography className={styles.name} type={TYPOGRAPHY_TYPE.HEADLINE4}>
          {groupInfo?.name || fallbackMessage}
        </Typography>
      </>
    );
  }
);

GroupConversationContent.displayName = "GroupConversationContent";

interface OneOnOneConversationContentProps {
  accountInfo: AccountInfo | undefined;
  conversationId: string;
}

const OneOnOneConversationContent = memo<OneOnOneConversationContentProps>(
  ({ accountInfo, conversationId }) => {
    const isTangoConversation = conversationId === TANGO_ACCOUNT_ID;
    const intl = useIntl();
    const { account_id, first_name, last_name, thumbnail_url } =
      accountInfo || {};
    const basicProfile = useSelector(
      useCallback(
        (state: RootState) =>
          profilesCacheSelectors.getBasicProfile(
            state,
            account_id || (isTangoConversation && TANGO_ACCOUNT_ID)
          ),
        [account_id, conversationId]
      ),
      shallowEqual
    );

    return (
      <>
        <ChatAvatar
          conversationId={conversationId}
          className={styles.avatar}
          pictureUrl={basicProfile?.profileThumbnailUrl || thumbnail_url}
          accountInfo={accountInfo}
          to={
            isTangoConversation
              ? undefined
              : makeLinkToProfile(accountInfo?.account_id, basicProfile)
          }
          basicProfile={basicProfile}
          name={formatDisplayName({
            intl,
            basicProfile,
            firstName: first_name,
            lastName: last_name,
          })}
        />
        <Typography className={styles.name} type={TYPOGRAPHY_TYPE.HEADLINE4}>
          <DisplayName
            basicProfile={basicProfile}
            showVerified
            firstName={first_name}
            lastName={last_name}
          />
        </Typography>
      </>
    );
  }
);

OneOnOneConversationContent.displayName = "OneOnOneConversationContent";

interface ConversationListItemProps {
  conversation: StoredConversation;
  isCurrent: boolean;
  isRequestsVisible?: boolean;
}

const ConversationListItem: React.FC<ConversationListItemProps> = ({
  conversation,
  isCurrent,
  isRequestsVisible,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const { conversation_id, group_info, account_info, state, hidden } =
    conversation;
  const isGroupChat = isGroupChatId(conversation_id);
  const isMessageRequestConversation =
    state === ConversationState.CHAT_REQUEST && !!hidden;
  const { latestMessage, messagesRequests, isSystemChat } = useSelector(
    useCallback(
      (state: RootState) => {
        const chatAndMessageRequestSelectors = isMessageRequestConversation
          ? messageRequestSelectors
          : chatSelectors;

        return {
          isSystemChat: getIsSystemChat(state, conversation_id),
          messagesRequests: messageRequestSelectors.getConversations(
            state
          ) as StoredConversation[],
          latestMessage: chatAndMessageRequestSelectors
            .getMessagesByConversationId(state, conversation_id)
            ?.find(
              (message: Message) => !ignoredMessagesTypes.includes(message.type)
            ),
        };
      },
      [conversation_id, isMessageRequestConversation]
    ),
    shallowEqual
  );

  useEffect(() => {
    if (!isMessageRequestConversation) {
      const conversationDuplicationInMessagesRequests = messagesRequests.find(
        (conversation) => conversation.conversation_id === conversation_id
      );

      if (conversationDuplicationInMessagesRequests) {
        dispatch(removeRequestsConversation(conversation_id));
      }
    }
  }, [
    isMessageRequestConversation,
    messagesRequests,
    conversation_id,
    dispatch,
  ]);

  const analyticsText = useMemo(() => {
    if (group_info?.familyId) {
      return ConversationsListItemClickedText.FAMILY;
    }

    if (isGroupChat) {
      return ConversationsListItemClickedText.GROUP;
    }

    if (isSystemChat) {
      return ConversationsListItemClickedText.SYSTEM;
    }

    return ConversationsListItemClickedText.SINGLE;
  }, [isGroupChat, group_info?.familyId, isSystemChat]);

  const handleClickConversationOpened = useCallback(() => {
    emitConversationsListItemClicked({
      size: Number(conversation.unread_message_count) || 0,
      text: analyticsText,
      chatId: conversation_id,
      screen: isRequestsVisible
        ? ConversationsListItemClickedScreen.CHAT_REQUESTS
        : ConversationsListItemClickedScreen.MAIN_CONVERSATIONS,
    });
  }, [
    conversation.unread_message_count,
    isRequestsVisible,
    conversation_id,
    analyticsText,
  ]);

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <Link
        to={
          isRequestsVisible
            ? makeLinkToMessageRequest(
                conversation.conversation_id,
                CHAT_SOURCE_CHAT_LIST
              )
            : makeLinkToChat(
                conversation.conversation_id,
                CHAT_SOURCE_CHAT_LIST
              )
        }
        className={classnames(styles.link, {
          [styles.selected]: isCurrent,
        })}
        onClick={handleClickConversationOpened}
        data-testid={conversation.conversation_id}
      >
        {group_info || isGroupChat ? (
          <>
            {group_info?.familyId ? (
              <LiveFamilyConversationContent
                groupInfo={group_info}
                conversationId={conversation.conversation_id}
              />
            ) : (
              <GroupConversationContent
                groupInfo={group_info}
                conversationId={conversation.conversation_id}
              />
            )}
          </>
        ) : (
          <OneOnOneConversationContent
            conversationId={conversation_id}
            accountInfo={account_info}
          />
        )}
        {latestMessage && latestMessage.id.ts > 0 && (
          <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH5} className={styles.date}>
            <LastMessageDatePreview value={Number(latestMessage.id.ts)} />
          </Typography>
        )}
        {!!Number(conversation.unread_message_count) && (
          <Typography
            type={TYPOGRAPHY_TYPE.HEADLINE6}
            as="div"
            className={classnames(styles.unreadMessagesIndicator, {
              [styles.unreadMessagesRequestIndicator]:
                state === ConversationState.CHAT_REQUEST,
            })}
          >
            {Number(conversation.unread_message_count) >
            MAX_DISPLAYED_MESSAGES_COUNT
              ? `${MAX_DISPLAYED_MESSAGES_COUNT}+`
              : conversation.unread_message_count}
          </Typography>
        )}
        {latestMessage && (
          <Typography
            type={TYPOGRAPHY_TYPE.PARAGRAPH3}
            className={styles.preview}
          >
            <Preview
              groupInfo={conversation.group_info}
              message={latestMessage}
            />
          </Typography>
        )}
      </Link>
    </div>
  );
};

export default memo(ConversationListItem);
