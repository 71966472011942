import React from "react";
import classnames from "classnames";
import { Breakpoints } from "chat/imports/constants";
import { useBreakpoint } from "chat/imports/hooks";
import { ComponentWithClassName } from "chat/imports/types";
import styles from "./BannersSkeleton.scss";

const BannersSkeleton: ComponentWithClassName = ({ className }) => {
  const breakpoint = useBreakpoint();

  return (
    <div className={classnames(styles.root, styles[breakpoint], className)}>
      <div className={styles.overlay} />
      <div className={styles.viewport}>
        <div className={styles.container}>
          <div className={styles.slide}>
            <div className={styles.placeholder} />
          </div>
          <div className={styles.slide}>
            <div className={styles.placeholder} />
          </div>
          {breakpoint === Breakpoints.DESKTOP && (
            <div className={styles.slide}>
              <div className={styles.placeholder} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannersSkeleton;
