import { fetchProfilesV2 } from "api/profile";
import { profilesCacheSelectors } from "state/selectors";
import {
  willLoadProfiles,
  failedToLoadProfiles,
  loadedProfiles,
} from "state/actionCreators/profile";
import { uniq } from "src/utils/miniLodash";

export default ({
    ids = [],
    loadOnlyIfMissing = false,
    params = { basic: true, detail: false, live: false, follow: false },
    ...rest
  }) =>
  (dispatch, getState) => {
    const state = getState();
    if (!ids || !ids.length) {
      return Promise.resolve();
    }
    const idsToFetch = uniq(
      ids.filter(
        (x) =>
          x &&
          !profilesCacheSelectors.isLoadingProfileById(state, x, params) &&
          (!loadOnlyIfMissing ||
            !profilesCacheSelectors.hasCompleteProfileForParams(
              state,
              x,
              params
            ))
      )
    );
    if (!idsToFetch.length) {
      return Promise.resolve(false);
    }
    dispatch(willLoadProfiles({ ids: idsToFetch, params, ...rest }));
    return fetchProfilesV2({
      profileIds: idsToFetch,
      basicProfile: params.basic || params.detail,
      liveStats: params.live,
      followStats: params.follow,
    })
      .then((profiles) =>
        dispatch(loadedProfiles({ profiles, params, ...rest }))
      )
      .catch((error) =>
        dispatch(failedToLoadProfiles({ error, ids, params, ...rest }))
      );
  };
