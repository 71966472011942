import { MessageStatusResult } from "chat/enums";
import { EventFields, EventNames, emitEvent } from "chat/imports/analytics";
import {
  ContentType,
  SINGLE_CHAT_MESSAGE_SENT_ANCHOR_ID,
  SINGLE_CHAT_MESSAGE_SENT_ANCHOR_TYPE,
} from "chat/premiumMessage/types";

export type EmitPremiumMessageSentParams = {
  accountId: string;
  chatId: string;
  giftId: string;
  messageId?: string;
  recipientAccountId: string;
  result: MessageStatusResult;
  type: ContentType;
};

export function emitPremiumMessageSent(params: EmitPremiumMessageSentParams) {
  const {
    accountId,
    chatId,
    giftId,
    messageId,
    recipientAccountId,
    result,
    type,
  } = params;

  emitEvent(EventNames.SINGLE_CHAT_MESSAGE_SENT, {
    [EventFields.FLAGS]: type.toLowerCase(),
    [EventFields.ANCHOR_ID]: SINGLE_CHAT_MESSAGE_SENT_ANCHOR_ID.PREMIUM_MESSAGE,
    [EventFields.ANCHOR_TYPE]: SINGLE_CHAT_MESSAGE_SENT_ANCHOR_TYPE.SENT,
    [EventFields.GIFT_ID]: giftId,
    [EventFields.PREMIUM_MESSAGE_ID]: messageId,
    [EventFields.RECIPIENT_ACCOUNT_ID]: recipientAccountId,
    [EventFields.LOGGED_IN_ACCOUNT_ID]: accountId,
    [EventFields.RESULT]: result,
    [EventFields.CHAT_ID]: chatId,
  });
}
