import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import LoginProviders from "src/features/signin/bottomScreen/components/LoginProviders";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import { SECOND } from "src/features/signin/imports/constants";
import { RootState, loginSelectors } from "src/features/signin/imports/state";
import { VoidCallback } from "src/features/signin/imports/types";
import { Spinner } from "src/features/signin/imports/ui";
import { useIsMountedRef } from "src/features/signin/imports/utils";
import { prepareProviders } from "src/features/signin/state/flows/login";
import styles from "./SignInOptions.scss";

const selector = (state: RootState) => ({
  loginInProgress: loginSelectors.isLoginInProgress(state),
  providers: loginSelectors.getAvailableProviders(state),
  isPreparingProviders: loginSelectors.isPreparingProviders(state),
});

interface SignInOptionsParams {
  beginPhoneNumberLogin: VoidCallback;
  dark?: boolean;
  onLoginSuccess?: VoidCallback;
  registrationSource: RegistrationSource;
}

const SignInOptions = ({
  dark,
  beginPhoneNumberLogin,
  onLoginSuccess,
  registrationSource,
}: SignInOptionsParams) => {
  const dispatch = useDispatch();
  const { isPreparingProviders, loginInProgress, providers } = useSelector(
    selector,
    shallowEqual
  );
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    dispatch(prepareProviders());
  }, [dispatch]);

  const [hasTimeToLoadProviders, setHasTimeToLoadProviders] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMountedRef?.current) {
        setHasTimeToLoadProviders(false);
      }
    }, SECOND);

    return () => {
      clearTimeout(timer);
    };
  }, [isMountedRef]);

  const providersHidden = isPreparingProviders && hasTimeToLoadProviders;
  const isPreparingProvidersOrLoginInProgress =
    providersHidden || loginInProgress;

  return (
    <div
      className={classnames(styles.root, {
        [styles.dark]: dark,
      })}
      data-testid="sign-in-options"
    >
      {isPreparingProvidersOrLoginInProgress ? (
        <Spinner className={styles.spinner} color="inherit" />
      ) : (
        <LoginProviders
          providers={providers}
          beginPhoneNumberLogin={beginPhoneNumberLogin}
          onLoginSuccess={onLoginSuccess}
          registrationSource={registrationSource}
        />
      )}
    </div>
  );
};

export default SignInOptions;
