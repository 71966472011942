import {
  BALANCE_BEGIN_FETCH,
  BALANCE_END_FETCH,
  TOTAL_CREDITS_UPDATE,
  GIFTS_BALANCE_BEGIN_FETCH,
  GIFTS_BALANCE_END_FETCH,
} from "state/actionTypes";

export const willFetchUserBalance = () => ({ type: BALANCE_BEGIN_FETCH });
export const fetchedUserBalance = ({
  credits,
  points,
  vipStatus,
  accountId,
  coinsLeft,
  vipLevelInfo,
}) => ({
  type: BALANCE_END_FETCH,
  payload: { credits, points, vipStatus, coinsLeft, vipLevelInfo },
  meta: { accountId },
});
export const failedToFetchUserBalance = (error) => ({
  type: BALANCE_END_FETCH,
  payload: error,
  error: true,
});

export const fetchedTotalCredits = (credits) => ({
  type: TOTAL_CREDITS_UPDATE,
  payload: credits,
});

export const willFetchGiftsBalance = () => ({
  type: GIFTS_BALANCE_BEGIN_FETCH,
});
export const fetchedGiftsBalance = (giftsBalance = []) => ({
  type: GIFTS_BALANCE_END_FETCH,
  payload: giftsBalance,
});
export const failedToFetchGiftsBalance = (error) => ({
  type: GIFTS_BALANCE_END_FETCH,
  payload: error,
  error: true,
});
