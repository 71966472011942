import { CHAT_SOURCE_DIRECT_LINK } from "@analytics/chatSource";
import { emitEvent } from "@analytics/emit";
import { EventNames } from "@analytics/enums";

export const defaultLocationState = {
  source: CHAT_SOURCE_DIRECT_LINK,
};

export const emitEnteredLiveFamily = ({
  ownerId,
  locationState = defaultLocationState,
}: {
  locationState?: { source: number };
  ownerId: string;
}) =>
  emitEvent(EventNames.LIVE_FAMILY_ENTER, {
    source: locationState.source,
    owner_id: ownerId,
    result: 1,
  });
