import { LottieConfigWithPathOrData } from "src/types/common";

const lottieDefaultConfig: Partial<LottieConfigWithPathOrData> = {
  renderer: "canvas",
  autoplay: true,
  loop: false,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
};

export default lottieDefaultConfig;
