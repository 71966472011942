import { ExternalMessageInfo } from "generated/proto/ExternalMessage";
import { Nullable } from "src/types/common";
import { parseMessageFromBase64 } from "src/utils/protobufUtil";

interface ActionInfo {
  actionText: string;
  actionUrl: string;
  installUrl: string;
}

interface ExternalMessagePayload {
  actionInfo?: ActionInfo[];
  messageText: string;
  previewThumbnailUrl: string;
}

export const getParsedExternalMessagePayload = (
  protobufPayload: Nullable<string | undefined>
) => {
  if (!protobufPayload) {
    return null;
  }

  try {
    return parseMessageFromBase64(
      protobufPayload,
      ExternalMessageInfo
    ) as ExternalMessagePayload;
  } catch (e) {
    return null;
  }
};
