import React, { forwardRef, memo } from "react";
import classnames from "classnames";
import { CustomContentProps, SnackbarContent } from "notistack";
import { RETRY_PAY_BUTTON_PORTAL_ID } from "src/constants";
import iconFailure from "img/ic-failure.svg?url";
import styles from "./common.scss";

const FailureToast = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ message, className, style }, ref) => (
    <SnackbarContent ref={ref} className={className} style={style}>
      <div className={styles.root} data-testid="toaster-payment-error">
        <>
          <div className={classnames(styles.message, styles.fullWidth)}>
            <div className={styles.leftBlock}>
              <img
                src={iconFailure}
                className={styles.iconFailure}
                alt="failure"
              />
              <p>{message}</p>
              <div
                id={RETRY_PAY_BUTTON_PORTAL_ID}
                data-testid="toaster-payment-retry"
              />
            </div>
          </div>
        </>
      </div>
    </SnackbarContent>
  )
);

FailureToast.displayName = "FailureToast";

export default memo(FailureToast);
