import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { messages } from "chat/components/common/messages";
import { DisplayName } from "chat/imports/components";
import { useMyProfile } from "chat/imports/hooks";

const IdentityValidationMessageHeader: React.FC = () => {
  const { basicProfile } = useMyProfile(true);

  return (
    <FormattedMessage
      {...messages.header}
      values={{
        userName: <DisplayName basicProfile={basicProfile} />,
      }}
    />
  );
};

export default memo(IdentityValidationMessageHeader);
