import { createAction } from "@reduxjs/toolkit";
import { GridConfig } from "state/tree/pipsConfig";

interface AttachPipToSlotPayload {
  pipPosition: string;
  slotPosition: string;
}

export const setIsPipDragging = createAction<boolean>(
  "lwc/pipsConfig/setIsPipDragging"
);

export const attachPipToSlot = createAction<AttachPipToSlotPayload>(
  "lwc/pipsConfig/attachPipToSlot"
);

export const setPipsGrid = createAction<GridConfig>(
  "lwc/pipsConfig/setPipsGrid"
);
export const resetPipsGrid = createAction("lwc/pipsConfig/resetPipsGrid");
