import React, { createContext, useCallback, useMemo } from "react";
import emptyFunction from "fbjs/lib/emptyFunction";
import { usePersistentState } from "chat/imports/hooks";
import { sample } from "chat/imports/utils";

const colors = [
  "#78BEFF",
  "#DDE0EA",
  "#85D654",
  "#FFD746",
  "#FFEFE2",
  "#FFB371",
  "#B96619",
  "#00C5DA",
  "#0094F5",
  "#FFE0E7",
];

type ColorsMap = Record<string, string>;

interface ChatAvatarColorContextType {
  conversationIdToColorMap: ColorsMap;
  getRandomChatColor(conversationId: string): void;
}

const CHAT_COLORS_MAP = "chat.colors.map";

const ChatAvatarColorContext = createContext<ChatAvatarColorContextType>({
  conversationIdToColorMap: {},
  getRandomChatColor: emptyFunction,
});

export const ChatAvatarColorContextProvider: React.FC = ({ children }) => {
  const [colorsMap, setColorsMap] = usePersistentState<ColorsMap>({
    key: CHAT_COLORS_MAP,
    initialValue: {},
    listenForUpdates: true,
  });

  const getRandomChatColor = useCallback(
    (conversationId: string) => {
      if (colorsMap[conversationId] != null) {
        return;
      }

      const randomColor = sample(colors);

      setColorsMap({
        ...colorsMap,
        [conversationId]: randomColor,
      });
    },
    [colorsMap, setColorsMap]
  );

  const value = useMemo(
    () => ({
      conversationIdToColorMap: colorsMap,
      getRandomChatColor,
    }),
    [getRandomChatColor, colorsMap]
  );

  return (
    <ChatAvatarColorContext.Provider value={value}>
      {children}
    </ChatAvatarColorContext.Provider>
  );
};

export default ChatAvatarColorContext;
