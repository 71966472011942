import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import copy from "copy-to-clipboard";
import {
  SHARE_TARGET_COPY_LINK,
  SHARE_TARGET_MORE,
  emitShareAction,
} from "@analytics/emitShareEvent";
import ScreenViewReportingContext from "@analytics/screenView/ScreenViewReportingContext";
import { Breakpoints, DeviceType, ToastType } from "src/enums";
import { VoidCallback } from "src/types/common";
import { useBreakpointPrecise } from "src/ui/hooks/useBreakpoint";
import {
  activateClipboard,
  copyAndRemove,
} from "src/utils/copyToAppleClipboard";
import { deviceInfoSelectors, userSelectors } from "state/selectors";
import sharedMessages from "ui/common/intl/sharedMessages";
import { useToast } from "ui/hooks/useToast";
import useUiAction from "ui/hooks/useUiAction";
import { ReactComponent as CheckMarkIcon } from "img/check-mark_32.svg";

export interface Options {
  afterLinkCopied?: VoidCallback;
  afterShare?: VoidCallback;
  emitOnly?: boolean;
  link: string;
  onUnableToShare?: VoidCallback;
  title: string;
}

export default ({
  link,
  title,
  afterShare,
  afterLinkCopied,
  onUnableToShare,
  emitOnly = false,
}: Options) => {
  const breakpoint = useBreakpointPrecise();
  const { notify } = useToast();

  const { getStack } = useContext(ScreenViewReportingContext);
  const [screen] = getStack();

  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const accountId = useSelector(userSelectors.getMyAccountId);

  const routeParams = useRouteMatch<{ id: string }>("*/:id");
  const sourceId = routeParams?.params?.id;

  const isDesktop =
    deviceType === DeviceType.DESKTOP && breakpoint === Breakpoints.DESKTOP;

  const showCopiedMessage = useCallback(() => {
    notify({
      IconComponent: CheckMarkIcon,
      type: ToastType.REGULAR,
      title: sharedMessages.linkCopied,
    });
  }, [notify]);

  const emitShareEvent = useCallback(
    (target: string) =>
      emitShareAction({
        screen,
        target,
        peerId: accountId,
        sourceId,
      }),
    [screen, accountId, sourceId]
  );

  const callback = useCallback(() => {
    setTimeout(copyAndRemove, 525);

    if (!isDesktop && navigator.share) {
      navigator
        .share({
          title,
          url: link,
        })
        .then(() => {
          emitShareEvent(SHARE_TARGET_MORE);
          afterShare?.();
        })
        .catch((e) => {
          if (e.name === "NotAllowedError") {
            activateClipboard(link, deviceType);
            showCopiedMessage();
            afterLinkCopied?.();
          } else {
            onUnableToShare?.();
          }
        });
    } else {
      copy(link);
      emitShareEvent(SHARE_TARGET_COPY_LINK);
      showCopiedMessage();
      afterLinkCopied?.();
    }
  }, [
    link,
    title,
    afterShare,
    deviceType,
    showCopiedMessage,
    afterLinkCopied,
    onUnableToShare,
    isDesktop,
    emitShareEvent,
  ]);

  return useUiAction({
    target: "share",
    callback: emitOnly ? afterShare : callback,
  });
};
