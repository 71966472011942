import merge from "lodash.merge";
import { PersonalFeedType } from "src/enums";
import { LOGOUT_END } from "src/features/signin/exports/state/actionTypes";
import { uniq } from "src/utils/miniLodash";
import {
  LIVE_POSTS_BEGIN_FETCH,
  LIVE_POSTS_END_FETCH,
  LIVE_POSTS_MODE_SELECTED,
  LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED,
} from "state/actionTypes";
import withFetcher, { createFetcherActions } from "state/hor/withFetcher";
import noopReducer from "state/tree/utils/noopReducer";
import parseLiveStreamsFeed, {
  allFollowingsRecommendations,
} from "state/tree/utils/parseLiveStreamsFeed";

export const personalTabs = [PersonalFeedType.FOLLOWING];

export const persistConfig = { whitelist: ["tabsConfig"] };

export const actionCreators = {
  ...createFetcherActions({
    beginFetchActionType: LIVE_POSTS_BEGIN_FETCH,
    endFetchActionType: LIVE_POSTS_END_FETCH,
  }),
  feedTypeSelected: (feedType) => ({
    type: LIVE_POSTS_MODE_SELECTED,
    payload: feedType,
  }),
};

const postsReducer = withFetcher({
  beginFetchActionType: LIVE_POSTS_BEGIN_FETCH,
  endFetchActionType: LIVE_POSTS_END_FETCH,
  initialData: {
    result: [],
    sessionId: "",
    requestId: "",
    totalCount: 0,
    pageCount: 0,
    gotEmptyResult: false,
    timestamp: 0,
  },
  extractData: parseLiveStreamsFeed,
  mergeData: (oldData, newData) => ({
    sessionId: newData.sessionId,
    requestId: newData.requestId,
    pageCount: oldData.pageCount + newData.pageCount, // or simply +1 ?
    totalCount: newData.totalCount,
    result: uniq([...oldData.result, ...newData.result]),
    gotEmptyResult: newData.gotEmptyResult,
    timestamp: newData.timestamp,
  }),
})(noopReducer);

const mainInitialState = {
  streamsPerMode: {
    [PersonalFeedType.FOLLOWING]: postsReducer(undefined, {}),
  },
  selectedMode: { common: undefined, personal: PersonalFeedType.FOLLOWING },
};

const reducer = (state = mainInitialState, action) => {
  switch (action.type) {
    case LIVE_POSTS_BEGIN_FETCH:
    case LIVE_POSTS_END_FETCH: {
      const mode = action.meta.mode;

      return {
        ...state,
        streamsPerMode: {
          ...state.streamsPerMode,
          [mode]: postsReducer(state.streamsPerMode[mode], action),
        },
      };
    }
    case LOGOUT_END: {
      if (action.error) {
        return state;
      }

      return {
        ...state,
        streamsPerMode: {
          ...state.streamsPerMode,
          [PersonalFeedType.FOLLOWING]: postsReducer(undefined, {}),
          [PersonalFeedType.OLD_FOLLOWING]: postsReducer(undefined, {}),
          [PersonalFeedType.RECOMMENDED]: postsReducer(undefined, {}),
          [PersonalFeedType.ALL]: postsReducer(undefined, {}),
        },
      };
    }
    case LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED: {
      const {
        payload: {
          stream: { id },
        },
        meta: { following },
      } = action;
      if (!following) {
        return state;
      }
      const followingState = state.streamsPerMode[PersonalFeedType.FOLLOWING];

      return {
        ...state,
        streamsPerMode: {
          ...state.streamsPerMode,
          [PersonalFeedType.FOLLOWING]: {
            ...followingState,
            result: uniq([id, ...followingState.result]),
          },
        },
      };
    }
    case LIVE_POSTS_MODE_SELECTED: {
      const mode = action.payload;
      if (personalTabs.includes(mode)) {
        return merge({}, state, { selectedMode: { personal: mode } });
      }

      return merge({}, state, { selectedMode: { common: mode } });
    }
  }

  return state;
};

export const selectors = {
  getLatestSelectedPersonalMode: (state) => state.selectedMode.personal,
  getLatestSelectedCommonMode: (state) => state.selectedMode.common,
  getStreamsListForMode: (state, mode) =>
    state.streamsPerMode[mode]?.result || [],
  getPageCount: (state, mode) => state.streamsPerMode[mode]?.pageCount || 0,
  getTotalCount: (state, mode) => state.streamsPerMode[mode]?.totalCount || 0,
  getSessionId: (state, mode) => state.streamsPerMode[mode]?.sessionId || "",
  getRequestId: (state, mode) => state.streamsPerMode[mode]?.requestId || "",
  isLoadingMode: (state, mode) =>
    state.streamsPerMode[mode]?.isLoadInProgress || false,
  canLoadMore: (state, mode) => {
    const data = state.streamsPerMode[mode];
    if (!data) {
      return false;
    }

    if (allFollowingsRecommendations.includes(mode)) {
      return !data.gotEmptyResult;
    }

    return data.totalCount > data.result.length && !data.gotEmptyResult;
  },
  isLoadFailedForMode: (state, mode) =>
    state.streamsPerMode[mode]?.isLoadFailed || false,
  getLastUpdatedTimestamp: (state, mode) =>
    state.streamsPerMode[mode]?.timestamp || 0,
};

export default reducer;
