import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";

export const fetchPurchasableBundles = ({ version = "" } = {}) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/offers?version=${version}`
  ).then((resp) => resp.json());

export const fetchPreferredProvider = (currency) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/routeProvider?currency=${currency}`
  ).then((resp) => resp.text());

export const sofortPayment = (params) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/checkout/sofort`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export const idealPayment = (params) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/checkout/ideal`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export const giropayPayment = (params) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/checkout/giropay`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export const fetchCurrenciesConfig = () =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/currencyConfig`
  ).then((resp) => resp.json());

export const deleteSafeChargeSavedCard = (id, safeChargeAccount) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/safecharge/deleteSavedCard?id=${id}&safeChargeAccount=${safeChargeAccount}`
  );

export const mintroutePayment = (params) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/mintroute/redeem`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export const fetchMintrouteSuspendedStatus = () =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/mintroute/suspended`
  ).then((resp) => resp.text());

export const fetchUpgradeSubscription = ({
  subscriptionId,
  externalOfferId,
  details,
}) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/subscription/${subscriptionId}/upgrade`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ externalOfferId, details }),
    }
  ).then((resp) => resp.json());
