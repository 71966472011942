import { Nullable } from "src/features/autoLogin/imports/types";

export enum AutoLoginQueryKey {
  ACCOUNT_ID = "accountId",
  TOKEN = "token",
}

export interface AutoLoginQueryParams {
  accountId: Nullable<string>;
  token: Nullable<string>;
}
