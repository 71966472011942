import type { AnyAction, Middleware } from "redux";
import {
  BALANCE_SERVICE_IDENTIFIER,
  BALANCE_SERVICE_NAME,
} from "src/features/offers/premiumEntrance/welcomePremiumOffer/constants";
import { fetchUserBalance } from "src/features/offers/premiumEntrance/welcomePremiumOffer/imports/api";
import { executeBuyTicket } from "src/features/offers/premiumEntrance/welcomePremiumOffer/imports/state/flows/flows";
import selectTicketPrivateParams from "src/features/offers/premiumEntrance/welcomePremiumOffer/imports/utils/selectTicketPrivateParams";
// remove in Jira ticket: https://tango-me.atlassian.net/browse/WEB-5703
import { getWelcomePremiumOfferEnabled } from "state/abTests";
// eslint-disable-next-line no-restricted-imports
import { ACME_RECEIVED } from "state/actionTypes";
import {
  getCurrentStream,
  upgradedStreamsSelectors,
  viewerSessionSelectors,
} from "state/selectors";

export const buyEntranceOnBalanceUpdate: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    const state = getState();
    const isWelcomePremiumOfferEnabled = getWelcomePremiumOfferEnabled(state);

    if (action.type === ACME_RECEIVED && isWelcomePremiumOfferEnabled) {
      const { serviceName, serviceIdentifier } = action.payload;
      const isTicketPurchaseInProgress =
        upgradedStreamsSelectors.isTicketPurchaseInProgress(state);

      if (
        serviceName === BALANCE_SERVICE_NAME &&
        serviceIdentifier === BALANCE_SERVICE_IDENTIFIER &&
        isTicketPurchaseInProgress
      ) {
        const userBalanceRes = await fetchUserBalance();
        const ticketPrice = getCurrentStream(state).ticketPrice;
        const credits = userBalanceRes?.userBalance?.credits;

        if (credits >= ticketPrice) {
          const streamId = viewerSessionSelectors.getStreamId(state);

          const analyticEvents = selectTicketPrivateParams(state);
          dispatch(
            executeBuyTicket(
              streamId,
              analyticEvents,
              true
            ) as unknown as AnyAction
          );
        }
      }
    }

    return next(action);
  };
