import React from "react";
import { SnackbarProvider } from "notistack";
import AuctionToast from "ui/snackbar/components/AuctionToast";
import LandingDismissToast from "ui/snackbar/components/LandingDismissToast";
import ClipboardToast from "ui/snackbar/components/ClipboardToast";
import SuccessToast from "ui/snackbar/components/purchase/SuccessToast";
import PendingToast from "ui/snackbar/components/purchase/PendingToast";
import FailureToast from "ui/snackbar/components/purchase/FailureToast";
import ImageUploadFailedToast, {
  ImageUploadFailedToastProps,
} from "ui/snackbar/components/ImageUploadFailedToast";
import styles from "./Snackbar.scss";

declare module "notistack" {
  // https://notistack.com/features/customization#custom-variant-(typescript)
  interface VariantOverrides {
    auction: true;
    landingDismiss: true;
    clipboard: true;
    imageUploadFailed: ImageUploadFailedToastProps;
    purchaseSuccess: true;
    purchasePending: true;
    purchaseFailure: true;
  }
}

const Snackbar: React.FC = ({ children }) => (
  <SnackbarProvider
    classes={{
      containerRoot: styles.root,
    }}
    preventDuplicate
    Components={{
      auction: AuctionToast,
      landingDismiss: LandingDismissToast,
      clipboard: ClipboardToast,
      imageUploadFailed: ImageUploadFailedToast,
      purchaseSuccess: SuccessToast,
      purchasePending: PendingToast,
      purchaseFailure: FailureToast,
    }}
  >
    {children}
  </SnackbarProvider>
);

export default Snackbar;
