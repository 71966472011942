import React, { FC, memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { ReactComponent as IncomingArrowIcon } from "chat/assets/incoming-arrow.svg";
import { ReactComponent as OutgoingArrowIcon } from "chat/assets/outgoing-arrow.svg";
import { ReactComponent as PhoneIcon } from "chat/assets/phone.svg";
import { ReactComponent as VideoCameraIcon } from "chat/assets/video-camera.svg";
import { MessageDateNow } from "chat/components/MessageDateNow/MessageDateNow";
import { chatMessages } from "chat/components/common/messages";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { StoredMessage } from "chat/state/reducer";
import { CallLogPayload, Mode } from "chat/types";
import { formatReadableDuration } from "chat/utils/formatReadableDuration";
import styles from "./CallMessage.scss";

interface CallMessageProps {
  messageId: StoredMessage["id"];
  messagePayload: CallLogPayload;
}
export const CallMessage: FC<CallMessageProps> = memo(
  ({
    messageId: { ts },
    messagePayload: { duration, is_incoming: isIncoming, mode },
  }) => {
    const intl = useIntl();

    const isMissed = !duration;

    return (
      <div
        className={classnames(styles.root, { [styles.incoming]: isIncoming })}
      >
        <div>
          <div className={styles.callStatus}>
            {isIncoming ? (
              <>
                <IncomingArrowIcon className={styles.arrowIcon} />
                <Typography
                  type={TYPOGRAPHY_TYPE.PARAGRAPH2}
                  as="div"
                  className={styles.ellipsis}
                >
                  <FormattedMessage
                    {...(isMissed
                      ? chatMessages.missedCall
                      : chatMessages.incomingCall)}
                  />
                </Typography>
              </>
            ) : (
              <>
                <OutgoingArrowIcon className={styles.arrowIcon} />
                <Typography
                  type={TYPOGRAPHY_TYPE.PARAGRAPH2}
                  as="div"
                  className={styles.ellipsis}
                >
                  <FormattedMessage {...chatMessages.outgoingCall} />
                </Typography>
              </>
            )}
          </div>

          <Typography
            type={TYPOGRAPHY_TYPE.PARAGRAPH5}
            className={styles.callMetadata}
            as="div"
          >
            <MessageDateNow value={ts} />
            {!!duration && `, ${formatReadableDuration(duration, intl)}`}
          </Typography>
        </div>

        <div className={styles.callMode}>
          {mode === Mode.AUDIO ? (
            <PhoneIcon className={styles.callModeIcon} />
          ) : (
            <VideoCameraIcon className={styles.callModeIcon} />
          )}
        </div>
      </div>
    );
  }
);

CallMessage.displayName = "CallMessage";
