/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.VideoPost || ($protobuf.roots.VideoPost = {});

export const VideoPost = $root.VideoPost = (() => {

    function VideoPost(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    VideoPost.prototype.caption = "";
    VideoPost.prototype.url = "";
    VideoPost.prototype.thumbnailUrl = "";
    VideoPost.prototype.referenceUrl = "";
    VideoPost.prototype.mediaId = "";
    VideoPost.prototype.duration = 0;
    VideoPost.prototype.rotation = 0;
    VideoPost.prototype.width = 0;
    VideoPost.prototype.height = 0;
    VideoPost.prototype.blurredThumbnailUrl = "";
    VideoPost.prototype.blurLevel = 0;
    VideoPost.prototype.blurredMediaId = "";

    VideoPost.create = function create(properties) {
        return new VideoPost(properties);
    };

    VideoPost.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
            writer.uint32(10).string(message.caption);
        writer.uint32(18).string(message.url);
        writer.uint32(26).string(message.thumbnailUrl);
        if (message.referenceUrl != null && Object.hasOwnProperty.call(message, "referenceUrl"))
            writer.uint32(34).string(message.referenceUrl);
        if (message.mediaId != null && Object.hasOwnProperty.call(message, "mediaId"))
            writer.uint32(42).string(message.mediaId);
        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
            writer.uint32(53).sfixed32(message.duration);
        if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
            writer.uint32(61).sfixed32(message.rotation);
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(69).sfixed32(message.width);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(77).sfixed32(message.height);
        if (message.blurredThumbnailUrl != null && Object.hasOwnProperty.call(message, "blurredThumbnailUrl"))
            writer.uint32(82).string(message.blurredThumbnailUrl);
        if (message.blurLevel != null && Object.hasOwnProperty.call(message, "blurLevel"))
            writer.uint32(89).double(message.blurLevel);
        if (message.blurredMediaId != null && Object.hasOwnProperty.call(message, "blurredMediaId"))
            writer.uint32(98).string(message.blurredMediaId);
        return writer;
    };

    VideoPost.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    VideoPost.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VideoPost();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.caption = reader.string();
                    break;
                }
            case 2: {
                    message.url = reader.string();
                    break;
                }
            case 3: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            case 4: {
                    message.referenceUrl = reader.string();
                    break;
                }
            case 5: {
                    message.mediaId = reader.string();
                    break;
                }
            case 6: {
                    message.duration = reader.sfixed32();
                    break;
                }
            case 7: {
                    message.rotation = reader.sfixed32();
                    break;
                }
            case 8: {
                    message.width = reader.sfixed32();
                    break;
                }
            case 9: {
                    message.height = reader.sfixed32();
                    break;
                }
            case 10: {
                    message.blurredThumbnailUrl = reader.string();
                    break;
                }
            case 11: {
                    message.blurLevel = reader.double();
                    break;
                }
            case 12: {
                    message.blurredMediaId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("url"))
            throw $util.ProtocolError("missing required 'url'", { instance: message });
        if (!message.hasOwnProperty("thumbnailUrl"))
            throw $util.ProtocolError("missing required 'thumbnailUrl'", { instance: message });
        return message;
    };

    VideoPost.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    VideoPost.fromObject = function fromObject(object) {
        if (object instanceof $root.VideoPost)
            return object;
        let message = new $root.VideoPost();
        if (object.caption != null)
            message.caption = String(object.caption);
        if (object.url != null)
            message.url = String(object.url);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        if (object.referenceUrl != null)
            message.referenceUrl = String(object.referenceUrl);
        if (object.mediaId != null)
            message.mediaId = String(object.mediaId);
        if (object.duration != null)
            message.duration = object.duration | 0;
        if (object.rotation != null)
            message.rotation = object.rotation | 0;
        if (object.width != null)
            message.width = object.width | 0;
        if (object.height != null)
            message.height = object.height | 0;
        if (object.blurredThumbnailUrl != null)
            message.blurredThumbnailUrl = String(object.blurredThumbnailUrl);
        if (object.blurLevel != null)
            message.blurLevel = Number(object.blurLevel);
        if (object.blurredMediaId != null)
            message.blurredMediaId = String(object.blurredMediaId);
        return message;
    };

    VideoPost.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.caption = "";
            object.url = "";
            object.thumbnailUrl = "";
            object.referenceUrl = "";
            object.mediaId = "";
            object.duration = 0;
            object.rotation = 0;
            object.width = 0;
            object.height = 0;
            object.blurredThumbnailUrl = "";
            object.blurLevel = 0;
            object.blurredMediaId = "";
        }
        if (message.caption != null && message.hasOwnProperty("caption"))
            object.caption = message.caption;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        if (message.referenceUrl != null && message.hasOwnProperty("referenceUrl"))
            object.referenceUrl = message.referenceUrl;
        if (message.mediaId != null && message.hasOwnProperty("mediaId"))
            object.mediaId = message.mediaId;
        if (message.duration != null && message.hasOwnProperty("duration"))
            object.duration = message.duration;
        if (message.rotation != null && message.hasOwnProperty("rotation"))
            object.rotation = message.rotation;
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        if (message.blurredThumbnailUrl != null && message.hasOwnProperty("blurredThumbnailUrl"))
            object.blurredThumbnailUrl = message.blurredThumbnailUrl;
        if (message.blurLevel != null && message.hasOwnProperty("blurLevel"))
            object.blurLevel = options.json && !isFinite(message.blurLevel) ? String(message.blurLevel) : message.blurLevel;
        if (message.blurredMediaId != null && message.hasOwnProperty("blurredMediaId"))
            object.blurredMediaId = message.blurredMediaId;
        return object;
    };

    VideoPost.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    VideoPost.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VideoPost";
    };

    return VideoPost;
})();

export { $root as default };
