import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { emitFollow, emitUnfollow } from "@analytics/emitFollowUnfollow";
import { fetchFollowingList, followUser, unfollowUser } from "api/follow";
import { TANGO_ACCOUNT_ID } from "src/constants";
import { FollowSource, LoginPromotionType, TCNNAction } from "src/enums";
import { openLoginView } from "src/features/signin/exports/state/flows";
import {
  followedUser,
  followingListFetcher,
  unfollowRealtimeRecommendation,
  unfollowedUser,
} from "state/actionCreators/follow";
import { genericNetworkError } from "state/actionCreators/networkError";
import { removeSelectedTcnnMessage } from "state/actionCreators/tcnn";
import retryAfterCaptcha from "state/flows/utils/retryAfterCaptcha";
import {
  deviceInfoSelectors,
  followingListSelectors,
  getBroadcasterId,
  loginSelectors,
  tcnnSelectors,
  userSelectors,
} from "state/selectors";

export const refreshFollowingList = () => (dispatch, getState) => {
  const state = getState();
  if (
    followingListSelectors.getIsLoadInProgress(state) ||
    !loginSelectors.isLoggedIn(state) ||
    followingListSelectors.getIsUpToDate(state)
  ) {
    return Promise.resolve();
  }
  dispatch(followingListFetcher.fetchBegan());

  return fetchFollowingList()
    .then((json) => {
      dispatch(
        followingListFetcher.fetchCompleted({ data: json, replaceAll: true })
      );

      return json;
    })
    .catch((error) => {
      dispatch(followingListFetcher.fetchFailed({ error, removeAll: false }));
      throw error;
    });
};

/**
 * @param {string} accountId
 * @param {number} source
 */
export const follow = (accountId, source) => async (dispatch, getState) => {
  if (!accountId) {
    // eslint-disable-next-line no-throw-literal
    throw "AccountId should be specified!";
  }
  if (accountId === TANGO_ACCOUNT_ID) {
    return false;
  }
  const state = getState();
  if (followingListSelectors.getIsFollowingByAccountId(state, accountId)) {
    return true;
  }
  const me = userSelectors.getMyAccountId(state);
  const locale = deviceInfoSelectors.getDeviceLocale(state);
  const selectedTcnnMessage = tcnnSelectors.getSelectedTcnnMessage(state);
  const resultSource =
    selectedTcnnMessage.action === TCNNAction.FOLLOW
      ? FollowSource.TCNN
      : source;
  const params = { accountId, me, source };
  const isLoggedIn = loginSelectors.isLoggedIn(state);
  AnalyticsUtils.updateInteractionId();
  try {
    await followUser({
      accountId,
      locale,
      guest: !isLoggedIn,
    });
    dispatch(followedUser(params));
    emitFollow(
      me,
      accountId,
      resultSource,
      selectedTcnnMessage.action === TCNNAction.FOLLOW
        ? selectedTcnnMessage.tracking_id
        : undefined
    );
    dispatch(removeSelectedTcnnMessage());
  } catch (error) {
    if (!isLoggedIn && error.status === 412) {
      dispatch(
        openLoginView({
          promotionType: LoginPromotionType.FOLLOW,
          accountId,
        })
      );
    }

    dispatch(genericNetworkError(error));
    retryAfterCaptcha(error, follow(accountId, source));

    return false;
  }

  return true;
};

/**
 * @param {number} source
 */
export const followCurrentBroadcaster = (source) => (dispatch, getState) => {
  const broadcasterId = getBroadcasterId(getState());
  if (broadcasterId) {
    return dispatch(follow(broadcasterId, source));
  }

  return Promise.resolve();
};

/**
 * @param {string} accountId
 * @param {number} source
 */
export const unfollow = (accountId, source) => async (dispatch, getState) => {
  if (!accountId) {
    // eslint-disable-next-line no-throw-literal
    throw "AccountId should be specified!";
  }
  const state = getState();
  const me = userSelectors.getMyAccountId(state);
  if (!followingListSelectors.getIsFollowingByAccountId(state, accountId)) {
    return true;
  }
  AnalyticsUtils.updateInteractionId();
  const params = { accountId, me };
  try {
    await unfollowUser(accountId);
    dispatch(unfollowedUser(params));
    dispatch(unfollowRealtimeRecommendation({ accountId, source }));
    emitUnfollow(me, accountId, source);
  } catch (error) {
    dispatch(genericNetworkError(error));
    retryAfterCaptcha(error, unfollow(accountId, source));

    return false;
  }

  return true;
};
