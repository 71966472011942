import { batch } from "react-redux";
import { initExternalBroadcast } from "src/features/broadcastExternal/api/broadcastExternal";
import {
  BroadcastExternalStatus,
  InitStreamResponseCode,
} from "src/features/broadcastExternal/common/enums";
import {
  checkImage,
  terminateStream,
} from "src/features/broadcastExternal/imports/api";
import {
  BroadcastSource,
  StreamKind,
  StreamSessionInitializationResult,
} from "src/features/broadcastExternal/imports/enums";
import {
  broadcastActionCreators,
  broadcastSelectors,
  endSessionInitialization,
  leftViewerSession,
  resetViewerSession,
} from "src/features/broadcastExternal/imports/state";
import {
  AppDispatch,
  RootState,
} from "src/features/broadcastExternal/imports/types";
import { setBroadcastExternalStatus } from "src/features/broadcastExternal/state/actionCreators";

interface BroadcastExternalInitProps {
  broadcastType: StreamKind;
}

export const initBroadcastExternal =
  (props: BroadcastExternalInitProps) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<boolean> => {
    const { broadcastType } = props;
    const state = getState();
    const broadcastPictureUrl = broadcastSelectors.broadcastPictureUrl(state);
    const isBroadcastPictureValid = await checkImage(broadcastPictureUrl ?? "");

    if (!isBroadcastPictureValid) {
      dispatch(broadcastActionCreators.broadcastBadPicture());

      return false;
    }

    dispatch(
      broadcastActionCreators.broadcastInitStarted({
        broadcastKind: broadcastType,
        broadcastSource: "",
      })
    );

    const broadcastTitle = broadcastSelectors.broadcastTitle(state);

    try {
      const {
        code,
        key: broadcastKey,
        encryptedStreamId: broadcastId,
        userBanDuration,
      } = await initExternalBroadcast({
        title: broadcastTitle,
        type: broadcastType,
        transcodingRequested: true,
      });

      if (code === InitStreamResponseCode.SUCCESS) {
        batch(() => {
          dispatch(resetViewerSession(broadcastId));
          dispatch(
            endSessionInitialization(
              broadcastId,
              StreamSessionInitializationResult.SUCCESS,
              BroadcastSource.BROADCASTING
            )
          );
          dispatch(
            broadcastActionCreators.broadcastInitSucceeded({
              broadcastId,
              broadcastKey,
            })
          );
          dispatch(
            setBroadcastExternalStatus(BroadcastExternalStatus.INITIALIZED)
          );
        });

        return true;
      }

      if (code === InitStreamResponseCode.EXISTING_LIVE_STREAM) {
        await terminateStream({ streamId: broadcastId });
        await dispatch(initBroadcastExternal(props));

        return false;
      }

      if (userBanDuration) {
        dispatch(broadcastActionCreators.broadcastInitUserBan(userBanDuration));

        return false;
      }

      dispatch(
        broadcastActionCreators.broadcastInitFailed(
          new Error(`Unknown result code: ${code}`)
        )
      );
    } catch (error) {
      dispatch(broadcastActionCreators.broadcastInitFailed(error as Error));

      return false;
    }

    return false;
  };

export const terminateBroadcastExternal =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const broadcastId = broadcastSelectors.broadcastId(getState());

    if (!broadcastId) {
      // No broadcast to terminate, act like terminate succeeded for now
      dispatch(broadcastActionCreators.broadcastTerminateSucceeded());

      return;
    }

    dispatch(broadcastActionCreators.broadcastTerminateStarted());

    try {
      await terminateStream({ streamId: broadcastId });
      batch(() => {
        dispatch(leftViewerSession(broadcastId));
        dispatch(broadcastActionCreators.broadcastTerminateSucceeded());
        dispatch(
          setBroadcastExternalStatus(BroadcastExternalStatus.TERMINATED)
        );
      });
    } catch (error) {
      dispatch(
        broadcastActionCreators.broadcastTerminateFailed(error as Error)
      );
    }
  };
