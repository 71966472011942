import { batch } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import qs from "query-string";
import { fetchServerOwnedConfig } from "api/configurator";
import { DataDogErrorMessage } from "src/enums";
import {
  loadedServerOwnedConfig,
  loadingServerOwnedConfig,
  overrideServerOwnedConfig,
  serverOwnedConfigFailed,
} from "state/actionCreators/configuration";

export const loadServerOwnedConfig = (locale) => async (dispatch) => {
  try {
    dispatch(loadingServerOwnedConfig());
    const config = await fetchServerOwnedConfig(locale);
    dispatch(loadedServerOwnedConfig(config));
  } catch (error) {
    datadogLogs.logger.error(
      DataDogErrorMessage.SERVER_OWNED_CONFIG_FETCH_FAILED,
      {},
      error
    );
    dispatch(serverOwnedConfigFailed(error));
  }
};

export const applyConfigOverridesFromSearchString =
  (searchString = window.location.search) =>
  (dispatch) => {
    if (searchString) {
      const parsed = qs.parse(searchString);
      batch(() => {
        Object.keys(parsed).forEach(
          (key) =>
            parsed[key] && dispatch(overrideServerOwnedConfig(key, parsed[key]))
        );
      });
    }
  };
