import React, { ElementType, FC, memo, useCallback, useMemo } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Link } from "react-router-dom";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "legal/enums";
import { MinusIcon, PlusIcon } from "legal/imports/assets";
import { Typography } from "legal/imports/components";
import { TYPOGRAPHY_TYPE } from "legal/imports/constants";
import { useBreakpointPrecise } from "legal/imports/hooks";
import styles from "./LegalAccordionItem.scss";

type AccordionItemProps = {
  active?: boolean;
  dataTestId?: string;
  expanded?: boolean;
  message: MessageDescriptor;
  onClick?: (id: string) => void;
  to: string;
};

const LegalAccordionItem: FC<AccordionItemProps> = ({
  to,
  message,
  onClick = emptyFunction,
  expanded,
  active = false,
  dataTestId,
}) => {
  const breakpoint = useBreakpointPrecise();
  const as: ElementType = active ? "li" : Link;

  const typographyType = useMemo(() => {
    if (breakpoint === Breakpoints.TABLET) {
      return active ? TYPOGRAPHY_TYPE.HEADLINE4 : TYPOGRAPHY_TYPE.PARAGRAPH2;
    }

    return active ? TYPOGRAPHY_TYPE.HEADLINE5 : TYPOGRAPHY_TYPE.PARAGRAPH3;
  }, [active, breakpoint]);

  const handleClick = useCallback(() => {
    onClick(to);
  }, [to, onClick]);

  return (
    <Typography
      type={typographyType}
      className={classnames(
        styles.accordionItem,
        styles[breakpoint],
        active && styles.active
      )}
      as={as}
      data-testid={dataTestId}
      onClick={handleClick}
      to={!active ? to : null}
    >
      <FormattedMessage {...message} />
      {active && (expanded ? <MinusIcon /> : <PlusIcon />)}
    </Typography>
  );
};

export default memo(LegalAccordionItem);
