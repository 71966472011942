import { CodeResponse } from "@react-oauth/google";
import { Dispatch } from "redux";
import { LoginProvider } from "src/features/signin/imports/enums";
import {
  RootState,
  loadMyProfile,
  loginSelectors,
} from "src/features/signin/imports/state";
import { VoidCallback } from "src/features/signin/imports/types";
import handleLoginResult from "src/features/signin/state/flows/utils/handleLoginResult";
import handleUpgradeGuestError from "src/features/signin/state/flows/utils/handleUpgradeGuestError";
import {
  dismissLoginModal,
  handlePreconditionErrorAndDismissModal,
} from "src/features/signin/state/flows/utils/loginHelpers";
import { upgradeGuestWithRetry } from "src/features/signin/state/flows/utils/upgradeGuestWithRetry";

export type GoogleCredentials = {
  redirectUri: string;
  token: string;
  type: LoginProvider;
};

interface GoogleLoginParams {
  codeResponse: CodeResponse;
  onLoginSuccess?: VoidCallback;
}

export const loginWithGoogleProvider =
  ({ codeResponse, onLoginSuccess }: GoogleLoginParams) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { code } = codeResponse;

    const state: RootState = getState();
    const isLoggedIn = loginSelectors.isLoggedIn(state);

    if (isLoggedIn) {
      return Promise.resolve();
    }

    const prepareGoogleCredentials = () =>
      new Promise<GoogleCredentials>((resolve, reject) => {
        if (!code) {
          reject(new Error("Code(token) should be available"));
        }

        resolve({
          type: LoginProvider.GOOGLE,
          token: code,
          redirectUri: window.location.origin,
        });
      });

    return (
      prepareGoogleCredentials()
        .then(upgradeGuestWithRetry(state, dispatch))
        .catch(
          handleUpgradeGuestError(state, dispatch, {
            code,
            callback: loginWithGoogleProvider,
          })
        )
        .then(
          handleLoginResult(
            getState,
            dispatch,
            LoginProvider.GOOGLE,
            onLoginSuccess
          )
        )
        // @ts-ignore
        .then(() => dispatch(loadMyProfile()))
        .then(dismissLoginModal(dispatch))
        .catch(handlePreconditionErrorAndDismissModal(dispatch))
    );
  };
