import React, { Suspense, forwardRef } from "react";
import lazy from "src/utils/lazyWithPageReload";
import HLSPlayer from "./HLSPlayer";

const Player = lazy(
  () =>
    import(
      /* webpackChunkName: "player" */ /* webpackPrefetch: true */ "./Player"
    )
);

const LazyPlayer = forwardRef((props, ref) => (
  <Suspense fallback={null}>
    <Player {...props} forwardedRef={ref} />
  </Suspense>
));

LazyPlayer.displayName = "LazyPlayer";

export const HLSPlayerWithRef = forwardRef((props, ref) => (
  <HLSPlayer {...props} forwardedRef={ref} />
));

HLSPlayerWithRef.displayName = "HLSPlayerWithRef";

export default LazyPlayer;
