import React, { FC, useMemo } from "react";
import { AnalyticsScreenDataContextProvider } from "chat/components/AnalyticsScreenDataContext";
import { EventFields, SCREEN_NAME } from "chat/imports/analytics";
import {
  ElementViewReporter,
  ScreenViewReporter,
} from "chat/imports/components";
import { StoredConversation } from "chat/state/reducer";
import { ConversationScreenState, ConversationState } from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";

interface ConversationTangoScreenViewAnalyticsProps {
  conversation: Pick<StoredConversation, "conversation_id" | "state">;
  isDesktop: boolean;
}

const tangoScreenViewConfig = {
  activeGroup: {
    name: SCREEN_NAME.CHAT_GROUP,
    additionalParams: {
      [EventFields.SCREEN_STATE]: "",
    },
  },
  activeSingle: {
    name: SCREEN_NAME.CHAT_SINGLE,
    additionalParams: {
      [EventFields.SCREEN_STATE]: ConversationScreenState.ONE_ON_ONE_CHAT,
    },
  },
  chatRequestSingle: {
    name: SCREEN_NAME.CHAT_SINGLE,
    additionalParams: {
      [EventFields.SCREEN_STATE]: ConversationScreenState.CHAT_REQUEST,
    },
  },
};

export const ConversationTangoScreenViewAnalytics: FC<
  ConversationTangoScreenViewAnalyticsProps
> = ({
  children,
  conversation: { conversation_id: conversationId, state },
  isDesktop,
}) => {
  const config = useMemo(() => {
    const isGroupConversation = isGroupChatId(conversationId);

    if (state === ConversationState.ACTIVE) {
      return isGroupConversation
        ? tangoScreenViewConfig.activeGroup
        : tangoScreenViewConfig.activeSingle;
    }

    if (state === ConversationState.CHAT_REQUEST) {
      return isGroupConversation
        ? null
        : tangoScreenViewConfig.chatRequestSingle;
    }

    return tangoScreenViewConfig.chatRequestSingle;
  }, [conversationId, state]);

  const screenData = useMemo(
    () =>
      config && {
        [EventFields.SCREEN_STATE]:
          config.additionalParams[EventFields.SCREEN_STATE],
        [EventFields.TANGO_SCREEN]: config.name,
      },
    [config]
  );

  const ViewReporter = useMemo(
    () => (isDesktop ? ElementViewReporter : ScreenViewReporter),
    [isDesktop]
  );

  return (
    <>
      {config && <ViewReporter key={conversationId} {...config} />}
      <AnalyticsScreenDataContextProvider value={screenData}>
        {children}
      </AnalyticsScreenDataContextProvider>
    </>
  );
};
