import { Nullable } from "src/types/common";
import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";
import { CountriesState } from "state/tree/countries";

const { data, meta } = generateAsyncSelectors<CountriesState>();

export default {
  data,
  meta,
  getFilteredCountries: (state: CountriesState, filterValue: string) =>
    data(state).filter(
      (country) =>
        country.countryName.toLowerCase().includes(filterValue.toLowerCase()) ||
        country.countryCode.startsWith(filterValue) ||
        `+${country.countryCode}`.startsWith(filterValue)
    ),
  getCountryByIsoCode: (state: CountriesState, isoCode: Nullable<string>) =>
    data(state).find(
      (country) => country.isoCountryCode === isoCode?.toLowerCase()
    ),
  getCountryById: (state: CountriesState, countryId: string) =>
    data(state).find((country) => country.countryId === countryId),
};
