import { Dispatch } from "react";
import { createAction } from "@reduxjs/toolkit";
import { addBottomSheet } from "src/features/promotions/promoBottomSheet/state/actionCreators";
import { RootState } from "src/state/delegate";
import { landingPagePhoenixSelectors } from "src/state/selectors";
import { TCNN } from "ui/tcnn/types";

export const addTcnnMessageToQueue = createAction<TCNN>("lwc/tcnn/received");

export const clearTcnnMessage = createAction<TCNN["tracking_id"] | undefined>(
  "lwc/tcnn/clear"
);

export const selectTcnnMessage = createAction<TCNN>("lwc/tcnn/select");

export const removeSelectedTcnnMessage = createAction(
  "lwc/tcnn/removeSelected"
);

export const addTcnnMessageToQueueThunk =
  (message: TCNN, isBottomSheetsEnabled: boolean) =>
  (
    dispatch: Dispatch<{ payload: TCNN; type: string }>,
    getState: () => RootState
  ) => {
    dispatch(addTcnnMessageToQueue(message));

    const state = getState();
    const landingPagePhoenixState =
      landingPagePhoenixSelectors.landingPagePhoenixState(state);

    const isLandingPageNotActive =
      landingPagePhoenixState.selectedCampaignId === null;

    if (
      isBottomSheetsEnabled &&
      message.supported &&
      message.displayAreas &&
      (message.bottom_sheet === "1" || message.bottom_sheet === "true") &&
      isLandingPageNotActive
    ) {
      dispatch(addBottomSheet(message));
    }
  };
