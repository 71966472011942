import React, { ForwardedRef, forwardRef } from "react";
import classnames from "classnames";
import { RawGift } from "src/features/gifts/imports";
import { FormattedNumber } from "src/ui/common/Formatted";
import LazyImage from "src/ui/common/lazyImage/LazyImage";
import { ReactComponent as CoinIcon } from "img/ic_coin.svg";
import styles from "./Gift.scss";

type GiftProps = {
  className?: string;
  gift: RawGift;
  isSelected?: boolean;
  onClick?: () => void;
};

const Gift = (
  { gift, isSelected = false, onClick, className }: GiftProps,
  ref: ForwardedRef<HTMLDivElement>
) => (
  <div
    ref={ref}
    data-testid={gift.id}
    onClick={() => onClick?.()}
    className={classnames(
      { [styles.root]: true, [styles.selected]: !!isSelected },
      className
    )}
  >
    <LazyImage src={gift.icon} alt={gift.name} className={styles.image} />
    <div className={styles.priceContainer}>
      <CoinIcon className={styles.coinIcon} />
      <span>
        <FormattedNumber value={gift.priceInCredit} />
      </span>
    </div>
  </div>
);

export default forwardRef(Gift);
