import { AnyAction, Middleware } from "@reduxjs/toolkit";
import emptyFunction from "fbjs/lib/emptyFunction";
import { isAction } from "src/utils/isAction";
import { isFunction } from "src/utils/isFunction";
import { isPromise } from "src/utils/isPromise";
import { AppDispatch } from "state/types";

export enum TaskState {
  IDLE = "idle",
  PENDING = "pending",
  RESOLVED = "resolved",
}

export interface Task {
  done?: ((action: AnyAction) => boolean) | AnyAction;
  executor: () => ReturnType<AppDispatch>;
  removeListener: VoidFunction;
  state: TaskState;
}

interface TaskOptions {
  done?: Task["done"];
}

export class AsyncActionQueue {
  private listeners: {
    done: NonNullable<Task["done"]>;
    effect: VoidFunction;
  }[] = [];

  private tasks: Task[] = [];

  listenerMiddleware: Middleware = () => (next) => (action) => {
    const returnValue = next(action);

    this.listeners.some(({ done, effect }) => {
      if (isAction(done) && done.type === action.type) {
        effect();
        return true;
      }

      if (isFunction(done) && done(action) === true) {
        effect();
        return true;
      }

      return false;
    });

    return returnValue;
  };

  private addListener(listener: {
    done: NonNullable<Task["done"]>;
    effect: VoidFunction;
  }) {
    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((x) => x !== listener);
    };
  }

  private run() {
    const task = this.tasks[0];

    if (!task) {
      return;
    }

    task.state = TaskState.PENDING;

    if (task.done) {
      task.removeListener = this.addListener({
        done: task.done,
        effect: () => {
          task.removeListener();

          if (task.state === TaskState.PENDING) {
            this.remove(task);
            this.run();
          }
        },
      });

      task.executor();
    } else {
      const result = task.executor();

      if (isPromise(result)) {
        result.then(() => {
          if (task.state === TaskState.PENDING) {
            this.remove(task);
            this.run();
          }
        });
      } else {
        this.remove(task);

        throw new Error(
          "The task executor must return a promise unless set to done"
        );
      }
    }
  }

  clear() {
    this.tasks = [];
    this.listeners = [];
  }

  createWithPresetOptions(options: TaskOptions) {
    return (executor: Task["executor"]) => {
      this.push(executor, options);
    };
  }

  push(executor: Task["executor"], options: TaskOptions = {}) {
    const { done } = options;
    const task = {
      state: TaskState.IDLE,
      executor,
      done,
      removeListener: emptyFunction,
    };

    this.tasks.push(task);

    if (this.length === 1) {
      this.run();
    }

    return task;
  }

  remove(task: Task) {
    this.tasks = this.tasks.filter((x) => x !== task);
  }

  resolveCurrentTask() {
    const task = this.tasks[0];

    if (task) {
      task.removeListener();
      task.state = TaskState.RESOLVED;
      this.remove(task);
      this.run();
    }
  }

  get isEmpty() {
    return this.tasks.length === 0;
  }

  get length() {
    return this.tasks.length;
  }
}
