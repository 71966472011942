import {
  legalMessages,
  linkToPrivacyPolicy,
  linkToTermsOfUse,
} from "src/features/ofac/imports/constants";

export const legalNavigationTermsOfUseConfig = {
  message: legalMessages.termsOfUse,
  to: linkToTermsOfUse,
  dataTestId: "link-to-terms-of-use",
};

export const legalNavigationPrivacyPolicyConfig = {
  message: legalMessages.privacyPolicy,
  to: linkToPrivacyPolicy,
  dataTestId: "link-to-privacy-policy",
};
