import { MutableRefObject, useCallback, useEffect } from "react";
import { Nullable } from "src/types/common";

/**
 * @see https://usehooks.com/useOnClickOutside/
 */
const useClickOutside = (
  ref: MutableRefObject<Nullable<HTMLElement>>,
  handler: (event: MouseEvent) => void,
  enabled = true
) => {
  const listener = useCallback(
    (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    },
    [ref, handler]
  );
  useEffect(() => {
    if (!enabled) return;
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [listener, enabled]);
};

export default useClickOutside;
