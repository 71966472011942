import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";
import { CountryInfo, NearbyCountriesState } from "state/types";

const { meta } = generateAsyncSelectors<NearbyCountriesState>();
export const comparator = (l: CountryInfo, r: CountryInfo) => {
  if (l.liveStreams === r.liveStreams) {
    return l.countryName.localeCompare(r.countryName);
  }
  return r.liveStreams - l.liveStreams;
};

const all = (state: NearbyCountriesState) =>
  Object.values(state.data).sort(comparator);

const active = (state: NearbyCountriesState) =>
  Object.values(state.data)
    .filter((x) => x.active)
    .sort(comparator);

const inactive = (state: NearbyCountriesState) =>
  Object.values(state.data)
    .filter((x) => !x.active)
    .sort(comparator);

const codes = (state: NearbyCountriesState) =>
  all(state).map((country) => country.isoCode);
const activeCodes = (state: NearbyCountriesState) =>
  active(state).map((country) => country.isoCode);

export default {
  all,
  active,
  inactive,
  codes,
  activeCodes,
  meta,
};
