import {
  AxonEventFields,
  AxonEventNames,
  AxonMethods,
} from "src/core/analytics/enumsAxon";

export const emitBILoginSuccess = (accountId: string) => {
  window.axon?.(AxonMethods.TRACK, AxonEventNames.COMPLETED_LOGIN, {
    [AxonEventFields.USER_ID]: accountId,
  });
};
