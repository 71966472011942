import React, { createContext, useMemo } from "react";
import emptyFunction from "fbjs/lib/emptyFunction";
import { DeviceType } from "src/enums";
import { useSelector } from "react-redux";
import { deviceInfoSelectors } from "state/selectors";
import usePersistentState from "ui/hooks/usePersistentState";
import { useEffectOnce } from "src/utils/miniReactUse";

const MuteContext = createContext({
  muted: false,
  setMuted: emptyFunction as (value: boolean) => void,
});

export default MuteContext;

const MUTED_STORAGE_KEY = "muted";

export const MuteContextProvider: React.FC = ({ children }) => {
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const mutedInitially =
    deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD;

  const [muted, setMuted] = usePersistentState({
    key: MUTED_STORAGE_KEY,
    session: true,
    listenForUpdates: true,
    restoreOldValue: true,
    initialValue: mutedInitially,
  });

  useEffectOnce(() => {
    if (!mutedInitially) return;
    const cb = () => {
      setMuted(document.visibilityState === "visible");
    };
    document.addEventListener("visibilitychange", cb);
    return () => document.removeEventListener("visibilitychange", cb);
  });

  const value = useMemo(() => ({ muted, setMuted }), [muted, setMuted]);
  return <MuteContext.Provider value={value}>{children}</MuteContext.Provider>;
};
