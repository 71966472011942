export enum MicrosoftAdsMethods {
  EVENT = "event",
  SET = "set",
}

export enum MicrosoftAdsEventNames {
  PURCHASE = "purchase",
  REGISTRATION = "registration",
}

export enum MicrosoftAdsEventFields {
  CURRENCY = "currency",
  EMAIL = "em",
  PHONE_NUMBER = "ph",
  REVENUE_VALUE = "revenue_value",
}
