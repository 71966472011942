import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { unlockTopModal } from "src/features/signin/imports/state";
import { VoidCallback } from "src/features/signin/imports/types";
import { loginWithGoogleProvider } from "src/features/signin/state/flows/loginWithGoogleProvider";
import {
  loginFailed,
  loginHideInProgressFlag,
} from "src/features/signin/state/login/actionCreators";

interface GoogleLoginParams {
  onLoginSuccess?: VoidCallback;
  shouldSkipErrorHandling?: boolean;
}

export default (params: GoogleLoginParams = {}) => {
  const { shouldSkipErrorHandling = false, onLoginSuccess } = params;

  const dispatch = useDispatch();

  return useGoogleLogin({
    flow: "auth-code",
    onSuccess: (codeResponse) => {
      dispatch(loginWithGoogleProvider({ codeResponse, onLoginSuccess }));
    },
    onError: (error) => {
      dispatch(unlockTopModal());
      shouldSkipErrorHandling
        ? dispatch(loginHideInProgressFlag())
        : dispatch(loginFailed(error.error));
    },
    onNonOAuthError: (error) => {
      dispatch(unlockTopModal());
      shouldSkipErrorHandling
        ? dispatch(loginHideInProgressFlag())
        : dispatch(loginFailed(error.type));
    },
  });
};
