import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import sortBy from "lodash.sortby";
import { emitEvent } from "@analytics/emit";
import { emitPersonalOffersEvent } from "@analytics/emitPersonalOffersEvent";
import {
  BI_LANDING_FIELD,
  EventNames,
  PERSONAL_OFFERS_SOURCE,
} from "@analytics/enums";
import { fetchPersonalOffers } from "api/personalOffers";
import { isApiError } from "api/utils/enhancedFetch";
import { PersonalOfferType } from "src/enums";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { personalOffersSelectors } from "state/selectors";
import { PersonalOffersState } from "state/tree/personalOffers";

const SORT_OFFERS_BY = "priority";
const OFFERS_NOT_FOUND_ERROR = "NOT FOUND";

interface GetRemoteConfigArgs {
  type: PersonalOfferType;
}

export default {
  fetchPersonalOffersConfig: createAsyncThunk<
    Data<PersonalOffersState>,
    FetcherMetaV2 & GetRemoteConfigArgs,
    { rejectValue: string; state: RootState }
  >(
    "lwc/personalOffersConfig/fetch",
    async ({ type }, api) => {
      try {
        const personalOfferSource = PERSONAL_OFFERS_SOURCE[type];

        const response = await fetchPersonalOffers(type);
        if (response) {
          const startTimestamp = Date.now();
          const state = api.getState();
          const parseNewConfig = {
            ...response,
            pricePoints: sortBy(response.pricePoints, SORT_OFFERS_BY),
          };
          const parseOldConfig =
            personalOffersSelectors.personalOffersState(state)?.[type];

          emitPersonalOffersEvent({
            parseOldConfig,
            parseNewConfig,
            startTimestamp,
            source: personalOfferSource,
          });

          return {
            [type]: parseNewConfig,
          };
        }
        const errorEventParams = {
          [BI_LANDING_FIELD.COMMENT]: personalOfferSource,
          [BI_LANDING_FIELD.REASON]: OFFERS_NOT_FOUND_ERROR,
        };

        emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);

        return api.rejectWithValue(OFFERS_NOT_FOUND_ERROR);
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        const personalOfferSource = PERSONAL_OFFERS_SOURCE[type];

        const errorEventParams = {
          [BI_LANDING_FIELD.COMMENT]: personalOfferSource,
          [BI_LANDING_FIELD.REASON]: error,
        };

        emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();
        const meta = personalOffersSelectors.personalOffersStateMeta(state);

        return !meta.loading;
      },
    }
  ),
  clearPersonalOffersConfig: createAction("lwc/personalOffersConfig/clear"),
};
