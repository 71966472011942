export type RequestHistoryItem = {
  status: number;
  url: string;
};

const historyLimit = 20;
let requestHistory: RequestHistoryItem[] = [];

export const getRequestHistory = () => requestHistory;

export const addRequestToHistory = (url: string, status: number) => {
  if (requestHistory.length >= historyLimit) {
    requestHistory.shift();
  }

  requestHistory.push({ url, status });
};

export const clearRequestHistory = () => {
  requestHistory = [];
};
