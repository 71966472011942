import React, { FC, memo, useCallback } from "react";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { useLightbox } from "chat/imports/context";
import { PremiumMessageDetails } from "chat/premiumMessage/types";
import { SenderMessageOverlay } from "../overlays/SenderMessageOverlay";
import PremiumImage from "./PremiumImage";
import styles from "./ViewerUnlockedMessage.scss";

interface SenderImageMessageProps {
  giftId: string;
  mediaInfo: PremiumMessageDetails["mediaInfo"];
  messageConfig: MessageConfig;
  status: PremiumMessageDetails["status"];
}

const SenderImageMessage: FC<SenderImageMessageProps> = ({
  giftId,
  mediaInfo,
  status,
  messageConfig,
}) => {
  const { contentUrl, width, height, duration } = mediaInfo || {};

  const openLightbox = useLightbox();

  const handleImageClick = useCallback(() => {
    // @ts-ignore TODO: WEB-3243 rewrite to ts
    contentUrl && openLightbox({ photos: [contentUrl] });
  }, [contentUrl, openLightbox]);

  return (
    <div
      className={styles.root}
      data-testid="premium-message-sender"
      onClick={handleImageClick}
    >
      <SenderMessageOverlay
        duration={duration}
        giftId={giftId}
        status={status}
        messageConfig={messageConfig}
      />

      {contentUrl && (
        <PremiumImage
          url={contentUrl}
          width={width}
          height={height}
          messageConfig={messageConfig}
        />
      )}
    </div>
  );
};

export default memo(SenderImageMessage);
