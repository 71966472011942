import { Layer } from "src/types/personalOffer";
const zipFormat = "zip";
const jsonFormat = "json";

export const generateJSONUrl = (url: string) => {
  const matchValue = url.split(".");
  if (matchValue[matchValue.length - 1] !== zipFormat) {
    return "";
  }
  matchValue[matchValue.length - 1] = jsonFormat;
  return matchValue.join(".");
};

export const generateGradientValue = (
  colors: Layer["colors"],
  gradientAngle: Layer["gradientAngle"] = 0
) =>
  colors && colors.length > 1
    ? `${colors?.reduce((acc, color, index) => {
        const colorPortion = Math.ceil((index / (colors.length - 1)) * 100);
        return `${acc}, ${color} ${colorPortion}%`;
      }, `linear-gradient(${gradientAngle}deg`)})`
    : "";

export const fromCent = (price: number) => price / 100;

export const compareArray = (a: string[], b: string[]) =>
  a.length === b.length && a.every((item) => b.includes(item));
