import { createReducer } from "@reduxjs/toolkit";
import { TCNNAction } from "src/enums";
import {
  addTcnnMessageToQueue,
  clearTcnnMessage,
  removeSelectedTcnnMessage,
  selectTcnnMessage,
} from "state/actionCreators/tcnn";
import {
  VIEWER_SESSION_LEFT,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET,
} from "state/actionTypes";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";
import { TCNN, TransportType } from "ui/tcnn/types";

type PullEventsFragmentType = {
  error: boolean;
  meta: {
    currentUserId: string;
    streamId: string;
  };
  payload: {
    entities: {
      events: {
        [key: string]: {
          accountId: string;
          data: {
            content: string;
          };
          details: {
            localizeTextKey: string;
            popUp: {
              defaultTitle: string;
              titleLocalizeKey: string;
              type: number;
            };
          };
          id: string;
        };
      };
    };
    eventIds: Array<string>;
  };
};

const initialState = {
  queue: [] as TCNN[],
  selectedTCNNMessage: {} as TCNN,
};

export type TCNNState = {
  queue: TCNN[];
  selectedTCNNMessage: TCNN;
};

export default createReducer<TCNNState>(initialState, (builder) => {
  addUserSessionScopeReducer(
    builder
      .addCase(addTcnnMessageToQueue, (state, action) => {
        if (action.payload.supported) {
          state.queue.push(action.payload);
        }
      })
      .addCase(clearTcnnMessage, (state, action) => {
        if (action.payload) {
          state.queue = state.queue.filter(
            (x) => x.tracking_id !== action.payload
          );
        } else {
          state.queue = [];
        }
      })
      .addCase(selectTcnnMessage, (state, action) => {
        if (action.payload) {
          state.selectedTCNNMessage = action.payload;
        }
      })
      .addCase(removeSelectedTcnnMessage, (state) => {
        state.selectedTCNNMessage = {} as TCNN;
      })
      .addMatcher(
        (action) =>
          action.type === VIEWER_SESSION_RESET ||
          action.type === VIEWER_SESSION_LEFT,
        () => initialState
      )
      .addMatcher(
        (action) => action.type === VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
        (state, action: PullEventsFragmentType) => {
          if (action.error) {
            return state;
          }

          const {
            payload: { entities: { events } = {}, eventIds },
            meta: { currentUserId },
          } = action;

          if (!events || !eventIds) {
            return state;
          }

          const foundEvent = Object.values(events).find((event) => {
            const { details: { popUp } = {}, accountId } = event;

            return popUp && accountId === currentUserId;
          });

          if (foundEvent) {
            const {
              id,
              data: { content },
              details: {
                localizeTextKey,
                popUp: { defaultTitle, titleLocalizeKey, type },
              },
            } = foundEvent;
            const [imageType] = localizeTextKey.split("_");
            const mappedTcnn = {
              trigger_id: "31",
              serverOnly: "{}",
              icon: `https://storage.googleapis.com/resources.tango.me/tango-officer-resources/v2/L/${imageType}.png`,
              message_id: "31",
              message_uuid: id, // uses event id instead tcnn id
              type,
              title: defaultTitle,
              message: content,
              transport_type: TransportType.PUSH,
              action: TCNNAction.MESSAGE,
              force: "true",
              soundFile: "",
              category: "TCNN",
              tracking_id: id,
              action_cta_name: "Got it",
              supported: true,
              localized: {
                titleLocalizeKey,
                localizeTextKey,
                buttonKey: "broadcast-permissions.got-it",
              },
            };

            state.queue.push(mappedTcnn);
          }
        }
      ),
    () => initialState
  );
});
