import {
  AUTHORIZATION_END,
  LOGIN_END,
  LOGOUT_END,
} from "src/features/signin/exports/state/actionTypes";
import { userSelectors } from "state/selectors";

const expectedActions = [
  "persist/REHYDRATE",
  LOGOUT_END,
  AUTHORIZATION_END,
  LOGIN_END,
];

export default (store) => (next) => (action) => {
  /* eslint-disable no-console */
  const accountId = userSelectors.getMyAccountId(store.getState());
  const ret = next(action);
  const newAccountId = userSelectors.getMyAccountId(store.getState());

  if (accountId !== newAccountId) {
    if (expectedActions.includes(action.type)) {
      console.log(
        `Expected account id change: ${accountId || "none"}->${
          newAccountId || "none"
        } by action ${action.type}`
      );
    } else {
      const errorMessage = `id change: ${action.type}: ${
        accountId || "none"
      }->${newAccountId || "none"}`;
      console.error(`Unexpected ${errorMessage}`);
    }
  }

  return ret;
};
