import { CurrenciesState } from "state/tree/currencies";
import { uniq } from "src/utils/miniLodash";
import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";
import { CurrencyList } from "ui/common/currencyPicker/types";
import { Currency } from "src/enums";

const fallbackOptions = [Currency.USD];

const allCurrenciesSelector = (state: CurrenciesState) => {
  const { options, recommended, override, whiteList } = state.data;
  if (override?.length) {
    return override;
  }
  if (!options.length) {
    return fallbackOptions;
  }

  const uniqCurrencies = uniq([Currency.USD, recommended, ...options]).filter(
    (cur) => {
      if (!cur) {
        return false;
      }

      return !whiteList?.length || whiteList.includes(cur);
    }
  ) as CurrencyList;

  return uniqCurrencies || fallbackOptions;
};

const currentCurrencySelector = (state: CurrenciesState) => {
  const options = allCurrenciesSelector(state);
  const { recommended, selected } = state.data;
  return [selected, recommended, options[0]].find(
    (cur) => cur && options.includes(cur)
  ) as Currency;
};

const { meta } = generateAsyncSelectors<CurrenciesState>();

export default {
  getCurrentCurrency: currentCurrencySelector,
  getAllCurrencies: allCurrenciesSelector,
  meta,
};
