import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchStreamKey } from "src/features/broadcastExternal/api/broadcastExternal";
import {
  BroadcastExternalError,
  BroadcastExternalStatus,
} from "src/features/broadcastExternal/common/enums";
import { StreamKey } from "src/features/broadcastExternal/common/types";
import { SECOND } from "src/features/broadcastExternal/imports/constants";
import { userSelectors } from "src/features/broadcastExternal/imports/state";
import {
  Nullable,
  RootState,
} from "src/features/broadcastExternal/imports/types";
import { broadcastExternalSelectors } from "src/features/broadcastExternal/state/selectors";

interface RejectDataError {
  message: BroadcastExternalError;
}

interface RejectData {
  rejectValue: RejectDataError;
}

const validateStreamKey = (
  streamKeyData: StreamKey,
  accountId: Nullable<string>
) =>
  streamKeyData.duration > new Date().getTime() &&
  streamKeyData.accountId === accountId;

const formatStreamKeyDuration = (ttlSec: number) =>
  ttlSec * SECOND + new Date().getTime();

export const getValidStreamKey = createAsyncThunk<StreamKey, void, RejectData>(
  "lwc/broadcastExternal/getValidStreamKey",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const streamKey = broadcastExternalSelectors.getStreamKey(state);
    const accountId = userSelectors.getMyAccountId(state);
    const streamKeyData = streamKey.data;

    if (validateStreamKey(streamKeyData, accountId)) {
      return streamKeyData;
    }

    try {
      const { token, ttlSec } = await fetchStreamKey();
      const duration = formatStreamKeyDuration(ttlSec);

      return {
        accountId,
        token,
        duration,
      };
    } catch (error) {
      return rejectWithValue({
        message: BroadcastExternalError.STREAM_KEY_ERROR_UNKNOWN,
      });
    }
  }
);

export const setBroadcastExternalStatus = createAction<BroadcastExternalStatus>(
  "lwc/broadcastExternal/setStatus"
);

export const resetBroadcastExternalStatus = createAction(
  "lwc/broadcastExternal/resetStatus"
);

export const startMultiBroadcastExternal = createAction(
  "lwc/broadcastExternal/multiBroadcast/start"
);

export const stopMultiBroadcastExternal = createAction(
  "lwc/broadcastExternal/multiBroadcast/stop"
);

export const setOBSInstructionVisibility = createAction<boolean>(
  "lwc/broadcastExternal/setOBSInstructionVisibility"
);
