/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.NewDeviceLoginMessage || ($protobuf.roots.NewDeviceLoginMessage = {});

export const NewDeviceLoginMessage = $root.NewDeviceLoginMessage = (() => {

    function NewDeviceLoginMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    NewDeviceLoginMessage.prototype.accountId = "";
    NewDeviceLoginMessage.prototype.username = "";
    NewDeviceLoginMessage.prototype.loginTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    NewDeviceLoginMessage.prototype.deviceName = "";
    NewDeviceLoginMessage.prototype.isoCountryCode = "";

    NewDeviceLoginMessage.create = function create(properties) {
        return new NewDeviceLoginMessage(properties);
    };

    NewDeviceLoginMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
            writer.uint32(10).string(message.accountId);
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(18).string(message.username);
        if (message.loginTime != null && Object.hasOwnProperty.call(message, "loginTime"))
            writer.uint32(25).sfixed64(message.loginTime);
        if (message.deviceName != null && Object.hasOwnProperty.call(message, "deviceName"))
            writer.uint32(34).string(message.deviceName);
        if (message.isoCountryCode != null && Object.hasOwnProperty.call(message, "isoCountryCode"))
            writer.uint32(42).string(message.isoCountryCode);
        return writer;
    };

    NewDeviceLoginMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    NewDeviceLoginMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NewDeviceLoginMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.accountId = reader.string();
                    break;
                }
            case 2: {
                    message.username = reader.string();
                    break;
                }
            case 3: {
                    message.loginTime = reader.sfixed64();
                    break;
                }
            case 4: {
                    message.deviceName = reader.string();
                    break;
                }
            case 5: {
                    message.isoCountryCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    NewDeviceLoginMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    NewDeviceLoginMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.NewDeviceLoginMessage)
            return object;
        let message = new $root.NewDeviceLoginMessage();
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        if (object.username != null)
            message.username = String(object.username);
        if (object.loginTime != null)
            if ($util.Long)
                (message.loginTime = $util.Long.fromValue(object.loginTime)).unsigned = false;
            else if (typeof object.loginTime === "string")
                message.loginTime = parseInt(object.loginTime, 10);
            else if (typeof object.loginTime === "number")
                message.loginTime = object.loginTime;
            else if (typeof object.loginTime === "object")
                message.loginTime = new $util.LongBits(object.loginTime.low >>> 0, object.loginTime.high >>> 0).toNumber();
        if (object.deviceName != null)
            message.deviceName = String(object.deviceName);
        if (object.isoCountryCode != null)
            message.isoCountryCode = String(object.isoCountryCode);
        return message;
    };

    NewDeviceLoginMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.accountId = "";
            object.username = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.loginTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.loginTime = options.longs === String ? "0" : 0;
            object.deviceName = "";
            object.isoCountryCode = "";
        }
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        if (message.loginTime != null && message.hasOwnProperty("loginTime"))
            if (typeof message.loginTime === "number")
                object.loginTime = options.longs === String ? String(message.loginTime) : message.loginTime;
            else
                object.loginTime = options.longs === String ? $util.Long.prototype.toString.call(message.loginTime) : options.longs === Number ? new $util.LongBits(message.loginTime.low >>> 0, message.loginTime.high >>> 0).toNumber() : message.loginTime;
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            object.deviceName = message.deviceName;
        if (message.isoCountryCode != null && message.hasOwnProperty("isoCountryCode"))
            object.isoCountryCode = message.isoCountryCode;
        return object;
    };

    NewDeviceLoginMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    NewDeviceLoginMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/NewDeviceLoginMessage";
    };

    return NewDeviceLoginMessage;
})();

export { $root as default };
