import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUpgradeSubscription } from "api/shop";
import {
  Details,
  Subscription,
  createPersonalSubsWithCredits,
  createSubsWithCredits,
  fetchCancelSubscription,
  fetchRenewSubscription,
  fetchRenewSubscriptionWithCredits,
  fetchStreamerSubscribers,
  fetchUserSubscriptionsList,
  upgradePersonalSubscription,
} from "api/subscriptionToStreamerList";
import { isApiError } from "api/utils/enhancedFetch";
import { VoidCallback } from "src/types/common";
import { getIsBecomeAFanPersonalOffersEnabled } from "state/abTests";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import {
  subscriptionPerStreamerListSelectors,
  userSelectors,
} from "state/selectors";
import { SubscriptionPerStreamerListState } from "state/tree/subscriptionPerStreamerList";

const actions = {
  refreshStreamerSubscribersList: createAsyncThunk<
    Subscription[],
    { accountId: string } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/streamerSubscribersList/fetch",
    async ({ accountId }, api) => {
      try {
        return await fetchStreamerSubscribers({ accountId });
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();

        return !subscriptionPerStreamerListSelectors.getIsStreamerSubscribersLoading(
          state
        );
      },
    }
  ),
  refreshUserSubscribersList: createAsyncThunk<
    Subscription[],
    FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/userSubscribersList/fetch",
    async (args, api) => {
      try {
        const accountId = userSelectors.getMyAccountId(api.getState());

        return await fetchStreamerSubscribers({ accountId });
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();

        return !subscriptionPerStreamerListSelectors.getIsStreamerSubscribersLoading(
          state
        );
      },
    }
  ),
  refreshUserSubscriptionsList: createAsyncThunk<
    Subscription[],
    { details?: Details } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/subscriptionsList/fetch",
    async ({ details }, api) => {
      try {
        const accountId = userSelectors.getMyAccountId(api.getState());

        return await fetchUserSubscriptionsList({
          accountIds: [accountId],
          ...(!!details && { details }),
        });
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();

        return !subscriptionPerStreamerListSelectors.getUserSubscriptionsMeta(
          state
        ).loading;
      },
    }
  ),
  renewSubscription: createAsyncThunk<
    Data<SubscriptionPerStreamerListState>,
    {
      isSubWithCredits?: boolean;
      onError?: VoidCallback;
      onSubscriptionExpired: VoidCallback;
      onSuccess?: ({ subscription }: { subscription: Subscription }) => void;
      subscriptionId: string;
    } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/renewSubscription/fetch",
    async (
      {
        isSubWithCredits = false,
        subscriptionId,
        onError,
        onSuccess,
        onSubscriptionExpired,
      },
      api
    ) => {
      try {
        const action = isSubWithCredits
          ? fetchRenewSubscriptionWithCredits
          : fetchRenewSubscription;
        const { subscription, code } = await action({ subscriptionId });

        if (code === "NOT_FOUND") {
          onSubscriptionExpired();

          return;
        }
        onSuccess?.({ subscription });
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;
        onError?.();

        return api.rejectWithValue(error);
      }
    }
  ),
  cancelSubscription: createAsyncThunk<
    Data<SubscriptionPerStreamerListState>,
    {
      onError?: VoidCallback;
      onSuccess?: VoidCallback;
      subscriptionId: string;
    } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/cancelSubscription/fetch",
    async ({ subscriptionId, onSuccess, onError }, api) => {
      try {
        await fetchCancelSubscription({ subscriptionId });
        onSuccess?.();
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;
        onError?.();

        return api.rejectWithValue(error);
      }
    }
  ),
  upgradeSubscription: createAsyncThunk<
    Data<SubscriptionPerStreamerListState>,
    {
      details: Details;
      externalOfferId: string;
      onError?: VoidCallback;
      onSuccess?: ({
        streamerId,
        subscription,
      }: {
        streamerId: string;
        subscription: Subscription;
      }) => void;
      streamerId: string;
      subscriptionId: string;
      triggerId?: string;
    } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/upgradeSubscription/fetch",
    async (
      {
        streamerId,
        onSuccess,
        onError,
        details,
        externalOfferId,
        subscriptionId,
        triggerId,
      },
      api
    ) => {
      try {
        const state = api.getState();
        const isBecomeAFanPersonalOffersEnabled =
          getIsBecomeAFanPersonalOffersEnabled(state);

        let data = null;

        if (isBecomeAFanPersonalOffersEnabled) {
          const response = await upgradePersonalSubscription({
            externalOfferId,
            details,
            subscriptionId,
            triggerId,
          });

          data = JSON.parse(JSON.stringify(response.subscription));
        } else {
          const response = await fetchUpgradeSubscription({
            externalOfferId,
            details,
            subscriptionId,
          });
          data = response.subscription;
        }
        onSuccess?.({ streamerId, subscription: data });
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;
        onError?.();

        return api.rejectWithValue(error);
      }
    }
  ),

  createSubscriptionWithCredits: createAsyncThunk<
    Data<SubscriptionPerStreamerListState>,
    {
      accountId: string;
      marketOfferId: string;
      onError?: VoidCallback;

      onSuccess?: ({
        streamerId,
        subscription,
      }: {
        streamerId: string;
        subscription: Subscription;
      }) => void;
      triggerId?: string;
    } & FetcherMetaV2,
    { rejectValue: string; state: RootState }
  >(
    "lwc/createSubscription/fetch",
    async (
      { accountId, marketOfferId, triggerId, onSuccess, onError },
      api
    ) => {
      try {
        const state = api.getState();
        const isBecomeAFanPersonalOffersEnabled =
          getIsBecomeAFanPersonalOffersEnabled(state);

        let data = null;

        if (isBecomeAFanPersonalOffersEnabled) {
          const response = await createPersonalSubsWithCredits({
            marketOfferId,
            accountId,
            triggerId,
          });

          data = JSON.parse(JSON.stringify(response.subscription));
        } else {
          data = await createSubsWithCredits({
            marketOfferId,
            accountId,
          });
        }
        onSuccess?.({ streamerId: accountId, subscription: data });
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;
        onError?.();

        return api.rejectWithValue(error);
      }
    }
  ),
  subscribeStreamer: createAction<{
    forceSubscribe?: boolean;
    streamerId: string;
    subscription: Subscription;
  }>("lwc/subscriptionsOffers/streamerSubscriptionDidBuy"),
  unsubscribeStreamer: createAction<{ accountId: string }>(
    "lwc/subscriptionList/unsubscribe"
  ),
  clearSubscription: createAction<{ accountId: string }>(
    "lwc/subscriptionList/clearSubscription"
  ),
  clearListsData: createAction("lwc/lists/clear"),
};

export default actions;
