import {
  HIDE_BOTTOM_SCREEN,
  BOTTOM_SCREEN_ANIMATION,
  SHOW_BOTTOM_SCREEN,
} from "state/actionTypes";

export const initialState = {
  screenType: undefined,
  prevScreen: "",
  screenData: undefined,
  animation: false,
  onDismiss: undefined,
  redirectOnDismiss: undefined,
};

export const selectors = {
  screenType: (state) => state.screenType,
  prevScreen: (state) => state.prevScreen,
  screenData: (state) => state.screenData,
  animation: (state) => state.animation,
  onDismiss: (state) => state.onDismiss,
  onOutsideClickDismiss: (state) => state.onOutsideClickDismiss,
  redirectOnDismiss: (state) => state.redirectOnDismiss,
  shouldShowBottomScreen: (state) => !!state.screenType,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BOTTOM_SCREEN:
      return {
        ...state,
        screenType: action.payload.screenType,
        screenData: action.payload.screenData,
        onDismiss: action.payload.onDismiss,
        onOutsideClickDismiss: action.payload.onOutsideClickDismiss,
        redirectOnDismiss: action.payload.redirectOnDismiss,
        prevScreen: state.screenType || "",
      };
    case HIDE_BOTTOM_SCREEN:
      return { ...initialState };
    case BOTTOM_SCREEN_ANIMATION:
      return { ...state, animation: action.payload.animation };
  }
  return state;
};
