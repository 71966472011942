import { useEffect } from "react";
import intersectionObserver from "src/utils/intersectionObserver";

const composeBackgroundImageStyle = (images) => {
  if (!images || !images.length) {
    return "none";
  }

  return (Array.isArray(images) ? images : images.split(","))
    .map((x) => `url('${x}')`)
    .join(",");
};

const actionObserver = (entries, self) => {
  entries
    .filter((x) => x.isIntersecting)
    .forEach((x) => {
      x.target.style.backgroundImage = composeBackgroundImageStyle(
        x.target.dataset.lazybg
      );
      self.unobserve(x.target);
    });
};

const observer = intersectionObserver(actionObserver, {
  rootMargin: "400px 0px 400px 0px",
});

/**
 * requires data-lazybg attribute set on elements to work
 * expects that data-lazybg and refs list does not change
 * multiple images should be presented as comma-separated string in the attribute
 */
export const useLazyBackgroundImageImmutable = (...refs) => {
  useEffect(() => {
    if (!observer) {
      return;
    }
    const actualRefs = refs.filter(
      (ref) => ref.current && ref.current.dataset.lazybg
    );
    if (!actualRefs.length) {
      return;
    }
    actualRefs.forEach((ref) => {
      observer.observe(ref.current);
    });

    return () => {
      actualRefs.forEach((ref) => observer.unobserve(ref.current));
    };
  }, []);
};

/**
 * accepts array of images and refs
 * refreshes on images array change
 */
export const useLazyBackgroundImagesBatch = ({ refs, images }) => {
  useEffect(() => {
    if (!observer) {
      return;
    }
    const actualRefs = refs
      .map((x, index) => [x, images[index]])
      .filter(([ref, image]) => ref.current && image)
      .filter(([ref, image]) => {
        if (ref.current.style.backgroundImage) {
          ref.current.style.backgroundImage =
            composeBackgroundImageStyle(image);

          return false;
        }
        ref.current.dataset.lazybg = image;

        return true;
      })
      .map(([ref]) => ref);

    if (!actualRefs.length) {
      return;
    }
    actualRefs.forEach((ref) => {
      observer.observe(ref.current);
    });

    return () => {
      actualRefs.forEach((ref) => observer.unobserve(ref.current));
    };
  }, [images]);
};

/**
 * Updates when images change
 * @param {*} ref target element to set bg image on
 * @param {*} images array or comma-separated string
 */
export const useLazyBackgroundImageMutable = (ref, images) => {
  useEffect(() => {
    if (!observer) {
      return;
    }
    if (!ref || !images || !images.length) {
      return;
    }
    if (ref.current.style.backgroundImage) {
      // considering background "unlocked" so updating immediately without fancy stuff
      ref.current.style.backgroundImage = composeBackgroundImageStyle(images);

      return;
    }
    ref.current.dataset.lazybg = Array.isArray(images)
      ? images.join(",")
      : images;
    observer.observe(ref.current);
    const savedRef = ref.current;

    return () => observer.unobserve(savedRef);
  }, [images]);
};
