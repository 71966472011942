import type {
  RealTimeRecommendationsClientEvent,
  RealTimeRecommendationsParamValue,
} from "src/features/recommendations/common/types";
import {
  RealTimeRecommendationsEventType,
  RealTimeRecommendationsParamKey,
} from "src/features/recommendations/common/enums";

export class RealTimeRecommendationsEventBuilder {
  private event: RealTimeRecommendationsClientEvent = {
    eventName: RealTimeRecommendationsEventType.UNKNOWN,
    params: [],
  };

  constructor(eventName: RealTimeRecommendationsEventType) {
    this.event.eventName = eventName;
  }

  public addParam(
    paramName: RealTimeRecommendationsParamKey,
    paramValue: RealTimeRecommendationsParamValue
  ): this {
    this.event.params.push({
      name: paramName,
      value: paramValue,
    });

    return this;
  }

  public build(): RealTimeRecommendationsClientEvent {
    return this.event;
  }
}
