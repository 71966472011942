import { fetchAccountTags } from "api/tags";
import { ACCOUNT_TAGS_EXPIRE_MS } from "src/constants";
import { setAccountTags } from "src/features/signin/exports/state/actionCreators";
import { genericNetworkError } from "state/actionCreators/networkError";
import { userSelectors } from "state/selectors";

export default (now = Date.now()) =>
  async (dispatch, getState) => {
    if (
      !!userSelectors.getAccountTagsUpdateTs(getState()) &&
      now - userSelectors.getAccountTagsUpdateTs(getState()) <
        ACCOUNT_TAGS_EXPIRE_MS
    ) {
      return;
    }
    try {
      const data = await fetchAccountTags();
      dispatch(setAccountTags({ tags: data.tags, updateTime: now }));
    } catch (error) {
      dispatch(genericNetworkError(error));
    }
  };
