import { createReducer } from "@reduxjs/toolkit";
import { Nullable } from "src/types/common";
import { PersonalOffersConfig } from "src/types/personalOffer";
import actions from "state/actionCreators/landingPagePhoenix";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";

const landingConfigInitialState = {
  data: null,
  meta: initialFetcherStateMeta,
  selectedCampaignId: null,
};

export const persistConfig = {
  whitelist: ["config"],
};

export type LandingPagePhoenixState = {
  selectedCampaignId: Nullable<string>;
} & AsyncState<Nullable<PersonalOffersConfig>, string>;

const currentLandingConfig = createReducer<LandingPagePhoenixState>(
  landingConfigInitialState,
  (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        builder,
        action: actions.fetchLandingPagePhoenixConfig,
        prepareData: (oldConfig, newConfig) => newConfig,
        initialData: landingConfigInitialState.data,
      })
        .addCase(actions.clearLandingPagePhoenixConfig, (state, action) => {
          const isLandingPageV2Enabled = action.payload;

          return {
            ...state,
            meta: landingConfigInitialState.meta,
            selectedCampaignId: isLandingPageV2Enabled
              ? state.selectedCampaignId
              : landingConfigInitialState.selectedCampaignId,
          };
        })
        .addCase(actions.setCampaignId, (state, action) => {
          state.selectedCampaignId = action.payload;
        })
        .addCase(
          actions.checkAndUpdateLandingPagePhoenixConfig.fulfilled,
          (state, action) => ({
            ...state,
            data: action.payload,
            selectedCampaignId:
              (action.payload && action.payload.campaignId) || null,
          })
        ),
      () => landingConfigInitialState
    );
  }
);

export default currentLandingConfig;
