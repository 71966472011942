import { useSelector } from "react-redux";
import { getVisitorProfileEnabled } from "state/abTests";
import { loginSelectors } from "state/selectors";

const useIsVisitorProfile = () => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const isVisitorPageEnabled = useSelector(getVisitorProfileEnabled);

  return !isLoggedIn && isVisitorPageEnabled;
};

export default useIsVisitorProfile;
