import enhancedFetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { Gift } from "src/types/gift";

type EncryptedGiftId = Gift["id"];

interface GiftsBatchBody {
  encryptedGiftIds: EncryptedGiftId[];
}

export interface GiftsBatchResponse {
  drawerVersion?: number;
  gifts: Gift[];
}

export const fetchGiftsBatch = async (encryptedGiftIds: EncryptedGiftId[]) => {
  const body: GiftsBatchBody = { encryptedGiftIds };

  const response = await enhancedFetch(
    `${getProxycadorHost()}/stickaloger/catalog/v4/gifts/list`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return (await response.json()) as GiftsBatchResponse;
};
