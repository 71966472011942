import { getProxycadorHost } from "src/environment";
import { Nullable } from "src/types/common";
import fetch from "api/utils/enhancedFetch";
import { BattleStatusEnum } from "src/types/tournament";

export interface Asset {
  backgroundUrl: string;
  backgroundColor: string;
  promoIconUrl: string;
  connectorColor: string;
}

export interface Player {
  accountId: string;
  prevBattleId?: Nullable<number>;
  streamId?: string;
}

export interface Battle {
  id: number;
  players: Player[];
  result: {
    winnerAccountId: Nullable<Player["accountId"]> | null;
  };
  startTsMs: Nullable<number>;
  battleDurationSec: Nullable<number>;
  status: BattleStatusEnum;
}

export interface Round {
  battles: Battle[];
  id: number;
  startTsMs: number;
  title: string;
}

export interface Tournament {
  id: string;
  title: string;
  startTsMs: number;
  battleDurationSec: number;
  rounds: Round[];
  assets?: Partial<Asset>;
}

export const fetchTournament = (name?: string): Promise<Tournament> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/tournament/v1/tournaments/lwc/${name}`
  ).then((resp) => resp.json());
