import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { emitPersonalOffersEvent } from "@analytics/emitPersonalOffersEvent";
import { PERSONAL_OFFERS_SOURCE } from "@analytics/enums";
import { isApiError } from "api/utils/enhancedFetch";
import { RootState } from "state/delegate";
import {
  GetRemoteConfigArgs,
  getRemoteConfig,
} from "state/flows/loadLandingPagePhoenixConfig";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { landingPagePhoenixSelectors } from "state/selectors";
import { LandingPagePhoenixState } from "state/tree/landingPagePhoenix";

export const SKIP_LANDING_UPDATE = "SKIP LANDING UPDATE";

export default {
  fetchLandingPagePhoenixConfig: createAsyncThunk<
    Data<LandingPagePhoenixState>,
    FetcherMetaV2 & GetRemoteConfigArgs,
    { rejectValue: string; state: RootState }
  >(
    "lwc/landingPagePhoenixConfig/fetch",
    async (args, api) => {
      try {
        const startTimestamp = Date.now();
        const config = await getRemoteConfig(args);

        if (config) {
          const state = api.getState();
          const parseOldConfig =
            landingPagePhoenixSelectors.landingPagePhoenixState(state).config;

          emitPersonalOffersEvent({
            parseOldConfig,
            parseNewConfig: config,
            startTimestamp,
            source: PERSONAL_OFFERS_SOURCE.LANDING_PAGE,
            trackingId: args?.trackingId,
          });

          return config;
        }

        return api.rejectWithValue("NOT FOUND");
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (args, api) => {
        const state = api.getState();
        const meta =
          landingPagePhoenixSelectors.landingPagePhoenixStateMeta(state);

        return !meta.loading;
      },
    }
  ),
  clearLandingPagePhoenixConfig: createAction<boolean>(
    "lwc/landingPagePhoenixConfig/clear"
  ),
  setCampaignId: createAction<string>(
    "lwc/landingPagePhoenixConfig/setCampaignId"
  ),
  checkAndUpdateLandingPagePhoenixConfig: createAsyncThunk<
    Data<LandingPagePhoenixState>,
    FetcherMetaV2 & GetRemoteConfigArgs,
    { rejectValue: string; state: RootState }
  >(
    "lwc/landingPagePhoenixConfig/checkAndUpdate",
    async (args, api) => {
      try {
        const { campaignId: currentCampaignId } = args;
        const config = await getRemoteConfig(args);

        if (config && config.campaignId !== currentCampaignId) {
          return config;
        }

        return api.rejectWithValue(SKIP_LANDING_UPDATE);
      } catch (e) {
        const error = isApiError(e) ? e.statusText : (e as Error).message;

        return api.rejectWithValue(error);
      }
    },
    {
      condition: (_, api) => {
        const state = api.getState();
        const meta =
          landingPagePhoenixSelectors.landingPagePhoenixStateMeta(state);

        return !meta.loading;
      },
    }
  ),
};
