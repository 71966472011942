import { getProxycadorHost } from "environment";
import fetch from "api/utils/enhancedFetch";
import { BannerConfig } from "src/types/banner";

interface BannersConfigResponse {
  banners?: BannerConfig[];
  version: string;
}

export const fetchBannerConfigs = (): Promise<BannersConfigResponse> =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/banner/search`).then(
    (resp) => resp.json()
  );
