import { createReducer } from "@reduxjs/toolkit";
import { actions } from "state/actionCreators/receivedAcmeIds";

export const persistConfig = {
  whitelist: ["acmeIds"],
};

const initialState = {
  acmeIds: [],
};

export interface ReceivedAcmeIdsType {
  acmeIds: string[];
}

export interface ReceivedAcmeIdType {
  data?: {
    acmeId: string;
  };
}

const reducer = createReducer<ReceivedAcmeIdsType>(initialState, (builder) => {
  builder
    .addCase(actions.saveAcmeId, (state, action) => {
      const { data } = action.payload;
      if (data?.acmeId) {
        state.acmeIds.push(data.acmeId);
      }
    })
    .addCase(actions.clearAcmeIds, (state) => {
      state.acmeIds = initialState.acmeIds;
    });
});

export default reducer;
