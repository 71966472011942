import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { checkSourceAccessibility } from "chat/api/sourceHealthcheck";
import { ReactComponent as ImageIcon } from "chat/assets/ic_image_empty_state.svg";
import MediaBackdrop from "chat/components/common/MediaBackdrop/MediaBackdrop";
import { useAlternativeDomainContentSupport } from "chat/hooks/useAlternativeDomainContentSupport";
import { HTTP_CODE_UNAUTHORIZED } from "chat/imports/constants";
import { useLightbox } from "chat/imports/context";
import {
  useBreakpointPrecise,
  useLazyBackgroundImageImmutable,
} from "chat/imports/hooks";
import { Nullable } from "chat/imports/types";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { MessageConfig } from "./Message";
import styles from "./ImageMessage.scss";
import messagesStyles from "chat/components/currentConversation/Message.scss";

interface ImageMessageProps {
  conversationId?: string;
  height: number;
  id?: number;
  isBlurred?: boolean;
  messageConfig: MessageConfig;
  url: string;
  width: number;
}

const ImageMessage: FC<ImageMessageProps> = ({
  id,
  url,
  width,
  height,
  messageConfig,
  conversationId,
  isBlurred = true,
}) => {
  const changedUrl = useAlternativeDomainContentSupport(url);

  const breakpoint = useBreakpointPrecise();
  const [isImageAccessible, setIsImageAccessible] = useState<boolean>(true);
  const openLightbox = useLightbox();

  const onImageClick = useCallback(() => {
    if (changedUrl) {
      // @ts-ignore TODO: WEB-3243 rewrite to ts
      openLightbox({ photos: [changedUrl] });
    }
  }, [openLightbox, changedUrl]);
  const ref = useRef<Nullable<HTMLDivElement>>(null);

  useLazyBackgroundImageImmutable(ref);

  const checkImageURLAccessibility = useCallback(async () => {
    try {
      const response = await checkSourceAccessibility(changedUrl);
      setIsImageAccessible(
        response.ok || response.status === HTTP_CODE_UNAUTHORIZED
      );
    } catch (error) {
      setIsImageAccessible(false);
    }
  }, [changedUrl]);

  useEffect(() => {
    if (changedUrl) {
      checkImageURLAccessibility();
    }
  }, [changedUrl, checkImageURLAccessibility]);

  const style = useMemo(() => {
    if (width > height) {
      return {
        width: "var(--mediaMaxDimen)",
        height: `max(calc(var(--mediaMaxDimen) * ${height} / ${width}), var(--mediaMinDimen))`,
      };
    }

    return {
      height: "var(--mediaMaxDimen)",
      width: `max(calc(var(--mediaMaxDimen) * ${width} / ${height}), var(--mediaMinDimen))`,
    };
  }, [width, height]);

  return (
    <div
      ref={ref}
      data-lazybg={changedUrl}
      onClick={onImageClick}
      className={classnames(
        styles.root,
        styles[breakpoint],
        messagesStyles.imageMessage,
        {
          [messagesStyles.firstInGroup]: messageConfig.isFirstInGroup,
          [styles.imageAccessible]: !isImageAccessible,
        },
        getGroupMessageClassnames(styles, messageConfig)
      )}
      style={style}
    >
      {id && conversationId && (
        <MediaBackdrop
          isBlurred={isBlurred}
          conversationId={conversationId}
          messageId={id}
          messageConfig={messageConfig}
        />
      )}
      {!isImageAccessible && <ImageIcon className={styles.image} />}
    </div>
  );
};

export default memo(ImageMessage);
