import { RoundingType } from "src/enums";
import { Nullable } from "src/types/common";

export interface RoundedNumberOptions extends Intl.NumberFormatOptions {
  decimalPlaces?: number;
  locale?: string;
  roundingType?: RoundingType;
}

export default (
  count: Nullable<number | string | undefined>,
  {
    locale,
    decimalPlaces = 1,
    roundingType = RoundingType.NEAREST,
    ...rest
  }: RoundedNumberOptions = {} as RoundedNumberOptions
) => {
  let number: number = 0;
  let abbrev = "";

  if (count == null || typeof count !== "number" || isNaN(count)) {
    number = 0;
  } else if (count >= 1000000) {
    number = count / 1000000;
    abbrev = "M";
  } else if (count >= 1000) {
    number = count / 1000;
    abbrev = "K";
  } else {
    number = count;
  }

  const factor = Math.pow(10, decimalPlaces);

  switch (roundingType) {
    case RoundingType.DOWN:
      number = Math.floor(number * factor) / factor;
      break;
    case RoundingType.UP:
      number = Math.ceil(number * factor) / factor;
      break;
    case RoundingType.NEAREST:
    default:
      number = Math.round(number * factor) / factor;
      break;
  }

  const formattedNumber = locale
    ? new Intl.NumberFormat(locale, { useGrouping: false, ...rest }).format(
        number
      )
    : number.toString();

  return `${formattedNumber}${abbrev}`;
};
