import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { Nullable } from "src/types/common";
import {
  SocialGamesResponseCode,
  SocialGamesSectionType,
} from "src/features/homePage/v2/imports/enums";
import {
  SocialGamesListGameDto,
  SocialGamesResponseStateAction,
} from "src/features/homePage/v2/imports/types";

export interface HomePageGamesState {
  error: Nullable<string>;
  games: SocialGamesListGameDto[];
  loading: boolean;
  responseCode: Nullable<SocialGamesResponseCode>;
}

const initialState: HomePageGamesState = {
  responseCode: null,
  games: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "homePageGames",
  initialState,
  reducers: {
    loadHomePageGamesStart(state) {
      state.loading = true;
      state.responseCode = null;
      state.error = null;
    },
    loadHomePageGamesComplete(
      state,
      action: PayloadAction<SocialGamesResponseStateAction>
    ) {
      state.loading = false;
      state.responseCode = action.payload.response.responseCode;

      if (action.payload.response.sectionGames && !state.games.length) {
        state.games = action.payload.response.sectionGames
          .filter(
            (section) => section.section === SocialGamesSectionType.SLOTS
          )[0]
          .games.slice(0, 5) as SocialGamesListGameDto[];
      }
    },
    loadHomePageGamesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadHomePageGamesStart,
  loadHomePageGamesFailure,
  loadHomePageGamesComplete,
} = slice.actions;

export const homePageGamesReducer = slice.reducer;
