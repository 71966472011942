import { useEffect, useLayoutEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Currency, CurrencyPricePoint } from "src/enums";
import { Nullable } from "src/types/common";
import { PaymentProvider } from "src/types/payment";
import {
  PersonalOffersCurrencyPrice,
  PricePoint,
} from "src/types/personalOffer";
import { getCurrentCurrencyPrice } from "src/utils/getCurrentCurrencyPrice";
import { uniq } from "src/utils/miniLodash";
import { getIsUseBasePriceFromOfferCurrentPriceEnabled } from "state/abTests";
import {
  currenciesOverride,
  currenciesSetWhiteList,
  fetchCurrencies,
} from "state/actionCreators/currencies";
import { RootState } from "state/delegate";
import { currenciesSelectors } from "state/selectors";
import usePersistentState from "ui/hooks/usePersistentState";
import {
  CurrencyPrice,
  ShopOffer,
  ShopOfferCurrencyPriceBase,
} from "ui/scenes/landingPage/types";
import { fromCent } from "ui/scenes/landingPagePhoenix/utils";

const emptyObj = {};

type useCurrenciesProps = {
  override?: Currency[];
  suggestedProvider?: PaymentProvider;
  whitelist?: Currency[];
};

export default ({
  override,
  whitelist,
  suggestedProvider,
}: useCurrenciesProps = emptyObj) => {
  const dispatch = useDispatch();
  const [overridePaymentProvider] = usePersistentState({
    key: `override.payment.providers`,
    isSkipParse: true,
  });

  const overrideProvider = suggestedProvider || overridePaymentProvider;
  const isSafeChargeUs = overrideProvider === PaymentProvider.SAFECHARGE_US;

  useEffect(() => {
    dispatch(fetchCurrencies());

    return () => {
      batch(() => {
        dispatch(currenciesSetWhiteList([]));
        dispatch(currenciesOverride([]));
      });
    };
  }, []);

  useLayoutEffect(() => {
    dispatch(currenciesSetWhiteList(whitelist || null));
  }, [whitelist]);

  useLayoutEffect(() => {
    if (isSafeChargeUs) {
      dispatch(currenciesOverride([Currency.USD]));
    } else {
      dispatch(currenciesOverride(override || null));
    }
  }, [isSafeChargeUs, override]);
};

export const getValueAndCurrency = (
  currency: Currency,
  offer: ShopOfferCurrencyPriceBase
): CurrencyPricePoint => {
  if (!offer.currencyPrice[currency]) {
    return {
      currency: Currency.USD,
      value: offer?.currencyPrice[Currency.USD],
    };
  }

  return { currency, value: offer.currencyPrice[currency] as number };
};

export const useValueAndCurrencyForOffer = (
  offer: ShopOfferCurrencyPriceBase,
  preferredCurrency?: Nullable<Currency>
) => {
  const userCurrency = useSelector(currenciesSelectors.getCurrentCurrency);

  return useMemo(
    () => getValueAndCurrency(preferredCurrency || userCurrency, offer),
    [preferredCurrency, userCurrency, offer]
  );
};

export const useSavedMoneyForOffer = (offer: ShopOfferCurrencyPriceBase) => {
  const { value, currency } = useValueAndCurrencyForOffer(offer);

  const savedPrice = value * offer.discountPercent;

  if (savedPrice <= 1) {
    return {
      value: Number(savedPrice.toFixed(2)),
      currency,
    };
  }

  return {
    value: Math.ceil(savedPrice),
    currency,
    maximumFractionDigits: 0,
  };
};

interface GetPersonalOffersValueAndCurrencyProps {
  currency: Currency;
  isUseBasePriceFromOfferCurrentPriceEnabled: boolean;
  offer: PersonalOffersCurrencyPrice;
}

export const getPersonalOffersValueAndCurrency = ({
  currency,
  offer,
  isUseBasePriceFromOfferCurrentPriceEnabled,
}: GetPersonalOffersValueAndCurrencyProps): CurrencyPricePoint => {
  const currentPrice = offer.currencyPrice?.find(
    ({ currency: offerCurrency }) => currency === offerCurrency
  );

  const price =
    currentPrice &&
    getCurrentCurrencyPrice(
      currentPrice,
      isUseBasePriceFromOfferCurrentPriceEnabled
    );

  if (!price) {
    return {
      currency: Currency.USD,
      value: offer.usdPrice,
    };
  }

  return { currency, value: fromCent(price) };
};

const selectors = (state: RootState) => ({
  currency: currenciesSelectors.getCurrentCurrency(state),
  isUseBasePriceFromOfferCurrentPriceEnabled:
    getIsUseBasePriceFromOfferCurrentPriceEnabled(state),
});

export const useValueAndCurrencyForPersonalOffers = (
  offer: PersonalOffersCurrencyPrice
) => {
  const { currency, isUseBasePriceFromOfferCurrentPriceEnabled } = useSelector(
    selectors,
    shallowEqual
  );

  return useMemo(
    () =>
      getPersonalOffersValueAndCurrency({
        currency,
        offer,
        isUseBasePriceFromOfferCurrentPriceEnabled,
      }),
    [currency, offer, isUseBasePriceFromOfferCurrentPriceEnabled]
  );
};

export const useCurrenciesWhiteList = (
  offers: ShopOffer[],
  selectedOffer?: Nullable<ShopOffer>
) =>
  useMemo(() => {
    if (!selectedOffer && !offers?.length) {
      return [] as Currency[];
    }

    if (selectedOffer) {
      return Object.keys(selectedOffer.currencyPrice) as Currency[];
    }

    return uniq(
      offers.flatMap((x) => Object.keys(x.currencyPrice)) as Currency[]
    );
  }, [offers, selectedOffer]);

export const useCurrenciesPersonalOffersWhiteList = (
  config: PricePoint[],
  selectedOffer?: Nullable<{ currencyPrice: CurrencyPrice }>
) =>
  useMemo(() => {
    if (!selectedOffer && !config?.length) {
      return [] as Currency[];
    }

    if (selectedOffer) {
      return Object.keys(selectedOffer.currencyPrice) as Currency[];
    }

    return uniq(
      config.flatMap(({ offers }) =>
        offers.flatMap((item) =>
          item.currencyPrice?.flatMap(({ currency }) => currency)
        )
      ) as Currency[]
    );
  }, [config, selectedOffer]);
