import { FeedPostErrorMessage, FeedState } from "src/features/feed/state/types";

export const feedPostSelectors = {
  getIsFansOnlyError: ({ post }: FeedState) =>
    post.meta.error === FeedPostErrorMessage.FANS_ONLY_NOT_SUPPORTED,
  getIsNotFoundError: ({ post }: FeedState) =>
    post.meta.error === FeedPostErrorMessage.NOT_FOUND,
  getIsSomethingWentWrongError: ({ post }: FeedState) =>
    post.meta.error === FeedPostErrorMessage.SOMETHING_WENT_WRONG,
  getIsUnsupportedError: ({ post }: FeedState) =>
    post.meta.error === FeedPostErrorMessage.TYPE_NOT_SUPPORTED,
  getPost: ({ post }: FeedState) => post.data,
};
