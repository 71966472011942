import { StreamVideoUrl } from "src/enums";
import { StreamKind } from "src/types/richFragment/Stream";

const getStreamListUrl = (oldUrl: string, updatedUrl: string) =>
  !updatedUrl || updatedUrl === StreamVideoUrl.NO_URL ? oldUrl : updatedUrl;

// TODO https://tango-me.atlassian.net/browse/WEB-5778
// @ts-ignore
const ensurePremiumStreamListUrl = ({ streams, updatedStream }) => {
  if (updatedStream.streamKind !== StreamKind.TICKET_PRIVATE) {
    return {};
  }

  const oldStreamData = streams[updatedStream.id];

  if (!oldStreamData) {
    return {};
  }

  return {
    liveListUrl: getStreamListUrl(
      oldStreamData.liveListUrl,
      updatedStream.liveListUrl
    ),
    masterListUrl: getStreamListUrl(
      oldStreamData.masterListUrl,
      updatedStream.masterListUrl
    ),
    playlistUrl: getStreamListUrl(
      oldStreamData.playlistUrl,
      updatedStream.playlistUrl
    ),
    previewListUrl: getStreamListUrl(
      oldStreamData.previewListUrl,
      updatedStream.previewListUrl
    ),
  };
};

export default ensurePremiumStreamListUrl;
