import { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import { refreshCustomDrawer, refreshGiftsDrawer } from "chat/imports/state";
import { loadDefaultGifts } from "chat/premiumMessage/state/flow";

export const useRefreshGifts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(refreshCustomDrawer());
      dispatch(refreshGiftsDrawer());
      dispatch(loadDefaultGifts());
    });
  }, [dispatch]);
};
