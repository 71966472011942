import { matchPath } from "react-router-dom";

interface UseIsMatchPathParams {
  pathNames: string[];
  pathname: string;
}

/**
 * checks if pathname with params are matched with pathNames array,
 * e.g. /live/creators matches with /live/:mode
 * @param pathNames
 * @param pathname
 */
const checkIsMatchPath = ({ pathNames, pathname }: UseIsMatchPathParams) =>
  pathNames.some((path) =>
    matchPath(pathname, {
      path,
    })
  );

export default checkIsMatchPath;
