export const SCENE = "SCENE";
export const GLOBAL = "GLOBAL";
export const CASHIER_SCOPE = "CASHIER_SCOPE";
export const REFILL_SCOPE = "REFILL_SCOPE";
export const LANDING_SCOPE = "LANDING_SCOPE";
export const STREAM_SCOPE = "STREAM_SCOPE";
export const MINI_PROFILE_SCOPE = "MINI_PROFILE_SCOPE";
export const RIGHT_SIDE_SCOPE = "RIGHT_SIDE_SCOPE";
export const RIGHT_SIDE_REFILL_SCOPE = "RIGHT_SIDE_REFILL_SCOPE";
export const REFERRAL_SCOPE = "AGENCY_SCOPE";
