import React, { ReactElement } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/features/signin/imports/enums";
import {
  TYPOGRAPHY_TYPE,
  Typography,
  useBreakpoint,
} from "src/features/signin/imports/ui";
import styles from "./Title.scss";

interface TitleParams {
  description?: ReactElement;
  isError?: boolean;
  title: ReactElement;
}

const Title: React.FC<TitleParams> = ({
  title,
  description,
  isError = false,
}) => {
  const breakpoint = useBreakpoint();
  const isTablet = breakpoint === Breakpoints.TABLET;
  const className = classnames(styles.container, styles[breakpoint]);

  if (isError) {
    return <div className={className} />;
  }

  return (
    <div className={className}>
      <Typography
        type={isTablet ? TYPOGRAPHY_TYPE.HERO3 : TYPOGRAPHY_TYPE.HERO4}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
          className={styles.description}
        >
          {description}
        </Typography>
      )}
    </div>
  );
};

export default Title;
