import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchNearbyCountries,
  updateNearbyCountries,
} from "api/nearbyCountries";
import { isApiError } from "api/utils/enhancedFetch";
import { LiveFeedType } from "src/enums";
import { getOFACCountriesList } from "state/abTests";
import { RootState } from "state/delegate";
import { refresh } from "state/flows/loadLiveStreamsFeeds";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { getLocaleCountryCode } from "state/selectors";
import { NearbyCountriesState } from "state/types";

export const loadNearbyCountries = createAsyncThunk<
  NearbyCountriesState["data"],
  FetcherMetaV2 | void,
  { rejectValue: string; state: RootState }
>("lwc/nearbyCountries/fetch", async (args, api) => {
  try {
    const state = api.getState();
    const locale = getLocaleCountryCode(state);
    const OFACCountriesList = getOFACCountriesList(state);
    const data = await fetchNearbyCountries(locale);

    return data.reduce(
      (map, country) => {
        if (OFACCountriesList.includes(country.isoCode.toLowerCase())) {
          return map;
        }

        map[country.isoCode] = country;

        return map;
      },
      {} as NearbyCountriesState["data"]
    );
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const updateActiveCountries = createAsyncThunk<
  void,
  string[],
  { state: RootState }
>("lwc/nearbyCountries/apply", async (activeCodes, api) => {
  try {
    await updateNearbyCountries(activeCodes);
    await api.dispatch(loadNearbyCountries());
    await api.dispatch(refresh(LiveFeedType.NEARBY));
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
