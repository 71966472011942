export const loginSelectors = {
  isLoggedIn: (state) => state.loggedIn,
  isLimitedSession: (state) => state.isLimitedSession,
  isAuthorized: (state) => state.authorized,
  isAuthorizationInProgress: (state) => state.authorizationInProgress,
  isAuthorizationFailed: (state) => state.authorizationFailed,
  isLoginFailed: (state) => state.loginFailed,
  getPhoneNumberAuthenticationState: (state) =>
    state.phoneNumberAuthenticationState,
  getResendAllowedTimestamp: (state, phoneNumber) =>
    state.resendAllowedTimestamps[phoneNumber],
  isLoginInProgress: (state) => state.loginInProgress,
  isLogoutInProgress: (state) => state.logoutInProgress,
  isPreparingProviders: (state) => state.isPreparingProviders,
  getDefaultCountryCode: (state) => state.defaultCountryCode,
  getAvailableProviders: (state) => state.providers,
  getLoggedInProvider: (state) => state.loggedInProvider,
  getReferrerAccountId: (state) => state.referrerAccountId,
  getCountryInformation: (state) => state.country,
  getLoginEntryPoint: (state) => state.loginEntryPoint,
  getLoginErrorMessage: (state) => state.errorMessage,
};
