import tangoAnalytics from "src/core/analytics";
import {
  EventCategory,
  EventDenominations,
  EventFields,
  EventNames,
} from "src/core/analytics/enums";

const LOGGED_IN = "LOGGED_IN";

interface DataType {
  loginResult: string;
  newRegistration: boolean;
}

const analyticsUpgradeGuest = (data: DataType, type?: string): void => {
  if (data.loginResult === LOGGED_IN) {
    tangoAnalytics.then((analytics) => {
      analytics.track(EventNames.EVENT, {
        [EventFields.EVENT_NAME]: data.newRegistration
          ? EventDenominations.SING_UP
          : EventDenominations.LOGIN,
        [EventFields.EVENT_CATEGORY]: EventCategory.PERSONAL_ACCOUNT_EVENTS,
        [EventFields.EVENT_DETAIL]: type,
      });
    });
  }
};

export { analyticsUpgradeGuest };
