import invariant from "fbjs/lib/invariant";
import {
  fetchDateRegistration,
  fetchMyProfileV2,
  fetchSingleProfile,
} from "api/profile";
import tangoAnalytics from "src/core/analytics";
import { EventFields, StorageKeys } from "src/core/analytics/enums";
import {
  failedToLoadSingleProfile,
  loadedSingleProfile,
  willLoadSingleProfile,
} from "state/actionCreators/profile";
import {
  loginSelectors,
  profilesCacheSelectors,
  userSelectors,
} from "state/selectors";

const fullProfileNetworkRequestParams = {
  basicProfile: true,
  liveStats: true,
  followStats: true,
};
const fullProfileCacheRequestParams = {
  basic: true,
  detail: true,
  live: true,
  follow: true,
};

const loadProfile =
  ({
    accountId,
    isMe,
    fetcher,
    cacheRequestParams = fullProfileCacheRequestParams,
    networkRequestParams = fullProfileNetworkRequestParams,
  }) =>
  (dispatch) => {
    dispatch(
      willLoadSingleProfile({
        accountId,
        isMe,
        fieldsRequested: cacheRequestParams,
      })
    );

    return Promise.all([
      fetcher({ ...networkRequestParams, accountId }),
      fetchDateRegistration(),
    ])
      .then(([profile, { registrationTime }]) => {
        const { followStats: { followingCount, followersCount } = {} } =
          profile;

        if (isMe) {
          tangoAnalytics.then((analytics) =>
            analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
              [EventFields.FOLLOWING]: followingCount,
              [EventFields.FOLLOWERS]: followersCount,
            })
          );
        }

        dispatch(
          loadedSingleProfile({
            profile: {
              ...profile,
              profileDetails: { ...profile.profileDetails, registrationTime },
            },
            accountId,
            isMe,
            fieldsRequested: cacheRequestParams,
          })
        );
      })
      .catch((error) =>
        dispatch(
          failedToLoadSingleProfile({
            error,
            accountId,
            isMe,
            fieldsRequested: cacheRequestParams,
          })
        )
      );
  };

export default (accountId) => (dispatch, getState) => {
  const state = getState();
  const myAccountId = userSelectors.getMyAccountId(state);
  let fetcherParams;
  if (accountId === myAccountId || !accountId) {
    // TODO ebratanova
    // invariant(loginSelectors.isLoggedIn(state), "must be logged in to load own profile");
    invariant(myAccountId, "user's account id must be known");
    fetcherParams = {
      accountId: myAccountId,
      isMe: true,
      fetcher: fetchMyProfileV2,
    };
  } else {
    fetcherParams = { accountId, isMe: false, fetcher: fetchSingleProfile };
  }
  if (
    profilesCacheSelectors.isLoadingProfileById(
      state,
      accountId || myAccountId,
      fullProfileCacheRequestParams
    )
  ) {
    return Promise.resolve();
  }

  return dispatch(loadProfile(fetcherParams));
};

export const loadMyProfile = () => (dispatch, getState) => {
  const state = getState();
  invariant(
    loginSelectors.isLoggedIn(state),
    "must be logged in to load profile"
  );

  return dispatch(
    loadProfile({
      isMe: true,
      accountId: userSelectors.getMyAccountId(state),
      fetcher: fetchMyProfileV2,
    })
  );
};
