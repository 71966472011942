export enum SendValidationSMSStatusType {
  FAILED = "failed",
  OK = "ok",
  RATELIMITED = "ratelimited",
}

export interface SendValidationSMSRequestParams {
  isResend: boolean;
  username: string;
}

export interface SendValidationSMSRequestType {
  deliveryMethod: string;
  validatedDevice: {
    platform: number;
  };
}

export interface SendValidationSMSResponse {
  reason: string;
  resendDelay: number;
  type: SendValidationSMSStatusType;
}
