import type { Dispatch } from "redux";
import type { RealtimeRecommendationsActions } from "src/features/recommendations/common/types";
import { RealTimeRecommendationsGiftFromType } from "src/features/recommendations/common/enums";
import { emitRealTimeRecommendationsFollowEvent } from "src/features/recommendations/helpers/emitRealTimeRecommendationsFollowEvent";
import { emitRealTimeRecommendationsGiftEvent } from "src/features/recommendations/helpers/emitRealTimeRecommendationsGiftEvent";
import { emitRealTimeRecommendationsUnfollowEvent } from "src/features/recommendations/helpers/emitRealTimeRecommendationsUnfollowEvent";
import { mapFollowSourceToFollowFrom } from "src/features/recommendations/helpers/mapFollowSourceToFollowFrom";
import { mapUnfollowSourceToUnfollowFrom } from "src/features/recommendations/helpers/mapUnfollowSourceToUnfollowFrom";
// eslint-disable-next-line no-restricted-imports
import {
  FOLLOW_USER,
  GIFT_SENT,
  UNFOLLOW_USER_REALTIME_RECOMMENDATION,
} from "state/actionTypes";

export const recommendationsEventsMiddleware =
  () => (next: Dispatch) => (action: RealtimeRecommendationsActions) => {
    const returnValue = next(action);

    if (action.type === FOLLOW_USER) {
      const followFrom = mapFollowSourceToFollowFrom(action.payload.source);

      emitRealTimeRecommendationsFollowEvent({
        streamerId: action.payload.accountId,
        followFrom,
      });
    }

    if (action.type === UNFOLLOW_USER_REALTIME_RECOMMENDATION) {
      const unfollowFrom = mapUnfollowSourceToUnfollowFrom(
        action.payload.source
      );

      emitRealTimeRecommendationsUnfollowEvent({
        streamerId: action.payload.accountId,
        unfollowFrom,
      });
    }

    if (action.type === GIFT_SENT) {
      emitRealTimeRecommendationsGiftEvent({
        streamerId: action.payload.userId,
        streamId: action.payload.streamId,
        giftFrom:
          action.payload.realTimeGiftFromType ||
          RealTimeRecommendationsGiftFromType.GIFT_DRAWER,
        giftCreditsPrice: action.payload.priceInCredit,
      });
    }

    return returnValue;
  };
