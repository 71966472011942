import { createSlice } from "@reduxjs/toolkit";
import { addBottomSheet } from "src/features/promotions/promoBottomSheet/state/actionCreators";
import {
  VIEWER_SESSION_LEFT,
  VIEWER_SESSION_RESET,
} from "src/features/promotions/promoBottomSheet/state/actionTypes";
import { BottomSheet } from "src/features/promotions/promoBottomSheet/types";
import { clearTcnnMessage } from "state/actionCreators/tcnn";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";
import { BottomSheetNotificationChannel, TCNN } from "ui/tcnn/types";

export type BottomSheetState = {
  items: BottomSheet[];
};

const initialState: BottomSheetState = {
  items: [],
};

const bottomSheetSlice = createSlice({
  name: "bottomSheet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSessionScopeReducer(
      builder
        .addCase(addBottomSheet, (state, { payload }) => {
          const isMessageInQueue = !!state.items.find(
            ({ message_id }) => message_id === payload.message_id
          );

          if (!isMessageInQueue) {
            const bottomSheetMessage: TCNN = {
              ...payload,
              notification_channel: BottomSheetNotificationChannel,
            };

            state.items.push(bottomSheetMessage);
          }
        })
        .addMatcher(
          (action) => clearTcnnMessage.match(action) && !!action.payload,
          (state, action) => {
            state.items = state.items.filter(
              (message) => message.tracking_id !== action.payload
            );
          }
        )
        .addMatcher(
          (action) =>
            [VIEWER_SESSION_LEFT, VIEWER_SESSION_RESET].includes(action.type),
          () => initialState
        ),
      () => initialState
    );
  },
});

export default bottomSheetSlice.reducer;
