import React from "react";
import { FormattedMessage } from "react-intl";
import { LiveFeedType } from "src/enums";
import { TabConfig } from "state/types";
import { feedModesTitles } from "./liveMessages";
import landscapeIcon from "img/ic_filled-landscape.svg?url";
import recommendedIcon from "img/thumbs-up.svg?url";

export const hardcodedTabConfigs: Record<
  LiveFeedType.LANDSCAPE | LiveFeedType.RECOMMENDED,
  TabConfig
> = {
  [LiveFeedType.RECOMMENDED]: {
    tag: LiveFeedType.RECOMMENDED,
    iconUrl: recommendedIcon,
    title: <FormattedMessage {...feedModesTitles[LiveFeedType.RECOMMENDED]} />,
  },
  [LiveFeedType.LANDSCAPE]: {
    tag: LiveFeedType.LANDSCAPE,
    iconUrl: landscapeIcon,
    title: <FormattedMessage {...feedModesTitles[LiveFeedType.LANDSCAPE]} />,
  },
};
