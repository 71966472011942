import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import {
  Nullable,
  addUserSessionScopeReducer,
} from "src/features/marketing/imports";
import { loadUserHashedInfo } from "src/features/marketing/state/asyncAction";
import { SetIsVisitRequestNeededPayload } from "src/features/marketing/state/types";
import { checkInVisitor } from "state/actionCreators/visitor";

export const persistConfig = {
  whitelist: ["analyticsKey"],
};

export interface MarketingState {
  analyticsKey: Nullable<string>;
  userHashedInfo: Nullable<{
    email: Nullable<string>;
    hashedEmail: Nullable<string>;
    hashedIpAddress: Nullable<string>;
    hashedPhoneNumber: Nullable<string>;
    ipAddress: string;
    phoneNumber: Nullable<string>;
  }>;
  visitData: {
    isVisitRequestNeeded: boolean;
  };
}

const initialState: MarketingState = {
  visitData: {
    isVisitRequestNeeded: false,
  },
  userHashedInfo: null,
  analyticsKey: null,
};

const updateAnalyticsKey = createAction<{ analyticsKey: string }>(
  "lwc/marketing/updateAnalyticsKey"
);

const slice = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    setMarketingVisitData(
      state,
      action: PayloadAction<SetIsVisitRequestNeededPayload>
    ) {
      state.visitData = action.payload;
    },
  },
  extraReducers(builder) {
    addUserSessionScopeReducer(
      builder
        .addCase(loadUserHashedInfo.fulfilled, (state, action) => {
          state.userHashedInfo = action.payload;
        })
        .addCase(checkInVisitor.fulfilled, (state, action) => {
          state.analyticsKey = action.payload.analyticsKey;
        })
        .addCase(updateAnalyticsKey, (state, action) => {
          state.analyticsKey = action.payload.analyticsKey;
        }),
      () => initialState
    );
  },
});

export { updateAnalyticsKey };

export const { setMarketingVisitData } = slice.actions;

export const marketingReducer = slice.reducer;
