import {
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET_COMPETITION,
  VIEWER_SESSION_RESET,
} from "state/actionTypes";
import { getStreamId } from "state/tree/viewerSession/commonSelectors";

const initialState = {};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      const { competition } = action.payload || {};
      const gameInfoData = competition?.gameInfo?.data;

      if (!gameInfoData?.length) return state;

      const gameEventsData = competition.gameEvents?.data;

      const nextState = {
        ...state,
      };

      nextState.competitions = gameInfoData.reduce((acc, next) => {
        if (next.gameType === "GAME_COMPETITION") {
          const {
            gameId,
            gameType,
            state: { stateEnum, countdownSeconds },
            options: { finishMillis },
          } = next;

          const previousGameEvents = state.competitions?.find(
            (compet) => compet.gameInfo.gameId === gameId
          )?.gameEvents;

          const gameEvents = gameEventsData?.find(
            (gameEvents) => gameEvents.gameId === gameId
          );

          const preparedCompetition = {
            gameInfo: {
              gameId,
              gameType,
              stateEnum,
              countdownSeconds,
              finishMillis,
            },
            gameEvents: gameEvents
              ? {
                  ...gameEvents.eventCompetition,
                  ...gameEvents.eventCompleted,
                }
              : {
                  ...previousGameEvents,
                },
          };
          acc.push(preparedCompetition);
        }

        return acc;
      }, []);

      return nextState;
    }
    case VIEWER_SESSION_RESET_COMPETITION: {
      return initialState;
    }
    case VIEWER_SESSION_RESET: {
      if (action.payload === getStreamId(context)) {
        return state;
      }
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export const localCompetitionSelectors = {
  getCompetitionState: (state) => state,
  getCompetitions: (state) => state.competitions,
  getCompetitionsIds: (state) =>
    state.competitions?.map((competition) => competition.gameInfo.gameId),
  getCompetitionByGameId: (state, gameId) =>
    state.competitions?.find(
      (competition) => competition.gameInfo.gameId === gameId
    ),
  getCompetitionByPlayerId: (state, playerId) =>
    state.competitions?.find(
      (competition) =>
        !!competition.gameEvents.players?.find(
          (player) => player.accountId === playerId
        )
    ),
};
