import {
  ACME_RECEIVED,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  VIEWER_SESSION_BOUGHT_TICKET,
  VIEWER_SESSION_WILL_BUY_TICKET,
} from "state/actionTypes";
import withUserSessionScope from "state/hor/withUserSessionScope";
import { combineReducers } from "redux";
import ensureParameter from "./utils/ensureParameter";
import { invert } from "src/utils/miniLodash";

const upgradedStreams = (state = {} /* private to public map */, action) => {
  if (action.type === ACME_RECEIVED) {
    const { serviceName, serviceIdentifier } = action.payload;
    if (serviceName === "stream") {
      const [command, id, newId] = serviceIdentifier.split(":");
      if (command === "streamUpgraded") {
        return { ...state, [id]: newId };
      }
    }
  }
  if (action.type === LIVE_RICH_NOTIFICATION_RECEIVED) {
    const { upgradedStreamId, streamId } = action.payload;
    if (upgradedStreamId) {
      return {
        ...state,
        [streamId]: upgradedStreamId,
      };
    }
  }
  return state;
};

const ticketPurchaseInProgress = (state = false, action) => {
  if (action.type === VIEWER_SESSION_WILL_BUY_TICKET) {
    return true;
  }
  if (action.type === VIEWER_SESSION_BOUGHT_TICKET) {
    return false;
  }
  return state;
};

const paidForStreams = (state = [], action) => {
  if (action.type === VIEWER_SESSION_BOUGHT_TICKET && !action.error) {
    return [...state, action.payload.streamId];
  }
  return state;
};

export default withUserSessionScope(
  combineReducers({
    upgradedStreams,
    ticketPurchaseInProgress,
    paidForStreams,
  })
);

export const selectors = {
  getPrivateStreamIdForPublicStream: (state, id) =>
    ensureParameter(id, "id") && state.upgradedStreams[id],
  getPublicStreamIdForPrivateStream: (state, id) =>
    ensureParameter(id, "id") && invert(state.upgradedStreams)[id],
  isTicketPurchaseInProgress: (state) => state.ticketPurchaseInProgress,
  wasStreamUpgraded: (state, id) =>
    ensureParameter(id, "id") &&
    Object.values(state.upgradedStreams).includes(id),
  didBuyTicketForStream: (state, id) =>
    ensureParameter(id, "id") && state.paidForStreams.includes(id),
};
