import { createSlice } from "@reduxjs/toolkit";
import {
  attachPipToSlot,
  resetPipsGrid,
  setIsPipDragging,
  setPipsGrid,
} from "state/actionCreators/pipsConfig";

export type GridLayout = Record<string, string>;

export interface GridConfig {
  layout: GridLayout;
  size: number;
}

export interface PipsConfigState {
  grid: GridConfig;
  isPipDragging: boolean;
}

const initialState: PipsConfigState = {
  grid: {
    layout: {},
    size: 0,
  },
  isPipDragging: false,
};

const slice = createSlice({
  name: "pipsConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setIsPipDragging, (state, action) => {
        state.isPipDragging = action.payload;
      })
      .addCase(attachPipToSlot, (state, action) => {
        const { slotPosition, pipPosition } = action.payload;
        const streamId = state.grid.layout[pipPosition];

        state.grid.layout[pipPosition] = state.grid.layout[slotPosition];
        state.grid.layout[slotPosition] = streamId;
      })
      .addCase(setPipsGrid, (state, action) => {
        state.grid = action.payload;
      })
      .addCase(resetPipsGrid, (state) => {
        state.grid = initialState.grid;
      });
  },
});

export const pipsConfigReducer = slice.reducer;
