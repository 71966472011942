import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";
import { SessionDetailsState } from "state/tree/sessionDetails";

const { data, meta } = generateAsyncSelectors<SessionDetailsState>();

export default {
  data,
  meta,
  getExpirationTime: (state: SessionDetailsState) =>
    data(state)?.expirationTsSec,
};
