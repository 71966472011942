import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Nullable } from "src/types/common";

export default (autoFocus = false) => {
  const textAreaRef = useRef<Nullable<HTMLTextAreaElement>>(null);
  const emojiSearchRef = useRef<Nullable<HTMLInputElement>>(null);
  const rootRef = useRef<Nullable<HTMLDivElement>>(null);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);

  useLayoutEffect(() => {
    if (autoFocus && emojiPickerVisible) {
      const container = rootRef.current?.querySelector(".emoji-mart-search");
      emojiSearchRef.current = container
        ? (container.children[0] as HTMLInputElement)
        : null;
    }
  }, [autoFocus, emojiPickerVisible]);

  const animationTimeout = 300;
  useEffect(() => {
    let timeout: number;
    if (!autoFocus) {
      return;
    }
    if (emojiPickerVisible) {
      timeout = window.setTimeout(() => {
        emojiSearchRef.current?.focus();
      }, animationTimeout);
    } else {
      textAreaRef.current?.focus();
    }
    return () => {
      timeout && window.clearTimeout(timeout);
    };
  }, [
    autoFocus,
    emojiPickerVisible,
    animationTimeout,
    emojiSearchRef.current,
    textAreaRef.current,
  ]);

  const onPickerVisibilityChange = useCallback((isVisible: boolean) => {
    setEmojiPickerVisible(isVisible);
  }, []);

  return { rootRef, textAreaRef, onPickerVisibilityChange };
};
