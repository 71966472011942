import useIsPathnameForVisitor from "ui/navigation/hooks/useIsPathnameForVisitor";

const useIsPageForVisitor = () => {
  const {
    location: { pathname },
  } = window;

  return useIsPathnameForVisitor({ pathname });
};

export default useIsPageForVisitor;
