/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.Acme || ($protobuf.roots.Acme = {});

export const KeyValuePair = $root.KeyValuePair = (() => {

    function KeyValuePair(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    KeyValuePair.prototype.key = "";
    KeyValuePair.prototype.value = "";

    KeyValuePair.create = function create(properties) {
        return new KeyValuePair(properties);
    };

    KeyValuePair.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.key);
        writer.uint32(18).string(message.value);
        return writer;
    };

    KeyValuePair.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    KeyValuePair.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.KeyValuePair();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.key = reader.string();
                    break;
                }
            case 2: {
                    message.value = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("key"))
            throw $util.ProtocolError("missing required 'key'", { instance: message });
        if (!message.hasOwnProperty("value"))
            throw $util.ProtocolError("missing required 'value'", { instance: message });
        return message;
    };

    KeyValuePair.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    KeyValuePair.fromObject = function fromObject(object) {
        if (object instanceof $root.KeyValuePair)
            return object;
        let message = new $root.KeyValuePair();
        if (object.key != null)
            message.key = String(object.key);
        if (object.value != null)
            message.value = String(object.value);
        return message;
    };

    KeyValuePair.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.key = "";
            object.value = "";
        }
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        return object;
    };

    KeyValuePair.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    KeyValuePair.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/KeyValuePair";
    };

    return KeyValuePair;
})();

export const KeyValuePairs = $root.KeyValuePairs = (() => {

    function KeyValuePairs(properties) {
        this.entry = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    KeyValuePairs.prototype.entry = $util.emptyArray;

    KeyValuePairs.create = function create(properties) {
        return new KeyValuePairs(properties);
    };

    KeyValuePairs.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.entry != null && message.entry.length)
            for (let i = 0; i < message.entry.length; ++i)
                $root.KeyValuePair.encode(message.entry[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    KeyValuePairs.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    KeyValuePairs.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.KeyValuePairs();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.entry && message.entry.length))
                        message.entry = [];
                    message.entry.push($root.KeyValuePair.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    KeyValuePairs.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    KeyValuePairs.fromObject = function fromObject(object) {
        if (object instanceof $root.KeyValuePairs)
            return object;
        let message = new $root.KeyValuePairs();
        if (object.entry) {
            if (!Array.isArray(object.entry))
                throw TypeError(".KeyValuePairs.entry: array expected");
            message.entry = [];
            for (let i = 0; i < object.entry.length; ++i) {
                if (typeof object.entry[i] !== "object")
                    throw TypeError(".KeyValuePairs.entry: object expected");
                message.entry[i] = $root.KeyValuePair.fromObject(object.entry[i]);
            }
        }
        return message;
    };

    KeyValuePairs.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.entry = [];
        if (message.entry && message.entry.length) {
            object.entry = [];
            for (let j = 0; j < message.entry.length; ++j)
                object.entry[j] = $root.KeyValuePair.toObject(message.entry[j], options);
        }
        return object;
    };

    KeyValuePairs.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    KeyValuePairs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/KeyValuePairs";
    };

    return KeyValuePairs;
})();

export const Message = $root.Message = (() => {

    function Message(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Message.prototype.keyValuePairs = null;
    Message.prototype.id = "";
    Message.prototype.payload = "";
    Message.prototype.payloadType = 1;
    Message.prototype.serviceName = "";

    Message.create = function create(properties) {
        return new Message(properties);
    };

    Message.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.keyValuePairs != null && Object.hasOwnProperty.call(message, "keyValuePairs"))
            $root.KeyValuePairs.encode(message.keyValuePairs, writer.uint32(10).fork()).ldelim();
        writer.uint32(18).string(message.id);
        writer.uint32(26).string(message.payload);
        writer.uint32(32).int32(message.payloadType);
        writer.uint32(42).string(message.serviceName);
        return writer;
    };

    Message.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Message.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Message();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.keyValuePairs = $root.KeyValuePairs.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.id = reader.string();
                    break;
                }
            case 3: {
                    message.payload = reader.string();
                    break;
                }
            case 4: {
                    message.payloadType = reader.int32();
                    break;
                }
            case 5: {
                    message.serviceName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("payload"))
            throw $util.ProtocolError("missing required 'payload'", { instance: message });
        if (!message.hasOwnProperty("payloadType"))
            throw $util.ProtocolError("missing required 'payloadType'", { instance: message });
        if (!message.hasOwnProperty("serviceName"))
            throw $util.ProtocolError("missing required 'serviceName'", { instance: message });
        return message;
    };

    Message.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Message.fromObject = function fromObject(object) {
        if (object instanceof $root.Message)
            return object;
        let message = new $root.Message();
        if (object.keyValuePairs != null) {
            if (typeof object.keyValuePairs !== "object")
                throw TypeError(".Message.keyValuePairs: object expected");
            message.keyValuePairs = $root.KeyValuePairs.fromObject(object.keyValuePairs);
        }
        if (object.id != null)
            message.id = String(object.id);
        if (object.payload != null)
            message.payload = String(object.payload);
        switch (object.payloadType) {
        default:
            if (typeof object.payloadType === "number") {
                message.payloadType = object.payloadType;
                break;
            }
            break;
        case "text_xml":
        case 1:
            message.payloadType = 1;
            break;
        case "text_json":
        case 2:
            message.payloadType = 2;
            break;
        case "application_x_protobuf":
        case 3:
            message.payloadType = 3;
            break;
        }
        if (object.serviceName != null)
            message.serviceName = String(object.serviceName);
        return message;
    };

    Message.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.keyValuePairs = null;
            object.id = "";
            object.payload = "";
            object.payloadType = options.enums === String ? "text_xml" : 1;
            object.serviceName = "";
        }
        if (message.keyValuePairs != null && message.hasOwnProperty("keyValuePairs"))
            object.keyValuePairs = $root.KeyValuePairs.toObject(message.keyValuePairs, options);
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.payload != null && message.hasOwnProperty("payload"))
            object.payload = message.payload;
        if (message.payloadType != null && message.hasOwnProperty("payloadType"))
            object.payloadType = options.enums === String ? $root.PayloadType[message.payloadType] === undefined ? message.payloadType : $root.PayloadType[message.payloadType] : message.payloadType;
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            object.serviceName = message.serviceName;
        return object;
    };

    Message.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Message.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Message";
    };

    return Message;
})();

export const RefreshMessage = $root.RefreshMessage = (() => {

    function RefreshMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    RefreshMessage.prototype.serviceIdentifier = "";
    RefreshMessage.prototype.serviceName = "";

    RefreshMessage.create = function create(properties) {
        return new RefreshMessage(properties);
    };

    RefreshMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.serviceIdentifier);
        writer.uint32(18).string(message.serviceName);
        return writer;
    };

    RefreshMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    RefreshMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RefreshMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.serviceIdentifier = reader.string();
                    break;
                }
            case 2: {
                    message.serviceName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("serviceIdentifier"))
            throw $util.ProtocolError("missing required 'serviceIdentifier'", { instance: message });
        if (!message.hasOwnProperty("serviceName"))
            throw $util.ProtocolError("missing required 'serviceName'", { instance: message });
        return message;
    };

    RefreshMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    RefreshMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.RefreshMessage)
            return object;
        let message = new $root.RefreshMessage();
        if (object.serviceIdentifier != null)
            message.serviceIdentifier = String(object.serviceIdentifier);
        if (object.serviceName != null)
            message.serviceName = String(object.serviceName);
        return message;
    };

    RefreshMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.serviceIdentifier = "";
            object.serviceName = "";
        }
        if (message.serviceIdentifier != null && message.hasOwnProperty("serviceIdentifier"))
            object.serviceIdentifier = message.serviceIdentifier;
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            object.serviceName = message.serviceName;
        return object;
    };

    RefreshMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    RefreshMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RefreshMessage";
    };

    return RefreshMessage;
})();

export const MessageIds = $root.MessageIds = (() => {

    function MessageIds(properties) {
        this.id = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MessageIds.prototype.id = $util.emptyArray;

    MessageIds.create = function create(properties) {
        return new MessageIds(properties);
    };

    MessageIds.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && message.id.length)
            for (let i = 0; i < message.id.length; ++i)
                writer.uint32(10).string(message.id[i]);
        return writer;
    };

    MessageIds.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MessageIds.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MessageIds();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.id && message.id.length))
                        message.id = [];
                    message.id.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MessageIds.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MessageIds.fromObject = function fromObject(object) {
        if (object instanceof $root.MessageIds)
            return object;
        let message = new $root.MessageIds();
        if (object.id) {
            if (!Array.isArray(object.id))
                throw TypeError(".MessageIds.id: array expected");
            message.id = [];
            for (let i = 0; i < object.id.length; ++i)
                message.id[i] = String(object.id[i]);
        }
        return message;
    };

    MessageIds.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.id = [];
        if (message.id && message.id.length) {
            object.id = [];
            for (let j = 0; j < message.id.length; ++j)
                object.id[j] = message.id[j];
        }
        return object;
    };

    MessageIds.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MessageIds.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MessageIds";
    };

    return MessageIds;
})();

export const Messages = $root.Messages = (() => {

    function Messages(properties) {
        this.message = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Messages.prototype.message = $util.emptyArray;

    Messages.create = function create(properties) {
        return new Messages(properties);
    };

    Messages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.message != null && message.message.length)
            for (let i = 0; i < message.message.length; ++i)
                $root.Message.encode(message.message[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    Messages.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Messages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Messages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.message && message.message.length))
                        message.message = [];
                    message.message.push($root.Message.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Messages.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Messages.fromObject = function fromObject(object) {
        if (object instanceof $root.Messages)
            return object;
        let message = new $root.Messages();
        if (object.message) {
            if (!Array.isArray(object.message))
                throw TypeError(".Messages.message: array expected");
            message.message = [];
            for (let i = 0; i < object.message.length; ++i) {
                if (typeof object.message[i] !== "object")
                    throw TypeError(".Messages.message: object expected");
                message.message[i] = $root.Message.fromObject(object.message[i]);
            }
        }
        return message;
    };

    Messages.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.message = [];
        if (message.message && message.message.length) {
            object.message = [];
            for (let j = 0; j < message.message.length; ++j)
                object.message[j] = $root.Message.toObject(message.message[j], options);
        }
        return object;
    };

    Messages.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Messages.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Messages";
    };

    return Messages;
})();

export const OverrideableMessage = $root.OverrideableMessage = (() => {

    function OverrideableMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    OverrideableMessage.prototype.serviceIdentifier = "";
    OverrideableMessage.prototype.serviceName = "";
    OverrideableMessage.prototype.payload = "";
    OverrideableMessage.prototype.payloadType = "";

    OverrideableMessage.create = function create(properties) {
        return new OverrideableMessage(properties);
    };

    OverrideableMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.serviceIdentifier);
        writer.uint32(18).string(message.serviceName);
        writer.uint32(26).string(message.payload);
        writer.uint32(34).string(message.payloadType);
        return writer;
    };

    OverrideableMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    OverrideableMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OverrideableMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.serviceIdentifier = reader.string();
                    break;
                }
            case 2: {
                    message.serviceName = reader.string();
                    break;
                }
            case 3: {
                    message.payload = reader.string();
                    break;
                }
            case 4: {
                    message.payloadType = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("serviceIdentifier"))
            throw $util.ProtocolError("missing required 'serviceIdentifier'", { instance: message });
        if (!message.hasOwnProperty("serviceName"))
            throw $util.ProtocolError("missing required 'serviceName'", { instance: message });
        if (!message.hasOwnProperty("payload"))
            throw $util.ProtocolError("missing required 'payload'", { instance: message });
        if (!message.hasOwnProperty("payloadType"))
            throw $util.ProtocolError("missing required 'payloadType'", { instance: message });
        return message;
    };

    OverrideableMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    OverrideableMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.OverrideableMessage)
            return object;
        let message = new $root.OverrideableMessage();
        if (object.serviceIdentifier != null)
            message.serviceIdentifier = String(object.serviceIdentifier);
        if (object.serviceName != null)
            message.serviceName = String(object.serviceName);
        if (object.payload != null)
            message.payload = String(object.payload);
        if (object.payloadType != null)
            message.payloadType = String(object.payloadType);
        return message;
    };

    OverrideableMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.serviceIdentifier = "";
            object.serviceName = "";
            object.payload = "";
            object.payloadType = "";
        }
        if (message.serviceIdentifier != null && message.hasOwnProperty("serviceIdentifier"))
            object.serviceIdentifier = message.serviceIdentifier;
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            object.serviceName = message.serviceName;
        if (message.payload != null && message.hasOwnProperty("payload"))
            object.payload = message.payload;
        if (message.payloadType != null && message.hasOwnProperty("payloadType"))
            object.payloadType = message.payloadType;
        return object;
    };

    OverrideableMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    OverrideableMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/OverrideableMessage";
    };

    return OverrideableMessage;
})();

export const PayloadType = $root.PayloadType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "text_xml"] = 1;
    values[valuesById[2] = "text_json"] = 2;
    values[valuesById[3] = "application_x_protobuf"] = 3;
    return values;
})();

export { $root as default };
