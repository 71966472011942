import React from "react";
import SendMediaView from "chat/components/sendMediaView/SendMediaView";
import { SendMediaData } from "chat/types";

type SendMediaToChatModalViewProps = {
  dismiss: VoidFunction;
  modalData: SendMediaData;
};

const SendMediaToChatModalView = ({
  modalData,
  dismiss,
}: SendMediaToChatModalViewProps) => (
  <SendMediaView onDismiss={dismiss} data={modalData} />
);

export default SendMediaToChatModalView;
