/* eslint-disable react/no-array-index-key */
import React, { FC, ReactElement } from "react";
import classnames from "classnames";
import { Typography } from "legal/imports/components";
import { TYPOGRAPHY_TYPE } from "legal/imports/constants";
import { useBreakpointPrecise } from "legal/imports/hooks";
import styles from "./Table.scss";

type CellType = ReactElement | string;

type RowType = CellType[];

type TableHeadItemProps = {
  item: string;
};

const TableHeadItem: FC<TableHeadItemProps> = ({ item }) => (
  <Typography type={TYPOGRAPHY_TYPE.HEADLINE6} as="th" className={styles.th}>
    {item}
  </Typography>
);

type TableRowProps = {
  row: CellType[];
};

const TableRow: FC<TableRowProps> = ({ row }) => (
  <tr className={styles.tr}>
    {row.map((item, i) => (
      <Typography
        key={i}
        type={TYPOGRAPHY_TYPE.PARAGRAPH4}
        as="td"
        className={styles.td}
      >
        {item}
      </Typography>
    ))}
  </tr>
);

type TableProps = {
  tbodyData: RowType[];
  theadData: string[];
};

const Table: FC<TableProps> = ({ theadData, tbodyData }) => {
  const breakpoint = useBreakpointPrecise();

  return (
    <div className={classnames(styles.tableContainer, styles[breakpoint])}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tr}>
            {theadData.map((h) => (
              <TableHeadItem key={h} item={h} />
            ))}
          </tr>
        </thead>
        <tbody>
          {tbodyData.map((row, i) => (
            <TableRow key={i} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
