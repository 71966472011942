import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ShowRoomStream } from "src/types/common";

const initialState: ShowRoomStream[] = [];

const showRoomStreamsSlice = createSlice({
  name: "showRoomStreams",
  initialState,
  reducers: {
    setShowRoomStreams: (_, action: PayloadAction<ShowRoomStream[]>) =>
      action.payload,
  },
});

export const {
  reducer: showRoomStreams,
  actions: { setShowRoomStreams },
} = showRoomStreamsSlice;
