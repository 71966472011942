import { createAction, createReducer } from "@reduxjs/toolkit";
import { TaskType } from "ui/navigation/deepLink/types";
import { Nullable } from "src/types/common";

const initialState = {
  queue: [],
};

interface BaseIntent {
  type:
    | TaskType.DEEP_LINK_INTENT_OPEN_PROFILE
    | TaskType.DEEP_LINK_INTENT_EDIT_PROFILE_SETTINGS
    | TaskType.DEEP_LINK_INTENT_OPEN_BRING_FRIENDS
    | TaskType.DEEP_LINK_INTENT_OPEN_MOBILE_SETTINGS
    | TaskType.DEEP_LINK_OPEN_NEARBY_WITH_REGISTRATION
    | TaskType.DEEP_LINK_OPEN_CREATORS_WITH_REGISTRATION;
}

interface OpenBuyCoinsIntent {
  type: TaskType.DEEP_LINK_INTENT_OPEN_BUY_COINS;
  payload?: {
    provider?: string;
    lockToProvider?: boolean;
    messageUuid?: Nullable<string>;
    paymentSummary?: Nullable<string>;
  };
}

interface OpenOnboardingLandingIntent {
  type: TaskType.DEEP_LINK_INTENT_OPEN_ONBOARDING_LANDING;
}

interface ConnectedAccountsIntent {
  type: TaskType.DEEP_LINK_INTENT_OPEN_CONNECTED_ACCOUNTS;
  payload: {
    instagramFlow: boolean;
  };
}

type Intent =
  | BaseIntent
  | ConnectedAccountsIntent
  | OpenOnboardingLandingIntent
  | OpenBuyCoinsIntent;

export const actionCreators = {
  addQueueItem: createAction<Intent>("lwc/taskmanager/add"),
  processFirstQueueItem: createAction("lwc/taskmanager/processFirst"),
};

export interface TaskManagerState {
  queue: Intent[];
}

const reducer = createReducer<TaskManagerState>(initialState, (builder) => {
  builder
    .addCase(actionCreators.processFirstQueueItem, (state) => {
      state.queue.shift();
    })
    .addCase(actionCreators.addQueueItem, (state, action) => {
      state.queue.push(action.payload);
    });
});

export default reducer;

export const selectors = {
  getFirstQueueItem: (state: TaskManagerState) => state.queue[0],
};
