import { createSelector } from "@reduxjs/toolkit";
import type {
  GroupedGames,
  SocialGamesListGameDto,
} from "src/features/socialGames/common/types";
import {
  ALLOWED_GAMES_SECTIONS,
  DEFAULT_SUGGESTED_GAMES_COUNT,
} from "src/features/socialGames/common/constants";
import {
  SocialGamesResponseCode,
  SocialGamesSectionType,
} from "src/features/socialGames/common/enums";
import type { SocialGamesGameState } from "./gameSlice";
import type { SocialGamesInStreamLobbyState } from "./inStreamLobbySlice";
import type { SocialGamesLobbyState } from "./lobbySlice";

const getSearchValue = (state: SocialGamesLobbyState) =>
  state.search.searchValue;

const getAllGames = (
  state: SocialGamesLobbyState
): Array<SocialGamesListGameDto> =>
  ALLOWED_GAMES_SECTIONS.reduce(
    (
      games: Array<SocialGamesListGameDto>,
      gameSection: SocialGamesSectionType
    ) => {
      const sectionGames = state.sections.find(
        (item) => item.section === gameSection
      );

      if (sectionGames?.games.length) {
        return [...games, ...sectionGames.games];
      }

      return games;
    },
    []
  );

const getUniqueGames = createSelector(
  getAllGames,
  (allGames: Array<SocialGamesListGameDto>): Array<SocialGamesListGameDto> => {
    const resultUniqueGames = [];
    const uniqueIds = new Set<string>();
    for (let i = 0; i < allGames.length; i++) {
      if (!uniqueIds.has(allGames[i].id)) {
        resultUniqueGames.push(allGames[i]);
        uniqueIds.add(allGames[i].id);
      }
    }

    return resultUniqueGames;
  }
);

const getSuggestedUniqueGames = createSelector(
  getUniqueGames,
  (uniqueGames: Array<SocialGamesListGameDto>) =>
    uniqueGames.slice(0, DEFAULT_SUGGESTED_GAMES_COUNT)
);

const getSearchedGames = createSelector(
  getSearchValue,
  getUniqueGames,
  (search, games) =>
    search
      ? games.filter(
          (game) =>
            game.name.toLowerCase().includes(search.toLowerCase()) ||
            game.category.toLowerCase().includes(search.toLowerCase())
        )
      : []
);

export const socialGamesLobbySelectors = {
  getLoading: (state: SocialGamesLobbyState) => state.loading,
  getError: (state: SocialGamesLobbyState) => state.error,
  getResponseCode: (state: SocialGamesLobbyState) => state.responseCode,
  getSections: (state: SocialGamesLobbyState) => state.sections,
  getSection: (
    state: SocialGamesLobbyState,
    section?: SocialGamesSectionType
  ) => state.sections.find((item) => item.section === section)?.games || [],
  getIsLoadingFailed: (state: SocialGamesLobbyState) =>
    (state.responseCode !== null &&
      state.responseCode !== SocialGamesResponseCode.SUCCESS) ||
    !!state.error,
  getVisibleGames: (state: SocialGamesLobbyState) => state.visibleGamesIds,
  getGamesForAnalytics: (state: SocialGamesLobbyState) => {
    const filteredGames = state.visibleGamesIds.filter(
      (game) =>
        !(
          state.alreadySentToAnalyticsGamesIds[game.itemType] &&
          state.alreadySentToAnalyticsGamesIds[game.itemType].includes(game.id)
        )
    );

    return filteredGames.reduce<GroupedGames>((grouped, game): GroupedGames => {
      if (!grouped[game.itemType]) {
        grouped[game.itemType] = [];
      }

      grouped[game.itemType].push(game.id);

      return grouped;
    }, {});
  },
  getIsLoaded: (state: SocialGamesLobbyState) =>
    state.responseCode === SocialGamesResponseCode.SUCCESS && !state.loading,
  getSearchValue,
  getSearchVisibility: (state: SocialGamesLobbyState) =>
    state.search.searchVisibility,
  getSuggestedGames: getSuggestedUniqueGames,
  getSearchedGames,
  getGameName: (state: SocialGamesLobbyState, gameId?: string) => {
    for (const section of state.sections) {
      for (const game of section.games) {
        if (game.id === gameId) {
          return game.name;
        }
      }
    }

    return gameId ?? "";
  },
  getRecentlyPlayedGamesUpdateStatus: (state: SocialGamesLobbyState) =>
    state.recentlyPlayedSectionUpdated,
};

export const socialGamesInStreamLobbySelectors = {
  getLoading: (state: SocialGamesInStreamLobbyState) => state.loading,
  getError: (state: SocialGamesInStreamLobbyState) => state.error,
  getResponseCode: (state: SocialGamesInStreamLobbyState) => state.responseCode,
  getGames: (state: SocialGamesInStreamLobbyState) => state.games,
  getGameId: (state: SocialGamesInStreamLobbyState) => state.gameId,
};

export const socialGamesGameSelectors = {
  getLoading: (state: SocialGamesGameState) => state.loading,
  getError: (state: SocialGamesGameState) => state.error,
  getResponseCode: (state: SocialGamesGameState) => state.responseCode,
  getLaunchScript: (state: SocialGamesGameState) => state.gameLaunchScript,
  getIsLoadingFailed: (state: SocialGamesGameState) =>
    (state.responseCode !== null &&
      state.responseCode !== SocialGamesResponseCode.SUCCESS) ||
    !!state.error,
  getIsLoaded: (state: SocialGamesGameState) =>
    state.responseCode === SocialGamesResponseCode.SUCCESS && !state.loading,
};
