import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Datadog from "@analytics/DatadogUtils";
import { fetchVideoToken } from "api/videoToken";
import { getVideoTokenEnabled, getVideoTokenPeriod } from "environment";
import { useLayoutEffectOnce } from "src/utils/miniReactUse";
import { changeUsername } from "state/actionCreators/connectionManager";
import { RootState } from "state/delegate";
import { connectionManagerSelectors } from "state/selectors";

const selector = (state: RootState) => ({
  usernameData: connectionManagerSelectors.getUsername(state),
});

const VideoTokenLoader: React.FC = () => {
  const dispatch = useDispatch();
  const { usernameData } = useSelector(selector, shallowEqual);

  useLayoutEffectOnce(() => {
    if (!getVideoTokenEnabled()) {
      return;
    }

    const getToken = () => {
      fetchVideoToken()
        .then(({ username }) => {
          Datadog.setGlobalContext({
            username,
          });

          if (!username || usernameData === username) {
            return;
          }

          dispatch(changeUsername(username));
        })
        .catch(() => {
          // noop
        });
    };

    getToken();

    const interval = window.setInterval(getToken, getVideoTokenPeriod());

    return () => {
      window.clearInterval(interval);
    };
  });

  return null;
};

export default VideoTokenLoader;
