import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { RootState } from "state/delegate";
import { fetchCurrenciesConfig } from "api/currency";
import { isApiError } from "api/utils/enhancedFetch";
import { CurrencyInfo, CurrencyList } from "ui/common/currencyPicker/types";
import { currenciesSelectors, settingsSelectors } from "state/selectors";
import { SELECTED_CURRENCY } from "src/constants";
import { Currency } from "src/enums";

export const selectCurrency = createAction<CurrencyInfo["selected"]>(
  "lwc/currency/select"
);
export const currenciesOverride = createAction<CurrencyInfo["override"]>(
  "lwc/currency/override"
);
export const currenciesSetWhiteList = createAction<CurrencyInfo["whiteList"]>(
  "lwc/currency/setWhiteList"
);
export const staleCurrencies = createAction<boolean>("lwc/currency/setStale");

export const fetchCurrencies = createAsyncThunk<
  Pick<CurrencyInfo, "options" | "recommended" | "selected">,
  FetcherMetaV2 | void,
  { state: RootState; rejectValue: string }
>(
  "lwc/currencies/fetch",
  async (args, { getState, rejectWithValue }) => {
    const state = getState();
    const selected = settingsSelectors.getPreference(
      state,
      SELECTED_CURRENCY
    ) as Currency;
    try {
      const { all, recommended } = await fetchCurrenciesConfig();

      return {
        options: all.map((x: string) => x.toUpperCase()) as CurrencyList,
        recommended: recommended.toUpperCase() as Currency,
        selected,
      };
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;
      return rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta = currenciesSelectors.meta(state);

      return !meta.loading && meta.stale;
    },
  }
);
