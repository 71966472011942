import { Nullable } from "src/types/common";
import { AsyncState } from "state/hor/addFetcherActionsToBuilder";

export enum BannerType {
  CASHIER_NEW = "CASHIER_NEW",
  CHATS = "CHATS",
  FOLLOWING = "FOLLOWING",
  LIVE_GRID = "LIVE_GRID",
  REFILL = "REFILL",
  WEB_BANNER = "WEB_BANNER",
}

export enum BannerLiveGridTabType {
  BATTLE = "BATTLE",
  CREATORS = "CREATORS",
  GAMES = "GAMES",
  LANDSCAPE = "LANDSCAPE",
  LIVEPARTY = "LIVEPARTY",
  NEARBY = "NEARBY",
  NEW = "NEW",
  POPULAR = "POPULAR",
  RECOMMENDED = "RECOMMENDED",
  RISING_STARS = "RISING_STARS",
  TOURNAMENTS = "TOURNAMENTS",
}

export enum ActionType {
  DEEPLINK = "DEEPLINK",
  LANDING_PAGE = "LANDING_PAGE",
  NONE = "NONE",
  URL = "URL",
}

export interface BannerConfigDimension {
  height: number;
  width: number;
}

export interface BannerWebImageConfiguration {
  imageUrlDesktop?: Nullable<string>;
  imageUrlMobile?: Nullable<string>;
  imageUrlTablet?: Nullable<string>;
}

export interface BannerConfig extends BannerWebImageConfiguration {
  action: {
    actionType: ActionType;
    autoLogin: boolean;
    link: string;
  };
  bannerType: string;
  displayConditions: {
    duration: number;
    impressionLimit: number;
    purchaseLimit: number;
  };
  displaySection: {
    liveGridTabs: BannerLiveGridTabType[];
    position: string;
    type: BannerType;
  };
  enabled: boolean;
  endTs: number;
  id: string;
  imageDimension: BannerConfigDimension;
  imageUrl: string;
  name: string;
  startTs: number;
  trackingId: string;
}

export type BannerConfigsState = AsyncState<
  Record<BannerType, BannerConfig[]>,
  string
>;
