import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { ReactComponent as ConversationIcon } from "chat/assets/ic_conversation.svg";
import { Typography } from "chat/imports/components";
import { Breakpoints, TYPOGRAPHY_TYPE } from "chat/imports/constants";
import styles from "./MessageRequestsEmptyState.scss";

interface MessageRequestsEmptyStateProps {
  breakpoint: Breakpoints;
}

export const MessageRequestsEmptyState: FC<MessageRequestsEmptyStateProps> = ({
  breakpoint,
}) => (
  <div className={classnames(styles.root, styles[breakpoint])}>
    <ConversationIcon className={styles.icon} />
    <Typography
      type={TYPOGRAPHY_TYPE.HEADLINE3}
      as="div"
      className={styles.title}
    >
      <FormattedMessage
        id="no_message_requests"
        defaultMessage="No Message Requests"
      />
    </Typography>
    <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3} as="div">
      <FormattedMessage
        id="chat.empty.messageRequests.description"
        defaultMessage="You currently have no message requests"
      />
    </Typography>
  </div>
);
