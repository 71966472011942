import { MutableRefObject, useEffect } from "react";
import intersectionObserver from "src/utils/intersectionObserver";
import { Nullable, VoidCallback } from "src/types/common";

const listenerCallbacks = new WeakMap();

const observer = intersectionObserver(
  (entries, obs) => {
    entries.forEach((entry) => {
      if (listenerCallbacks.has(entry.target)) {
        const cb = listenerCallbacks.get(entry.target);

        if (entry.isIntersecting) {
          obs.unobserve(entry.target);
          listenerCallbacks.delete(entry.target);
          cb();
        }
      }
    });
  },
  {
    threshold: 0,
    rootMargin: "5px 0px 0px 0px",
  }
);

export const useLazyLoadImage = <TElementType extends Nullable<HTMLElement>>(
  ref: MutableRefObject<TElementType>,
  callback: VoidCallback
) => {
  useEffect(() => {
    if (!observer) {
      return;
    }

    const target = ref.current;
    if (target) {
      listenerCallbacks.set(target, callback);
      observer.observe(target);

      return () => {
        listenerCallbacks.delete(target);
        observer.unobserve(target);
      };
    }
  }, [ref]);
};
