import React, { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { PurchaseNowOffer } from "src/features/welcomeOnboardingOffer/components/PurchaseNowOffer/PurchaseNowOffer";
import { ReactComponent as BannerImage } from "src/features/welcomeOnboardingOffer/components/WelcomeOnboardingOffer/woo_banner.svg";
import { WelcomeRadialBackground } from "src/features/welcomeOnboardingOffer/components/WelcomeRadialBackground/WelcomeRadialBackground";
import { useWelcomeOffer } from "src/features/welcomeOnboardingOffer/hooks/useWelcomeOffer";
import {
  BI_LANDING_FIELD,
  EventFields,
  PERSONAL_OFFER_TYPE,
  PURCHASE_ORIGIN,
  SCREEN_STATES,
  ScreenViewReporter,
  UIActionTarget,
  emitUiAction,
} from "src/features/welcomeOnboardingOffer/imports/analytics";
import {
  Breakpoints,
  BuyCoinsModalCloseReason,
  CASHIER,
  GLOBAL,
  ModalScreenName,
  ModalType,
} from "src/features/welcomeOnboardingOffer/imports/enums";
import {
  getIsPaymentFlowV2Enabled,
  openBuyCoinsModal,
  openCheckoutBottomScreen,
  openCheckoutModal,
  openModal,
  openRefillDrawerBottomScreen,
} from "src/features/welcomeOnboardingOffer/imports/state";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  LottieAnimation,
  TYPOGRAPHY_TYPE,
  Typography,
  sharedMessages,
  useAppDispatch,
  useBreakpointPrecise,
} from "src/features/welcomeOnboardingOffer/imports/ui";
import styles from "./WelcomeOnboardingOffer.scss";

interface WelcomeOnboardingOfferProps {
  dismiss: VoidFunction;
}

export const WelcomeOnboardingOffer: FC<WelcomeOnboardingOfferProps> = ({
  dismiss,
}) => {
  const dispatch = useAppDispatch();
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const {
    data: {
      offer,
      offerPricePoint,
      bonus,
      currencyPrice,
      bonusPercentage,
      lottieSettings,
      color,
    },
    welcomeOfferAnalytics,
  } = useWelcomeOffer();

  const isPaymentFlowV2Enabled = useSelector(getIsPaymentFlowV2Enabled);

  const additionalParams = useMemo(
    () => ({
      [EventFields.SCREEN_STATE]: SCREEN_STATES.OFFER,
      [EventFields.SOURCE]: PERSONAL_OFFER_TYPE.WELCOME_OFFER_NEWCOMER,
      [BI_LANDING_FIELD.PRICE]: offerPricePoint?.price || 0,
      [BI_LANDING_FIELD.AMOUNT]: offer?.coins,
    }),
    [offer, offerPricePoint]
  );

  const handlePurchaseClick = useCallback(() => {
    emitUiAction({
      target: UIActionTarget.WELCOME_OFFER_CLICKED,
      tango_screen: {
        reportedName: ModalScreenName.WELCOME_ONBOARDING_OFFER,
      },
      additionalParams,
    });

    emitUiAction({
      target: UIActionTarget.OFFER_CLICKED,
      tango_screen: {
        reportedName: ModalScreenName.WELCOME_ONBOARDING_OFFER,
      },
      additionalParams: {
        ...welcomeOfferAnalytics,
      },
    });

    dismiss();

    const data = {
      purchaseSource: PURCHASE_ORIGIN.WEB_NATIVE_LANDING_PAGE,
      purchaseOrigin: PURCHASE_ORIGIN.WEB_NATIVE_LANDING_PAGE,
      viewType: CASHIER,
      offer: offerPricePoint,
      shouldDismissOnBack: true,
      onDismiss: (reason: BuyCoinsModalCloseReason) => {
        if (reason === BuyCoinsModalCloseReason.BACK) {
          dispatch(
            openModal({
              modalType: ModalType.WELCOME_ONBOARDING_OFFER,
              modalData: {},
              modalScope: GLOBAL,
            })
          );
        }
      },
      analyticsParams: {
        [BI_LANDING_FIELD.TRIGGER_ID]:
          welcomeOfferAnalytics[BI_LANDING_FIELD.TRIGGER_ID],
        [BI_LANDING_FIELD.SERVER_OFFER_ID]:
          welcomeOfferAnalytics[BI_LANDING_FIELD.SERVER_OFFER_ID],
        [EventFields.CAMPAIGN_ID]:
          welcomeOfferAnalytics[EventFields.CAMPAIGN_ID],
      },
    };

    if (isDesktop) {
      dispatch(
        isPaymentFlowV2Enabled
          ? openCheckoutModal(data)
          : // TODO: https://tango-me.atlassian.net/browse/WEB-5885
            // @ts-expect-error
            openBuyCoinsModal(data)
      );
    } else {
      dispatch(
        (isPaymentFlowV2Enabled
          ? openCheckoutBottomScreen
          : openRefillDrawerBottomScreen)({
          screenData: data,
        })
      );
    }
  }, [
    additionalParams,
    dismiss,
    dispatch,
    isDesktop,
    offerPricePoint,
    welcomeOfferAnalytics,
  ]);

  const handleSkipClick = useCallback(() => {
    emitUiAction({
      target: UIActionTarget.SKIP,
      tango_screen: {
        reportedName: ModalScreenName.WELCOME_ONBOARDING_OFFER,
      },
      additionalParams: additionalParams || {},
    });

    dismiss();
  }, [additionalParams, dismiss]);

  if (!offer || !currencyPrice) {
    return (
      <ScreenViewReporter
        name={ModalScreenName.WELCOME_ONBOARDING_OFFER}
        additionalParams={{
          [EventFields.SCREEN_STATE]: SCREEN_STATES.LOADING,
        }}
      />
    );
  }

  return (
    <div
      className={classnames(styles.root, styles[breakpoint])}
      data-testid="welcome-onboarding-offer-component"
    >
      <ScreenViewReporter
        name={ModalScreenName.WELCOME_ONBOARDING_OFFER}
        additionalParams={{
          [EventFields.SCREEN_STATE]: SCREEN_STATES.OFFER,
        }}
      />
      <WelcomeRadialBackground
        className={styles.offerBackground}
        color={color}
      />
      <LottieAnimation
        lottieConfig={lottieSettings}
        className={styles.animationLayer}
      />
      <div className={styles.content}>
        <div className={styles.buttonContainer} />
        <div className={styles.offer}>
          <div className={styles.title} data-testid="welcome-title-block">
            <BannerImage className={styles.banner} />
            {Boolean(bonus) && (
              <Typography
                className={styles.value}
                type={TYPOGRAPHY_TYPE.BANGO_HERO3}
              >
                {bonusPercentage}
              </Typography>
            )}
          </div>
          <PurchaseNowOffer
            currencyPrice={currencyPrice}
            bonus={bonus}
            coins={offer.coins}
            onClick={handlePurchaseClick}
            analyticsData={welcomeOfferAnalytics}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            data-testid="skip-button"
            size={isDesktop ? ButtonSize.BIG_48 : ButtonSize.MEDIUM_32}
            variant={
              isDesktop
                ? ButtonVariant.SECONDARY_ON_SHADER
                : ButtonVariant.TERTIARY_ON_SHADER
            }
            onClick={handleSkipClick}
          >
            <Typography type={TYPOGRAPHY_TYPE.HEADLINE2}>
              <FormattedMessage {...sharedMessages.skip} />
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
