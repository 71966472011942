import { createAsyncThunk } from "@reduxjs/toolkit";
import { FETCH_WELCOME_ONBOARDING_OFFER } from "src/features/welcomeOnboardingOffer/constants";
import {
  BI_LANDING_FIELD,
  EventNames,
  PERSONAL_OFFERS_SOURCE,
  emitEvent,
} from "src/features/welcomeOnboardingOffer/imports/analytics";
import {
  fetchPersonalOffers,
  isApiError,
} from "src/features/welcomeOnboardingOffer/imports/api";
import { PersonalOfferType } from "src/features/welcomeOnboardingOffer/imports/enums";
import { WelcomeOnboardingOfferState } from "src/features/welcomeOnboardingOffer/state/welcomeOfferSlice";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";

interface GetRemoteConfigParams {
  campaignId: string;
}

export const loadWelcomeOnboardingOffer = createAsyncThunk<
  Data<WelcomeOnboardingOfferState>,
  FetcherMetaV2 & GetRemoteConfigParams,
  { rejectValue: string; state: RootState }
>(FETCH_WELCOME_ONBOARDING_OFFER, async ({ campaignId }, api) => {
  try {
    const startTimestamp = Date.now();

    const config = await fetchPersonalOffers(
      PersonalOfferType.LANDING_PAGE,
      campaignId
    );

    if (config) {
      emitEvent(EventNames.PERSONAL_OFFERS_RECEIVED, {
        [BI_LANDING_FIELD.TRIGGER_ID]: config.triggerId,
        [BI_LANDING_FIELD.SOURCE]:
          PERSONAL_OFFERS_SOURCE.LANDING_PAGE.toLowerCase(),
        [BI_LANDING_FIELD.TIME_LOADING]: Date.now() - startTimestamp,
      });

      return config;
    }

    const error = "NOT FOUND";
    const errorEventParams = {
      [BI_LANDING_FIELD.COMMENT]:
        PERSONAL_OFFERS_SOURCE.LANDING_PAGE.toLowerCase(),
      [BI_LANDING_FIELD.REASON]: error,
    };

    emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);
    return api.rejectWithValue(error);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    const errorEventParams = {
      [BI_LANDING_FIELD.COMMENT]:
        PERSONAL_OFFERS_SOURCE.LANDING_PAGE.toLowerCase(),
      [BI_LANDING_FIELD.REASON]: error,
    };

    emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);

    return api.rejectWithValue(error);
  }
});
