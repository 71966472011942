import { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { FROM_FLOW } from "enums/modalDismissReason";
import { ModalType } from "src/enums";
import { getCaptchaLockingEnabled } from "src/environment";
import { CaptchaType } from "src/features/signin/imports/ui";
import { hideBottomScreen } from "state/actionCreators/bottomScreen";
import { setLockedState } from "state/actionCreators/captcha";
import {
  dismissModalWithType,
  lockTopModal,
  openCaptchaModalView,
  unlockTopModal,
} from "state/actionCreators/modal";
import { setCaptchaConfig } from "state/actionCreators/networkError";
import { refreshLockedState } from "state/flows/captcha";
import { captchaSelectors, loginSelectors } from "state/selectors";

const CaptchaController = () => {
  const captchaRequired =
    useSelector(captchaSelectors.isLockedByCaptcha) &&
    getCaptchaLockingEnabled();
  const isAuthorized = useSelector(loginSelectors.isAuthorized);
  const dispatch = useDispatch();

  useEffect(() => {
    if (captchaRequired && !isAuthorized) {
      batch(() => {
        dispatch(
          setCaptchaConfig({
            type: CaptchaType.COMMON,
          })
        );
        dispatch(setLockedState(false));
      });
    }

    if (captchaRequired && isAuthorized) {
      dispatch(refreshLockedState());
    }
  }, []);

  useEffect(() => {
    batch(() => {
      if (captchaRequired) {
        dispatch(openCaptchaModalView());
        dispatch(hideBottomScreen());
        dispatch(lockTopModal());
      } else {
        dispatch(unlockTopModal());
        dispatch(
          dismissModalWithType({
            modalType: ModalType.CAPTCHA_MODAL,
            modalDismissReason: FROM_FLOW,
          })
        );
      }
    });
  }, [captchaRequired]);

  return null;
};

export default CaptchaController;
