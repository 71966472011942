import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "state/delegate";
import {
  countriesSelectors,
  countryByIpSelectors,
  visitorSelectors,
} from "state/selectors";

const selector = (state: RootState) => ({
  countryByIp: countryByIpSelectors.data(state),
  visitor: visitorSelectors.data(state),
});

const useGetInitialCountryInfo = () => {
  const {
    countryByIp,
    visitor: { countryCode },
  } = useSelector(selector, shallowEqual);

  return useSelector((state: RootState) =>
    countriesSelectors.getCountryByIsoCode(state, countryByIp || countryCode)
  );
};

export default useGetInitialCountryInfo;
