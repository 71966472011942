import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { Taxes } from "src/types/common";
import { Currency } from "src/enums";

export const fetchSalesTax = (currency: Currency): Promise<Taxes> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/tax?currency=${currency}`
  ).then((resp) => resp.json());

export const fetchSalesTaxV2 = (
  currency: Currency,
  marketOfferId: string
): Promise<Taxes> =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/tax/v2/multiplier`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      currency,
      marketOfferId,
    }),
  }).then((resp) => resp.json());
