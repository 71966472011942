import { sendEventToGateway } from "src/core/analytics/api/DataMeshEventsApi";
import { ALLOWED_GATEWAY_EVENTS } from "src/core/analytics/plugins/dataMesh/constants";
import { EventNames } from "src/core/analytics/plugins/dataMesh/enums";
import { getInMemoryStore } from "utils/getStoreHandler";

interface Payload {
  event: string;
  properties: { [key: string]: unknown };
  type: string;
}

export default () => ({
  NAMESPACE: "provider-datamesh",
  track: ({ payload }: { payload: Payload }) => {
    if (!ALLOWED_GATEWAY_EVENTS.includes(<EventNames>payload.event)) {
      return;
    }

    sendEventToGateway({
      isUserLoggedIn: getInMemoryStore()?.login?.loggedIn,
      name: payload.event,
      payload: payload.properties,
    });
  },
  loaded: () => true,
});
