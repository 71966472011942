import { MIN_AGE_TO_BROADCAST } from "src/constants";
import { useSelector } from "react-redux";
import { getMyAgeSelector } from "state/selectors";
import { getAntMediaServerWebsocketUrl } from "environment";

export default () => {
  const antMediaServerWebsocketUrl = getAntMediaServerWebsocketUrl();
  const myAge = useSelector(getMyAgeSelector);

  return (
    !!antMediaServerWebsocketUrl && (!myAge || myAge > MIN_AGE_TO_BROADCAST)
  );
};
