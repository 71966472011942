import {
  ACME_RECEIVED,
  SEND_MESSAGE_TO_SESSION_END,
  VIEWER_SESSION_INITIALIZATION_END,
  VIEWER_SESSION_RESET,
} from "state/actionTypes";
import { StreamSessionInitializationResult } from "src/enums";

const initialState = {
  initResult: StreamSessionInitializationResult.NOT_INITIALIZED,
  kickedOut: false,
  paywall: false,
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      const { streamId } = context;
      if (action.payload === streamId || action.meta.multibroadcastSwitch) {
        return { ...initialState, initResult: state.initResult };
      }
      return initialState;
    }

    case VIEWER_SESSION_INITIALIZATION_END: {
      return {
        ...initialState,
        initResult: action.payload,
        kickedOut:
          action.payload === StreamSessionInitializationResult.KICKED_OUT,
      };
    }
    case ACME_RECEIVED: {
      const { serviceIdentifier } = action.payload;
      const [command] = serviceIdentifier.split(":");
      if (command === "hasFreeMessage") {
        return { ...state, paywall: false };
      }
      if (command === "kickout") {
        return { ...state, kickedOut: true };
      }
      return state;
    }
    case SEND_MESSAGE_TO_SESSION_END: {
      if (action.meta && action.meta.paywall) {
        return { ...state, paywall: action.meta.paywall };
      }
      return state;
    }
  }
  return state;
};

export const selectors = {
  getInitializationResult: (state) => state.initResult,
  isKickedOut: (state) => state.kickedOut,
  hasPaywall: (state) => state.paywall,
};
