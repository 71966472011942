import emit from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";

export const SHARE_TARGET_COPY_LINK = "copy_link";
export const SHARE_TARGET_MORE = "more";
export const SHARE_TYPE_LINK = "link";

const shareSourceConfig = {
  referral: "referral",
  happy_moments: "happy_moments",
  "profile.my_own": "profile",
  "profile.other": "profile",
  "profile.live.player.public": "stream",
  "live.player.public": "stream",
};

export const emitShareAction = ({
  target,
  screen,
  shareType = SHARE_TYPE_LINK,
  peerId,
  sourceId,
}) => {
  emit(EventNames.SHARE_EVENT, {
    [EventFields.SOURCE]: shareSourceConfig[screen?.name],
    [EventFields.TARGET]: target,
    [EventFields.SHARE_TYPE]: shareType,
    [EventFields.PEER_ID]: peerId,
    [EventFields.SHARE_SOURCE_ID]: sourceId,
  });
};
