import { OneClickOfferTypes } from "enums/oneClickOffers";

export const getOneClickV2AnalyticsData = (
  offerType: OneClickOfferTypes | undefined,
  recipientAccountId: string,
  locationId: string
) => {
  switch (offerType) {
    case OneClickOfferTypes.ONE_CLICK_MESSAGES: {
      return { chat_id: locationId };
    }
    case OneClickOfferTypes.ONE_CLICK_IN_STREAM:
    case OneClickOfferTypes.ONE_CLICK_PREMIUM_IN_STREAM: {
      return { stream_id: locationId };
    }
    case OneClickOfferTypes.ONE_CLICK_PROFILE: {
      return {
        profile_id: recipientAccountId,
        post_id: locationId,
      };
    }
    default: {
      return {};
    }
  }
};
