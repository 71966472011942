import { EventFields } from "@analytics/enums";
import streamKindToAnalyticsStreamKind from "@analytics/streamKindToAnalyticsStreamKind";
import { HAPPY_MOMENTS, POST, PROFILE, STREAM } from "enums/giftRecipientType";
import {
  giftingRequestsSelectors,
  giftsCacheSelectors,
  settingsSelectors,
  viewerSessionSelectors,
} from "state/selectors";
import { giftRecipientTypeToGiftSource } from "./giftSource";

export default (state, giftId) => {
  const recipientType = giftingRequestsSelectors.getRecipientType(state);
  const recipientAccountId =
    giftingRequestsSelectors.getRecipientAccountId(state);
  const secondaryRecipientId =
    giftingRequestsSelectors.getSecondaryRecipientId(state);
  const oneClickGiftingEnabled =
    settingsSelectors.isOneClickGiftingEnabled(state);
  const giftOption = giftsCacheSelectors.getOptionLastClickedGift(state);

  const giftCategory = giftsCacheSelectors.getCategoryById(
    state,
    giftOption.categoryId
  );

  const analyticParams = {
    [EventFields.GIFT_SOURCE]: giftRecipientTypeToGiftSource(recipientType),
    [EventFields.GIFT_ID]: giftId,
    [EventFields.ONE_CLICK_GIFTING]: oneClickGiftingEnabled ? 1 : 0,
    [EventFields.ONE_CLICK_PURCHASE]: 0,
    [EventFields.ITEM_TYPE]: giftCategory?.name,
  };

  switch (recipientType) {
    case PROFILE: {
      analyticParams[EventFields.PROFILE_ID] = recipientAccountId;
      break;
    }
    case POST: {
      analyticParams[EventFields.FEED_POST_ID] = secondaryRecipientId;
      analyticParams[EventFields.FEED_POST_AUTHOR_ID] = recipientAccountId;
      break;
    }
    case STREAM: {
      analyticParams[EventFields.STREAM_ID] = secondaryRecipientId;
      analyticParams[EventFields.STREAM_KIND] = streamKindToAnalyticsStreamKind(
        viewerSessionSelectors.getStreamKind(state, secondaryRecipientId)
      );
      analyticParams[EventFields.ITEM_TYPE] =
        giftCategory?.name || "moods_on_screen";
      analyticParams[EventFields.POSITION] = giftOption.positionIndex + 1;

      break;
    }
    case HAPPY_MOMENTS: {
      analyticParams[EventFields.HAPPY_MOMENT_ID] = secondaryRecipientId;
      break;
    }
  }

  return analyticParams;
};
