/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.CashierPersonalOffer || ($protobuf.roots.CashierPersonalOffer = {});

export const CurrencyPrice = $root.CurrencyPrice = (() => {

    function CurrencyPrice(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CurrencyPrice.prototype.currency = "";
    CurrencyPrice.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    CurrencyPrice.create = function create(properties) {
        return new CurrencyPrice(properties);
    };

    CurrencyPrice.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
            writer.uint32(10).string(message.currency);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            writer.uint32(16).int64(message.price);
        return writer;
    };

    CurrencyPrice.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CurrencyPrice.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CurrencyPrice();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.currency = reader.string();
                    break;
                }
            case 2: {
                    message.price = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    CurrencyPrice.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CurrencyPrice.fromObject = function fromObject(object) {
        if (object instanceof $root.CurrencyPrice)
            return object;
        let message = new $root.CurrencyPrice();
        if (object.currency != null)
            message.currency = String(object.currency);
        if (object.price != null)
            if ($util.Long)
                (message.price = $util.Long.fromValue(object.price)).unsigned = false;
            else if (typeof object.price === "string")
                message.price = parseInt(object.price, 10);
            else if (typeof object.price === "number")
                message.price = object.price;
            else if (typeof object.price === "object")
                message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
        return message;
    };

    CurrencyPrice.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.currency = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.price = options.longs === String ? "0" : 0;
        }
        if (message.currency != null && message.hasOwnProperty("currency"))
            object.currency = message.currency;
        if (message.price != null && message.hasOwnProperty("price"))
            if (typeof message.price === "number")
                object.price = options.longs === String ? String(message.price) : message.price;
            else
                object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber() : message.price;
        return object;
    };

    CurrencyPrice.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CurrencyPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CurrencyPrice";
    };

    return CurrencyPrice;
})();

export const Offer = $root.Offer = (() => {

    function Offer(properties) {
        this.currencyPrice = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Offer.prototype.offerSku = "";
    Offer.prototype.bonus = 0;
    Offer.prototype.coins = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Offer.prototype.category = "";
    Offer.prototype.marketOfferId = "";
    Offer.prototype.offerDescription = "";
    Offer.prototype.stwEnabled = false;
    Offer.prototype.currencyPrice = $util.emptyArray;
    Offer.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    Offer.create = function create(properties) {
        return new Offer(properties);
    };

    Offer.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.offerSku);
        writer.uint32(17).double(message.bonus);
        if (message.coins != null && Object.hasOwnProperty.call(message, "coins"))
            writer.uint32(24).int64(message.coins);
        if (message.category != null && Object.hasOwnProperty.call(message, "category"))
            writer.uint32(34).string(message.category);
        writer.uint32(42).string(message.marketOfferId);
        writer.uint32(50).string(message.offerDescription);
        if (message.stwEnabled != null && Object.hasOwnProperty.call(message, "stwEnabled"))
            writer.uint32(56).bool(message.stwEnabled);
        if (message.currencyPrice != null && message.currencyPrice.length)
            for (let i = 0; i < message.currencyPrice.length; ++i)
                $root.CurrencyPrice.encode(message.currencyPrice[i], writer.uint32(66).fork()).ldelim();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(88).int64(message.id);
        return writer;
    };

    Offer.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Offer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Offer();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.offerSku = reader.string();
                    break;
                }
            case 2: {
                    message.bonus = reader.double();
                    break;
                }
            case 3: {
                    message.coins = reader.int64();
                    break;
                }
            case 4: {
                    message.category = reader.string();
                    break;
                }
            case 5: {
                    message.marketOfferId = reader.string();
                    break;
                }
            case 6: {
                    message.offerDescription = reader.string();
                    break;
                }
            case 7: {
                    message.stwEnabled = reader.bool();
                    break;
                }
            case 8: {
                    if (!(message.currencyPrice && message.currencyPrice.length))
                        message.currencyPrice = [];
                    message.currencyPrice.push($root.CurrencyPrice.decode(reader, reader.uint32()));
                    break;
                }
            case 11: {
                    message.id = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("offerSku"))
            throw $util.ProtocolError("missing required 'offerSku'", { instance: message });
        if (!message.hasOwnProperty("bonus"))
            throw $util.ProtocolError("missing required 'bonus'", { instance: message });
        if (!message.hasOwnProperty("marketOfferId"))
            throw $util.ProtocolError("missing required 'marketOfferId'", { instance: message });
        if (!message.hasOwnProperty("offerDescription"))
            throw $util.ProtocolError("missing required 'offerDescription'", { instance: message });
        return message;
    };

    Offer.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Offer.fromObject = function fromObject(object) {
        if (object instanceof $root.Offer)
            return object;
        let message = new $root.Offer();
        if (object.offerSku != null)
            message.offerSku = String(object.offerSku);
        if (object.bonus != null)
            message.bonus = Number(object.bonus);
        if (object.coins != null)
            if ($util.Long)
                (message.coins = $util.Long.fromValue(object.coins)).unsigned = false;
            else if (typeof object.coins === "string")
                message.coins = parseInt(object.coins, 10);
            else if (typeof object.coins === "number")
                message.coins = object.coins;
            else if (typeof object.coins === "object")
                message.coins = new $util.LongBits(object.coins.low >>> 0, object.coins.high >>> 0).toNumber();
        if (object.category != null)
            message.category = String(object.category);
        if (object.marketOfferId != null)
            message.marketOfferId = String(object.marketOfferId);
        if (object.offerDescription != null)
            message.offerDescription = String(object.offerDescription);
        if (object.stwEnabled != null)
            message.stwEnabled = Boolean(object.stwEnabled);
        if (object.currencyPrice) {
            if (!Array.isArray(object.currencyPrice))
                throw TypeError(".Offer.currencyPrice: array expected");
            message.currencyPrice = [];
            for (let i = 0; i < object.currencyPrice.length; ++i) {
                if (typeof object.currencyPrice[i] !== "object")
                    throw TypeError(".Offer.currencyPrice: object expected");
                message.currencyPrice[i] = $root.CurrencyPrice.fromObject(object.currencyPrice[i]);
            }
        }
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
        return message;
    };

    Offer.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.currencyPrice = [];
        if (options.defaults) {
            object.offerSku = "";
            object.bonus = 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.coins = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.coins = options.longs === String ? "0" : 0;
            object.category = "";
            object.marketOfferId = "";
            object.offerDescription = "";
            object.stwEnabled = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
        }
        if (message.offerSku != null && message.hasOwnProperty("offerSku"))
            object.offerSku = message.offerSku;
        if (message.bonus != null && message.hasOwnProperty("bonus"))
            object.bonus = options.json && !isFinite(message.bonus) ? String(message.bonus) : message.bonus;
        if (message.coins != null && message.hasOwnProperty("coins"))
            if (typeof message.coins === "number")
                object.coins = options.longs === String ? String(message.coins) : message.coins;
            else
                object.coins = options.longs === String ? $util.Long.prototype.toString.call(message.coins) : options.longs === Number ? new $util.LongBits(message.coins.low >>> 0, message.coins.high >>> 0).toNumber() : message.coins;
        if (message.category != null && message.hasOwnProperty("category"))
            object.category = message.category;
        if (message.marketOfferId != null && message.hasOwnProperty("marketOfferId"))
            object.marketOfferId = message.marketOfferId;
        if (message.offerDescription != null && message.hasOwnProperty("offerDescription"))
            object.offerDescription = message.offerDescription;
        if (message.stwEnabled != null && message.hasOwnProperty("stwEnabled"))
            object.stwEnabled = message.stwEnabled;
        if (message.currencyPrice && message.currencyPrice.length) {
            object.currencyPrice = [];
            for (let j = 0; j < message.currencyPrice.length; ++j)
                object.currencyPrice[j] = $root.CurrencyPrice.toObject(message.currencyPrice[j], options);
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
        return object;
    };

    Offer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Offer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Offer";
    };

    return Offer;
})();

export const SasPricePoint = $root.SasPricePoint = (() => {

    function SasPricePoint(properties) {
        this.offers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SasPricePoint.prototype.price = 0;
    SasPricePoint.prototype.offers = $util.emptyArray;
    SasPricePoint.prototype.id = "";

    SasPricePoint.create = function create(properties) {
        return new SasPricePoint(properties);
    };

    SasPricePoint.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(9).double(message.price);
        if (message.offers != null && message.offers.length)
            for (let i = 0; i < message.offers.length; ++i)
                $root.Offer.encode(message.offers[i], writer.uint32(18).fork()).ldelim();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(26).string(message.id);
        return writer;
    };

    SasPricePoint.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SasPricePoint.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SasPricePoint();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.price = reader.double();
                    break;
                }
            case 2: {
                    if (!(message.offers && message.offers.length))
                        message.offers = [];
                    message.offers.push($root.Offer.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.id = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("price"))
            throw $util.ProtocolError("missing required 'price'", { instance: message });
        return message;
    };

    SasPricePoint.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SasPricePoint.fromObject = function fromObject(object) {
        if (object instanceof $root.SasPricePoint)
            return object;
        let message = new $root.SasPricePoint();
        if (object.price != null)
            message.price = Number(object.price);
        if (object.offers) {
            if (!Array.isArray(object.offers))
                throw TypeError(".SasPricePoint.offers: array expected");
            message.offers = [];
            for (let i = 0; i < object.offers.length; ++i) {
                if (typeof object.offers[i] !== "object")
                    throw TypeError(".SasPricePoint.offers: object expected");
                message.offers[i] = $root.Offer.fromObject(object.offers[i]);
            }
        }
        if (object.id != null)
            message.id = String(object.id);
        return message;
    };

    SasPricePoint.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.offers = [];
        if (options.defaults) {
            object.price = 0;
            object.id = "";
        }
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
        if (message.offers && message.offers.length) {
            object.offers = [];
            for (let j = 0; j < message.offers.length; ++j)
                object.offers[j] = $root.Offer.toObject(message.offers[j], options);
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        return object;
    };

    SasPricePoint.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SasPricePoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SasPricePoint";
    };

    return SasPricePoint;
})();

export const PricePoint = $root.PricePoint = (() => {

    function PricePoint(properties) {
        this.offers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PricePoint.prototype.priority = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PricePoint.prototype.price = 0;
    PricePoint.prototype.isHot = false;
    PricePoint.prototype.isPopular = false;
    PricePoint.prototype.imageUrl = "";
    PricePoint.prototype.offers = $util.emptyArray;
    PricePoint.prototype.sasPricePoint = null;
    PricePoint.prototype.id = "";
    PricePoint.prototype.isTop = false;
    PricePoint.prototype.progressiveState = "";
    PricePoint.prototype.stwTemplate = null;
    PricePoint.prototype.header = "";
    PricePoint.prototype.subheader = "";
    PricePoint.prototype.badgeText = "";

    PricePoint.create = function create(properties) {
        return new PricePoint(properties);
    };

    PricePoint.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int64(message.priority);
        writer.uint32(17).double(message.price);
        if (message.isHot != null && Object.hasOwnProperty.call(message, "isHot"))
            writer.uint32(24).bool(message.isHot);
        if (message.isPopular != null && Object.hasOwnProperty.call(message, "isPopular"))
            writer.uint32(32).bool(message.isPopular);
        if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
            writer.uint32(42).string(message.imageUrl);
        if (message.offers != null && message.offers.length)
            for (let i = 0; i < message.offers.length; ++i)
                $root.Offer.encode(message.offers[i], writer.uint32(50).fork()).ldelim();
        if (message.sasPricePoint != null && Object.hasOwnProperty.call(message, "sasPricePoint"))
            $root.SasPricePoint.encode(message.sasPricePoint, writer.uint32(58).fork()).ldelim();
        writer.uint32(66).string(message.id);
        if (message.isTop != null && Object.hasOwnProperty.call(message, "isTop"))
            writer.uint32(72).bool(message.isTop);
        if (message.progressiveState != null && Object.hasOwnProperty.call(message, "progressiveState"))
            writer.uint32(82).string(message.progressiveState);
        if (message.stwTemplate != null && Object.hasOwnProperty.call(message, "stwTemplate"))
            $root.SasTemplate.encode(message.stwTemplate, writer.uint32(90).fork()).ldelim();
        if (message.header != null && Object.hasOwnProperty.call(message, "header"))
            writer.uint32(98).string(message.header);
        if (message.subheader != null && Object.hasOwnProperty.call(message, "subheader"))
            writer.uint32(106).string(message.subheader);
        if (message.badgeText != null && Object.hasOwnProperty.call(message, "badgeText"))
            writer.uint32(114).string(message.badgeText);
        return writer;
    };

    PricePoint.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PricePoint.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PricePoint();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.priority = reader.int64();
                    break;
                }
            case 2: {
                    message.price = reader.double();
                    break;
                }
            case 3: {
                    message.isHot = reader.bool();
                    break;
                }
            case 4: {
                    message.isPopular = reader.bool();
                    break;
                }
            case 5: {
                    message.imageUrl = reader.string();
                    break;
                }
            case 6: {
                    if (!(message.offers && message.offers.length))
                        message.offers = [];
                    message.offers.push($root.Offer.decode(reader, reader.uint32()));
                    break;
                }
            case 7: {
                    message.sasPricePoint = $root.SasPricePoint.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.id = reader.string();
                    break;
                }
            case 9: {
                    message.isTop = reader.bool();
                    break;
                }
            case 10: {
                    message.progressiveState = reader.string();
                    break;
                }
            case 11: {
                    message.stwTemplate = $root.SasTemplate.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.header = reader.string();
                    break;
                }
            case 13: {
                    message.subheader = reader.string();
                    break;
                }
            case 14: {
                    message.badgeText = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("priority"))
            throw $util.ProtocolError("missing required 'priority'", { instance: message });
        if (!message.hasOwnProperty("price"))
            throw $util.ProtocolError("missing required 'price'", { instance: message });
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        return message;
    };

    PricePoint.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PricePoint.fromObject = function fromObject(object) {
        if (object instanceof $root.PricePoint)
            return object;
        let message = new $root.PricePoint();
        if (object.priority != null)
            if ($util.Long)
                (message.priority = $util.Long.fromValue(object.priority)).unsigned = false;
            else if (typeof object.priority === "string")
                message.priority = parseInt(object.priority, 10);
            else if (typeof object.priority === "number")
                message.priority = object.priority;
            else if (typeof object.priority === "object")
                message.priority = new $util.LongBits(object.priority.low >>> 0, object.priority.high >>> 0).toNumber();
        if (object.price != null)
            message.price = Number(object.price);
        if (object.isHot != null)
            message.isHot = Boolean(object.isHot);
        if (object.isPopular != null)
            message.isPopular = Boolean(object.isPopular);
        if (object.imageUrl != null)
            message.imageUrl = String(object.imageUrl);
        if (object.offers) {
            if (!Array.isArray(object.offers))
                throw TypeError(".PricePoint.offers: array expected");
            message.offers = [];
            for (let i = 0; i < object.offers.length; ++i) {
                if (typeof object.offers[i] !== "object")
                    throw TypeError(".PricePoint.offers: object expected");
                message.offers[i] = $root.Offer.fromObject(object.offers[i]);
            }
        }
        if (object.sasPricePoint != null) {
            if (typeof object.sasPricePoint !== "object")
                throw TypeError(".PricePoint.sasPricePoint: object expected");
            message.sasPricePoint = $root.SasPricePoint.fromObject(object.sasPricePoint);
        }
        if (object.id != null)
            message.id = String(object.id);
        if (object.isTop != null)
            message.isTop = Boolean(object.isTop);
        if (object.progressiveState != null)
            message.progressiveState = String(object.progressiveState);
        if (object.stwTemplate != null) {
            if (typeof object.stwTemplate !== "object")
                throw TypeError(".PricePoint.stwTemplate: object expected");
            message.stwTemplate = $root.SasTemplate.fromObject(object.stwTemplate);
        }
        if (object.header != null)
            message.header = String(object.header);
        if (object.subheader != null)
            message.subheader = String(object.subheader);
        if (object.badgeText != null)
            message.badgeText = String(object.badgeText);
        return message;
    };

    PricePoint.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.offers = [];
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.priority = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.priority = options.longs === String ? "0" : 0;
            object.price = 0;
            object.isHot = false;
            object.isPopular = false;
            object.imageUrl = "";
            object.sasPricePoint = null;
            object.id = "";
            object.isTop = false;
            object.progressiveState = "";
            object.stwTemplate = null;
            object.header = "";
            object.subheader = "";
            object.badgeText = "";
        }
        if (message.priority != null && message.hasOwnProperty("priority"))
            if (typeof message.priority === "number")
                object.priority = options.longs === String ? String(message.priority) : message.priority;
            else
                object.priority = options.longs === String ? $util.Long.prototype.toString.call(message.priority) : options.longs === Number ? new $util.LongBits(message.priority.low >>> 0, message.priority.high >>> 0).toNumber() : message.priority;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
        if (message.isHot != null && message.hasOwnProperty("isHot"))
            object.isHot = message.isHot;
        if (message.isPopular != null && message.hasOwnProperty("isPopular"))
            object.isPopular = message.isPopular;
        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
            object.imageUrl = message.imageUrl;
        if (message.offers && message.offers.length) {
            object.offers = [];
            for (let j = 0; j < message.offers.length; ++j)
                object.offers[j] = $root.Offer.toObject(message.offers[j], options);
        }
        if (message.sasPricePoint != null && message.hasOwnProperty("sasPricePoint"))
            object.sasPricePoint = $root.SasPricePoint.toObject(message.sasPricePoint, options);
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.isTop != null && message.hasOwnProperty("isTop"))
            object.isTop = message.isTop;
        if (message.progressiveState != null && message.hasOwnProperty("progressiveState"))
            object.progressiveState = message.progressiveState;
        if (message.stwTemplate != null && message.hasOwnProperty("stwTemplate"))
            object.stwTemplate = $root.SasTemplate.toObject(message.stwTemplate, options);
        if (message.header != null && message.hasOwnProperty("header"))
            object.header = message.header;
        if (message.subheader != null && message.hasOwnProperty("subheader"))
            object.subheader = message.subheader;
        if (message.badgeText != null && message.hasOwnProperty("badgeText"))
            object.badgeText = message.badgeText;
        return object;
    };

    PricePoint.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PricePoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PricePoint";
    };

    return PricePoint;
})();

export const MultiplierProbability = $root.MultiplierProbability = (() => {

    function MultiplierProbability(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MultiplierProbability.prototype.multiplier = 0;

    MultiplierProbability.create = function create(properties) {
        return new MultiplierProbability(properties);
    };

    MultiplierProbability.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(9).double(message.multiplier);
        return writer;
    };

    MultiplierProbability.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MultiplierProbability.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiplierProbability();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.multiplier = reader.double();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("multiplier"))
            throw $util.ProtocolError("missing required 'multiplier'", { instance: message });
        return message;
    };

    MultiplierProbability.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MultiplierProbability.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiplierProbability)
            return object;
        let message = new $root.MultiplierProbability();
        if (object.multiplier != null)
            message.multiplier = Number(object.multiplier);
        return message;
    };

    MultiplierProbability.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.multiplier = 0;
        if (message.multiplier != null && message.hasOwnProperty("multiplier"))
            object.multiplier = options.json && !isFinite(message.multiplier) ? String(message.multiplier) : message.multiplier;
        return object;
    };

    MultiplierProbability.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    MultiplierProbability.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiplierProbability";
    };

    return MultiplierProbability;
})();

export const SasTemplate = $root.SasTemplate = (() => {

    function SasTemplate(properties) {
        this.nativeTemplate = [];
        this.creditTemplate = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SasTemplate.prototype.nativeTemplate = $util.emptyArray;
    SasTemplate.prototype.creditTemplate = $util.emptyArray;

    SasTemplate.create = function create(properties) {
        return new SasTemplate(properties);
    };

    SasTemplate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.nativeTemplate != null && message.nativeTemplate.length)
            for (let i = 0; i < message.nativeTemplate.length; ++i)
                $root.MultiplierProbability.encode(message.nativeTemplate[i], writer.uint32(10).fork()).ldelim();
        if (message.creditTemplate != null && message.creditTemplate.length)
            for (let i = 0; i < message.creditTemplate.length; ++i)
                $root.MultiplierProbability.encode(message.creditTemplate[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    SasTemplate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SasTemplate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SasTemplate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.nativeTemplate && message.nativeTemplate.length))
                        message.nativeTemplate = [];
                    message.nativeTemplate.push($root.MultiplierProbability.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    if (!(message.creditTemplate && message.creditTemplate.length))
                        message.creditTemplate = [];
                    message.creditTemplate.push($root.MultiplierProbability.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SasTemplate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SasTemplate.fromObject = function fromObject(object) {
        if (object instanceof $root.SasTemplate)
            return object;
        let message = new $root.SasTemplate();
        if (object.nativeTemplate) {
            if (!Array.isArray(object.nativeTemplate))
                throw TypeError(".SasTemplate.nativeTemplate: array expected");
            message.nativeTemplate = [];
            for (let i = 0; i < object.nativeTemplate.length; ++i) {
                if (typeof object.nativeTemplate[i] !== "object")
                    throw TypeError(".SasTemplate.nativeTemplate: object expected");
                message.nativeTemplate[i] = $root.MultiplierProbability.fromObject(object.nativeTemplate[i]);
            }
        }
        if (object.creditTemplate) {
            if (!Array.isArray(object.creditTemplate))
                throw TypeError(".SasTemplate.creditTemplate: array expected");
            message.creditTemplate = [];
            for (let i = 0; i < object.creditTemplate.length; ++i) {
                if (typeof object.creditTemplate[i] !== "object")
                    throw TypeError(".SasTemplate.creditTemplate: object expected");
                message.creditTemplate[i] = $root.MultiplierProbability.fromObject(object.creditTemplate[i]);
            }
        }
        return message;
    };

    SasTemplate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.nativeTemplate = [];
            object.creditTemplate = [];
        }
        if (message.nativeTemplate && message.nativeTemplate.length) {
            object.nativeTemplate = [];
            for (let j = 0; j < message.nativeTemplate.length; ++j)
                object.nativeTemplate[j] = $root.MultiplierProbability.toObject(message.nativeTemplate[j], options);
        }
        if (message.creditTemplate && message.creditTemplate.length) {
            object.creditTemplate = [];
            for (let j = 0; j < message.creditTemplate.length; ++j)
                object.creditTemplate[j] = $root.MultiplierProbability.toObject(message.creditTemplate[j], options);
        }
        return object;
    };

    SasTemplate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SasTemplate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SasTemplate";
    };

    return SasTemplate;
})();

export const CashierPersonalOfferResponse = $root.CashierPersonalOfferResponse = (() => {

    function CashierPersonalOfferResponse(properties) {
        this.pricePoints = [];
        this.destination = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CashierPersonalOfferResponse.prototype.pricePoints = $util.emptyArray;
    CashierPersonalOfferResponse.prototype.sasEnabled = false;
    CashierPersonalOfferResponse.prototype.sasTemplate = null;
    CashierPersonalOfferResponse.prototype.campaignId = "";
    CashierPersonalOfferResponse.prototype.personalOfferId = "";
    CashierPersonalOfferResponse.prototype.progressiveStateEnabled = false;
    CashierPersonalOfferResponse.prototype.destination = $util.emptyArray;
    CashierPersonalOfferResponse.prototype.type = "";
    CashierPersonalOfferResponse.prototype.expirationDateMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    CashierPersonalOfferResponse.prototype.purchaseLimit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    CashierPersonalOfferResponse.prototype.closestDateToSeeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    CashierPersonalOfferResponse.prototype.layout = null;
    CashierPersonalOfferResponse.prototype.campaignExpirationDateMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    CashierPersonalOfferResponse.prototype.triggerId = "";

    CashierPersonalOfferResponse.create = function create(properties) {
        return new CashierPersonalOfferResponse(properties);
    };

    CashierPersonalOfferResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pricePoints != null && message.pricePoints.length)
            for (let i = 0; i < message.pricePoints.length; ++i)
                $root.PricePoint.encode(message.pricePoints[i], writer.uint32(10).fork()).ldelim();
        writer.uint32(16).bool(message.sasEnabled);
        if (message.sasTemplate != null && Object.hasOwnProperty.call(message, "sasTemplate"))
            $root.SasTemplate.encode(message.sasTemplate, writer.uint32(26).fork()).ldelim();
        writer.uint32(34).string(message.campaignId);
        writer.uint32(42).string(message.personalOfferId);
        if (message.progressiveStateEnabled != null && Object.hasOwnProperty.call(message, "progressiveStateEnabled"))
            writer.uint32(104).bool(message.progressiveStateEnabled);
        if (message.destination != null && message.destination.length)
            for (let i = 0; i < message.destination.length; ++i)
                writer.uint32(114).string(message.destination[i]);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(122).string(message.type);
        if (message.expirationDateMs != null && Object.hasOwnProperty.call(message, "expirationDateMs"))
            writer.uint32(129).sfixed64(message.expirationDateMs);
        if (message.purchaseLimit != null && Object.hasOwnProperty.call(message, "purchaseLimit"))
            writer.uint32(136).int64(message.purchaseLimit);
        if (message.closestDateToSeeMs != null && Object.hasOwnProperty.call(message, "closestDateToSeeMs"))
            writer.uint32(145).sfixed64(message.closestDateToSeeMs);
        if (message.layout != null && Object.hasOwnProperty.call(message, "layout"))
            $root.Layout.encode(message.layout, writer.uint32(154).fork()).ldelim();
        if (message.campaignExpirationDateMs != null && Object.hasOwnProperty.call(message, "campaignExpirationDateMs"))
            writer.uint32(161).sfixed64(message.campaignExpirationDateMs);
        if (message.triggerId != null && Object.hasOwnProperty.call(message, "triggerId"))
            writer.uint32(170).string(message.triggerId);
        return writer;
    };

    CashierPersonalOfferResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CashierPersonalOfferResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CashierPersonalOfferResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.pricePoints && message.pricePoints.length))
                        message.pricePoints = [];
                    message.pricePoints.push($root.PricePoint.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    message.sasEnabled = reader.bool();
                    break;
                }
            case 3: {
                    message.sasTemplate = $root.SasTemplate.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.campaignId = reader.string();
                    break;
                }
            case 5: {
                    message.personalOfferId = reader.string();
                    break;
                }
            case 13: {
                    message.progressiveStateEnabled = reader.bool();
                    break;
                }
            case 14: {
                    if (!(message.destination && message.destination.length))
                        message.destination = [];
                    message.destination.push(reader.string());
                    break;
                }
            case 15: {
                    message.type = reader.string();
                    break;
                }
            case 16: {
                    message.expirationDateMs = reader.sfixed64();
                    break;
                }
            case 17: {
                    message.purchaseLimit = reader.int64();
                    break;
                }
            case 18: {
                    message.closestDateToSeeMs = reader.sfixed64();
                    break;
                }
            case 19: {
                    message.layout = $root.Layout.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.campaignExpirationDateMs = reader.sfixed64();
                    break;
                }
            case 21: {
                    message.triggerId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("sasEnabled"))
            throw $util.ProtocolError("missing required 'sasEnabled'", { instance: message });
        if (!message.hasOwnProperty("campaignId"))
            throw $util.ProtocolError("missing required 'campaignId'", { instance: message });
        if (!message.hasOwnProperty("personalOfferId"))
            throw $util.ProtocolError("missing required 'personalOfferId'", { instance: message });
        return message;
    };

    CashierPersonalOfferResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CashierPersonalOfferResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CashierPersonalOfferResponse)
            return object;
        let message = new $root.CashierPersonalOfferResponse();
        if (object.pricePoints) {
            if (!Array.isArray(object.pricePoints))
                throw TypeError(".CashierPersonalOfferResponse.pricePoints: array expected");
            message.pricePoints = [];
            for (let i = 0; i < object.pricePoints.length; ++i) {
                if (typeof object.pricePoints[i] !== "object")
                    throw TypeError(".CashierPersonalOfferResponse.pricePoints: object expected");
                message.pricePoints[i] = $root.PricePoint.fromObject(object.pricePoints[i]);
            }
        }
        if (object.sasEnabled != null)
            message.sasEnabled = Boolean(object.sasEnabled);
        if (object.sasTemplate != null) {
            if (typeof object.sasTemplate !== "object")
                throw TypeError(".CashierPersonalOfferResponse.sasTemplate: object expected");
            message.sasTemplate = $root.SasTemplate.fromObject(object.sasTemplate);
        }
        if (object.campaignId != null)
            message.campaignId = String(object.campaignId);
        if (object.personalOfferId != null)
            message.personalOfferId = String(object.personalOfferId);
        if (object.progressiveStateEnabled != null)
            message.progressiveStateEnabled = Boolean(object.progressiveStateEnabled);
        if (object.destination) {
            if (!Array.isArray(object.destination))
                throw TypeError(".CashierPersonalOfferResponse.destination: array expected");
            message.destination = [];
            for (let i = 0; i < object.destination.length; ++i)
                message.destination[i] = String(object.destination[i]);
        }
        if (object.type != null)
            message.type = String(object.type);
        if (object.expirationDateMs != null)
            if ($util.Long)
                (message.expirationDateMs = $util.Long.fromValue(object.expirationDateMs)).unsigned = false;
            else if (typeof object.expirationDateMs === "string")
                message.expirationDateMs = parseInt(object.expirationDateMs, 10);
            else if (typeof object.expirationDateMs === "number")
                message.expirationDateMs = object.expirationDateMs;
            else if (typeof object.expirationDateMs === "object")
                message.expirationDateMs = new $util.LongBits(object.expirationDateMs.low >>> 0, object.expirationDateMs.high >>> 0).toNumber();
        if (object.purchaseLimit != null)
            if ($util.Long)
                (message.purchaseLimit = $util.Long.fromValue(object.purchaseLimit)).unsigned = false;
            else if (typeof object.purchaseLimit === "string")
                message.purchaseLimit = parseInt(object.purchaseLimit, 10);
            else if (typeof object.purchaseLimit === "number")
                message.purchaseLimit = object.purchaseLimit;
            else if (typeof object.purchaseLimit === "object")
                message.purchaseLimit = new $util.LongBits(object.purchaseLimit.low >>> 0, object.purchaseLimit.high >>> 0).toNumber();
        if (object.closestDateToSeeMs != null)
            if ($util.Long)
                (message.closestDateToSeeMs = $util.Long.fromValue(object.closestDateToSeeMs)).unsigned = false;
            else if (typeof object.closestDateToSeeMs === "string")
                message.closestDateToSeeMs = parseInt(object.closestDateToSeeMs, 10);
            else if (typeof object.closestDateToSeeMs === "number")
                message.closestDateToSeeMs = object.closestDateToSeeMs;
            else if (typeof object.closestDateToSeeMs === "object")
                message.closestDateToSeeMs = new $util.LongBits(object.closestDateToSeeMs.low >>> 0, object.closestDateToSeeMs.high >>> 0).toNumber();
        if (object.layout != null) {
            if (typeof object.layout !== "object")
                throw TypeError(".CashierPersonalOfferResponse.layout: object expected");
            message.layout = $root.Layout.fromObject(object.layout);
        }
        if (object.campaignExpirationDateMs != null)
            if ($util.Long)
                (message.campaignExpirationDateMs = $util.Long.fromValue(object.campaignExpirationDateMs)).unsigned = false;
            else if (typeof object.campaignExpirationDateMs === "string")
                message.campaignExpirationDateMs = parseInt(object.campaignExpirationDateMs, 10);
            else if (typeof object.campaignExpirationDateMs === "number")
                message.campaignExpirationDateMs = object.campaignExpirationDateMs;
            else if (typeof object.campaignExpirationDateMs === "object")
                message.campaignExpirationDateMs = new $util.LongBits(object.campaignExpirationDateMs.low >>> 0, object.campaignExpirationDateMs.high >>> 0).toNumber();
        if (object.triggerId != null)
            message.triggerId = String(object.triggerId);
        return message;
    };

    CashierPersonalOfferResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.pricePoints = [];
            object.destination = [];
        }
        if (options.defaults) {
            object.sasEnabled = false;
            object.sasTemplate = null;
            object.campaignId = "";
            object.personalOfferId = "";
            object.progressiveStateEnabled = false;
            object.type = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.expirationDateMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.expirationDateMs = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.purchaseLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.purchaseLimit = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.closestDateToSeeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.closestDateToSeeMs = options.longs === String ? "0" : 0;
            object.layout = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.campaignExpirationDateMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.campaignExpirationDateMs = options.longs === String ? "0" : 0;
            object.triggerId = "";
        }
        if (message.pricePoints && message.pricePoints.length) {
            object.pricePoints = [];
            for (let j = 0; j < message.pricePoints.length; ++j)
                object.pricePoints[j] = $root.PricePoint.toObject(message.pricePoints[j], options);
        }
        if (message.sasEnabled != null && message.hasOwnProperty("sasEnabled"))
            object.sasEnabled = message.sasEnabled;
        if (message.sasTemplate != null && message.hasOwnProperty("sasTemplate"))
            object.sasTemplate = $root.SasTemplate.toObject(message.sasTemplate, options);
        if (message.campaignId != null && message.hasOwnProperty("campaignId"))
            object.campaignId = message.campaignId;
        if (message.personalOfferId != null && message.hasOwnProperty("personalOfferId"))
            object.personalOfferId = message.personalOfferId;
        if (message.progressiveStateEnabled != null && message.hasOwnProperty("progressiveStateEnabled"))
            object.progressiveStateEnabled = message.progressiveStateEnabled;
        if (message.destination && message.destination.length) {
            object.destination = [];
            for (let j = 0; j < message.destination.length; ++j)
                object.destination[j] = message.destination[j];
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.expirationDateMs != null && message.hasOwnProperty("expirationDateMs"))
            if (typeof message.expirationDateMs === "number")
                object.expirationDateMs = options.longs === String ? String(message.expirationDateMs) : message.expirationDateMs;
            else
                object.expirationDateMs = options.longs === String ? $util.Long.prototype.toString.call(message.expirationDateMs) : options.longs === Number ? new $util.LongBits(message.expirationDateMs.low >>> 0, message.expirationDateMs.high >>> 0).toNumber() : message.expirationDateMs;
        if (message.purchaseLimit != null && message.hasOwnProperty("purchaseLimit"))
            if (typeof message.purchaseLimit === "number")
                object.purchaseLimit = options.longs === String ? String(message.purchaseLimit) : message.purchaseLimit;
            else
                object.purchaseLimit = options.longs === String ? $util.Long.prototype.toString.call(message.purchaseLimit) : options.longs === Number ? new $util.LongBits(message.purchaseLimit.low >>> 0, message.purchaseLimit.high >>> 0).toNumber() : message.purchaseLimit;
        if (message.closestDateToSeeMs != null && message.hasOwnProperty("closestDateToSeeMs"))
            if (typeof message.closestDateToSeeMs === "number")
                object.closestDateToSeeMs = options.longs === String ? String(message.closestDateToSeeMs) : message.closestDateToSeeMs;
            else
                object.closestDateToSeeMs = options.longs === String ? $util.Long.prototype.toString.call(message.closestDateToSeeMs) : options.longs === Number ? new $util.LongBits(message.closestDateToSeeMs.low >>> 0, message.closestDateToSeeMs.high >>> 0).toNumber() : message.closestDateToSeeMs;
        if (message.layout != null && message.hasOwnProperty("layout"))
            object.layout = $root.Layout.toObject(message.layout, options);
        if (message.campaignExpirationDateMs != null && message.hasOwnProperty("campaignExpirationDateMs"))
            if (typeof message.campaignExpirationDateMs === "number")
                object.campaignExpirationDateMs = options.longs === String ? String(message.campaignExpirationDateMs) : message.campaignExpirationDateMs;
            else
                object.campaignExpirationDateMs = options.longs === String ? $util.Long.prototype.toString.call(message.campaignExpirationDateMs) : options.longs === Number ? new $util.LongBits(message.campaignExpirationDateMs.low >>> 0, message.campaignExpirationDateMs.high >>> 0).toNumber() : message.campaignExpirationDateMs;
        if (message.triggerId != null && message.hasOwnProperty("triggerId"))
            object.triggerId = message.triggerId;
        return object;
    };

    CashierPersonalOfferResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CashierPersonalOfferResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CashierPersonalOfferResponse";
    };

    return CashierPersonalOfferResponse;
})();

export const Layout = $root.Layout = (() => {

    function Layout(properties) {
        this.layers = [];
        this.resolutions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Layout.prototype.id = "";
    Layout.prototype.hideTabTitle = false;
    Layout.prototype.header = "";
    Layout.prototype.subheader = "";
    Layout.prototype.showTimer = false;
    Layout.prototype.contentLayout = 0;
    Layout.prototype.theme = 0;
    Layout.prototype.layers = $util.emptyArray;
    Layout.prototype.resolutions = $util.emptyArray;

    Layout.create = function create(properties) {
        return new Layout(properties);
    };

    Layout.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        writer.uint32(16).bool(message.hideTabTitle);
        if (message.header != null && Object.hasOwnProperty.call(message, "header"))
            writer.uint32(26).string(message.header);
        if (message.subheader != null && Object.hasOwnProperty.call(message, "subheader"))
            writer.uint32(34).string(message.subheader);
        writer.uint32(40).bool(message.showTimer);
        writer.uint32(48).int32(message.contentLayout);
        writer.uint32(56).int32(message.theme);
        if (message.layers != null && message.layers.length)
            for (let i = 0; i < message.layers.length; ++i)
                $root.Layer.encode(message.layers[i], writer.uint32(66).fork()).ldelim();
        if (message.resolutions != null && message.resolutions.length)
            for (let i = 0; i < message.resolutions.length; ++i)
                $root.Resolution.encode(message.resolutions[i], writer.uint32(74).fork()).ldelim();
        return writer;
    };

    Layout.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Layout.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Layout();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.hideTabTitle = reader.bool();
                    break;
                }
            case 3: {
                    message.header = reader.string();
                    break;
                }
            case 4: {
                    message.subheader = reader.string();
                    break;
                }
            case 5: {
                    message.showTimer = reader.bool();
                    break;
                }
            case 6: {
                    message.contentLayout = reader.int32();
                    break;
                }
            case 7: {
                    message.theme = reader.int32();
                    break;
                }
            case 8: {
                    if (!(message.layers && message.layers.length))
                        message.layers = [];
                    message.layers.push($root.Layer.decode(reader, reader.uint32()));
                    break;
                }
            case 9: {
                    if (!(message.resolutions && message.resolutions.length))
                        message.resolutions = [];
                    message.resolutions.push($root.Resolution.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("hideTabTitle"))
            throw $util.ProtocolError("missing required 'hideTabTitle'", { instance: message });
        if (!message.hasOwnProperty("showTimer"))
            throw $util.ProtocolError("missing required 'showTimer'", { instance: message });
        if (!message.hasOwnProperty("contentLayout"))
            throw $util.ProtocolError("missing required 'contentLayout'", { instance: message });
        if (!message.hasOwnProperty("theme"))
            throw $util.ProtocolError("missing required 'theme'", { instance: message });
        return message;
    };

    Layout.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Layout.fromObject = function fromObject(object) {
        if (object instanceof $root.Layout)
            return object;
        let message = new $root.Layout();
        if (object.id != null)
            message.id = String(object.id);
        if (object.hideTabTitle != null)
            message.hideTabTitle = Boolean(object.hideTabTitle);
        if (object.header != null)
            message.header = String(object.header);
        if (object.subheader != null)
            message.subheader = String(object.subheader);
        if (object.showTimer != null)
            message.showTimer = Boolean(object.showTimer);
        switch (object.contentLayout) {
        default:
            if (typeof object.contentLayout === "number") {
                message.contentLayout = object.contentLayout;
                break;
            }
            break;
        case "GRID":
        case 0:
            message.contentLayout = 0;
            break;
        case "LIST":
        case 1:
            message.contentLayout = 1;
            break;
        case "LANDING_PAGE":
        case 2:
            message.contentLayout = 2;
            break;
        }
        switch (object.theme) {
        default:
            if (typeof object.theme === "number") {
                message.theme = object.theme;
                break;
            }
            break;
        case "AUTO_CASHIER":
        case 0:
            message.theme = 0;
            break;
        case "REFILL":
        case 1:
            message.theme = 1;
            break;
        case "LANDING_PAGE_LIGHT":
        case 2:
            message.theme = 2;
            break;
        case "LANDING_PAGE_DARK":
        case 3:
            message.theme = 3;
            break;
        }
        if (object.layers) {
            if (!Array.isArray(object.layers))
                throw TypeError(".Layout.layers: array expected");
            message.layers = [];
            for (let i = 0; i < object.layers.length; ++i) {
                if (typeof object.layers[i] !== "object")
                    throw TypeError(".Layout.layers: object expected");
                message.layers[i] = $root.Layer.fromObject(object.layers[i]);
            }
        }
        if (object.resolutions) {
            if (!Array.isArray(object.resolutions))
                throw TypeError(".Layout.resolutions: array expected");
            message.resolutions = [];
            for (let i = 0; i < object.resolutions.length; ++i) {
                if (typeof object.resolutions[i] !== "object")
                    throw TypeError(".Layout.resolutions: object expected");
                message.resolutions[i] = $root.Resolution.fromObject(object.resolutions[i]);
            }
        }
        return message;
    };

    Layout.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.layers = [];
            object.resolutions = [];
        }
        if (options.defaults) {
            object.id = "";
            object.hideTabTitle = false;
            object.header = "";
            object.subheader = "";
            object.showTimer = false;
            object.contentLayout = options.enums === String ? "GRID" : 0;
            object.theme = options.enums === String ? "AUTO_CASHIER" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.hideTabTitle != null && message.hasOwnProperty("hideTabTitle"))
            object.hideTabTitle = message.hideTabTitle;
        if (message.header != null && message.hasOwnProperty("header"))
            object.header = message.header;
        if (message.subheader != null && message.hasOwnProperty("subheader"))
            object.subheader = message.subheader;
        if (message.showTimer != null && message.hasOwnProperty("showTimer"))
            object.showTimer = message.showTimer;
        if (message.contentLayout != null && message.hasOwnProperty("contentLayout"))
            object.contentLayout = options.enums === String ? $root.ContentLayoutType[message.contentLayout] === undefined ? message.contentLayout : $root.ContentLayoutType[message.contentLayout] : message.contentLayout;
        if (message.theme != null && message.hasOwnProperty("theme"))
            object.theme = options.enums === String ? $root.LayoutTheme[message.theme] === undefined ? message.theme : $root.LayoutTheme[message.theme] : message.theme;
        if (message.layers && message.layers.length) {
            object.layers = [];
            for (let j = 0; j < message.layers.length; ++j)
                object.layers[j] = $root.Layer.toObject(message.layers[j], options);
        }
        if (message.resolutions && message.resolutions.length) {
            object.resolutions = [];
            for (let j = 0; j < message.resolutions.length; ++j)
                object.resolutions[j] = $root.Resolution.toObject(message.resolutions[j], options);
        }
        return object;
    };

    Layout.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Layout.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Layout";
    };

    return Layout;
})();

export const Layer = $root.Layer = (() => {

    function Layer(properties) {
        this.colors = [];
        this.anchors = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Layer.prototype.type = 0;
    Layer.prototype.order = 0;
    Layer.prototype.colors = $util.emptyArray;
    Layer.prototype.gradientAngle = 0;
    Layer.prototype.opacity = 0;
    Layer.prototype.dynamicOpacity = false;
    Layer.prototype.scale = 0;
    Layer.prototype.url = "";
    Layer.prototype.anchors = $util.emptyArray;

    Layer.create = function create(properties) {
        return new Layer(properties);
    };

    Layer.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.type);
        writer.uint32(16).int32(message.order);
        if (message.colors != null && message.colors.length)
            for (let i = 0; i < message.colors.length; ++i)
                writer.uint32(26).string(message.colors[i]);
        if (message.gradientAngle != null && Object.hasOwnProperty.call(message, "gradientAngle"))
            writer.uint32(33).double(message.gradientAngle);
        if (message.opacity != null && Object.hasOwnProperty.call(message, "opacity"))
            writer.uint32(41).double(message.opacity);
        if (message.dynamicOpacity != null && Object.hasOwnProperty.call(message, "dynamicOpacity"))
            writer.uint32(48).bool(message.dynamicOpacity);
        if (message.scale != null && Object.hasOwnProperty.call(message, "scale"))
            writer.uint32(57).double(message.scale);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(66).string(message.url);
        if (message.anchors != null && message.anchors.length)
            for (let i = 0; i < message.anchors.length; ++i)
                writer.uint32(72).int32(message.anchors[i]);
        return writer;
    };

    Layer.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Layer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Layer();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.order = reader.int32();
                    break;
                }
            case 3: {
                    if (!(message.colors && message.colors.length))
                        message.colors = [];
                    message.colors.push(reader.string());
                    break;
                }
            case 4: {
                    message.gradientAngle = reader.double();
                    break;
                }
            case 5: {
                    message.opacity = reader.double();
                    break;
                }
            case 6: {
                    message.dynamicOpacity = reader.bool();
                    break;
                }
            case 7: {
                    message.scale = reader.double();
                    break;
                }
            case 8: {
                    message.url = reader.string();
                    break;
                }
            case 9: {
                    if (!(message.anchors && message.anchors.length))
                        message.anchors = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.anchors.push(reader.int32());
                    } else
                        message.anchors.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("order"))
            throw $util.ProtocolError("missing required 'order'", { instance: message });
        return message;
    };

    Layer.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Layer.fromObject = function fromObject(object) {
        if (object instanceof $root.Layer)
            return object;
        let message = new $root.Layer();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "GRADIENT":
        case 0:
            message.type = 0;
            break;
        case "IMAGE":
        case 1:
            message.type = 1;
            break;
        case "LOTTIE":
        case 2:
            message.type = 2;
            break;
        case "VECTOR_IMAGE":
        case 3:
            message.type = 3;
            break;
        case "COLOR":
        case 4:
            message.type = 4;
            break;
        }
        if (object.order != null)
            message.order = object.order | 0;
        if (object.colors) {
            if (!Array.isArray(object.colors))
                throw TypeError(".Layer.colors: array expected");
            message.colors = [];
            for (let i = 0; i < object.colors.length; ++i)
                message.colors[i] = String(object.colors[i]);
        }
        if (object.gradientAngle != null)
            message.gradientAngle = Number(object.gradientAngle);
        if (object.opacity != null)
            message.opacity = Number(object.opacity);
        if (object.dynamicOpacity != null)
            message.dynamicOpacity = Boolean(object.dynamicOpacity);
        if (object.scale != null)
            message.scale = Number(object.scale);
        if (object.url != null)
            message.url = String(object.url);
        if (object.anchors) {
            if (!Array.isArray(object.anchors))
                throw TypeError(".Layer.anchors: array expected");
            message.anchors = [];
            for (let i = 0; i < object.anchors.length; ++i)
                switch (object.anchors[i]) {
                default:
                    if (typeof object.anchors[i] === "number") {
                        message.anchors[i] = object.anchors[i];
                        break;
                    }
                case "TOP":
                case 0:
                    message.anchors[i] = 0;
                    break;
                case "BOTTOM":
                case 1:
                    message.anchors[i] = 1;
                    break;
                case "LEFT":
                case 2:
                    message.anchors[i] = 2;
                    break;
                case "RIGHT":
                case 3:
                    message.anchors[i] = 3;
                    break;
                case "UNDER_CONTENT":
                case 4:
                    message.anchors[i] = 4;
                    break;
                }
        }
        return message;
    };

    Layer.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.colors = [];
            object.anchors = [];
        }
        if (options.defaults) {
            object.type = options.enums === String ? "GRADIENT" : 0;
            object.order = 0;
            object.gradientAngle = 0;
            object.opacity = 0;
            object.dynamicOpacity = false;
            object.scale = 0;
            object.url = "";
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.LayoutLayerType[message.type] === undefined ? message.type : $root.LayoutLayerType[message.type] : message.type;
        if (message.order != null && message.hasOwnProperty("order"))
            object.order = message.order;
        if (message.colors && message.colors.length) {
            object.colors = [];
            for (let j = 0; j < message.colors.length; ++j)
                object.colors[j] = message.colors[j];
        }
        if (message.gradientAngle != null && message.hasOwnProperty("gradientAngle"))
            object.gradientAngle = options.json && !isFinite(message.gradientAngle) ? String(message.gradientAngle) : message.gradientAngle;
        if (message.opacity != null && message.hasOwnProperty("opacity"))
            object.opacity = options.json && !isFinite(message.opacity) ? String(message.opacity) : message.opacity;
        if (message.dynamicOpacity != null && message.hasOwnProperty("dynamicOpacity"))
            object.dynamicOpacity = message.dynamicOpacity;
        if (message.scale != null && message.hasOwnProperty("scale"))
            object.scale = options.json && !isFinite(message.scale) ? String(message.scale) : message.scale;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.anchors && message.anchors.length) {
            object.anchors = [];
            for (let j = 0; j < message.anchors.length; ++j)
                object.anchors[j] = options.enums === String ? $root.Anchor[message.anchors[j]] === undefined ? message.anchors[j] : $root.Anchor[message.anchors[j]] : message.anchors[j];
        }
        return object;
    };

    Layer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Layer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Layer";
    };

    return Layer;
})();

export const Resolution = $root.Resolution = (() => {

    function Resolution(properties) {
        this.layers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Resolution.prototype.resolutionType = 0;
    Resolution.prototype.layers = $util.emptyArray;

    Resolution.create = function create(properties) {
        return new Resolution(properties);
    };

    Resolution.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.resolutionType != null && Object.hasOwnProperty.call(message, "resolutionType"))
            writer.uint32(8).int32(message.resolutionType);
        if (message.layers != null && message.layers.length)
            for (let i = 0; i < message.layers.length; ++i)
                $root.Layer.encode(message.layers[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    Resolution.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Resolution.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Resolution();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.resolutionType = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.layers && message.layers.length))
                        message.layers = [];
                    message.layers.push($root.Layer.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Resolution.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Resolution.fromObject = function fromObject(object) {
        if (object instanceof $root.Resolution)
            return object;
        let message = new $root.Resolution();
        switch (object.resolutionType) {
        default:
            if (typeof object.resolutionType === "number") {
                message.resolutionType = object.resolutionType;
                break;
            }
            break;
        case "SMALL_MOBILE":
        case 0:
            message.resolutionType = 0;
            break;
        case "MOBILE":
        case 1:
            message.resolutionType = 1;
            break;
        case "TABLET":
        case 2:
            message.resolutionType = 2;
            break;
        case "WINDOW":
        case 3:
            message.resolutionType = 3;
            break;
        }
        if (object.layers) {
            if (!Array.isArray(object.layers))
                throw TypeError(".Resolution.layers: array expected");
            message.layers = [];
            for (let i = 0; i < object.layers.length; ++i) {
                if (typeof object.layers[i] !== "object")
                    throw TypeError(".Resolution.layers: object expected");
                message.layers[i] = $root.Layer.fromObject(object.layers[i]);
            }
        }
        return message;
    };

    Resolution.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.layers = [];
        if (options.defaults)
            object.resolutionType = options.enums === String ? "SMALL_MOBILE" : 0;
        if (message.resolutionType != null && message.hasOwnProperty("resolutionType"))
            object.resolutionType = options.enums === String ? $root.ResolutionType[message.resolutionType] === undefined ? message.resolutionType : $root.ResolutionType[message.resolutionType] : message.resolutionType;
        if (message.layers && message.layers.length) {
            object.layers = [];
            for (let j = 0; j < message.layers.length; ++j)
                object.layers[j] = $root.Layer.toObject(message.layers[j], options);
        }
        return object;
    };

    Resolution.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Resolution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Resolution";
    };

    return Resolution;
})();

export const ContentLayoutType = $root.ContentLayoutType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "GRID"] = 0;
    values[valuesById[1] = "LIST"] = 1;
    values[valuesById[2] = "LANDING_PAGE"] = 2;
    return values;
})();

export const LayoutTheme = $root.LayoutTheme = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "AUTO_CASHIER"] = 0;
    values[valuesById[1] = "REFILL"] = 1;
    values[valuesById[2] = "LANDING_PAGE_LIGHT"] = 2;
    values[valuesById[3] = "LANDING_PAGE_DARK"] = 3;
    return values;
})();

export const LayoutLayerType = $root.LayoutLayerType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "GRADIENT"] = 0;
    values[valuesById[1] = "IMAGE"] = 1;
    values[valuesById[2] = "LOTTIE"] = 2;
    values[valuesById[3] = "VECTOR_IMAGE"] = 3;
    values[valuesById[4] = "COLOR"] = 4;
    return values;
})();

export const Anchor = $root.Anchor = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "TOP"] = 0;
    values[valuesById[1] = "BOTTOM"] = 1;
    values[valuesById[2] = "LEFT"] = 2;
    values[valuesById[3] = "RIGHT"] = 3;
    values[valuesById[4] = "UNDER_CONTENT"] = 4;
    return values;
})();

export const ResolutionType = $root.ResolutionType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "SMALL_MOBILE"] = 0;
    values[valuesById[1] = "MOBILE"] = 1;
    values[valuesById[2] = "TABLET"] = 2;
    values[valuesById[3] = "WINDOW"] = 3;
    return values;
})();

export { $root as default };
