import { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import loadCompleteProfileInfo from "state/flows/loadCompleteProfileInfo";
import { userSelectors, profilesCacheSelectors } from "state/selectors";

const selector = (state) => ({
  accountId: userSelectors.getMyAccountId(state),
  isProfileUpToDate: userSelectors.isMyProfileUpToDate(state),
  isLoadingMyProfile: userSelectors.isLoadingMyProfile(state),
});

const useMyProfile = (refreshIfStale = true) => {
  const dispatch = useDispatch();
  const { accountId, isProfileUpToDate, isLoadingMyProfile } = useSelector(
    selector,
    shallowEqual
  );
  const basicProfile = useSelector(
    (state) =>
      accountId && profilesCacheSelectors.getBasicProfile(state, accountId),
    shallowEqual
  );
  const profileDetails = useSelector(
    (state) =>
      accountId && profilesCacheSelectors.getProfileDetails(state, accountId),
    shallowEqual
  );
  const followStats = useSelector(
    (state) =>
      accountId && profilesCacheSelectors.getFollowStats(state, accountId),
    shallowEqual
  );
  const liveStats = useSelector(
    (state) =>
      accountId && profilesCacheSelectors.getLiveStats(state, accountId),
    shallowEqual
  );
  useEffect(() => {
    if (
      accountId &&
      !isLoadingMyProfile &&
      !isProfileUpToDate &&
      refreshIfStale
    ) {
      dispatch(loadCompleteProfileInfo(accountId));
    }
  }, [isProfileUpToDate, refreshIfStale]);
  return accountId
    ? {
        accountId,
        isProfileUpToDate,
        isLoadingMyProfile,
        basicProfile,
        profileDetails,
        followStats,
        liveStats,
      }
    : {};
};

export default useMyProfile;
