import React, { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import {
  EventFields,
  SCREEN_NAME,
  ScreenViewReporter,
} from "src/features/signin/imports/analytics";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
} from "src/features/signin/imports/ui";
import { resetPhoneLogin } from "src/features/signin/state/login/actionCreators";
import { useBreakpointPrecise } from "src/ui/hooks/useBreakpoint";
import { ReactComponent as ErrorIcon } from "img/emptyState/error_64.svg";
import styles from "./LoginFailed.scss";

export const GenericFailureReason = () => (
  <FormattedMessage
    id="modal.login.failed.title"
    defaultMessage="Login failed"
  />
);

export const ReateLimitedFailureReason = () => (
  <FormattedMessage
    id="modal.login.failed.too.many.attempts"
    defaultMessage="Too many failed registration attempts"
  />
);

interface LoginFailedProps {
  errorMessage?: string;
}

const LoginFailed: FC<LoginFailedProps> = ({ children, errorMessage }) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();

  const handleBackClick = useCallback(() => {
    dispatch(resetPhoneLogin());
  }, [dispatch]);

  const additionalParams = {
    ...(errorMessage && { [EventFields.COMMENT]: errorMessage }),
  };

  return (
    <>
      <ScreenViewReporter
        name={SCREEN_NAME.REGISTRATION_FAIL_DIALOG}
        additionalParams={additionalParams}
      />

      <div className={classnames(styles.root, styles[breakpoint])}>
        <ErrorIcon className={styles.icon} />

        <Typography type={TYPOGRAPHY_TYPE.HEADLINE3} className={styles.title}>
          {children}
        </Typography>
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH3}
          className={styles.description}
        >
          <FormattedMessage
            id="modal.login.failed.retry.later"
            defaultMessage="Please retry later"
          />
        </Typography>

        <Button
          className={styles.backButton}
          onClick={handleBackClick}
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.SECONDARY}
        >
          <FormattedMessage id="back" defaultMessage="Back" />
        </Button>
      </div>
    </>
  );
};

export default LoginFailed;
