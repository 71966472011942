import { useCallback, useEffect, useRef } from "react";
import { Nullable } from "src/types/common";

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback: () => void, delay?: Nullable<number>) => {
  const savedCallback = useRef(callback);
  const savedIntervalId = useRef<number>();
  const delayRef = useRef(delay);

  const restartInterval = useCallback(() => {
    clearInterval(savedIntervalId.current);

    if (delayRef.current) {
      savedIntervalId.current = window.setInterval(
        () => savedCallback.current(),
        delayRef.current
      );
    }
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    delayRef.current = delay;
    restartInterval();
  }, [delay]);

  useEffect(() => () => clearInterval(savedIntervalId.current), []);

  return restartInterval;
};

export default useInterval;
