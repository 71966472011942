import React, { FC } from "react";
import { useMount } from "utils/miniReactUse";

interface UserDataSecurityGateOpenerProps {
  onMount: VoidFunction;
}

export const UserDataSecurityGateOpener: FC<
  UserDataSecurityGateOpenerProps
> = ({ children, onMount }) => {
  useMount(onMount);

  return <>{children}</>;
};
