import { defineMessages } from "react-intl";

export const messages = defineMessages({
  someoneScreenshotted: {
    id: "someone.took.a.screenshot",
    defaultMessage: "{someone} took a screenshot",
  },
  youScreenshotted: {
    id: "you_took_a_screenshot",
    defaultMessage: "You took a screenshot",
  },
});
