import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { emitUiAction } from "@analytics/emitUiAction";
import { SIGNOUT_TARGET } from "@analytics/enums";
import { DeviceType, ModalType } from "src/enums";
import { logout } from "src/features/signin/exports/state/flows";
import { VoidCallback } from "src/types/common";
import { openConfirmationBottomScreen } from "state/actionCreators/bottomScreen";
import {
  dismissBatchModals,
  openConfirmationModal,
} from "state/actionCreators/modal";
import { deviceInfoSelectors, modalSelectors } from "state/selectors";
import sharedMessages from "ui/common/intl/sharedMessages";

interface UseLogoutParams {
  onClose?: VoidCallback;
  reason?: string;
}

const useLogout = ({ onClose, reason }: UseLogoutParams) => {
  const dispatch = useDispatch();
  const modalStack = useSelector(modalSelectors.getModalStack);
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const isMobile = useSelector(deviceInfoSelectors.isAnyMobileDevice);
  const isLandscape = window.innerHeight < window.innerWidth;
  const history = useHistory();

  const openConfirmation =
    isMobile || (deviceType === DeviceType.IPAD && !isLandscape)
      ? openConfirmationBottomScreen
      : openConfirmationModal;

  return useCallback(() => {
    batch(() => {
      if (modalStack.length > 0) {
        dispatch(dismissBatchModals(modalStack));
        onClose?.();
      }

      dispatch(
        // TODO types
        // @ts-ignore
        openConfirmation({
          title: sharedMessages.logout,
          modalType: ModalType.LOGOUT_MODAL,
          body: (
            <FormattedMessage
              id="modal.logoutConfirmation.bodyText"
              defaultMessage="Are you sure you want to log out?"
            />
          ),
          confirm: () => {
            emitUiAction({
              target: SIGNOUT_TARGET.SIGNOUT_CONFIRM,
            });
            dispatch(logout({ reason, history }));
          },
          dismiss: () => {
            emitUiAction({
              target: SIGNOUT_TARGET.SIGNOUT_CANCEL,
            });
          },
          isConfirmButtonHighlighted: true,
        })
      );
    });
  }, [modalStack, dispatch, openConfirmation, onClose, reason, history]);
};

export default useLogout;
