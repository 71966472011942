import { getEnvironmentName } from "environment";
import DatadogTransport from "src/analytics/DatadogUtils";

export const clearPersistLandingPageKey = () => {
  try {
    const key = `persist:${getEnvironmentName()}:landingPagePhoenix`;

    const raw = localStorage.getItem(key);
    const persisted = raw ? JSON.parse(raw) : null;

    if (!persisted) {
      return;
    }

    const { selectedCampaignId, ...rest } = persisted;

    localStorage.setItem(key, JSON.stringify(rest));
  } catch (error) {
    if (error instanceof Error) {
      DatadogTransport.error("Failed to clear persist landing page", {}, error);
    }
  }
};
