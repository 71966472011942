import { useContext, useMemo } from "react";
import { AnalyticsScreenDataContext } from "chat/components/AnalyticsScreenDataContext";
import { EventFields, emitUiAction } from "chat/imports/analytics";

export interface LiveProfileData {
  isUserInLive: true;
  isUserInPremium: boolean;
  streamId: string;
  userId: string;
}

export interface ProfileData {
  isUserInLive?: undefined;
  isUserInPremium?: undefined;
  streamId?: undefined;
  userId: string;
}

export interface UnsupportedProfileData {
  isUserInLive?: undefined;
  isUserInPremium?: undefined;
  streamId?: undefined;
  userId: undefined;
}

enum ProfileAvatarClickTargetProperty {
  LIVE_STREAM_COVER = "live_stream_cover",
  PROFILE = "profile",
}

enum ProfileAvatarClickTextProperty {
  LIVE = "live",
  PREMIUM = "premium",
  PROFILE = "profile",
}

export const useProfileAvatarClickTracker = ({
  isUserInLive,
  isUserInPremium,
  streamId,
  userId: peerId,
}: LiveProfileData | ProfileData | UnsupportedProfileData) => {
  const analyticsScreenDataContext = useContext(AnalyticsScreenDataContext);

  const analyticsTargetData = useMemo(() => {
    if (!peerId) {
      return null;
    }

    if (!isUserInLive) {
      return {
        [EventFields.TARGET]: ProfileAvatarClickTargetProperty.PROFILE,
        [EventFields.PEER_ID]: peerId,
        [EventFields.TEXT]: ProfileAvatarClickTextProperty.PROFILE,
      };
    }

    if (!isUserInPremium) {
      return {
        [EventFields.TARGET]:
          ProfileAvatarClickTargetProperty.LIVE_STREAM_COVER,
        [EventFields.PEER_ID]: peerId,
        [EventFields.TEXT]: ProfileAvatarClickTextProperty.LIVE,
        [EventFields.STREAM_ID]: streamId,
      };
    }

    return {
      [EventFields.TARGET]: ProfileAvatarClickTargetProperty.LIVE_STREAM_COVER,
      [EventFields.PEER_ID]: peerId,
      [EventFields.TEXT]: ProfileAvatarClickTextProperty.PREMIUM,
      [EventFields.STREAM_ID]: streamId,
    };
  }, [isUserInLive, isUserInPremium, peerId, streamId]);

  const analyticsFormattedData = useMemo(() => {
    if (!analyticsScreenDataContext || !analyticsTargetData) {
      return null;
    }

    const { [EventFields.TARGET]: target, ...additionalTargetData } =
      analyticsTargetData;

    const { [EventFields.TANGO_SCREEN]: tangoScreen, ...additionalScreenData } =
      analyticsScreenDataContext;

    return {
      [EventFields.TANGO_SCREEN]: tangoScreen,
      [EventFields.TARGET]: target,
      additionalParams: {
        ...additionalScreenData,
        ...additionalTargetData,
      },
    };
  }, [analyticsScreenDataContext, analyticsTargetData]);

  return useMemo(
    () =>
      analyticsFormattedData
        ? () => emitUiAction(analyticsFormattedData)
        : null,
    [analyticsFormattedData]
  );
};
