export const getVideoInfo = (videoFile: File) =>
  new Promise<{ duration: number; height: number; width: number }>(
    (resolve) => {
      const video = document.createElement("video");

      video.onloadedmetadata = function () {
        const duration = Math.round(video.duration);
        const width = video.videoWidth;
        const height = video.videoHeight;

        URL.revokeObjectURL(video.src);

        resolve({ duration, width, height });
      };

      video.src = URL.createObjectURL(videoFile);
    }
  );
