import { createSlice } from "@reduxjs/toolkit";
import {
  AutoLoginResult,
  AutoLoginState,
} from "src/features/autoLogin/state/types";

const initialState: AutoLoginState = {
  inProgress: false,
  isAutoLoginSession: false,
  result: AutoLoginResult.UNKNOWN,
};

const slice = createSlice({
  name: "autoLogin",
  initialState,
  reducers: {
    finishFailure: (state) => {
      state.inProgress = false;
      state.isAutoLoginSession = true;
      state.result = AutoLoginResult.FAILURE;
    },
    finishSuccess: (state) => {
      state.inProgress = false;
      state.isAutoLoginSession = true;
      state.result = AutoLoginResult.SUCCESS;
    },
    start: (state) => {
      state.inProgress = true;
      state.isAutoLoginSession = true;
      state.result = AutoLoginResult.UNKNOWN;
    },
  },
});

export const {
  reducer: autoLoginReducer,
  actions: {
    finishFailure: finishAutoLoginFailure,
    finishSuccess: finishAutoLoginSuccess,
    start: startAutoLogin,
  },
} = slice;
