import React, { FC, memo } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import classnames from "classnames";
import { unsupportedMessages } from "chat/components/common/messages";
import {
  APP_STORE_LINK,
  Breakpoints,
  GOOGLE_PLAY_LINK,
} from "chat/imports/constants";
import { getFirebaseAppDeeplink } from "chat/imports/environment";
import { useBreakpoint } from "chat/imports/hooks";
import { MessageConfig } from "./Message";
import styles from "./UnsupportedMessage.scss";

interface UnsupportedMessageProps {
  message?: MessageDescriptor;
  messageConfig: MessageConfig;
}

const UnsupportedMessage: FC<UnsupportedMessageProps> = ({
  message = unsupportedMessages.default_short,
  messageConfig,
}) => {
  const isMobile = useBreakpoint() !== Breakpoints.DESKTOP;
  const deeplink = getFirebaseAppDeeplink();
  const shouldUseStoreLink = !isMobile || !deeplink;

  return (
    <div
      className={classnames(styles.root, {
        [styles.myMessage]: messageConfig.isMyMessage,
      })}
    >
      <FormattedMessage
        {...message}
        values={{
          br: <br />,
          appStoreLink: (
            <a
              href={shouldUseStoreLink ? APP_STORE_LINK : deeplink}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.link}
            >
              <FormattedMessage
                id="chat.unsupported-message.app-store"
                defaultMessage="App Store"
              />
            </a>
          ),
          googlePlayLink: (
            <a
              href={shouldUseStoreLink ? GOOGLE_PLAY_LINK : deeplink}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.link}
            >
              <FormattedMessage
                id="chat.unsupported-message.google-play"
                defaultMessage="Google Play"
              />
            </a>
          ),
        }}
      />
    </div>
  );
};

export default memo(UnsupportedMessage);
