import { Nullable } from "src/types/common";

// https://github.com/facebook/react/issues/11538#issuecomment-417504600
export default () => {
  /* eslint-disable prefer-rest-params */
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function <T extends Node>(child: T): T {
      if (child.parentNode !== this) {
        return child as T;
      }

      return originalRemoveChild.apply(this, [child]) as T;
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function <T extends Node>(
      newNode: T,
      referenceNode: Nullable<Node>
    ): T {
      if (referenceNode && referenceNode.parentNode !== this) {
        return newNode as T;
      }

      return originalInsertBefore.apply(this, [newNode, referenceNode]) as T;
    };
  }
};
