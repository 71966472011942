import { Nullable } from "src/types/common";

export enum MosItemType {
  AI_GIFT = "AI_GIFT",
  ALGORITHM_PERSONAL_GIFT = "ALGORITHM_PERSONAL_GIFT",
  ALGORITHM_TOP_GIFT_PREFERRED_BY_USER = "ALGORITHM_TOP_GIFT_PREFERRED_BY_USER",
  ALGORITHM_TOP_GIFT_TOP_SENT_GIFT = "ALGORITHM_TOP_GIFT_TOP_SENT_GIFT",
  DEFAULT_GIFT = "DEFAULT_GIFT",
  FOLLOW_GIFT = "FOLLOW_GIFT",
  INVENTORY_GIFT = "INVENTORY_GIFT",
  OFFER_PLACEHOLDER = "OFFER_PLACEHOLDER",
  STREAM_GAME_GIFT_PLACEHOLDER = "STREAM_GAME_GIFT_PLACEHOLDER",
  TANGO_CARD_ITEM = "TANGO_CARD_ITEM",
  UNRECOGNIZED_MOS_ITEM_TYPE = "UNRECOGNIZED_MOS_ITEM_TYPE",
}

export enum MosItemTypeAbbreviation {
  AI_GIFT = "AI",
  ALGORITHM_PERSONAL_GIFT = "PG",
  ALGORITHM_TOP_GIFT_PREFERRED_BY_USER = "GP",
  ALGORITHM_TOP_GIFT_TOP_SENT_GIFT = "TG",
  DEFAULT_GIFT = "DG",
  FOLLOW_GIFT = "FG",
  INVENTORY_GIFT = "IN",
  OFFER_PLACEHOLDER = "OF",
  STREAM_GAME_GIFT_PLACEHOLDER = "GG",
  TANGO_CARD_ITEM = "TC",
}

export enum MosVersion {
  V0 = "V0",
  V1 = "V1",
  V2 = "V2",
}

export enum SupportedMosItemType {
  ALGORITHM_PERSONAL_GIFT = MosItemType.ALGORITHM_PERSONAL_GIFT,
  ALGORITHM_TOP_GIFT_TOP_SENT_GIFT = MosItemType.ALGORITHM_TOP_GIFT_TOP_SENT_GIFT,
  DEFAULT_GIFT = MosItemType.DEFAULT_GIFT,
  INVENTORY_GIFT = MosItemType.INVENTORY_GIFT,
}

export type BucketsMap = {
  [key: string]: {
    giftsIds: GiftId[];
    type: MosItemType;
    typeAbbreviation: MosItemTypeAbbreviation;
  };
};

export interface Gift {
  assetBundle: string;
  comboAnimationUrl: string;
  drawerAnimationUrl: string;
  giftKind: string;
  icon: string;
  id: GiftId;
  lottieAnimationUrl: string;
  lottieAnimationZipUrl: string;
  name: string;
  nonStandardResolution: boolean;
  notCollectedIcon: string;
  priceInCredit: number;
  priceInPoint: number;
  streamerId: string;
  vipLevel: number;
}

export interface GiftBalance {
  amount: number;
  gift: GiftId;
}

export type GiftId = string;

export interface MosData {
  items: MosUiItem[];
  missedGiftsIds: GiftId[];
  version: MosVersion;
}

export interface MosUiItem {
  content: { gift: Gift };
  type: MosItemType;
  typeAbbreviation: string;
}

export interface MosItem {
  giftReference?: {
    encryptedGiftId: string;
  };
  giftRichModel?: {
    gift: Gift;
  };
  type: MosItemType;
  typeAbbreviation: string;
}

export interface MosV2State {
  error: Nullable<string>;
  items: MosItem[];
  loading: boolean;
}

export interface FetchMosV2LineupParams {
  iso2CountryCode: string;
  streamId: string;
  streamerId: string;
}
export interface MosV2LineupSuccess {
  items: MosItem[];
}

export interface FetchMosV2LineupResponse {
  items: MosItem[];
  lineupId: string;
}

export enum MosError {
  SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
}

export interface RejectError {
  message: string;
}

export interface MosV2LineupRequestRejectError {
  rejectValue: RejectError;
}
