export enum RealTimeRecommendationsPlatform {
  ANDROID = 1,
  IOS = 2,
  WEB = 3,
}

export enum RealTimeRecommendationsEventType {
  FOLLOW = "realtime_follow",
  GIFT = "realtime_gift",
  REPORT = "realtime_report",
  UNFOLLOW = "realtime_unfollow",
  UNKNOWN = "",
  VIEW_END = "realtime_view_end",
  VIEW_START = "realtime_view_start",
}

export enum RealTimeRecommendationsViewEndType {
  PIP = "pip",
  STREAM_END = "stream_end",
  STREAM_EXIT = "stream_exit",
  STREAMER_PROFILE_VIEW = "streamer_profile_view",
  SWIPE_DOWN = "swipe_down",
  SWIPE_UP = "swipe_up",
}

export enum RealTimeRecommendationsGiftFromType {
  GIFT_DRAWER = "gift_drawer",
  GIFT_MOODS = "gift_moods",
  GIFT_STICKER = "gift_sticker",
  GIFT_TCNN = "gift_tcnn",
}

export enum RealTimeRecommendationsFollowFromType {
  STREAM_FOLLOW_BUTTON = "stream_follow_button",
  STREAMER_MINI_PROFILE = "streamer_mini_profile",
}

export enum RealTimeRecommendationsUnfollowFromType {
  STREAMER_BLOCKED = "streamer_blocked",
  STREAMER_MINI_PROFILE = "streamer_mini_profile",
}

export enum RealTimeRecommendationUnfollowSource {
  STREAMER_BLOCKED = 0,
}

export enum RealTimeRecommendationsParamKey {
  END_TYPE = "end_type",
  FOLLOW_FROM = "follow_from",
  GIFT_CREDITS_PRICE = "gift_credits_price",
  GIFT_FROM = "gift_from",
  PREVIOUS_SCREEN_NAME = "previous_screen_name",
  PREVIOUS_SCREEN_STATE = "previous_screen_state",
  REPORT_REASON = "report_reason",
  STREAM_ID = "stream_id",
  STREAMER_ID = "streamer_id",
  UNFOLLOW_FROM = "unfollow_from",
}
