const estimatePriceWithoutBonus = (price?: number, discount = 0.2): number => {
  if (!price) {
    return 0;
  }

  const rawPrice = price * (1 + discount);
  const dotIndex = `${rawPrice}`.indexOf(".");
  const stringWithoutDot = `${rawPrice}`.replace(".", "");
  const firstTwoDigits = Math.round(Number(stringWithoutDot.substring(0, 2)));
  const third = Number(stringWithoutDot[2]) > 5 ? 10 : 5;

  let result = Number(`${firstTwoDigits}0`) + third;

  if (dotIndex === 1) {
    result = result / 100;
  } else if (dotIndex === 2) {
    result = result / 10;
  } else if (dotIndex > 3) {
    result = result * Math.pow(10, dotIndex - 3);
  }

  return result - 0.01;
};

export default estimatePriceWithoutBonus;
