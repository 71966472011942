import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import vipConfigsActions from "state/actionCreators/vipConfigs";
import { RootState } from "state/delegate";
import { loginSelectors } from "state/selectors";

const selector = (state: RootState) => ({
  isAuthorized: loginSelectors.isAuthorized(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
});

const useLoadVipConfigs = () => {
  const dispatch = useDispatch();
  // TODO: remove isAuthorized once guest is disabled (https://tango-me.atlassian.net/browse/WEB-6577)
  const { isAuthorized, isLoggedIn } = useSelector(selector, shallowEqual);

  useEffect(() => {
    if (!isAuthorized && !isLoggedIn) {
      return;
    }

    dispatch(vipConfigsActions.fetchVipConfigs({ resetOnError: true }));
  }, [dispatch, isAuthorized, isLoggedIn]);
};

export default useLoadVipConfigs;
