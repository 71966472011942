import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import emptyFunction from "fbjs/lib/emptyFunction";
import { CHAT_SOURCE_DIRECT_LINK } from "@analytics/chatSource";
import {
  createLocationStateForLiveStreamFeed,
  createLocationStateForViralityLink,
} from "@analytics/livePlay/streamLocationState";
import { fetchListByAccountId } from "api/stream";
import composeQueryString from "api/utils/composeQueryString";
import {
  SETTINGS_SCREEN_APP,
  SETTINGS_SCREEN_PAYMENT,
} from "enums/settingsScreenType";
import { TANGO_ACCOUNT_ID } from "src/constants";
import { LiveFeedType } from "src/enums";
import { Nullable } from "src/types/common";
import { getIsInJosh } from "src/utils/joshUtils";
import { useMount } from "src/utils/miniReactUse";
import { changeUsername } from "state/actionCreators/connectionManager";
import { refresh } from "state/flows/loadLiveStreamsFeeds";
import { userSelectors } from "state/selectors";
import { Target } from "ui/navigation/deepLink/types";
import useDeepLinkActions from "ui/navigation/deepLink/useDeepLinkActions";
import {
  deepLink,
  defaultRoute,
  linkToChat,
  linkToCreatorsTab,
  linkToFeed,
  linkToGiftCards,
  linkToLiveStream,
  linkToLiveStreamHottestTab,
  linkToLiveStreamLatestTab,
  linkToLiveStreamNearbyTab,
  linkToPrivacyPolicy,
  makeLinkToChat,
  makeLinkToHappyMoment,
  makeLinkToLiveStream,
  makeLinkToLiveStreamsFeed,
  makeLinkToProfile,
  makeLinkToSettingsScreen,
  makeLinkToSocialGames,
} from "ui/navigation/links";
import useQuery from "ui/navigation/useQuery";

export const WEB_VIEW_SOURCE_KEY = "webviewSource";

const DeepLinkIntentExtractor: React.FC = () => {
  const history = useHistory();
  const accountId = useSelector(userSelectors.getMyAccountId);
  const dispatch = useDispatch();
  const query = useQuery();
  const allQueryParams = Object.fromEntries(query.entries());
  const { extractDeepLinkAction, setTaskByAdditionalAction, setTaskByTarget } =
    useDeepLinkActions();
  const deepLinkAction = useMemo(
    () => extractDeepLinkAction(window.location.href),
    [extractDeepLinkAction]
  );
  const {
    target,
    additionalAction,
    username,
    targetUid,
    targetSocialGameId,
    targetStreamId,
    targetStoryId,
    targetConversationId,
    tab,
    webview,
  } = deepLinkAction;

  const isRedirectToFirstStreamEnabled =
    getIsInJosh() && target === Target.LIVE;

  const getDeepLinkTargets = useCallback(
    (target: Nullable<Target>) => {
      switch (target) {
        case Target.NEARBY: {
          return linkToLiveStreamNearbyTab;
        }
        case Target.LATEST: {
          return linkToLiveStreamLatestTab;
        }
        case Target.TIMELINE: {
          return linkToFeed;
        }
        case Target.NEWS: {
          return linkToFeed;
        }
        case Target.PROFILE: {
          return accountId ? makeLinkToProfile(accountId) : defaultRoute;
        }
        case Target.VIEW_PROFILE: {
          return targetUid ? makeLinkToProfile(targetUid) : defaultRoute;
        }
        case Target.PRIVACY: {
          return linkToPrivacyPolicy;
        }
        case Target.CONVERSATION: {
          return linkToChat;
        }
        case Target.CHAT_DETAIL: {
          return targetConversationId
            ? makeLinkToChat(targetConversationId, CHAT_SOURCE_DIRECT_LINK)
            : defaultRoute;
        }
        case Target.PROFILE_SETTINGS: {
          return defaultRoute;
        }
        case Target.APP_SETTINGS: {
          return makeLinkToSettingsScreen(SETTINGS_SCREEN_APP);
        }
        case Target.PAYMENT_SETTINGS: {
          return makeLinkToSettingsScreen(SETTINGS_SCREEN_PAYMENT);
        }
        case Target.AGENT: {
          return linkToLiveStreamHottestTab;
        }
        case Target.STREAM: {
          return targetStreamId
            ? makeLinkToLiveStream(
                targetStreamId,
                createLocationStateForViralityLink(targetUid)
              )
            : defaultRoute;
        }
        case Target.STORIES: {
          return targetStoryId
            ? makeLinkToHappyMoment(targetStoryId)
            : defaultRoute;
        }
        case Target.MOBILE_SETTINGS: {
          return makeLinkToSettingsScreen(SETTINGS_SCREEN_APP);
        }
        // profile/tango-id is set up to redirect to cs page with proper back navigation
        case Target.SUPPORT: {
          return makeLinkToProfile(TANGO_ACCOUNT_ID);
        }
        case Target.VOUCHER: {
          return linkToGiftCards;
        }
        case Target.EXTERNAL_REFERRAL: {
          return linkToCreatorsTab;
        }
        case Target.CREATORS: {
          return linkToCreatorsTab;
        }
        case Target.SOCIAL_GAMES: {
          return makeLinkToSocialGames(targetSocialGameId);
        }
        default:
          return null;
      }
    },
    [
      accountId,
      targetConversationId,
      targetStoryId,
      targetStreamId,
      targetSocialGameId,
    ]
  );

  useMount(() => {
    additionalAction && setTaskByAdditionalAction(deepLinkAction);
    if (isRedirectToFirstStreamEnabled) {
      return;
    } // we don't need to redirect to live streams grid in this case
    target && setTaskByTarget(deepLinkAction);
  });

  useMount(() => {
    if (username) {
      dispatch(changeUsername(username));
    }
  });

  useMount(() => {
    if (webview) {
      sessionStorage.setItem(WEB_VIEW_SOURCE_KEY, webview);
    }
  });

  useEffect(() => {
    if (!targetUid || target !== Target.VIEW_PROFILE) {
      return;
    }
    fetchListByAccountId({ accountId: targetUid })
      .then(({ result }) => {
        const streamId = result[0];
        const link =
          (streamId && makeLinkToLiveStream(streamId)?.pathname) ||
          (target && getDeepLinkTargets(target));
        history.replace(link);
      })
      .catch(emptyFunction);
  }, [targetUid, target, getDeepLinkTargets, history]);

  useEffect(() => {
    if (isRedirectToFirstStreamEnabled) {
      const preparedTab = tab || LiveFeedType.RECOMMENDED;
      dispatch(refresh(preparedTab))
        // @ts-ignore TODO: typescript
        .then((action) => {
          const streamId = action?.payload?.result?.[0];
          if (streamId) {
            history.replace(
              makeLinkToLiveStream(
                streamId,
                createLocationStateForLiveStreamFeed({
                  mode: preparedTab,
                  rank: 0,
                })
              )
            );

            return;
          }
          history.replace(makeLinkToLiveStreamsFeed(preparedTab));
        })
        .catch(() => {
          history.replace(makeLinkToLiveStreamsFeed(preparedTab));
        });
    }
  }, [dispatch, history, isRedirectToFirstStreamEnabled, tab]);

  if (
    (targetUid && target === Target.VIEW_PROFILE) ||
    isRedirectToFirstStreamEnabled
  ) {
    return null;
  }

  const to =
    (target === Target.LIVE &&
      makeLinkToLiveStreamsFeed(tab || LiveFeedType.POPULAR)) ||
    getDeepLinkTargets(target) ||
    linkToLiveStream;

  if (to === defaultRoute) {
    const utmParams = composeQueryString(allQueryParams);

    return <Redirect to={{ pathname: to, state: { utmParams } }} />;
  }

  return <Redirect to={to} />;
};

const DeepLinkGateRouter: React.FC = ({ children }) => (
  <Switch>
    <Route path={deepLink} component={DeepLinkIntentExtractor} />
    <Route>{children}</Route>
  </Switch>
);

export default DeepLinkGateRouter;
