import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { AdditionalParamsObject } from "./types";

type emitUiActionType = {
  action?: string;
  target: string | string[];
  [EventFields.TANGO_SCREEN]?: string | { reportedName: string };
  additionalParams?: AdditionalParamsObject;
};

export const emitUiAction = ({
  action = "click",
  target,
  tango_screen = "",
  additionalParams = {},
}: emitUiActionType) => {
  emitEvent(EventNames.UI_ACTION, {
    [EventFields.TANGO_SCREEN]:
      typeof tango_screen === "string"
        ? tango_screen
        : tango_screen.reportedName,
    [EventFields.ACTION]: action,
    [EventFields.TARGET]: target,
    ...additionalParams,
  });
};
