import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { WEBVIEW_QUERY_PARAM } from "src/core/webview/WebViewController/constants";
import useQuery from "ui/navigation/useQuery";

/*
Used to clean query parameters in the application in WebView mode. Next clicks
in the mobile app's "Open in browser" will open web application in the browser
with the clean URL.

This hook should be removed when the 'useWebViewDetector' hook no longer needs
to use the WebView query parameter.
*/
export const useWebViewQueryParamRemover = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();

  useEffect(() => {
    if (!query.has(WEBVIEW_QUERY_PARAM)) {
      return;
    }

    query.delete(WEBVIEW_QUERY_PARAM);
    history.replace(`${pathname}${query.toString() ? `?${query}` : ""}`);
  }, [history, pathname, query]);
};
