import {
  SET_ERROR_MESSAGE_FOR_CREDIT_CARD,
  REMOVE_ERROR_MESSAGE_FOR_CREDIT_CARD,
} from "state/actionTypes";
import { omit } from "src/utils/miniLodash";

export const persistConfig = {
  whitelist: ["errorMessages"],
};

const initialState = {
  errorMessages: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE_FOR_CREDIT_CARD: {
      const { id, message } = action.payload;
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          [id]: message,
        },
      };
    }
    case REMOVE_ERROR_MESSAGE_FOR_CREDIT_CARD: {
      const id = action.payload;
      const newMessages = omit(state.errorMessages, id);

      return { state, errorMessages: { ...newMessages } };
    }
  }
  return state;
};

export const actionCreators = {
  setErrorMessageForCard: (id, message) => ({
    type: SET_ERROR_MESSAGE_FOR_CREDIT_CARD,
    payload: {
      id,
      message,
    },
  }),
  removeErrorMessageForCard: (id) => ({
    type: REMOVE_ERROR_MESSAGE_FOR_CREDIT_CARD,
    payload: id,
  }),
};

export const selectors = {
  getErrorMessageByCardId: (state, id) => state.errorMessages[id],
  getErrorMessages: (state) => state.errorMessages,
};

export default reducer;
