import type { Dispatch, Store } from "redux";
import { AcmeServiceName } from "src/enums";
import { getSocialGamesEnabled } from "state/abTests";
import { ACME_RECEIVED } from "state/actionTypes";

interface FinanceAcmeAction {
  payload?: {
    serviceIdentifier: string;
    serviceName: string;
  };
  type: string;
}
export const financeServiceAcmeMiddleware =
  (store: Store) => (next: Dispatch) => (action: FinanceAcmeAction) => {
    const isFinanceAcmeEnabled = getSocialGamesEnabled(store.getState());

    if (
      action.type === ACME_RECEIVED &&
      action.payload?.serviceName === AcmeServiceName.FINANCE
    ) {
      next({
        ...action,
        payload: {
          ...action.payload,
          isEnabled: isFinanceAcmeEnabled,
        },
      });
      return;
    }

    next(action);
  };
