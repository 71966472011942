export const NEW_SIGN_UP_PROMOTION_TYPE_GIFT_OR_LIKE =
  "NEW_SIGN_UP_PROMOTION_TYPE_GIFT_OR_LIKE";
export const NEW_SIGN_UP_PROMOTION_TYPE_CHAT =
  "NEW_SIGN_UP_PROMOTION_TYPE_CHAT";
export const NEW_SIGN_UP_PROMOTION_TYPE_OTHER =
  "NEW_SIGN_UP_PROMOTION_TYPE_OTHER";
export const NEW_SIGN_UP_PROMOTION_TYPE_TRANSITION_TO_PRIVATE_STREAM =
  "NEW_SIGN_UP_PROMOTION_TYPE_TRANSITION_TO_PRIVATE_STREAM";
export const NEW_SIGN_UP_PROMOTION_LANDING_OFFERS =
  "NEW_SIGN_UP_PROMOTION_LANDING_OFFERS";
