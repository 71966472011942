import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CASHIER } from "enums/cashier";
import { PersonalOfferType } from "src/enums";
import { Nullable } from "src/types/common";
import { PersonalOffersViewType, PricePoint } from "src/types/personalOffer";
import personalOffersActions from "state/actionCreators/personalOffers";
import { personalOffersSelectors } from "state/selectors";
import { OFFER_TYPES } from "ui/common/personalOffer/constants";
import { BannerOffer } from "ui/scenes/landingPage/types";

interface usePersonalOffersStateProps {
  initialOffer?: Nullable<BannerOffer | PricePoint>;
  isLoadingEnabled?: boolean;
  viewType?: PersonalOffersViewType;
}

const usePersonalOffersState = ({
  initialOffer,
  viewType = CASHIER,
  isLoadingEnabled = true,
}: usePersonalOffersStateProps) => {
  const [selectedOffer, setSelectedOffer] = useState(initialOffer);
  const type = OFFER_TYPES[viewType] as
    | typeof PersonalOfferType.CASHIER
    | typeof PersonalOfferType.LOYALTY_OFFERS
    | typeof PersonalOfferType.REFILL;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoadingEnabled) {
      dispatch(
        personalOffersActions.fetchPersonalOffersConfig({
          type,
        })
      );
    }
  }, [dispatch, type, isLoadingEnabled]);

  const data = useSelector(
    personalOffersSelectors.personalOffersState,
    shallowEqual
  );
  const { loading, error } = useSelector(
    personalOffersSelectors.personalOffersStateMeta,
    shallowEqual
  );

  return {
    selectedOffer,
    setSelectedOffer,
    config: data?.[type],
    isLoading: loading,
    isError: !!error,
  };
};

export default usePersonalOffersState;
