import React, { FC, ReactEventHandler, memo, useRef } from "react";
import classnames from "classnames";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { useAlternativeDomainContentSupport } from "chat/hooks/useAlternativeDomainContentSupport";
import { useIntersectionObserver } from "chat/imports/hooks";
import { getMediaDimensionsStyle } from "chat/premiumMessage/common/getMediaDimensionsStyle";
import { MediaInfo } from "chat/premiumMessage/types";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import styles from "./PremiumVideo.scss";
import originStyles from "chat/components/currentConversation/VideoMessage.scss";

interface PremiumVideoProps {
  isShowOnlyPreview?: boolean;
  media: MediaInfo;
  messageConfig: MessageConfig;
  onEnded?: ReactEventHandler<HTMLVideoElement>;
}

const PremiumVideo: FC<PremiumVideoProps> = ({
  isShowOnlyPreview,
  media,
  onEnded,
  messageConfig,
}) => {
  const { width, height } = media;

  const contentUrl = useAlternativeDomainContentSupport(media.contentUrl);
  const thumbnailUrl = useAlternativeDomainContentSupport(media.thumbnailUrl);

  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useIntersectionObserver(ref, {
    rootMargin: "0px 0px 0px 0px",
  });

  const style = getMediaDimensionsStyle(width, height);

  return (
    <div
      ref={ref}
      className={classnames(
        styles.root,
        getGroupMessageClassnames(styles, messageConfig)
      )}
      data-testid="premium-video"
      style={style}
    >
      {isVisible && (
        <video
          className={classnames(
            originStyles.video,
            styles.video,
            originStyles.visible,
            getGroupMessageClassnames(originStyles, messageConfig)
          )}
          disablePictureInPicture
          controlsList="nodownload"
          preload="metadata"
          poster={thumbnailUrl}
          controls
          src={contentUrl}
          onEnded={onEnded}
          {...(isShowOnlyPreview && { muted: true, controls: false })}
        />
      )}
    </div>
  );
};

export default memo(PremiumVideo);
