import React, { FC, memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { match } from "ts-pattern";
import CodeVerification from "src/features/signin/components/CodeVerification/CodeVerification";
import CountrySelect from "src/features/signin/components/CountrySelect/CountrySelect";
import LoginFailed, {
  GenericFailureReason,
  ReateLimitedFailureReason,
} from "src/features/signin/components/LoginFailed/LoginFailed";
import PhoneNumberInput from "src/features/signin/components/PhoneNumberInput/PhoneNumberInput";
import ProvidersSkeleton from "src/features/signin/components/ProivdersSkeleton/ProvidersSkeleton";
import ProviderSelector from "src/features/signin/components/ProviderSelector/ProviderSelector";
import { useCaptchaRequired } from "src/features/signin/hooks";
import {
  EventFields,
  RegistrationSource,
  SCREEN_NAME,
  ScreenViewReporter,
} from "src/features/signin/imports/analytics";
import { LoginModalTitleType } from "src/features/signin/imports/enums";
import { RootState, loginSelectors } from "src/features/signin/imports/state";
import { Spinner } from "src/features/signin/imports/ui";
import LoginUnavailable from "src/features/signin/modal/LoginUnavailable";
import styles from "./ContentController.scss";

const selector = (state: RootState) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isLoginFailed: loginSelectors.isLoginFailed(state),
  loginInProgress: loginSelectors.isLoginInProgress(state),
  providers: loginSelectors.getAvailableProviders(state),
  phoneNumberAuthenticationState:
    loginSelectors.getPhoneNumberAuthenticationState(state),
  isPreparingProviders: loginSelectors.isPreparingProviders(state),
  errorMessage: loginSelectors.getLoginErrorMessage(state),
});

interface ContentControllerProps {
  registrationSource: RegistrationSource;
}

const ContentController: FC<ContentControllerProps> = ({
  registrationSource,
}) => {
  const isCaptchaRequired = useCaptchaRequired();
  const {
    providers,
    isLoggedIn,
    isLoginFailed,
    loginInProgress,
    isPreparingProviders,
    phoneNumberAuthenticationState,
    errorMessage,
  } = useSelector(selector, shallowEqual);

  const isSkeletonVisible = isPreparingProviders && providers.length === 0;
  const showSpinner = isLoggedIn || loginInProgress;

  if (isSkeletonVisible) {
    return <ProvidersSkeleton />;
  }

  if (showSpinner || isCaptchaRequired) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner
          className={styles.spinner}
          color="inherit"
          data-testid="login-modal-spinner"
        />
      </div>
    );
  }

  if (isLoginFailed) {
    return (
      <LoginFailed errorMessage={errorMessage}>
        <GenericFailureReason />
      </LoginFailed>
    );
  }

  return match(phoneNumberAuthenticationState)
    .with({ attemptLimitReached: true }, () => (
      <LoginFailed>
        <ReateLimitedFailureReason />
      </LoginFailed>
    ))
    .with({ phoneVerificationUnavailable: true }, () => (
      <>
        <ScreenViewReporter
          name={SCREEN_NAME.REGISTRATION_SIGN_IN}
          additionalParams={{
            [EventFields.SCREEN_STATE]: "unavailable_phone",
          }}
        />
        <LoginUnavailable registrationSource={registrationSource} />
      </>
    ))
    .with({ selectCountry: true }, () => <CountrySelect />)
    .with({ waitingForCode: true }, () => <CodeVerification />)
    .with({ continueWithPhone: true }, () => <PhoneNumberInput />)
    .otherwise(() => <ProviderSelector title={LoginModalTitleType.REGULAR} />);
};

export default memo(ContentController);
