import React, { FC } from "react";
import classnames from "classnames";
import { Skeleton } from "chat/imports/components";
import { useBreakpointPrecise } from "chat/imports/hooks";
import styles from "./CurrentConversationSkeleton.scss";

const CurrentConversationSkeleton: FC = () => {
  const breakpoints = useBreakpointPrecise();

  return (
    <div className={classnames(styles.root, styles[breakpoints])}>
      <div className={styles.date}>
        <Skeleton className={styles.skeleton} width={72} height={16} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.leftMessage)}>
        <Skeleton className={styles.skeleton} width={308} height={64} />
        <Skeleton className={styles.skeleton} width={180} height={40} />
      </div>
      <div className={styles.date}>
        <Skeleton className={styles.skeleton} width={72} height={16} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.rightMessage)}>
        <Skeleton className={styles.skeleton} width={308} height={110} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.leftMessage)}>
        <Skeleton className={styles.skeleton} width={230} height={40} />
      </div>
      <div className={styles.date}>
        <Skeleton className={styles.skeleton} width={72} height={16} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.rightMessage)}>
        <Skeleton className={styles.skeleton} width={230} height={40} />
      </div>
      <div className={styles.date}>
        <Skeleton className={styles.skeleton} width={72} height={16} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.leftMessage)}>
        <Skeleton className={styles.skeleton} width={308} height={64} />
        <Skeleton className={styles.skeleton} width={180} height={40} />
      </div>
      <div className={styles.date}>
        <Skeleton className={styles.skeleton} width={72} height={16} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.rightMessage)}>
        <Skeleton className={styles.skeleton} width={308} height={110} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.leftMessage)}>
        <Skeleton className={styles.skeleton} width={230} height={40} />
      </div>
      <div className={styles.date}>
        <Skeleton className={styles.skeleton} width={72} height={16} />
      </div>
      <div className={classnames(styles.messagesGroup, styles.rightMessage)}>
        <Skeleton className={styles.skeleton} width={230} height={40} />
      </div>
    </div>
  );
};

export default CurrentConversationSkeleton;
