export enum AppealExtraStatus {
  SUBMITTED = "SUBMITTED",
}

export enum AppealStatus {
  APPEAL_APPROVED = "APPEAL_APPROVED",
  APPEAL_REJECTED = "APPEAL_REJECTED",
  BAD_TOKEN = "BAD_TOKEN",
  NO_APPEAL = "NO_APPEAL,",
  OPEN_APPEAL = "OPEN_APPEAL,",
}

export enum AppealError {
  EXPIRED = "EXPIRED",
  SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
}

export interface CheckAppealParams {
  token: Token;
}

type Token = string;

export interface TokenData {
  accountId: string;
  comment: string;
  duration: number;
  exp: number;
  iss: string;
  premiumStream: boolean;
  screenshot: string;
  streamId: string;
  streamType: string;
}

export interface SendAppealParams {
  broadcasterComment: string;
  token: Token;
}
