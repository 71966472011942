import { defineMessage } from "react-intl";
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { ChatErrorType, ResponseCode } from "chat/enums";
import {
  CUSTOMER_SUPPORT_LINK,
  TANGO_ACCOUNT_ID,
} from "chat/imports/constants";
import { getChatIdForLoadLandingsFromShouts } from "chat/imports/environment";
import {
  ContentSharedPayload,
  encodeMessageToBase64,
  parseMessageFromBase64,
} from "chat/imports/proto";
import {
  ACME_RECEIVED,
  AsyncState,
  addAsyncCasesToBuilderV2,
  addUserSessionScopeReducer,
  initialFetcherStateMeta,
} from "chat/imports/state";
import { Nullable } from "chat/imports/types";
import {
  sendPremiumMediaMessage,
  sendPremiumMessage,
} from "chat/premiumMessage/state/asyncAction";
import { ContentSharedPayloadMessage } from "chat/premiumMessage/types";
import * as actions from "chat/state/actionCreators";
import {
  ConversationInfo,
  Message,
  MessageIdentifier,
  MessageMedia,
  MessageType,
} from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";

const autoreplyMessage = defineMessage({
  id: "chat.tango.autoreply",
  defaultMessage: `We love hearing from you! If you require assistance, please contact our Customer Support: {csLink}`,
});

let fakeMessageId = 0;

// for tests purpose only
export const resetFakeMessageId = (value = 0) => {
  fakeMessageId = value;
};

type StoredMessageMedia = {
  caption?: string;
  isUploading?: boolean;
} & MessageMedia;

export type StoredConversation = {
  hasMoreMessages?: boolean;
  isLoading?: boolean;
  isLoadingFailed?: boolean;
} & ConversationInfo;

export type StoredMessage = {
  error?: string;
  id: { requestId?: string } & MessageIdentifier;
  isBlurred?: boolean;
  isMediaLoading?: boolean;
  isPending?: boolean;
  isTranslated?: boolean;
  isTranslating?: boolean;
  language?: string;
  media?: StoredMessageMedia[];
  translation?: Record<string, string>;
} & Omit<Message, "id" | "media">;

export type ChatState = AsyncState<{
  canLoadMore: boolean;
  conversations: Record<string, StoredConversation>;
  currentConversationId: Nullable<string>;
  messages: Record<string, StoredMessage[]>;
  totalUnreadCount: number;
}>;

export const chatInitialState = {
  data: {
    currentConversationId: null,
    conversations: {},
    messages: {},
    canLoadMore: false,
    totalUnreadCount: 0,
  },
  meta: { ...initialFetcherStateMeta, stale: false },
};

const getUniqMessages = (messages: StoredMessage[]) =>
  Object.values(
    messages.reduce((acc: Record<number, StoredMessage>, next) => {
      const id = next.id.id;

      if (!acc[id]) {
        acc[id] = next;
      } else {
        acc[id] = { ...acc[id], ...next };
      }

      return acc;
    }, {})
  ).sort((a, b) => b.id.ts - a.id.ts);

const getMessageByMessageId = (
  messages: Record<string, StoredMessage[]>,
  conversationId: string,
  messageId: number
) => messages[conversationId].find((item) => item.id.id === messageId);

const reducer = createReducer<ChatState>(chatInitialState, (builder) => {
  addUserSessionScopeReducer(
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.fetchConversations,
      prepareData: (prevData, nextData, meta) => {
        const { isRefreshing } = meta.arg;
        const currentData = {
          ...prevData,
          canLoadMore: isRefreshing
            ? prevData.canLoadMore
            : !!nextData.has_more_conversations,
          totalUnreadCount: Number(nextData.total_unread_count),
        };

        return nextData.conversations
          ? nextData.conversations.reduce((acc, next) => {
              const { conversation, messages = [] } = next;
              const currentConversation =
                acc.conversations[conversation.conversation_id] || {};
              const currentMessages =
                acc.messages[conversation.conversation_id] || [];

              acc.conversations[conversation.conversation_id] = Object.assign(
                currentConversation,
                conversation
              );
              acc.messages[conversation.conversation_id] = getUniqMessages([
                ...currentMessages,
                ...messages,
              ]);

              return acc;
            }, currentData)
          : currentData;
      },
      initialData: chatInitialState.data,
    })
      .addCase(actions.setCurrentConversationId, (state, action) => {
        state.data.currentConversationId = action.payload;
      })
      .addCase(actions.updateChatMessageBlur, (state, action) => {
        const { conversationId, messageId, isBlurred } = action.payload;

        const message = state.data.messages[conversationId].find(
          (message) => Number(message.id.id) === messageId
        );

        if (message) {
          message.isBlurred = isBlurred;
        }
      })
      .addCase(actions.fetchConversation.pending, (state, action) => {
        const { conversationId } = action.meta.arg;
        const currentConversation =
          state.data.conversations[conversationId] ||
          (isGroupChatId(conversationId)
            ? {
                conversation_id: conversationId,
              }
            : {
                conversation_id: conversationId,
                account_info: {
                  account_id: conversationId,
                },
              });
        state.data.conversations[conversationId] = Object.assign(
          currentConversation,
          { isLoading: true, isLoadingFailed: false }
        );
      })
      .addCase(actions.fetchConversation.fulfilled, (state, action) => {
        const { conversationId } = action.meta.arg;
        if (action.payload.conversation) {
          const { conversation, has_more_messages, messages } =
            action.payload.conversation;
          state.data.conversations[conversationId] = Object.assign(
            state.data.conversations[conversationId],
            conversation,
            {
              hasMoreMessages: has_more_messages,
              isLoading: false,
              isLoadingFailed: false,
            }
          );

          if (messages) {
            state.data.messages[conversationId] = getUniqMessages([
              ...(state.data.messages[conversationId] || []),
              ...messages,
            ]);
          }

          return state;
        }

        if (
          action.payload.status.code ===
            ResponseCode.RESPONSE_STATUS_CODE_SVR_ERR ||
          (ResponseCode.RESPONSE_STATUS_CODE_MESSAGE_NONEXISTENT &&
            isGroupChatId(conversationId))
        ) {
          delete state.data.conversations[conversationId];
          delete state.data.messages[conversationId];

          return state;
        }

        if (
          action.payload.status.code ===
          ResponseCode.RESPONSE_STATUS_CODE_MESSAGE_NONEXISTENT
        ) {
          state.data.conversations[conversationId] = Object.assign(
            state.data.conversations[conversationId],
            {
              isLoading: false,
              isLoadingFailed: false,
            }
          );
        }
      })
      .addCase(actions.fetchConversation.rejected, (state, action) => {
        state.data.conversations[action.meta.arg.conversationId] = {
          ...state.data.conversations[action.meta.arg.conversationId],
          isLoading: false,
          isLoadingFailed: true,
        };
      })
      .addCase(actions.readMessages.fulfilled, (state, action) => {
        const { conversation_id } = action.meta.arg;
        const {
          unread_count,
          total_unread_count,
          status: { timestamp },
        } = action.payload;
        state.data.totalUnreadCount = Number(total_unread_count);
        state.data.conversations[conversation_id] = Object.assign(
          state.data.conversations[conversation_id],
          {
            unread_message_count: unread_count,
            last_self_read_message_ts: timestamp,
          }
        );
      })
      .addCase(actions.uploadImage.fulfilled, (state, action) => {
        const { messageId, retryId, conversationId } = action.meta.arg;
        const pendingMessage = (state.data.messages[conversationId] || []).find(
          (message) => message.id.requestId === (retryId || messageId)
        );

        if (!pendingMessage || !pendingMessage.media?.[0]) {
          return state;
        }

        const conversation = state.data.conversations[conversationId];
        if (conversation) {
          const lastMessageTimestamp = Date.now();
          conversation.last_message_ts = lastMessageTimestamp;
          conversation.last_self_read_message_ts = lastMessageTimestamp;
        }

        const { url, thumbnailUrl, width, height } = action.payload;
        pendingMessage.media[0] = Object.assign(pendingMessage.media[0], {
          download_url: url,
          thumbnail_url: thumbnailUrl,
          width,
          height,
          isUploading: false,
        });
      })
      .addCase(actions.getMessageTranslation.pending, (state, action) => {
        const { conversationId, messageId } = action.meta.arg;
        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );

        if (message) {
          message.isTranslated = false;
          message.isTranslating = true;
        }
      })
      .addCase(actions.getMessageTranslation.fulfilled, (state, action) => {
        const { conversationId, messageId, locale } = action.meta.arg;

        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );
        if (!message) {
          return state;
        }

        const { translated, language } = action.payload;

        message.language = language;
        if (!message.translation) {
          message.translation = {};
        }
        if (translated) {
          message.translation[locale] = translated;
          message.isTranslated = true;
        }
        message.isTranslating = false;

        if (message.error === ChatErrorType.TRANSLATION_ERROR) {
          message.error = undefined;
        }
      })
      .addCase(actions.getMessageTranslation.rejected, (state, action) => {
        const { conversationId, messageId } = action.meta.arg;
        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );

        if (message) {
          message.isTranslating = false;
          message.error = ChatErrorType.TRANSLATION_ERROR;
        }
      })
      .addCase(actions.setIsTranslated, (state, action) => {
        const { conversationId, messageId, isTranslated } = action.payload;
        const message = getMessageByMessageId(
          state.data.messages,
          conversationId,
          messageId
        );

        if (message) {
          message.isTranslated = isTranslated;
        }
      })
      .addCase(sendPremiumMediaMessage.pending, (state, action) => {
        const {
          arg: { conversationId, from },
          requestId,
        } = action.meta;

        if (!state.data.messages[conversationId]) {
          state.data.messages[conversationId] = [];
        }

        state.data.messages[conversationId].unshift({
          id: {
            id: --fakeMessageId,
            ts: Date.now(),
            chat_id: conversationId,
            requestId,
          },
          type: MessageType.PREMIUM_MESSAGE_SHARED,
          from,
          isPending: true,
          isMediaLoading: true,
        });
      })
      .addCase(sendPremiumMessage.pending, (state, action) => {
        const {
          arg: { conversationId, giftId, items, retryId, messageId },
        } = action.meta;

        const pendingMessage = state.data.messages[conversationId].find(
          (message) => message.id.requestId === (retryId || messageId)
        );

        const messageToRetry = retryId
          ? state.data.messages[conversationId].find(
              (message) => message.id.requestId === retryId
            )
          : null;

        if (retryId && !messageToRetry) {
          return state;
        }

        if (!pendingMessage) {
          return state;
        }

        if (messageToRetry) {
          messageToRetry.isPending = true;
          messageToRetry.error = undefined;

          return state;
        }

        delete pendingMessage.isMediaLoading;

        const { type, mediaInfo } = items[0];

        pendingMessage.payload = encodeMessageToBase64(
          {
            giftId,
            messageId: "",
            thumbnailBlurUrl: "",
            type,
            mediaInfo,
          },
          ContentSharedPayload
        );
      })
      .addCase(sendPremiumMessage.fulfilled, (state, action) => {
        const {
          arg: { conversationId, retryId, messageId },
        } = action.meta;
        const fakeId = retryId || messageId;

        const shareItemResponse = action.payload.items[0];

        const conversation = state.data.conversations[conversationId];

        if (conversation && Boolean(shareItemResponse.state)) {
          conversation.state = shareItemResponse.state;
        }

        const pendingMessage = state.data.messages[conversationId].find(
          (message) => message.id.requestId === fakeId
        );

        if (!pendingMessage) {
          return state;
        }

        if (conversation) {
          const lastMessageTimestamp = Date.now();
          conversation.last_message_ts = lastMessageTimestamp;
          conversation.last_self_read_message_ts = lastMessageTimestamp;
        }

        pendingMessage.id = {
          ...pendingMessage.id,
          ...shareItemResponse.tc2Id,
        };
        delete pendingMessage.isPending;

        const payloadData = parseMessageFromBase64(
          pendingMessage.payload,
          ContentSharedPayload
        ) as ContentSharedPayloadMessage;

        pendingMessage.payload = encodeMessageToBase64(
          {
            ...payloadData,
            messageId: shareItemResponse.messageId,
          },
          ContentSharedPayload
        );
      })
      .addCase(sendPremiumMessage.rejected, (state, action) => {
        const {
          arg: { conversationId, retryId, messageId },
        } = action.meta;

        const pendingMessage = state.data.messages[conversationId].find(
          (message) => message.id.requestId === (retryId || messageId)
        );

        if (!pendingMessage) {
          return state;
        }

        delete pendingMessage.isPending;
        pendingMessage.error = ChatErrorType.SEND_MESSAGE_ERROR;
      })
      .addCase(actions.removeConversation.pending, (state) => {
        state.meta.loading = true;
      })
      .addCase(actions.removeConversation.fulfilled, (state, action) => {
        if (action.meta.arg.conversationId) {
          delete state.data.conversations[action.meta.arg.conversationId];
          delete state.data.messages[action.meta.arg.conversationId];
        }
        state.meta.loading = false;
      })
      .addMatcher(
        isAnyOf(
          actions.sendTextMessage.pending,
          actions.sendImageMessage.pending
        ),
        (state, action) => {
          const {
            arg: { conversationId, body, imageUrl, from, retryId },
            requestId,
          } = action.meta;

          if (!state.data.messages[conversationId]) {
            state.data.messages[conversationId] = [];
          }

          const messageToRetry = retryId
            ? state.data.messages[conversationId].find(
                (message) => message.id.requestId === retryId
              )
            : null;

          if (retryId && !messageToRetry) {
            return state;
          }

          if (messageToRetry) {
            messageToRetry.isPending = true;
            messageToRetry.error = undefined;

            return state;
          }

          state.data.messages[conversationId].unshift({
            id: {
              id: --fakeMessageId,
              ts: Date.now(),
              chat_id: conversationId,
              requestId,
            },
            type: imageUrl
              ? MessageType.IMAGE_MESSAGE
              : MessageType.TEXT_MESSAGE,
            body,
            media: imageUrl
              ? [
                  {
                    thumbnail_url: imageUrl,
                    download_url: imageUrl,
                    isUploading: true,
                  },
                ]
              : undefined,
            from,
            isPending: true,
          });
        }
      )
      .addMatcher(
        isAnyOf(
          actions.sendTextMessage.fulfilled,
          actions.sendImageMessage.fulfilled
        ),
        (state, action) => {
          const {
            arg: { conversationId, formatMessage, retryId },
            requestId,
          } = action.meta;
          const fakeId = retryId || requestId;
          const responseDetails = action.payload.details?.[0];
          const isSystemChat =
            conversationId === TANGO_ACCOUNT_ID ||
            conversationId === getChatIdForLoadLandingsFromShouts();

          const conversation = state.data.conversations[conversationId];

          if (conversation && !!responseDetails?.state) {
            conversation.state = responseDetails.state;
          }

          if (conversation && conversation.hidden) {
            conversation.hidden = false;
          }

          if (!responseDetails?.id && !isSystemChat) {
            state.data.messages[conversationId] = state.data.messages[
              conversationId
            ].filter((message) => message.id.requestId !== fakeId);

            return state;
          }

          const pendingMessage = state.data.messages[conversationId].find(
            (message) => message.id.requestId === fakeId
          );

          if (!pendingMessage) {
            return state;
          }

          if (conversation) {
            const lastMessageTimestamp = Date.now();
            conversation.last_message_ts = lastMessageTimestamp;
            conversation.last_self_read_message_ts = lastMessageTimestamp;
          }

          pendingMessage.id = responseDetails?.id || pendingMessage.id;
          delete pendingMessage.isPending;

          if (isSystemChat) {
            state.data.messages[conversationId].unshift({
              id: {
                id: --fakeMessageId,
                ts: pendingMessage.id.ts + 1,
                chat_id: conversationId,
              },
              from: conversationId,
              type: MessageType.TEXT_MESSAGE,
              body: formatMessage(autoreplyMessage, {
                csLink: CUSTOMER_SUPPORT_LINK,
              }),
            });
          }
        }
      )
      .addMatcher(
        isAnyOf(
          actions.sendTextMessage.rejected,
          actions.sendImageMessage.rejected
        ),
        (state, action) => {
          const {
            arg: { conversationId, retryId },
            requestId,
          } = action.meta;

          const pendingMessage = state.data.messages[conversationId].find(
            (message) => message.id.requestId === (retryId || requestId)
          );

          if (!pendingMessage) {
            return state;
          }

          delete pendingMessage.isPending;
          pendingMessage.error = ChatErrorType.SEND_MESSAGE_ERROR;
        }
      )
      .addMatcher(
        (action) => action.type === ACME_RECEIVED,
        (state) => {
          state.meta.stale = true;
        }
      ),
    () => chatInitialState
  );
});

export default reducer;
