import { enhancedFetch } from "src/features/autoLogin/imports/api";
import { getProxycadorHost } from "src/features/autoLogin/imports/environment";

interface AutoLoginWithTokenParams {
  accountId: string;
  capabilities: string[];
  fingerprint: string;
  locale: string;
  osVersion: string;
  token: string;
  version: string;
}

interface AutoLoginWithTokenResponse {
  accountId: string;
  analyticsKey: string;
  deviceToken: string;
  hordemasterDetails: { hostname: string; port: number };
  username: string;
}

export const autoLoginWithToken = async ({
  accountId,
  capabilities,
  fingerprint,
  locale,
  osVersion,
  token,
  version,
}: AutoLoginWithTokenParams): Promise<AutoLoginWithTokenResponse> => {
  const response = await enhancedFetch(
    `${getProxycadorHost()}/autologin/token/v1/login`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        accountId,
        clientDetails: { capabilities, fingerprint, osVersion, version },
        locale,
        token,
      }),
    }
  );

  return response.json();
};
