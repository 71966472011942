import { createAction, createReducer } from "@reduxjs/toolkit";

interface DropdownState {
  isAvatarMenuVisible?: boolean;
  isBurgerMenuVisible?: boolean;
  shouldHideInstantly?: boolean;
}

const initialState = {
  isBurgerMenuVisible: false,
  isAvatarMenuVisible: false,
  shouldHideInstantly: false,
};

export const setDropdownMenuState = createAction<DropdownState>(
  "lwc/dropdownMenu/setDropdownMenuState"
);

export const setDropdownAvatarState = createAction<DropdownState>(
  "lwc/dropdownMenu/setAvatarMenuState"
);

const reducer = createReducer<DropdownState>(initialState, (builder) => {
  builder
    .addCase(setDropdownMenuState, (state, action) => {
      const { isBurgerMenuVisible, shouldHideInstantly } = action.payload;

      Object.assign(state, {
        isBurgerMenuVisible,
        shouldHideInstantly,
      });
    })
    .addCase(setDropdownAvatarState, (state, action) => {
      const { isAvatarMenuVisible, shouldHideInstantly } = action.payload;

      Object.assign(state, {
        isAvatarMenuVisible,
        shouldHideInstantly,
      });
    });
});

export const localDropdownMenuSelectors = {
  getDropdownMenuState: (state: DropdownState) => state,
};

export default reducer;
