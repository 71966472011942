import Cookies from "js-cookie";
import { AdditionalDataPoints, SearchParams } from "@analytics/enums";
import {
  GA_COOKIE_NAME,
  UTM_COOKIE_NAME_FIRST_TOUCH_PREFIX,
  UTM_COOKIE_NAME_PREFIX,
  UTM_PARAMS,
} from "src/constants";

export default () => {
  const searchParams = new URLSearchParams(window.location.search);
  const irClickId = searchParams.get(SearchParams.IR_CLICK_ID);
  const vBrandId = searchParams.get(SearchParams.VOLUUM);
  const snapchatId = searchParams.get(SearchParams.SNAPCHAT);
  const twitterId = searchParams.get(SearchParams.TWITTER);
  const facebookId = searchParams.get(SearchParams.FACEBOOK_CLICK_ID);
  const bingId = searchParams.get(SearchParams.BING_CLICK_ID);
  const googleId = searchParams.get(SearchParams.GOOGLE_CLICK_ID);
  const tiktokId = searchParams.get(SearchParams.TIKTOK_CLICK_ID);
  const gaSessionId = Cookies.get(GA_COOKIE_NAME);
  const landingUrl = window.location.href;
  const referrerUrl = document.referrer;
  const referrerSearchParams = document.referrer
    ? new URL(document.referrer)?.searchParams
    : "";

  UTM_PARAMS.forEach((param) => {
    const utmReferrerSearchParam =
      referrerSearchParams instanceof URLSearchParams
        ? referrerSearchParams?.get(param)
        : "";
    const landingSearchParam = searchParams.get(param);
    const utmSearchParam = utmReferrerSearchParam || landingSearchParam;
    if (utmSearchParam) {
      const cookieKey = `${UTM_COOKIE_NAME_PREFIX}${param}`;
      const firstCookieKey = `${UTM_COOKIE_NAME_FIRST_TOUCH_PREFIX}${param}`;

      if (!Cookies.get(firstCookieKey)) {
        Cookies.set(firstCookieKey, utmSearchParam);
      }

      Cookies.set(cookieKey, utmSearchParam);

      localStorage.setItem(param, utmSearchParam);
    }
  });

  if (irClickId) {
    localStorage.setItem(SearchParams.IR_CLICK_ID, irClickId);
  }

  if (vBrandId) {
    localStorage.setItem(SearchParams.VOLUUM, vBrandId);
  }

  if (snapchatId) {
    localStorage.setItem(SearchParams.SNAPCHAT, snapchatId);
  }

  if (twitterId) {
    localStorage.setItem(SearchParams.TWITTER, twitterId);
  }

  if (facebookId) {
    localStorage.setItem(SearchParams.FACEBOOK_CLICK_ID, facebookId);
  }

  if (bingId) {
    localStorage.setItem(SearchParams.BING_CLICK_ID, bingId);
  }

  if (googleId) {
    localStorage.setItem(SearchParams.GOOGLE_CLICK_ID, googleId);
  }

  if (tiktokId) {
    localStorage.setItem(SearchParams.TIKTOK_CLICK_ID, tiktokId);
  }

  if (landingUrl) {
    localStorage.setItem(AdditionalDataPoints.LANDING_URL, landingUrl);
  }

  if (referrerUrl) {
    localStorage.setItem(AdditionalDataPoints.REFERRER_URL, referrerUrl);
  }

  if (gaSessionId) {
    localStorage.setItem(AdditionalDataPoints.SESSION_ID, gaSessionId);
  }
};
