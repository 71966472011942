export default {
  ar: () => require("generated/translations/ar.js"),
  de: () => require("generated/translations/de.js"),
  en: () => require("generated/translations/en.js"),
  es: () => require("generated/translations/es.js"),
  fil: () => require("generated/translations/fil.js"),
  fr: () => require("generated/translations/fr.js"),
  he: () => require("generated/translations/he.js"),
  hi: () => require("generated/translations/hi.js"),
  id: () => require("generated/translations/id.js"),
  it: () => require("generated/translations/it.js"),
  ja: () => require("generated/translations/ja.js"),
  ko: () => require("generated/translations/ko.js"),
  ms: () => require("generated/translations/ms.js"),
  nl: () => require("generated/translations/nl.js"),
  pt: () => require("generated/translations/pt.js"),
  ro: () => require("generated/translations/ro.js"),
  ru: () => require("generated/translations/ru.js"),
  ta: () => require("generated/translations/ta.js"),
  te: () => require("generated/translations/te.js"),
  th: () => require("generated/translations/th.js"),
  tr: () => require("generated/translations/tr.js"),
  uk: () => require("generated/translations/uk.js"),
  vi: () => require("generated/translations/vi.js"),
};