import { HomePageStreamsState } from "src/features/homePage/shared/streamsState/streamsSlice";

export const homePageStreamsSelectors = {
  getStreamsIds: (state: HomePageStreamsState) => state.streamsIds,
  getStreamsDetails: (state: HomePageStreamsState) => state.streamsDetails,
  getError: (state: HomePageStreamsState) => state.error,
  getLoading: (state: HomePageStreamsState) => state.loading,
  getCurrentStream: (state: HomePageStreamsState) => state.currentStream,
  getPrevStream: (state: HomePageStreamsState) => state.prevStream,
  getNextStream: (state: HomePageStreamsState) => state.nextStream,
};
