import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { phoneSettingsMessages } from "src/features/profile/settings/common/constants";
import {
  SCREEN_NAME,
  SIGNIN_TARGET,
  ScreenViewReporter,
  emitUiAction,
} from "src/features/signin/imports/analytics";
import { LoginProvider } from "src/features/signin/imports/enums";
import { loginSelectors } from "src/features/signin/imports/state";
import {
  ComponentWithClassName,
  VoidCallback,
} from "src/features/signin/imports/types";
import {
  TYPOGRAPHY_TYPE,
  Typography,
  useBreakpointPrecise,
} from "src/features/signin/imports/ui";
import { times } from "src/features/signin/imports/utils";
import DigitInput from "src/features/signin/modal/login/components/DigitInput";
import ResendCode from "src/features/signin/modal/login/components/ResendCode";
import { VerificationCodeStepParams } from "src/features/signin/modal/login/steps/VerificationCodeStep";
import { loginWithProvider } from "src/features/signin/state/flows/login";
import { specifyVerificationCodeForPhoneLogin } from "src/features/signin/state/login/actionCreators";
import styles from "./CodeInputPanel.scss";

const CODE_LENGTH = 4;

interface CodeInputPanelProps extends VerificationCodeStepParams {
  isNested?: boolean;
  onLoginSuccess?: VoidCallback;
}

const CodeInputPanel: ComponentWithClassName<CodeInputPanelProps> = ({
  className,
  screenType,
  isNested = false,
  onLoginSuccess,
}) => {
  const breakpoint = useBreakpointPrecise();
  const dispatch = useDispatch();
  const { errorMessage, allowOnlySmsValidation } = useSelector(
    loginSelectors.getPhoneNumberAuthenticationState
  );

  const [code, setCode] = useState("");
  const isInvalidErrorMessage =
    errorMessage ===
    phoneSettingsMessages.invalidVerificationCode.defaultMessage;

  const onCodeChange = useCallback((code) => {
    setCode(code);
    if (code.trim().length !== CODE_LENGTH) {
      return;
    }

    emitUiAction({
      target: SIGNIN_TARGET.CODE_ENTERED,
      tango_screen: {
        reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
      },
    });

    batch(() => {
      dispatch(specifyVerificationCodeForPhoneLogin(code));
      dispatch(
        loginWithProvider({
          provider: LoginProvider.TANGO_PHONE_NUMBER,
          onLoginSuccess,
          screenType,
        })
      );
      setCode("");
    });
  }, []);

  return (
    <div className={classnames(styles.root, className, styles[breakpoint])}>
      <div className={styles.text} data-testid="sent-sms">
        {allowOnlySmsValidation && (
          <ScreenViewReporter
            name={SCREEN_NAME.ENTER_SMS_CODE}
            nested={isNested}
          />
        )}
      </div>
      <DigitInput length={CODE_LENGTH} value={code} onChange={onCodeChange}>
        {(value: string) => (
          <div className={styles.inputs}>
            {times(CODE_LENGTH, (k) => (
              <input
                data-testid={`digit-input-${k}`}
                key={k}
                className={classnames(styles.input, {
                  [styles.error]: errorMessage,
                })}
                type="tel"
                // @ts-ignore
                {...value[k]}
              />
            ))}
          </div>
        )}
      </DigitInput>

      {errorMessage && (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH5}
          as="div"
          data-testid="wrong-verification-code"
          className={classnames(styles.text, styles.errorMessage)}
        >
          {isInvalidErrorMessage ? (
            <FormattedMessage
              {...phoneSettingsMessages.invalidVerificationCode}
            />
          ) : (
            errorMessage
          )}
        </Typography>
      )}

      <ResendCode onLoginSuccess={onLoginSuccess} />
    </div>
  );
};

export default CodeInputPanel;
