import { UIActionTarget } from "@analytics/enums";
import { CASHIER, LOYALTY_OFFERS, MINTROUTE, REFILL } from "enums/cashier";
import { ShopOffer } from "ui/scenes/landingPage/types";

export enum OfferCardVariants {
  BANNER = "banner",
  MINTROUTE = "mintroute",
  REGULAR = "regular",
}

export enum OfferCardSizes {
  EXTRA_SMALL = "xs",
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

export enum OfferCardTypes {
  CASHIER = "cashier",
  LANDING = "landing",
  LANDING_LIGHT = "landing_light",
  REFILL = "refill",
}

export const DEFAULT_OFFER_CARD_SIZE = OfferCardSizes.MEDIUM;
export const DEFAULT_OFFER_CARD_VARIANT = OfferCardVariants.REGULAR;

export const OFFER_CARD_TYPES_MAP = {
  [CASHIER]: OfferCardTypes.CASHIER,
  [LOYALTY_OFFERS]: OfferCardTypes.CASHIER,
  [MINTROUTE]: OfferCardTypes.CASHIER,
  [REFILL]: OfferCardTypes.REFILL,
  LANDING: OfferCardTypes.LANDING,
};

export type BaseOfferCardProps = {
  analyticsParams?: Record<string, unknown>;
  biCardIndex?: number;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  offer: ShopOffer;
  onCancelSubscription?: undefined;
  onClick: (offer: ShopOffer) => void;
  onSubscribeClick?: VoidFunction;
  selected?: boolean;
  size: OfferCardSizes;
  target?: UIActionTarget;
  type: OfferCardTypes;
};
