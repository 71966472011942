import { UIActionTarget } from "chat/enums";
import { EventFields, emitUiAction } from "chat/imports/analytics";

export const emitChatInboxClicked = (size: number) => {
  emitUiAction({
    target: UIActionTarget.CHAT_REQUESTS,
    additionalParams: {
      [EventFields.SIZE]: size,
    },
  });
};
