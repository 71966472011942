import { defineMessages } from "react-intl";

export const badgeMessages = defineMessages({
  continueWatching: {
    id: "live.premium.continue.watching",
    defaultMessage: "Continue Watching & Get:",
  },
  status: {
    id: "vip.status",
    defaultMessage: "{vipName} status",
  },
  exclusiveContent: {
    id: "vip.exclusiveContent",
    defaultMessage: "Exclusive content",
  },
  off: {
    id: "cashier.promo.offer.sale",
    defaultMessage: "off",
  },
});

export const BALANCE_SERVICE_NAME = "gift";
export const BALANCE_SERVICE_IDENTIFIER = "syncCredit";
