import { UriComponent } from "src/types/common";

const formatValue = (value: UriComponent | UriComponent[]) =>
  Array.isArray(value)
    ? value.map(encodeURIComponent).join(",")
    : encodeURIComponent(value);

const isUriComponent = (value: unknown): value is UriComponent =>
  ["number", "string", "boolean"].includes(typeof value);

export default <T extends Record<string, unknown>>(obj: T) =>
  Object.entries(obj)
    .reduce((acc: string[], next) => {
      if (isUriComponent(next[1])) {
        acc.push(`${next[0]}=${formatValue(next[1])}`);
      }
      return acc;
    }, [])
    .join("&");
