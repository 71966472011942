import React, { FC, memo, useCallback, useState } from "react";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { PremiumMessageDetails } from "chat/premiumMessage/types";
import { SenderMessageOverlay } from "../overlays/SenderMessageOverlay";
import PremiumVideo from "./PremiumVideo";
import styles from "./ViewerUnlockedMessage.scss";

interface SenderVideoMessageProps {
  giftId: string;
  mediaInfo: PremiumMessageDetails["mediaInfo"];
  messageConfig: MessageConfig;
  status: PremiumMessageDetails["status"];
}

const SenderVideoMessage: FC<SenderVideoMessageProps> = ({
  giftId,
  mediaInfo,
  status,
  messageConfig,
}) => {
  const [isShowOverlay, setIsShowOverlay] = useState(true);

  const { duration } = mediaInfo || {};

  const handleOverlayClick = useCallback(() => {
    setIsShowOverlay(false);
  }, []);

  const handleShowOverlay = useCallback(() => {
    setIsShowOverlay(true);
  }, []);

  return (
    <div className={styles.root} data-testid="premium-message-sender">
      {isShowOverlay && (
        <SenderMessageOverlay
          duration={duration}
          giftId={giftId}
          status={status}
          onClick={handleOverlayClick}
          messageConfig={messageConfig}
        />
      )}
      <PremiumVideo
        isShowOnlyPreview={isShowOverlay}
        media={mediaInfo}
        onEnded={handleShowOverlay}
        messageConfig={messageConfig}
      />
    </div>
  );
};

export default memo(SenderVideoMessage);
