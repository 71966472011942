import { TimeFrame, LiveFeedType, PersonalFeedType } from "src/enums";
import { STREAM_SOURCE } from "@analytics/enums";

const liveStreamsFeedToSource = {
  [LiveFeedType.POPULAR]: STREAM_SOURCE.POPULAR_FEED,
  [LiveFeedType.NEW]: STREAM_SOURCE.NEW_FEED,
  [LiveFeedType.NEARBY]: STREAM_SOURCE.NEARBY_FEED,
  [LiveFeedType.RECOMMENDED]: STREAM_SOURCE.RECOMMENDATIONS_FEED,
  [PersonalFeedType.OLD_FOLLOWING]: STREAM_SOURCE.FOLLOWING_FEED,
};

const leaderboardTimeframeToSource = {
  [TimeFrame.DAILY]: STREAM_SOURCE.LEADERBOARD_DAILY,
  [TimeFrame.THISWEEK]: STREAM_SOURCE.LEADERBOARD_WEEKLY,
  [TimeFrame.LIFETIME]: STREAM_SOURCE.LEADERBOARD_ALLTIME,
};

export const sourceForLiveStreamsFeedMode = (x) =>
  liveStreamsFeedToSource[x] || STREAM_SOURCE.TAGGED_FEED;
export const sourceForLeaderboardTimeframe = (x) =>
  leaderboardTimeframeToSource[x];
