import React, { memo, useEffect, useState } from "react";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "src/enums";
import CircularProgress from "ui/common/circularProgress/CircularProgress";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useCallbackRef from "ui/hooks/useCallbackRef";
import useInterval from "ui/hooks/useInterval";
import styles from "./CircularTimer.scss";

interface CircularTimerProps {
  className?: string;
  initialValue: number;
  onEnd?: () => void;
  onShader?: boolean;
}

const CircularTimer: React.FC<CircularTimerProps> = ({
  initialValue,
  className,
  onEnd = emptyFunction,
  onShader = false,
}) => {
  const [countdown, setCountdown] = useState(initialValue);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const size = isMobile ? 40 : 48;
  const handleEndRef = useCallbackRef(onEnd);

  useInterval(() => {
    setCountdown(Math.max(countdown - 1, 0));
  }, 1000);

  useEffect(() => {
    if (countdown === 0 && handleEndRef.current) {
      handleEndRef.current();
    }
  }, [countdown, handleEndRef]);

  const progress = ((initialValue - countdown) / initialValue) * 100;

  return (
    <div
      className={classnames(
        styles.root,
        styles[breakpoint],
        onShader && styles.onShader,
        className
      )}
      data-testid="circular-timer"
    >
      <CircularProgress size={size} progress={progress} />
      <Typography
        type={isMobile ? TYPOGRAPHY_TYPE.HEADLINE2 : TYPOGRAPHY_TYPE.HEADLINE4}
        className={styles.timer}
      >
        {countdown}
      </Typography>
    </div>
  );
};

export default memo(CircularTimer);
