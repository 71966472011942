import React, { useCallback } from "react";
import ErrorView from "ui/common/errorView/ErrorView";
import useOpenIntercom from "ui/intercom/useOpenIntercom";
import { ErrorViewTypes } from "ui/common/errorView/enum";

const UserSuspendedView: React.FC = () => {
  const openIntercom = useOpenIntercom();
  const handleSupportClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      openIntercom();
    },
    [openIntercom]
  );
  return (
    <ErrorView
      testId="user-suspended-view"
      type={ErrorViewTypes.SUSPENDED_ACCOUNT_ERROR}
      onClick={handleSupportClick}
    />
  );
};

export default UserSuspendedView;
