import React, { memo, useCallback, useContext, useEffect } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PURCHASE_SOURCE } from "@analytics/enums";
import { CASHIER } from "enums/cashier";
import { SETTINGS_SCREEN_APP } from "enums/settingsScreenType";
import {
  getCashierSasEnabled,
  getEnabledPaymentProviders,
} from "src/environment";
import {
  getIsCashierDesignV2DesktopEnabled,
  getIsCashierDesignV2Enabled,
} from "src/state/abTests";
import { actionCreators } from "src/state/tree/taskManager";
import {
  openCashierV2DrawerBottomScreen,
  openRefillDrawerBottomScreen,
} from "state/actionCreators/bottomScreen";
import {
  openBuyCoinsModal,
  openCashierModal,
  openEditProfileModal,
} from "state/actionCreators/modal";
import { RootState } from "state/delegate";
import {
  autoLoginSelectors,
  bottomScreenSelectors,
  loginSelectors,
  serverOwnedConfigSelectors,
  taskManagerSelectors,
} from "state/selectors";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import useMyProfile from "ui/hooks/useMyProfile";
import PurchaseToasterContext from "ui/modal/modalViews/buyCoins/PurchaseToasterContext";
import { TaskType } from "ui/navigation/deepLink/types";
import {
  linkToOnboardingLandingPage,
  linkToReferralProgram,
  makeLinkToProfile,
  makeLinkToSettingsScreen,
} from "ui/navigation/links";

const selectors = (state: RootState) => ({
  isAutoLoginFailure: autoLoginSelectors.getIsResultFailure(state),
  isAutoLoginSuccess: autoLoginSelectors.getIsResultSuccess(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isLoginInProgress: loginSelectors.isLoginInProgress(state),
  firstQueueItem: taskManagerSelectors.getFirstQueueItem(state),
  isCashierV2Enabled: getIsCashierDesignV2Enabled(state),
  isCashierDesignV2DesktopEnabled: getIsCashierDesignV2DesktopEnabled(state),
  isServerConfigReady: serverOwnedConfigSelectors.getIsServerConfigReady(state),
  activeBottomScreenType: bottomScreenSelectors.screenType(state),
  isNoActiveAnimation: !bottomScreenSelectors.animation(state),
});

const TaskManager: React.FC = () => {
  const history = useHistory();
  const isMobile = useBreakpointMobileLayout();

  const {
    isAutoLoginFailure,
    isAutoLoginSuccess,
    isLoggedIn,
    firstQueueItem,
    isCashierV2Enabled,
    isCashierDesignV2DesktopEnabled,
    isLoginInProgress,
    isServerConfigReady,
    activeBottomScreenType,
    isNoActiveAnimation,
  } = useSelector(selectors, shallowEqual);

  const { accountId, basicProfile } = useMyProfile();

  const { showSuccessPurchaseToaster, showFailurePurchaseToaster } = useContext(
    PurchaseToasterContext
  );

  const dispatch = useDispatch();

  const onLoginDismiss = useCallback(() => {
    if (!isLoggedIn && !isLoginInProgress) {
      dispatch(actionCreators.processFirstQueueItem());
    }
  }, [dispatch, isLoggedIn, isLoginInProgress]);

  useEffect(() => {
    if (!firstQueueItem) {
      return;
    }

    switch (firstQueueItem.type) {
      case TaskType.DEEP_LINK_INTENT_EDIT_PROFILE_SETTINGS:
        if (isLoggedIn && basicProfile) {
          batch(() => {
            dispatch(actionCreators.processFirstQueueItem());
            dispatch(openEditProfileModal());
          });
        }
        break;
      case TaskType.DEEP_LINK_INTENT_OPEN_PROFILE:
        if (isLoggedIn && basicProfile) {
          dispatch(actionCreators.processFirstQueueItem());
          history.replace(makeLinkToProfile(accountId, basicProfile));
        }
        break;
      case TaskType.DEEP_LINK_OPEN_NEARBY_WITH_REGISTRATION:
        if (isLoggedIn) {
          dispatch(actionCreators.processFirstQueueItem());
        }
        break;
      case TaskType.DEEP_LINK_OPEN_CREATORS_WITH_REGISTRATION:
        if (isLoggedIn) {
          dispatch(actionCreators.processFirstQueueItem());
        }
        break;
      case TaskType.DEEP_LINK_INTENT_OPEN_MOBILE_SETTINGS:
        if (isLoggedIn || isAutoLoginSuccess) {
          history.push(makeLinkToSettingsScreen(SETTINGS_SCREEN_APP));
        } else if (isAutoLoginFailure) {
          history.push("/");
        }
        dispatch(actionCreators.processFirstQueueItem());
        break;
      case TaskType.DEEP_LINK_INTENT_OPEN_ONBOARDING_LANDING:
        dispatch(actionCreators.processFirstQueueItem());
        history.push(linkToOnboardingLandingPage);
        break;
      case TaskType.DEEP_LINK_INTENT_OPEN_BUY_COINS:
        if (!isServerConfigReady) {
          return;
        }

        switch (firstQueueItem.payload?.paymentSummary) {
          case "success":
            if (window.parent.onSuccessfulCheckoutSession) {
              window.parent.onSuccessfulCheckoutSession();
            } else {
              showSuccessPurchaseToaster();
            }
            break;
          case "failed":
            if (window.parent.onFailedCheckoutSession) {
              window.parent.onFailedCheckoutSession();
            } else {
              showFailurePurchaseToaster();
            }
            break;
          default:
            window.parent.onFinallyCheckoutSession?.();
            break;
        }

        dispatch(actionCreators.processFirstQueueItem());
        if (!getEnabledPaymentProviders().length) {
          return;
        }
        if (isLoggedIn) {
          const payload = {
            ...firstQueueItem.payload,
            purchaseSource: PURCHASE_SOURCE.REFILL,
            viewType: CASHIER,
          };

          if (isMobile) {
            if (isNoActiveAnimation && !activeBottomScreenType) {
              if (isCashierV2Enabled) {
                dispatch(
                  openCashierV2DrawerBottomScreen({
                    screenData: {
                      ...payload,
                      withBonus: getCashierSasEnabled(),
                    },
                  })
                );
              } else {
                dispatch(
                  openRefillDrawerBottomScreen({
                    screenData: {
                      ...payload,
                      withBonus: getCashierSasEnabled(),
                    },
                  })
                );
              }
            }
          } else {
            if (isCashierDesignV2DesktopEnabled) {
              dispatch(
                openCashierModal({
                  ...payload,
                  withBonus: getCashierSasEnabled(),
                })
              );
            } else {
              dispatch(
                // @ts-ignore
                openBuyCoinsModal({
                  ...payload,
                  withBonus: getCashierSasEnabled(),
                })
              );
            }
          }
        }
        break;
      case TaskType.DEEP_LINK_INTENT_OPEN_BRING_FRIENDS:
        dispatch(actionCreators.processFirstQueueItem());
        if (isLoggedIn) {
          history.replace({
            pathname: linkToReferralProgram,
            state: {
              source: "deeplink",
            },
          });
        }
        break;
    }
  }, [
    isAutoLoginFailure,
    isAutoLoginSuccess,
    isLoggedIn,
    basicProfile,
    firstQueueItem,
    dispatch,
    history,
    accountId,
    isMobile,
    onLoginDismiss,
    isNoActiveAnimation,
    activeBottomScreenType,
    isCashierV2Enabled,
    isCashierDesignV2DesktopEnabled,
    isServerConfigReady,
  ]);

  return null;
};

export default memo(TaskManager);
