import React from "react";
import {
  IntlProvider,
  FormattedDate as OriginalFormattedDate,
} from "react-intl";
import useGetLocale from "chat/components/hooks/useGetLocale";

export const FormattedDate: typeof OriginalFormattedDate = ({
  value,
  ...rest
}) => (
  <IntlProvider locale={useGetLocale("fr")}>
    <OriginalFormattedDate value={value} {...rest} />
  </IntlProvider>
);
