import {
  FOLLOW_USER,
  FOLLOWING_LIST_BEGIN_FETCH,
  FOLLOWING_LIST_END_FETCH,
  UNFOLLOW_USER,
  UNFOLLOW_USER_REALTIME_RECOMMENDATION,
} from "state/actionTypes";
import { createFetcherActions } from "state/hor/withFetcher";

export const followingListFetcher = createFetcherActions({
  beginFetchActionType: FOLLOWING_LIST_BEGIN_FETCH,
  endFetchActionType: FOLLOWING_LIST_END_FETCH,
});

export const followedUser = ({ accountId, me, source }) => ({
  type: FOLLOW_USER,
  payload: { accountId, me, source },
});
export const unfollowedUser = ({ accountId, me }) => ({
  type: UNFOLLOW_USER,
  payload: { accountId, me },
});

export const unfollowRealtimeRecommendation = ({ accountId, source }) => ({
  type: UNFOLLOW_USER_REALTIME_RECOMMENDATION,
  payload: { accountId, source },
});
