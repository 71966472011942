import React, { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { ArrowBackIcon } from "chat/messageRequest/imports/images";
import { VoidCallback } from "chat/messageRequest/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
} from "chat/messageRequest/imports/uiCommon";
import styles from "./BackHeader.scss";

interface BackHeaderProps {
  isDesktop?: boolean;
  isShowDescription: boolean;
  onClick: VoidCallback;
}

const BackHeader = forwardRef<HTMLDivElement, BackHeaderProps>(
  ({ onClick, isDesktop, isShowDescription }, ref) => (
    <div
      className={classnames(styles.root, {
        [styles.rootMobile]: !isDesktop,
      })}
      ref={ref}
    >
      <div
        className={classnames(styles.container, {
          [styles.containerMobile]: !isDesktop,
        })}
      >
        <Button
          data-testid="go-to-chat"
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.ICON_ONLY}
          onClick={onClick}
          leftIcon={ArrowBackIcon}
        >
          <ArrowBackIcon className={styles.icon} />
        </Button>
        <Typography
          type={TYPOGRAPHY_TYPE.HEADLINE4}
          className={classnames(styles.title, {
            [styles.titleCenter]: !isDesktop,
          })}
        >
          <FormattedMessage
            id="chat.request.headerTitle"
            defaultMessage="Message Requests"
          />
        </Typography>
      </div>
      {isShowDescription && (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH4}
          as="div"
          className={classnames(styles.messageRequestTitle, {
            [styles.messageRequestTitleCenter]: !isDesktop,
          })}
        >
          <FormattedMessage
            id="chat.request.chatListDescription"
            defaultMessage="Senders won't know message was seen until you reply"
          />
        </Typography>
      )}
    </div>
  )
);

BackHeader.displayName = "BackHeader";

export { BackHeader };
