import { ensureHttps } from "src/utils/ensureHttps";
import {
  BALANCE_END_FETCH,
  MINTROUTE_PURCHASABLE_ITEMS_BEGIN_FETCH,
  MINTROUTE_PURCHASABLE_ITEMS_END_FETCH,
  MINTROUTE_SET_SUSPENDED_STATUS,
  PROMO_PURCHASABLE_ITEMS_BEGIN_FETCH,
  PROMO_PURCHASABLE_ITEMS_END_FETCH,
  PURCHASABLE_ITEMS_BEGIN_FETCH,
  PURCHASABLE_ITEMS_END_FETCH,
  VIP_PURCHASABLE_ITEMS_BEGIN_FETCH,
  VIP_PURCHASABLE_ITEMS_END_FETCH,
} from "state/actionTypes";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
} from "state/hor/withFetcher";
import { makeWithUserSessionScope } from "state/hor/withUserSessionScope";
import noopReducer from "./utils/noopReducer";

export const persistConfig = {
  whitelist: ["list", "version"],
};

export const mintroutePersistConfig = {
  whitelist: ["list", "suspended", "version"],
};

export const shopActionCreators = {
  ...createFetcherActions({
    beginFetchActionType: PURCHASABLE_ITEMS_BEGIN_FETCH,
    endFetchActionType: PURCHASABLE_ITEMS_END_FETCH,
  }),
};

export const promoShopActionCreators = createFetcherActions({
  beginFetchActionType: PROMO_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: PROMO_PURCHASABLE_ITEMS_END_FETCH,
});

export const vipShopActionCreators = createFetcherActions({
  beginFetchActionType: VIP_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: VIP_PURCHASABLE_ITEMS_END_FETCH,
});

export const mintrouteShopActionCreators = {
  ...createFetcherActions({
    beginFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_BEGIN_FETCH,
    endFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_END_FETCH,
  }),
  setSuspendedStatus: (status) => ({
    type: MINTROUTE_SET_SUSPENDED_STATUS,
    payload: status,
  }),
};

export const transformOffer = ({ currencyPrice = [], image, ...rest }) => ({
  ...rest,
  image: ensureHttps(image),
  currencyPrice: currencyPrice.reduce(
    (a, { currency, price }) => {
      a[currency.toUpperCase()] = price / 100;

      return a;
    },
    { USD: rest.usd }
  ),
});

const makeReducer = ({
  beginFetchActionType,
  endFetchActionType,
  additionalActionsHandler = noopReducer,
  initialData = {
    list: [],
    isStale: true,
    version: "",
  },
}) =>
  withFetcher({
    beginFetchActionType,
    endFetchActionType,
    initialData,
    extractData: (list, { version }) => ({
      list: list.map(transformOffer),
      isStale: false,
      version,
    }),
    mergeData: (_, newData) => newData,
  })(additionalActionsHandler);

export const shopReducer = makeReducer({
  beginFetchActionType: PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: PURCHASABLE_ITEMS_END_FETCH,
  additionalActionsHandler: makeWithUserSessionScope(({ state }) => ({
    ...state,
  }))((state, action) => {
    if (action.type === BALANCE_END_FETCH) {
      if (action.error) {
        return state;
      }

      const { vipStatus, coinsLeft, vipLevelInfo } = action.payload;

      return {
        ...state,
        vipInfo: {
          status: vipStatus,
          coinsLeft,
          vipLevelInfo,
        },
      };
    }

    return state;
  }),
});

export const promoShopReducer = makeReducer({
  beginFetchActionType: PROMO_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: PROMO_PURCHASABLE_ITEMS_END_FETCH,
});

export const vipShopReducer = makeReducer({
  beginFetchActionType: VIP_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: VIP_PURCHASABLE_ITEMS_END_FETCH,
});

export const mintrouteShopReducer = makeReducer({
  beginFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_BEGIN_FETCH,
  endFetchActionType: MINTROUTE_PURCHASABLE_ITEMS_END_FETCH,
  additionalActionsHandler: makeWithUserSessionScope(({ state }) => ({
    ...state,
    suspended: false,
  }))((state, action) => {
    switch (action.type) {
      case MINTROUTE_SET_SUSPENDED_STATUS: {
        return { ...state, suspended: action.payload };
      }
    }

    return state;
  }),
});

const commonSelectors = {
  ...fetcherSelectors,
  getListOfPurchasableItems: (state) => state.list,
  getIsStale: (state) => state.isStale,
  getVersion: (state) => state.version,
};

export const selectors = {
  ...commonSelectors,
  getVipInfo: (state) => state.vipInfo,
};

export const mintrouteShopSelectors = {
  ...commonSelectors,
  getIsSuspended: (state) => state.suspended,
};
