import { VIEWER_SESSION_RESET } from "state/actionTypes";

export default (state = "", action) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      return action.payload;
    }
  }
  return state;
};
