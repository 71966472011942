import { LOCATION_CHANGE } from "connected-react-router";
import { BY_NAVIGATION, FROM_FLOW } from "enums/modalDismissReason";
import {
  CASHIER_SCOPE,
  GLOBAL,
  REFILL_SCOPE,
  RIGHT_SIDE_REFILL_SCOPE,
  RIGHT_SIDE_SCOPE,
  SCENE,
  STREAM_SCOPE,
} from "enums/modalScope";
import { pick, uniq, without } from "src/utils/miniLodash";
import { DISMISS_MODAL, LOCK_MODAL, SHOW_MODAL } from "state/actionTypes";

const initialState = {
  stack: [],
  lastDismissedModalType: null,
  lastModalDismissReason: null,
  dataByType: {},
  scopeByType: {},
  meta: {},
  locked: false,
};

const getTopModalType = (state) =>
  state.stack.length ? state.stack[0] : undefined;

const REDIRECT_TO_DISMISS_SCOPES = [
  SCENE,
  CASHIER_SCOPE,
  REFILL_SCOPE,
  STREAM_SCOPE,
  RIGHT_SIDE_SCOPE,
  RIGHT_SIDE_REFILL_SCOPE,
];

export const selectors = {
  shouldShowModal: (state) => !!state.stack.length,
  topModalType: getTopModalType,
  topModalData: (state) => {
    const topModalType = getTopModalType(state);
    if (topModalType) {
      return state.dataByType[topModalType];
    }

    return undefined;
  },
  modalScopeByType: (state, type) => state.scopeByType[type] || GLOBAL,
  modalDataByType: (state, type) => state.dataByType[type],
  isLocked: (state) => state.locked,
  getLastDismissedModalType: (state) => state.lastDismissedModalType,
  getLastModalDismissReason: (state) => state.lastModalDismissReason,
  isModalInStack: (state, type) => !!state.stack.find((item) => item === type),
  getModalStack: (state) => state.stack,
};

export default (state = initialState, action) => {
  let { dataByType, stack } = state;
  switch (action.type) {
    case SHOW_MODAL: {
      const {
        payload: { modalType, modalData, modalScope },
        meta,
      } = action;
      stack = uniq([modalType, ...stack]);

      return {
        ...state,
        stack,
        lastDismissedModalType:
          state.lastDismissedModalType === modalType
            ? null
            : state.lastDismissedModalType,
        lastModalDismissReason:
          state.lastDismissedModalType === modalType
            ? null
            : state.lastModalDismissReason,
        dataByType: {
          ...dataByType,
          [modalType]: modalData,
        },
        scopeByType: {
          ...state.scopeByType,
          [modalType]: modalScope,
        },
        meta: {
          ...state.meta,
          [modalType]: meta,
        },
      };
    }
    case DISMISS_MODAL: {
      if (!stack.length || state.locked) {
        return state;
      }
      let { modalType, modalDismissReason } = action.payload;

      if (action.meta && action.meta.batch) {
        const newStack = without(stack, ...modalType);
        const wasTopModalDismissed =
          !newStack.length || stack[0] !== newStack[0];
        const lastDismissedModalType = wasTopModalDismissed
          ? stack[0]
          : state.lastDismissedModalType;

        return {
          ...state,
          stack: newStack,
          dataByType: pick(
            state.dataByType,
            ...newStack,
            lastDismissedModalType
          ),
          meta: pick(state.meta, ...newStack, lastDismissedModalType),
          lastDismissedModalType,
          lastModalDismissReason: wasTopModalDismissed
            ? FROM_FLOW
            : state.lastModalDismissReason,
        };
      }
      if (!modalType) {
        [modalType] = stack;
      }
      if (!stack.includes(modalType)) {
        return state;
      }

      return {
        ...state,
        stack: stack.filter((item) => item !== modalType),
        lastDismissedModalType: modalType,
        lastModalDismissReason: modalDismissReason,
      };
    }
    case LOCATION_CHANGE: {
      const modalsToDismiss = stack.filter(
        (type, index) =>
          !(index === 0 && state.locked) &&
          REDIRECT_TO_DISMISS_SCOPES.includes(state.scopeByType[type]) &&
          state.meta[type].pathname !== action.payload.location.pathname &&
          !state.meta[type].stayOnRedirect
      );
      if (modalsToDismiss.length === 0) {
        return state;
      }
      const lastDismissedModalType =
        modalsToDismiss[0] === stack[0]
          ? modalsToDismiss[0]
          : state.lastDismissedModalType;
      const newStack = without(stack, ...modalsToDismiss);

      return {
        ...state,
        lastDismissedModalType,
        lastModalDismissReason: BY_NAVIGATION,
        stack: newStack,
        dataByType: pick(dataByType, ...newStack, lastDismissedModalType),
        meta: pick(state.meta, ...newStack, lastDismissedModalType),
      };
    }
    case LOCK_MODAL: {
      return {
        ...state,
        locked: action.payload,
      };
    }
  }

  return state;
};
