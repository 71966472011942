import React, { FC, memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { chatMessages } from "chat/components/common/messages";
import { CallLogPayload } from "chat/types";
import { formatDuration } from "chat/utils/formatDuration";

interface CallMessagePreviewProps {
  messagePayload: CallLogPayload;
}

export const CallMessagePreview: FC<CallMessagePreviewProps> = memo(
  ({ messagePayload: { duration, is_incoming: isIncoming } }) => {
    const isMissed = !duration;

    const durationInBrackets = useMemo(
      () => (duration ? ` (${formatDuration(duration)})` : ""),
      [duration]
    );

    if (isIncoming) {
      if (isMissed) {
        return <FormattedMessage {...chatMessages.missedCall} />;
      }

      return (
        <>
          <FormattedMessage {...chatMessages.incomingCall} />
          {durationInBrackets}
        </>
      );
    }

    if (isMissed) {
      return <FormattedMessage {...chatMessages.outgoingCall} />;
    }

    return (
      <>
        <FormattedMessage {...chatMessages.outgoingCall} />
        {durationInBrackets}
      </>
    );
  }
);

CallMessagePreview.displayName = "CallMessagePreview";
