interface NumericStringToNumberParams {
  [key: string]: unknown;
}

const convertNumericStringToNumber = <T extends NumericStringToNumberParams>(
  obj: T
): { [K in keyof T]: number | unknown } =>
  Object.keys(obj).reduce(
    (acc, key) => {
      if (
        typeof obj[key] === "string" &&
        !isNaN(Number(obj[key])) &&
        !isNaN(parseFloat(obj[key] as string))
      ) {
        return {
          ...acc,
          [key]: Number(obj[key]),
        };
      }

      return {
        ...acc,
        [key]: obj[key],
      };
    },
    {} as { [K in keyof T]: number | unknown }
  );

export default convertNumericStringToNumber;
