const countries = require("i18n-iso-countries");
module.exports = function(cb) {
    require.ensure([
      "i18n-iso-countries/langs/hi.json",
      "date-fns/locale/te",
      "translations/te.json",
    ], (require) => {
      countries.registerLocale(require("i18n-iso-countries/langs/hi.json"));
      cb({
        translatedMessages: require("translations/te.json"),
        dateFnsLocale: require("date-fns/locale/te").default
      });
    }, "locale-te-no-intl");
};