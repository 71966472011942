import { PRODUCTION_ENVIRONMENT, TANGO_DOMAIN } from "src/constants";

export const isAlternativeDomain = (): boolean => {
  const isProduction = process.env.NODE_ENV === PRODUCTION_ENVIRONMENT;
  const isAlternativeHostname =
    !window.location.hostname.includes(TANGO_DOMAIN);

  return isProduction && isAlternativeHostname;
};

export const replaceDomainWithHostname = (url: string): string => {
  const targetDomain = TANGO_DOMAIN;
  const currentHostname = window.location.hostname;

  try {
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname.includes(targetDomain)) {
      parsedUrl.hostname = parsedUrl.hostname.replace(
        targetDomain,
        currentHostname
      );
    }

    return parsedUrl.toString();
  } catch {
    return url;
  }
};
