import tangoAnalytics from "src/core/analytics";
import {
  EventCategory,
  EventDenominations,
  EventFields as TangoEventFields,
  EventNames as TangoEventNames,
} from "src/core/analytics/enums";

interface DataType {
  GiftSource: string;
  gift_id: string;
  item_type: string;
  one_click: number;
  one_click_purchase: number;
}

const analyticsSendGift = (
  price: number,
  data: DataType,
  isOneClickPurchase: boolean
): void => {
  tangoAnalytics.then((analytics) => {
    analytics.track(TangoEventNames.EVENT, {
      [TangoEventFields.EVENT_NAME]: EventDenominations.SEND_GIFT,
      [TangoEventFields.EVENT_CATEGORY]: EventCategory.STREAM_EVENTS,
      [TangoEventFields.EVENT_DETAIL]: price,
      [TangoEventFields.GIFT_SOURCE]: data.GiftSource,
      [TangoEventFields.GIFT_ID]: data.gift_id,
      [TangoEventFields.ONE_CLICK]: data.one_click,
      [TangoEventFields.ONE_CLICK_PURCHASE]: isOneClickPurchase ? 1 : 0,
      [TangoEventFields.ITEM_TYPE]: data.item_type,
    });
  });
};

export { analyticsSendGift };
