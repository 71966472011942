import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { Nullable } from "src/features/homePage/v2/imports/types";
import { HomePageVisitorStreamsResponse } from "src/features/homePage/shared/constants/types";
import {
  BasicProfileData,
  ProfilesResponse,
} from "src/features/homePage/v2/common/types";

export interface HomePageProfilesState {
  error: Nullable<string>;
  isLoading: boolean;
  profiles: Record<string, Partial<BasicProfileData>>;
}

const initialState: HomePageProfilesState = {
  profiles: {},
  isLoading: false,
  error: null,
};

const profilesSlice = createSlice({
  name: "homePageProfiles",
  initialState,
  reducers: {
    loadHomePageProfilesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loadHomePageProfilesComplete(
      state,
      action: PayloadAction<ProfilesResponse>
    ) {
      state.profiles = action.payload;
      state.isLoading = false;
    },
    loadHomePageVisitorsProfileComplete(
      state,
      action: PayloadAction<HomePageVisitorStreamsResponse>
    ) {
      const { catalogItems } = action.payload;
      const profiles: Record<string, Partial<BasicProfileData>> = {};

      catalogItems.forEach((stream) => {
        profiles[stream.broadcaster.encryptedId] = {
          liveStats: {
            points: stream.broadcaster.diamonds,
          },
          basicProfile: {
            firstName: stream.broadcaster.name,
            profilePictureUrl: stream.broadcaster.avatarUrl,
            profileThumbnailUrl: stream.broadcaster.avatarThumbnailUrl,
          },
          accountId: stream.broadcaster.encryptedId,
        };
      });

      state.profiles = profiles;
    },
    loadHomePageProfilesFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadHomePageProfilesStart,
  loadHomePageProfilesComplete,
  loadHomePageProfilesFailure,
  loadHomePageVisitorsProfileComplete,
} = profilesSlice.actions;

export const homePageProfilesReducer = profilesSlice.reducer;
