import { useCallback } from "react";
import { useSelector } from "react-redux";
import * as linkify from "linkifyjs";
import emitMessageTranslateEvent, {
  TranslateItemIds,
  getScreenNameByConversationId,
} from "chat/analytics/emitMessageTranslateEvent";
import { TranslateItemType } from "chat/enums";
import { useAppDispatch } from "chat/imports/hooks";
import { getOfflineChatTranslationEnabled } from "chat/imports/state";
import { VoidCallback } from "chat/imports/types";
import { isStringWithoutLetters } from "chat/imports/utils";
import {
  getMessageTranslation,
  setIsTranslated,
} from "chat/state/actionCreators";
import { MessageType } from "chat/types";
import { CommonProps } from "./Message";

interface TranslateMessageProps extends CommonProps {
  isMyMessage: boolean;
  locale: string;
}

export default ({
  message,
  locale,
  conversationId,
  isMyMessage,
}: TranslateMessageProps): VoidCallback => {
  const dispatch = useAppDispatch();
  const isTranslationEnabled = useSelector(getOfflineChatTranslationEnabled);
  const {
    type: messageType,
    isTranslated,
    translation,
    id: { id: messageId },
    body: messageBody,
    language,
  } = message;

  return useCallback(() => {
    if (
      !isTranslationEnabled ||
      !messageBody ||
      isMyMessage ||
      messageType !== MessageType.TEXT_MESSAGE ||
      window.getSelection()?.toString().length ||
      isStringWithoutLetters(messageBody) ||
      linkify.test(messageBody) ||
      language === locale
    ) {
      return;
    }

    if (isTranslated && translation?.[locale]) {
      dispatch(
        setIsTranslated({
          conversationId,
          messageId,
          isTranslated: false,
        })
      );

      return;
    }

    if (translation?.[locale]) {
      dispatch(
        setIsTranslated({
          conversationId,
          messageId,
          isTranslated: true,
        })
      );

      emitMessageTranslateEvent({
        chatId: conversationId,
        translateToLang: locale,
        screenName: getScreenNameByConversationId(conversationId),
        peerId: message.from,
        intValue: messageBody.length,
        itemId: TranslateItemIds.OFFLINE_CHAT,
        itemType: TranslateItemType.CACHE,
        translateFromLang: language,
      });

      return;
    }

    dispatch(
      getMessageTranslation({
        message: messageBody,
        senderId: message.from,
        locale,
        conversationId,
        messageId,
      })
    ).unwrap();
  }, [
    dispatch,
    isTranslationEnabled,
    messageType,
    isMyMessage,
    isTranslated,
    translation,
    locale,
    messageBody,
    conversationId,
    messageId,
    language,
  ]);
};
