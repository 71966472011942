import { StreamType } from "src/features/broadcastExternal/common/enums";
import { InitExternalStreamParams } from "src/features/broadcastExternal/common/types";
import { StreamKind } from "src/features/broadcastExternal/imports/enums";

const getStreamType = (streamType: StreamKind) => {
  if (streamType === StreamKind.PUBLIC) {
    return StreamType.PUBLIC;
  }

  return StreamType.PRIVATE;
};

export const mapInitExternalRequestParams = ({
  type,
  title,
  transcodingRequested,
}: InitExternalStreamParams) => ({
  type: getStreamType(type),
  title,
  transcodingRequested,
});
