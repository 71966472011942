import { performGoogleOneTapSignIn } from "src/features/signin/api/login";
import { CLIENT_CAPABILITIES_ARRAY } from "src/features/signin/imports/constants";
import { RootState } from "src/features/signin/imports/state";
import { getOsVersion } from "src/features/signin/imports/utils";
import { loginWithProviderSelectors } from "src/features/signin/state/flows/login";

export default (state: RootState) => async (credential: string) => {
  const { fingerprint, locale } = loginWithProviderSelectors(state);

  const response = await performGoogleOneTapSignIn({
    idToken: credential,
    locale,
    clientDetails: {
      capabilities: CLIENT_CAPABILITIES_ARRAY,
      fingerprint,
      osVersion: getOsVersion(),
      version: GENERATED_APP_INFO.fullVersion,
    },
  });

  return { ...response, credential };
};
