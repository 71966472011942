import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import StreamAnalyticsContext from "@analytics/livePlay/StreamAnalyticsContext";
import streamKindToAnalyticsStreamKind from "@analytics/streamKindToAnalyticsStreamKind";
import { userSelectors, viewerSessionSelectors } from "state/selectors";
import { MiniProfileEntranceSource, ProfileType } from "src/enums";
import { BIAdditionalParams } from "src/types/common";
import { EventFields } from "@analytics/enums";

export const useProfileEvent = (
  source: MiniProfileEntranceSource = MiniProfileEntranceSource.UNKNOWN,
  type: ProfileType
) => {
  const { viewSessionId } = useContext(StreamAnalyticsContext);

  const accountId = useSelector(userSelectors.getMyAccountId);
  const streamKind = streamKindToAnalyticsStreamKind(
    useSelector(viewerSessionSelectors.getStreamKind)
  );
  const streamId = useSelector(viewerSessionSelectors.getStreamId);
  const broadcasterId = useSelector(viewerSessionSelectors.getBroadcasterId);

  return useCallback(
    (peerId: string, additionalParams: BIAdditionalParams = {}) => ({
      ...additionalParams,
      [EventFields.LOGGED_IN_ACCOUNT_ID]: accountId,
      [EventFields.VIEW_SESSION_ID]: viewSessionId,
      [EventFields.STREAM_KIND]: streamKind,
      [EventFields.MINI_PROFILE_IS_PUBLISHER]: broadcasterId === peerId ? 1 : 0,
      [EventFields.PEER_ID]: peerId,
      [EventFields.SOURCE]: source,
      [EventFields.ITEM_TYPE]: type,
      [EventFields.STREAM_ID]: streamId,
    }),
    [viewSessionId, streamId, broadcasterId, streamKind, source]
  );
};
