import { datadogLogs } from "@datadog/browser-logs";
import { PersistConfig } from "redux-persist";
import { getEnvironmentName } from "environment";
import { InMemoryStorage } from "src/core/storage/inMemory/InMemoryStorage";
import { IndexedDBStorage } from "./IndexedDBStorage";

export const persistedCacheStorage = new IndexedDBStorage(
  "TangoDB",
  "persistedCache",
  {
    fallbackStorage: new InMemoryStorage(),
    onError: (error) => {
      datadogLogs.logger.warn(error.message, { kind: "indexedDB" }, error);
    },
  }
);

export const makeIndexedDBPersistConfig = <T extends object>(
  name: string,
  baseConfig: Omit<PersistConfig<T>, "key" | "storage"> = {}
): PersistConfig<T> => {
  localStorage.removeItem(`persist:${getEnvironmentName()}:${name}`);

  return {
    ...baseConfig,
    key: `${getEnvironmentName()}:${name}`,
    storage: persistedCacheStorage,
  };
};
