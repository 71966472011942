import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";

// this endpoint is used to update to cookies, response payload is ignored
// response payload type is described below (used in previous versions)

export interface VideoTokenData {
  token: string;
  expireAt: number;
  ttlSeconds: number;
  username: string;
}
export const fetchVideoToken = (): Promise<VideoTokenData> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v1/tokenData`
  ).then((resp) => resp.json());
