import { StoredConversation } from "chat/messageRequest/types";

const PREMIUM_MESSAGE = "premiumMessageEnabled";

export const messageRequestAdapter = (conversations: StoredConversation[]) =>
  conversations.map((item) => ({
    conversation_id: item.conversationId,
    account_info: {
      account_id: item?.accountInfo?.accountId,
      first_name: item?.accountInfo?.firstName || "",
      vip_level: item?.accountInfo?.vipLevel,
      vip_config_id: item?.accountInfo?.vipConfigId || "",
    },
    last_message_ts: item.lastMessageTs ? parseInt(item.lastMessageTs, 10) : 0,
    last_self_read_message_ts: item.lastSelfReadMessageTs
      ? parseInt(item.lastSelfReadMessageTs, 10)
      : 0,
    last_update_ts: item.lastUpdateTs ? parseInt(item.lastUpdateTs, 10) : 0,
    unread_message_count: item.unreadMessageCount
      ? parseInt(item.unreadMessageCount, 10)
      : 0,
    state: item.state,
    hidden: item.hidden,
  }));

export const toSnakeCase = (conversationKey: string): string =>
  conversationKey.replace(/([A-Z])/g, "_$1").toLowerCase();

export const convertKeysToSnakeCase = (conversation: unknown): unknown => {
  if (Array.isArray(conversation)) {
    return conversation.map(convertKeysToSnakeCase);
  } else if (conversation !== null && typeof conversation === "object") {
    return Object.entries(conversation).reduce(
      (acc, [key, value]) => {
        const snakeKey = key === PREMIUM_MESSAGE ? key : toSnakeCase(key);
        acc[snakeKey] = convertKeysToSnakeCase(value);

        return acc;
      },
      {} as Record<string, unknown>
    );
  }

  return conversation;
};
