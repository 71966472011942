import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { messages } from "src/features/signin/components/CodeRecipientMessage/messages";
import { loginSelectors } from "src/features/signin/imports/state";
import { ReactComponent as TelegramIcon } from "img/login/telegram.svg";
import { ReactComponent as WhatsAppIcon } from "img/login/whatsapp.svg";
import styles from "./CodeRecipientMessage.scss";

enum CodeProvider {
  TELEGRAM = "telegram",
  WHATSAPP = "wassup",
}

export const CodeRecipientMessage = () => {
  const { allowOnlySmsValidation, codeProvider, phoneNumber } = useSelector(
    loginSelectors.getPhoneNumberAuthenticationState
  );

  const codeWasSentViaTango = !allowOnlySmsValidation;

  if (codeWasSentViaTango) {
    return (
      <FormattedMessage
        {...messages.codeWasSentViaTango}
        values={{
          tangoApp: <FormattedMessage {...messages.tangoApp} />,
          tangoChat: <FormattedMessage {...messages.tangoChat} />,
        }}
      />
    );
  }

  switch (codeProvider) {
    case CodeProvider.TELEGRAM:
      return (
        <FormattedMessage
          {...messages.codeWasSentViaProvider}
          values={{
            phoneNumber: (
              <span className={styles.phoneNumber}>{phoneNumber}</span>
            ),
            provider: (
              <>
                <br />
                <span className={styles.codeProvider}>
                  <TelegramIcon /> <FormattedMessage {...messages.telegram} />
                </span>
              </>
            ),
          }}
        />
      );

    case CodeProvider.WHATSAPP:
      return (
        <FormattedMessage
          {...messages.codeWasSentViaProvider}
          values={{
            phoneNumber: (
              <span className={styles.phoneNumber}>{phoneNumber}</span>
            ),
            provider: (
              <>
                <br />
                <span className={styles.codeProvider}>
                  <WhatsAppIcon /> <FormattedMessage {...messages.whatsApp} />
                </span>
              </>
            ),
          }}
        />
      );

    default:
      return (
        <FormattedMessage
          {...messages.codeWasSentViaSms}
          values={{
            phoneNumber: (
              <span className={styles.phoneNumber}>{phoneNumber}</span>
            ),
          }}
        />
      );
  }
};
