import uniqBy from "lodash.uniqby";
import { compose } from "redux";
import { POST, PROFILE } from "enums/giftRecipientType";
import { StreamEvents } from "src/enums";
import {
  giftsActionCreators,
  giftsSelectors,
} from "src/features/gifts/exports/state";
import { ensureHttps } from "src/utils/ensureHttps";
import { mapValues, uniq } from "src/utils/miniLodash";
import giftCacheActionCreator from "state/actionCreators/giftCache";
import {
  CHANGE_TYPE_BUY_GIFT,
  COLLECTED_GIFTS_END_FETCH,
  GIFT_SENT,
  GIFTS_LIST_BEGIN_FETCH,
  GIFTS_LIST_END_FETCH,
  GIFTS_LIST_SET_AVAILABLE_VERSION,
  GIFTS_LOADED_SINGLE_GIFT,
  GIFTS_SET_CONTEXT,
  GIFTS_SET_DRAWER_TAB,
  GIFTS_SET_OPTION_LAST_CLICKED,
  GIFTS_SET_PERSONAL_GIFTS,
  GIFTS_SET_TARGET_COLLECTION,
  GIFTS_SET_TOP_SENT_GIFTS,
  SEND_GIFT_AFTER_BUY,
  SET_GIFT_AFTER_BUY,
  SET_GIFT_ID_TO_CONFIRM,
  SET_STATUS_GIFT_WITH_DIAMOND,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
} from "state/actionTypes";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
} from "state/hor/withFetcher";
import withUserSessionScope from "state/hor/withUserSessionScope";

export const persistConfig = {
  blacklist: [
    "isLoadFailed",
    "isLoadInProgress",
    "availableDrawerVersion",
    "collectedGiftsMap",
    "stale",
    "personalGifts",
    "buyGiftWithDiamonds",
    "isAvailableGiftingWithDiamonds",
    "giftIdToConfirm",
    "giftsAfterBuy",
    "giftAnimations",
  ],
};

const initialCollectedInfo = {
  giftIds: [],
  timestampMillis: 0,
};

export const actionCreators = {
  ...createFetcherActions({
    beginFetchActionType: GIFTS_LIST_BEGIN_FETCH,
    endFetchActionType: GIFTS_LIST_END_FETCH,
  }),
  setAvailableDrawerVersion: (version) => ({
    type: GIFTS_LIST_SET_AVAILABLE_VERSION,
    payload: version,
  }),
  fetchedCollectedGifts: ({ giftIds, accountId, timestampMillis }) => ({
    type: COLLECTED_GIFTS_END_FETCH,
    payload: giftIds,
    meta: { accountId, timestampMillis },
  }),
  fetchedSingleGift: ({ gift, drawerVersion }) => ({
    type: GIFTS_LOADED_SINGLE_GIFT,
    payload: gift,
    meta: {
      drawerVersion,
    },
  }),
  setTargetCollectionId: (collectionId) => ({
    type: GIFTS_SET_TARGET_COLLECTION,
    payload: collectionId,
  }),
  setGiftsDrawerTabId: (tabId) => ({
    type: GIFTS_SET_DRAWER_TAB,
    payload: tabId || "",
  }),
  setTopSentGifts: (giftIds) => ({
    type: GIFTS_SET_TOP_SENT_GIFTS,
    payload: giftIds,
  }),
  setPersonalGifts: (gifts) => ({
    type: GIFTS_SET_PERSONAL_GIFTS,
    payload: gifts,
  }),
  setOptionLastClickedGift: (option) => ({
    type: GIFTS_SET_OPTION_LAST_CLICKED,
    payload: option,
  }),
  changeTypeBuyGift: () => ({
    type: CHANGE_TYPE_BUY_GIFT,
  }),
  setStatusGiftWithDiamond: (status) => ({
    type: SET_STATUS_GIFT_WITH_DIAMOND,
    payload: status,
  }),
  setGiftIdToConfirm: ({ giftId, realTimeGiftFromType }) => ({
    type: SET_GIFT_ID_TO_CONFIRM,
    payload: giftId,
    meta: { realTimeGiftFromType },
  }),
  setGiftAfterBuy: (payload) => ({
    type: SET_GIFT_AFTER_BUY,
    payload,
  }),
  sendGiftAfterBuy: (giftId) => ({
    type: SEND_GIFT_AFTER_BUY,
    payload: giftId,
  }),
  ...giftsActionCreators,
};

const getCollectedGiftInfoForAccountId = (state, accountId) =>
  accountId ? state.collectedGiftsMap[accountId] : undefined;

const collectionsListSelector = (state) =>
  state.collectionIdsList.map((x) => state.collections[x]);

const ensureHttpsOnGift = ({ icon, notCollectedIcon, ...rest }) => ({
  ...rest,
  icon: ensureHttps(icon),
  notCollectedIcon: ensureHttps(notCollectedIcon),
});

const ensureHttpsOnCategory = ({ activeIcon, icon, ...rest }) => ({
  ...rest,
  activeIcon: ensureHttps(activeIcon),
  icon: ensureHttps(icon),
});

// can be removed some time later, for now need this cuz of persistently cached non-https activeIcons
const categoriesMapSelector = (state) =>
  mapValues(state.categories, ensureHttpsOnCategory);

export const selectors = {
  ...fetcherSelectors,
  isStale: (state) => state.stale,
  getCategoryIdsList: (state) => state.categoryIdsList,
  getCollectionsList: collectionsListSelector,
  getCategories: (state) => state.categories,
  getCategoryById: (state, id) => categoriesMapSelector(state)[id],
  getCollectionById: (state, id) => state.collections[id],
  getGroupById: (state, id) => state.groups[id],
  getCollectedInfoTimestampMs: (state, accountId) =>
    getCollectedGiftInfoForAccountId(state, accountId)?.timestampMillis || 0,
  getCollectedGiftIds: (state, accountId) =>
    getCollectedGiftInfoForAccountId(state, accountId)?.giftIds,
  getGiftById: (state, id) => state.gifts[id],
  getGiftsByIds: (state, ids) =>
    ids.reduce((gifts, id) => {
      const gift = state.gifts[id];
      if (gift) {
        gifts.push(gift);
      }

      return gifts;
    }, []),
  getLoadedVersion: (state) => state.loadedDrawerVersion,
  getAvailableVersion: (state) => state.availableDrawerVersion,
  getCollectibleGiftIds: (state) => state.collectibleGifts,
  getAllGifts: (state) => state.gifts,
  getGiftIdToLottieUrl: (state) =>
    mapValues(
      state.gifts,
      ({ lottieAnimationUrl }) => lottieAnimationUrl || undefined,
      {
        omitUndefined: true,
      }
    ),
  getAllGiftsAsList: (state) => Object.values(state.gifts),
  getLoadedGiftsLocale: (state) => state.locale,
  getTargetCollectionId: (state) => state.targetCollectionId,
  getGiftsDrawerTabId: (state) => state.giftsDrawerTabId,
  getTopSentGifts: (state) => state.topSentGifts,
  getPersonalGifts: (state) => state.personalGifts,
  getOptionLastClickedGift: (state) => state.option,
  getStatusGiftWithDiamond: (state) => state.isAvailableGiftingWithDiamonds,
  isBuyGiftWithCoins: (state) => !state.buyGiftWithDiamonds,
  getGiftIdToConfirm: (state) => state.giftIdToConfirm,
  getGiftsNotSendAfterBuy: (state) => state.giftsAfterBuy,
  getGiftAnimationByKey: (state, key) => state.giftAnimations[key],
  getRealTimeGiftFromType: (state) => state.realTimeGiftFromType,
  ...giftsSelectors,
};

const collectToMap = (list) =>
  list.reduce((a, x) => {
    a[x.id] = x;

    return a;
  }, {});

const UNIQ_GIFT_FIELD = "id";

export default compose(
  withUserSessionScope,
  withFetcher({
    beginFetchActionType: GIFTS_LIST_BEGIN_FETCH,
    endFetchActionType: GIFTS_LIST_END_FETCH,
    initialData: {
      stale: true,
      categoryIdsList: [],
      collectibleGifts: [],
      gifts: {},
      categories: {},
      collections: {},
      groups: {},
      collectionIdsList: [],
      loadedDrawerVersion: 0,
      availableDrawerVersion: 0,
      collectedGiftsMap: {}, // account id + timestamp + collected gift ids
      targetCollectionId: null,
      giftsDrawerTabId: "",
      topSentGifts: [],
      personalGifts: [],
      option: {},
      giftsAfterBuy: [],
      buyGiftWithDiamonds: false,
      isAvailableGiftingWithDiamonds: false,
      giftIdToConfirm: null,
      realTimeGiftFromType: "",
      giftAnimations: {},
      defaultPremiumMessageGiftIds: [],
      oneClickFlowLocation: null,
    },
    extractData: ({
      categories = [],
      gifts = [],
      collections = [],
      groups = [],
      drawerVersion,
      locale,
    }) => ({
      loadedDrawerVersion: drawerVersion,
      categoryIdsList: categories.map((x) => x.id),
      categories: collectToMap(categories.map(ensureHttpsOnCategory)),
      groups: collectToMap(groups),
      collections: collectToMap(
        collections.map(
          ({
            completedImage,
            incompletedImage,
            currentCompletedImage,
            ...rest
          }) => ({
            ...rest,
            completedImage: ensureHttps(completedImage),
            incompletedImage: ensureHttps(incompletedImage),
            currentCompletedImage: ensureHttps(currentCompletedImage),
          })
        )
      ),
      collectionIdsList: collections.map((x) => x.id),
      gifts: collectToMap(gifts.map(ensureHttpsOnGift)),
      collectibleGifts: [...new Set(collections.flatMap((x) => x.giftIds))],
      locale,
      stale: false,
    }),
    mergeData: (oldData, newData) => ({
      ...newData,
      gifts: { ...oldData.gifts, ...newData.gifts },
    }),
  })
)((state, action) => {
  switch (action.type) {
    case GIFTS_LIST_SET_AVAILABLE_VERSION: {
      if (state.availableDrawerVersion >= action.payload) {
        return state;
      }

      return { ...state, availableDrawerVersion: action.payload };
    }
    case COLLECTED_GIFTS_END_FETCH: {
      return {
        ...state,
        collectedGiftsMap: {
          ...state.collectedGiftsMap,
          [action.meta.accountId]: {
            giftIds: action.payload,
            timestampMillis: action.meta.timestampMillis,
          },
        },
      };
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }
      const {
        payload: {
          detail: { stream: { encryptedAccountId } = {} } = {},
          entities: { events = {} } = {},
        },
      } = action;

      if (!encryptedAccountId) {
        return state;
      }

      const info =
        getCollectedGiftInfoForAccountId(state, encryptedAccountId) ||
        initialCollectedInfo;
      const newCollectedGiftIds = Object.values(events)
        .filter((x) => x.type === StreamEvents.GIFT)
        .map((x) => x.data.giftId)
        .filter(
          (x) => state.collectibleGifts.includes(x) && !info.giftIds.includes(x)
        );
      if (!newCollectedGiftIds.length) {
        return state;
      }

      return {
        ...state,
        collectedGiftsMap: {
          ...state.collectedGiftsMap,
          [encryptedAccountId]: {
            ...info,
            giftIds: [...info.giftIds, ...newCollectedGiftIds],
          },
        },
      };
    }
    case GIFTS_LOADED_SINGLE_GIFT: {
      const gift = action.payload;

      return {
        ...state,
        gifts: {
          ...state.gifts,
          [gift.id]: ensureHttpsOnGift(gift),
        },
      };
    }
    case GIFT_SENT: {
      const { giftId, userId } = action.payload;

      if (!state.collectibleGifts.includes(giftId)) {
        return state;
      }
      const info =
        getCollectedGiftInfoForAccountId(state, userId) || initialCollectedInfo;
      if (info.giftIds.includes(giftId)) {
        return state;
      }

      return {
        ...state,
        collectedGiftsMap: {
          ...state.collectedGiftsMap,
          [userId]: { ...info, giftIds: [...info.giftIds, giftId] },
        },
      };
    }
    case GIFTS_SET_TARGET_COLLECTION: {
      return { ...state, targetCollectionId: action.payload };
    }
    case GIFTS_SET_DRAWER_TAB: {
      return { ...state, giftsDrawerTabId: action.payload };
    }
    case GIFTS_SET_TOP_SENT_GIFTS: {
      return { ...state, topSentGifts: action.payload };
    }
    case GIFTS_SET_PERSONAL_GIFTS: {
      return {
        ...state,
        personalGifts: uniqBy(action.payload, UNIQ_GIFT_FIELD),
        gifts: {
          ...state.gifts,
          ...collectToMap(action.payload.map(ensureHttpsOnGift)),
        },
      };
    }
    case GIFTS_SET_OPTION_LAST_CLICKED: {
      const option = action.payload;

      return {
        ...state,
        option: {
          ...option,
        },
      };
    }
    case SET_STATUS_GIFT_WITH_DIAMOND: {
      return { ...state, isAvailableGiftingWithDiamonds: action.payload };
    }
    case CHANGE_TYPE_BUY_GIFT: {
      return { ...state, buyGiftWithDiamonds: !state.buyGiftWithDiamonds };
    }
    case GIFTS_SET_CONTEXT: {
      const { recipientType } = action.payload;

      if (
        state.buyGiftWithDiamonds &&
        (recipientType === PROFILE || recipientType === POST)
      ) {
        return { ...state, buyGiftWithDiamonds: false };
      }

      return state;
    }
    case SET_GIFT_ID_TO_CONFIRM: {
      return {
        ...state,
        giftIdToConfirm: action.payload,
        realTimeGiftFromType: action.meta.realTimeGiftFromType,
      };
    }
    case SET_GIFT_AFTER_BUY: {
      const gift = action.payload;

      return {
        ...state,
        giftsAfterBuy: [...state.giftsAfterBuy, gift],
      };
    }
    case SEND_GIFT_AFTER_BUY: {
      const giftId = action.payload;

      return {
        ...state,
        giftsAfterBuy: state.giftsAfterBuy.filter((item) => item.id !== giftId),
      };
    }
    case giftCacheActionCreator.setGiftAnimation.type: {
      const { url, jsonData } = action.payload;

      return {
        ...state,
        giftAnimations: {
          ...state.giftAnimations,
          [url]: jsonData,
        },
      };
    }
    case giftsActionCreators.setOneClickFlowLocation.type: {
      const oneClickFlowLocation = action.payload;

      return {
        ...state,
        oneClickFlowLocation,
      };
    }
    case giftsActionCreators.selectPremiumMessageGift.type: {
      const giftId = action.payload;
      const newDefaultGiftIds = uniq([
        giftId,
        ...state.defaultPremiumMessageGiftIds,
      ]);

      if (
        newDefaultGiftIds.length > state.defaultPremiumMessageGiftIds.length
      ) {
        newDefaultGiftIds.pop();
      }

      return {
        ...state,
        defaultPremiumMessageGiftIds: newDefaultGiftIds,
      };
    }
    case giftsActionCreators.setDefaultPremiumMessageGiftIds.type: {
      return {
        ...state,
        defaultPremiumMessageGiftIds: action.payload,
      };
    }
    case giftsActionCreators.giftsBatchReceived.type: {
      return {
        ...state,
        gifts: {
          ...state.gifts,
          ...collectToMap(action.payload.gifts.map(ensureHttpsOnGift)),
        },
      };
    }
  }

  return state;
});
