import { ReferredUsersMetaData } from "src/features/referralProgram/common/types";

export const getProjectedTierIndex = (meta: ReferredUsersMetaData) => {
  const idx = meta.tiers?.findIndex(
    ({ id }) => id === meta.tier?.projectedNextTier
  );

  // return 1 because the first tier (Rookie) is not counted
  return !idx || idx === -1 ? 1 : idx;
};
