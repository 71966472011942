import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { Subscription } from "api/subscriptionToStreamerList";
import { SubscriptionStatus } from "src/enums";
import { uniq, without } from "src/utils/miniLodash";
import actions from "state/actionCreators/subscriptionPerStreamerList";
import { VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT } from "state/actionTypes";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";

const getStreamerAccountIds = (list: Subscription[], accountIds: string[]) => [
  ...accountIds,
  ...list.map((item) => item.details.streamer.streamerProfile.accountId),
];

const getSubscribersAccountIds = (list: Subscription[]) =>
  list.map((item) => item.subscriberProfile.accountId);

const mapDetailsList = (
  list: Subscription[],
  map: Partial<Record<string, Subscription>>
) => ({
  ...map,
  ...list.reduce<Record<string, Subscription>>((accum, current) => {
    const {
      details: {
        streamer: {
          streamerProfile: { accountId },
        },
      },
    } = current;
    accum[accountId] = current;
    return accum;
  }, {}),
});

export const mapSubscribersList = (list: Subscription[]) =>
  list.reduce<Record<string, Subscription>>((accum, current) => {
    const {
      subscriberProfile: { accountId },
    } = current;
    accum[accountId] = current;
    return accum;
  }, {});

const userSubscribersInitialState = {
  data: {
    accountIds: [],
    map: {},
  },
  meta: initialFetcherStateMeta,
};

export type UserSubscribersState = AsyncState<
  { accountIds: string[]; map: Partial<Record<string, Subscription>> },
  string
>;

const userSubscribers = createReducer<UserSubscribersState>(
  userSubscribersInitialState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.refreshUserSubscribersList,
      prepareData: (_, list) => ({
        accountIds: uniq(getSubscribersAccountIds(list)),
        map: mapSubscribersList(list),
      }),
      initialData: userSubscribersInitialState.data,
    });
  }
);

const userSubscriptionsInitialState = {
  data: {
    accountIds: [],
    map: {},
  },
  meta: initialFetcherStateMeta,
};

export type UserSubscriptionsState = AsyncState<
  { accountIds: string[]; map: Partial<Record<string, Subscription>> },
  string
>;

const userSubscriptions = createReducer<UserSubscriptionsState>(
  userSubscriptionsInitialState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.refreshUserSubscriptionsList,
      prepareData: (data, list, meta) => {
        const accountId = meta.arg.details?.streamer.streamerProfile.accountId;
        const currentSubscription = accountId ? data.map[accountId] : undefined;

        const filteredList = list.filter(({ id }) =>
          currentSubscription?.id ? id === currentSubscription.id : true
        );

        return {
          accountIds: uniq(
            getStreamerAccountIds(filteredList, data.accountIds)
          ),
          map: mapDetailsList(filteredList, data.map),
        };
      },
      initialData: userSubscriptionsInitialState.data,
    })
      .addCase(actions.unsubscribeStreamer, (state, action) => {
        const {
          payload: { accountId },
        } = action;

        const currentSubscription = state.data.map[accountId];
        if (currentSubscription) {
          state.data.map[accountId] = {
            ...currentSubscription,
            status: SubscriptionStatus.CANCELLED,
          };
        }
      })
      .addCase(actions.clearSubscription, (state, action) => {
        const {
          payload: { accountId },
        } = action;
        state.data.accountIds = without(state.data.accountIds, accountId);
        delete state.data.map[accountId];
      })
      .addCase(actions.subscribeStreamer, (state, action) => {
        const {
          payload: { streamerId, subscription, forceSubscribe = false },
        } = action;
        state.data.accountIds = uniq([...state.data.accountIds, streamerId]);
        state.data.map[streamerId] = {
          ...subscription,
          status: forceSubscribe
            ? SubscriptionStatus.ACTIVE
            : subscription.status,
        };
      })
      .addCase(actions.clearListsData, (state) => {
        state.data = userSubscriptionsInitialState.data;
      });
  }
);

const streamerSubscribersInitialState = {
  data: {},
  meta: initialFetcherStateMeta,
};

export type StreamerSubscribersState = AsyncState<
  Record<
    string,
    { accountIds: string[]; map: Partial<Record<string, Subscription>> }
  >,
  string
>;

const streamerSubscribers = createReducer<StreamerSubscribersState>(
  streamerSubscribersInitialState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.refreshStreamerSubscribersList,
      prepareData: (data, list, meta) => ({
        ...data,
        [meta.arg.accountId]: {
          accountIds: uniq(getSubscribersAccountIds(list)),
          map: mapSubscribersList(list),
        },
      }),
      initialData: streamerSubscribersInitialState.data,
    })
      .addCase(actions.clearListsData, (state) => {
        state.data = streamerSubscribersInitialState.data;
      })
      .addMatcher(
        (action) => action.type === VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
        (state, action) => {
          const { payload } = action;

          if (!payload.eventIds || payload.eventIds.length < 1) {
            return state;
          }

          const id = payload.eventIds[0];

          if (payload.entities.events[id].type === "SUBSCRIPTION") {
            state.meta.stale = true;
          }
        }
      );
  }
);

export interface SubscriptionPerStreamerListState {
  streamerSubscribers: StreamerSubscribersState;
  userSubscribers: UserSubscribersState;
  userSubscriptions: UserSubscriptionsState;
}

export default combineReducers({
  userSubscriptions,
  userSubscribers,
  streamerSubscribers,
});
