import React, { FC } from "react";
import LegalAccordion from "legal/components/accordion/LegalAccordion";
import LegalNavigation from "legal/components/navigation/LegalNavigation";
import { Breakpoints } from "legal/enums";
import { useBreakpointPrecise } from "legal/imports/hooks";
import { LegalNavigationConfig } from "legal/types";

interface NavigationComponentProps {
  navigationConfig: LegalNavigationConfig[];
}

const NavigationComponent: FC<NavigationComponentProps> = ({
  navigationConfig,
}) => {
  const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;

  const LegalNavigationComponent = isDesktop ? LegalNavigation : LegalAccordion;

  return <LegalNavigationComponent navigationConfig={navigationConfig} />;
};

export default NavigationComponent;
