import { isSameDay } from "date-fns";
import { ignoredMessagesTypes } from "chat/constants";
import {
  generateAsyncSelectors,
  typedDelegateSelectors,
} from "chat/imports/state";
import { messageRequestAdapter } from "chat/messageRequest/common/adapter";
import { TANGO_ACCOUNT_ID } from "chat/messageRequest/imports/constants";
import { uniq } from "chat/messageRequest/imports/utils";
import { MessagesRequestState } from "chat/messageRequest/state/reducer";
import { StoredMessage } from "chat/messageRequest/types";
import { ConversationState } from "chat/types";

const { data, meta } = generateAsyncSelectors<MessagesRequestState>();

export const localMessageRequestSelectors = {
  data,
  meta,
  getTotalConversationRequestCount: (state: MessagesRequestState) =>
    state.data?.totalConversationRequestCount,
  getConversations: (state: MessagesRequestState) => {
    const { conversations } = data(state);
    const sortedConversations = Object.values(conversations).sort((a, b) => {
      const aTs = Number(a.lastMessageTs) || 0;
      const bTs = Number(b.lastMessageTs) || 0;
      if (aTs === bTs) {
        if (a.conversationId === TANGO_ACCOUNT_ID) {
          return -1;
        }
        if (b.conversationId === TANGO_ACCOUNT_ID) {
          return 1;
        }

        return a.conversationId?.localeCompare(b.conversationId);
      }

      return bTs - aTs;
    });

    return messageRequestAdapter(
      sortedConversations
        .filter(
          ({ state, hidden }) =>
            state === ConversationState.CHAT_REQUEST && hidden
        )
        .map((conversation) => ({ ...conversation }))
    );
  },
  getConversation: (state: MessagesRequestState, conversationId: string) =>
    data(state).conversations[conversationId],
  getLoading: (state: MessagesRequestState) => state.meta.loading,
  getError: (state: MessagesRequestState) => state.meta.error,
  getStale: (state: MessagesRequestState) => state.meta.stale,
  canLoadMore: (state: MessagesRequestState) => data(state).canLoadMore,
  getMessagesByConversationId: (
    state: MessagesRequestState,
    conversationId: string
  ) => data(state).messages[conversationId],
  getConversationMessagesByDates: (
    state: MessagesRequestState,
    conversationId: string
  ) => {
    const preparedData = (data(state).messages[conversationId] || []).reduce(
      (
        acc: {
          accountIds: string[];
          dates: { date: number; messages: StoredMessage[] }[];
          lastMessageTs: number;
          latestMessageFrom: string | undefined;
          messagesIds: string[];
        },
        next
      ) => {
        if (ignoredMessagesTypes.includes(next.type)) {
          return acc;
        }
        acc.lastMessageTs = next.id.ts;
        if (acc.messagesIds.length === 0) {
          acc.latestMessageFrom = next.from;
        }
        acc.messagesIds.push(`${next.id.id}`);

        if (next.from) {
          acc.accountIds.push(next.from);
        }

        if (acc.dates.length === 0) {
          acc.dates.push({
            date: next.id.ts,
            messages: [next],
          });

          return acc;
        }

        const isSameDayTs = isSameDay(
          acc.dates[acc.dates.length - 1].date,
          next.id.ts
        );

        if (isSameDayTs) {
          acc.dates[acc.dates.length - 1].messages.push(next);
        } else {
          acc.dates.push({
            date: next.id.ts,
            messages: [next],
          });
        }

        return acc;
      },
      {
        dates: [],
        messagesIds: [],
        lastMessageTs: 0,
        accountIds: [],
        latestMessageFrom: undefined,
      }
    );

    preparedData.accountIds = uniq(preparedData.accountIds);

    return preparedData;
  },
};

export const messageRequestSelectors = typedDelegateSelectors(
  // @ts-ignore
  localMessageRequestSelectors,
  "messageRequest"
);
