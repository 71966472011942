import { datadogLogs } from "@datadog/browser-logs";
import { VoidCallback } from "src/types/common";

class DatadogTransport {
  static availableToLogger(isAvailable: boolean, callback: VoidCallback) {
    if (isAvailable) callback();
  }

  static error(message: string, messageContext?: object, error?: Error) {
    datadogLogs.logger.error(message, messageContext, error);
  }

  static info(message: string, messageContext?: object, error?: Error) {
    datadogLogs.logger.info(message, messageContext, error);
  }

  static setGlobalContext(context: object) {
    datadogLogs.setGlobalContext(context);
  }

  static warn(message: string, messageContext?: object, error?: Error) {
    datadogLogs.logger.warn(message, messageContext, error);
  }
}

export default DatadogTransport;
