import { createAction, createReducer } from "@reduxjs/toolkit";
import { AcmeServiceIdentifier, AcmeServiceName, Platforms } from "src/enums";
import { Nullable } from "src/types/common";
import { PricePoint } from "src/types/personalOffer";
import { ACME_RECEIVED } from "state/actionTypes";

export const persistConfig = {
  whitelist: ["specialOffers", "isAllOffersViewed"],
};

const initialState = {
  specialOffers: [],
  campaignId: "",
  isAllOffersViewed: true,
};

export interface Offer {
  campaignId: string;
  destination: string[];
  expirationDateMs: number;
  personalOfferId: string;
  pricePoints: PricePoint[];
  purchaseLimit: Nullable<number>;
  sasEnabled: boolean;
  triggerId?: string;
  ts: number;
  type: string;
}

export interface SpecialOffer {
  campaignId: string;
  isAllOffersViewed: boolean;
  isSelectedSpecialOffer?: boolean;
  specialOffers: Offer[];
}

export interface SpecialOfferAcme {
  data?: Offer;
  serviceIdentifier?: string;
  serviceName?: string;
}

export const actionCreators = {
  addSpecialOffer: createAction<SpecialOfferAcme>(ACME_RECEIVED),
  updateSpecialOffer: createAction(
    "lwc/specialOffer/update",
    (campaignId: string) => ({
      payload: {
        campaignId,
      },
    })
  ),
  selectedSpecialOffer: createAction(
    "lwc/specialOffer/select",
    (isSelect: boolean) => ({
      payload: {
        isSelect,
      },
    })
  ),
  removeSpecialOffer: createAction(
    "lwc/specialOffer/remove",
    (campaignId: string) => ({
      payload: {
        campaignId,
      },
    })
  ),
  viewAllOffers: createAction("lwc/specialOffer/viewAllOffers"),
  removeExpiredOffers: createAction("lwc/specialOffer/removeAllExpired"),
};

const reducer = createReducer<SpecialOffer>(initialState, (builder) => {
  builder
    .addCase(actionCreators.addSpecialOffer, (state, action) => {
      const { serviceName, serviceIdentifier, data } = action.payload;
      if (
        serviceName === AcmeServiceName.PERSONAL_OFFERS &&
        serviceIdentifier === AcmeServiceIdentifier.PERSONAL_OFFERS_ACME
      ) {
        if (data) {
          data.pricePoints.forEach((item) => {
            item.offers = item.offers.filter((offer) =>
              offer.category.includes(Platforms.WEB)
            );

            return item;
          });

          state.specialOffers.push(data);
          state.isAllOffersViewed = false;
        }
      }
    })
    .addCase(actionCreators.updateSpecialOffer, (state, action) => {
      const { campaignId } = action.payload;
      state.specialOffers = state.specialOffers.map((offer) => {
        if (offer.campaignId === campaignId && offer.purchaseLimit !== null) {
          return {
            ...offer,
            purchaseLimit: offer.purchaseLimit - 1,
          };
        }

        return offer;
      });
    })
    .addCase(actionCreators.selectedSpecialOffer, (state, action) => {
      const { isSelect } = action.payload;
      state.isSelectedSpecialOffer = isSelect;
    })
    .addCase(actionCreators.removeSpecialOffer, (state, action) => {
      const { campaignId } = action.payload;
      state.specialOffers = state.specialOffers.filter(
        (offer) => offer.campaignId !== campaignId
      );
      !state.specialOffers.length && (state.isAllOffersViewed = true);
    })
    .addCase(actionCreators.removeExpiredOffers, (state) => {
      state.specialOffers = state.specialOffers.filter(
        ({ expirationDateMs }) => expirationDateMs > Date.now()
      );
      !state.specialOffers.length && (state.isAllOffersViewed = true);
    })
    .addCase(actionCreators.viewAllOffers, (state) => {
      state.isAllOffersViewed = true;
    });
});

export default reducer;

export const selectors = {
  getSpecialOffers: (state: SpecialOffer) =>
    state.specialOffers[state.specialOffers.length - 1],
  getLastActiveSpecialOffer: (
    state: SpecialOffer,
    destinations: string[] = []
  ) =>
    // TODO: replace to findLast during https://tango-me.atlassian.net/browse/WEB-6338
    [...state.specialOffers].reverse().find((offer) => {
      if (offer.expirationDateMs > Date.now()) {
        if (destinations.length > 0) {
          return destinations.some((destination) =>
            offer.destination.includes(destination)
          );
        }

        return true;
      }

      return false;
    }),
  getIsSelectedSpecialOffer: (state: SpecialOffer) =>
    !!state.isSelectedSpecialOffer,
  isAllOffersViewed: (state: SpecialOffer) => state.isAllOffersViewed,
};
