import { getCurrentStream, giftsCacheSelectors } from "state/selectors";
export default (state) => {
  const stream = getCurrentStream(state);
  const gifts = giftsCacheSelectors.getAllGifts(state);
  const categories = giftsCacheSelectors.getCategoryIdsList(state);
  const classicCategory =
    categories && giftsCacheSelectors.getCategoryById(state, categories[0]);

  if (!classicCategory || classicCategory.giftIds.length === 0 || !stream) {
    return;
  }
  const classicGifts = classicCategory.giftIds.map((x) => gifts[x]);

  return (
    classicGifts
      .filter((x) => !x.special)
      .sort((a, b) => a.priceInCredit - b.priceInCredit)
      .find((x) => x.priceInCredit >= stream.ticketPrice) ||
    classicGifts[classicGifts.length - 1]
  );
};
