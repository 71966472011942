import React from "react";
import classnames from "classnames";
import { ArrowIcon } from "chat/imports/assets";
import { Button } from "chat/imports/components";
import { ButtonSize, ButtonVariant } from "chat/imports/constants";
import { ComponentWithClassName, VoidCallback } from "chat/imports/types";
import styles from "./BackButton.scss";

interface BackButtonProps {
  onClick: VoidCallback;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const BackButton: ComponentWithClassName<BackButtonProps> = ({
  onClick,
  className,
  size = ButtonSize.CIRCLE_MEDIUM_40,
  variant = ButtonVariant.SECONDARY,
}) => (
  <Button
    className={classnames(styles.root, className)}
    data-testid="back"
    onClick={onClick}
    size={size}
    variant={variant}
  >
    <ArrowIcon />
  </Button>
);

export default BackButton;
