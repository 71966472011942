import React, { FC, memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Avatar,
  Button,
  DisplayName,
  Typography,
} from "chat/imports/components";
import {
  AvatarSize,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useProfileById } from "chat/imports/hooks";
import { TCProfileInfo, parseMessageFromBase64 } from "chat/imports/proto";
import { userSelectors } from "chat/imports/state";
import { makeLinkToProfile } from "chat/imports/utils";
import { StoredMessage } from "chat/state/reducer";
import styles from "./ProfileMessage.scss";

interface ProfileMessageProps {
  message: StoredMessage;
}

const ProfileMessage: FC<ProfileMessageProps> = ({ message }) => {
  const accountId = useSelector(userSelectors.getMyAccountId);
  const isMyMessage = accountId === message.from;

  const { profileId } = useMemo(
    () => parseMessageFromBase64(message.payload, TCProfileInfo),
    [message.payload]
  );

  const { basicProfile } = useProfileById(profileId, true);

  return (
    <Link
      to={makeLinkToProfile(profileId, basicProfile)}
      className={classnames(styles.root, { [styles.myMessage]: isMyMessage })}
    >
      {!!basicProfile && (
        <>
          <div className={styles.container}>
            <div className={styles.avatar}>
              <Avatar
                size={AvatarSize.SMALL_PLUS}
                basicProfile={basicProfile}
              />
            </div>
            <Typography
              type={TYPOGRAPHY_TYPE.HEADLINE4}
              className={styles.name}
              as="div"
            >
              <DisplayName basicProfile={basicProfile} showVerified />
            </Typography>
            <Button
              size={ButtonSize.MEDIUM_32}
              variant={
                isMyMessage
                  ? ButtonVariant.GET_COINS_SECONDARY
                  : ButtonVariant.PRIMARY
              }
              className={styles.button}
              data-testid="view-profile-button"
            >
              <FormattedMessage
                id="view.profile"
                defaultMessage="View Profile"
              />
            </Button>
          </div>
        </>
      )}
    </Link>
  );
};

export default memo(ProfileMessage);
