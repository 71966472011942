import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface BonusWheelState {
  bonusWheelStatus: number;
}

const initialState: BonusWheelState = {
  bonusWheelStatus: 0,
};

const slice = createSlice({
  name: "bonusWheel",
  initialState,
  reducers: {
    setBonusWheelStatus(state, action: PayloadAction<number>) {
      state.bonusWheelStatus = action.payload;
    },
  },
});

export const { setBonusWheelStatus } = slice.actions;

export const bonusWheelReducer = slice.reducer;
