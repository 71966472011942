type Subscriber<T> = (value: T) => void;

type Subscription = () => void;

export const createObservable = <T>(value: T) => {
  let internalValue = value;

  const subscribers: Subscriber<T>[] = [];

  const set = (value: T) => {
    internalValue = value;
    subscribers.forEach((subscriber) => subscriber(value));
  };

  const get = () => internalValue;

  const subscribe = (subscriber: Subscriber<T>): Subscription => {
    subscribers.push(subscriber);

    return () => {
      const index = subscribers.indexOf(subscriber);

      if (index !== -1) {
        subscribers.splice(index, 1);
      }
    };
  };

  return {
    set,
    get,
    subscribe,
  };
};
