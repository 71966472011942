import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import messages from "chat/components/common/DeleteAllButton/messages";
import { Button, Typography } from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  ModalType,
  SCENE,
  TYPOGRAPHY_TYPE,
  linkToMessageRequest,
  linkToRequest,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { ComponentWithClassName } from "chat/imports/types";
import {
  openConfirmationBottomScreen,
  openConfirmationModal,
  sharedMessages,
} from "chat/imports/utils";
import { deleteAllMessageRequests } from "chat/messageRequest/state/asyncAction";

const DeleteAllButton: ComponentWithClassName = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpointPrecise();
  const { pathname } = useLocation();

  const openConfirmation =
    breakpoint === Breakpoints.DESKTOP
      ? openConfirmationModal
      : openConfirmationBottomScreen;

  const onConfirm = useCallback(() => {
    dispatch(deleteAllMessageRequests());

    if (pathname.includes(linkToRequest)) {
      history.push(linkToMessageRequest);
    }
  }, [dispatch, pathname]);

  const openDeleteAllConfirmation = useCallback(() => {
    dispatch(
      // @ts-ignore TODO: JIRA ticket https://tango-me.atlassian.net/browse/WEB-5935
      openConfirmation({
        modalScope: SCENE,
        modalType: ModalType.DELETE_ALL_MESSAGE_REQUESTS,
        title: messages.title,
        confirmText: messages.delete,
        dismissText: sharedMessages.cancel,
        body: <FormattedMessage {...messages.description} />,
        confirm: onConfirm,
        isConfirmButtonHighlighted: true,
      })
    );
  }, [dispatch, onConfirm]);

  return (
    <Button
      className={className}
      dataTestId="message-requests-delete-all-button"
      onClick={openDeleteAllConfirmation}
      size={ButtonSize.BIG_48}
      variant={ButtonVariant.SECONDARY}
    >
      <Typography type={TYPOGRAPHY_TYPE.HEADLINE5}>
        <FormattedMessage {...messages.deleteAll} />
      </Typography>
    </Button>
  );
};

export default DeleteAllButton;
