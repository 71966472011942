import { useSelector } from "react-redux";
import { getCaptchaLockingEnabled } from "src/features/signin/imports/environment";
import { captchaSelectors } from "src/features/signin/imports/state";

export default () => {
  const captchaRequired =
    useSelector(captchaSelectors.isLockedByCaptcha) &&
    getCaptchaLockingEnabled();

  return captchaRequired;
};
