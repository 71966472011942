import { useEffect } from "react";
import { history } from "state/configureStore";

export const useHistoryChange = (callback: VoidFunction) => {
  useEffect(() => {
    const clearListener = history.listen(callback);

    return () => {
      clearListener();
    };
  }, [callback]);
};
