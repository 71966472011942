import { useMemo } from "react";
import { GiftOriginType } from "chat/imports/constants";
import { ChatGiftMessage, parseMessageFromBase64 } from "chat/imports/proto";
import { StoredMessage } from "chat/state/reducer";

export const useGiftMessage = (message: StoredMessage) => {
  const { payload, from, body } = message;

  const payloadData = useMemo(
    () => parseMessageFromBase64(payload, ChatGiftMessage),
    [payload]
  );

  const { giftId, iconUrl, receiverId } = payloadData;

  // FYI: Here you need to call hasOwnProperty because the
  // protobuff writes optional values to prototype
  // As a result, even if giftOrigin does not have a value,
  // it is still present in the prototype with the value
  const isToUnlock = Object.prototype.hasOwnProperty.call(
    payloadData,
    "giftOrigin"
  )
    ? payloadData.giftOrigin === GiftOriginType.GIFT_ORIGIN_PREMIUM_MESSAGE
    : false;

  return { giftId, iconUrl, receiverId, from, body, isToUnlock };
};
