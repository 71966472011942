import { useContext } from "react";
import { MAX, MIN } from "src/constants";
import { Breakpoints } from "src/enums";
import {
  BreakpointContext,
  BreakpointWithoutDesktop,
  getBreakpointQuery,
} from "ui/Breakpoint";
import useMedia from "ui/hooks/useMedia";
import useWindowDimensions from "ui/hooks/useWindowDimensions";

const isTallDeviceCond = "(min-height: 890px)";

/**
 * @deprecated
 *
 * use useBreakpointPrecise instead
 */
export const useBreakpoint = () => useContext(BreakpointContext);

// Lower Than
export const useBreakpointLT = (breakpoint: BreakpointWithoutDesktop) =>
  useMedia([getBreakpointQuery(breakpoint, MAX, true)], [true], false);

// Lower Than or Equal
export const useBreakpointLTE = (breakpoint: BreakpointWithoutDesktop) =>
  useMedia([getBreakpointQuery(breakpoint, MAX)], [true], false);

// Greater Than
export const useBreakpointGT = (breakpoint: BreakpointWithoutDesktop) =>
  useMedia([getBreakpointQuery(breakpoint, MIN, true)], [true], false);

// Greater Than or Equal
export const useBreakpointGTE = (breakpoint: BreakpointWithoutDesktop) =>
  useMedia([getBreakpointQuery(breakpoint, MIN)], [true], false);

export const useBreakpointMobileLayout = () => {
  const isTabletOrLessWidth = useBreakpointLT(Breakpoints.TABLET);
  const { width, height } = useWindowDimensions();
  const isTallDevice = useMedia([isTallDeviceCond], [true], false);

  if (isTabletOrLessWidth) return true;

  const isLandscape = width > height;

  // For vertical iPad Pro
  return !isLandscape && isTallDevice;
};

export const useBreakpointPrecise = () => {
  const breakpoint = useBreakpoint();
  const isMobileDevice = useBreakpointMobileLayout();
  if (breakpoint === Breakpoints.DESKTOP && isMobileDevice) {
    return Breakpoints.TABLET;
  }
  return breakpoint;
};

export const useBreakpointTablet = () => useBreakpointLT(Breakpoints.TABLET);
export const useBreakpointMobile = () => useBreakpointLT(Breakpoints.MOBILE);
export const useBreakpointSmallMobile = () =>
  useBreakpointLT(Breakpoints.SMALL_MOBILE);

export const useBreakpointSE = () => {
  const { width, height } = useWindowDimensions();
  return width < 376 && height < 668;
};

export const useBreakpointTightDevice = () => {
  const { height } = useWindowDimensions();
  return height < 590;
};
