import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Title from "src/features/signin/components/Title/Title";
import { Breakpoints } from "src/features/signin/imports/enums";
import {
  RootState,
  countriesSelectors,
} from "src/features/signin/imports/state";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Scrollbar,
  TYPOGRAPHY_TYPE,
  Typography,
  sharedMessages,
  useBreakpoint,
} from "src/features/signin/imports/ui";
import {
  loginSetPhoneCountryInfo,
  loginSetSelectCountryStep,
  specifyPhoneNumberForPhoneLogin,
} from "src/features/signin/state/login/actionCreators";
import { ReactComponent as ClearIcon } from "img/close-rounded.svg";
import { ReactComponent as EmptySearchIcon } from "img/emptyState/search_64.svg";
import { ReactComponent as SearchIcon } from "img/ic_search_v2.svg";
import styles from "./CountrySelect.scss";

const CountrySelect = () => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const [query, setQuery] = useState("");

  const countries = useSelector(
    (state: RootState) => countriesSelectors.getFilteredCountries(state, query),
    shallowEqual
  );

  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const isTablet = breakpoint === Breakpoints.TABLET;

  const onContainerClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const onChange = useCallback((event) => {
    const query = event.target.value.trim();

    setQuery(query);
  }, []);

  const handleClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }

    setQuery("");
  }, []);

  const selectCountry = useCallback(
    ({ countryCode, countryIcon }) => {
      batch(() => {
        dispatch(loginSetSelectCountryStep(false));
        dispatch(
          loginSetPhoneCountryInfo({
            countryCode,
            countryIcon,
          })
        );
        dispatch(specifyPhoneNumberForPhoneLogin());
      });
    },
    [dispatch]
  );

  const onSelectCountry = useCallback(
    (country) => () => selectCountry(country),
    [selectCountry]
  );

  return (
    <>
      <Title
        title={
          <FormattedMessage
            id="modal.login.phone.select.country.header.title"
            defaultMessage="Select country"
          />
        }
      />

      <div className={classnames(styles.container, styles[breakpoint])}>
        <div
          ref={rootRef}
          className={styles.searchContainer}
          onClick={onContainerClick}
          role="searchbox"
          tabIndex={0}
        >
          <SearchIcon className={styles.searchIcon} />

          <input
            ref={inputRef}
            className={styles.input}
            data-testid="search-input"
            defaultValue=""
            onChange={onChange}
            placeholder={formatMessage(sharedMessages.searchPlaceholder)}
            autoFocus
          />

          {Boolean(query.length) && (
            <Button
              className={styles.clear}
              onClick={handleClear}
              size={ButtonSize.SMALL_24}
              variant={ButtonVariant.ICON_ONLY}
            >
              <ClearIcon />
            </Button>
          )}
        </div>

        {countries.length ? (
          <Scrollbar
            autoHide={isMobile}
            className={styles.scrollbar}
            wrapperClassName={styles.scrollbarWrapper}
          >
            <div className={styles.countries}>
              <div className={styles.countryList}>
                {countries.map((country) => (
                  <div
                    key={country.countryId}
                    className={styles.countryItem}
                    onClick={onSelectCountry(country)}
                  >
                    <div className={styles.country}>
                      <div
                        style={{
                          backgroundImage: `url(${country.countryIcon})`,
                        }}
                        className={styles.countryIcon}
                      />
                      <Typography
                        type={
                          isTablet
                            ? TYPOGRAPHY_TYPE.PARAGRAPH1
                            : TYPOGRAPHY_TYPE.PARAGRAPH2
                        }
                        className={styles.countryName}
                      >
                        {country.countryName}
                      </Typography>
                    </div>
                    <Typography
                      type={
                        isTablet
                          ? TYPOGRAPHY_TYPE.HEADLINE4
                          : TYPOGRAPHY_TYPE.HEADLINE5
                      }
                      className={styles.countryCode}
                    >
                      {`+${country.countryCode}`}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </Scrollbar>
        ) : (
          <div className={styles.emptyState}>
            <EmptySearchIcon className={styles.emptyIcon} />
            <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3}>
              <FormattedMessage
                id="modal.login.phone.select.country.content.empty"
                defaultMessage="Oops, no results found"
              />
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default CountrySelect;
