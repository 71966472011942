import React from "react";
import classnames from "classnames";
import { SimpleHeader } from "src/features/ofac/components/SimpleHeader";
import { ComponentWithClassName } from "src/features/ofac/imports/types";
import { useBreakpointPrecise } from "src/features/ofac/imports/ui";
import styles from "./RestrictedLayout.scss";

export const RestrictedLayout: ComponentWithClassName = ({
  children,
  className,
}) => {
  const breakpoint = useBreakpointPrecise();

  return (
    <div className={classnames(className, styles[breakpoint], styles.layout)}>
      <SimpleHeader className={styles.header} />
      {children}
    </div>
  );
};
