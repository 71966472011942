import heic2any from "heic2any";

export const convertToJpeg = (file: File) =>
  new Promise<File>((resolve) => {
    heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.6,
    }).then((imageBlob) => {
      const fileName = `${file.name.split(".").slice(0, -1).join()}.jpeg`;
      const jpegFile = new File([imageBlob as Blob], fileName, {
        type: "image/jpeg",
      });

      resolve(jpegFile);
    });
  });
