import invariant from "fbjs/lib/invariant";

const checkArrayLimits = (array, index) => {
  invariant(
    index >= 0 && index < array.length,
    `Index must be within array ${array.length} ${index}`
  );
  return true;
};

export const getItemByCircularIndex = (list, index) => {
  const listLen = list.length;
  return list[((index % listLen) + listLen) % listLen]; // I'm stupid but I can google https://stackoverflow.com/a/54427125
};

export const insertItemAtIndex = (array, index, item) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

export const replaceAtIndex = (array, targetIndex, item) =>
  checkArrayLimits(array, targetIndex) &&
  array.map((val, index) => (index === targetIndex ? item : val));

export const replaceItem = (array, targetItem, replacement) =>
  array.map((val) => (val === targetItem ? replacement : val));

export const removeAtIndex = (array, index) =>
  checkArrayLimits(array, index) && array.filter((_, x) => x !== index);

export const removeItem = (array, item) => array.filter((x) => x !== item);

export const sort = (array, comparator) => [...array].sort(comparator);

export const reverse = (array) => [...array].reverse();
