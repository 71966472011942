import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { callAF } from "@analytics/appsFlyerWrapper";
import tangoAnalytics from "src/core/analytics";
import { EventFields, StorageKeys } from "src/core/analytics/enums";
import {
  connectionManagerSelectors,
  deviceInfoSelectors,
  hasValidAccount,
  loginSelectors,
  userSelectors,
} from "state/selectors";
import useVipInfo from "ui/hooks/useVipInfo";
import { setUserProperties } from "../emit";

const selector = (state) => ({
  hasValidAccount: hasValidAccount(state),
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  username: connectionManagerSelectors.getUsername(state),
  vipStatus: userSelectors.getVipStatus(state),
  accountId: userSelectors.getMyAccountId(state),
});

const UserPropertiesReporter = () => {
  const {
    isLoggedIn,
    hasValidAccount,
    vipStatus,
    accountId,
    fingerprint,
    username,
  } = useSelector(selector, shallowEqual);
  const { vipLabel } = useVipInfo({
    vipLevel: vipStatus,
  });
  useEffect(() => {
    let properties;
    if (!hasValidAccount) {
      properties = {
        user_id: "",
        device_id: "",
        username: "",
        VipStatus: "",
        guestModeActive: 1,
      };
    } else if (!isLoggedIn) {
      properties = {
        user_id: accountId,
        device_id: fingerprint,
        username,
        VipStatus: vipLabel,
        guestModeActive: 1,
      };
    } else {
      properties = {
        user_id: accountId,
        device_id: fingerprint,
        username,
        VipStatus: vipLabel,
        guestModeActive: 0,
      };
    }
    tangoAnalytics.then((analytics) => {
      analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
        [EventFields.DEVICE_ID]: properties.device_id,
        [EventFields.VIP_STATUS]: properties.VipStatus,
        [EventFields.IS_LOGGED_IN]: !properties.guestModeActive,
        ...(properties.guestModeActive && {
          [EventFields.HASHE]: null,
          [EventFields.HASHP]: null,
        }),
      });
    });
    setUserProperties(properties);
  }, [hasValidAccount, vipStatus, accountId, fingerprint, username]);
  useEffect(() => {
    if (!accountId) {
      return;
    }
    callAF("pba", "setCustomerUserId", accountId);
  }, [accountId]);

  return null;
};

export default UserPropertiesReporter;
