import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AddWelcomeOfferPayload,
  WelcomeOffer,
} from "src/features/offers/premiumEntrance/welcomePremiumOffer/types";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";

export interface WelcomePremiumOfferState {
  welcomeOffer: WelcomeOffer;
}

const initialState: WelcomePremiumOfferState = {
  welcomeOffer: null,
};

const slice = createSlice({
  name: "welcomePremiumOffer",
  initialState,
  reducers: {
    addWelcomeOffer(state, action: PayloadAction<AddWelcomeOfferPayload>) {
      const { offer } = action.payload;
      state.welcomeOffer = offer;
    },
  },
  extraReducers(builder) {
    addUserSessionScopeReducer(builder, () => initialState);
  },
});

export const { addWelcomeOffer } = slice.actions;

export const welcomePremiumOfferReducer = slice.reducer;
