import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "src/api/utils/enhancedFetch";
import { fetchSessionDetails as fetchSessionDetailsApi } from "src/features/signin/exports/api";
import { SessionDetailsState } from "src/state/tree/sessionDetails";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";

export const fetchSessionDetails = createAsyncThunk<
  Data<SessionDetailsState>,
  FetcherMetaV2 | void,
  { rejectValue: string; state: RootState }
>("lwc/sessionDetails/fetchSessionDetails", async (_, api) => {
  try {
    return await fetchSessionDetailsApi();
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const resetSessionDetails = createAction("lwc/sessionDetails/reset");
