import { useEffect, useState } from "react";
import { differenceInMilliseconds } from "date-fns";
import { Nullable } from "src/types/common";
import useInterval from "ui/hooks/useInterval";

const useCountdown = (
  targetDate: Nullable<Date | number | undefined>,
  interval = 1000
) => {
  const msLeft =
    targetDate == null
      ? 0
      : Math.max(differenceInMilliseconds(targetDate, new Date()), 0);
  const [timeLeft, setTimeLeft] = useState(0);
  const updateMsLeft = () => {
    setTimeLeft(msLeft);
  };

  const isOver = msLeft === 0;

  // Set initial time
  useEffect(updateMsLeft, [targetDate, isOver]);

  // Reduce time every interval
  useInterval(updateMsLeft, msLeft > 0 ? interval : null);

  return timeLeft;
};

export default useCountdown;
