import React, { FC } from "react";
import ContentController from "src/features/signin/components/ContentController/ContentController";
import Disclaimer from "src/features/signin/components/Disclaimer/Disclaimer";
import BackButton from "src/features/signin/components/buttons/BackButton";
import { useBackButtonData } from "src/features/signin/hooks";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import { ReactComponent as TangoLogo } from "img/tango-logo-new.svg";
import styles from "src/features/signin/SignUp.scss";

interface SignUpDesktopProps {
  registrationSource: RegistrationSource;
}

const SignUpDesktop: FC<SignUpDesktopProps> = ({ registrationSource }) => {
  const { isBackButtonVisible, navigateBack } = useBackButtonData();

  return (
    <div className={styles.contentWrapper}>
      <TangoLogo className={styles.logo} />

      <div className={styles.signin}>
        {isBackButtonVisible && (
          <BackButton className={styles.back} onClick={navigateBack} />
        )}

        <ContentController registrationSource={registrationSource} />
      </div>

      <Disclaimer className={styles.disclaimer} />
    </div>
  );
};

export default SignUpDesktop;
