import { StreamEvents } from "src/enums";

export const getMessageType = ({ failedToSend, censored }) => {
  if (failedToSend) {
    return StreamEvents.FAILED_TO_SEND;
  }

  if (censored) {
    return StreamEvents.CENSORED_MESSAGE;
  }

  return StreamEvents.MESSAGE;
};
