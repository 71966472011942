import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import { performVisitorCheckIn } from "src/features/signin/api/visitor";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { visitorSelectors } from "state/selectors";
import { VisitorState } from "state/tree/visitor";

export const checkInVisitor = createAsyncThunk<
  VisitorState["data"],
  FetcherMetaV2 | void,
  { rejectValue: string; state: RootState }
>(
  "lwc/visitor/fetch",
  async (_, api) => {
    try {
      const response = await performVisitorCheckIn();

      return {
        ...response,
        isVisitor: true,
      };
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition: (arg, api) => {
      const state = api.getState();
      const meta = visitorSelectors.meta(state);

      return !meta.loading && meta.stale;
    },
  }
);

export const clearVisitorData = createAction("lwc/visitor/clear");

export const markVisitorDataStale = createAction("lwc/visitor/markStale");
