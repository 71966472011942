import { createReducer } from "@reduxjs/toolkit";
import {
  setIsUserPostsListVisible,
  setUserPostsInfo,
} from "src/state/actionCreators/userPosts";
import { Nullable } from "src/types/common";

export interface UserPostsState {
  category: Nullable<string>;
  isUserPostsListVisible: boolean;
  maxLevel: Nullable<number>;
  minLevel: Nullable<number>;
  postId: Nullable<string>;
}

export const initialUserPostsState = {
  category: null,
  isUserPostsListVisible: false,
  maxLevel: null,
  minLevel: null,
  postId: null,
};

export default createReducer<UserPostsState>(
  initialUserPostsState,
  (builder) => {
    builder
      .addCase(setIsUserPostsListVisible, (state, action) => {
        state.isUserPostsListVisible = action.payload;
      })
      .addCase(setUserPostsInfo, (state, action) => ({
        ...state,
        ...action.payload,
      }));
  }
);
