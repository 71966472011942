import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSelectors } from "src/features/signin/imports/state";
import {
  loginSetSelectCountryStep,
  loginWaitingForCode,
  resetPhoneLogin,
} from "src/features/signin/state/login/actionCreators";

export default () => {
  const dispatch = useDispatch();

  const phoneNumberAuthenticationState = useSelector(
    loginSelectors.getPhoneNumberAuthenticationState
  );
  const isLoginFailed = useSelector(loginSelectors.isLoginFailed);
  const isLoginInProgress = useSelector(loginSelectors.isLoginInProgress);

  const {
    selectCountry,
    waitingForCode,
    continueWithPhone,
    phoneVerificationUnavailable,
  } = phoneNumberAuthenticationState;

  const isBackButtonVisible = Boolean(
    Object.keys(phoneNumberAuthenticationState).length &&
      !phoneVerificationUnavailable &&
      !isLoginFailed &&
      !isLoginInProgress
  );

  const navigateBack = useCallback(() => {
    if (selectCountry) {
      dispatch(loginSetSelectCountryStep(false));
    } else if (waitingForCode) {
      dispatch(loginWaitingForCode(false));
    } else if (continueWithPhone) {
      dispatch(resetPhoneLogin());
    }
  }, [dispatch, waitingForCode, selectCountry, continueWithPhone]);

  return {
    isBackButtonVisible,
    navigateBack,
  };
};
