import Analytics, { AnalyticsInstance } from "@tango-analytics/analytics";
import googleTagManager from "@tango-analytics/google-tag-manager";
import { ensureLoadedEnvironment, getGtmId } from "environment";
import dataMesh from "src/core/analytics/plugins/dataMesh";
import {
  BIInLocalStorage,
  BIInSessionStorage,
  StorageAdvanced,
} from "src/core/analytics/storage";

interface AnalyticsType extends AnalyticsInstance {
  Local: StorageAdvanced;
  Session: StorageAdvanced;
}

const analyticsPromise = async () => {
  const containerId = await ensureLoadedEnvironment(getGtmId);
  const analytics = <AnalyticsType>Analytics({
    app: "tango-app",
    plugins: [
      googleTagManager({
        containerId,
      }),
      dataMesh(),
    ],
  });

  analytics.Session = BIInSessionStorage;

  analytics.Local = BIInLocalStorage;

  return analytics;
};

export default analyticsPromise();
