import { parseMessageFromBase64 } from "src/utils/protobufUtil";

import AcmeProto from "generated/proto/Acme";
import { CashierPersonalOfferResponse as Offer } from "generated/proto/CashierPersonalOffer";
import { TransactionStatusMessage } from "generated/proto/TransactionProtos";
import { AcmeServiceName } from "src/enums";

const { OverrideableMessage } = AcmeProto;

export const parseAcme = (decodedAcme, onAcme) => {
  onAcme({
    data: {
      acmeId: decodedAcme.id,
    },
  });

  if (decodedAcme.serviceName === AcmeServiceName.FINANCE) {
    const { serviceName, serviceIdentifier, payload } = parseMessageFromBase64(
      decodedAcme.payload,
      OverrideableMessage
    );

    const data = parseMessageFromBase64(payload, TransactionStatusMessage);
    onAcme({
      serviceName,
      serviceIdentifier,
      data,
    });
    return;
  }

  if (decodedAcme.serviceName === AcmeServiceName.PERSONAL_OFFERS) {
    const { serviceName, serviceIdentifier, payload } = parseMessageFromBase64(
      decodedAcme.payload,
      OverrideableMessage
    );

    const data = parseMessageFromBase64(payload, Offer);
    onAcme({
      serviceName,
      serviceIdentifier,
      data: {
        ...data,
        ts: Date.now(),
      },
    });
  }
};
