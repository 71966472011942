import { compose } from "redux";

import {
  FOLLOW_USER,
  UNFOLLOW_USER,
  FOLLOWING_LIST_BEGIN_FETCH,
  FOLLOWING_LIST_END_FETCH,
} from "state/actionTypes";
import withUserSessionScope from "state/hor/withUserSessionScope";
import withFetcher from "state/hor/withFetcher";
import { uniq, without } from "src/utils/miniLodash";

export const persistConfig = { whitelist: ["accountIds"] };

export const selectors = {
  getIsFollowingByAccountId: (state, accountId) =>
    state.accountIds && state.accountIds.includes(accountId),
  getIsUpToDate: (state) => !state.isStale,
  getIsLoadInProgress: (state) => state.isLoadInProgress,
  getFollowedUserIds: (state) => state.accountIds,
};

const initialData = {
  accountIds: [],
  isStale: true,
};

export default compose(
  withUserSessionScope,
  withFetcher({
    beginFetchActionType: FOLLOWING_LIST_BEGIN_FETCH,
    endFetchActionType: FOLLOWING_LIST_END_FETCH,
    initialData,
    extractData: (list) => ({
      isStale: false,
      accountIds: list || [],
      loadedProfilesPage: 0,
    }),
    mergeData: (_, newData) => newData,
  })
)((state, action) => {
  switch (action.type) {
    case FOLLOW_USER: {
      const {
        payload: { accountId },
      } = action;
      if (state.accountIds.includes(accountId)) {
        return state;
      }
      return {
        ...state,
        accountIds: uniq([...state.accountIds, accountId]),
      };
    }
    case UNFOLLOW_USER: {
      const {
        payload: { accountId },
      } = action;
      if (!state.accountIds.includes(accountId)) {
        return state;
      }
      return {
        ...state,
        accountIds: without(state.accountIds, accountId),
      };
    }
  }
  return state;
});
