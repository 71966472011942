import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { ComponentWithClassName } from "src/features/signin/imports/types";
import {
  TYPOGRAPHY_TYPE,
  Typography,
  linkToPrivacyPolicy,
  linkToTermsOfUse,
} from "src/features/signin/imports/ui";
import styles from "./Disclaimer.scss";

const linkProps = {
  rel: "noreferrer noopener",
  target: "_blank",
};

const Disclaimer: ComponentWithClassName = ({ className }) => (
  <Typography
    type={TYPOGRAPHY_TYPE.PARAGRAPH5}
    as="div"
    className={classnames(styles.disclaimer, className)}
  >
    <FormattedMessage
      id="modal.login.disclaimer.18.years.old"
      values={{
        termsOfUse: (
          <Link to={linkToTermsOfUse} {...linkProps}>
            <FormattedMessage
              id="legalNav.termsOfUse"
              defaultMessage="Terms of Use"
            />
          </Link>
        ),
        privacyPolicy: (
          <Link to={linkToPrivacyPolicy} {...linkProps}>
            <FormattedMessage
              id="legalNav.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </Link>
        ),
      }}
      defaultMessage="By logging in, you confirm you’re over 18 years old and agree to our {termsOfUse} and {privacyPolicy}"
    />
  </Typography>
);

export default Disclaimer;
