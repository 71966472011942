import { createReducer } from "@reduxjs/toolkit";
import { loadNearbyCountries } from "state/actionCreators/nearbyCountries";
import {
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";
import { NearbyCountriesState } from "state/types";

export const persistConfig = {
  whitelist: ["data"],
};

const nearbyCountriesInitialState = {
  data: {},
  meta: initialFetcherStateMeta,
};

const nearby = createReducer<NearbyCountriesState>(
  nearbyCountriesInitialState,
  (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        initialData: nearbyCountriesInitialState.data,
        builder,
        action: loadNearbyCountries,
        prepareData: (_, newMap) => newMap,
      }),
      () => nearbyCountriesInitialState
    );
  }
);
export default nearby;
