import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { HomePageV2NearbyRegion } from "src/features/homePage/v2/common/enums";
import {
  HomePageV2NearbyCountriesParsed,
  HomePageV2NearbyCountriesRegionParsed,
} from "src/features/homePage/v2/common/types";
import { Nullable } from "src/types/common";
import { CountryInfo } from "state/types";

export interface HomePageV2NearbyState {
  error: Nullable<string>;
  lineup: CountryInfo[];
  loading: boolean;
  regions: HomePageV2NearbyCountriesRegionParsed[];
  selectedCountry: string;
  selectedRegion: HomePageV2NearbyRegion;
}

const initialState: HomePageV2NearbyState = {
  error: null,
  lineup: [],
  loading: false,
  regions: [],
  selectedCountry: "",
  selectedRegion: HomePageV2NearbyRegion.ASIA,
};

const nearbySlice = createSlice({
  name: "homePageV2Nearby",
  initialState,
  reducers: {
    loadNearbyCountriesStart(state) {
      state.loading = true;
    },
    loadNearbyCountriesSuccess(
      state,
      action: PayloadAction<HomePageV2NearbyCountriesParsed>
    ) {
      state.lineup = action.payload.lineup;
      state.regions = action.payload.regions;
      state.loading = false;
    },
    loadNearbyCountriesFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    setSelectedNearbyCountry(state, action: PayloadAction<CountryInfo>) {
      state.selectedCountry = action.payload.isoCode;

      if (
        !!action.payload.isoCode &&
        state.lineup.findIndex(
          (item) => item.isoCode === action.payload.isoCode
        ) === -1
      ) {
        state.lineup = [action.payload, ...state.lineup.slice(0, -1)];
      }
    },
    setSelectedNearbyCountryToLineupStart(
      state,
      action: PayloadAction<CountryInfo>
    ) {
      state.selectedCountry = action.payload.isoCode;

      const hasIsoCode = !!action.payload.isoCode;
      const lineUpIndex = state.lineup.findIndex(
        (item) => item.isoCode === action.payload.isoCode
      );

      if (hasIsoCode && lineUpIndex) {
        state.lineup = [
          action.payload,
          ...state.lineup.filter(
            (item) => item.isoCode !== action.payload.isoCode
          ),
        ];
      }
    },
    setSelectedRegion(state, action: PayloadAction<HomePageV2NearbyRegion>) {
      state.selectedRegion = action.payload;
    },
  },
});

export const {
  loadNearbyCountriesStart,
  loadNearbyCountriesSuccess,
  loadNearbyCountriesFailure,
  setSelectedNearbyCountry,
  setSelectedRegion,
  setSelectedNearbyCountryToLineupStart,
} = nearbySlice.actions;

export const homePageV2Nearby = nearbySlice.reducer;
