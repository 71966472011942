import { BI_LANDING_FIELD } from "@analytics/enums";
import { PersonalOffersConfig } from "src/types/personalOffer";
import { omitUndefinedProps } from "src/utils/omitUndefinedProps";

const getOneClickAnalyticsParams = (offer?: PersonalOffersConfig | null) => {
  const params = {
    [BI_LANDING_FIELD.TRIGGER_ID]: offer?.triggerId,
    [BI_LANDING_FIELD.SERVER_OFFER_ID]: offer?.personalOfferId,
    [BI_LANDING_FIELD.PRICE_POINT_ID]: offer?.pricePoints?.[0]?.id,
  };

  return omitUndefinedProps(params);
};

export default getOneClickAnalyticsParams;
