import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import { AcmeMessagesFromServerType } from "src/types/acme";

export const fetchAcmeMessage = (
  acmeIds: string[]
): Promise<AcmeMessagesFromServerType> =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/acme/sync`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Accept: "application/json; charset=UTF-8",
    },
    body: JSON.stringify({
      messageAcks: {
        id: acmeIds,
      },
    }),
  }).then((resp) => resp.json());
