export enum AdditionalAction {
  INSTAGRAM_FLOW = "instagram_flow",
  ONBOARDING_LANDING = "onboarding_landing",
  OPEN_BRING_FRIENDS = "open_bring_friends",
  OPEN_OFFERS = "open_offers",
  OPEN_REGISTRATION = "open_registration",
}

export enum Target {
  AGENT = "agent",
  APP_SETTINGS = "app_settings",
  CASHIER = "cashier",
  CHAT_DETAIL = "chat_detail",
  CONNECTED_ACCOUNTS = "connected_accounts",
  CONVERSATION = "conversations",
  CREATORS = "creators",
  CREATORS_WITH_REGISTRATION = "creatorsWithRegistration",
  EXTERNAL_REFERRAL = "external_referral",
  LANDING_PAGE = "landing_page",
  LATEST = "latest",
  LIVE = "live",
  MOBILE_SETTINGS = "mobile_settings",
  NEARBY = "nearby",
  NEARBY_WITH_REGISTRATION = "nearbyWithRegistration",
  NEWS = "news",
  OBS_STREAM_START = "obs_stream_start",
  PAYMENT_SETTINGS = "payment_settings",
  PRIVACY = "privacy",
  PROFILE = "profile",
  PROFILE_SETTINGS = "profile_settings",
  REFILL = "refill",
  SOCIAL_GAMES = "social_games",
  STORIES = "stories",
  STREAM = "stream",
  SUPPORT = "support",
  SUPPORTER = "supporter",
  TIMELINE = "timeline",
  VIEW_PROFILE = "view_profile",
  VOUCHER = "voucher",
}

export enum TaskType {
  DEEP_LINK_INTENT_EDIT_PROFILE_SETTINGS = "editProfileSettings",
  DEEP_LINK_INTENT_OPEN_BRING_FRIENDS = "openBringFriends",
  DEEP_LINK_INTENT_OPEN_BUY_COINS = "openBuyCoins",
  DEEP_LINK_INTENT_OPEN_CONNECTED_ACCOUNTS = "openConnectedAccounts",
  DEEP_LINK_INTENT_OPEN_MOBILE_SETTINGS = "openMobileSettings",
  DEEP_LINK_INTENT_OPEN_ONBOARDING_LANDING = "openOnboardingLanding",
  DEEP_LINK_INTENT_OPEN_PROFILE = "openProfile",
  DEEP_LINK_OPEN_CREATORS_WITH_REGISTRATION = "openCreatorsWithRegistration",
  DEEP_LINK_OPEN_NEARBY_WITH_REGISTRATION = "openNearbyWithRegistration",
}

export enum DeeplinkQueryField {
  ADDITIONAL_ACTION = "additional_action",
  BANNER_TYPE = "banner_type",
  CAMPAIGN_ID = "campaignId",
  CID = "cid",
  MESSAGE_UUID = "message_uuid",
  PAYMENT_SUMMARY = "payment_summary",
  PROMOTION_TYPE = "promotion_type",
  PROMOTION_VALUE = "promotion_value",
  PROVIDER = "provider",
  REFERRER_ID = "ref_id",
  SHOW_OFFERS = "showOffers",
  SOCIAL_GAME_ID = "socialGameId",
  STORY_ID = "story_id",
  STREAM_ID = "streamId",
  TAB = "tab",
  TARGET = "target",
  TRACKING_ID = "tracking_id",
  UID = "uid",
  USERNAME = "username",
  WEB_VIEW = "webview",
}

export enum Landing {
  MAIN_SCREEN = "main_screen",
  PROFILE = "profile",
  STREAM = "stream",
}

export enum LinkType {
  AGENTS = "agents",
  LIVE = "live",
  PROFILE = "profile",
  STREAM = "stream",
}
