import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  BI_LANDING_FIELD,
  EventFields,
  PERSONAL_OFFER_TYPE,
  PERSONAL_OFFERS_SOURCE,
} from "@analytics/enums";
import { AdditionalParamsObject } from "@analytics/types";
import { Breakpoints, CurrencyPricePoint } from "src/enums";
import {
  WELCOME_ONBOARDING_OFFER_BONUS_MULTIPLIER,
  WELCOME_ONBOARDING_OFFER_COLOR,
} from "src/features/welcomeOnboardingOffer/constants";
import {
  Currency,
  LayerTypes,
} from "src/features/welcomeOnboardingOffer/imports/enums";
import {
  currenciesSelectors,
  getIsUseBasePriceFromOfferCurrentPriceEnabled,
  getWelcomeOfferDiscountPercentage,
} from "src/features/welcomeOnboardingOffer/imports/state";
import {
  Layout,
  LottieConfigWithPath,
  Offer,
  PricePoint,
} from "src/features/welcomeOnboardingOffer/imports/types";
import {
  generateJSONUrl,
  useBreakpointPrecise,
} from "src/features/welcomeOnboardingOffer/imports/ui";
import { getCurrentCurrencyPrice } from "src/features/welcomeOnboardingOffer/imports/utils";
import { welcomeOnboardingOfferSelectors } from "src/features/welcomeOnboardingOffer/state/selectors";
import { RootState } from "state/delegate";

interface WelcomeOnboardingOfferData {
  bonus: number;
  bonusPercentage: string;
  color: string;
  currencyPrice?: CurrencyPricePoint;
  lottieSettings: LottieConfigWithPath;
  offer?: Offer;
  offerPricePoint?: PricePoint;
}
interface WelcomeOnboardingOfferResponse {
  data: WelcomeOnboardingOfferData;
  welcomeOfferAnalytics: AdditionalParamsObject;
}

const lottieConfig = {
  loop: false,
  renderer: "svg",
  autoplay: true,
} as const;

const numberToBonusPercentage = (bonus: number = 0): string => `+${bonus}%`;

const getLayersByResolutionType = (
  resolutionType: string,
  resolutionsData: Layout["resolutions"]
) => {
  const resolution = resolutionsData.find(
    (res) => res.resolutionType === resolutionType
  );

  if (!resolution) {
    return null;
  }

  const lottieLayer =
    resolution?.layers?.find((layer) => layer.type === LayerTypes.LOTTIE) ||
    null;
  const gradientLayer =
    resolution?.layers?.find((layer) => layer.type === LayerTypes.GRADIENT) ||
    null;

  return { lottieLayer, gradientLayer };
};

const getLayersByResolutionOrder = (
  resolutionTypes: string[],
  layout: Layout
) => {
  let lottieLayer = null;
  let gradientLayer = null;

  for (let i = 0; i < resolutionTypes.length; i++) {
    const resolutionType = resolutionTypes[i];
    const layers = getLayersByResolutionType(
      resolutionType,
      layout.resolutions
    );

    if (!lottieLayer) {
      lottieLayer = layers?.lottieLayer;
    }

    if (!gradientLayer) {
      gradientLayer = layers?.gradientLayer;
    }

    if (lottieLayer && gradientLayer) {
      break;
    }
  }

  return { lottieLayer, gradientLayer };
};

const orderLayersMap = {
  [Breakpoints.DESKTOP]: ["WINDOW", "TABLET", "MOBILE"],
  [Breakpoints.TABLET]: ["TABLET", "MOBILE", "WINDOW"],
  [Breakpoints.MOBILE]: ["MOBILE", "TABLET", "WINDOW"],
  [Breakpoints.SMALL_MOBILE]: ["MOBILE", "TABLET", "WINDOW"],
};

const selectors = (state: RootState) => ({
  welcomeOffer:
    welcomeOnboardingOfferSelectors.getWelcomeOnboardingOffer(state),
  currency: currenciesSelectors.getCurrentCurrency(state),
  discountPercentage: getWelcomeOfferDiscountPercentage(state),
  isUseBasePriceFromOfferCurrentPriceEnabled:
    getIsUseBasePriceFromOfferCurrentPriceEnabled(state),
});

export const useWelcomeOffer = (): WelcomeOnboardingOfferResponse => {
  const breakpoint = useBreakpointPrecise();

  const {
    welcomeOffer,
    currency,
    discountPercentage,
    isUseBasePriceFromOfferCurrentPriceEnabled,
  } = useSelector(selectors, shallowEqual);

  const [welcomeOnboardingOfferData, setWelcomeOnboardingOfferData] =
    useState<WelcomeOnboardingOfferData>({
      bonus: WELCOME_ONBOARDING_OFFER_BONUS_MULTIPLIER,
      bonusPercentage: numberToBonusPercentage(
        WELCOME_ONBOARDING_OFFER_BONUS_MULTIPLIER
      ),
      color: WELCOME_ONBOARDING_OFFER_COLOR,
      lottieSettings: { ...lottieConfig, path: "" },
    });

  const [welcomeOfferAnalytics, setWelcomeOfferAnalytics] =
    useState<AdditionalParamsObject>({});

  useEffect(() => {
    const offerPricePoint = welcomeOffer?.pricePoints?.[0];
    const newOffer = offerPricePoint?.offers?.[0];

    if (newOffer) {
      setWelcomeOnboardingOfferData((prevState) => ({
        ...prevState,
        offerPricePoint,
        offer: newOffer,
        bonusPercentage: numberToBonusPercentage(discountPercentage),
        bonus: discountPercentage,
      }));
    }
  }, [
    welcomeOffer?.layout.resolutions,
    welcomeOffer?.pricePoints,
    discountPercentage,
  ]);

  useEffect(() => {
    const { layout } = welcomeOffer || {};

    if (layout?.resolutions) {
      const { lottieLayer, gradientLayer } = getLayersByResolutionOrder(
        orderLayersMap[breakpoint],
        layout
      );

      const path = generateJSONUrl(lottieLayer?.url || "");
      const lottieSettings = { ...lottieConfig, path };
      const color =
        gradientLayer?.colors?.[0] || WELCOME_ONBOARDING_OFFER_COLOR;

      setWelcomeOnboardingOfferData((prevState) => ({
        ...prevState,
        lottieSettings,
        color,
      }));
    }
  }, [breakpoint, welcomeOffer?.layout]);

  useEffect(() => {
    if (welcomeOnboardingOfferData?.offer) {
      const currentCurrencyPrice =
        welcomeOnboardingOfferData.offer?.currencyPrice?.find(
          (currencyPriceItem) => currencyPriceItem.currency === currency
        ) ||
        welcomeOnboardingOfferData.offer?.currencyPrice?.find(
          (currencyPriceItem) => currencyPriceItem.currency === Currency.USD
        );

      if (currentCurrencyPrice) {
        setWelcomeOnboardingOfferData((prevState) => ({
          ...prevState,
          currencyPrice: {
            currency: currentCurrencyPrice.currency as Currency,
            value: getCurrentCurrencyPrice(
              currentCurrencyPrice,
              isUseBasePriceFromOfferCurrentPriceEnabled
            ),
          },
        }));
      }
    }
  }, [currency, welcomeOnboardingOfferData.offer]);

  useEffect(() => {
    const { offer, offerPricePoint } = welcomeOnboardingOfferData;
    if (welcomeOffer && offer && offerPricePoint) {
      const welcomeOfferAnalyticsData = {
        [BI_LANDING_FIELD.TRIGGER_ID]: welcomeOffer.triggerId,
        [BI_LANDING_FIELD.MARKET_OFFER_ID]: offer.marketOfferId,
        [BI_LANDING_FIELD.PRICE_POINT_ID]: offerPricePoint.id,
        [BI_LANDING_FIELD.SERVER_OFFER_ID]: welcomeOffer.personalOfferId,
        [BI_LANDING_FIELD.POSITION]: 0,
        [EventFields.PERSONAL_OFFER_TYPE]:
          PERSONAL_OFFER_TYPE.SPECIAL_PERSONAL_OFFER,
        [BI_LANDING_FIELD.SOURCE]: PERSONAL_OFFERS_SOURCE.LANDING_PAGE,
        [EventFields.CAMPAIGN_ID]: welcomeOffer.campaignId,
      };

      setWelcomeOfferAnalytics(welcomeOfferAnalyticsData);
    }
  }, [welcomeOffer, welcomeOnboardingOfferData]);

  return { data: welcomeOnboardingOfferData, welcomeOfferAnalytics };
};
