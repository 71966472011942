import { fetchStreamByEncryptedAccountIds } from "api/stream";
import { getFollowSuggestionsModerationLevel } from "state/abTests";
import { loadedUserCurrentStream } from "state/actionCreators/profile";

export default (accountId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const moderationLevel = getFollowSuggestionsModerationLevel(state);
    const json = await fetchStreamByEncryptedAccountIds({
      accountIds: [accountId],
      moderationLevel,
    });

    dispatch(loadedUserCurrentStream({ accountId, data: json }));
  } catch (e) {
    // eslint-disable-next-line no-useless-return
    return;
  }
};
