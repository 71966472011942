export const STREAM_LOGGING_STATE_STARTED = "started";
export const STREAM_LOGGING_STATE_STOPPED = "stopped";
export const STREAM_LOGGING_STATE_USER_LEFT = "userLeft";

export const STREAM_LOGGING_EVENT_START = "start";
export const STREAM_LOGGING_EVENT_PAUSE = "pause";
export const STREAM_LOGGING_EVENT_RESUME = "resume";
export const STREAM_LOGGING_EVENT_STOP = "stop";
export const STREAM_LOGGING_EVENT_EXPIRED = "expired";
export const STREAM_LOGGING_EVENT_READY_FOR_DISPLAY = "readyForDisplay";
export const STREAM_LOGGING_EVENT_SHARED_VIEWERS = "sharedViewers";
export const STREAM_LOGGING_EVENT_ERROR = "error";

export const STREAM_LOGGING_EVENTS_WITH_REASON = [
  STREAM_LOGGING_EVENT_PAUSE,
  STREAM_LOGGING_EVENT_RESUME,
  STREAM_LOGGING_EVENT_STOP,
  STREAM_LOGGING_EVENT_ERROR,
];

export const STOP_REASON_KICKED_OUT = "kickedOut";
export const STOP_REASON_TERMINATED = "terminated";
export const STOP_REASON_UPGRADED_TO_PRIVATE = "upgradedToPrivate";
export const STOP_REASON_USER_LEFT = "user_left";

export const PAUSE_RESUME_REASON_BY_USER = "by_user";
export const PAUSE_RESUME_REASON_REMOTE = "remote";
