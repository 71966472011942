import { createAsyncThunk } from "@reduxjs/toolkit";
import { SuspensionStatusState } from "state/types";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { isApiError } from "api/utils/enhancedFetch";
import { RootState } from "state/delegate";
import { loginSelectors, suspensionStatusSelectors } from "state/selectors";
import { fetchMySuspensionStatus } from "api/suspend";

export const getStatus = createAsyncThunk<
  Data<SuspensionStatusState>,
  FetcherMetaV2,
  { state: RootState; rejectValue: string }
>(
  "lwc/suspensionStatus/getStatus",
  async (_, api) => {
    try {
      return await fetchMySuspensionStatus();
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;
      return api.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta = suspensionStatusSelectors.meta(state);
      const isLoggedIn = loginSelectors.isLoggedIn(state);
      return !meta.loading && isLoggedIn;
    },
  }
);
