import { createReducer } from "@reduxjs/toolkit";
import {
  addAsyncCasesToBuilderV2,
  AsyncState,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import { fetchLeaderBoardLanding } from "state/actionCreators/leaderBoardLanding";
import { LeaderBoardLanding } from "src/types/leaderBoard";

const initialState = {
  data: {
    assets: {},
    endAtTsMs: null,
    startAtTsMs: null,
    status: "UPCOMING",
    title: "",
    topParticipantsChart: { sortedParticipantsProgress: [] },
    configuredForTypes: [],
  },
  meta: initialFetcherStateMeta,
};

export type LeaderBoardState = AsyncState<LeaderBoardLanding, string>;

export default createReducer<LeaderBoardState>(initialState, (builder) => {
  addAsyncCasesToBuilderV2({
    builder,
    action: fetchLeaderBoardLanding,
    prepareData: (oldData, newData) => newData,
    initialData: initialState.data,
  });
});
