import React from "react";

/* eslint-disable react/jsx-no-literals, react/jsx-key */
export const tableConfig = {
  privacyPolicy: {
    processingPersonInformation: {
      theadData: ["Purpose", "Legal basis"],
      tbodyData: [
        [
          `
              Provision of our Services; support and customer relations. 
              We use your Personal Information, such as your name and email
              address, for consumer services purposes. This includes, for
              example, managing your account, operating the Services, and responding to your inquiries.
            `,
          `
              The legal bases for processing this data are the performance of
              our contractual obligation towards you (Art. 6.1(b) GDPR); your
              consent (Art. 6.1(a) GDPR); compliance with our legal
              obligations (Art. 6.1ca) GDPR); and our legitimate interests
              (Art. 6.1(f) GDPR). Our legitimate interests in this case are
              enforcing our policies, providing our Services.
            `,
        ],
        [
          `
              Improving our Services.
              We collect and analyze information about you and your usage of
              our Services to improve the usability and effectiveness of our
              Services.
            `,
          `
              The legal bases for processing this data are our legitimate
              interests (Art. 6.1(f) GDPR), in this case – providing and
              improving our Services.
            `,
        ],
        [
          `
              Marketing, advertising and analytics.
              We use your Personal Information in order to provide you with
              personalized advertisements when you visit our Services, to
              market our Services and to gather aggregate usage information
              and analytics.
            `,
          `
              The legal basis for processing this data are your consent (when
              required) and our legitimate interests (Art. 6.1(a), 6.1(f)
              GDPR). Our legitimate interests in this case are providing you
              with tailored services, content and advertisements that better
              correspond with your interests and to promote our Services.
            `,
        ],
        [
          `
              Marketing, advertising and analytics.
              We use your Personal Information in order to provide you with
              personalized advertisements when you visit our Services, to
              market our Services and to gather aggregate usage information
              and analytics.
            `,
          `
              The legal basis for processing this data are your consent (when
              required) and our legitimate interests (Art. 6.1(a), 6.1(f)
              GDPR). Our legitimate interests in this case are providing you
              with tailored services, content and advertisements that better
              correspond with your interests and to promote our Services.
            `,
        ],
        [
          `
              Dispute resolution and protection of our legal claims.
              We collect your Personal Information in order to investigate
              violation of our policies, enable us to resolve disputes in
              connection with your use of the Services and to establish and
              defend our legal claims.
            `,
          `
              The legal basis for processing this data is our legitimate
              interests (Art. 6.1(f) GDPR). Our legitimate interests in this
              case are to establish and defend our legal claims.
            `,
        ],
        [
          `
              Corporate transactions.
              We may share your Personal Information with a potential
              purchasers, successors or investors in the Company or in the
              event of a corporate transaction (e.g. sale of a substantial
              part of our business, merger, reorganization, bankruptcy,
              consolidation or asset sale of an asset or transfer in the
              operation thereof) in relation to the Company.
            `,
          `
              The legal basis for processing this data is our legitimate
              interests (Art. 6.1(f) GDPR), in expanding and building our
              business.
            `,
        ],
        [
          `
              Prevention of fraud.
              We may process your Personal Information to detect and prevent
              fraudulent and illegal activity or any other type of activity
              that may jeopardize or negatively affect the integrity of the
              Services, including by identifying risks associated with your
              activity on our Services.
            `,
          `
              The legal basis for processing this data is our legitimate
              interests (Art. 6.1(f) GDPR). Our legitimate interests in this
              case are to protect our Company and customer against fraud.
            `,
        ],
        [
          `
              Compliance with applicable laws.
              We process your Personal Information to comply with our various
              legal obligations, anti-money laundering, identity verification,
              prevention of fraud complying with data subject rights etc.
            `,
          `
              The legal basis for processing this data is to comply with our
              various legal obligations and our legitimate interests (Art.
              6.1(f) GDPR).
            `,
        ],
      ],
    },
    personalInformationCategories: {
      theadData: [
        "Category of Personal Information Collected",
        "Personal Information Collected",
        "Categories of recipients to whom Personal Information was disclosed",
      ],
      tbodyData: [
        [
          "Identifiers",
          `
              Full name, email address, social media identifiers, username,
              birthdate, IP address, MAC, UDID
            `,
          <span>
            Affiliated companies <br /> Advertising networks <br /> KYC Service
            providers
          </span>,
        ],
        [
          `
              Personal Information Categories listed in the California
              Customer Records Statute (Cal. Civ. Code § 1798.80(e))
            `,
          `
              Telephone number, debit or credit card number, passport or other
              government or state ID card number
            `,
          <span>
            Affiliated companies <br /> Payment processors <br /> Other service
            providers
          </span>,
        ],
        [
          "Internet or Other Electronic Network Activity Information",
          `
              Browsing history, search history, information on a consumer’s
              interaction with a website, application, or advertisement.
            `,
          <span>
            Affiliated companies <br /> Advertising networks <br /> Other
            service providers
          </span>,
        ],
        [
          "Geolocation Data",
          "Geolocation",
          <span>
            Affiliated companies <br /> Advertising networks <br /> Other
            service providers
          </span>,
        ],
      ],
    },
  },
};
