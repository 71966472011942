import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";

export const fetchCurrenciesConfig = (): Promise<{
  all: string[];
  recommended: string;
}> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/purchases/v1/currencyConfig`
  ).then((resp) => resp.json());
