import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CHAT_OLDER_MESSAGES_PAGE_SIZE } from "chat/constants";
import { Direction } from "chat/enums";
import { linkToChat } from "chat/imports/constants";
import { usePrevious } from "chat/imports/hooks";
import { RootState } from "chat/imports/state";
import { readMessageRequest } from "chat/messageRequest/state/asyncAction";
import { fetchConversation, readMessages } from "chat/state/actionCreators";
import chatSelectors from "chat/state/selectors";
import { ConversationState } from "chat/types";

export default (conversationId: string | undefined) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const conversation = useSelector(
    useCallback(
      (state: RootState) =>
        conversationId
          ? chatSelectors.getConversation(state, conversationId)
          : null,
      [conversationId]
    ),
    shallowEqual
  );

  const [lastMessageTimestamp, setLastMessageTimestamp] = useState(0);

  const prevConversation = usePrevious(conversation);
  const prevConversationId = usePrevious(conversationId);

  const isMessageRequest =
    conversation?.state === ConversationState.CHAT_REQUEST;

  useLayoutEffect(() => {
    // redirects only when user tries to open restricted chat
    if (
      conversationId != null &&
      conversationId === prevConversationId &&
      !conversation &&
      prevConversation != null
    ) {
      history.replace(linkToChat);
    }
  }, [
    conversation,
    prevConversation,
    history,
    conversationId,
    prevConversationId,
  ]);

  useEffect(() => {
    if (!conversationId) {
      return;
    }

    dispatch(
      fetchConversation({
        conversationId,
        direction: Direction.FORWARD,
        start_timestamp: 0,
        include_group_info: true,
        include_group_members: true,
        limit: CHAT_OLDER_MESSAGES_PAGE_SIZE,
      })
    );
  }, [conversationId, dispatch]);

  useEffect(() => {
    if (!conversation) {
      return;
    }

    const { isLoading, last_message_ts = 0, hidden } = conversation;

    const lastReadMessageTimestamp = last_message_ts || Date.now();

    if (
      !isLoading &&
      conversationId &&
      lastMessageTimestamp < last_message_ts
    ) {
      dispatch(
        readMessages({
          conversation_id: conversationId,
          last_read_message_ts: lastReadMessageTimestamp,
          notify_sender: !hidden,
        })
      );

      if (isMessageRequest) {
        dispatch(
          readMessageRequest({
            conversation_id: conversationId,
            last_read_message_ts: lastReadMessageTimestamp,
            notify_sender: !hidden,
          })
        );
      }
      setLastMessageTimestamp(lastReadMessageTimestamp);
    }
  }, [
    conversation,
    conversationId,
    dispatch,
    lastMessageTimestamp,
    isMessageRequest,
  ]);

  useEffect(() => {
    setLastMessageTimestamp(0);
  }, [conversationId, isMessageRequest]);
};
