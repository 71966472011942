import { createReducer } from "@reduxjs/toolkit";
import { Subscription } from "api/subscriptionToStreamerList";
import { ContentType } from "chat/premiumMessage/exports/types";
import { AFTER_BUY_OPERATIONS } from "src/enums";
import actions from "state/actionCreators/afterPurchaseTaskManager";
import { ShopOffer } from "ui/scenes/landingPage/types";

interface AuctionBidTask {
  amount: number;
  auctionId: string;
  lotId: string;
  min: number;
  screenName: string;
  screenState: string;
  source: string;
  target: string;
  type: AFTER_BUY_OPERATIONS.AUCTION_BID;
}

interface BecomeAFanTask {
  offer: ShopOffer;
  streamerId: string;
  triggerId?: string;
  type: AFTER_BUY_OPERATIONS.SUBSCRIPTION;
}

interface UpgradeFanTask {
  details: Subscription["details"];
  offer: ShopOffer;
  streamerId: string;
  subscriptionId: string;
  triggerId?: string;
  type: AFTER_BUY_OPERATIONS.SUBSCRIPTION_UPGRADE;
}

interface RenewSubscription {
  isSubWithCredits: boolean;
  streamerId: string;
  subscriptionId: string;
  type: AFTER_BUY_OPERATIONS.RENEW_SUBSCRIPTION;
}

interface UnlockPremiumMessage {
  contentType: ContentType;
  conversationId: string;
  giftId: string;
  messageId: string;
  recipientId: string;
  type: AFTER_BUY_OPERATIONS.UNLOCK_PREMIUM_MESSAGE;
}

export type AfterPurchaseTask =
  | AuctionBidTask
  | BecomeAFanTask
  | RenewSubscription
  | UnlockPremiumMessage
  | UpgradeFanTask;

export interface AfterPurchaseTaskManagerState {
  queue: AfterPurchaseTask[];
}

export const initialState = {
  queue: [],
};

export const selectors = {
  getItemForSend: (state: AfterPurchaseTaskManagerState) => state.queue[0],
};

export default createReducer<AfterPurchaseTaskManagerState>(
  initialState,
  (builder) => {
    builder
      .addCase(actions.processFirstQueueItem, (state) => {
        state.queue = state.queue.slice(1);
      })
      .addCase(actions.setItemAfterCoinsBuy, (state, action) => {
        state.queue.push(action.payload);
      });
  }
);
