import { typedDelegateSelectors } from "chat/imports/state";
import { ContentSharedPayloadMessage } from "chat/premiumMessage/types";
import { PremiumMessageState } from "./slice";

export const localPremiumMessageSelectors = {
  getPending: (
    state: PremiumMessageState,
    messageId: ContentSharedPayloadMessage["messageId"]
  ) => state.pending[messageId],
  getDetails: (state: PremiumMessageState) => state.details,
  getDetail: (
    state: PremiumMessageState,
    messageId: ContentSharedPayloadMessage["messageId"]
  ) => state.details[messageId],
};

export const premiumMessageSelectors = typedDelegateSelectors(
  localPremiumMessageSelectors,
  "premiumMessage"
);
