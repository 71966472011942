import { fetchUserPreferences, updateUserPreferences } from "api/preferences";
import { ONE_CLICK_GIFTING } from "src/constants";
import { actionCreators as settingsActionCreators } from "state/tree/settings";
import { ENABLED } from "ui/scenes/settings/contents/app/SettingsEnum";

export const loadSettings =
  (isOneClickGiftingEnabledByDefault) => async (dispatch) => {
    dispatch(settingsActionCreators.fetchBegan());

    try {
      const json = await fetchUserPreferences();

      const hasToEnableByDefault =
        isOneClickGiftingEnabledByDefault &&
        json[ONE_CLICK_GIFTING] === undefined;

      if (hasToEnableByDefault) {
        json[ONE_CLICK_GIFTING] = ENABLED;
      }

      dispatch(
        settingsActionCreators.fetchCompleted({ data: json, replaceAll: false })
      );
    } catch (e) {
      dispatch(
        settingsActionCreators.fetchFailed({ error: e, removeAll: false })
      );
    }
  };

export const saveSettings = (map) => async (dispatch) => {
  dispatch(settingsActionCreators.beginSave(map));
  try {
    await updateUserPreferences(map);
    dispatch(settingsActionCreators.endSave(map));
  } catch (e) {
    dispatch(settingsActionCreators.failedToSave(e, map));
  }
};
