import { getProxycadorHost } from "environment";
import fetch from "./utils/enhancedFetch";

export const fetchUserPreferences = () =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/preferences/get`).then(
    (resp) => resp.json()
  );

export const updateUserPreferences = (payload) =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/preferences/set`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  });
