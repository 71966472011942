import { TANGO_ACCOUNT_ID } from "chat/imports/constants";
import { getParsedExternalMessagePayload } from "chat/imports/utils";
import { StoredMessage } from "chat/state";

export const getExternalMessageStyles = (
  styles: Record<string, string>,
  message: StoredMessage
) => {
  const isMessageFromTango = message.from === TANGO_ACCOUNT_ID;
  if (!isMessageFromTango) {
    return {};
  }

  const parsedPayload = getParsedExternalMessagePayload(
    message.sdk_message_payload?.payload
  );

  if (!parsedPayload || !message.sdk_message_payload) {
    return {};
  }

  const { messageText, actionInfo, previewThumbnailUrl } = parsedPayload;

  return {
    [styles.externalMessage]: true,
    [styles.externalMessageWithText]: messageText,
    [styles.externalMessageWithActionInfo]: actionInfo && actionInfo.length > 0,
    [styles.externalMessageWithThumbnail]: previewThumbnailUrl,
  };
};
