import { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getChatPollPeriodMs,
  getIsChatDisabled,
} from "chat/imports/environment";
import { usePageVisibility } from "chat/imports/hooks";
import { RootState, connectionManagerSelectors } from "chat/imports/state";
import { refreshConversations } from "chat/state/flows";
import chatSelectors from "chat/state/selectors";

const selector = (state: RootState) => ({
  meta: chatSelectors.meta(state),
  isAcmeWorking: connectionManagerSelectors.isAcmeWorking(state),
});

const usePollConversations = () => {
  const dispatch = useDispatch();
  const isPageVisible = usePageVisibility({ considerFocus: false });
  const isMountRefreshSkipped = useRef<boolean>(false);
  const {
    meta: { loading },
    isAcmeWorking,
  } = useSelector(selector, shallowEqual);

  useEffect(() => {
    if (!isMountRefreshSkipped.current) {
      isMountRefreshSkipped.current = true;

      return;
    }
    if (isAcmeWorking || !isPageVisible || getIsChatDisabled()) {
      return;
    }
    const pollPeriod = getChatPollPeriodMs();
    if (pollPeriod < 1) {
      return;
    }

    if (!loading) {
      // refresh on reentering page
      dispatch(refreshConversations());
    }
    const intervalHandle = setInterval(() => {
      dispatch(refreshConversations());
    }, pollPeriod);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [isAcmeWorking, isPageVisible]);
};

export default usePollConversations;
