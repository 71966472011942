import {
  fetchGiftsBalance,
  fetchTotalCredits,
  fetchUserBalance,
} from "api/gifts";
import { batch } from "react-redux";
import { loginSelectors, userSelectors } from "state/selectors";
import {
  willFetchUserBalance,
  fetchedUserBalance,
  failedToFetchUserBalance,
  fetchedTotalCredits,
  willFetchGiftsBalance,
  failedToFetchGiftsBalance,
  fetchedGiftsBalance,
} from "state/actionCreators/balance";
import { awaitForAcme } from "state/middleware/acmeObserver";
import { genericNetworkError } from "state/actionCreators/networkError";

const loadBalances =
  (force = false) =>
  async (dispatch, getState) => {
    const state = getState();
    if (!loginSelectors.isLoggedIn(state)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    }
    if (
      !force &&
      ((userSelectors.isCoinsBalanceUpToDate(state) &&
        userSelectors.isPointsBalanceUpToDate(state)) ||
        userSelectors.isFetchingBalance(state))
    ) {
      return;
    }
    const accountId = userSelectors.getMyAccountId(state);
    batch(() => {
      dispatch(willFetchUserBalance());
      dispatch(willFetchGiftsBalance());
    });

    return Promise.all(
      [fetchUserBalance(), fetchGiftsBalance()].map((p) =>
        p
          .then((res) => ({ success: true, value: res }))
          .catch((e) => ({ success: false, value: e }))
      )
    ).then(([userBalanceRes, giftsBalanceRes]) => {
      if (userBalanceRes.success) {
        const {
          userBalance: {
            credits,
            points,
            vipInfo: { level: vipStatus = null, coinsLeft, vipLevelInfo } = {},
          },
        } = userBalanceRes.value;
        dispatch(
          fetchedUserBalance({
            credits,
            points,
            vipStatus,
            accountId,
            coinsLeft,
            vipLevelInfo,
          })
        );
      } else {
        dispatch(failedToFetchUserBalance(userBalanceRes.value));
      }

      if (giftsBalanceRes.success) {
        dispatch(fetchedGiftsBalance(giftsBalanceRes.value));
      } else {
        dispatch(failedToFetchGiftsBalance(giftsBalanceRes.value));
      }
    });
  };

export const loadBalancesIfNoAcmeWithinTimeout =
  (timeoutMillis) => async (dispatch) => {
    try {
      await awaitForAcme(
        { serviceName: "gift", serviceIdentifier: "syncCredit" },
        timeoutMillis
      );
    } catch (e) {
      return dispatch(loadBalances(true));
    }
  };

export default loadBalances;

export const refreshCoinsTotal = () => async (dispatch, getState) => {
  if (userSelectors.isCoinsTotalBalanceUpToDate(getState())) {
    return;
  }
  try {
    const totalCredits = await fetchTotalCredits();
    dispatch(fetchedTotalCredits(totalCredits));
  } catch (error) {
    dispatch(genericNetworkError(error));
  }
};
