import {
  CUSTOM_DRAWER_BEGIN_FETCH,
  CUSTOM_DRAWER_END_FETCH,
} from "state/actionTypes";
import withFetcher, {
  createFetcherActions,
  fetcherSelectors,
} from "state/hor/withFetcher";

export const persistConfig = {
  blacklist: ["isLoadFailed", "isLoadInProgress"],
};

export const actionCreators = {
  ...createFetcherActions({
    beginFetchActionType: CUSTOM_DRAWER_BEGIN_FETCH,
    endFetchActionType: CUSTOM_DRAWER_END_FETCH,
  }),
};

export const selectors = {
  ...fetcherSelectors,
  getCategoryById: (state, categoryId) =>
    state.categories?.find((cat) => cat.id === categoryId),
  getCategories: (state) => state.categories,
  getDrawerId: (state) => state.id,
  getDrawerVersion: (state) => state.drawerVersion,
};

export default withFetcher({
  beginFetchActionType: CUSTOM_DRAWER_BEGIN_FETCH,
  endFetchActionType: CUSTOM_DRAWER_END_FETCH,
  initialData: {
    categories: null,
    drawerVersion: "",
    id: "",
  },
  extractData: (data) => data,
  mergeData: (oldData, newData) => ({ ...oldData, ...newData }),
})((state) => state);
