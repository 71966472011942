import { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LANDING_PLACE } from "@analytics/enums";
import { ModalType } from "src/enums";
import {
  getIsLandingPageOpenAsModalEnabled,
  getIsLandingPageV3Enabled,
  getIsSkipCashierEnabled,
  getIsSkipRefillEnabled,
} from "src/state/abTests";
import { BannerType } from "src/types/banner";
import { addOpenFromWebParam } from "src/utils/locationUtils";
import landingPagePhoenixConfigActions from "state/actionCreators/landingPagePhoenix";
import { openLandingPageModalHandler } from "state/actionCreators/modal";
import { RootState } from "state/delegate";
import { bannerConfigsSelectors, modalSelectors } from "state/selectors";
import { Target } from "ui/navigation/deepLink/types";
import useDeepLinkActions from "ui/navigation/deepLink/useDeepLinkActions";

interface RedirectFromCashierToLandingParams {
  onDismiss?: () => void;
  place?: LANDING_PLACE;
  withBottomScreen?: boolean;
}

const selector = (state: RootState) => ({
  shouldSkipRefillEnabled: getIsSkipRefillEnabled(state),
  shouldSkipCashierEnabled: getIsSkipCashierEnabled(state),
  isLandingPageOpenAsModalEnabled: getIsLandingPageOpenAsModalEnabled(state),
  isLandingPageV3Enabled: getIsLandingPageV3Enabled(state),
  isCashierOpen: modalSelectors.isModalInStack(state, ModalType.CASHIER_MODAL),
});

const useRedirectFromCashierToLanding = (
  type: BannerType = BannerType.CASHIER_NEW,
  place?: LANDING_PLACE
) => {
  const dispatch = useDispatch();
  const {
    shouldSkipRefillEnabled,
    shouldSkipCashierEnabled,
    isLandingPageOpenAsModalEnabled,
    isCashierOpen,
  } = useSelector(selector);
  const isLandingPageV3Enabled = useSelector(getIsLandingPageV3Enabled);
  const bannerConfigs = useSelector(bannerConfigsSelectors.data, shallowEqual)[
    type
  ];

  const { extractDeepLinkAction } = useDeepLinkActions();

  const isSkipRefillCashierEnabled =
    type === BannerType.REFILL
      ? shouldSkipRefillEnabled
      : shouldSkipCashierEnabled;

  const bannerWithSkipCashier = useMemo(() => {
    if (
      !isSkipRefillCashierEnabled ||
      !place ||
      !bannerConfigs ||
      isCashierOpen
    ) {
      return null;
    }

    return bannerConfigs.find((banner) => {
      const { target, showOffers } = extractDeepLinkAction(
        new URL(banner.action.link).toString()
      );

      return target === Target.LANDING_PAGE && showOffers === "true";
    });
  }, [
    bannerConfigs,
    extractDeepLinkAction,
    isCashierOpen,
    isSkipRefillCashierEnabled,
    place,
  ]);

  const redirectToLanding = useCallback(
    (params: RedirectFromCashierToLandingParams) => {
      if (bannerWithSkipCashier) {
        const bannerAction = extractDeepLinkAction(
          addOpenFromWebParam(
            new URL(bannerWithSkipCashier.action.link)
          ).toString()
        );
        if (bannerAction?.campaignId !== null) {
          dispatch(
            landingPagePhoenixConfigActions.setCampaignId(
              bannerAction?.campaignId
            )
          );
        }

        dispatch(
          // TODO: remove @ts-ignore when move modal.js to typescript https://tango-me.atlassian.net/browse/WEB-4861
          // @ts-ignore
          openLandingPageModalHandler({
            ...params,
            withBottomScreen:
              isLandingPageOpenAsModalEnabled && !isLandingPageV3Enabled
                ? false
                : params.withBottomScreen,
            trackingId: bannerWithSkipCashier?.trackingId,
            isLandingPageV3Enabled,
          })
        );
      }
    },
    [
      dispatch,
      extractDeepLinkAction,
      bannerWithSkipCashier,
      isLandingPageOpenAsModalEnabled,
    ]
  );

  return {
    bannerWithSkipCashier,
    redirectToLanding,
  };
};

export default useRedirectFromCashierToLanding;
