import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { getChatConnectionMinGiftPrice } from "chat/imports/state";
import { useBreakpointPrecise } from "chat/messageRequest/imports/hooks";
import {
  TYPOGRAPHY_TYPE,
  Typography,
} from "chat/messageRequest/imports/uiCommon";
import styles from "./DisclaimerForSender.scss";

export const DisclaimerForSender = memo(() => {
  const minGiftPrice = useSelector(getChatConnectionMinGiftPrice);
  const breakpoint = useBreakpointPrecise();

  return (
    <Typography
      type={TYPOGRAPHY_TYPE.PARAGRAPH5}
      className={classnames(
        styles.chatRequestDisclaimerForSender,
        styles[breakpoint]
      )}
      data-testid="chat-request-disclaimer-for-sender"
    >
      <FormattedMessage
        id="chat.request.disclaimer"
        defaultMessage="Your chat was sent as Message Request. Send a {value}-Coin gift for your message to stand out."
        values={{
          value: minGiftPrice,
        }}
      />
    </Typography>
  );
});

DisclaimerForSender.displayName = "ChatRequestDisclaimerForSender";
