import { createSlice } from "@reduxjs/toolkit";
import {
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "src/features/feed/imports/state";
import { fetchFeedPost } from "src/features/feed/state/feedPost/actions";
import {
  FeedInnerSliceName,
  FeedPostState,
} from "src/features/feed/state/types";

const feedPostInitialState: FeedPostState = {
  data: null,
  meta: initialFetcherStateMeta,
};

const feedPostSlice = createSlice({
  name: FeedInnerSliceName.POST,
  initialState: feedPostInitialState,
  reducers: {},
  extraReducers: (builder) => {
    addAsyncCasesToBuilderV2({
      action: fetchFeedPost,
      builder,
      initialData: feedPostInitialState.data,
      prepareData: (_, payload) => payload,
    });
  },
});

export const feedPostReducer = feedPostSlice.reducer;
