import {
  AutoLoginResult,
  AutoLoginState,
} from "src/features/autoLogin/state/types";

export const autoLoginSelectors = {
  getIsAutoLoginSession: ({ isAutoLoginSession }: AutoLoginState) =>
    isAutoLoginSession,
  getIsResultFailure: ({ result }: AutoLoginState) =>
    result === AutoLoginResult.FAILURE,
  getIsResultSuccess: ({ result }: AutoLoginState) =>
    result === AutoLoginResult.SUCCESS,
};
