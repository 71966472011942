import { createSlice } from "@reduxjs/toolkit";
import { BroadcastExternalError } from "src/features/broadcastExternal/common/enums";
import { BroadcastExternalState } from "src/features/broadcastExternal/common/types";
import {
  getValidStreamKey,
  resetBroadcastExternalStatus,
  setBroadcastExternalStatus,
  setOBSInstructionVisibility,
  startMultiBroadcastExternal,
  stopMultiBroadcastExternal,
} from "src/features/broadcastExternal/state/actionCreators";

const defaultStreamKeyData = {
  accountId: null,
  token: "",
  duration: 0,
};

const initialState: BroadcastExternalState = {
  streamKey: {
    data: defaultStreamKeyData,
    loading: false,
    error: null,
  },
  status: null,
  isMultiBroadcastStarted: false,
  isOBSInstructionVisible: false,
};

const slice = createSlice({
  name: "broadcastExternal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getValidStreamKey.pending, (state) => {
        state.streamKey.loading = true;
      })
      .addCase(getValidStreamKey.fulfilled, (state, action) => {
        state.streamKey.loading = false;
        state.streamKey.error = null;
        state.streamKey.data = action.payload;
      })
      .addCase(getValidStreamKey.rejected, (state, action) => {
        state.streamKey.loading = false;
        state.streamKey.error =
          action.payload?.message ??
          BroadcastExternalError.STREAM_KEY_ERROR_UNKNOWN;
        state.streamKey.data = defaultStreamKeyData;
      })
      .addCase(setBroadcastExternalStatus, (state, action) => {
        state.status = action.payload;
      })
      .addCase(startMultiBroadcastExternal, (state) => {
        state.isMultiBroadcastStarted = true;
      })
      .addCase(stopMultiBroadcastExternal, (state) => {
        state.isMultiBroadcastStarted = false;
      })
      .addCase(setOBSInstructionVisibility, (state, action) => {
        state.isOBSInstructionVisible = action.payload;
      })
      .addCase(resetBroadcastExternalStatus, (state) => {
        state.status = null;
      });
  },
});

export const broadcastExternalPersistConfig = {
  whitelist: ["streamKey"],
};

export const broadcastExternalReducer = slice.reducer;
