import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { Battle, Tournament, fetchTournament } from "api/tournamentsLanding";
import { isApiError } from "api/utils/enhancedFetch";
import { TournamentEventType, TournamentsLanding } from "src/types/tournament";
import { uniq } from "src/utils/miniLodash";
import { fetchAccountToStreamMap } from "state/actionCreators/userToStream";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { tournamentsLandingSelectors } from "state/selectors";
import { StatusTournament } from "ui/scenes/landingPage/Tournaments/enums";

const tournamentDateFormatter = (timestamp: number) =>
  format(new Date(timestamp), "yyyy-MM-dd'T'HH:mm");

const STATUS_MAP: Record<Battle["status"], StatusTournament> = {
  ACTIVE: StatusTournament.LIVE,
  CREATED: StatusTournament.PENDING,
  FINISHED: StatusTournament.PASSED,
  DRAFT: StatusTournament.PENDING,
  SCHEDULED: StatusTournament.PENDING,
};

const SECONDS_IN_MINUTE = 60;

const tournamentTransform = (newData: Tournament) => ({
  startDate: tournamentDateFormatter(newData.startTsMs),
  timeOfRound: newData.battleDurationSec / SECONDS_IN_MINUTE,
  rounds: newData.rounds.map((round, index) => ({
    number: index + 1,
    countEvents: round.battles.length,
  })),
  assets: newData.assets,
  events: newData.rounds.reduce(
    (acc, round, index) => ({
      ...acc,
      [index + 1]: round.battles.map((battle) => {
        const date = battle.startTsMs
          ? tournamentDateFormatter(battle.startTsMs)
          : "";

        return {
          date,
          name: "",
          id: battle.id,
          roundId: round.id,
          players: battle.players.map((player) => ({
            encryptedId: player.accountId,
            familyName: "",
            user: "",
            isWinner: battle?.result?.winnerAccountId === player.accountId,
            streamId: player.streamId,
          })),
          status: STATUS_MAP[battle.status],
          timeOfBattle:
            (battle.battleDurationSec ?? newData.battleDurationSec) /
            SECONDS_IN_MINUTE,
        };
      }),
    }),
    {} as TournamentEventType
  ),
});

const getTournamentParticipants = (tournament: Tournament): string[] => {
  const accounts: string[] = [];

  tournament.rounds.forEach((round) => {
    round.battles.forEach((battle) => {
      battle.players.forEach((player) => {
        player.accountId && accounts.push(player.accountId);
      });
    });
  });

  return uniq(accounts);
};

export const fetchTournamentsLanding = createAsyncThunk<
  TournamentsLanding,
  { tournamentName?: string } & FetcherMetaV2,
  { rejectValue: string; state: RootState }
>(
  "lwc/tournamentsLanding/fetch",
  async ({ tournamentName }, thunkAPI) => {
    try {
      const currentTournament = await fetchTournament(tournamentName);

      if (!currentTournament) {
        throw new Error("Tournament not found!");
      }

      thunkAPI.dispatch(
        fetchAccountToStreamMap({
          accountIds: getTournamentParticipants(currentTournament),
        })
      );

      return tournamentTransform(currentTournament);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return thunkAPI.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta = tournamentsLandingSelectors.meta(state);

      return !meta.loading;
    },
  }
);
