import React, { Suspense, memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import lazy from "src/utils/lazyWithPageReload";
import { bottomScreenSelectors } from "state/selectors";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";

const BottomScreenController = lazy(
  () =>
    import(/* webpackChunkName: "bottomScreen" */ "./BottomScreenController")
);

const LazyBottomScreenController = () => {
  const bottomScreenLazyLoaded = useRef(false);
  const screenType = useSelector(bottomScreenSelectors.screenType);
  const isMobileLayout = useBreakpointMobileLayout();

  useEffect(() => {
    if (!bottomScreenLazyLoaded.current && !!screenType) {
      bottomScreenLazyLoaded.current = true;
    }
  }, [screenType]);

  return (
    isMobileLayout && (
      <Suspense fallback={null}>
        {(!!screenType || bottomScreenLazyLoaded.current) && (
          <BottomScreenController />
        )}
      </Suspense>
    )
  );
};

export default memo(LazyBottomScreenController);
