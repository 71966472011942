import { Breakpoints } from "src/enums";

export enum LayerTypes {
  COLOR = "COLOR",
  GRADIENT = "GRADIENT",
  IMAGE = "IMAGE",
  LOTTIE = "LOTTIE",
}

export const ResolutionTypes = {
  [Breakpoints.DESKTOP]: "WINDOW",
  [Breakpoints.TABLET]: "TABLET",
  [Breakpoints.MOBILE]: "MOBILE",
  [Breakpoints.SMALL_MOBILE]: "MOBILE",
  DEFAULT: "DEFAULT",
};
