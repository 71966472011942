import { getLiveTabByNameEnabling } from "environment";
import { LiveFeedType } from "src/enums";
import { RootState } from "state/delegate";
import { tabsConfigsSelectors } from "state/selectors";
import { TabConfig } from "state/types";
import { hardcodedTabConfigs } from "ui/scenes/live/shared/radioChipsTabDataConstant";

const exploreTabsSelector = (state: RootState): TabConfig[] => {
  const tabs = tabsConfigsSelectors.data(state);
  const isLandscapeEnabled = getLiveTabByNameEnabling(LiveFeedType.LANDSCAPE);

  if (!isLandscapeEnabled) {
    return tabs;
  }

  return [...tabs, hardcodedTabConfigs[LiveFeedType.LANDSCAPE]];
};

const exploreTags = (state: RootState) =>
  exploreTabsSelector(state).map((tab) => tab.tag);

export default {
  tags: exploreTags,
  tabs: exploreTabsSelector,
  meta: tabsConfigsSelectors.meta,
};
