import { FetcherStateMeta } from "src/features/feed/imports/state";
import { Nullable } from "src/features/feed/imports/types";
import { Post } from "src/features/feed/types/post";

export enum FeedInnerSliceName {
  POST = "feed/post",
}

export interface FeedPostState {
  data: Nullable<Post>;
  meta: FetcherStateMeta<FeedPostErrorMessage>;
}

export enum FeedPostErrorMessage {
  FANS_ONLY_NOT_SUPPORTED = "Fans only post not supported",
  NOT_FOUND = "Post not found",
  SOMETHING_WENT_WRONG = "Something went wrong",
  TYPE_NOT_SUPPORTED = "Post type not supported",
}

export interface FeedState {
  post: FeedPostState;
}
