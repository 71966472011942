import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import LoginProviders from "src/features/signin/bottomScreen/components/LoginProviders";
import useGetLoginProviders from "src/features/signin/hooks/useGetLoginProviders";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import { Breakpoints, LoginProvider } from "src/features/signin/imports/enums";
import {
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
  useBreakpoint,
} from "src/features/signin/imports/ui";
import LoginModalDisclaimer from "src/features/signin/modal/LoginModalDisclaimer";
import { providerToComponentMap } from "src/features/signin/modal/enums";
import { ReactComponent as WarningIcon } from "img/emptyState/warning_64.svg";
import styles from "./LoginUnavailable.scss";

interface LoginUnavailableProps {
  registrationSource: RegistrationSource;
}

const LoginUnavailable: FC<LoginUnavailableProps> = ({
  registrationSource,
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const { otherProviders } = useGetLoginProviders();

  return (
    <>
      <div className={classnames(styles.head, styles[breakpoint])}>
        <WarningIcon className={styles.icon} />

        <Typography className={styles.error} type={TYPOGRAPHY_TYPE.HEADLINE3}>
          <FormattedMessage
            id="modal.login.loginUnavailable.error"
            defaultMessage="Registration by phone is currently unavailable"
          />
        </Typography>
        <Typography
          className={styles.description}
          type={TYPOGRAPHY_TYPE.PARAGRAPH3}
        >
          <FormattedMessage
            id="modal.login.loginUnavailable"
            defaultMessage="Please use other registration options"
          />
        </Typography>
      </div>

      <div
        className={classnames(styles.wrapperOtherMethods, styles[breakpoint])}
      >
        {isDesktop ? (
          otherProviders.map((provider: LoginProvider, index) => {
            const Component = providerToComponentMap[provider];

            if (!Component) {
              return null;
            }

            return (
              <Component
                key={provider}
                provider={provider}
                buttonVariant={
                  index === 0 ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY
                }
                registrationSource={registrationSource}
              />
            );
          })
        ) : (
          <LoginProviders
            providers={otherProviders}
            registrationSource={registrationSource}
          />
        )}
      </div>

      <LoginModalDisclaimer />
    </>
  );
};

export default LoginUnavailable;
