import useUiAction from "ui/hooks/useUiAction";
import { useCallback } from "react";

export default (offer, onClick, additionalParams) => {
  const callback = useCallback(() => {
    onClick && onClick(offer);
  }, [offer, onClick]);
  const action = useUiAction({
    target: offer?.marketOfferId || offer?.offerId,
    callback,
    additionalParams,
  });

  return onClick ? action : undefined;
};
