import React from "react";
import classnames from "classnames";
import { Breakpoints } from "src/features/signin/imports/enums";
import { useBreakpoint } from "src/features/signin/imports/ui";
import styles from "./ProvidersSkeleton.scss";

const ProvidersSkeleton = () => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <div className={styles.title} />
      <div className={styles.content}>
        <div className={styles.mainProviders}>
          <div className={styles.button} />
          <div className={styles.button} />
        </div>

        {isDesktop && <div className={styles.separator} />}

        <div className={styles.button} />
      </div>
    </div>
  );
};

export default ProvidersSkeleton;
