/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.TcProfileInfo || ($protobuf.roots.TcProfileInfo = {});

export const TCProfileInfo = $root.TCProfileInfo = (() => {

    function TCProfileInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TCProfileInfo.prototype.profileId = "";
    TCProfileInfo.prototype.profileType = 0;
    TCProfileInfo.prototype.summaryText = "";

    TCProfileInfo.create = function create(properties) {
        return new TCProfileInfo(properties);
    };

    TCProfileInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.profileId);
        writer.uint32(16).int32(message.profileType);
        writer.uint32(26).string(message.summaryText);
        return writer;
    };

    TCProfileInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TCProfileInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TCProfileInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profileId = reader.string();
                    break;
                }
            case 2: {
                    message.profileType = reader.int32();
                    break;
                }
            case 3: {
                    message.summaryText = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("profileId"))
            throw $util.ProtocolError("missing required 'profileId'", { instance: message });
        if (!message.hasOwnProperty("profileType"))
            throw $util.ProtocolError("missing required 'profileType'", { instance: message });
        if (!message.hasOwnProperty("summaryText"))
            throw $util.ProtocolError("missing required 'summaryText'", { instance: message });
        return message;
    };

    TCProfileInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TCProfileInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.TCProfileInfo)
            return object;
        let message = new $root.TCProfileInfo();
        if (object.profileId != null)
            message.profileId = String(object.profileId);
        if (object.profileType != null)
            message.profileType = object.profileType | 0;
        if (object.summaryText != null)
            message.summaryText = String(object.summaryText);
        return message;
    };

    TCProfileInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.profileId = "";
            object.profileType = 0;
            object.summaryText = "";
        }
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            object.profileId = message.profileId;
        if (message.profileType != null && message.hasOwnProperty("profileType"))
            object.profileType = message.profileType;
        if (message.summaryText != null && message.hasOwnProperty("summaryText"))
            object.summaryText = message.summaryText;
        return object;
    };

    TCProfileInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TCProfileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TCProfileInfo";
    };

    return TCProfileInfo;
})();

export { $root as default };
