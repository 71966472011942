import React, { FC, createContext, useMemo, useState } from "react";
import emptyFunction from "fbjs/lib/emptyFunction";

export interface ConversationActiveVideo {
  playing: string;
  unmuted: string;
}
export const initialActiveVideo = {
  unmuted: "",
  playing: "",
};

const ConversationMuteContext = createContext({
  activeVideo: initialActiveVideo,
  setActiveVideo: emptyFunction as (video: ConversationActiveVideo) => void,
});

export default ConversationMuteContext;

export const ConversationMuteContextProvider: FC = ({ children }) => {
  const [activeVideo, setActiveVideo] = useState(initialActiveVideo);

  const value = useMemo(
    () => ({
      activeVideo,
      setActiveVideo,
    }),
    [activeVideo, setActiveVideo]
  );

  return (
    <ConversationMuteContext.Provider value={value}>
      {children}
    </ConversationMuteContext.Provider>
  );
};
