import { jwtDecode } from "jwt-decode";
import { Dispatch } from "redux";
import { LoginProvider } from "src/features/signin/imports/enums";
import {
  RootState,
  loadMyProfile,
  loginSelectors,
} from "src/features/signin/imports/state";
import { VoidCallback } from "src/features/signin/imports/types";
import { AppleAuthParams } from "src/features/signin/modal/login/components/LoginAppleButton";
import handleLoginResult from "src/features/signin/state/flows/utils/handleLoginResult";
import handleUpgradeGuestError from "src/features/signin/state/flows/utils/handleUpgradeGuestError";
import {
  dismissLoginModal,
  handlePreconditionErrorAndDismissModal,
} from "src/features/signin/state/flows/utils/loginHelpers";
import { upgradeGuestWithRetry } from "src/features/signin/state/flows/utils/upgradeGuestWithRetry";

export type AppleCredentials = {
  appleUserId: string;
  token: string;
  type: LoginProvider;
};

interface JwtToken {
  sub: string;
}

interface AppleLoginParams {
  loginParams: AppleAuthParams;
  onLoginSuccess?: VoidCallback;
}

export const loginWithAppleProvider =
  ({ loginParams, onLoginSuccess }: AppleLoginParams) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { id_token: idToken } = loginParams;

    const state: RootState = getState();
    const isLoggedIn = loginSelectors.isLoggedIn(state);

    if (isLoggedIn) {
      return Promise.resolve();
    }

    const prepareAppleCredentials = () =>
      new Promise<AppleCredentials>((resolve, reject) => {
        if (!idToken) {
          reject(new Error("Code(idToken) should be available"));
        }

        const { sub } = jwtDecode<JwtToken>(idToken);

        resolve({
          type: LoginProvider.APPLE,
          token: idToken,
          appleUserId: sub,
        });
      });

    return (
      prepareAppleCredentials()
        .then(upgradeGuestWithRetry(state, dispatch))
        .catch(
          handleUpgradeGuestError(state, dispatch, {
            code: idToken,
            callback: loginWithAppleProvider,
          })
        )
        .then(
          handleLoginResult(
            getState,
            dispatch,
            LoginProvider.APPLE,
            onLoginSuccess
          )
        )
        // @ts-ignore
        .then(() => dispatch(loadMyProfile()))
        .then(dismissLoginModal(dispatch))
        .catch(handlePreconditionErrorAndDismissModal(dispatch))
    );
  };
