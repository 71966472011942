import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/features/welcomeOnboardingOffer/imports/types";
import styles from "./WelcomeRadialBackground.scss";

interface WelcomeRadialBackgroundProps {
  color: string;
}

const hexRegExp = /^#([0-9A-F]{3}){1,2}$/i;

export const WelcomeRadialBackground: ComponentWithClassName<
  WelcomeRadialBackgroundProps
> = ({ className, color }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && hexRegExp.test(color)) {
      ref.current.style.setProperty("--color", color);
    }
  }, [color]);

  return <div ref={ref} className={classnames(styles.root, className)} />;
};
