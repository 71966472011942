import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { currencyToSymbol } from "src/constants";
import { Currency } from "src/enums";
import { currenciesSelectors } from "state/selectors";
import { Select, SingleSelectProps } from "ui/common/select/Select";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useChangeCurrency from "ui/hooks/useChangeCurrency";
import { ReactComponent as Chevron24 } from "img/chevron_24.svg";
import styles from "./CurrencyPicker.scss";

const formatOption = (value: Currency) =>
  `${currencyToSymbol[value] ? `${currencyToSymbol[value]}, ` : ""}${value}`;

type CurrencyPickerProps = {
  disabled?: boolean;
  onChange?: (newCurrency: Currency, prevCurrency: Currency) => void;
  variant?: "outlined" | "shader";
};

/*
 * @notice Do not put BI specific logic in this component,
 * instead use onChange callback to report any BI events.
 *
 * @example
 * See src/features/offers/cashier/components/CashierHeader/CashierHeader.tsx
 */
export const CurrencyPicker = ({
  variant = "outlined",
  disabled = false,
  onChange,
}: CurrencyPickerProps) => {
  const breakpoint = useBreakpointPrecise();
  const changeCurrency = useChangeCurrency();
  const currentCurrency = useSelector(currenciesSelectors.getCurrentCurrency);
  const currencies = useSelector(
    currenciesSelectors.getAllCurrencies,
    shallowEqual
  );

  const handleChangeCurrency: SingleSelectProps["onChange"] = (
    currencyItem
  ) => {
    if (currencyItem) {
      changeCurrency(currencyItem.value as Currency);
      onChange?.(currencyItem.value as Currency, currentCurrency);
    }
  };

  const currencyItems = currencies.map((currency) => ({
    value: currency,
    label: formatOption(currency),
  }));

  const currentCurrencyItem = {
    value: currentCurrency,
    label: formatOption(currentCurrency),
  };

  if (currencies?.length <= 1) {
    return null;
  }

  return (
    <Select
      containerClassName={classnames(
        styles[breakpoint],
        styles.currencySelect,
        styles[variant]
      )}
      onChange={handleChangeCurrency}
      value={currentCurrencyItem}
      options={currencyItems}
      disabled={disabled}
      variant={variant}
      data-testid="currency-picker"
      ButtonIcon={Chevron24}
    />
  );
};
