import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { BasicProfile } from "src/types/profile/profile";
import { RootState } from "state/delegate";
import {
  profilesCacheSelectors,
  viewerSessionSelectors,
  visitorProfileSelectors,
} from "state/selectors";
import useGetProfileIdByNickname from "ui/hooks/useGetProfileIdByNickname";
import useHelmet from "ui/hooks/useHelmet";
import {
  linkToProfileMatch,
  linkToStreamMatch,
  linkToUserProfileOrLiveStream,
} from "ui/navigation/links";
import { getTrimmedValue } from "utils/getTrimmedValue";

const BASE_CANONICAL_LINK = "https://tango.me";
const SHOULD_THROW_ERROR = false;

const getDescriptionText = (basicProfile: BasicProfile) => {
  const values = [
    getTrimmedValue(basicProfile.firstName),
    getTrimmedValue(basicProfile.lastName),
    getTrimmedValue(basicProfile.aliases?.[0]?.alias),
  ];

  return `Join ${values.join(" ")} & watch millions of livestreams on Tango!`;
};

export const useDynamicMetaTags = (pathname: string) => {
  const { formatMessage } = useIntl();
  const openGraph = useHelmet(pathname);

  const [shouldAddCanonicalLink, setShouldAddCanonicalLink] = useState(false);
  const [shouldAddMetaDescription, setShouldAddMetaDescription] =
    useState(false);

  const profileOrLiveStreamRouteMatch = useRouteMatch<{ nickname: string }>(
    linkToUserProfileOrLiveStream
  );
  const streamRouteMatch = useRouteMatch(linkToStreamMatch);
  const profileRouteMatch = useRouteMatch<{ accountId: string }>(
    linkToProfileMatch
  );

  const broadcasterId = useSelector(viewerSessionSelectors.getBroadcasterId);

  const { profileId } = useGetProfileIdByNickname(
    profileOrLiveStreamRouteMatch?.params?.nickname || "",
    profileRouteMatch?.params?.accountId || broadcasterId,
    SHOULD_THROW_ERROR
  );

  const basicProfileSelector = useCallback(
    (state: RootState): BasicProfile =>
      profilesCacheSelectors.getBasicProfile(state, profileId),
    [profileId]
  );

  const basicProfile = useSelector(basicProfileSelector, shallowEqual);

  const visitorProfile = useSelector(visitorProfileSelectors.getVisitorProfile);

  const defaultDescription = useMemo(
    () => formatMessage(openGraph.description),
    [formatMessage, openGraph.description]
  );

  const isProfileOrStreamRoute = useMemo(
    () =>
      profileOrLiveStreamRouteMatch?.isExact ||
      profileRouteMatch?.isExact ||
      streamRouteMatch?.isExact,
    [profileOrLiveStreamRouteMatch, profileRouteMatch, streamRouteMatch]
  );

  const profileAlias = useMemo(
    () =>
      basicProfile?.aliases?.[0]?.alias ??
      visitorProfile?.basic.aliases?.[0]?.alias ??
      "",
    [basicProfile, visitorProfile]
  );

  const canonicalLinkHref = useMemo(() => {
    if (isProfileOrStreamRoute && profileAlias) {
      return `${BASE_CANONICAL_LINK}/${profileAlias}`;
    }

    return BASE_CANONICAL_LINK;
  }, [isProfileOrStreamRoute, profileAlias]);

  const descriptionText = useMemo(() => {
    if (isProfileOrStreamRoute && (basicProfile || visitorProfile)) {
      return getDescriptionText(basicProfile ?? visitorProfile?.basic);
    }

    return defaultDescription;
  }, [
    basicProfile,
    defaultDescription,
    isProfileOrStreamRoute,
    visitorProfile,
  ]);

  useEffect(() => {
    const serverCanonicalLinkElement = document.querySelector(
      'link[rel="canonical"]:not([data-react-helmet])'
    );
    const serverMetaDescriptionElement = document.querySelector(
      'meta[name="description"]:not([data-react-helmet])'
    );

    if (serverCanonicalLinkElement) {
      serverCanonicalLinkElement.setAttribute("href", canonicalLinkHref);
    }

    if (serverMetaDescriptionElement) {
      serverMetaDescriptionElement.setAttribute("content", descriptionText);
    }

    setShouldAddCanonicalLink(!serverCanonicalLinkElement);
    setShouldAddMetaDescription(!serverMetaDescriptionElement);
  }, [canonicalLinkHref, descriptionText, pathname]);

  return {
    shouldAddCanonicalLink,
    canonicalLinkHref,
    shouldAddMetaDescription,
    descriptionText,
  };
};
