import React, { FC, memo } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { DisplayName } from "chat/imports/components";
import { TANGO_ACCOUNT_ID } from "chat/messageRequest/imports/constants";
import { useBreakpointPrecise } from "chat/messageRequest/imports/hooks";
import { profilesCacheSelectors } from "chat/messageRequest/imports/state";
import { AccountInfo, RootState } from "chat/messageRequest/imports/types";
import {
  TYPOGRAPHY_TYPE,
  Typography,
} from "chat/messageRequest/imports/uiCommon";
import styles from "./Disclaimer.scss";

interface ChatRequestDisclaimerProps {
  accountInfo?: AccountInfo | undefined;
  conversationId: string;
}

export const Disclaimer: FC<ChatRequestDisclaimerProps> = memo(
  ({ accountInfo, conversationId }) => {
    const breakpoint = useBreakpointPrecise();
    const {
      account_id: accountId,
      first_name: firstName,
      last_name: lastName,
    } = accountInfo || {};

    const basicProfile = useSelector(
      (state: RootState) =>
        profilesCacheSelectors.getBasicProfile(
          state,
          accountId || (conversationId === TANGO_ACCOUNT_ID && TANGO_ACCOUNT_ID)
        ),
      shallowEqual
    );

    return (
      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH5}
        className={classnames(styles.chatRequestDisclaimer, styles[breakpoint])}
        data-testid="chat-request-disclaimer"
      >
        <FormattedMessage
          id="chat.request.disclaimerResponse"
          defaultMessage="When you reply, {value} will be able to contact you and see your status."
          values={{
            value: (
              <Typography
                type={TYPOGRAPHY_TYPE.HEADLINE6}
                as="span"
                className={styles.chatRequestDisclaimerName}
              >
                <DisplayName
                  basicProfile={basicProfile}
                  firstName={firstName}
                  lastName={lastName}
                />
              </Typography>
            ),
          }}
        />
      </Typography>
    );
  }
);

Disclaimer.displayName = "ChatRequestDisclaimer";
