import React from "react";
import PropTypes from "prop-types";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { ReactComponent as CloseIcon } from "img/close-rounded.svg";
import useNotificationData from "./useNotificationData";
import styles from "./FallbackNotification.scss";

const FallbackNotification = ({ notificationId }) => {
  const data = useNotificationData(notificationId);
  if (!data) {
    return null;
  }
  const { title, icon, body, onClick, onClose } = data;
  return (
    <div
      className={styles.root}
      onClick={onClick}
      data-testid={`notification-${notificationId}`}
    >
      <img className={styles.icon} src={icon} />
      <div className={styles.title} data-testid="title">
        {title}
      </div>
      <div className={styles.body} data-testid="body">
        {body}
      </div>
      <Button
        className={styles.close}
        size={ButtonSize.MEDIUM_32}
        variant={ButtonVariant.ICON_ONLY}
        onClick={onClose}
        data-testid="close"
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

FallbackNotification.propTypes = {
  notificationId: PropTypes.string.isRequired,
};

export default FallbackNotification;
