import { defineMessages } from "react-intl";
export const sharedMessages = defineMessages({
  joinTheGlobal: {
    id: "home.joinTheGlobal.2",
    defaultMessage: "Join the global",
  },
  liveStreamingPlatform: {
    id: "home.liveStreamingPlatform.2",
    defaultMessage: "live-streaming platform",
  },
  forContentCreation: {
    id: "home.forContentCreation",
    defaultMessage:
      "for content creation, social communication, and live entertainment.",
  },
  liveStreams: {
    id: "home.liveStreams",
    defaultMessage: "Livestreams",
  },
  leaderboard: {
    id: "home.Leaderboard",
    defaultMessage: "Leaderboard",
  },
  games: {
    id: "menu.unauthorised.users.games",
    defaultMessage: "Games",
  },
  signInWithGoogle: {
    id: "home.signInWithGoogle",
    defaultMessage: "Sign in with Google",
  },
  signInWithApple: {
    id: "home.signInWithApple",
    defaultMessage: "Sign in with Apple",
  },
  signInWithPhone: {
    id: "home.signInWithPhone",
    defaultMessage: "Sign in with Phone",
  },
  moreOptions: {
    id: "home.moreOptions",
    defaultMessage: "More options",
  },
  homeOrSeparator: {
    id: "OR",
    defaultMessage: "OR",
  },
  homeBurgerJoin: {
    id: "home.burger.joinWorldwideLiveStreamingCommunity",
    defaultMessage: "Join worldwide live-streaming community",
  },
  trendingNow: {
    id: "home.carousel.trendingNow",
    defaultMessage: "Trending Now",
  },
  tangoApp: {
    id: "home.tangoApp",
    defaultMessage: "Tango App",
  },
});
