import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import bannerConfigsActions from "state/actionCreators/bannerGonfigs";
import { RootState } from "state/delegate";
import { loginSelectors, userSelectors } from "state/selectors";

const selector = (state: RootState) => ({
  accountId: userSelectors.getMyAccountId(state),
  isAuthorized: loginSelectors.isAuthorized(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
});

const useLoadBannerConfigs = () => {
  const dispatch = useDispatch();
  // TODO: remove isAuthorized once guest is disabled (https://tango-me.atlassian.net/browse/WEB-6577)
  const { accountId, isAuthorized, isLoggedIn } = useSelector(
    selector,
    shallowEqual
  );

  useEffect(() => {
    if (!isAuthorized && !isLoggedIn) {
      return;
    }

    dispatch(bannerConfigsActions.fetchBannerConfigs({ resetOnError: true }));
  }, [accountId, dispatch, isAuthorized, isLoggedIn]);
};

export default useLoadBannerConfigs;
