import { MutableRefObject } from "react";
import { Nullable } from "src/types/common";

export type IntersectionObserverRef = MutableRefObject<Nullable<HTMLElement>>;

const intersectionObserver = (
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit
) => {
  if (!window.IntersectionObserver) {
    console.error("not support intersection observer"); // eslint-disable-line no-console
    return null;
  }
  const instanceObserver = new IntersectionObserver(callback, options);
  const isDOMElement = (node: Element) => node instanceof Element;

  return {
    observe: (node: Element) => {
      if (isDOMElement(node)) {
        instanceObserver.observe(node);
      }
    },
    unobserve: (node: Element) => {
      if (isDOMElement(node)) {
        try {
          instanceObserver.unobserve(node);
        } catch (e) {
          console.error("unobserve error", e); // eslint-disable-line no-console
        }
      }
    },
  };
};

export default intersectionObserver;
