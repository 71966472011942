import {
  FAILED_TO_LOAD_PROFILE_IDS,
  LIVE_STREAM_FETCH_BY_ACCOUNT_ID,
  LIVE_STREAM_FETCH_BY_ACCOUNT_IDS,
  LOADED_PROFILE_BY_NICKNAME,
  LOADED_PROFILES,
  RESET_LOADING_PROFILE_ERRORS,
  SET_LOADING_PROFILE_IDS,
  SINGLE_PROFILE_BEGIN_FETCH,
  SINGLE_PROFILE_END_FETCH,
} from "state/actionTypes";

export const willLoadProfiles = ({ ids, params, ...rest }) => ({
  type: SET_LOADING_PROFILE_IDS,
  payload: ids,
  meta: { params, ...rest },
});

export const loadedProfiles = ({ profiles, params, ...rest }) => ({
  type: LOADED_PROFILES,
  payload: profiles,
  meta: { params, ...rest },
});

export const loadedProfileByNickname = ({
  profileId,
  profile,
  nickname,
  params,
}) => ({
  type: LOADED_PROFILE_BY_NICKNAME,
  payload: {
    profileId,
    nickname,
    profile,
  },
  meta: { params },
});

export const failedToLoadProfiles = ({ ids, error, params, ...rest }) => ({
  type: FAILED_TO_LOAD_PROFILE_IDS,
  error: true,
  payload: error,
  meta: { params, ids, ...rest },
});

export const willLoadSingleProfile = ({
  accountId,
  isMe = false,
  fieldsRequested,
}) => ({
  type: SINGLE_PROFILE_BEGIN_FETCH,
  meta: { isMe, accountId, fieldsRequested },
});

export const loadedSingleProfile = ({
  accountId,
  profile,
  isMe = false,
  fieldsRequested,
}) => ({
  type: SINGLE_PROFILE_END_FETCH,
  payload: profile,
  meta: { accountId, isMe, fieldsRequested },
});

export const failedToLoadSingleProfile = ({
  accountId,
  isMe,
  error,
  fieldsRequested,
}) => ({
  type: SINGLE_PROFILE_END_FETCH,
  error: true,
  payload: error,
  meta: { accountId, isMe, fieldsRequested },
});

export const loadedUserCurrentStream = ({ accountId, data }) => ({
  type: LIVE_STREAM_FETCH_BY_ACCOUNT_ID,
  payload: data,
  meta: { accountId },
});

export const loadedUsersListCurrentStreams = ({ accountIds, data }) => ({
  type: LIVE_STREAM_FETCH_BY_ACCOUNT_IDS,
  payload: data,
  meta: { accountIds },
});

export const resetLoadingProfileErrors = () => ({
  type: RESET_LOADING_PROFILE_ERRORS,
});
