import React from "react";
import { MAX, MIN } from "src/constants";
import { Breakpoints } from "src/enums";
import useMedia from "ui/hooks/useMedia";

export type BreakpointWithoutDesktop = Exclude<
  Breakpoints,
  Breakpoints.DESKTOP
>;

// depends on: src/sassPartials/_breakpoint.scss
const BREAKPOINTS_MAP = {
  [Breakpoints.SMALL_MOBILE]: 360,
  [Breakpoints.MOBILE]: 640,
  [Breakpoints.TABLET]: 1024,
} satisfies Record<BreakpointWithoutDesktop, number>;

export const getBreakpointQuery = (
  breakpoint: BreakpointWithoutDesktop,
  minMax: typeof MIN | typeof MAX,
  exclude = false
) => {
  const width = BREAKPOINTS_MAP[breakpoint];

  const excludeModifier = minMax === MIN ? +1 : -1;
  const finalWidth = exclude ? width + excludeModifier : width;

  return `(${minMax}-width: ${finalWidth}px)`;
};

const tabletBp = getBreakpointQuery(Breakpoints.TABLET, MAX, true);
const mobileBp = getBreakpointQuery(Breakpoints.MOBILE, MAX, true);
const smallMobileBp = getBreakpointQuery(Breakpoints.SMALL_MOBILE, MAX, true);

export const BreakpointContext = React.createContext<Breakpoints>(
  Breakpoints.DESKTOP
);

export const BreakpointProvider: React.FC = ({ children }) => {
  const breakpoint = useMedia(
    [smallMobileBp, mobileBp, tabletBp],
    [Breakpoints.SMALL_MOBILE, Breakpoints.MOBILE, Breakpoints.TABLET],
    Breakpoints.DESKTOP
  );
  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
};
