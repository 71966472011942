export enum ResponseCode {
  OK = "OK",
  FAILED_GENERAL = "FAILED_GENERAL",
  FAILED_UNAUTHORIZED = "FAILED_UNAUTHORIZED",
  FAILED_WRONG_STREAM_ID = "FAILED_WRONG_STREAM_ID",
  FAILED_TERMINATED = "FAILED_TERMINATED",
  FAILED_EXPIRED = "FAILED_EXPIRED",
  FAILED_WRONG_GIFT_ID = "FAILED_WRONG_GIFT_ID",
  FAILED_UNSUFFICIENT_BALANCE = "FAILED_UNSUFFICIENT_BALANCE",
  FAILED_DUPLICATED_TRANSACTION_ID = "FAILED_DUPLICATED_TRANSACTION_ID",
  FAILED_EXCEED_REDEEM_LIMIT = "FAILED_EXCEED_REDEEM_LIMIT",
  FAILED_VERIFY_PAYPAL_AUTHORIZATION_CODE = "FAILED_VERIFY_PAYPAL_AUTHORIZATION_CODE",
  FAILED_NO_PAYPAL_INFO = "FAILED_NO_PAYPAL_INFO",
  FAILED_TOO_MANY_REDEEM_REQUEST = "FAILED_TOO_MANY_REDEEM_REQUEST",
  FAILED_BAD_REQUEST = "FAILED_BAD_REQUEST",
  FAILED_NOT_INVITED = "FAILED_NOT_INVITED",
  FAILED_NOT_TICKET_PRIVATE = "FAILED_NOT_TICKET_PRIVATE",
  FAILED_NEED_TICKET = "FAILED_NEED_TICKET",
  FAILED_ALREADY_BOUGHT_TICKET = "FAILED_ALREADY_BOUGHT_TICKET",
  FAILED_SUSPENDED_RECIPIENT = "FAILED_SUSPENDED_RECIPIENT",
  FAILED_OPEN_REDEEM = "FAILED_OPEN_REDEEM",
  FAILED_WRONG_GAME_ID = "FAILED_WRONG_GAME_ID",
  FAILED_WRONG_GAME_STATE = "FAILED_WRONG_GAME_STATE",
  FAILED_EXCEED_GAME_LIMIT = "FAILED_EXCEED_GAME_LIMIT",
  FAILED_EXCEED_TRANSLATION_LIMIT = "FAILED_EXCEED_TRANSLATION_LIMIT",
  FAILED_TRANSLATION_PROVIDER_ERROR = "FAILED_TRANSLATION_PROVIDER_ERROR",
  FAILED_WRONG_GAME_TYPE = "FAILED_WRONG_GAME_TYPE",
  FAILED_ALREADY_BOUGHT_ON_DIFFERENT_ACCOUNT = "FAILED_ALREADY_BOUGHT_ON_DIFFERENT_ACCOUNT",
  FAILED_NEED_SUBSCRIPTION = "FAILED_NEED_SUBSCRIPTION",
  FAILED_NOT_SUPPORTED_CLIENT_VERSION = "FAILED_NOT_SUPPORTED_CLIENT_VERSION",
  FAILED_CARD_LIMIT = "FAILED_CARD_LIMIT",
  FAILED_SUBSCRIPTION_NOT_FOUND = "FAILED_SUBSCRIPTION_NOT_FOUND",
  FAILED_WRONG_SUBSCRIPTION_STATE = "FAILED_WRONG_SUBSCRIPTION_STATE",
  FAILED_INVALID_TRANSACTION_ID = "FAILED_INVALID_TRANSACTION_ID",
  FAILED_INVALID_RECEIPT = "FAILED_INVALID_RECEIPT",
  FAILED_NOT_VERIFIED_ACCOUNT = "FAILED_NOT_VERIFIED_ACCOUNT",
  FAILED_NO_ACTIVE_REDEEM_ACCOUNT = "FAILED_NO_ACTIVE_REDEEM_ACCOUNT",
  FAILED_REJECTED_REDEEM_COOLDOWN = "FAILED_REJECTED_REDEEM_COOLDOWN",
}
