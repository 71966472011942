import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { CaseReducer } from "@reduxjs/toolkit/src/createReducer";
import { AnyAction } from "redux";
import { LOGOUT_END } from "src/features/signin/exports/state/actionTypes";

export default <State, Action extends AnyAction>(
  builder: Omit<ActionReducerMapBuilder<State>, "addCase">,
  reducer: CaseReducer<State, Action>
) => {
  builder.addMatcher(
    (action) => action.type === LOGOUT_END && !action.error,
    reducer
  );

  return builder;
};
