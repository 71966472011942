import fetch, { formatParams } from "./utils/enhancedFetch";
import { getProxycadorHost } from "environment";

const baseUrl = () => `${getProxycadorHost()}/proxycador/api/public/v1/follow`;

const updateFollowingStatus = (accountId, action) =>
  fetch(`${baseUrl()}/${action}`, {
    method: "POST",
    body: accountId,
  });

export const followUser = ({ accountId, locale, guest }) =>
  updateFollowingStatus(accountId, `add?${formatParams({ locale, guest })}`);

export const unfollowUser = (accountId) =>
  updateFollowingStatus(accountId, "remove");

export const fetchFollowingList = () =>
  fetch(`${baseUrl()}/list`).then((resp) => resp.json());
