import {
  BannerConfig,
  cleanSessionStorageByKeys,
} from "src/features/banners/imports";

const PREFIX_BANNER_SHOWN_EVENT_KEY = "banner_shown";

const getKey = (banner: BannerConfig, isCashierOrRefill: boolean) =>
  `${PREFIX_BANNER_SHOWN_EVENT_KEY}_${banner.id}_${isCashierOrRefill ? "" : location.pathname}`;

export const bannerShownEventPersister = {
  get(banner: BannerConfig, isCashierOrRefill: boolean) {
    const key = getKey(banner, isCashierOrRefill);

    return sessionStorage.getItem(key) === "1";
  },
  set(banner: BannerConfig, isCashierOrRefill: boolean, isSent: boolean) {
    const key = getKey(banner, isCashierOrRefill);

    sessionStorage.setItem(key, isSent ? "1" : "0");
  },
  removeAll() {
    cleanSessionStorageByKeys(
      Object.keys(sessionStorage).filter((key) =>
        key.startsWith(PREFIX_BANNER_SHOWN_EVENT_KEY)
      )
    );
  },
};
