import React, { FC } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { PrivacyPolicyPage } from "src/features/ofac/components/PrivacyPolicyPage";
import { RestrictedPage } from "src/features/ofac/components/RestrictedPage";
import { TermsOfUsePage } from "src/features/ofac/components/TermsOfUsePage";
import {
  ThemeProvider,
  linkToBasePath,
  linkToPrivacyPolicy,
  linkToTermsOfUse,
  useSplashScreen,
} from "src/features/ofac/imports/ui";

export const RestrictedApp: FC = () => {
  useSplashScreen(false);

  return (
    <ThemeProvider>
      <Router>
        <Switch>
          <Route exact path={linkToBasePath} component={RestrictedPage} />
          <Route exact path={linkToTermsOfUse} component={TermsOfUsePage} />
          <Route
            exact
            path={linkToPrivacyPolicy}
            component={PrivacyPolicyPage}
          />
          <Redirect to={linkToBasePath} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
