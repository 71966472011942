import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { IconTango } from "src/features/ofac/imports/img";
import { ComponentWithClassName } from "src/features/ofac/imports/types";
import {
  linkToBasePath,
  useBreakpointPrecise,
} from "src/features/ofac/imports/ui";
import styles from "./SimpleHeader.scss";

export const SimpleHeader: ComponentWithClassName = ({ className }) => {
  const breakpoint = useBreakpointPrecise();

  return (
    <header
      className={classnames(className, styles[breakpoint], styles.header)}
      data-testid="simple-header"
    >
      <Link to={linkToBasePath}>
        <IconTango className={styles.logo} />
      </Link>
    </header>
  );
};
