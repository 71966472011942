import {
  VIEWER_SESSION_RESET,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  VIEWER_SESSION_UPDATE,
  VIEWER_SESSION_CLEAR_TOP_GIFTER_MESSAGE_EVENT,
  VIEWER_SESSION_TOP_GIFTERS_END_FETCH,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  SEND_MESSAGE_TO_SESSION_END,
} from "state/actionTypes";
import { selectors as topGiftersSelectors } from "./topGiftersSliceReducer";
import { selectors as eventsSelectors } from "./eventsSliceReducer";
import {
  STREAM_MAX_EVENTS_TO_KEEP_DEFAULT,
  STREAM_TOP_GIFTERS_BUBBLE_MESSAGE_LIMIT,
} from "src/constants";
import { StreamEvents } from "src/enums";

import invariant from "fbjs/lib/invariant";
import { reverse } from "src/utils/immutableArrayUtils";
import delegateSelectors from "state/tree/utils/delegateSelectors";
const NO_MESSAGE = null;
const initialState = {
  message: NO_MESSAGE, // { accountId: "", content: [], eventIds: [] }
  waitingForGifterInfoMessages: [],
  processedMessages: [],
};

const { shouldUpdateTopGifters } = delegateSelectors(
  topGiftersSelectors,
  (state) => state.topGifters
);

const { getEvent, getNonReconciledEvents } = delegateSelectors(
  eventsSelectors,
  (state) => state.events
);

const constructMessageFromTopGiftersInfo = (
  topGifters,
  messageIds,
  getMessageData,
  existingMessage
) => {
  for (const gifter of topGifters) {
    const gifterMessages = messageIds
      .map((id) => getMessageData(id))
      .filter((x) => x && x.accountId === gifter);
    if (gifterMessages.length) {
      if (existingMessage && existingMessage.accountId === gifter) {
        const newMessages = gifterMessages.filter(
          (x) => !existingMessage.eventIds.includes(x.id)
        );
        return {
          ...existingMessage,
          content: [
            ...existingMessage.content,
            ...newMessages.map((x) => x.data.content),
          ],
          eventIds: [
            ...existingMessage.eventIds,
            ...newMessages.map((x) => x.id),
          ],
        };
      }
      return {
        accountId: gifter,
        content: gifterMessages.map((x) => x.data.content),
        eventIds: gifterMessages.map((x) => x.id),
      };
    }
    if (existingMessage && existingMessage.accountId === gifter) {
      // prevent messages from gifters lower in top from bumping
      return existingMessage;
    }
  }
  return NO_MESSAGE;
};

const processEvents = (state, events, context) => {
  const hasGiftEvents = !!Object.values(events).find(
    (x) => x.type === StreamEvents.GIFT
  );
  const messageEvents = Object.values(events).filter(
    (x) => x.type === StreamEvents.MESSAGE
  );
  const messageEventIds = messageEvents
    .map((x) => x.id)
    .filter(
      (id) =>
        !state.waitingForGifterInfoMessages.includes(id) &&
        !state.processedMessages.includes(id)
    );

  if (hasGiftEvents || shouldUpdateTopGifters(context)) {
    // wait for top gifters to be updated
    return {
      ...state,
      waitingForGifterInfoMessages: [
        ...state.waitingForGifterInfoMessages,
        ...messageEventIds,
      ],
    };
  }
  const topGifters = context.topGifters.accountIds.slice(
    0,
    STREAM_TOP_GIFTERS_BUBBLE_MESSAGE_LIMIT
  );
  if (!topGifters.length) {
    return {
      ...state,
      message: NO_MESSAGE,
      waitingForGifterInfoMessages: [],
      processedMessages: [
        ...reverse(messageEventIds),
        ...reverse(state.waitingForGifterInfoMessages),
        ...state.processedMessages,
      ].slice(0, STREAM_MAX_EVENTS_TO_KEEP_DEFAULT),
    };
  }

  invariant(
    state.waitingForGifterInfoMessages.length === 0,
    "Cannot have any waitingForGifterInfoMessages here"
  );
  if (!messageEventIds.length && !state.waitingForGifterInfoMessages.length) {
    return state;
  }

  const pendingMessageIds = [
    ...reverse(messageEventIds),
    ...state.waitingForGifterInfoMessages,
  ];
  const newMessage = constructMessageFromTopGiftersInfo(
    topGifters,
    pendingMessageIds,
    (id) => events[id],
    state.message
  );
  return {
    ...state,
    message: newMessage,
    waitingForGifterInfoMessages: [],
    processedMessages: [
      ...reverse(messageEventIds),
      ...reverse(state.waitingForGifterInfoMessages),
      ...state.processedMessages,
    ].slice(0, STREAM_MAX_EVENTS_TO_KEEP_DEFAULT),
  };
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      return initialState;
    }
    case VIEWER_SESSION_TOP_GIFTERS_END_FETCH: {
      if (action.error) {
        return state;
      }
      const { topGifters } = action.payload;
      const { waitingForGifterInfoMessages, message, processedMessages } =
        state;
      if (!waitingForGifterInfoMessages.length) {
        if (message) {
          return topGifters.includes(message.accountId)
            ? state
            : {
                ...state,
                message: NO_MESSAGE,
              };
        }
        return state;
      }

      return {
        ...state,
        waitingForGifterInfoMessages: [],
        message: constructMessageFromTopGiftersInfo(
          topGifters.slice(0, STREAM_TOP_GIFTERS_BUBBLE_MESSAGE_LIMIT),
          waitingForGifterInfoMessages,
          (id) => getEvent(context, id),
          message
        ),
        processedMessages: [
          ...reverse(waitingForGifterInfoMessages),
          ...processedMessages,
        ].slice(0, STREAM_MAX_EVENTS_TO_KEEP_DEFAULT),
      };
    }
    case SEND_MESSAGE_TO_SESSION_END: {
      if (action.error || action.meta.censored) {
        return state;
      }
      const {
        meta: { requestId },
      } = action;
      const event = getNonReconciledEvents(context)
        .map((x) => getEvent(context, x))
        .find((x) => `${requestId}` === x.clientEventId);
      if (!event) {
        return state;
      }
      return processEvents(state, { [event.id]: event }, context);
    }
    case LIVE_RICH_NOTIFICATION_RECEIVED:
    case VIEWER_SESSION_UPDATE:
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }
      const {
        payload: { entities: { events = {} } = {} },
        meta: { currentUserId },
      } = action;
      const filteredEvents = Object.entries(events)
        .filter(
          ([, v]) =>
            !(v.type === StreamEvents.MESSAGE && v.accountId === currentUserId)
        )
        .reduce((a, [k, v]) => {
          a[k] = v;
          return a;
        }, {});
      return processEvents(state, filteredEvents, context);
    }
    case VIEWER_SESSION_CLEAR_TOP_GIFTER_MESSAGE_EVENT: {
      if (state.message === NO_MESSAGE) {
        return state;
      }
      return {
        ...state,
        message: NO_MESSAGE,
      };
    }
  }
  return state;
};

export const selectors = {
  getTopGifterMessage: (state) => state.message,
};
