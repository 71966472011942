import { parseJson } from "src/utils/jsonUtils";

const parseMessageDetails = (events) =>
  Object.entries(events).reduce((acc, [key, value]) => {
    if (value.data?.messageDetails) {
      acc[key] = {
        ...value,
        details: parseJson(value.data.messageDetails),
      };
    } else if (value.details) {
      acc[key] = {
        ...value,
        details: parseJson(value.details),
      };
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});

export default parseMessageDetails;
