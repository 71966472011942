import type { HomePageCreatorsState } from "src/features/homePage/v2/state/creatorsSlice";
import type { HomePageGamesState } from "src/features/homePage/v2/state/gamesSlice";
import type { HomePageV2NearbyState } from "src/features/homePage/v2/state/nearbySlice";
import type { HomePageProfilesState } from "src/features/homePage/v2/state/profilesSlice";

export const homePageGamesSelectors = {
  getLoading: (state: HomePageGamesState) => state.loading,
  getError: (state: HomePageGamesState) => state.error,
  getGames: (state: HomePageGamesState) => state.games,
};

export const homePageV2NearbySelectors = {
  getNearbyLineup: (state: HomePageV2NearbyState) => state.lineup,
  getNearbyRegions: (state: HomePageV2NearbyState) => state.regions,
  getNearbySelectedCountry: (state: HomePageV2NearbyState) =>
    state.selectedCountry,
  getNearbyLoading: (state: HomePageV2NearbyState) => state.loading,
  getNearbyError: (state: HomePageV2NearbyState) => state.error,
  getSelectedRegion: (state: HomePageV2NearbyState) => state.selectedRegion,
};

export const homePageCreatorsSelectors = {
  getLoading: (state: HomePageCreatorsState) => state.isLoading,
  getError: (state: HomePageCreatorsState) => state.error,
  getStreams: (state: HomePageCreatorsState) => state.streams,
  getBroadcasterIds: (state: HomePageCreatorsState) => state.broadcasterIds,
};

export const homePageProfilesSelectors = {
  getLoading: (state: HomePageProfilesState) => state.isLoading,
  getError: (state: HomePageProfilesState) => state.error,
  getProfiles: (state: HomePageProfilesState) => state.profiles,
};
