import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import styles from "./DragOverlay.scss";

const DragOverlay = memo(() => (
  <div className={styles.root}>
    <Typography type={TYPOGRAPHY_TYPE.HEADLINE1} className={styles.text}>
      <FormattedMessage
        id="chat.drag-overlay"
        defaultMessage="Drop files here to send them"
      />
    </Typography>
  </div>
));

DragOverlay.displayName = "DragOverlay";

export default DragOverlay;
