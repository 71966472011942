import React, { Fragment, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import SignInOptions from "src/features/signin/bottomScreen/SignInOptions";
import Logo from "src/features/signin/bottomScreen/components/Logo";
import MainViewContentContainer from "src/features/signin/bottomScreen/components/MainViewContentContainer";
import Title from "src/features/signin/bottomScreen/components/Title";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import {
  BottomScreenType,
  LoginModalTitleType,
} from "src/features/signin/imports/enums";
import LoginModalDisclaimer from "src/features/signin/modal/LoginModalDisclaimer";
import {
  descriptionMessages,
  titleMessages,
} from "src/features/signin/modal/constants";
import { loginContinueWithPhone } from "src/features/signin/state/login/actionCreators";
import styles from "src/features/signin/bottomScreen/SignUp.scss";

interface SignInMainViewParams {
  screenData: {
    registrationSource: RegistrationSource;
    title: LoginModalTitleType;
  };
  screenType: BottomScreenType;
}

const SignInMainView = ({ screenData, screenType }: SignInMainViewParams) => {
  const dispatch = useDispatch();

  const { title } = screenData;
  const isFullScreen =
    screenType === BottomScreenType.SIGN_UP_BOTTOM_SCREEN_FULL_HEIGHT;

  const MainViewContent = useMemo(
    () => (isFullScreen ? MainViewContentContainer : Fragment),
    [isFullScreen]
  );

  const beginPhoneNumberLogin = useCallback(() => {
    dispatch(loginContinueWithPhone());
  }, [dispatch]);

  const description =
    title in descriptionMessages ? (
      <FormattedMessage {...descriptionMessages[title]} />
    ) : undefined;

  return (
    <>
      {isFullScreen && <Logo className={styles.logo} />}

      <MainViewContent>
        <Title
          title={<FormattedMessage {...titleMessages[title]} />}
          description={description}
        />
        <SignInOptions
          {...screenData}
          beginPhoneNumberLogin={beginPhoneNumberLogin}
        />
      </MainViewContent>

      <LoginModalDisclaimer />
    </>
  );
};

export default SignInMainView;
