import { useContext, useEffect, useRef } from "react";
import useOpenCashier from "ui/hooks/useOpenCashier";
import PurchaseToasterContext from "ui/modal/modalViews/buyCoins/PurchaseToasterContext";

// linked with src/payments/checkout/notify-parent.js
const PAYMENT_SESSION_SUMMARY_PROPERTY = "paymentSessionSummary";

const usePaymentSessionSummary = () => {
  const timeout = useRef<number>();
  const openCashier = useOpenCashier();
  const { showSuccessPurchaseToaster, showFailurePurchaseToaster } = useContext(
    PurchaseToasterContext
  );

  useEffect(() => {
    const paymentSessionSummary = window.sessionStorage.getItem(
      PAYMENT_SESSION_SUMMARY_PROPERTY
    );
    if (paymentSessionSummary) {
      timeout.current = window.setTimeout(() => openCashier(), 1000);
      window.sessionStorage.removeItem(PAYMENT_SESSION_SUMMARY_PROPERTY);
      if (Number(paymentSessionSummary)) {
        showSuccessPurchaseToaster();
      } else {
        showFailurePurchaseToaster();
      }
    }

    return () => window.clearTimeout(timeout.current);
  }, [openCashier, showFailurePurchaseToaster, showSuccessPurchaseToaster]);
};

export default usePaymentSessionSummary;
