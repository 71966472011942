import React, { forwardRef, memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { VoidCallback } from "src/types/common";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import sharedMessages from "ui/common/intl/sharedMessages";
import { ReactComponent as CloseIcon } from "img/close-rounded.svg";
import styles from "./ImageUploadFailedToast.scss";

export type ImageUploadFailedToastProps = {
  runUpload: (onSuccess?: VoidCallback) => void;
};

const ImageUploadFailedToast = forwardRef<
  HTMLDivElement,
  CustomContentProps & ImageUploadFailedToastProps
>(({ id, runUpload, className, style }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleRetry = useCallback(() => {
    runUpload(handleClose);
  }, [handleClose, runUpload]);

  return (
    <SnackbarContent ref={ref} key={id} className={className} style={style}>
      <div className={styles.root}>
        <Button
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.ICON_ONLY}
          onClick={handleClose}
        >
          <CloseIcon />
        </Button>
        <FormattedMessage {...sharedMessages.uploadFailed} />
        <Button
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.SECONDARY_ON_SHADER}
          className={styles.retryButton}
          onClick={handleRetry}
          uppercase
        >
          <FormattedMessage {...sharedMessages.retry} />
        </Button>
      </div>
    </SnackbarContent>
  );
});

ImageUploadFailedToast.displayName = "ImageUploadFailedToast";

export default memo(ImageUploadFailedToast);
