import { useCallback, useLayoutEffect, useState } from "react";

export default (attached = true) => {
  const [el] = useState(() => document.createElement("div"));

  const appendChild = useCallback(() => {
    try {
      document.body.appendChild(el);
    } catch (e) {}
  }, [el]);

  const removeChild = useCallback(() => {
    try {
      document.body.removeChild(el);
    } catch (e) {}
  }, [el]);

  useLayoutEffect(() => {
    if (!attached) {
      return;
    }
    appendChild();

    return removeChild;
  }, [attached]);

  return el;
};
