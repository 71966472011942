import { useRouteMatch } from "react-router-dom";
import { Breakpoints, GiftsDrawerLayout } from "src/enums";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { linkToStreamMatch } from "ui/navigation/links";

const useGiftsDrawerLayout = () => {
  const isInStream = !!useRouteMatch(linkToStreamMatch);
  const isMobileLayout = useBreakpointPrecise() !== Breakpoints.DESKTOP;

  if (isMobileLayout) {
    return GiftsDrawerLayout.BOTTOM_SCREEN;
  } else if (isInStream) {
    return GiftsDrawerLayout.RIGHT_SIDE;
  }

  return GiftsDrawerLayout.MODAL;
};

export default useGiftsDrawerLayout;
