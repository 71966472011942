import React from "react";
import { ComponentWithClassName } from "src/features/signin/imports/types";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "src/features/signin/imports/ui";
import { ReactComponent as ArrowIcon } from "img/arrow_24.svg";

interface BackButtonProps {
  onClick: () => void;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const BackButton: ComponentWithClassName<BackButtonProps> = ({
  onClick,
  className,
  size = ButtonSize.CIRCLE_MEDIUM_40,
  variant = ButtonVariant.SECONDARY,
}) => (
  <Button
    className={className}
    data-testid="back"
    onClick={onClick}
    size={size}
    variant={variant}
  >
    <ArrowIcon />
  </Button>
);

export default BackButton;
