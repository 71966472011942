import { useCallback, useContext, useEffect, useRef } from "react";
import { emitUiAction } from "@analytics/emitUiAction";
import ScreenViewReportingContext from "@analytics/screenView/ScreenViewReportingContext";
import { AnyFunction } from "src/types/common";
import { AdditionalParamsObject } from "src/analytics/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useUiAction = <Args extends any[] = any[]>({
  action = "click",
  target,
  callback,
  additionalParams,
}: {
  action?: string;
  target: string;
  callback?: AnyFunction<void, Args>;
  additionalParams?:
    | AdditionalParamsObject
    | AnyFunction<AdditionalParamsObject, Args>;
}) => {
  const additionalParamsRef = useRef(additionalParams);
  useEffect(() => {
    additionalParamsRef.current = additionalParams;
  }, [additionalParams]);

  const { getStack } = useContext(ScreenViewReportingContext);
  return useCallback(
    (...args: Args) => {
      const [tango_screen] = getStack();
      const additionalParams = additionalParamsRef.current;
      emitUiAction({
        action,
        target,
        tango_screen,
        ...(additionalParams && {
          additionalParams:
            typeof additionalParams === "function"
              ? additionalParams(...args)
              : additionalParams,
        }),
      });
      return callback?.(...args);
    },
    [action, target, callback]
  );
};

export default useUiAction;
