import {
  fetchAllAvailableGifts,
  fetchDrawerVersion,
  fetchPersonalGifts,
  fetchSingleGift,
  fetchTopSentGifts,
} from "api/gifts";
import { fetchGiftsBatch } from "api/typedGifts";
import {
  getIsUseGatewayForGifts,
  getMoodsLegacyV2Enabled,
} from "state/abTests";
import {
  deviceInfoSelectors,
  giftsCacheSelectors,
  loginSelectors,
  userSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/giftsCache";

const DRAWER_INCLUDES_SPECIALS = ["AR_GIFT"];

const loadDrawerImpl = async (
  locale,
  drawerVersion,
  dispatch,
  isUseGateway
) => {
  dispatch(actionCreators.setAvailableDrawerVersion(drawerVersion));
  dispatch(actionCreators.fetchBegan());
  try {
    const drawer = await fetchAllAvailableGifts({
      locale,
      version: drawerVersion,
      includeSpecials: DRAWER_INCLUDES_SPECIALS,
      isUseGateway,
    });
    dispatch(
      actionCreators.fetchCompleted({
        data: { ...drawer, locale },
        replaceAll: false,
      })
    );
  } catch (error) {
    dispatch(actionCreators.fetchFailed({ error }));
  }
};

export default () => async (dispatch, getState) => {
  const state = getState();
  if (giftsCacheSelectors.getIsLoadInProgress(state)) {
    return;
  }
  let drawerVersion = 0;
  try {
    const response = await fetchDrawerVersion();
    drawerVersion = response.drawerVersion;
  } catch (error) {
    return;
  }
  const locale = deviceInfoSelectors.getDeviceLocale(state);
  if (
    drawerVersion <= giftsCacheSelectors.getLoadedVersion(state) &&
    locale === giftsCacheSelectors.getLoadedGiftsLocale(state) &&
    !giftsCacheSelectors.isStale(state)
  ) {
    return;
  }
  const isUseGatewayForGifts = getIsUseGatewayForGifts(state);
  await loadDrawerImpl(locale, drawerVersion, dispatch, isUseGatewayForGifts);
};

export const loadSingleGift = (giftId) => async (dispatch, getState) => {
  const state = getState();
  if (giftsCacheSelectors.getGiftById(state, giftId)) {
    return;
  }
  const locale = deviceInfoSelectors.getDeviceLocale(state);
  const latestKnownDrawerVersion = giftsCacheSelectors.getLoadedVersion(state);
  const isUseGatewayForGifts = getIsUseGatewayForGifts(state);
  const response = await fetchSingleGift({
    locale,
    giftId,
    isUseGateway: isUseGatewayForGifts,
  });
  dispatch(actionCreators.fetchedSingleGift(response));
  if (response.drawerVersion > latestKnownDrawerVersion) {
    const isUseGatewayForGifts = getIsUseGatewayForGifts(state);
    await loadDrawerImpl(
      locale,
      response.drawerVersion,
      dispatch,
      isUseGatewayForGifts
    );
  }
};

export const loadGiftsBatch =
  ({ giftIds, ignoreCache }) =>
  async (dispatch, getState) => {
    const state = getState();
    const idsToLoad = ignoreCache
      ? giftIds
      : giftIds.filter((x) => !giftsCacheSelectors.getGiftById(state, x));
    if (!idsToLoad.length) {
      return;
    }
    const locale = deviceInfoSelectors.getDeviceLocale(state);
    const loadedVersion = giftsCacheSelectors.getLoadedVersion(state);
    const giftsBatch = await fetchGiftsBatch(giftIds);
    dispatch(actionCreators.giftsBatchReceived(giftsBatch));
    if (giftsBatch.drawerVersion > loadedVersion) {
      const isUseGatewayForGifts = getIsUseGatewayForGifts(state);
      await loadDrawerImpl(
        locale,
        giftsBatch.drawerVersion,
        dispatch,
        isUseGatewayForGifts
      );
    }
  };

export const loadTopSentGifts = () => async (dispatch, getState) => {
  const state = getState();
  if (!getMoodsLegacyV2Enabled(state) || !loginSelectors.isLoggedIn(state)) {
    return;
  }
  try {
    const data = await fetchTopSentGifts({
      accountId: userSelectors.getMyAccountId(state),
    });
    const { topSentGifts } = data;
    if (!topSentGifts?.length) {
      return;
    }
    dispatch(actionCreators.setTopSentGifts(topSentGifts));
    await dispatch(
      loadGiftsBatch({ giftIds: topSentGifts, ignoreCache: false })
    );
  } catch (e) {
    // do nothing
  }
};

export const loadPersonalGifts = (streamerId) => async (dispatch, getState) => {
  const state = getState();
  if (!loginSelectors.isLoggedIn(state)) {
    return;
  }
  try {
    const data = await fetchPersonalGifts(streamerId);
    const { gifts } = data;
    if (!gifts?.length) {
      dispatch(actionCreators.setPersonalGifts([]));

      return;
    }
    dispatch(actionCreators.setPersonalGifts(gifts));
  } catch (e) {
    dispatch(actionCreators.setPersonalGifts([]));
  }
};
