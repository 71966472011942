import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { match } from "path-to-regexp";
import { LANDING_PLACE, PURCHASE_SOURCE, UI_COMPONENT } from "@analytics/enums";
import { CASHIER, REFILL } from "enums/cashier";
import { getCashierSasEnabled } from "environment";
import { BOTTOM_SCREEN_HIDE_ANIMATION_DURATION } from "src/constants";
import { Breakpoints, ModalType } from "src/enums";
import { BannerType } from "src/types/banner";
import { VoidCallback } from "src/types/common";
import { PersonalOffersViewType } from "src/types/personalOffer";
import {
  getIsCashierDesignV2DesktopEnabled,
  getIsCashierDesignV2Enabled,
  getIsRefillV2DesktopEnabled,
  getIsRefillV2MobileEnabled,
} from "state/abTests";
import {
  hideBottomScreen,
  openCashierV2DrawerBottomScreen,
  openGiftsDrawerInStreamBottomScreen,
  openRefillDrawerBottomScreen,
  openRefillV2DrawerBottomScreen,
  setAnimation,
} from "state/actionCreators/bottomScreen";
import {
  openBuyCoinsModal,
  openCashierModal,
  openRefillModal,
} from "state/actionCreators/modal";
import { RootState } from "state/delegate";
import { modalSelectors, navigationSelectors } from "state/selectors";
import { actionCreators as specialOfferActionCreators } from "state/tree/specialOffers";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useRedirectFromCashierToLanding from "ui/hooks/useRedirectFromCashierToLanding";
import { linkToStreamMatch } from "ui/navigation/links";

export interface CashierModalParams {
  onDismiss?: VoidCallback;
  previousUiComponent?: UI_COMPONENT;
  purchaseSource: PURCHASE_SOURCE;
  stayOnRedirect?: boolean;
  uiComponent?: UI_COMPONENT;
  viewType?: PersonalOffersViewType;
}

const selector = (state: RootState) => ({
  isCashierV2Enabled: getIsCashierDesignV2Enabled(state),
  currentRoute: navigationSelectors.getCurrentRoute(state),
  isRefillV2MobileEnabled: getIsRefillV2MobileEnabled(state),
  isCashierDesignV2DesktopEnabled: getIsCashierDesignV2DesktopEnabled(state),
  isRefillV2DesktopEnabled: getIsRefillV2DesktopEnabled(state),
  isCashierOpen: modalSelectors.isModalInStack(state, ModalType.CASHIER_MODAL),
});

const useOpenCashier = (
  modalParams?: CashierModalParams,
  LPParams?: {
    bannerType?: BannerType;
    landingPlace?: LANDING_PLACE;
  }
) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();

  const {
    isCashierV2Enabled,
    isRefillV2MobileEnabled,
    currentRoute,
    isCashierDesignV2DesktopEnabled,
    isCashierOpen,
    isRefillV2DesktopEnabled,
  } = useSelector(selector, shallowEqual);

  const history = useHistory();
  const { bannerWithSkipCashier, redirectToLanding } =
    useRedirectFromCashierToLanding(
      LPParams?.bannerType,
      LPParams?.landingPlace
    );
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  return useCallback(() => {
    const openModal = () => {
      const isCashier = modalParams?.viewType === CASHIER;
      const isRefill = modalParams?.viewType === REFILL;

      modalParams?.onDismiss?.();

      if (isCashier && isCashierDesignV2DesktopEnabled) {
        if (!isCashierOpen) {
          dispatch(
            // @ts-ignore TODO: typescript
            openCashierModal({
              ...modalParams,
              withBonus: getCashierSasEnabled(),
            })
          );
        }
      } else if (isRefill && isRefillV2DesktopEnabled) {
        dispatch(
          // @ts-ignore TODO: typescript
          openRefillModal({
            ...modalParams,
            withBonus: getCashierSasEnabled(),
          })
        );
      } else {
        dispatch(
          // @ts-ignore TODO: typescript
          openBuyCoinsModal({
            ...modalParams,
            withBonus: getCashierSasEnabled(),
          })
        );
      }
    };

    const onRefillCashierV2BottomScreenClose = () => {
      dispatch(setAnimation(true));

      setTimeout(() => {
        dispatch(hideBottomScreen());
        if (modalParams?.previousUiComponent === UI_COMPONENT.GIFT_DRAWER) {
          dispatch(openGiftsDrawerInStreamBottomScreen());
        }
      }, BOTTOM_SCREEN_HIDE_ANIMATION_DURATION);
    };

    const openBottomScreen = () => {
      const isStream = match(linkToStreamMatch, { end: false })(currentRoute);

      if (isCashierV2Enabled && !isStream) {
        dispatch(
          openCashierV2DrawerBottomScreen({
            screenData: {
              ...modalParams,
              withBonus: getCashierSasEnabled(),
              onClose: onRefillCashierV2BottomScreenClose,
            },
          })
        );
      } else if (isRefillV2MobileEnabled && isStream) {
        dispatch(
          openRefillV2DrawerBottomScreen({
            screenData: {
              ...modalParams,
              withBonus: getCashierSasEnabled(),
              onClose: onRefillCashierV2BottomScreenClose,
              onOutsideClickDismiss: onRefillCashierV2BottomScreenClose,
            },
          })
        );
      } else {
        dispatch(
          openRefillDrawerBottomScreen({
            screenData: {
              ...modalParams,
              withBonus: getCashierSasEnabled(),
            },
          })
        );
      }
    };

    const openContent = () => {
      isDesktop ? openModal() : openBottomScreen();

      dispatch(specialOfferActionCreators.viewAllOffers());
    };

    if (bannerWithSkipCashier) {
      redirectToLanding({
        place: LPParams?.landingPlace,
        withBottomScreen: !isDesktop,
        onDismiss: openContent,
      });
    } else {
      openContent();
    }
  }, [
    bannerWithSkipCashier,
    modalParams,
    isCashierDesignV2DesktopEnabled,
    isRefillV2DesktopEnabled,
    isCashierOpen,
    dispatch,
    currentRoute,
    isCashierV2Enabled,
    isRefillV2MobileEnabled,
    history,
    isDesktop,
    redirectToLanding,
    LPParams?.landingPlace,
  ]);
};

export default useOpenCashier;
