import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AccountToActiveStreamMap,
  fetchActiveStreamByAccountIds,
} from "api/stream";
import { isApiError } from "api/utils/enhancedFetch";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";

export const fetchAccountToStreamMap = createAsyncThunk<
  AccountToActiveStreamMap,
  { accountIds: string[] } & FetcherMetaV2,
  { rejectValue: string; state: RootState }
>("lwc/accountToStreamMap/fetch", async ({ accountIds }, thunkAPI) => {
  try {
    return await fetchActiveStreamByAccountIds({
      accountIds,
    });
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return thunkAPI.rejectWithValue(error);
  }
});
