import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCountriesForSignIn } from "src/api/countries";
import { isApiError } from "src/api/utils/enhancedFetch";
import { getOFACCountriesList } from "state/abTests";
import { RootState } from "state/delegate";
import { Data, FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import { CountriesState } from "state/tree/countries";

export const loadCountriesForSignIn = createAsyncThunk<
  Data<CountriesState>,
  FetcherMetaV2 | void,
  { rejectValue: string; state: RootState }
>("lwc/countries/fetchCountriesForSignIn", async (_, api) => {
  try {
    const OFACCountriesList = getOFACCountriesList(api.getState());
    const countries = await fetchCountriesForSignIn();

    return countries.filter(
      (country) =>
        !OFACCountriesList.includes(country.isoCountryCode.toLowerCase())
    );
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
