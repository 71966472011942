import {
  SET_NOTIFICATIONS_SUPPORTED,
  UPDATE_NOTI_PERMISSION,
  CLEAR_NOTIFICATIONS,
  INSERT_NOTIFICATION,
  LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED,
  MUTE_NOTIFICATIONS,
} from "state/actionTypes";
import withUserSessionScope from "state/hor/withUserSessionScope";
import { GRANTED, DEFAULT } from "enums/notificationsPermission";
import { isValidNotificationsPermission } from "enumAddons/notificationsPermission";
import ensureParameter from "./utils/ensureParameter";
import { omit, without } from "src/utils/miniLodash";
import { NotificationType } from "src/enums";

export const actionCreators = {
  setMutedNotificationTypes: (types) => ({
    type: MUTE_NOTIFICATIONS,
    payload: types,
  }),
  addNotification: ({ id, type, ...rest }) => ({
    type: INSERT_NOTIFICATION,
    payload: { id, type, ...rest },
  }),
  setNotificationsSupported: (supported) => ({
    type: SET_NOTIFICATIONS_SUPPORTED,
    payload: supported,
  }),
  setNotificationPermission: (permission, meta) => ({
    type: UPDATE_NOTI_PERMISSION,
    payload: permission,
    meta,
  }),
  clearNotifications: (ids) => ({ type: CLEAR_NOTIFICATIONS, payload: ids }),
};

export const selectors = {
  areNotificationsSupported: (state) => state.notificationsSupported,
  areNotificationsAllowed: (state) => state.notificationsPermission === GRANTED,
  shouldAskForUserPermissionToShowNotifications: (state) =>
    state.notificationsSupported && state.notificationsPermission === DEFAULT,
  getNotificationIds: (state) => state.notificationIds,
  getNotificationById: (state, notificationId) =>
    ensureParameter(notificationId, "notificationId") &&
    state.notifications[notificationId],
};

const initialState = {
  mutedTypes: [],
  notificationsSupported: false,
  notificationsPermission: DEFAULT,
  notificationIds: [], // mostly stream ids but can be whatever
  notifications: {}, // maps stream ids to data
};

const insertNotification = (state, id, type, data) => {
  if (state.mutedTypes.includes(type)) {
    return state;
  }
  return {
    ...state,
    notificationIds: [...state.notificationIds.filter((x) => x !== id), id],
    notifications: {
      ...state.notifications,
      [id]: {
        type,
        ...data,
      },
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTI_PERMISSION: {
      if (isValidNotificationsPermission(action.payload)) {
        return {
          ...state,
          notificationsPermission: action.payload,
        };
      }
      break;
    }
    case SET_NOTIFICATIONS_SUPPORTED: {
      return { ...state, notificationsSupported: action.payload };
    }
    case CLEAR_NOTIFICATIONS: {
      if (action.payload) {
        return {
          ...state,
          notificationIds: without(state.notificationIds, ...action.payload),
          notifications: omit(state.notifications, ...action.payload),
        };
      }
      return { ...state, notificationIds: [], notifications: {} };
    }
    case MUTE_NOTIFICATIONS: {
      const mutedTypes = action.payload;
      if (!mutedTypes.length) {
        return { ...state, mutedTypes };
      }
      const notificationIds = state.notificationIds.filter((x) => {
        const type = state.notifications[x].type;
        return !mutedTypes.includes(type);
      });
      return {
        ...state,
        mutedTypes,
        notificationIds,
        notifications: notificationIds.reduce((a, x) => {
          a[x] = state.notifications[x];
          return a;
        }, {}),
      };
    }
    case INSERT_NOTIFICATION: {
      const { id, type, ...rest } = action.payload;
      return insertNotification(state, id, type, rest);
    }
    case LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED: {
      const {
        payload: { stream },
      } = action;
      const notificationId = `stream-${stream.id}`;
      const data = { ...stream, accountId: stream.broadcasterId };
      return insertNotification(
        state,
        notificationId,
        NotificationType.STREAM_BEGAN,
        data
      );
    }
  }
  return state;
};

export default withUserSessionScope(reducer);
