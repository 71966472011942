import { fetchProfilesV2 } from "api/profile";
import {
  failedToLoadProfiles,
  loadedProfileByNickname,
  willLoadProfiles,
} from "state/actionCreators/profile";
import loadUserCurrentStream from "state/flows/loadUserCurrentStream";

export default ({
    nickname,
    params = { basic: true },
    withErrorCatch = true,
  }) =>
  async (dispatch) => {
    try {
      dispatch(willLoadProfiles({ ids: [nickname], params }));

      const profile = await fetchProfilesV2({
        profileIds: [nickname],
      });

      const profileId = Object.keys(profile)[0] || null;

      if (!profileId) {
        throw Error("User not found");
      }

      await dispatch(loadUserCurrentStream(profileId));

      dispatch(
        loadedProfileByNickname({
          profileId,
          profile,
          nickname,
          params,
        })
      );
    } catch (error) {
      if (withErrorCatch) {
        dispatch(failedToLoadProfiles({ error, ids: [nickname], params }));
      }
    }
  };
