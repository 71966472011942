import mapValues from "lodash.mapvalues";
import without from "lodash.without";
import pick from "lodash.pick";
import pickBy from "lodash.pickby";
import invert from "lodash.invert";
import omit from "lodash.omit";

export const uniq = <T>(array: ReadonlyArray<T>) => [...new Set(array)];

export const times = <T>(
  iterations: number,
  mapper: (value: number, index: number, arr: number[]) => T
) => [...Array(iterations).keys()].map(mapper);

/**
 * Checks if a string is empty or contains only whitespaces.
 * @see https://github.com/google/closure-library/blob/master/closure/goog/string/internal.js#L90
 */
export const isEmptyOrWhitespace = (str: string) =>
  // Testing length == 0 first is actually slower in all browsers (about the
  // same in Opera).
  // Since IE doesn't include non-breaking-space (0xa0) in their \s character
  // class (as required by section 7.2 of the ECMAScript spec), we explicitly
  // include it in the regexp to enforce consistent cross-browser behavior.
  /^[\s\xa0]*$/.test(str);

export const sample = <T>(array: T[]): T =>
  array[Math.floor(Math.random() * array.length)];

export { pick, pickBy, without, mapValues, invert, omit };
