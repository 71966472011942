import { LOGOUT_END } from "src/features/signin/exports/state/actionTypes";

export const makeWithUserSessionScope =
  (transform) => (reducer) => (state, action) => {
    if (action.type === LOGOUT_END && !action.error) {
      return transform({ reducer, state, action });
    }

    return reducer(state, action);
  };

const defaultImpl = makeWithUserSessionScope(({ reducer, action }) =>
  reducer(undefined, action)
);

export default defaultImpl;
