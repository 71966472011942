import { PhoneNumberSettingsState } from "src/features/profile/settings/state/phoneNumberSettingsSlice";

export const phoneNumberSettingsSelectors = {
  getPhoneNumberVerificationStep: (state: PhoneNumberSettingsState) =>
    state.phoneNumberVerificationStep,
  getPhoneNumberToVerify: (state: PhoneNumberSettingsState) =>
    state.phoneNumberToVerify,
  getTimestampByPhone: (state: PhoneNumberSettingsState) =>
    state.timestampByPhone,
  getCurrentAllowedCodeResendTime: (state: PhoneNumberSettingsState) =>
    state.timestampByPhone[state.phoneNumberToVerify],
  getCountryInfo: (state: PhoneNumberSettingsState) => state.country,
  getPhoneInputError: (state: PhoneNumberSettingsState) =>
    state.phoneInputError,
  getCodeInputError: (state: PhoneNumberSettingsState) => state.codeInputError,
};
