import {
  StickerGiftPayload,
  StickerGoalPayload,
  StickerImagePayload,
  StickerLuckyWheelPayload,
  StickerVotePayload,
  StickerWheelPayload,
} from "generated/proto/Sticker";
import {
  LiveStickerEventType,
  StickerType,
} from "src/types/richFragment/Sticker";
import { parseMessageFromBase64 } from "src/utils/protobufUtil";
import {
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";
import { getStreamId } from "state/tree/viewerSession/commonSelectors";

const initialState = [];

const stickerTypeToMessageType = {
  [StickerType.GOAL]: StickerGoalPayload,
  [StickerType.GIFT]: StickerGiftPayload,
  [StickerType.VOTE]: StickerVotePayload,
  [StickerType.IMAGE]: StickerImagePayload,
  [StickerType.WHEEL]: StickerWheelPayload,
  [StickerType.LUCKY_WHEEL]: StickerLuckyWheelPayload,
  [StickerType.UNKNOWN_TYPE]: null,
};

const supportedStickersTypeSet = new Set([
  StickerType.GOAL,
  StickerType.GIFT,
  StickerType.IMAGE,
  StickerType.VOTE,
  StickerType.WHEEL,
]);

const getParsedStickerPayload = (protobufPayload, stickerType) => {
  const messageType = stickerTypeToMessageType[stickerType];
  if (!protobufPayload || !messageType) {
    return null;
  }

  try {
    return parseMessageFromBase64(protobufPayload, messageType);
  } catch (e) {
    return null;
  }
};

const getStickerWithParsedPayload = (sticker) => {
  const preparedPayload = getParsedStickerPayload(
    sticker.payload,
    sticker.type
  );

  return (
    preparedPayload && {
      ...sticker,
      payload: preparedPayload,
    }
  );
};

export const localStickersSelectors = {
  getStickers: (state) =>
    state
      .filter((sticker) =>
        [
          StickerType.GIFT,
          StickerType.GOAL,
          StickerType.IMAGE,
          StickerType.WHEEL,
        ].includes(sticker.type)
      )
      .sort((first, second) => first.posY - second.posY),
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      if (action.payload === getStreamId(context)) {
        return state;
      }

      return initialState;
    }
    case VIEWER_SESSION_UPDATE: {
      const { stickers } = action.payload;
      if (!stickers) {
        return initialState;
      }

      return stickers.reduce((acc, sticker) => {
        if (!supportedStickersTypeSet.has(sticker.type)) {
          return acc;
        }
        const preparedSticker = getStickerWithParsedPayload(sticker);
        if (preparedSticker) {
          acc.push(preparedSticker);
        }

        return acc;
      }, []);
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }

      const { stickersEvents } = action.payload;
      if (!stickersEvents) {
        return state;
      }

      return stickersEvents.reduce(
        (storedStickers, { eventType, sticker }) => {
          switch (eventType) {
            case LiveStickerEventType.CREATED: {
              if (
                !supportedStickersTypeSet.has(sticker.type) ||
                !!storedStickers.find(
                  (storedSticker) => storedSticker.id === sticker.id
                )
              ) {
                return storedStickers;
              }

              const preparedSticker = getStickerWithParsedPayload(sticker);

              if (
                preparedSticker &&
                supportedStickersTypeSet.has(preparedSticker.type)
              ) {
                storedStickers.push(getStickerWithParsedPayload(sticker));
              }

              return storedStickers;
            }
            case LiveStickerEventType.UPDATED: {
              return storedStickers.map((storedSticker) => {
                if (storedSticker.id === sticker.id) {
                  const preparedSticker = getStickerWithParsedPayload(sticker);

                  return preparedSticker || storedSticker;
                }

                return storedSticker;
              });
            }
            case LiveStickerEventType.DELETED: {
              return storedStickers.filter(
                (storedSticker) => storedSticker.id !== sticker.id
              );
            }
            default: {
              return storedStickers;
            }
          }
        },
        [...state]
      );
    }

    default: {
      return state;
    }
  }
};
