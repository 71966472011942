import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";

const FOREGROUND_ID_KEY = "foreground-id";
const INTERACTION_ID_KEY = "interaction-id";
const IS_LINK_OPENED_FROM_WEB_KEY = "isLinkOpenedFromWeb";
export const USERNAME_KEY = "username";

export default class AnalyticsUtils {
  static updateInteractionId() {
    sessionStorage.setItem(INTERACTION_ID_KEY, uuidv4());
    /* eslint-disable no-console */
    console.log(
      "HEADERS interaction_id",
      AnalyticsUtils.getHeaders(sessionStorage.getItem(INTERACTION_ID_KEY))
    ); // eslint-disable-line no-console
  }

  static updateForegroundId() {
    const id = sessionStorage.getItem(FOREGROUND_ID_KEY);
    if (!id) {
      sessionStorage.setItem(FOREGROUND_ID_KEY, uuidv4());
      /* eslint-disable no-console */
      console.log(
        "HEADERS foreground_id",
        AnalyticsUtils.getHeaders(sessionStorage.getItem(FOREGROUND_ID_KEY))
      ); // eslint-disable-line no-console
    }
  }

  static updateIsLinkOpenedFromWeb(value) {
    sessionStorage.setItem(IS_LINK_OPENED_FROM_WEB_KEY, value);
  }

  static get interactionId() {
    return sessionStorage.getItem(INTERACTION_ID_KEY);
  }

  static get foregroundId() {
    return sessionStorage.getItem(FOREGROUND_ID_KEY);
  }

  static get username() {
    return sessionStorage.getItem(USERNAME_KEY);
  }

  static get isLinkOpenedFromWeb() {
    const storedValue = sessionStorage.getItem(IS_LINK_OPENED_FROM_WEB_KEY);
    return storedValue === null || storedValue === "1";
  }

  static getHeaders() {
    return {
      [FOREGROUND_ID_KEY]: AnalyticsUtils.foregroundId,
      [INTERACTION_ID_KEY]: AnalyticsUtils.interactionId,
      [USERNAME_KEY]: AnalyticsUtils.username,
    };
  }
}

export const useGenerateInteractionId = () => {
  useEffect(() => {
    AnalyticsUtils.updateInteractionId();
  }, []);
};
