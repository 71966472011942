import {
  CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT,
  CHAT_CONVERSATIONS_PAGE_SIZE,
} from "chat/constants";
import {
  ApiMessageType,
  Direction,
  ParamConversationState,
  ResponseCode,
} from "chat/enums";
import { getProxycadorHost } from "chat/imports/environment";
import { enhancedFetch as fetch } from "chat/imports/utils";
import {
  Conversation,
  ConversationState,
  GetMessageTranslationParams,
  MessageIdentifier,
  MessageMedia,
} from "chat/types";

type ResponseStatus = {
  code: ResponseCode;
  message?: string;
  timestamp: number;
};

export type GetConversationsParams = {
  direction: Direction;
  include_account_info?: boolean;
  include_group_info?: boolean;
  include_group_members?: boolean;
  include_messages?: boolean;
  last_update_request_timestamp?: number;
  limit_conversations?: number;
  limit_messages_per_conversation?: number;
  state?: ParamConversationState;
};

export type GetConversationsResponse = {
  conversations?: Conversation[];
  has_more_conversations?: boolean;
  status: ResponseStatus;
  total_conversation_request_count?: number;
  total_unread_count?: number;
};

export const getConversations = (
  {
    direction,
    // 0 - when using direction FORWARD only.
    last_update_request_timestamp,
    limit_conversations = CHAT_CONVERSATIONS_PAGE_SIZE,
    limit_messages_per_conversation = CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT,
    ...rest
  }: GetConversationsParams = {} as GetConversationsParams
): Promise<GetConversationsResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/conversations/get`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        direction,
        last_update_request_timestamp,
        limit_conversations,
        limit_messages_per_conversation,
        ...rest,
      }),
    }
  ).then((resp) => resp.json());

export type GetConversationParams = {
  direction?: Direction; // FORWARD or REVERSE, should NOT be set when range is used, i.e. both start_timestamp and end_timestamp are provided
  end_timestamp?: number; // used in range or when the direction = REVERSE
  include_group_info?: boolean;
  include_group_members?: boolean;
  limit?: number; // -1 - no limit
  start_timestamp?: number; // used in range or when the direction = FORWARD
};

export type GetConversationResponse = {
  conversation?: Conversation;
  status: ResponseStatus;
};

export const getConversation = (
  conversationId: string,
  params: GetConversationParams
): Promise<GetConversationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/messages/get?conversationId=${conversationId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export type ReadConversationParams = {
  conversation_id: string;
  last_read_message_ts: number;
  notify_sender?: boolean;
};

export type ReadConversationResponse = {
  status: ResponseStatus;
  total_unread_count?: number;
  unread_count?: number;
};

export const readConversationMessages = (
  params: ReadConversationParams
): Promise<ReadConversationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/messages/read`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export type SendMessage = {
  body?: string;
  forwardInfo?: {
    from_account_id?: string;
    from_account_name?: string;
  };
  media?: MessageMedia[];
  message_type: ApiMessageType;
  payload?: string;
  reply_message_id?: MessageIdentifier;
  sequence?: number;
};

export type SendMessageParams = {
  messages: SendMessage[];
  options?: {
    store_message_for_recipient?: boolean;
    store_message_for_sender?: boolean;
  };
  to: string[];
};

export type SendMessageResponse = {
  details?: {
    id?: MessageIdentifier;
    sequence?: number;
    state?: ConversationState;
  }[];
  status: ResponseStatus;
};

export const sendMessage = (
  params: SendMessageParams
): Promise<SendMessageResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/messages/send`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export type GetMessageTranslationResponse = {
  code: string;
  translated: string;
};

export const detectMessageLang = async ({
  message,
  locale,
}: Omit<GetMessageTranslationParams, "conversationId" | "messageId">) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/translator/v1/detectLanguage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        text: message,
        deviceLocale: locale,
        suggestedLanguageISO: locale,
      }),
    }
  ).then((resp) => resp.json());

export const getMessageTranslation = async ({
  message,
  locale,
}: GetMessageTranslationParams): Promise<GetMessageTranslationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/translator/v1/stream/client/v2/chat/translate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ text: message, language: locale }),
    }
  ).then((resp) => resp.json());

export const getMessageTranslationOnlineChat = async ({
  message,
  locale,
}: Omit<
  GetMessageTranslationParams,
  "conversationId" | "messageId" | "senderId"
>): Promise<GetMessageTranslationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/translator/v1/client/v2/stream/translate?language=${locale}&text=${message}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }
  ).then((resp) => resp.json());
