import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Button, EmojiSpan, Typography } from "chat/imports/components";
import {
  ButtonSize,
  ButtonVariant,
  LEADERBOARD_PATH,
  PROTOBUF_NULL_VALUE,
  TOURNAMENT_PATH,
  TYPOGRAPHY_TYPE,
  Target,
  deepLink,
} from "chat/imports/constants";
import { useBreakpointPrecise, useDeepLinkActions } from "chat/imports/hooks";
import { getExternalMessageWithUseDeepLinkEnabled } from "chat/imports/state";
import {
  addOpenFromWebParam,
  ensureHttps,
  getParsedExternalMessagePayload,
} from "chat/imports/utils";
import { ActionInfo, Message } from "chat/types";
import { addOpenFromWebChatParam } from "chat/utils/locationUtils";
import styles from "./ExternalMessage.scss";

interface ActionInfoProps {
  actionInfo: ActionInfo;
}

const LINKS_TO_APPEND_WITH_WEB_PARAM = [
  deepLink,
  TOURNAMENT_PATH,
  LEADERBOARD_PATH,
];

const Action = memo<ActionInfoProps>(({ actionInfo }) => {
  const isExternalMessageWithUseDeepLinkEnabled = useSelector(
    getExternalMessageWithUseDeepLinkEnabled
  );

  const { extractDeepLinkAction, setTaskByTarget, setTaskByAdditionalAction } =
    useDeepLinkActions();

  const { actionText, actionUrl, installUrl } = actionInfo;

  const btnProps = useMemo(() => {
    let indexOfLink = -1;
    let isDeepLink = false;

    if (actionUrl) {
      LINKS_TO_APPEND_WITH_WEB_PARAM.forEach((link) => {
        const index = actionUrl.indexOf(link);

        if (index > -1) {
          indexOfLink = index;

          if (link === deepLink) {
            isDeepLink = true;
          }
        }
      });
    }

    if (indexOfLink === -1) {
      return {
        to: actionUrl || installUrl,
        external: true,
        target: "_blank",
        rel: "noreferrer noopener",
        "data-testid": "banner-link",
      } as const;
    }

    const url = new URL(actionUrl);
    url.searchParams.delete("token");
    url.searchParams.delete("accountId");

    const action = extractDeepLinkAction(addOpenFromWebParam(url).toString());

    if (
      isDeepLink &&
      (isExternalMessageWithUseDeepLinkEnabled ||
        action.target === Target.LANDING_PAGE)
    ) {
      return {
        "data-testid": "banner-deeplink",
        onClick: () => {
          if (action.additionalAction) {
            setTaskByAdditionalAction(action);
          } else if (action.target) {
            setTaskByTarget(action);
          }
        },
      };
    }

    return {
      to: addOpenFromWebChatParam(addOpenFromWebParam(url))
        .toString()
        .slice(indexOfLink),
      "data-testid": isDeepLink ? "banner-deeplink" : "banner-link",
    } as const;
  }, [
    actionUrl,
    extractDeepLinkAction,
    installUrl,
    setTaskByAdditionalAction,
    setTaskByTarget,
    isExternalMessageWithUseDeepLinkEnabled,
  ]);

  return (
    <Button
      className={styles.action}
      size={ButtonSize.BIG_48}
      variant={ButtonVariant.PRIMARY}
      {...btnProps}
    >
      {actionText}
    </Button>
  );
});

Action.displayName = "Action";

interface ExternalMessageProps {
  message: Message;
}

const ExternalMessage: React.FC<ExternalMessageProps> = ({ message }) => {
  const breakpoint = useBreakpointPrecise();

  const { sdk_message_payload, alt, body } = message;

  const parsedPayload = useMemo(
    () => getParsedExternalMessagePayload(sdk_message_payload?.payload),
    [sdk_message_payload]
  );

  if (!parsedPayload || !sdk_message_payload) {
    return null;
  }

  const {
    messageText,
    actionInfo: actionsList,
    previewThumbnailUrl,
  } = parsedPayload;

  const actionInfo = actionsList?.[0];
  const isActionAvailable =
    actionInfo?.actionText && actionInfo?.actionText !== PROTOBUF_NULL_VALUE;

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      {previewThumbnailUrl && (
        <div className={styles.aspectRatioContainer}>
          <img
            src={ensureHttps(previewThumbnailUrl)}
            className={styles.banner}
            data-testid="banner-image"
            alt=""
          />
        </div>
      )}
      <Typography
        type={TYPOGRAPHY_TYPE.HEADLINE5}
        className={styles.content}
        as="div"
      >
        <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH2} data-testid="banner-body">
          <EmojiSpan>{messageText || body || alt}</EmojiSpan>
        </Typography>
        {isActionAvailable && <Action actionInfo={actionInfo} />}
      </Typography>
    </div>
  );
};

export default memo(ExternalMessage);
