/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.PicturePost || ($protobuf.roots.PicturePost = {});

export const PicturePost = $root.PicturePost = (() => {

    function PicturePost(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PicturePost.prototype.caption = "";
    PicturePost.prototype.url = "";
    PicturePost.prototype.thumbnailUrl = "";
    PicturePost.prototype.referenceUrl = "";
    PicturePost.prototype.hyperlinkUrl = "";
    PicturePost.prototype.hyperlinkCaption = "";
    PicturePost.prototype.attribution = "";
    PicturePost.prototype.tapAction = 1;
    PicturePost.prototype.thumbnailWidth = 0;
    PicturePost.prototype.thumbnailHeight = 0;
    PicturePost.prototype.blurredUrl = "";
    PicturePost.prototype.blurredThumbnailUrl = "";
    PicturePost.prototype.blurLevel = 0;
    PicturePost.prototype.mediaId = "";
    PicturePost.prototype.blurredMediaId = "";

    PicturePost.create = function create(properties) {
        return new PicturePost(properties);
    };

    PicturePost.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
            writer.uint32(10).string(message.caption);
        writer.uint32(18).string(message.url);
        writer.uint32(26).string(message.thumbnailUrl);
        if (message.referenceUrl != null && Object.hasOwnProperty.call(message, "referenceUrl"))
            writer.uint32(34).string(message.referenceUrl);
        if (message.hyperlinkUrl != null && Object.hasOwnProperty.call(message, "hyperlinkUrl"))
            writer.uint32(42).string(message.hyperlinkUrl);
        if (message.hyperlinkCaption != null && Object.hasOwnProperty.call(message, "hyperlinkCaption"))
            writer.uint32(50).string(message.hyperlinkCaption);
        if (message.attribution != null && Object.hasOwnProperty.call(message, "attribution"))
            writer.uint32(58).string(message.attribution);
        if (message.tapAction != null && Object.hasOwnProperty.call(message, "tapAction"))
            writer.uint32(64).int32(message.tapAction);
        if (message.thumbnailWidth != null && Object.hasOwnProperty.call(message, "thumbnailWidth"))
            writer.uint32(77).sfixed32(message.thumbnailWidth);
        if (message.thumbnailHeight != null && Object.hasOwnProperty.call(message, "thumbnailHeight"))
            writer.uint32(85).sfixed32(message.thumbnailHeight);
        if (message.blurredUrl != null && Object.hasOwnProperty.call(message, "blurredUrl"))
            writer.uint32(90).string(message.blurredUrl);
        if (message.blurredThumbnailUrl != null && Object.hasOwnProperty.call(message, "blurredThumbnailUrl"))
            writer.uint32(98).string(message.blurredThumbnailUrl);
        if (message.blurLevel != null && Object.hasOwnProperty.call(message, "blurLevel"))
            writer.uint32(105).double(message.blurLevel);
        if (message.mediaId != null && Object.hasOwnProperty.call(message, "mediaId"))
            writer.uint32(114).string(message.mediaId);
        if (message.blurredMediaId != null && Object.hasOwnProperty.call(message, "blurredMediaId"))
            writer.uint32(122).string(message.blurredMediaId);
        return writer;
    };

    PicturePost.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PicturePost.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PicturePost();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.caption = reader.string();
                    break;
                }
            case 2: {
                    message.url = reader.string();
                    break;
                }
            case 3: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            case 4: {
                    message.referenceUrl = reader.string();
                    break;
                }
            case 5: {
                    message.hyperlinkUrl = reader.string();
                    break;
                }
            case 6: {
                    message.hyperlinkCaption = reader.string();
                    break;
                }
            case 7: {
                    message.attribution = reader.string();
                    break;
                }
            case 8: {
                    message.tapAction = reader.int32();
                    break;
                }
            case 9: {
                    message.thumbnailWidth = reader.sfixed32();
                    break;
                }
            case 10: {
                    message.thumbnailHeight = reader.sfixed32();
                    break;
                }
            case 11: {
                    message.blurredUrl = reader.string();
                    break;
                }
            case 12: {
                    message.blurredThumbnailUrl = reader.string();
                    break;
                }
            case 13: {
                    message.blurLevel = reader.double();
                    break;
                }
            case 14: {
                    message.mediaId = reader.string();
                    break;
                }
            case 15: {
                    message.blurredMediaId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("url"))
            throw $util.ProtocolError("missing required 'url'", { instance: message });
        if (!message.hasOwnProperty("thumbnailUrl"))
            throw $util.ProtocolError("missing required 'thumbnailUrl'", { instance: message });
        return message;
    };

    PicturePost.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PicturePost.fromObject = function fromObject(object) {
        if (object instanceof $root.PicturePost)
            return object;
        let message = new $root.PicturePost();
        if (object.caption != null)
            message.caption = String(object.caption);
        if (object.url != null)
            message.url = String(object.url);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        if (object.referenceUrl != null)
            message.referenceUrl = String(object.referenceUrl);
        if (object.hyperlinkUrl != null)
            message.hyperlinkUrl = String(object.hyperlinkUrl);
        if (object.hyperlinkCaption != null)
            message.hyperlinkCaption = String(object.hyperlinkCaption);
        if (object.attribution != null)
            message.attribution = String(object.attribution);
        switch (object.tapAction) {
        default:
            if (typeof object.tapAction === "number") {
                message.tapAction = object.tapAction;
                break;
            }
            break;
        case "OpenUrl":
        case 1:
            message.tapAction = 1;
            break;
        case "OpenGallery":
        case 2:
            message.tapAction = 2;
            break;
        }
        if (object.thumbnailWidth != null)
            message.thumbnailWidth = object.thumbnailWidth | 0;
        if (object.thumbnailHeight != null)
            message.thumbnailHeight = object.thumbnailHeight | 0;
        if (object.blurredUrl != null)
            message.blurredUrl = String(object.blurredUrl);
        if (object.blurredThumbnailUrl != null)
            message.blurredThumbnailUrl = String(object.blurredThumbnailUrl);
        if (object.blurLevel != null)
            message.blurLevel = Number(object.blurLevel);
        if (object.mediaId != null)
            message.mediaId = String(object.mediaId);
        if (object.blurredMediaId != null)
            message.blurredMediaId = String(object.blurredMediaId);
        return message;
    };

    PicturePost.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.caption = "";
            object.url = "";
            object.thumbnailUrl = "";
            object.referenceUrl = "";
            object.hyperlinkUrl = "";
            object.hyperlinkCaption = "";
            object.attribution = "";
            object.tapAction = options.enums === String ? "OpenUrl" : 1;
            object.thumbnailWidth = 0;
            object.thumbnailHeight = 0;
            object.blurredUrl = "";
            object.blurredThumbnailUrl = "";
            object.blurLevel = 0;
            object.mediaId = "";
            object.blurredMediaId = "";
        }
        if (message.caption != null && message.hasOwnProperty("caption"))
            object.caption = message.caption;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        if (message.referenceUrl != null && message.hasOwnProperty("referenceUrl"))
            object.referenceUrl = message.referenceUrl;
        if (message.hyperlinkUrl != null && message.hasOwnProperty("hyperlinkUrl"))
            object.hyperlinkUrl = message.hyperlinkUrl;
        if (message.hyperlinkCaption != null && message.hasOwnProperty("hyperlinkCaption"))
            object.hyperlinkCaption = message.hyperlinkCaption;
        if (message.attribution != null && message.hasOwnProperty("attribution"))
            object.attribution = message.attribution;
        if (message.tapAction != null && message.hasOwnProperty("tapAction"))
            object.tapAction = options.enums === String ? $root.TapAction[message.tapAction] === undefined ? message.tapAction : $root.TapAction[message.tapAction] : message.tapAction;
        if (message.thumbnailWidth != null && message.hasOwnProperty("thumbnailWidth"))
            object.thumbnailWidth = message.thumbnailWidth;
        if (message.thumbnailHeight != null && message.hasOwnProperty("thumbnailHeight"))
            object.thumbnailHeight = message.thumbnailHeight;
        if (message.blurredUrl != null && message.hasOwnProperty("blurredUrl"))
            object.blurredUrl = message.blurredUrl;
        if (message.blurredThumbnailUrl != null && message.hasOwnProperty("blurredThumbnailUrl"))
            object.blurredThumbnailUrl = message.blurredThumbnailUrl;
        if (message.blurLevel != null && message.hasOwnProperty("blurLevel"))
            object.blurLevel = options.json && !isFinite(message.blurLevel) ? String(message.blurLevel) : message.blurLevel;
        if (message.mediaId != null && message.hasOwnProperty("mediaId"))
            object.mediaId = message.mediaId;
        if (message.blurredMediaId != null && message.hasOwnProperty("blurredMediaId"))
            object.blurredMediaId = message.blurredMediaId;
        return object;
    };

    PicturePost.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PicturePost.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PicturePost";
    };

    return PicturePost;
})();

export const TapAction = $root.TapAction = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "OpenUrl"] = 1;
    values[valuesById[2] = "OpenGallery"] = 2;
    return values;
})();

export { $root as default };
