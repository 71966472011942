import React, { memo, useMemo } from "react";
import Helmet from "react-helmet";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useDynamicMetaTags } from "ui/hooks/useDynamicMetaTags";
import useHelmet from "ui/hooks/useHelmet";

const DEFAULT_IMAGES = [
  "https://resources.tango.me/og/new/1200x630_Main.png",
  "https://resources.tango.me/og/new/1024x512_Main.png",
  "https://resources.tango.me/og/new/800x600_Main.png",
];

const HelmetController: React.FC = () => {
  const TANGO_LIVE_OG = "Tango Live - ";
  const { pathname } = useLocation();
  const openGraph = useHelmet(pathname);
  const { formatMessage } = useIntl();

  const {
    shouldAddCanonicalLink,
    canonicalLinkHref,
    descriptionText,
    shouldAddMetaDescription,
  } = useDynamicMetaTags(pathname);

  const meta = useMemo(() => {
    if (!openGraph) {
      return null;
    }

    const title = formatMessage(openGraph.title);
    const description = formatMessage(openGraph.description);
    const commonContent = `${TANGO_LIVE_OG}${title}`;

    return [
      // general
      { name: "title", content: commonContent },
      // Facebook Meta Tags
      { property: "og:url", content: document.location.href },
      { property: "og:title", content: commonContent },
      {
        property: "og:description",
        content: description,
      },
      { property: "og:type", content: "website" },
      ...DEFAULT_IMAGES.map((url: string) => ({
        property: "og:image",
        content: url,
      })),
      // Twitter Meta Tags
      { property: "twitter:url", content: document.location.href },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: commonContent },
      {
        name: "twitter:description",
        content: description,
      },
      { name: "twitter:image", content: DEFAULT_IMAGES[0] },
    ];
  }, [openGraph, formatMessage]);

  if (!meta) {
    return null;
  }

  return (
    <Helmet meta={meta}>
      {shouldAddCanonicalLink && (
        <link rel="canonical" href={canonicalLinkHref} />
      )}
      {shouldAddMetaDescription && (
        <meta name="description" content={descriptionText} />
      )}
    </Helmet>
  );
};

export default memo(HelmetController);
