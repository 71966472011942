import React, { useEffect } from "react";
import { defineMessages } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import classnames from "classnames";
import { SignUpDesktop, SignUpMobile } from "src/features/signin/components";
import { useCaptchaRequired } from "src/features/signin/hooks";
import {
  EventFields,
  EventNames,
  RegistrationSource,
  emitEvent,
} from "src/features/signin/imports/analytics";
import {
  Breakpoints,
  SignUpEntryPoint,
} from "src/features/signin/imports/enums";
import { loginSelectors } from "src/features/signin/imports/state";
import { linkToBasePath, useBreakpoint } from "src/features/signin/imports/ui";
import { useMount, useUnmount } from "src/features/signin/imports/utils";
import { prepareProviders } from "src/features/signin/state/flows/login";
import {
  loginSetEntryPoint,
  resetPhoneLogin,
} from "src/features/signin/state/login/actionCreators";
import { linkToRegistration, linkToRegpage1 } from "src/ui/navigation/links";
import FormattedMessageTitle from "ui/common/documentTitle/FormattedMessageTitle";
import styles from "./SignUp.scss";

const { title } = defineMessages({
  title: {
    id: "page.seo.title.registration",
    defaultMessage: "Tango Live Registration - Sign up Now",
  },
});

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const isCaptchaRequired = useCaptchaRequired();

  const { pathname } = useLocation();
  const isRegistrationEntrypoint = pathname === linkToRegistration;
  const isRegpageEntrypoint = pathname === linkToRegpage1;

  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(linkToBasePath);
    }
  }, [history, isLoggedIn]);

  useMount(() => {
    if (!isLoggedIn) {
      emitEvent(EventNames.GUEST_REGISTRATION, {
        [EventFields.GUEST_REGISTRATION_SOURCE]:
          RegistrationSource.REGISTRATION_PAGE,
      });

      batch(() => {
        dispatch(prepareProviders());
        if (isRegistrationEntrypoint) {
          dispatch(loginSetEntryPoint(SignUpEntryPoint.REGISTRATION));
        } else if (isRegpageEntrypoint) {
          dispatch(loginSetEntryPoint(SignUpEntryPoint.REGPAGE_1));
        }
      });
    }
  });

  useUnmount(() => {
    batch(() => {
      if (!isCaptchaRequired) {
        dispatch(resetPhoneLogin());
      }

      dispatch(loginSetEntryPoint(null));
    });
  });

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <FormattedMessageTitle {...title} isOverrideTitle />
      {isDesktop ? (
        <SignUpDesktop
          registrationSource={RegistrationSource.REGISTRATION_PAGE}
        />
      ) : (
        <SignUpMobile
          registrationSource={RegistrationSource.REGISTRATION_PAGE}
        />
      )}
    </div>
  );
};

export default SignUp;
