import { enhancedFetch } from "src/features/signin/imports/api";
import { getProxycadorHost } from "src/features/signin/imports/environment";

export const referralRegistration = (accountId) =>
  enhancedFetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/registerReferral`,
    {
      method: "POST",
      body: accountId,
    }
  );
