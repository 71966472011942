import { StreamStatus } from "src/types/richFragment/Stream";
import {
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW,
  VIEWER_SESSION_RESET,
} from "state/actionTypes";
import { getStreamId } from "./commonSelectors";

const initialState = {
  firstVersion: 0,
  lastVersion: 0,
  lastLoadedVersion: 0,
  targetFragmentDurationMs: 1000,
  gotTerminatedFragment: false,
};

export const selectors = {
  getFirstFragmentVersion: (state) => state.firstVersion,
  getLastFragmentVersion: (state) => state.lastVersion,
  getLastLoadedFragmentVersion: (state) => state.lastLoadedVersion,
  getTargetFragmentDurationMs: (state) => state.targetFragmentDurationMs,
  gotTerminatedFragment: (state) => state.gotTerminatedFragment,
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      if (getStreamId(context) === action.payload) {
        return state;
      }
      return initialState;
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW: {
      if (action.error) {
        return state;
      }

      const { targetDuration, lastVersion, firstVersion } = action.payload;

      return {
        ...state,
        targetFragmentDurationMs: targetDuration * 1000,
        lastVersion: Math.max(lastVersion, state.lastVersion),
        firstVersion: Math.max(firstVersion, state.firstVersion),
      };
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }

      const { version, detail: { stream: { status } = {} } = {} } =
        action.payload;

      if (Number(version) <= state.lastLoadedVersion) {
        return state;
      }

      return {
        ...state,
        lastLoadedVersion: Number(version),
        gotTerminatedFragment:
          state.gotTerminatedFragment ||
          status === StreamStatus.TERMINATED ||
          status === StreamStatus.EXPIRED,
      };
    }
  }
  return state;
};
