export enum PaymentProvider {
  CHECKOUT = "checkout",
  MINTROUTE = "mintroute",
  SAFECHARGE = "safecharge",
  SAFECHARGE_US = "safecharge_us",
  TANGO = "tango",
}

export enum PaymentType {
  AKBANK_TR = "akbank_tr",
  APPLE_PAY = "apple_pay",
  COINS = "coins",
  CRYPTO = "crypto",
  DENIZ_BANK_TR = "deniz_bank_tr",
  FAWRY = "fawry",
  FREECHARGE = "freecharge",
  GIROPAY = "giropay",
  GOOGLE_PAY = "google_pay",
  IDEAL = "ideal",
  ININAL_TR = "ininal_tr",
  ISBANKSI_TR = "isbanksi_tr",
  JIOMONEY = "jiomoney",
  KUVEYBANK_TR = "kuveybank_tr",
  MINTROUTE = "mintroute",
  MISTERCASH = "mistercash",
  MOBIKWIK = "mobikwik",
  NETBANKING = "netbanking",
  NEW_CARD = "new_card",
  OLAMONEY = "olamoney",
  OXIGENWALLET = "oxigenwallet",
  PAPARA = "papara",
  PAYPAL = "paypal",
  PAYTM = "paytm",
  PTT_TR = "ptt_tr",
  RAZER_EWALLETS = "razer_ewallets",
  RAZER_GOLD_WALLET = "razer_gold_wallet",
  RAZER_PAYMENT_WALL = "razer_payment_wall",
  SAVED_CARD = "saved_card",
  SEKERBANK_TR = "sekerbank_tr",
  SOFORT = "sofort",
  TURKIYEFINANS_TR = "turkiyefinans_tr",
  UPI = "upi",
  VAKRFBANK_TR = "vakrfbank_tr",
  YAPICREDI_TR = "yapicredi_tr",
  ZIRAAT_BANK_TR = "ziraat_bank_tr",
}

export enum CardType {
  CREDIT = "credit",
  DEBIT = "debit",
}

export enum CardScheme {
  AMERICAN_EXPRESS = "AMERICAN_EXPRESS",
  DEFAULT = "DEFAULT",
  MASTER_CARD = "MASTER_CARD",
  VISA = "VISA",
}

export enum PaymentTarget {
  CRYPTO = "crypto",
  TURKEY_BANKING = "tr_banking",
}
