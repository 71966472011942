/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.Sticker || ($protobuf.roots.Sticker = {});

export const AccountInfo = $root.AccountInfo = (() => {

    function AccountInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AccountInfo.prototype.id = "";
    AccountInfo.prototype.firstName = "";
    AccountInfo.prototype.lastName = "";
    AccountInfo.prototype.thumbnailUrl = "";
    AccountInfo.prototype.guest = false;
    AccountInfo.prototype.verified = false;
    AccountInfo.prototype.vipConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    AccountInfo.create = function create(properties) {
        return new AccountInfo(properties);
    };

    AccountInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(18).string(message.firstName);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(26).string(message.lastName);
        if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
            writer.uint32(34).string(message.thumbnailUrl);
        if (message.guest != null && Object.hasOwnProperty.call(message, "guest"))
            writer.uint32(40).bool(message.guest);
        if (message.verified != null && Object.hasOwnProperty.call(message, "verified"))
            writer.uint32(48).bool(message.verified);
        if (message.vipConfigId != null && Object.hasOwnProperty.call(message, "vipConfigId"))
            writer.uint32(57).sfixed64(message.vipConfigId);
        return writer;
    };

    AccountInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AccountInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AccountInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.firstName = reader.string();
                    break;
                }
            case 3: {
                    message.lastName = reader.string();
                    break;
                }
            case 4: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            case 5: {
                    message.guest = reader.bool();
                    break;
                }
            case 6: {
                    message.verified = reader.bool();
                    break;
                }
            case 7: {
                    message.vipConfigId = reader.sfixed64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        return message;
    };

    AccountInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AccountInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.AccountInfo)
            return object;
        let message = new $root.AccountInfo();
        if (object.id != null)
            message.id = String(object.id);
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        if (object.guest != null)
            message.guest = Boolean(object.guest);
        if (object.verified != null)
            message.verified = Boolean(object.verified);
        if (object.vipConfigId != null)
            if ($util.Long)
                (message.vipConfigId = $util.Long.fromValue(object.vipConfigId)).unsigned = false;
            else if (typeof object.vipConfigId === "string")
                message.vipConfigId = parseInt(object.vipConfigId, 10);
            else if (typeof object.vipConfigId === "number")
                message.vipConfigId = object.vipConfigId;
            else if (typeof object.vipConfigId === "object")
                message.vipConfigId = new $util.LongBits(object.vipConfigId.low >>> 0, object.vipConfigId.high >>> 0).toNumber();
        return message;
    };

    AccountInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.firstName = "";
            object.lastName = "";
            object.thumbnailUrl = "";
            object.guest = false;
            object.verified = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.vipConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.vipConfigId = options.longs === String ? "0" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        if (message.guest != null && message.hasOwnProperty("guest"))
            object.guest = message.guest;
        if (message.verified != null && message.hasOwnProperty("verified"))
            object.verified = message.verified;
        if (message.vipConfigId != null && message.hasOwnProperty("vipConfigId"))
            if (typeof message.vipConfigId === "number")
                object.vipConfigId = options.longs === String ? String(message.vipConfigId) : message.vipConfigId;
            else
                object.vipConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.vipConfigId) : options.longs === Number ? new $util.LongBits(message.vipConfigId.low >>> 0, message.vipConfigId.high >>> 0).toNumber() : message.vipConfigId;
        return object;
    };

    AccountInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AccountInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AccountInfo";
    };

    return AccountInfo;
})();

export const Sticker = $root.Sticker = (() => {

    function Sticker(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Sticker.prototype.id = "";
    Sticker.prototype.type = 1;
    Sticker.prototype.payload = "";
    Sticker.prototype.posX = 0;
    Sticker.prototype.posY = 0;
    Sticker.prototype.rotation = 0;
    Sticker.prototype.scaleX = 0;
    Sticker.prototype.scaleY = 0;
    Sticker.prototype.text = "";
    Sticker.prototype.status = 1;
    Sticker.prototype.scale = 0;

    Sticker.create = function create(properties) {
        return new Sticker(properties);
    };

    Sticker.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(10).string(message.id);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(16).int32(message.type);
        if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
            writer.uint32(26).string(message.payload);
        if (message.posX != null && Object.hasOwnProperty.call(message, "posX"))
            writer.uint32(37).float(message.posX);
        if (message.posY != null && Object.hasOwnProperty.call(message, "posY"))
            writer.uint32(45).float(message.posY);
        if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
            writer.uint32(53).float(message.rotation);
        if (message.scaleX != null && Object.hasOwnProperty.call(message, "scaleX"))
            writer.uint32(61).float(message.scaleX);
        if (message.scaleY != null && Object.hasOwnProperty.call(message, "scaleY"))
            writer.uint32(69).float(message.scaleY);
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(74).string(message.text);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(80).int32(message.status);
        if (message.scale != null && Object.hasOwnProperty.call(message, "scale"))
            writer.uint32(93).float(message.scale);
        return writer;
    };

    Sticker.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Sticker.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Sticker();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.type = reader.int32();
                    break;
                }
            case 3: {
                    message.payload = reader.string();
                    break;
                }
            case 4: {
                    message.posX = reader.float();
                    break;
                }
            case 5: {
                    message.posY = reader.float();
                    break;
                }
            case 6: {
                    message.rotation = reader.float();
                    break;
                }
            case 7: {
                    message.scaleX = reader.float();
                    break;
                }
            case 8: {
                    message.scaleY = reader.float();
                    break;
                }
            case 9: {
                    message.text = reader.string();
                    break;
                }
            case 10: {
                    message.status = reader.int32();
                    break;
                }
            case 11: {
                    message.scale = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Sticker.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Sticker.fromObject = function fromObject(object) {
        if (object instanceof $root.Sticker)
            return object;
        let message = new $root.Sticker();
        if (object.id != null)
            message.id = String(object.id);
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "UNKNOWN_TYPE":
        case 1:
            message.type = 1;
            break;
        case "GIFT":
        case 2:
            message.type = 2;
            break;
        case "IMAGE":
        case 3:
            message.type = 3;
            break;
        case "VOTE":
        case 4:
            message.type = 4;
            break;
        case "GOAL":
        case 5:
            message.type = 5;
            break;
        case "WHEEL":
        case 6:
            message.type = 6;
            break;
        case "LUCKY_WHEEL":
        case 7:
            message.type = 7;
            break;
        }
        if (object.payload != null)
            message.payload = String(object.payload);
        if (object.posX != null)
            message.posX = Number(object.posX);
        if (object.posY != null)
            message.posY = Number(object.posY);
        if (object.rotation != null)
            message.rotation = Number(object.rotation);
        if (object.scaleX != null)
            message.scaleX = Number(object.scaleX);
        if (object.scaleY != null)
            message.scaleY = Number(object.scaleY);
        if (object.text != null)
            message.text = String(object.text);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "UNKNOWN_STATUS":
        case 1:
            message.status = 1;
            break;
        case "ACTIVE":
        case 2:
            message.status = 2;
            break;
        case "PENDING_MODERATION":
        case 3:
            message.status = 3;
            break;
        case "MODERATION_FAILED":
        case 4:
            message.status = 4;
            break;
        }
        if (object.scale != null)
            message.scale = Number(object.scale);
        return message;
    };

    Sticker.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.type = options.enums === String ? "UNKNOWN_TYPE" : 1;
            object.payload = "";
            object.posX = 0;
            object.posY = 0;
            object.rotation = 0;
            object.scaleX = 0;
            object.scaleY = 0;
            object.text = "";
            object.status = options.enums === String ? "UNKNOWN_STATUS" : 1;
            object.scale = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.StickerType[message.type] === undefined ? message.type : $root.StickerType[message.type] : message.type;
        if (message.payload != null && message.hasOwnProperty("payload"))
            object.payload = message.payload;
        if (message.posX != null && message.hasOwnProperty("posX"))
            object.posX = options.json && !isFinite(message.posX) ? String(message.posX) : message.posX;
        if (message.posY != null && message.hasOwnProperty("posY"))
            object.posY = options.json && !isFinite(message.posY) ? String(message.posY) : message.posY;
        if (message.rotation != null && message.hasOwnProperty("rotation"))
            object.rotation = options.json && !isFinite(message.rotation) ? String(message.rotation) : message.rotation;
        if (message.scaleX != null && message.hasOwnProperty("scaleX"))
            object.scaleX = options.json && !isFinite(message.scaleX) ? String(message.scaleX) : message.scaleX;
        if (message.scaleY != null && message.hasOwnProperty("scaleY"))
            object.scaleY = options.json && !isFinite(message.scaleY) ? String(message.scaleY) : message.scaleY;
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.StickerStatus[message.status] === undefined ? message.status : $root.StickerStatus[message.status] : message.status;
        if (message.scale != null && message.hasOwnProperty("scale"))
            object.scale = options.json && !isFinite(message.scale) ? String(message.scale) : message.scale;
        return object;
    };

    Sticker.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Sticker.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Sticker";
    };

    return Sticker;
})();

export const StickerLuckyWheelPayload = $root.StickerLuckyWheelPayload = (() => {

    function StickerLuckyWheelPayload(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerLuckyWheelPayload.prototype.configurationId = "";

    StickerLuckyWheelPayload.create = function create(properties) {
        return new StickerLuckyWheelPayload(properties);
    };

    StickerLuckyWheelPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.configurationId);
        return writer;
    };

    StickerLuckyWheelPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerLuckyWheelPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerLuckyWheelPayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.configurationId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("configurationId"))
            throw $util.ProtocolError("missing required 'configurationId'", { instance: message });
        return message;
    };

    StickerLuckyWheelPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerLuckyWheelPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerLuckyWheelPayload)
            return object;
        let message = new $root.StickerLuckyWheelPayload();
        if (object.configurationId != null)
            message.configurationId = String(object.configurationId);
        return message;
    };

    StickerLuckyWheelPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.configurationId = "";
        if (message.configurationId != null && message.hasOwnProperty("configurationId"))
            object.configurationId = message.configurationId;
        return object;
    };

    StickerLuckyWheelPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerLuckyWheelPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerLuckyWheelPayload";
    };

    return StickerLuckyWheelPayload;
})();

export const StickerWheelSegment = $root.StickerWheelSegment = (() => {

    function StickerWheelSegment(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerWheelSegment.prototype.id = "";
    StickerWheelSegment.prototype.text = "";
    StickerWheelSegment.prototype.emoji = "";

    StickerWheelSegment.create = function create(properties) {
        return new StickerWheelSegment(properties);
    };

    StickerWheelSegment.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.text);
        writer.uint32(26).string(message.emoji);
        return writer;
    };

    StickerWheelSegment.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerWheelSegment.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerWheelSegment();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.text = reader.string();
                    break;
                }
            case 3: {
                    message.emoji = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("text"))
            throw $util.ProtocolError("missing required 'text'", { instance: message });
        if (!message.hasOwnProperty("emoji"))
            throw $util.ProtocolError("missing required 'emoji'", { instance: message });
        return message;
    };

    StickerWheelSegment.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerWheelSegment.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerWheelSegment)
            return object;
        let message = new $root.StickerWheelSegment();
        if (object.id != null)
            message.id = String(object.id);
        if (object.text != null)
            message.text = String(object.text);
        if (object.emoji != null)
            message.emoji = String(object.emoji);
        return message;
    };

    StickerWheelSegment.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.text = "";
            object.emoji = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.emoji != null && message.hasOwnProperty("emoji"))
            object.emoji = message.emoji;
        return object;
    };

    StickerWheelSegment.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerWheelSegment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerWheelSegment";
    };

    return StickerWheelSegment;
})();

export const StickerWheelPayload = $root.StickerWheelPayload = (() => {

    function StickerWheelPayload(properties) {
        this.segments = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerWheelPayload.prototype.giftId = "";
    StickerWheelPayload.prototype.configurationId = "";
    StickerWheelPayload.prototype.priceInCredit = 0;
    StickerWheelPayload.prototype.segments = $util.emptyArray;

    StickerWheelPayload.create = function create(properties) {
        return new StickerWheelPayload(properties);
    };

    StickerWheelPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.giftId);
        writer.uint32(18).string(message.configurationId);
        writer.uint32(29).sfixed32(message.priceInCredit);
        if (message.segments != null && message.segments.length)
            for (let i = 0; i < message.segments.length; ++i)
                $root.StickerWheelSegment.encode(message.segments[i], writer.uint32(34).fork()).ldelim();
        return writer;
    };

    StickerWheelPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerWheelPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerWheelPayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.giftId = reader.string();
                    break;
                }
            case 2: {
                    message.configurationId = reader.string();
                    break;
                }
            case 3: {
                    message.priceInCredit = reader.sfixed32();
                    break;
                }
            case 4: {
                    if (!(message.segments && message.segments.length))
                        message.segments = [];
                    message.segments.push($root.StickerWheelSegment.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("giftId"))
            throw $util.ProtocolError("missing required 'giftId'", { instance: message });
        if (!message.hasOwnProperty("configurationId"))
            throw $util.ProtocolError("missing required 'configurationId'", { instance: message });
        if (!message.hasOwnProperty("priceInCredit"))
            throw $util.ProtocolError("missing required 'priceInCredit'", { instance: message });
        return message;
    };

    StickerWheelPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerWheelPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerWheelPayload)
            return object;
        let message = new $root.StickerWheelPayload();
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.configurationId != null)
            message.configurationId = String(object.configurationId);
        if (object.priceInCredit != null)
            message.priceInCredit = object.priceInCredit | 0;
        if (object.segments) {
            if (!Array.isArray(object.segments))
                throw TypeError(".StickerWheelPayload.segments: array expected");
            message.segments = [];
            for (let i = 0; i < object.segments.length; ++i) {
                if (typeof object.segments[i] !== "object")
                    throw TypeError(".StickerWheelPayload.segments: object expected");
                message.segments[i] = $root.StickerWheelSegment.fromObject(object.segments[i]);
            }
        }
        return message;
    };

    StickerWheelPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.segments = [];
        if (options.defaults) {
            object.giftId = "";
            object.configurationId = "";
            object.priceInCredit = 0;
        }
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.configurationId != null && message.hasOwnProperty("configurationId"))
            object.configurationId = message.configurationId;
        if (message.priceInCredit != null && message.hasOwnProperty("priceInCredit"))
            object.priceInCredit = message.priceInCredit;
        if (message.segments && message.segments.length) {
            object.segments = [];
            for (let j = 0; j < message.segments.length; ++j)
                object.segments[j] = $root.StickerWheelSegment.toObject(message.segments[j], options);
        }
        return object;
    };

    StickerWheelPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerWheelPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerWheelPayload";
    };

    return StickerWheelPayload;
})();

export const StickerGiftPayload = $root.StickerGiftPayload = (() => {

    function StickerGiftPayload(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerGiftPayload.prototype.giftId = "";
    StickerGiftPayload.prototype.gifterAccountId = "";
    StickerGiftPayload.prototype.collectionGoal = 0;
    StickerGiftPayload.prototype.collectionProgress = 0;

    StickerGiftPayload.create = function create(properties) {
        return new StickerGiftPayload(properties);
    };

    StickerGiftPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.giftId);
        if (message.gifterAccountId != null && Object.hasOwnProperty.call(message, "gifterAccountId"))
            writer.uint32(18).string(message.gifterAccountId);
        if (message.collectionGoal != null && Object.hasOwnProperty.call(message, "collectionGoal"))
            writer.uint32(29).sfixed32(message.collectionGoal);
        if (message.collectionProgress != null && Object.hasOwnProperty.call(message, "collectionProgress"))
            writer.uint32(37).sfixed32(message.collectionProgress);
        return writer;
    };

    StickerGiftPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerGiftPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerGiftPayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.giftId = reader.string();
                    break;
                }
            case 2: {
                    message.gifterAccountId = reader.string();
                    break;
                }
            case 3: {
                    message.collectionGoal = reader.sfixed32();
                    break;
                }
            case 4: {
                    message.collectionProgress = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("giftId"))
            throw $util.ProtocolError("missing required 'giftId'", { instance: message });
        return message;
    };

    StickerGiftPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerGiftPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerGiftPayload)
            return object;
        let message = new $root.StickerGiftPayload();
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.gifterAccountId != null)
            message.gifterAccountId = String(object.gifterAccountId);
        if (object.collectionGoal != null)
            message.collectionGoal = object.collectionGoal | 0;
        if (object.collectionProgress != null)
            message.collectionProgress = object.collectionProgress | 0;
        return message;
    };

    StickerGiftPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.giftId = "";
            object.gifterAccountId = "";
            object.collectionGoal = 0;
            object.collectionProgress = 0;
        }
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.gifterAccountId != null && message.hasOwnProperty("gifterAccountId"))
            object.gifterAccountId = message.gifterAccountId;
        if (message.collectionGoal != null && message.hasOwnProperty("collectionGoal"))
            object.collectionGoal = message.collectionGoal;
        if (message.collectionProgress != null && message.hasOwnProperty("collectionProgress"))
            object.collectionProgress = message.collectionProgress;
        return object;
    };

    StickerGiftPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerGiftPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerGiftPayload";
    };

    return StickerGiftPayload;
})();

export const StickerGoalPayload = $root.StickerGoalPayload = (() => {

    function StickerGoalPayload(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerGoalPayload.prototype.goal = 0;
    StickerGoalPayload.prototype.progress = 0;

    StickerGoalPayload.create = function create(properties) {
        return new StickerGoalPayload(properties);
    };

    StickerGoalPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.goal != null && Object.hasOwnProperty.call(message, "goal"))
            writer.uint32(13).sfixed32(message.goal);
        if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
            writer.uint32(21).sfixed32(message.progress);
        return writer;
    };

    StickerGoalPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerGoalPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerGoalPayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.goal = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.progress = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StickerGoalPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerGoalPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerGoalPayload)
            return object;
        let message = new $root.StickerGoalPayload();
        if (object.goal != null)
            message.goal = object.goal | 0;
        if (object.progress != null)
            message.progress = object.progress | 0;
        return message;
    };

    StickerGoalPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.goal = 0;
            object.progress = 0;
        }
        if (message.goal != null && message.hasOwnProperty("goal"))
            object.goal = message.goal;
        if (message.progress != null && message.hasOwnProperty("progress"))
            object.progress = message.progress;
        return object;
    };

    StickerGoalPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerGoalPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerGoalPayload";
    };

    return StickerGoalPayload;
})();

export const StickerImagePayload = $root.StickerImagePayload = (() => {

    function StickerImagePayload(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerImagePayload.prototype.url = "";

    StickerImagePayload.create = function create(properties) {
        return new StickerImagePayload(properties);
    };

    StickerImagePayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.url);
        return writer;
    };

    StickerImagePayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerImagePayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerImagePayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.url = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("url"))
            throw $util.ProtocolError("missing required 'url'", { instance: message });
        return message;
    };

    StickerImagePayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerImagePayload.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerImagePayload)
            return object;
        let message = new $root.StickerImagePayload();
        if (object.url != null)
            message.url = String(object.url);
        return message;
    };

    StickerImagePayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.url = "";
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        return object;
    };

    StickerImagePayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerImagePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerImagePayload";
    };

    return StickerImagePayload;
})();

export const StickerList = $root.StickerList = (() => {

    function StickerList(properties) {
        this.stickers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerList.prototype.stickers = $util.emptyArray;

    StickerList.create = function create(properties) {
        return new StickerList(properties);
    };

    StickerList.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.stickers != null && message.stickers.length)
            for (let i = 0; i < message.stickers.length; ++i)
                $root.Sticker.encode(message.stickers[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    StickerList.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerList();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.stickers && message.stickers.length))
                        message.stickers = [];
                    message.stickers.push($root.Sticker.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StickerList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerList.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerList)
            return object;
        let message = new $root.StickerList();
        if (object.stickers) {
            if (!Array.isArray(object.stickers))
                throw TypeError(".StickerList.stickers: array expected");
            message.stickers = [];
            for (let i = 0; i < object.stickers.length; ++i) {
                if (typeof object.stickers[i] !== "object")
                    throw TypeError(".StickerList.stickers: object expected");
                message.stickers[i] = $root.Sticker.fromObject(object.stickers[i]);
            }
        }
        return message;
    };

    StickerList.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.stickers = [];
        if (message.stickers && message.stickers.length) {
            object.stickers = [];
            for (let j = 0; j < message.stickers.length; ++j)
                object.stickers[j] = $root.Sticker.toObject(message.stickers[j], options);
        }
        return object;
    };

    StickerList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerList";
    };

    return StickerList;
})();

export const StickerVoteOption = $root.StickerVoteOption = (() => {

    function StickerVoteOption(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerVoteOption.prototype.id = "";
    StickerVoteOption.prototype.title = "";
    StickerVoteOption.prototype.numberOfVoters = 0;
    StickerVoteOption.prototype.lastVotedOrMyAccount = null;

    StickerVoteOption.create = function create(properties) {
        return new StickerVoteOption(properties);
    };

    StickerVoteOption.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(10).string(message.id);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(18).string(message.title);
        if (message.numberOfVoters != null && Object.hasOwnProperty.call(message, "numberOfVoters"))
            writer.uint32(29).sfixed32(message.numberOfVoters);
        if (message.lastVotedOrMyAccount != null && Object.hasOwnProperty.call(message, "lastVotedOrMyAccount"))
            $root.AccountInfo.encode(message.lastVotedOrMyAccount, writer.uint32(34).fork()).ldelim();
        return writer;
    };

    StickerVoteOption.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerVoteOption.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerVoteOption();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.title = reader.string();
                    break;
                }
            case 3: {
                    message.numberOfVoters = reader.sfixed32();
                    break;
                }
            case 4: {
                    message.lastVotedOrMyAccount = $root.AccountInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StickerVoteOption.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerVoteOption.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerVoteOption)
            return object;
        let message = new $root.StickerVoteOption();
        if (object.id != null)
            message.id = String(object.id);
        if (object.title != null)
            message.title = String(object.title);
        if (object.numberOfVoters != null)
            message.numberOfVoters = object.numberOfVoters | 0;
        if (object.lastVotedOrMyAccount != null) {
            if (typeof object.lastVotedOrMyAccount !== "object")
                throw TypeError(".StickerVoteOption.lastVotedOrMyAccount: object expected");
            message.lastVotedOrMyAccount = $root.AccountInfo.fromObject(object.lastVotedOrMyAccount);
        }
        return message;
    };

    StickerVoteOption.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.title = "";
            object.numberOfVoters = 0;
            object.lastVotedOrMyAccount = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.numberOfVoters != null && message.hasOwnProperty("numberOfVoters"))
            object.numberOfVoters = message.numberOfVoters;
        if (message.lastVotedOrMyAccount != null && message.hasOwnProperty("lastVotedOrMyAccount"))
            object.lastVotedOrMyAccount = $root.AccountInfo.toObject(message.lastVotedOrMyAccount, options);
        return object;
    };

    StickerVoteOption.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerVoteOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerVoteOption";
    };

    return StickerVoteOption;
})();

export const StickerVotePayload = $root.StickerVotePayload = (() => {

    function StickerVotePayload(properties) {
        this.options = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerVotePayload.prototype.choiceLimit = 0;
    StickerVotePayload.prototype.options = $util.emptyArray;

    StickerVotePayload.create = function create(properties) {
        return new StickerVotePayload(properties);
    };

    StickerVotePayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.choiceLimit != null && Object.hasOwnProperty.call(message, "choiceLimit"))
            writer.uint32(13).sfixed32(message.choiceLimit);
        if (message.options != null && message.options.length)
            for (let i = 0; i < message.options.length; ++i)
                $root.StickerVoteOption.encode(message.options[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    StickerVotePayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerVotePayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerVotePayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.choiceLimit = reader.sfixed32();
                    break;
                }
            case 2: {
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.StickerVoteOption.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StickerVotePayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerVotePayload.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerVotePayload)
            return object;
        let message = new $root.StickerVotePayload();
        if (object.choiceLimit != null)
            message.choiceLimit = object.choiceLimit | 0;
        if (object.options) {
            if (!Array.isArray(object.options))
                throw TypeError(".StickerVotePayload.options: array expected");
            message.options = [];
            for (let i = 0; i < object.options.length; ++i) {
                if (typeof object.options[i] !== "object")
                    throw TypeError(".StickerVotePayload.options: object expected");
                message.options[i] = $root.StickerVoteOption.fromObject(object.options[i]);
            }
        }
        return message;
    };

    StickerVotePayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.options = [];
        if (options.defaults)
            object.choiceLimit = 0;
        if (message.choiceLimit != null && message.hasOwnProperty("choiceLimit"))
            object.choiceLimit = message.choiceLimit;
        if (message.options && message.options.length) {
            object.options = [];
            for (let j = 0; j < message.options.length; ++j)
                object.options[j] = $root.StickerVoteOption.toObject(message.options[j], options);
        }
        return object;
    };

    StickerVotePayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerVotePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerVotePayload";
    };

    return StickerVotePayload;
})();

export const StickerVoter = $root.StickerVoter = (() => {

    function StickerVoter(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerVoter.prototype.account = null;
    StickerVoter.prototype.coinsSpent = 0;
    StickerVoter.prototype.voteOptionId = "";

    StickerVoter.create = function create(properties) {
        return new StickerVoter(properties);
    };

    StickerVoter.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.account != null && Object.hasOwnProperty.call(message, "account"))
            $root.AccountInfo.encode(message.account, writer.uint32(10).fork()).ldelim();
        if (message.coinsSpent != null && Object.hasOwnProperty.call(message, "coinsSpent"))
            writer.uint32(21).sfixed32(message.coinsSpent);
        if (message.voteOptionId != null && Object.hasOwnProperty.call(message, "voteOptionId"))
            writer.uint32(26).string(message.voteOptionId);
        return writer;
    };

    StickerVoter.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerVoter.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerVoter();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.account = $root.AccountInfo.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.coinsSpent = reader.sfixed32();
                    break;
                }
            case 3: {
                    message.voteOptionId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StickerVoter.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerVoter.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerVoter)
            return object;
        let message = new $root.StickerVoter();
        if (object.account != null) {
            if (typeof object.account !== "object")
                throw TypeError(".StickerVoter.account: object expected");
            message.account = $root.AccountInfo.fromObject(object.account);
        }
        if (object.coinsSpent != null)
            message.coinsSpent = object.coinsSpent | 0;
        if (object.voteOptionId != null)
            message.voteOptionId = String(object.voteOptionId);
        return message;
    };

    StickerVoter.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.account = null;
            object.coinsSpent = 0;
            object.voteOptionId = "";
        }
        if (message.account != null && message.hasOwnProperty("account"))
            object.account = $root.AccountInfo.toObject(message.account, options);
        if (message.coinsSpent != null && message.hasOwnProperty("coinsSpent"))
            object.coinsSpent = message.coinsSpent;
        if (message.voteOptionId != null && message.hasOwnProperty("voteOptionId"))
            object.voteOptionId = message.voteOptionId;
        return object;
    };

    StickerVoter.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerVoter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerVoter";
    };

    return StickerVoter;
})();

export const StickerVoterList = $root.StickerVoterList = (() => {

    function StickerVoterList(properties) {
        this.voters = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StickerVoterList.prototype.voters = $util.emptyArray;

    StickerVoterList.create = function create(properties) {
        return new StickerVoterList(properties);
    };

    StickerVoterList.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.voters != null && message.voters.length)
            for (let i = 0; i < message.voters.length; ++i)
                $root.StickerVoter.encode(message.voters[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    StickerVoterList.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StickerVoterList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StickerVoterList();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.voters && message.voters.length))
                        message.voters = [];
                    message.voters.push($root.StickerVoter.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StickerVoterList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StickerVoterList.fromObject = function fromObject(object) {
        if (object instanceof $root.StickerVoterList)
            return object;
        let message = new $root.StickerVoterList();
        if (object.voters) {
            if (!Array.isArray(object.voters))
                throw TypeError(".StickerVoterList.voters: array expected");
            message.voters = [];
            for (let i = 0; i < object.voters.length; ++i) {
                if (typeof object.voters[i] !== "object")
                    throw TypeError(".StickerVoterList.voters: object expected");
                message.voters[i] = $root.StickerVoter.fromObject(object.voters[i]);
            }
        }
        return message;
    };

    StickerVoterList.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.voters = [];
        if (message.voters && message.voters.length) {
            object.voters = [];
            for (let j = 0; j < message.voters.length; ++j)
                object.voters[j] = $root.StickerVoter.toObject(message.voters[j], options);
        }
        return object;
    };

    StickerVoterList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StickerVoterList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StickerVoterList";
    };

    return StickerVoterList;
})();

export const StickerType = $root.StickerType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "UNKNOWN_TYPE"] = 1;
    values[valuesById[2] = "GIFT"] = 2;
    values[valuesById[3] = "IMAGE"] = 3;
    values[valuesById[4] = "VOTE"] = 4;
    values[valuesById[5] = "GOAL"] = 5;
    values[valuesById[6] = "WHEEL"] = 6;
    values[valuesById[7] = "LUCKY_WHEEL"] = 7;
    return values;
})();

export const StickerStatus = $root.StickerStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "UNKNOWN_STATUS"] = 1;
    values[valuesById[2] = "ACTIVE"] = 2;
    values[valuesById[3] = "PENDING_MODERATION"] = 3;
    values[valuesById[4] = "MODERATION_FAILED"] = 4;
    return values;
})();

export { $root as default };
