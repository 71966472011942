import React, { FC } from "react";
import { useIntl } from "react-intl";
import { FormattedDate } from "chat/components/FormattedDate/FormattedDate";
import { MessageDateNow } from "chat/components/MessageDateNow/MessageDateNow";
import { sharedMessages } from "chat/imports/utils";
import capitalize from "chat/utils/capitalize";

const DATE_FORMAT_MAX_LENGTH = 8;

interface DateProps {
  value: number;
}

export const LastMessageDatePreview: FC<DateProps> = ({ value }) => {
  const intl = useIntl();
  const date = new Date(value);

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const dateTimestamp = new Date(date.setHours(0, 0, 0, 0)).getTime();

  if (dateTimestamp === now.getTime()) {
    return <MessageDateNow value={value} />;
  } else if (dateTimestamp === yesterday.getTime()) {
    const yesterday = intl.formatMessage(sharedMessages.yesterday);

    return <>{capitalize(yesterday)}</>;
  } else if (dateTimestamp > oneWeekAgo.getTime()) {
    const weekday = intl.formatDate(value, { weekday: "long" });

    return <>{capitalize(weekday)}</>;
  }

  const formattedDate = intl.formatDate(value, {
    day: "numeric",
    month: "long",
  });

  return (
    <FormattedDate
      value={value}
      day="numeric"
      month={formattedDate.length > DATE_FORMAT_MAX_LENGTH ? "short" : "long"}
    />
  );
};
