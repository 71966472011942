import { createReducer } from "@reduxjs/toolkit";
import { LiveFeedType } from "src/enums";
import { loadTabsConfig } from "state/actionCreators/tabsConfig";
import {
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import { TabsConfigState } from "state/types";

export const persistConfig = { whitelist: ["data"] };

export const initialState = {
  data: [
    // current prod config
    {
      tag: LiveFeedType.NEARBY,
      title: "Nearby",
      iconUrl: "https://resources.tango.me/tab/nearby.png",
      color: "#66B0DA",
    },
    {
      tag: LiveFeedType.POPULAR,
      title: "Popular",
      iconUrl: "https://resources.tango.me/tab/popular.png",
      color: "#E96E84",
    },
    {
      tag: LiveFeedType.NEW,
      title: "New",
      iconUrl: "https://resources.tango.me/tab/new.png",
      color: "#9FD060",
    },
  ],
  meta: initialFetcherStateMeta,
};

const reducer = createReducer<TabsConfigState>(initialState, (builder) => {
  addAsyncCasesToBuilderV2({
    builder,
    action: loadTabsConfig,
    prepareData: (oldData, newData) => newData,
    initialData: initialState.data,
  });
});

export default reducer;
