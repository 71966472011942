import {
  AUTHORIZATION_END,
  LOGIN_END,
} from "src/features/signin/exports/state/actionTypes";
import {
  ACME_RECEIVED,
  CONNECTION_MANAGER_CHANGE_USERNAME,
  CONNECTION_MANAGER_CONNECTED,
  CONNECTION_MANAGER_DISCONNECTED,
  CONNECTION_MANAGER_RECONNECT,
} from "state/actionTypes";
import withUserSessionScope from "state/hor/withUserSessionScope";

const emptyConnectionManagerData = {
  username: "",
  hostname: "",
  deviceToken: "",
  port: 0,
};

const defaultState = {
  ...emptyConnectionManagerData,
  connected: false,
  reconnectRequested: false,
  acmeWorking: false,
};

export const persistConfig = {
  blacklist: ["connected", "reconnectRequested", "acmeWorking"],
};

export const selectors = {
  isConnected: (state) => state.connected,
  isReconnectRequested: (state) => state.reconnectRequested,
  hasConnectionManagerData: (state) =>
    !!state.username && !!state.port && !!state.hostname,
  getUsername: (state) => state.username,
  getDeviceToken: (state) => state.deviceToken,
  getPort: (state) => state.port,
  getHostname: (state) => state.hostname,
  isAcmeWorking: (state) => state.acmeWorking,
};

export default withUserSessionScope((state = defaultState, action) => {
  switch (action.type) {
    case AUTHORIZATION_END:
    case LOGIN_END: {
      if (action.error) {
        return state;
      }
      const { connectionManagerData } = action.payload;

      return { ...state, ...connectionManagerData };
    }
    case CONNECTION_MANAGER_CHANGE_USERNAME: {
      return { ...state, username: action.payload };
    }
    case CONNECTION_MANAGER_CONNECTED: {
      return { ...state, reconnectRequested: false, connected: true };
    }
    case CONNECTION_MANAGER_DISCONNECTED: {
      return {
        ...state,
        reconnectRequested: false,
        connected: false,
        acmeWorking: false,
      };
    }
    case CONNECTION_MANAGER_RECONNECT: {
      return {
        ...state,
        reconnectRequested: true,
      };
    }
    case ACME_RECEIVED: {
      if (state.acmeWorking) {
        return state;
      }

      return { ...state, acmeWorking: true };
    }
  }

  return state;
});
