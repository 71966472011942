import invariant from "fbjs/lib/invariant";
import { ACME_RECEIVED } from "state/actionTypes";

const callbacksRegistry = {};

const optListenersQueue = (key, action) => {
  const queue = callbacksRegistry[key];
  queue && action(queue);
};

const getListenersQueue = (key) => {
  let listenersQueue = callbacksRegistry[key];
  if (!listenersQueue) {
    listenersQueue = [];
    callbacksRegistry[key] = listenersQueue;
  }
  return listenersQueue;
};

const makeCallbackKey = ({ serviceName, serviceIdentifier }) =>
  `${serviceName}${serviceIdentifier}`;

export const awaitForAcme = (acmeData, timeoutMillis) =>
  new Promise((resolve, reject) => {
    const callbackKey = makeCallbackKey(acmeData);
    let isResolved = false;
    let isRejected = false;
    let timeoutHandle = null;

    const ensureFirstCallback = () => {
      invariant(
        !isResolved,
        "Observer must be cleaned up if we resolve things"
      );
      invariant(!isRejected, "Observer must be cleaned up if we reject things");
    };

    const callback = () => {
      ensureFirstCallback();
      isResolved = true;
      clearTimeout(timeoutHandle);
      resolve();
    };

    timeoutHandle = setTimeout(() => {
      ensureFirstCallback();
      isRejected = true;

      optListenersQueue(callbackKey, (listenersQueue) => {
        const indexOfCallback = listenersQueue.indexOf(callback);
        listenersQueue.splice(indexOfCallback, 1);
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      reject();
    }, timeoutMillis);

    getListenersQueue(callbackKey).push(callback);
  });

export default () => (next) => (action) => {
  const ret = next(action);
  if (action.type === ACME_RECEIVED) {
    const callbackKey = makeCallbackKey(action.payload);
    optListenersQueue(callbackKey, (listenersQueue) => {
      listenersQueue.forEach((x) => x());
      delete callbacksRegistry[callbackKey];
    });
  }
  return ret;
};
