import {
  UPDATE_LIVE_STREAM_INFO,
  UPDATE_LIVE_STREAM_SETTINGS,
  UPDATE_LIVE_STREAM_BATCH_STATUSES,
} from "state/actionTypes";

export const updatedLiveStreamInfo = ({ streamId, info }) => ({
  type: UPDATE_LIVE_STREAM_INFO,
  payload: info,
  meta: { streamId },
});

export const updatedLiveStreamSettings = ({ streamId, settings }) => ({
  type: UPDATE_LIVE_STREAM_SETTINGS,
  payload: settings,
  meta: { streamId },
});

export const updatedBatchStreamStatuses = (
  streamIdToStatusMap,
  streamsInfo
) => ({
  type: UPDATE_LIVE_STREAM_BATCH_STATUSES,
  payload: streamIdToStatusMap,
  meta: { streamsInfo },
});
