import React from "react";
import useQueryToPatchEnvironment from "./hooks/useQueryToPatchEnvironment";

const LpSoundTestController: React.FC = () => {
  useQueryToPatchEnvironment({
    propertyKey: "lp.sound.enable",
    queryMap: {
      enableLpSound: "true",
      disableLpSound: "false",
      resetLpSound: "",
    },
  });
  return null;
};

export default LpSoundTestController;
