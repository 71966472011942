/* eslint-disable react/jsx-no-bind */
import React, { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MediaPreview from "chat/components/mediaPreview/MediaPreview";
import { useRefreshGifts } from "chat/components/sendMediaView/useRefreshGifts";
import {
  RootState,
  giftsCacheActionCreators as actionCreators,
  giftsCacheSelectors,
  userSelectors,
} from "chat/imports/state";
import { VoidCallback } from "chat/imports/types";
import { logger } from "chat/imports/utils";
import { ContentType } from "chat/premiumMessage/types";
import PremiumMessageGifts from "chat/premiumMessage/ui/components/premiumMessageGifts/PremiumMessageGifts";
import { sendMediaMessage } from "chat/state/flows";
import { SendMediaData } from "chat/types";
import { convertToJpeg } from "chat/utils/convertToJpeg";

const IMAGE_EXTENSIONS = [".heic", ".heif"];

type SendMediaViewProps = {
  data: SendMediaData;
  onDismiss: VoidCallback;
};

const defaultGiftsSelector = (state: RootState) => {
  const defaultGiftIds =
    giftsCacheSelectors.getDefaultPremiumMessageGiftIds(state);

  return giftsCacheSelectors.getGiftsByIds(state, defaultGiftIds);
};

const getMediaType = (file: File) =>
  file.type.startsWith("image") ? ContentType.PHOTO : ContentType.VIDEO;

const SendMediaView: FC<SendMediaViewProps> = ({
  data: { file, conversationId, analyticsParams },
  onDismiss,
}) => {
  useRefreshGifts();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const defaultGifts = useSelector(defaultGiftsSelector, shallowEqual);
  const accountId = useSelector(userSelectors.getMyAccountId);
  const [caption, setCaption] = useState("");
  const [selectedGiftId, setSelectedGiftId] = useState("");
  const [showAllGifts, setShowAllGifts] = useState(false);
  const isConversionNeeded = IMAGE_EXTENSIONS.some((extension) =>
    file.name.endsWith(extension)
  );
  const [mediaFile, setMediaFile] = useState(() =>
    isConversionNeeded ? null : file
  );
  const mediaPreviewUrl = useMemo(
    () => (mediaFile ? URL.createObjectURL(mediaFile) : ""),
    [mediaFile]
  );
  const mediaType = getMediaType(file);

  useEffect(() => {
    if (isConversionNeeded) {
      convertToJpeg(file)
        .then(setMediaFile)
        .catch((err) => logger.error(err.message));
    }
  }, [file, isConversionNeeded]);

  useEffect(
    () => () => URL.revokeObjectURL(mediaPreviewUrl),
    [mediaPreviewUrl]
  );

  const handleSend = async () => {
    if (mediaFile) {
      dispatch(
        sendMediaMessage({
          conversationId,
          mediaFile,
          mediaType,
          mediaUrl: mediaPreviewUrl,
          caption: caption.trim(),
          from: accountId,
          formatMessage,
          giftId: selectedGiftId,
          analyticsParams,
        })
      );
      onDismiss();
    }
  };

  const handleChangeCaption = (caption: string) => {
    setCaption(caption);
  };

  const handleSelectGift = (giftId: string) => {
    setSelectedGiftId((id) => (giftId === id ? "" : giftId));
  };

  const handleSelectAndSaveGift = (giftId: string) => {
    dispatch(actionCreators.selectPremiumMessageGift(giftId));
    setSelectedGiftId(giftId);
    setShowAllGifts(false);
  };

  if (showAllGifts) {
    return (
      <PremiumMessageGifts
        onClickBack={() => setShowAllGifts(false)}
        onSelectGift={handleSelectAndSaveGift}
      />
    );
  }

  return (
    <MediaPreview
      gifts={defaultGifts}
      mediaUrl={mediaPreviewUrl}
      mediaType={mediaType}
      caption={caption}
      selectedGiftId={selectedGiftId}
      onSelectGift={handleSelectGift}
      onSend={handleSend}
      onChangeCaption={handleChangeCaption}
      onClose={onDismiss}
      onClickMore={() => setShowAllGifts(true)}
    />
  );
};

export default SendMediaView;
