import { fetchCustomDrawer } from "api/gifts";
import { customDrawerSelectors, loginSelectors } from "state/selectors";
import { actionCreators as customDrawerActions } from "state/tree/customDrawer";

export const CUSTOM_DRAWER_STATUSES = {
  FAILED: "FAILED",
  UPDATED: "UPDATED",
  NOT_EXISTS: "NOT_EXISTS",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  OK: "OK",
};

const loadCustomDrawer = async (drawerId, drawerVersion, dispatch) => {
  dispatch(customDrawerActions.fetchBegan());
  try {
    const response = await fetchCustomDrawer({
      drawerId,
      version: drawerVersion,
    });
    switch (response.status) {
      case CUSTOM_DRAWER_STATUSES.OK:
        dispatch(
          customDrawerActions.fetchCompleted({
            data: {},
            replaceAll: false,
          })
        );
        break;
      case CUSTOM_DRAWER_STATUSES.UPDATED:
        dispatch(
          customDrawerActions.fetchCompleted({
            data: response.giftDrawer,
            replaceAll: true,
          })
        );
        break;
      case CUSTOM_DRAWER_STATUSES.UNAUTHENTICATED:
      case CUSTOM_DRAWER_STATUSES.FAILED:
      case CUSTOM_DRAWER_STATUSES.NOT_EXISTS:
        throw new Error(response.status);
      default:
        throw new Error("No status provided");
    }
  } catch (error) {
    dispatch(customDrawerActions.fetchFailed({ error, removeAll: true }));
  }
};

export default () => async (dispatch, getState) => {
  const state = getState();
  const isLoadingCustomDrawer =
    customDrawerSelectors.getIsLoadInProgress(state);
  const isLoggedIn = loginSelectors.isLoggedIn(state);
  if (!isLoadingCustomDrawer && isLoggedIn) {
    const drawerId = customDrawerSelectors.getDrawerId(state);
    const customDrawerVersion = customDrawerSelectors.getDrawerVersion(state);
    await loadCustomDrawer(drawerId, customDrawerVersion, dispatch);
  }
};
