import { OneClickOfferTypes } from "enums/oneClickOffers";
import { Nullable } from "src/types/common";

interface GetOneClickOfferTypeParams {
  giftingLocation: Nullable<OneClickOfferTypes>;
  isOneClickV2PurchaseEnabled: boolean;
  offerType: OneClickOfferTypes | undefined;
}

const getOneClickOfferType = ({
  isOneClickV2PurchaseEnabled,
  offerType,
  giftingLocation,
}: GetOneClickOfferTypeParams): OneClickOfferTypes | undefined => {
  if (isOneClickV2PurchaseEnabled) {
    if (offerType) {
      return offerType;
    }

    if (giftingLocation) {
      return giftingLocation;
    }
  }

  return undefined;
};

export default getOneClickOfferType;
