import { Ref } from "react";
import { LinkProps } from "react-router-dom";

export type LinkPropsWithInnerRef = {
  innerRef?: Ref<HTMLAnchorElement>;
} & LinkProps;

export default <
  T extends { to?: undefined },
  U extends { innerRef?: Ref<HTMLAnchorElement> } & LinkProps,
>(
  props: T | U
): props is T => props.to === undefined;
