import {
  TutorialName,
  TutorialState,
  TutorialsState,
} from "src/features/tutorials/shared/state/types";

export const localTutorialsSelectors = {
  getObsClickToInviteTutorialCompleted: (state: TutorialsState) =>
    state[TutorialName.OBS_CLICK_TO_INVITE] === TutorialState.COMPLETED,
  getStickersClickTutorialCompleted: (state: TutorialsState) =>
    state[TutorialName.STICKERS_CLICK] === TutorialState.COMPLETED,
  getStreamsSwipesTutorialCompleted: (state: TutorialsState) =>
    state[TutorialName.STREAMS_SWIPES] === TutorialState.COMPLETED,
  getStreamsSwipesTutorialStarted: (state: TutorialsState) =>
    state[TutorialName.STREAMS_SWIPES] === TutorialState.STARTED,
  getWebToAppClickToDownloadTutorialCompleted: (state: TutorialsState) =>
    state[TutorialName.WEB_TO_APP_CLICK_TO_DOWNLOAD] ===
    TutorialState.COMPLETED,
};
