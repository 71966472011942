let jobQueue = [];

export const enqueuePendingAction = ({ check, actionCreator }) => {
  jobQueue.push({ check, actionCreator });
};

export default (store) => (next) => (action) => {
  const oldState = store.getState();
  const ret = next(action);
  const newState = store.getState();
  if (jobQueue.length) {
    const query = { oldState, newState, action };
    jobQueue = jobQueue.filter((x) => {
      if (x.check(query)) {
        store.dispatch(x.actionCreator());
        return false;
      }
      return true;
    });
  }
  return ret;
};
