import {
  fetchLockedState,
  sendTokenAndPhoneForValidation,
  sendTokenForValidation,
} from "api/captcha";
import { setLockedState } from "state/actionCreators/captcha";
import { genericNetworkError } from "state/actionCreators/networkError";
import { captchaSelectors, loginSelectors } from "state/selectors";
import { CaptchaType } from "ui/modal/modalViews/captcha/enum";

export const refreshLockedState = () => async (dispatch) => {
  try {
    const isLocked = await fetchLockedState();
    dispatch(setLockedState(isLocked));
  } catch (error) {
    dispatch(genericNetworkError(error));
  }
};

const makeRequest = async (token, state) => {
  const { type, ...rest } = captchaSelectors.getCaptchaConfig(state);
  const isAuthorized = loginSelectors.isAuthorized(state);

  if (type === CaptchaType.PHONE) {
    return await sendTokenAndPhoneForValidation({
      isAuthorized,
      token,
      ...rest,
    });
  }

  return await sendTokenForValidation({ token });
};

export const validateCaptchaResult = (token) => async (dispatch, getState) => {
  try {
    const success = await makeRequest(token, getState());
    dispatch(setLockedState(!success));
  } catch (error) {
    dispatch(genericNetworkError(error));
  }
};
