import { getProxycadorHost } from "environment";
import fetch from "./utils/enhancedFetch";

const parse = (promise: Promise<Response>) =>
  promise.then((resp) => resp.text()).then((x: string) => !!JSON.parse(x));

export const sendTokenForValidation = ({ token }: { token: string }) =>
  parse(
    fetch(
      `${getProxycadorHost()}/proxycador/api/public/v1/captcha/recaptcha/validateV2`,
      {
        method: "POST",
        body: token,
      }
    )
  );

export const sendTokenAndPhoneForValidation = ({
  token,
  isAuthorized,
  phoneNumber,
  fingerprint,
}: {
  fingerprint: string;
  isAuthorized: boolean;
  phoneNumber: string;
  token: string;
}) => {
  const url = isAuthorized
    ? `${getProxycadorHost()}/proxycador/api/public/v1/captcha/recaptcha/sms/validateV3`
    : `${getProxycadorHost()}/proxycador/api/visitors/captcha/v1/recaptcha/sms/validateV3`;

  return parse(
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        secret: btoa(`${phoneNumber}___${fingerprint}`),
      }),
    })
  );
};

export const fetchLockedState = () =>
  parse(
    fetch(`${getProxycadorHost()}/proxycador/api/public/v1/captcha/locked`)
  );
