/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.ExternalMessage || ($protobuf.roots.ExternalMessage = {});

export const ExternalActionPlatformType = $root.ExternalActionPlatformType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "EXTERNAL_ACTION_PLATFORM_ANY"] = 0;
    values[valuesById[1] = "EXTERNAL_ACTION_PLATFORM_IOS"] = 1;
    values[valuesById[2] = "EXTERNAL_ACTION_PLATFORM_ANDROID"] = 2;
    return values;
})();

export const ExternalActionInfo = $root.ExternalActionInfo = (() => {

    function ExternalActionInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ExternalActionInfo.prototype.platform = 0;
    ExternalActionInfo.prototype.actionUrl = "";
    ExternalActionInfo.prototype.installUrl = "";
    ExternalActionInfo.prototype.actionText = "";
    ExternalActionInfo.prototype.actionUrlMimeType = "";
    ExternalActionInfo.prototype.appLaunchUrl = "";
    ExternalActionInfo.prototype.appLaunchPrompt = "";

    ExternalActionInfo.create = function create(properties) {
        return new ExternalActionInfo(properties);
    };

    ExternalActionInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
            writer.uint32(8).int32(message.platform);
        if (message.actionUrl != null && Object.hasOwnProperty.call(message, "actionUrl"))
            writer.uint32(18).string(message.actionUrl);
        if (message.installUrl != null && Object.hasOwnProperty.call(message, "installUrl"))
            writer.uint32(26).string(message.installUrl);
        if (message.actionText != null && Object.hasOwnProperty.call(message, "actionText"))
            writer.uint32(34).string(message.actionText);
        if (message.actionUrlMimeType != null && Object.hasOwnProperty.call(message, "actionUrlMimeType"))
            writer.uint32(42).string(message.actionUrlMimeType);
        if (message.appLaunchUrl != null && Object.hasOwnProperty.call(message, "appLaunchUrl"))
            writer.uint32(50).string(message.appLaunchUrl);
        if (message.appLaunchPrompt != null && Object.hasOwnProperty.call(message, "appLaunchPrompt"))
            writer.uint32(58).string(message.appLaunchPrompt);
        return writer;
    };

    ExternalActionInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ExternalActionInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExternalActionInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.platform = reader.int32();
                    break;
                }
            case 2: {
                    message.actionUrl = reader.string();
                    break;
                }
            case 3: {
                    message.installUrl = reader.string();
                    break;
                }
            case 4: {
                    message.actionText = reader.string();
                    break;
                }
            case 5: {
                    message.actionUrlMimeType = reader.string();
                    break;
                }
            case 6: {
                    message.appLaunchUrl = reader.string();
                    break;
                }
            case 7: {
                    message.appLaunchPrompt = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ExternalActionInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ExternalActionInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.ExternalActionInfo)
            return object;
        let message = new $root.ExternalActionInfo();
        switch (object.platform) {
        default:
            if (typeof object.platform === "number") {
                message.platform = object.platform;
                break;
            }
            break;
        case "EXTERNAL_ACTION_PLATFORM_ANY":
        case 0:
            message.platform = 0;
            break;
        case "EXTERNAL_ACTION_PLATFORM_IOS":
        case 1:
            message.platform = 1;
            break;
        case "EXTERNAL_ACTION_PLATFORM_ANDROID":
        case 2:
            message.platform = 2;
            break;
        }
        if (object.actionUrl != null)
            message.actionUrl = String(object.actionUrl);
        if (object.installUrl != null)
            message.installUrl = String(object.installUrl);
        if (object.actionText != null)
            message.actionText = String(object.actionText);
        if (object.actionUrlMimeType != null)
            message.actionUrlMimeType = String(object.actionUrlMimeType);
        if (object.appLaunchUrl != null)
            message.appLaunchUrl = String(object.appLaunchUrl);
        if (object.appLaunchPrompt != null)
            message.appLaunchPrompt = String(object.appLaunchPrompt);
        return message;
    };

    ExternalActionInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.platform = options.enums === String ? "EXTERNAL_ACTION_PLATFORM_ANY" : 0;
            object.actionUrl = "";
            object.installUrl = "";
            object.actionText = "";
            object.actionUrlMimeType = "";
            object.appLaunchUrl = "";
            object.appLaunchPrompt = "";
        }
        if (message.platform != null && message.hasOwnProperty("platform"))
            object.platform = options.enums === String ? $root.ExternalActionPlatformType[message.platform] === undefined ? message.platform : $root.ExternalActionPlatformType[message.platform] : message.platform;
        if (message.actionUrl != null && message.hasOwnProperty("actionUrl"))
            object.actionUrl = message.actionUrl;
        if (message.installUrl != null && message.hasOwnProperty("installUrl"))
            object.installUrl = message.installUrl;
        if (message.actionText != null && message.hasOwnProperty("actionText"))
            object.actionText = message.actionText;
        if (message.actionUrlMimeType != null && message.hasOwnProperty("actionUrlMimeType"))
            object.actionUrlMimeType = message.actionUrlMimeType;
        if (message.appLaunchUrl != null && message.hasOwnProperty("appLaunchUrl"))
            object.appLaunchUrl = message.appLaunchUrl;
        if (message.appLaunchPrompt != null && message.hasOwnProperty("appLaunchPrompt"))
            object.appLaunchPrompt = message.appLaunchPrompt;
        return object;
    };

    ExternalActionInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ExternalActionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ExternalActionInfo";
    };

    return ExternalActionInfo;
})();

export const ExternalMessageInfo = $root.ExternalMessageInfo = (() => {

    function ExternalMessageInfo(properties) {
        this.actionInfo = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ExternalMessageInfo.prototype.messageText = "";
    ExternalMessageInfo.prototype.previewThumbnailUrl = "";
    ExternalMessageInfo.prototype.actionInfo = $util.emptyArray;
    ExternalMessageInfo.prototype.appId = "";
    ExternalMessageInfo.prototype.sdkSessionId = "";
    ExternalMessageInfo.prototype.userData = "";
    ExternalMessageInfo.prototype.isForwardable = false;
    ExternalMessageInfo.prototype.customThumbnailWidth = 0;
    ExternalMessageInfo.prototype.customThumbnailHeight = 0;

    ExternalMessageInfo.create = function create(properties) {
        return new ExternalMessageInfo(properties);
    };

    ExternalMessageInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.messageText != null && Object.hasOwnProperty.call(message, "messageText"))
            writer.uint32(10).string(message.messageText);
        if (message.previewThumbnailUrl != null && Object.hasOwnProperty.call(message, "previewThumbnailUrl"))
            writer.uint32(18).string(message.previewThumbnailUrl);
        if (message.actionInfo != null && message.actionInfo.length)
            for (let i = 0; i < message.actionInfo.length; ++i)
                $root.ExternalActionInfo.encode(message.actionInfo[i], writer.uint32(26).fork()).ldelim();
        if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
            writer.uint32(34).string(message.appId);
        if (message.sdkSessionId != null && Object.hasOwnProperty.call(message, "sdkSessionId"))
            writer.uint32(42).string(message.sdkSessionId);
        if (message.userData != null && Object.hasOwnProperty.call(message, "userData"))
            writer.uint32(50).string(message.userData);
        if (message.isForwardable != null && Object.hasOwnProperty.call(message, "isForwardable"))
            writer.uint32(56).bool(message.isForwardable);
        if (message.customThumbnailWidth != null && Object.hasOwnProperty.call(message, "customThumbnailWidth"))
            writer.uint32(64).int32(message.customThumbnailWidth);
        if (message.customThumbnailHeight != null && Object.hasOwnProperty.call(message, "customThumbnailHeight"))
            writer.uint32(72).int32(message.customThumbnailHeight);
        return writer;
    };

    ExternalMessageInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ExternalMessageInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExternalMessageInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.messageText = reader.string();
                    break;
                }
            case 2: {
                    message.previewThumbnailUrl = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.actionInfo && message.actionInfo.length))
                        message.actionInfo = [];
                    message.actionInfo.push($root.ExternalActionInfo.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.appId = reader.string();
                    break;
                }
            case 5: {
                    message.sdkSessionId = reader.string();
                    break;
                }
            case 6: {
                    message.userData = reader.string();
                    break;
                }
            case 7: {
                    message.isForwardable = reader.bool();
                    break;
                }
            case 8: {
                    message.customThumbnailWidth = reader.int32();
                    break;
                }
            case 9: {
                    message.customThumbnailHeight = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ExternalMessageInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ExternalMessageInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.ExternalMessageInfo)
            return object;
        let message = new $root.ExternalMessageInfo();
        if (object.messageText != null)
            message.messageText = String(object.messageText);
        if (object.previewThumbnailUrl != null)
            message.previewThumbnailUrl = String(object.previewThumbnailUrl);
        if (object.actionInfo) {
            if (!Array.isArray(object.actionInfo))
                throw TypeError(".ExternalMessageInfo.actionInfo: array expected");
            message.actionInfo = [];
            for (let i = 0; i < object.actionInfo.length; ++i) {
                if (typeof object.actionInfo[i] !== "object")
                    throw TypeError(".ExternalMessageInfo.actionInfo: object expected");
                message.actionInfo[i] = $root.ExternalActionInfo.fromObject(object.actionInfo[i]);
            }
        }
        if (object.appId != null)
            message.appId = String(object.appId);
        if (object.sdkSessionId != null)
            message.sdkSessionId = String(object.sdkSessionId);
        if (object.userData != null)
            message.userData = String(object.userData);
        if (object.isForwardable != null)
            message.isForwardable = Boolean(object.isForwardable);
        if (object.customThumbnailWidth != null)
            message.customThumbnailWidth = object.customThumbnailWidth | 0;
        if (object.customThumbnailHeight != null)
            message.customThumbnailHeight = object.customThumbnailHeight | 0;
        return message;
    };

    ExternalMessageInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.actionInfo = [];
        if (options.defaults) {
            object.messageText = "";
            object.previewThumbnailUrl = "";
            object.appId = "";
            object.sdkSessionId = "";
            object.userData = "";
            object.isForwardable = false;
            object.customThumbnailWidth = 0;
            object.customThumbnailHeight = 0;
        }
        if (message.messageText != null && message.hasOwnProperty("messageText"))
            object.messageText = message.messageText;
        if (message.previewThumbnailUrl != null && message.hasOwnProperty("previewThumbnailUrl"))
            object.previewThumbnailUrl = message.previewThumbnailUrl;
        if (message.actionInfo && message.actionInfo.length) {
            object.actionInfo = [];
            for (let j = 0; j < message.actionInfo.length; ++j)
                object.actionInfo[j] = $root.ExternalActionInfo.toObject(message.actionInfo[j], options);
        }
        if (message.appId != null && message.hasOwnProperty("appId"))
            object.appId = message.appId;
        if (message.sdkSessionId != null && message.hasOwnProperty("sdkSessionId"))
            object.sdkSessionId = message.sdkSessionId;
        if (message.userData != null && message.hasOwnProperty("userData"))
            object.userData = message.userData;
        if (message.isForwardable != null && message.hasOwnProperty("isForwardable"))
            object.isForwardable = message.isForwardable;
        if (message.customThumbnailWidth != null && message.hasOwnProperty("customThumbnailWidth"))
            object.customThumbnailWidth = message.customThumbnailWidth;
        if (message.customThumbnailHeight != null && message.hasOwnProperty("customThumbnailHeight"))
            object.customThumbnailHeight = message.customThumbnailHeight;
        return object;
    };

    ExternalMessageInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ExternalMessageInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ExternalMessageInfo";
    };

    return ExternalMessageInfo;
})();

export { $root as default };
