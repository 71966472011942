/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.WebLinkPost || ($protobuf.roots.WebLinkPost = {});

export const WebLinkPost = $root.WebLinkPost = (() => {

    function WebLinkPost(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    WebLinkPost.prototype.caption = "";
    WebLinkPost.prototype.title = "";
    WebLinkPost.prototype.imageUrl = "";
    WebLinkPost.prototype.imageType = 0;
    WebLinkPost.prototype.pageUrl = "";
    WebLinkPost.prototype.siteName = "";

    WebLinkPost.create = function create(properties) {
        return new WebLinkPost(properties);
    };

    WebLinkPost.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
            writer.uint32(10).string(message.caption);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(18).string(message.title);
        if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
            writer.uint32(26).string(message.imageUrl);
        if (message.imageType != null && Object.hasOwnProperty.call(message, "imageType"))
            writer.uint32(37).sfixed32(message.imageType);
        if (message.pageUrl != null && Object.hasOwnProperty.call(message, "pageUrl"))
            writer.uint32(42).string(message.pageUrl);
        if (message.siteName != null && Object.hasOwnProperty.call(message, "siteName"))
            writer.uint32(50).string(message.siteName);
        return writer;
    };

    WebLinkPost.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    WebLinkPost.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebLinkPost();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.caption = reader.string();
                    break;
                }
            case 2: {
                    message.title = reader.string();
                    break;
                }
            case 3: {
                    message.imageUrl = reader.string();
                    break;
                }
            case 4: {
                    message.imageType = reader.sfixed32();
                    break;
                }
            case 5: {
                    message.pageUrl = reader.string();
                    break;
                }
            case 6: {
                    message.siteName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    WebLinkPost.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    WebLinkPost.fromObject = function fromObject(object) {
        if (object instanceof $root.WebLinkPost)
            return object;
        let message = new $root.WebLinkPost();
        if (object.caption != null)
            message.caption = String(object.caption);
        if (object.title != null)
            message.title = String(object.title);
        if (object.imageUrl != null)
            message.imageUrl = String(object.imageUrl);
        if (object.imageType != null)
            message.imageType = object.imageType | 0;
        if (object.pageUrl != null)
            message.pageUrl = String(object.pageUrl);
        if (object.siteName != null)
            message.siteName = String(object.siteName);
        return message;
    };

    WebLinkPost.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.caption = "";
            object.title = "";
            object.imageUrl = "";
            object.imageType = 0;
            object.pageUrl = "";
            object.siteName = "";
        }
        if (message.caption != null && message.hasOwnProperty("caption"))
            object.caption = message.caption;
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
            object.imageUrl = message.imageUrl;
        if (message.imageType != null && message.hasOwnProperty("imageType"))
            object.imageType = message.imageType;
        if (message.pageUrl != null && message.hasOwnProperty("pageUrl"))
            object.pageUrl = message.pageUrl;
        if (message.siteName != null && message.hasOwnProperty("siteName"))
            object.siteName = message.siteName;
        return object;
    };

    WebLinkPost.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    WebLinkPost.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WebLinkPost";
    };

    return WebLinkPost;
})();

export { $root as default };
