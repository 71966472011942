import { Store } from "redux";
import {
  hideBottomScreen,
  setAnimation,
} from "state/actionCreators/bottomScreen";
import { BOTTOM_SCREEN_HIDE_ANIMATION_DURATION } from "src/constants";
import { bottomScreenSelectors, modalSelectors } from "state/selectors";
import { dismissTopModal } from "state/actionCreators/modal";
import { BY_NAVIGATION } from "enums/modalDismissReason";
import { getIsInJosh } from "src/utils/joshUtils";
import { RootState } from "state/delegate";

const selector = (state: RootState) => ({
  isBottomScreenOpen: !!bottomScreenSelectors.screenType(state),
  onDismiss: bottomScreenSelectors.onDismiss(state),
  animation: bottomScreenSelectors.animation(state),
});
export default (store: Store) => {
  if (getIsInJosh()) {
    window.tangoCanGoBack = () => {
      const { isBottomScreenOpen, animation, onDismiss } = selector(
        store.getState()
      );
      if (isBottomScreenOpen) {
        // src/ui/bottomScreen/BottomScreenController.jsx
        if (!animation) {
          onDismiss?.();
          store.dispatch(setAnimation(true));
          setTimeout(() => {
            store.dispatch(hideBottomScreen());
          }, BOTTOM_SCREEN_HIDE_ANIMATION_DURATION);
        }

        return false;
      }

      const topModalType = modalSelectors.topModalType(store.getState());
      if (topModalType) {
        // @ts-ignore TODO:typescript
        store.dispatch(dismissTopModal(BY_NAVIGATION));
        return false;
      }

      return true;
    };
  }
};
