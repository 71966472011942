import React from "react";
import useQueryToPatchEnvironment from "./hooks/useQueryToPatchEnvironment";

const queryToLhlsStatus = {
  enableLhls: "true",
  disableLhls: "false",
  resetLhls: "",
};

const queryToHdStatus = {
  enableHd: "true",
  disableHd: "false",
  resetHd: "",
};

const VideoTestController: React.FC = () => {
  useQueryToPatchEnvironment({
    propertyKey: "lhls.enable",
    queryMap: queryToLhlsStatus,
  });
  useQueryToPatchEnvironment({
    propertyKey: "hd.video.enable",
    queryMap: queryToHdStatus,
  });
  return null;
};

export default VideoTestController;
