import { UAParser } from "ua-parser-js";

export default () => {
  const { userAgent } = window.navigator;
  const uaParser = new UAParser(userAgent);
  const os = uaParser.getOS();
  const browser = uaParser.getBrowser();

  return `${os.version} (${browser.name}-${browser.major})`;
};
