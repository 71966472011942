import { getFirebaseAppDeeplink } from "environment";
import { DeviceType } from "src/enums";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "src/constants";
import { DeeplinkQueryField, Target } from "ui/navigation/deepLink/types";

export enum AF_SMART_LINK_BUTTON_NAME {
  HOME_PAGE = "MW_Homepage_Install_Button",
  BURGER_MENU = "MW_Side_Menu_Install_Button",
  CHAT = "MW_Chat_Tab_Install_Button",
  FOLLOWING_TAB = "MW_Following_Tab_Install_Button",
  PROFILE = "MW_Profile_Install_Button",
  UNKNOWN = "button_unknown",
}

const URI_BASE = "tango://";
const encodedUriBase = encodeURIComponent(URI_BASE);
const getTangoProfileDeeplink = (userId: string) =>
  encodeURIComponent(
    `${URI_BASE}deeplink/q?target=${Target.VIEW_PROFILE}&${DeeplinkQueryField.UID}=${userId}`
  );

type OneLinkOptions =
  | {
      buttonName: Exclude<
        AF_SMART_LINK_BUTTON_NAME,
        AF_SMART_LINK_BUTTON_NAME.PROFILE
      >;
      userId?: never;
    }
  | { buttonName: AF_SMART_LINK_BUTTON_NAME.PROFILE; userId: string };

const DEFAULT_OPTIONS = {
  buttonName: AF_SMART_LINK_BUTTON_NAME.UNKNOWN,
  userId: undefined,
} as const;

const prepareOneLinkURL = (options: OneLinkOptions = DEFAULT_OPTIONS) => {
  const appsFlyerSmartScriptLink = window.AF_SMART_SCRIPT_RESULT?.clickURL;

  if (!appsFlyerSmartScriptLink) return null;

  const url = new URL(appsFlyerSmartScriptLink);
  const savedSearchParams = new URLSearchParams(url.search);
  url.search = "";

  const { buttonName, userId } = options;

  const generatedLink = window.AF_SMART_SCRIPT?.generateOneLinkURL({
    oneLinkURL: url.href,
    afParameters: {
      mediaSource: {
        keys: ["utm_source"],
        defaultValue: savedSearchParams.get("pid") || "Web Organic",
      },
      ...(!!userId && {
        deepLinkValue: {
          keys: [],
          defaultValue: getTangoProfileDeeplink(userId),
        },
      }),
      afCustom: [
        {
          paramKey: "af_dp",
          keys: [],
          // tango URI scheme defined in appsflyer admin panel
          // without this parameter redirect inside of android will not work
          defaultValue: encodedUriBase,
        },
        {
          paramKey: "button_name",
          keys: [],
          defaultValue: buttonName,
        },
      ],
    },
  })?.clickURL;

  if (!generatedLink) return appsFlyerSmartScriptLink;

  const generatedUrl = new URL(generatedLink);

  for (const [key, value] of savedSearchParams) {
    if (!generatedUrl.searchParams.has(key)) {
      generatedUrl.searchParams.set(key, value);
    }
  }

  return generatedUrl.href;
};

export default (deviceType: DeviceType, oneLinkOptions?: OneLinkOptions) => {
  const deeplink = getFirebaseAppDeeplink();
  return (
    prepareOneLinkURL(oneLinkOptions) ||
    deeplink ||
    (deviceType === DeviceType.ANDROID ? GOOGLE_PLAY_LINK : APP_STORE_LINK)
  );
};
