import { StreamSourceChatType } from "chat/enums";
import { EventFields, STREAM_SOURCE } from "chat/imports/analytics";

export type ChatLocationState = {
  LiveSessionStreamSource: number;
  chat_type: StreamSourceChatType;
};

export const createLocationStateForChat = (
  chatType: StreamSourceChatType
): ChatLocationState => ({
  [EventFields.STREAM_SOURCE]: STREAM_SOURCE.CHAT,
  [EventFields.STREAM_CHAT_SOURCE_TYPE]: chatType,
});
