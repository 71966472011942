import { batch } from "react-redux";
import { bidNftLot } from "api/nft";
import { MARKETPLACE_ID } from "src/constants";
import { nftAuctionSelectors } from "state/selectors";
import { lotsActionCreators } from "state/tree/nft";
import { BidResult, LotClientStatus, ResponseCode } from "ui/nft/enums";

const processLotBid =
  (
    { lotId, amount, auctionId, onBidEnd, target, isMarketLot = false },
    attempts = 2
  ) =>
  async (dispatch, getState) => {
    const state = getState();

    if (
      nftAuctionSelectors.getLotStatus(state, lotId) ===
      LotClientStatus.PROCESSING
    ) {
      return;
    }

    dispatch(lotsActionCreators.processLotBidStart(lotId));

    try {
      let response;
      while (attempts) {
        response = await bidNftLot({
          lotId,
          amount,
        });

        if (response.code !== ResponseCode.OK) {
          throw new Error("Network error");
        }

        attempts = response.result !== BidResult.TIMEOUT ? 0 : attempts - 1;
      }

      const { result, lot } = response;

      batch(() => {
        dispatch(lotsActionCreators.processLotBidEnd({ result, lot, lotId }));
        result === BidResult.SUCCESS &&
          dispatch(
            lotsActionCreators.addLotToMyLotList({
              lotId,
              auctionId: isMarketLot ? MARKETPLACE_ID : auctionId,
            })
          );
      });

      onBidEnd && onBidEnd(result, lotId, target);
      return result;
    } catch (error) {
      dispatch(
        lotsActionCreators.processLotBidEnd({
          result: LotClientStatus.ERROR,
          lotId,
        })
      );
      onBidEnd && onBidEnd(LotClientStatus.ERROR, lotId, target);
      return LotClientStatus.ERROR;
    }
  };

export default processLotBid;
