import { SubscriptionsPerStreamerState } from "state/tree/subscriptionsPerStreamer";

export default {
  // credit subscription offers
  getCreditOffers: (state: SubscriptionsPerStreamerState, account: string) =>
    state.creditOffers.data[account],
  getCreditOffersMeta: (state: SubscriptionsPerStreamerState) =>
    state.creditOffers.meta,

  // animation
  getShowAnimationOverlay: (state: SubscriptionsPerStreamerState) =>
    state.subscriptions.animationOverlay?.show,
  getAnimationOverlayType: (state: SubscriptionsPerStreamerState) =>
    state.subscriptions?.animationOverlay?.type,
  getAnimationLoop: (state: SubscriptionsPerStreamerState) =>
    state.subscriptions?.animationOverlay?.loop,
  getProfile: (state: SubscriptionsPerStreamerState) =>
    state.subscriptions?.animationOverlay?.profile,
  getProfileAccountId: (state: SubscriptionsPerStreamerState) =>
    state.subscriptions?.animationOverlay?.accountId,
  getStreamerAccountId: (state: SubscriptionsPerStreamerState) =>
    state.subscriptions?.animationOverlay?.streamerAccountId,

  // upgrade offers
  getUpgradeOffers: (state: SubscriptionsPerStreamerState, account: string) =>
    state.upgradeOffers.data[account],
  getUpgradeOffersMeta: (state: SubscriptionsPerStreamerState) =>
    state.upgradeOffers.meta,

  // streamer plans
  getStreamerPlans: (state: SubscriptionsPerStreamerState, account: string) =>
    state.streamerPlans.data[account],
  getStreamerPlansMeta: (state: SubscriptionsPerStreamerState) =>
    state.streamerPlans.meta,
};
