import isEmpty from "lodash.isempty";
import fetch, { urlAndParams } from "api/utils/enhancedFetch";
import { OneClickOfferTypes } from "enums/oneClickOffers";
import { getProxycadorHost } from "environment";
import { CashierPersonalOfferResponse as Offer } from "generated/proto/CashierPersonalOffer";
import { analyticsPersonalOffersItems } from "src/core/analytics/utils/analyticsPersonalOffers";
import { PersonalOfferType } from "src/enums";
import { PersonalOffersConfig, PricePoint } from "src/types/personalOffer";
import logger from "utils/logger";

export const fetchPersonalOffers = async (
  type: PersonalOfferType,
  campaignId?: string
): Promise<PersonalOffersConfig> => {
  const resp = await fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/personal-offers/client/offer`,
      { type, ...(campaignId && { campaignId }) }
    )
  );

  const data = await resp.json();

  if (data.pricePoints) {
    data.pricePoints = data.pricePoints.filter(
      (pricePoint: PricePoint) => !!pricePoint.offers
    );
  }

  try {
    !isEmpty(data) && analyticsPersonalOffersItems(data);
  } catch (error) {
    logger.error(error);
  }

  return data;
};

interface fetchOneClickOfferParams {
  coins: number;
  type?: OneClickOfferTypes;
}

export const fetchOneClickOffer = async ({
  coins,
  type,
}: fetchOneClickOfferParams): Promise<PersonalOffersConfig> => {
  const resp = await fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/personal-offers/client/offer/one-click`,
      { coins, ...(type && { type }) }
    )
  );

  return resp.json();
};

export const fetchOfferByPricePointId = async (
  pricePointId: string,
  init?: RequestInit
): Promise<PersonalOffersConfig> => {
  const resp = await fetch(
    urlAndParams(
      `${getProxycadorHost()}/personal-offers/client/offer/pricePoint`,
      { pricePointId }
    ),
    init
  );
  const buffer = await resp.arrayBuffer();

  return Offer.decode(new Uint8Array(buffer), undefined);
};
