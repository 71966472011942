import type { Action, Dispatch, Store } from "redux";
import {
  realTimeRecommendationsContextService,
  realTimeRecommendationsQueueService,
  realTimeRecommendationsSenderService,
} from "src/features/recommendations/client";
import { getRealtimeRecommendationsEnabled } from "state/abTests";
import { loginSelectors, userSelectors } from "state/selectors";

export const recommendationsSetupMiddleware =
  (store: Store) => (next: Dispatch) => (action: Action) => {
    const returnValue = next(action);

    const recommendationsSocEnabled = getRealtimeRecommendationsEnabled(
      store.getState()
    );

    const isLoggedIn = loginSelectors.isLoggedIn(store.getState());

    if (!recommendationsSocEnabled || !isLoggedIn) {
      if (realTimeRecommendationsSenderService.isEnabled()) {
        realTimeRecommendationsSenderService.disable();
      }

      if (realTimeRecommendationsQueueService.isEnabled()) {
        realTimeRecommendationsQueueService.disable();
      }

      return returnValue;
    }

    const currentAccountId = userSelectors.getMyAccountId(store.getState());

    if (currentAccountId) {
      realTimeRecommendationsContextService.setAccountId(currentAccountId);
    }

    // TODO: find out better way to initialize https://tango-me.atlassian.net/browse/WEB-5168
    if (!realTimeRecommendationsSenderService.isInited()) {
      realTimeRecommendationsSenderService.init();
    }

    return returnValue;
  };
