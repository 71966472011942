import { getGtmId, getGtmLoadDelay } from "environment";
import { loadScript } from "src/utils/scriptUtils";

window.dataLayer = window.dataLayer || [];

export default () => {
  const id = getGtmId();
  if (!id) {
    return;
  }
  loadScript({
    src: `https://www.googletagmanager.com/gtm.js?id=${id}`,
    nodeId: "gtm-script",
    async: true,
    delay: getGtmLoadDelay(),
  });

  window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
};
