import { emitEvent } from "@analytics/emit";
import { emitPersonalOffersEvent } from "@analytics/emitPersonalOffersEvent";
import {
  BI_LANDING_FIELD,
  EventFields,
  EventNames,
  PERSONAL_OFFERS_SOURCE,
} from "@analytics/enums";
import { fetchOneClickOffer } from "api/personalOffers";
import { isApiError } from "api/utils/enhancedFetch";
import {
  OneClickEndReason,
  OneClickEndResult,
  OneClickOfferTypes,
} from "enums/oneClickOffers";
import { Nullable } from "src/types/common";
import { PersonalOffersConfig } from "src/types/personalOffer";
import { currentTimeMillis } from "src/utils/dateUtils";
import { getOneClickPurchaseDefaultOffer } from "state/abTests";
import { RootState } from "state/delegate";

interface LoadClosestPersonalOfferParams {
  analyticsParams?: Record<string, unknown>;
  giftPrice: number;
  offerType?: OneClickOfferTypes;
  previousOneClickOffer?: Nullable<PersonalOffersConfig> | undefined;
  source?: PERSONAL_OFFERS_SOURCE;
  state: RootState;
  userBalance: number;
}

interface BIParams {
  [key: string]: Nullable<number | string>;
}

export const loadClosestPersonalOffer = async ({
  giftPrice,
  userBalance,
  state,
  source = PERSONAL_OFFERS_SOURCE.WEB_ONE_CLICK,
  previousOneClickOffer,
  offerType,
  analyticsParams = {},
}: LoadClosestPersonalOfferParams) => {
  let closestOfferToBuyGift = null;

  try {
    const startTimestamp = currentTimeMillis();

    const coinsNeededToBuyGift = giftPrice - userBalance;

    if (offerType !== OneClickOfferTypes.ONE_CLICK_PREMIUM_IN_STREAM_FT) {
      emitEvent(EventNames.ONE_CLICK_START, {
        [EventFields.CURRENT_BALANCE]: userBalance,
        [EventFields.ONE_CLICK_COINS_DIFFERENCE]: coinsNeededToBuyGift,
        ...analyticsParams,
      });
    }

    closestOfferToBuyGift = await fetchOneClickOffer({
      coins: coinsNeededToBuyGift,
      type: offerType,
    });

    emitPersonalOffersEvent({
      parseOldConfig: previousOneClickOffer,
      parseNewConfig: closestOfferToBuyGift,
      startTimestamp,
      source,
    });
  } catch (error) {
    let errorEventParams: BIParams = {
      [BI_LANDING_FIELD.COMMENT]: source,
    };

    if (isApiError(error) && error.status === 404) {
      errorEventParams = {
        ...errorEventParams,
        [BI_LANDING_FIELD.REASON]: error.statusText,
        [BI_LANDING_FIELD.ERROR_CODE]: error.status,
      };

      emitEvent(EventNames.ONE_CLICK_END, {
        [EventFields.RESULT]: OneClickEndResult.UNSUCCESSFUL,
        [EventFields.REASON]: OneClickEndReason.OFFER_NOT_FOUND,
        ...analyticsParams,
      });
    } else {
      closestOfferToBuyGift = getOneClickPurchaseDefaultOffer(state);
    }

    emitEvent(EventNames.PERSONAL_OFFERS_RECEIVE_ERROR, errorEventParams);
  }

  return closestOfferToBuyGift;
};
