export default ({ coinsBalance, gift, offers }) => {
  const creditsToPurchase = gift.priceInCredit - coinsBalance;

  return offers.find(
    (offer) =>
      !offer.bonusWithoutInitPurchase && offer.credits >= creditsToPurchase
  );
};

export const closestVipOffer = (vipOffers = [], usdToPurchase, offerPriceKey) =>
  [...vipOffers]
    .sort((a, b) => a[offerPriceKey] - b[offerPriceKey])
    .find((offer) => usdToPurchase <= offer[offerPriceKey]);
