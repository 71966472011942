import { LandingPagePhoenixState } from "state/tree/landingPagePhoenix";
import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";

const { meta: landingPageConfigMeta, data: landingPageConfigData } =
  generateAsyncSelectors<LandingPagePhoenixState>();

export const selectors = {
  landingPagePhoenixState: (state: LandingPagePhoenixState) => ({
    config: landingPageConfigData(state),
    selectedCampaignId: state.selectedCampaignId,
  }),
  landingPagePhoenixStateMeta: (state: LandingPagePhoenixState) =>
    landingPageConfigMeta(state),
};
