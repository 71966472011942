import emptyFunction from "fbjs/lib/emptyFunction";
import fetch from "api/utils/enhancedFetch";
import { getProxycadorHost } from "environment";
import {
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  UpgradeSubscriptionRequest,
  UpgradeSubscriptionResponse,
} from "generated/proto/SubscriptionApi";
import { SubscriptionStatus } from "src/enums";
import { Nullable } from "src/types/common";
const baseUrl = () => `${getProxycadorHost()}/proxycador/api/public/v1`;

export interface SubscriptionProfile {
  accountId: string;
  firstName: string;
  lastName: string;
  pictureUrl: string;
  thumbnailUrl: string;
}

export interface Details {
  streamer: {
    level: number;
    streamerProfile: SubscriptionProfile;
  };
}

export interface Subscription {
  credits: Nullable<number>;
  details: Details;
  endedAt: Nullable<number>;
  externalOfferId: string;
  id: string;
  isRenew: Nullable<boolean>;
  origin: string;
  points: Nullable<number>;
  startedAt: number;
  status: SubscriptionStatus;
  subscriberProfile: SubscriptionProfile;
  times: number;
}

export const createSubsWithCredits = ({
  marketOfferId,
  accountId,
}: {
  accountId: string;
  marketOfferId: string;
}) =>
  fetch(`${baseUrl()}/subscription/credits/subscribe/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      externalOfferId: marketOfferId,
      details: {
        streamer: {
          streamerProfile: {
            accountId,
          },
          level: 1,
        },
        level: 1,
      },
    }),
  }).then((resp) => resp.json());

export const createPersonalSubsWithCredits = async ({
  marketOfferId,
  accountId,
  triggerId,
}: {
  accountId: string;
  marketOfferId: string;
  triggerId?: string;
}) => {
  const resp = await fetch(
    `${getProxycadorHost()}/stream/v1/subscription/credits/subscribe`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
      body: CreateSubscriptionRequest.encode({
        externalOfferId: marketOfferId,
        triggerId,
        details: {
          streamer: {
            streamerProfile: {
              accountId,
            },
            level: 1,
          },
          level: 1,
        },
      }).finish(),
    }
  );
  const buffer = await resp.arrayBuffer();

  return CreateSubscriptionResponse.decode(new Uint8Array(buffer), undefined);
};

export const fetchCancelSubscription = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) =>
  fetch(`${baseUrl()}/subscription/${subscriptionId}/cancel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(emptyFunction);

export const fetchRenewSubscription = ({
  subscriptionId,
}: {
  subscriptionId: string;
}): Promise<{ code: string; subscription: Subscription }> =>
  fetch(`${baseUrl()}/subscription/${subscriptionId}/renew`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((resp) => resp.json());

export const fetchRenewSubscriptionWithCredits = ({
  subscriptionId,
}: {
  subscriptionId: string;
}): Promise<{ code: string; subscription: Subscription }> =>
  fetch(`${baseUrl()}/subscription/${subscriptionId}/credits/renew`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((resp) => resp.json());

export const fetchUserSubscriptionsList = ({
  accountIds,
  details,
}: {
  accountIds: string[];
  details?: Details;
}): Promise<Subscription[]> =>
  fetch(`${baseUrl()}/subscription/list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subscriberIds: accountIds,
      statuses: [
        SubscriptionStatus.GRACE_PERIOD,
        SubscriptionStatus.RENEWING,
        SubscriptionStatus.CANCELLED,
        SubscriptionStatus.ACTIVE,
        SubscriptionStatus.FREE_TRIAL,
        SubscriptionStatus.PENDING,
      ],
      ...(details && {
        details: [
          {
            streamer: {
              streamerProfile: {
                accountId: details.streamer.streamerProfile.accountId,
              },
            },
          },
        ],
      }),
    }),
  }).then((resp) => resp.json());

export const fetchStreamerSubscribers = ({
  accountId,
}: {
  accountId: string;
}): Promise<Subscription[]> =>
  fetch(`${baseUrl()}/subscription/list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      statuses: [
        SubscriptionStatus.GRACE_PERIOD,
        SubscriptionStatus.RENEWING,
        SubscriptionStatus.CANCELLED,
        SubscriptionStatus.ACTIVE,
        SubscriptionStatus.FREE_TRIAL,
        SubscriptionStatus.PENDING,
      ],
      details: [
        {
          streamer: {
            streamerProfile: {
              accountId,
            },
          },
        },
      ],
    }),
  }).then((resp) => resp.json());

export const upgradePersonalSubscription = async ({
  subscriptionId,
  externalOfferId,
  details,
  triggerId,
}: {
  details: Details;
  externalOfferId: string;
  subscriptionId: string;
  triggerId?: string;
}) => {
  const resp = await fetch(
    `${getProxycadorHost()}/stream/v1/subscription/${subscriptionId}/upgrade`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-protobuf;charset=UTF-8",
      },
      body: UpgradeSubscriptionRequest.encode({
        externalOfferId,
        details,
        triggerId,
      }).finish(),
    }
  );
  const buffer = await resp.arrayBuffer();

  return UpgradeSubscriptionResponse.decode(new Uint8Array(buffer), undefined);
};
