export enum FBMethods {
  TRACK = "track",
}

export enum FBEventNames {
  COMPLETED_REGISTRATION = "CompleteRegistration",
  PURCHASE = "Purchase",
}

export enum FBEventFields {
  CURRENCY = "currency",
  EVENT_ID = "eventID",
  VALUE = "value",
}
