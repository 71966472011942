import React, { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import useGoogleLogin from "src/features/signin/hooks/useGoogleLogin";
import {
  EventFields,
  RegistrationSource,
  SCREEN_NAME,
  SIGNIN_TARGET,
  emitUiAction,
} from "src/features/signin/imports/analytics";
import { LoginProvider } from "src/features/signin/imports/enums";
import { VoidCallback } from "src/features/signin/imports/types";
import {
  ButtonVariant,
  useBreakpointPrecise,
} from "src/features/signin/imports/ui";
import { isAppleDevice } from "src/features/signin/imports/utils";
import LoginAppleButton from "src/features/signin/modal/login/components/LoginAppleButton";
import ProviderButton, {
  ProviderButtonProps,
} from "src/features/signin/modal/login/components/ProviderButton/ProviderButton";
import { loginWithProvider } from "src/features/signin/state/flows/login";
import {
  loginCancelled,
  loginStart,
} from "src/features/signin/state/login/actionCreators";
import styles from "./LoginProviders.scss";

const preferredProviderOptions = [
  LoginProvider.GOOGLE,
  LoginProvider.APPLE,
  LoginProvider.FACEBOOK,
  LoginProvider.TANGO_PHONE_NUMBER,
];

interface LoginProviderButtonParams
  extends Omit<ProviderButtonProps, "provider"> {
  onLoginSuccess?: VoidCallback;
  provider?: LoginProvider;
  registrationSource?: RegistrationSource;
}

const LoginProviderButton = ({
  provider,
  onClick,
  buttonVariant,
  onLoginSuccess,
  registrationSource,
}: LoginProviderButtonParams) =>
  provider === LoginProvider.APPLE ? (
    <LoginAppleButton
      provider={LoginProvider.APPLE}
      buttonVariant={buttonVariant}
      onLoginSuccess={onLoginSuccess}
      registrationSource={registrationSource}
    />
  ) : (
    <ProviderButton
      // @ts-ignore
      provider={provider}
      buttonVariant={buttonVariant}
      onClick={onClick}
      onLoginSuccess={onLoginSuccess}
    />
  );

interface LoginProvidersProps {
  beginPhoneNumberLogin?: VoidCallback;
  onLoginSuccess?: VoidCallback;
  providers: LoginProvider[];
  registrationSource?: RegistrationSource;
}

export const LoginProviders: FC<LoginProvidersProps> = ({
  providers,
  beginPhoneNumberLogin,
  onLoginSuccess,
  registrationSource,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();

  const startGoogleLogin = useGoogleLogin({ onLoginSuccess });

  const isAppleProviderAvailable = providers.includes(LoginProvider.APPLE);
  const isGoogleProviderAvailable = providers.includes(LoginProvider.GOOGLE);
  const { mainProvider, secondaryProviders } = useMemo(() => {
    if (!providers?.length) {
      return { secondaryProviders: [] };
    }

    let mainProvider: LoginProvider | undefined;
    if (isAppleDevice() && isAppleProviderAvailable) {
      mainProvider = LoginProvider.APPLE;
    } else if (isGoogleProviderAvailable) {
      mainProvider = LoginProvider.GOOGLE;
    } else {
      mainProvider = preferredProviderOptions.find((option) =>
        providers.includes(option)
      );
    }
    const secondaryProviders = providers.filter(
      (provider) => provider !== mainProvider
    );

    return { mainProvider, secondaryProviders };
  }, [providers, isAppleProviderAvailable, isGoogleProviderAvailable]);

  const onProviderClick = useCallback(
    (provider) => {
      if (provider === LoginProvider.TANGO_PHONE_NUMBER) {
        emitUiAction({
          target: SIGNIN_TARGET.SIGNIN_WITH_PHONE,
          tango_screen: {
            reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
          },
          additionalParams: {
            [EventFields.SOURCE]: registrationSource,
          },
        });

        dispatch(loginCancelled()); // clean up loginFailed flag after google/fb attempts (if any)
        beginPhoneNumberLogin?.();
      } else if (provider === LoginProvider.GOOGLE) {
        emitUiAction({
          target: SIGNIN_TARGET.SIGNIN_WITH_GOOGLE,
          tango_screen: {
            reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
          },
          additionalParams: {
            [EventFields.SOURCE]: registrationSource,
          },
        });

        dispatch(loginStart());
        startGoogleLogin();
      } else if (provider === LoginProvider.APPLE) {
        emitUiAction({
          target: SIGNIN_TARGET.SIGNIN_WITH_APPLE,
          tango_screen: {
            reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
          },
          additionalParams: {
            [EventFields.SOURCE]: registrationSource,
          },
        });

        dispatch(loginStart());
      } else {
        // TODO: move login.js to TypeScript in https://tango-me.atlassian.net/browse/WEB-6884
        // @ts-ignore
        dispatch(loginWithProvider({ provider, onLoginSuccess }));
      }
    },
    [
      registrationSource,
      dispatch,
      beginPhoneNumberLogin,
      startGoogleLogin,
      onLoginSuccess,
    ]
  );

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <LoginProviderButton
        provider={mainProvider}
        buttonVariant={ButtonVariant.PRIMARY}
        onClick={onProviderClick}
        onLoginSuccess={onLoginSuccess}
        registrationSource={registrationSource}
      />

      {secondaryProviders?.map((provider) => (
        <LoginProviderButton
          key={provider}
          provider={provider}
          onClick={onProviderClick}
          onLoginSuccess={onLoginSuccess}
          registrationSource={registrationSource}
        />
      ))}
    </div>
  );
};

export default LoginProviders;
