import {
  ImageVariant,
  ResolutionTypes,
  ResolutionsType,
  VipImageArgs,
} from "src/features/profile/visitorProfile/imports/types";
import { getRootPathForVipLevel } from "src/features/profile/visitorProfile/imports/utils";
import { VisitorProfileState } from "src/features/profile/visitorProfile/state/slice";

export const visitorProfileSelectors = {
  getVisitorProfile: (state: VisitorProfileState) => state.visitorProfile,
  getVisitorAccountId: (state: VisitorProfileState) => state.visitorAccountId,
  getIsVisitorAccountIdLoadedWithErrors: (state: VisitorProfileState) =>
    state.isVisitorAccountIdLoadedWithError,
  getVisitorProfileFeed: (state: VisitorProfileState) =>
    state.visitorProfileFeed,
  getVisitorProfileStreamId: (state: VisitorProfileState) =>
    state.visitorProfileStreamId,
  getVisitorProfileVipConfig: (state: VisitorProfileState) =>
    state.visitorProfileVipConfig,
  getVipImage: (
    state: VisitorProfileState,
    { imageName, theme, breakpoint }: VipImageArgs
  ) => {
    const model = state.visitorProfileVipConfig;

    if (!model?.bundleZipUrl || !model?.assetConfig) {
      return "";
    }
    const vipLevelRootPath = getRootPathForVipLevel(model.bundleZipUrl);
    const image = model.assetConfig.assets[imageName];
    const resolution = ResolutionTypes[breakpoint] as keyof ResolutionsType;
    const searchForTheme = ({ theme: defaultTheme }: ImageVariant) =>
      defaultTheme === theme;
    const imagePath = image.resolutions.DEFAULT
      ? image.resolutions.DEFAULT.find(searchForTheme)
      : image.resolutions[resolution]?.find(searchForTheme);

    return `${vipLevelRootPath}/${model.label}/${image.rootPath}/${imagePath?.path}`;
  },
};
