/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.StoryPost || ($protobuf.roots.StoryPost = {});

export const Story = $root.Story = (() => {

    function Story(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Story.prototype.storyId = "";
    Story.prototype.storyFolderName = "";
    Story.prototype.gifterId = "";
    Story.prototype.giftId = "";
    Story.prototype.giftTimestamp = 0;
    Story.prototype.subscribersOnly = false;
    Story.prototype.duration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Story.prototype.videoLink = "";
    Story.prototype.thumbnailLink = "";
    Story.prototype.shownGifter = false;

    Story.create = function create(properties) {
        return new Story(properties);
    };

    Story.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.storyId != null && Object.hasOwnProperty.call(message, "storyId"))
            writer.uint32(10).string(message.storyId);
        if (message.storyFolderName != null && Object.hasOwnProperty.call(message, "storyFolderName"))
            writer.uint32(18).string(message.storyFolderName);
        if (message.gifterId != null && Object.hasOwnProperty.call(message, "gifterId"))
            writer.uint32(26).string(message.gifterId);
        if (message.giftId != null && Object.hasOwnProperty.call(message, "giftId"))
            writer.uint32(34).string(message.giftId);
        if (message.giftTimestamp != null && Object.hasOwnProperty.call(message, "giftTimestamp"))
            writer.uint32(45).sfixed32(message.giftTimestamp);
        if (message.subscribersOnly != null && Object.hasOwnProperty.call(message, "subscribersOnly"))
            writer.uint32(48).bool(message.subscribersOnly);
        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
            writer.uint32(57).sfixed64(message.duration);
        if (message.videoLink != null && Object.hasOwnProperty.call(message, "videoLink"))
            writer.uint32(66).string(message.videoLink);
        if (message.thumbnailLink != null && Object.hasOwnProperty.call(message, "thumbnailLink"))
            writer.uint32(74).string(message.thumbnailLink);
        if (message.shownGifter != null && Object.hasOwnProperty.call(message, "shownGifter"))
            writer.uint32(80).bool(message.shownGifter);
        return writer;
    };

    Story.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Story.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Story();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.storyId = reader.string();
                    break;
                }
            case 2: {
                    message.storyFolderName = reader.string();
                    break;
                }
            case 3: {
                    message.gifterId = reader.string();
                    break;
                }
            case 4: {
                    message.giftId = reader.string();
                    break;
                }
            case 5: {
                    message.giftTimestamp = reader.sfixed32();
                    break;
                }
            case 6: {
                    message.subscribersOnly = reader.bool();
                    break;
                }
            case 7: {
                    message.duration = reader.sfixed64();
                    break;
                }
            case 8: {
                    message.videoLink = reader.string();
                    break;
                }
            case 9: {
                    message.thumbnailLink = reader.string();
                    break;
                }
            case 10: {
                    message.shownGifter = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Story.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Story.fromObject = function fromObject(object) {
        if (object instanceof $root.Story)
            return object;
        let message = new $root.Story();
        if (object.storyId != null)
            message.storyId = String(object.storyId);
        if (object.storyFolderName != null)
            message.storyFolderName = String(object.storyFolderName);
        if (object.gifterId != null)
            message.gifterId = String(object.gifterId);
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.giftTimestamp != null)
            message.giftTimestamp = object.giftTimestamp | 0;
        if (object.subscribersOnly != null)
            message.subscribersOnly = Boolean(object.subscribersOnly);
        if (object.duration != null)
            if ($util.Long)
                (message.duration = $util.Long.fromValue(object.duration)).unsigned = false;
            else if (typeof object.duration === "string")
                message.duration = parseInt(object.duration, 10);
            else if (typeof object.duration === "number")
                message.duration = object.duration;
            else if (typeof object.duration === "object")
                message.duration = new $util.LongBits(object.duration.low >>> 0, object.duration.high >>> 0).toNumber();
        if (object.videoLink != null)
            message.videoLink = String(object.videoLink);
        if (object.thumbnailLink != null)
            message.thumbnailLink = String(object.thumbnailLink);
        if (object.shownGifter != null)
            message.shownGifter = Boolean(object.shownGifter);
        return message;
    };

    Story.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.storyId = "";
            object.storyFolderName = "";
            object.gifterId = "";
            object.giftId = "";
            object.giftTimestamp = 0;
            object.subscribersOnly = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.duration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.duration = options.longs === String ? "0" : 0;
            object.videoLink = "";
            object.thumbnailLink = "";
            object.shownGifter = false;
        }
        if (message.storyId != null && message.hasOwnProperty("storyId"))
            object.storyId = message.storyId;
        if (message.storyFolderName != null && message.hasOwnProperty("storyFolderName"))
            object.storyFolderName = message.storyFolderName;
        if (message.gifterId != null && message.hasOwnProperty("gifterId"))
            object.gifterId = message.gifterId;
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.giftTimestamp != null && message.hasOwnProperty("giftTimestamp"))
            object.giftTimestamp = message.giftTimestamp;
        if (message.subscribersOnly != null && message.hasOwnProperty("subscribersOnly"))
            object.subscribersOnly = message.subscribersOnly;
        if (message.duration != null && message.hasOwnProperty("duration"))
            if (typeof message.duration === "number")
                object.duration = options.longs === String ? String(message.duration) : message.duration;
            else
                object.duration = options.longs === String ? $util.Long.prototype.toString.call(message.duration) : options.longs === Number ? new $util.LongBits(message.duration.low >>> 0, message.duration.high >>> 0).toNumber() : message.duration;
        if (message.videoLink != null && message.hasOwnProperty("videoLink"))
            object.videoLink = message.videoLink;
        if (message.thumbnailLink != null && message.hasOwnProperty("thumbnailLink"))
            object.thumbnailLink = message.thumbnailLink;
        if (message.shownGifter != null && message.hasOwnProperty("shownGifter"))
            object.shownGifter = message.shownGifter;
        return object;
    };

    Story.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Story.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Story";
    };

    return Story;
})();

export { $root as default };
