import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatGiftAnimationSelectors } from "chat/giftAnimation/state/selectors";
import { giftPlayed } from "chat/giftAnimation/state/slice";
import { GiftOverlay } from "chat/imports/components";
import { Breakpoints, SpecialKind } from "chat/imports/constants";
import { useBreakpoint } from "chat/imports/hooks";
import { RootState, giftsCacheSelectors } from "chat/imports/state";
import { GiftOverlayProps } from "chat/imports/types";
import ChatGiftVideo from "./GiftChatVideo";
import styles from "./GiftChatAnimationOverlay.scss";

const giftOverlayComponents: {
  [key in Breakpoints]?: GiftOverlayProps["components"];
} = {
  [Breakpoints.DESKTOP]: {
    GiftVideo: ChatGiftVideo,
  },
};

const GiftChatAnimationOverlay = () => {
  const dispatch = useDispatch();

  const giftToPlay = useSelector(chatGiftAnimationSelectors.getGiftToPlay);

  const gift = useSelector((state: RootState) =>
    giftsCacheSelectors.getGiftById(state, giftToPlay?.giftId)
  );

  const onComplete = useCallback(() => {
    dispatch(giftPlayed());
  }, [dispatch]);

  const breakpoint = useBreakpoint();

  const components = giftOverlayComponents[breakpoint];

  if (!gift || !giftToPlay) {
    return null;
  }

  if (gift.special === SpecialKind.AR_GIFT) {
    onComplete();

    return null;
  }

  return (
    <div className={styles.root}>
      <GiftOverlay
        key={giftToPlay.id}
        gift={gift}
        components={components}
        onComplete={onComplete}
      />
    </div>
  );
};

GiftChatAnimationOverlay.displayName = "GiftChatAnimationOverlay";

export default memo(GiftChatAnimationOverlay);
