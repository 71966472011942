import React, { FC } from "react";
import classnames from "classnames";
import { Typography } from "legal/imports/components";
import { TYPOGRAPHY_TYPE } from "legal/imports/constants";
import { useBreakpointPrecise } from "legal/imports/hooks";
import styles from "./PostDate.scss";

type PostDateProps = {
  onClick?: () => void;
};

const PostDate: FC<PostDateProps> = ({ onClick, children }) => {
  const breakpoint = useBreakpointPrecise();

  return (
    <Typography
      type={TYPOGRAPHY_TYPE.PARAGRAPH5}
      as="time"
      className={classnames(styles.postDate, styles[breakpoint])}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

export default PostDate;
