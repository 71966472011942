import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import {
  GetUserHashedInfoResponse,
  getUserHashedInfo,
} from "src/features/marketing/api/marketing";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { initializeMarketingPixel } from "src/features/marketing/utils";
import { RootState } from "state/delegate";

export const loadUserHashedInfo = createAsyncThunk<
  GetUserHashedInfoResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/marketing/fetch", async (_, api) => {
  try {
    const resp = await getUserHashedInfo();
    const state = api.getState();
    const userHashedInfo = marketingSelectors.getUserHashedInfo(state);
    const email = resp?.email;
    const phoneNumber = resp?.phoneNumber;
    const isEmailChanged = email !== userHashedInfo?.email;
    const isPhoneNumberChanged = phoneNumber !== userHashedInfo?.phoneNumber;

    if ((email || phoneNumber) && (isEmailChanged || isPhoneNumberChanged)) {
      initializeMarketingPixel(resp);
    }

    return resp;
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
