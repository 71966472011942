import React, { useEffect, useState } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import loadTranslations from "src/loadTranslations";
import { setDeviceLocale } from "state/flows/deviceInfo";
import { deviceInfoSelectors, settingsSelectors } from "state/selectors";

const IntlProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState({});
  const locale = useSelector(deviceInfoSelectors.getDeviceLocale);
  const localeFromSettings = useSelector(settingsSelectors.getLocale);

  useEffect(() => {
    if (localeFromSettings) {
      dispatch(setDeviceLocale(localeFromSettings));
    }
  }, [dispatch, localeFromSettings]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    loadTranslations(locale, signal).then(([messages, guardianMessages]) => {
      const mergedMessages = {
        ...messages,
        ...guardianMessages,
      };
      setMessages(mergedMessages);
    });

    return () => {
      abortController.abort();
    };
  }, [locale]);

  return (
    <ReactIntlProvider textComponent="span" locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  );
};

IntlProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default IntlProvider;
