import { PersonalOffersState } from "state/tree/personalOffers";
import { generateAsyncSelectors } from "state/hor/addFetcherActionsToBuilder";

const { meta: personalOffersConfigMeta, data: personalOffersConfigData } =
  generateAsyncSelectors<PersonalOffersState>();

export const selectors = {
  personalOffersState: (state: PersonalOffersState) =>
    personalOffersConfigData(state),
  personalOffersStateMeta: (state: PersonalOffersState) =>
    personalOffersConfigMeta(state),
};
