import { createReducer } from "@reduxjs/toolkit";
import {
  addAsyncCasesToBuilderV2,
  AsyncState,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import { fetchTournamentsLanding } from "state/actionCreators/tournamentsLanding";
import { TournamentsLanding } from "src/types/tournament";

export type TournamentsLandingState = AsyncState<TournamentsLanding, string>;

const initialState = {
  data: {
    startDate: null,
    timeOfRound: 30,
    rounds: [],
    events: {},
    assets: {},
  },
  meta: initialFetcherStateMeta,
};

export default createReducer<TournamentsLandingState>(
  initialState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      builder,
      action: fetchTournamentsLanding,
      prepareData: (oldData, newData) => newData,
      initialData: initialState.data,
    });
  }
);
