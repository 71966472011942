import { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import refreshGiftsDrawer from "state/flows/refreshGiftsDrawer";
import refreshCustomDrawer from "state/flows/refreshCustomDrawer";

const useRefreshGiftsDrawer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(refreshCustomDrawer());
      dispatch(refreshGiftsDrawer());
    });
  }, []);
};

export default useRefreshGiftsDrawer;
