import { jwtDecode } from "jwt-decode";
import { TokenData } from "src/features/appeal/AppealPage/types";

export function parseToken(token: string) {
  try {
    return jwtDecode<TokenData>(token);
  } catch {
    return null;
  }
}
