import type { RealTimeRecommendationsClientEvent } from "src/features/recommendations/common/types";

export class RealTimeRecommendationsQueueService {
  private enabled = false;

  private events: RealTimeRecommendationsClientEvent[] = [];

  public clearEvents(): void {
    this.events = [];
  }

  public disable(): void {
    this.enabled = false;

    this.clearEvents();
  }

  public enable(): void {
    this.enabled = true;
  }

  public getEvents(): RealTimeRecommendationsClientEvent[] {
    return this.events;
  }

  public isEnabled(): boolean {
    return this.enabled;
  }

  public scheduleEvent(event: RealTimeRecommendationsClientEvent): void {
    if (!this.enabled) {
      return;
    }

    this.events.push(event);
  }
}
