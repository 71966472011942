export const getIsContinueButtonDisabled = (
  initialPhoneNumber: string,
  currentPhoneNumber: string,
  currentCountryCode: string | undefined,
  minPhoneLength: number
) => {
  if (currentPhoneNumber.length < minPhoneLength) {
    return true;
  }

  return !!(
    initialPhoneNumber &&
    initialPhoneNumber === currentCountryCode + currentPhoneNumber
  );
};

export const getPhoneNumberWithoutCountryCode = (
  fullPhone: string,
  countryCode: string | undefined = ""
): string => fullPhone.replace(countryCode, "") || "";

export const getNextSMSResendTimestamp = (resendDelay: number): number =>
  new Date().getTime() + resendDelay * 1000;

export const getIsSMSResendAllowed = (
  allowedSMSResendTimestamp: number | undefined
): boolean => {
  if (!allowedSMSResendTimestamp) {
    return true;
  }

  return Date.now() > allowedSMSResendTimestamp;
};
