import React from "react";
import classnames from "classnames";
import HeaderAction from "src/features/signin/bottomScreen/components/HeaderAction";
import { VoidCallback } from "src/features/signin/imports/types";
import { ReactComponent as CloseIcon } from "img/close_24.svg";
import { ReactComponent as BackIcon } from "img/new-ui/burger_menu/ic-burger-mune-back.svg";
import styles from "./HeaderActions.scss";

interface HeaderActionsParams {
  bottomScreenStyle?: string;
  isBackButtonVisible: boolean;
  isCloseButtonVisible: boolean;
  navigateBack: VoidCallback;
  onClose: VoidCallback;
}

const HeaderActions: React.FC<HeaderActionsParams> = ({
  isBackButtonVisible,
  isCloseButtonVisible,
  navigateBack,
  onClose,
  bottomScreenStyle,
}) => (
  <>
    {isBackButtonVisible && (
      <HeaderAction
        onClick={navigateBack}
        className={classnames(
          styles.back,
          bottomScreenStyle && styles[bottomScreenStyle]
        )}
      >
        <BackIcon />
      </HeaderAction>
    )}

    {isCloseButtonVisible && (
      <HeaderAction
        onClick={onClose}
        className={classnames(
          styles.close,
          bottomScreenStyle && styles[bottomScreenStyle]
        )}
      >
        <CloseIcon />
      </HeaderAction>
    )}
  </>
);

export default HeaderActions;
