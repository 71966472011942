/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.ClientSessionEventModel || ($protobuf.roots.ClientSessionEventModel = {});

export const ClientSessionEvent = $root.ClientSessionEvent = (() => {

    function ClientSessionEvent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ClientSessionEvent.prototype.username = "";
    ClientSessionEvent.prototype.deviceId = "";
    ClientSessionEvent.prototype.deviceCategory = "";
    ClientSessionEvent.prototype.platform = "";
    ClientSessionEvent.prototype.browser = "";
    ClientSessionEvent.prototype.browserVersion = "";
    ClientSessionEvent.prototype.deviceModel = "";
    ClientSessionEvent.prototype.deviceManufacturer = "";
    ClientSessionEvent.prototype.deviceLanguage = "";
    ClientSessionEvent.prototype.deviceCountry = "";
    ClientSessionEvent.prototype.tangoVersion = "";
    ClientSessionEvent.prototype.os = "";
    ClientSessionEvent.prototype.osVersion = "";
    ClientSessionEvent.prototype.appsflyerId = "";
    ClientSessionEvent.prototype.idfa = "";
    ClientSessionEvent.prototype.idfv = "";
    ClientSessionEvent.prototype.hasGoogleServices = false;
    ClientSessionEvent.prototype.rooted = false;
    ClientSessionEvent.prototype.ram = 0;
    ClientSessionEvent.prototype.isEmulator = false;

    ClientSessionEvent.create = function create(properties) {
        return new ClientSessionEvent(properties);
    };

    ClientSessionEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(10).string(message.username);
        if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
            writer.uint32(18).string(message.deviceId);
        if (message.deviceCategory != null && Object.hasOwnProperty.call(message, "deviceCategory"))
            writer.uint32(26).string(message.deviceCategory);
        if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
            writer.uint32(34).string(message.platform);
        if (message.browser != null && Object.hasOwnProperty.call(message, "browser"))
            writer.uint32(42).string(message.browser);
        if (message.browserVersion != null && Object.hasOwnProperty.call(message, "browserVersion"))
            writer.uint32(50).string(message.browserVersion);
        if (message.deviceModel != null && Object.hasOwnProperty.call(message, "deviceModel"))
            writer.uint32(58).string(message.deviceModel);
        if (message.deviceManufacturer != null && Object.hasOwnProperty.call(message, "deviceManufacturer"))
            writer.uint32(66).string(message.deviceManufacturer);
        if (message.deviceLanguage != null && Object.hasOwnProperty.call(message, "deviceLanguage"))
            writer.uint32(74).string(message.deviceLanguage);
        if (message.deviceCountry != null && Object.hasOwnProperty.call(message, "deviceCountry"))
            writer.uint32(82).string(message.deviceCountry);
        if (message.tangoVersion != null && Object.hasOwnProperty.call(message, "tangoVersion"))
            writer.uint32(90).string(message.tangoVersion);
        if (message.os != null && Object.hasOwnProperty.call(message, "os"))
            writer.uint32(98).string(message.os);
        if (message.osVersion != null && Object.hasOwnProperty.call(message, "osVersion"))
            writer.uint32(106).string(message.osVersion);
        if (message.appsflyerId != null && Object.hasOwnProperty.call(message, "appsflyerId"))
            writer.uint32(114).string(message.appsflyerId);
        if (message.idfa != null && Object.hasOwnProperty.call(message, "idfa"))
            writer.uint32(122).string(message.idfa);
        if (message.idfv != null && Object.hasOwnProperty.call(message, "idfv"))
            writer.uint32(130).string(message.idfv);
        if (message.hasGoogleServices != null && Object.hasOwnProperty.call(message, "hasGoogleServices"))
            writer.uint32(136).bool(message.hasGoogleServices);
        if (message.rooted != null && Object.hasOwnProperty.call(message, "rooted"))
            writer.uint32(144).bool(message.rooted);
        if (message.ram != null && Object.hasOwnProperty.call(message, "ram"))
            writer.uint32(152).uint32(message.ram);
        if (message.isEmulator != null && Object.hasOwnProperty.call(message, "isEmulator"))
            writer.uint32(160).bool(message.isEmulator);
        return writer;
    };

    ClientSessionEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ClientSessionEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientSessionEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.username = reader.string();
                    break;
                }
            case 2: {
                    message.deviceId = reader.string();
                    break;
                }
            case 3: {
                    message.deviceCategory = reader.string();
                    break;
                }
            case 4: {
                    message.platform = reader.string();
                    break;
                }
            case 5: {
                    message.browser = reader.string();
                    break;
                }
            case 6: {
                    message.browserVersion = reader.string();
                    break;
                }
            case 7: {
                    message.deviceModel = reader.string();
                    break;
                }
            case 8: {
                    message.deviceManufacturer = reader.string();
                    break;
                }
            case 9: {
                    message.deviceLanguage = reader.string();
                    break;
                }
            case 10: {
                    message.deviceCountry = reader.string();
                    break;
                }
            case 11: {
                    message.tangoVersion = reader.string();
                    break;
                }
            case 12: {
                    message.os = reader.string();
                    break;
                }
            case 13: {
                    message.osVersion = reader.string();
                    break;
                }
            case 14: {
                    message.appsflyerId = reader.string();
                    break;
                }
            case 15: {
                    message.idfa = reader.string();
                    break;
                }
            case 16: {
                    message.idfv = reader.string();
                    break;
                }
            case 17: {
                    message.hasGoogleServices = reader.bool();
                    break;
                }
            case 18: {
                    message.rooted = reader.bool();
                    break;
                }
            case 19: {
                    message.ram = reader.uint32();
                    break;
                }
            case 20: {
                    message.isEmulator = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ClientSessionEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ClientSessionEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientSessionEvent)
            return object;
        let message = new $root.ClientSessionEvent();
        if (object.username != null)
            message.username = String(object.username);
        if (object.deviceId != null)
            message.deviceId = String(object.deviceId);
        if (object.deviceCategory != null)
            message.deviceCategory = String(object.deviceCategory);
        if (object.platform != null)
            message.platform = String(object.platform);
        if (object.browser != null)
            message.browser = String(object.browser);
        if (object.browserVersion != null)
            message.browserVersion = String(object.browserVersion);
        if (object.deviceModel != null)
            message.deviceModel = String(object.deviceModel);
        if (object.deviceManufacturer != null)
            message.deviceManufacturer = String(object.deviceManufacturer);
        if (object.deviceLanguage != null)
            message.deviceLanguage = String(object.deviceLanguage);
        if (object.deviceCountry != null)
            message.deviceCountry = String(object.deviceCountry);
        if (object.tangoVersion != null)
            message.tangoVersion = String(object.tangoVersion);
        if (object.os != null)
            message.os = String(object.os);
        if (object.osVersion != null)
            message.osVersion = String(object.osVersion);
        if (object.appsflyerId != null)
            message.appsflyerId = String(object.appsflyerId);
        if (object.idfa != null)
            message.idfa = String(object.idfa);
        if (object.idfv != null)
            message.idfv = String(object.idfv);
        if (object.hasGoogleServices != null)
            message.hasGoogleServices = Boolean(object.hasGoogleServices);
        if (object.rooted != null)
            message.rooted = Boolean(object.rooted);
        if (object.ram != null)
            message.ram = object.ram >>> 0;
        if (object.isEmulator != null)
            message.isEmulator = Boolean(object.isEmulator);
        return message;
    };

    ClientSessionEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.username = "";
            object.deviceId = "";
            object.deviceCategory = "";
            object.platform = "";
            object.browser = "";
            object.browserVersion = "";
            object.deviceModel = "";
            object.deviceManufacturer = "";
            object.deviceLanguage = "";
            object.deviceCountry = "";
            object.tangoVersion = "";
            object.os = "";
            object.osVersion = "";
            object.appsflyerId = "";
            object.idfa = "";
            object.idfv = "";
            object.hasGoogleServices = false;
            object.rooted = false;
            object.ram = 0;
            object.isEmulator = false;
        }
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        if (message.deviceId != null && message.hasOwnProperty("deviceId"))
            object.deviceId = message.deviceId;
        if (message.deviceCategory != null && message.hasOwnProperty("deviceCategory"))
            object.deviceCategory = message.deviceCategory;
        if (message.platform != null && message.hasOwnProperty("platform"))
            object.platform = message.platform;
        if (message.browser != null && message.hasOwnProperty("browser"))
            object.browser = message.browser;
        if (message.browserVersion != null && message.hasOwnProperty("browserVersion"))
            object.browserVersion = message.browserVersion;
        if (message.deviceModel != null && message.hasOwnProperty("deviceModel"))
            object.deviceModel = message.deviceModel;
        if (message.deviceManufacturer != null && message.hasOwnProperty("deviceManufacturer"))
            object.deviceManufacturer = message.deviceManufacturer;
        if (message.deviceLanguage != null && message.hasOwnProperty("deviceLanguage"))
            object.deviceLanguage = message.deviceLanguage;
        if (message.deviceCountry != null && message.hasOwnProperty("deviceCountry"))
            object.deviceCountry = message.deviceCountry;
        if (message.tangoVersion != null && message.hasOwnProperty("tangoVersion"))
            object.tangoVersion = message.tangoVersion;
        if (message.os != null && message.hasOwnProperty("os"))
            object.os = message.os;
        if (message.osVersion != null && message.hasOwnProperty("osVersion"))
            object.osVersion = message.osVersion;
        if (message.appsflyerId != null && message.hasOwnProperty("appsflyerId"))
            object.appsflyerId = message.appsflyerId;
        if (message.idfa != null && message.hasOwnProperty("idfa"))
            object.idfa = message.idfa;
        if (message.idfv != null && message.hasOwnProperty("idfv"))
            object.idfv = message.idfv;
        if (message.hasGoogleServices != null && message.hasOwnProperty("hasGoogleServices"))
            object.hasGoogleServices = message.hasGoogleServices;
        if (message.rooted != null && message.hasOwnProperty("rooted"))
            object.rooted = message.rooted;
        if (message.ram != null && message.hasOwnProperty("ram"))
            object.ram = message.ram;
        if (message.isEmulator != null && message.hasOwnProperty("isEmulator"))
            object.isEmulator = message.isEmulator;
        return object;
    };

    ClientSessionEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ClientSessionEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientSessionEvent";
    };

    return ClientSessionEvent;
})();

export { $root as default };
