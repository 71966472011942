import { combineReducers } from "@reduxjs/toolkit";
import { customizations } from "state/tree/homePageV1/customizations/customizationsSlice";
import { showRoomStreams } from "state/tree/homePageV1/showRoomStreams/showRoomStreamsSlice";

export const homePageV1PersistConfig = {
  whitelist: ["showRoomStreams"],
};

export default combineReducers({
  customizations,
  showRoomStreams,
});
