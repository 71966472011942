import { BroadcastExternalStatus } from "src/features/broadcastExternal/common/enums";
import { Nullable } from "src/features/broadcastExternal/imports/types";

const isBroadcastExternalStarted = (
  broadcastExternalStatus: Nullable<BroadcastExternalStatus>
) =>
  !!broadcastExternalStatus &&
  [
    BroadcastExternalStatus.ACTIVE,
    BroadcastExternalStatus.INITIALIZED,
  ].includes(broadcastExternalStatus);

export default isBroadcastExternalStarted;
