import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserType, DeviceType } from "src/enums";
import {
  getGiftsVideoEnabled,
  getGiftsVideoMovEnabled,
} from "src/state/abTests";
import { RootState } from "src/state/delegate";
import { deviceInfoSelectors } from "src/state/selectors";
import { Gift, VideoSource } from "src/types/gift";

const selector = (state: RootState) => ({
  browserName: deviceInfoSelectors.getBrowserName(state),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isGiftsVideoMovEnabled: getGiftsVideoMovEnabled(state),
  isGiftsVideoEnabled: getGiftsVideoEnabled(state),
});

const useGenerateEnabledVideoSources = (gift: Gift): VideoSource[] => {
  const {
    browserName,
    isGiftsVideoMovEnabled,
    isGiftsVideoEnabled,
    deviceType,
  } = useSelector(selector, shallowEqual);

  const isSafari = browserName === BrowserType.SAFARI;
  const isIos = deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD;

  return useMemo(() => {
    if (!isGiftsVideoEnabled) {
      return [];
    }

    const {
      webmAnimation720Url,
      webmAnimation360Url,
      movAnimation720Url,
      movAnimation360Url,
    } = gift;

    const videoSources: VideoSource[] = [];

    if ((isSafari || isIos) && isGiftsVideoMovEnabled) {
      movAnimation720Url &&
        videoSources.push({ src: movAnimation720Url, type: "video/quicktime" });
      movAnimation360Url &&
        videoSources.push({ src: movAnimation360Url, type: "video/quicktime" });
    } else if (!isSafari) {
      webmAnimation720Url &&
        videoSources.push({ src: webmAnimation720Url, type: "video/webm" });
      webmAnimation360Url &&
        videoSources.push({ src: webmAnimation360Url, type: "video/webm" });
    }

    return videoSources;
  }, [isGiftsVideoEnabled, gift, isSafari, isGiftsVideoMovEnabled]);
};

export default useGenerateEnabledVideoSources;
