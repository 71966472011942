import React, { FC, memo } from "react";
import { DEFAULT_GIFT_LINK } from "src/constants";
import { VoidCallback } from "src/types/common";
import { Gift } from "src/types/gift";
import GiftAnimation from "src/ui/animations/GiftAnimation";
import GiftAnimationZip from "src/ui/animations/GiftAnimationZip";
import GiftVideo, { GiftVideoProps } from "src/ui/animations/GiftVideo";
import useGenerateEnabledVideoSources from "src/ui/animations/useGenerateVideoSources";

export interface GiftAnimationProps {
  components?: {
    GiftVideo?: FC<GiftVideoProps>;
  };
  gift: Gift;
  onComplete?: VoidCallback;
}

const GiftAnimationFactory: FC<GiftAnimationProps> = ({
  components = {},
  gift,
  onComplete,
}) => {
  const sources = useGenerateEnabledVideoSources(gift);

  const { lottieAnimationUrl, lottieAnimationZipUrl, icon, assetBundle, id } =
    gift;

  if (lottieAnimationUrl) {
    return <GiftAnimation url={lottieAnimationUrl} onComplete={onComplete} />;
  }

  if (sources.length) {
    const Component = components.GiftVideo || GiftVideo;

    return <Component sources={sources} onComplete={onComplete} />;
  }

  if (lottieAnimationZipUrl) {
    return (
      <GiftAnimationZip
        url={lottieAnimationZipUrl}
        assetsPath={icon}
        onComplete={onComplete}
        giftId={id}
      />
    );
  }

  if (assetBundle) {
    return (
      <GiftAnimationZip
        url={assetBundle}
        assetsPath={icon}
        onComplete={onComplete}
        giftId={id}
      />
    );
  }

  if (icon) {
    return (
      <GiftAnimation
        url={DEFAULT_GIFT_LINK}
        assetsPath={icon}
        onComplete={onComplete}
      />
    );
  }

  return null;
};

export default memo(GiftAnimationFactory);
