import React, { FC, useRef } from "react";
import classnames from "classnames";
import { LegalNavigation } from "src/features/ofac/components/LegalNavigation";
import { RestrictedLayout } from "src/features/ofac/components/RestrictedLayout";
import { ScrollToTopButton } from "src/features/ofac/components/ScrollToTopButton";
import { Breakpoints } from "src/features/ofac/imports/enums";
import {
  useBreakpointPrecise,
  useIntersectionObserver,
} from "src/features/ofac/imports/ui";
import styles from "./LegalLayout.scss";

export const LegalLayout: FC = ({ children }) => {
  const legalNavigationRef = useRef<HTMLElement>(null);

  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const isTablet = breakpoint === Breakpoints.TABLET;

  const isLegalNavigationInView = useIntersectionObserver(legalNavigationRef, {
    initialValue: true,
    rootMargin: isTablet ? `-64px 0px 0px` : `-56px 0px 0px`,
  });

  return (
    <RestrictedLayout className={classnames(styles[breakpoint], styles.layout)}>
      <LegalNavigation ref={legalNavigationRef} />
      <main className={styles.main}>{children}</main>
      <ScrollToTopButton shouldShow={!isDesktop && !isLegalNavigationInView} />
    </RestrictedLayout>
  );
};
