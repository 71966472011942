import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTabsConfig } from "api/stream";
import { isApiError } from "api/utils/enhancedFetch";
import { RootState } from "state/delegate";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";
import tabsConfigsSelectorsFactory from "state/selectorFactories/exploreTabsConfig";
import { TabConfig, TabsConfigState } from "state/types";

export const loadTabsConfig = createAsyncThunk<
  TabsConfigState["data"],
  { locale: string } & FetcherMetaV2,
  { rejectValue: string; state: RootState }
>(
  "lwc/tabsConfig/load",
  async ({ locale }, api) => {
    try {
      const payload = await fetchTabsConfig({ locale });
      const { status, tabs } = payload;
      if (status !== "OK" || !tabs?.length) {
        return api.rejectWithValue("Bad payload");
      }

      return tabs as TabConfig[];
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const meta = tabsConfigsSelectorsFactory.meta(state);

      return !meta.loading;
    },
  }
);
