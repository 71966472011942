import { useSelector } from "react-redux";
import {
  loginSelectors,
  setRedirectAfterLogin,
  useAppDispatch,
  useMount,
  useQuery,
} from "src/features/marketing/imports";

export const useOpenLoginByMarketingQuery = () => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const dispatch = useAppDispatch();
  const query = useQuery();

  useMount(() => {
    if (isLoggedIn) {
      return;
    }

    const allQueryParams = Object.fromEntries(query.entries());
    const { marketingLoginAndRedirectLink } = allQueryParams;

    if (marketingLoginAndRedirectLink) {
      dispatch(
        setRedirectAfterLogin({
          redirectPath: `${marketingLoginAndRedirectLink}?${query.toString()}`,
        })
      );
    }
  });
};
