import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useIsVisitorProfile from "src/features/profile/visitorProfile/exports/hooks";
import loadCompleteProfileInfo from "state/flows/loadCompleteProfileInfo";
import { profilesCacheSelectors } from "state/selectors";

const selectorFactory = (accountId) => (state) => ({
  basicProfile: profilesCacheSelectors.getBasicProfile(state, accountId) || {},
  profileDetails:
    profilesCacheSelectors.getProfileDetails(state, accountId) || {},
  followStats: profilesCacheSelectors.getFollowStats(state, accountId) || {},
  liveStats: profilesCacheSelectors.getLiveStats(state, accountId) || {},
});

export default (accountId, autorefresh = true) => {
  const selector = useMemo(() => selectorFactory(accountId), [accountId]);
  const isVisitorProfilePage = useIsVisitorProfile();
  const state = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    if (accountId && autorefresh && !isVisitorProfilePage) {
      dispatch(loadCompleteProfileInfo(accountId));
    }
  }, [accountId, autorefresh, isVisitorProfilePage]);

  return state;
};
