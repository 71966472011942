import React from "react";
import { FormattedMessage } from "react-intl";
import useGetLoginProviders from "src/features/signin/hooks/useGetLoginProviders";
import { RegistrationSource } from "src/features/signin/imports/analytics";
import {
  LoginModalTitleType,
  LoginProvider,
} from "src/features/signin/imports/enums";
import { VoidCallback } from "src/features/signin/imports/types";
import {
  ButtonVariant,
  TYPOGRAPHY_TYPE,
  Typography,
} from "src/features/signin/imports/ui";
import LoginModalDisclaimer from "src/features/signin/modal/LoginModalDisclaimer";
import LoginModalHeader from "src/features/signin/modal/LoginModalHeader";
import {
  descriptionMessages,
  titleMessages,
} from "src/features/signin/modal/constants";
import { providerToComponentMap } from "src/features/signin/modal/enums";
import ProviderButtonPhone from "src/features/signin/modal/login/components/ProviderButtonPhone";
import styles from "./LoginProviderSelector.scss";

const Separator = () => (
  <div className={styles.separator}>
    <div className={styles.separatorLine} />
    <Typography
      as="div"
      className={styles.separatorText}
      type={TYPOGRAPHY_TYPE.ALL_CAPS}
    >
      <FormattedMessage id="OR" defaultMessage="OR" />
    </Typography>
    <div className={styles.separatorLine} />
  </div>
);

interface LoginProviderSelectorParams {
  onLoginSuccess?: VoidCallback;
  registrationSource: RegistrationSource;
  title: LoginModalTitleType;
}

const LoginProviderSelector: React.FC<LoginProviderSelectorParams> = ({
  title,
  onLoginSuccess,
  registrationSource,
}) => {
  const { mainProvider, otherProviders } = useGetLoginProviders();
  const areOtherProvidersAvailable = Boolean(otherProviders.length);

  const description =
    title in descriptionMessages ? (
      <FormattedMessage {...descriptionMessages[title]} />
    ) : undefined;

  return (
    <>
      <LoginModalHeader
        title={<FormattedMessage {...titleMessages[title]} />}
        description={description}
      />
      <div className={styles.loginMethods}>
        {areOtherProvidersAvailable && (
          <>
            <div className={styles.otherProviders}>
              {otherProviders.map((provider: LoginProvider, index) => {
                const Component = providerToComponentMap[provider];

                if (!Component) {
                  return null;
                }

                return (
                  <Component
                    key={provider}
                    provider={provider}
                    buttonVariant={
                      index === 0
                        ? ButtonVariant.PRIMARY
                        : ButtonVariant.SECONDARY
                    }
                    onLoginSuccess={onLoginSuccess}
                    registrationSource={registrationSource}
                  />
                );
              })}
            </div>

            {mainProvider && <Separator />}
          </>
        )}

        {mainProvider && (
          <ProviderButtonPhone registrationSource={registrationSource} />
        )}
      </div>

      <LoginModalDisclaimer />
    </>
  );
};
export default LoginProviderSelector;
