import { useRef, useEffect } from "react";
import { AnyFunction } from "src/types/common";

const useCallbackRef = <T extends AnyFunction>(cb: T) => {
  const cbRef = useRef(cb);
  useEffect(() => {
    cbRef.current = cb;
  }, [cb]);
  return cbRef;
};

export default useCallbackRef;
