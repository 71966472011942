import { useEffect } from "react";
import usePersistentState from "ui/hooks/usePersistentState";
import { useLocation } from "react-router-dom";
import { patchEnvironment } from "src/environment";

const useQueryToPatchEnvironment = <TQuery extends Record<string, string>>({
  propertyKey,
  queryMap,
  preprocessValue,
}: {
  propertyKey: string;
  queryMap: TQuery;
  preprocessValue?: (value: string) => string;
}) => {
  const [overrideValue, setOverrideValue] = usePersistentState({
    key: `override.${propertyKey}`,
    isSkipParse: true,
  });
  const { search } = useLocation();
  useEffect(() => {
    if (search) {
      const queryString = search.slice(1);
      const queryArray = queryString.split("&");
      queryArray.forEach((item) => {
        const value = queryMap[item];
        if (value !== undefined) {
          if (value) {
            patchEnvironment(
              propertyKey,
              preprocessValue ? preprocessValue(value) : value
            );
          }
          setOverrideValue(value);
        }
      });
    } else if (overrideValue) {
      patchEnvironment(
        propertyKey,
        preprocessValue ? preprocessValue(overrideValue) : overrideValue
      );
    }
  }, []);
};

export default useQueryToPatchEnvironment;
