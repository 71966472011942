/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.RegistrationV8 || ($protobuf.roots.RegistrationV8 = {});

export const Capabilities = $root.Capabilities = (() => {

    function Capabilities(properties) {
        this.capability = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Capabilities.prototype.capability = $util.emptyArray;

    Capabilities.create = function create(properties) {
        return new Capabilities(properties);
    };

    Capabilities.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.capability != null && message.capability.length)
            for (let i = 0; i < message.capability.length; ++i)
                writer.uint32(10).string(message.capability[i]);
        return writer;
    };

    Capabilities.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Capabilities.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Capabilities();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.capability && message.capability.length))
                        message.capability = [];
                    message.capability.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Capabilities.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Capabilities.fromObject = function fromObject(object) {
        if (object instanceof $root.Capabilities)
            return object;
        let message = new $root.Capabilities();
        if (object.capability) {
            if (!Array.isArray(object.capability))
                throw TypeError(".Capabilities.capability: array expected");
            message.capability = [];
            for (let i = 0; i < object.capability.length; ++i)
                message.capability[i] = String(object.capability[i]);
        }
        return message;
    };

    Capabilities.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.capability = [];
        if (message.capability && message.capability.length) {
            object.capability = [];
            for (let j = 0; j < message.capability.length; ++j)
                object.capability[j] = message.capability[j];
        }
        return object;
    };

    Capabilities.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Capabilities.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Capabilities";
    };

    return Capabilities;
})();

export const Contact = $root.Contact = (() => {

    function Contact(properties) {
        this.SocialNetworkId = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Contact.prototype.email = "";
    Contact.prototype.PhoneNumber = null;
    Contact.prototype.SocialNetworkId = $util.emptyArray;
    Contact.prototype.ZippedContactList = null;
    Contact.prototype.verificationProvider = 1;
    Contact.prototype.verificationParams = null;
    Contact.prototype.abookSize = 0;
    Contact.prototype.device = "";
    Contact.prototype.firstName = "";
    Contact.prototype.hash = "";
    Contact.prototype.isPhone = false;
    Contact.prototype.iso2CountryCode = "";
    Contact.prototype.lastName = "";
    Contact.prototype.linkAccounts = false;
    Contact.prototype.locale = "";

    Contact.create = function create(properties) {
        return new Contact(properties);
    };

    Contact.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
            writer.uint32(10).string(message.email);
        if (message.PhoneNumber != null && Object.hasOwnProperty.call(message, "PhoneNumber"))
            $root.PhoneNumber.encode(message.PhoneNumber, writer.uint32(18).fork()).ldelim();
        if (message.SocialNetworkId != null && message.SocialNetworkId.length)
            for (let i = 0; i < message.SocialNetworkId.length; ++i)
                $root.SocialNetworkId.encode(message.SocialNetworkId[i], writer.uint32(26).fork()).ldelim();
        if (message.ZippedContactList != null && Object.hasOwnProperty.call(message, "ZippedContactList"))
            $root.ZippedContactList.encode(message.ZippedContactList, writer.uint32(34).fork()).ldelim();
        if (message.verificationProvider != null && Object.hasOwnProperty.call(message, "verificationProvider"))
            writer.uint32(40).int32(message.verificationProvider);
        if (message.verificationParams != null && Object.hasOwnProperty.call(message, "verificationParams"))
            $root.VerificationParams.encode(message.verificationParams, writer.uint32(50).fork()).ldelim();
        if (message.abookSize != null && Object.hasOwnProperty.call(message, "abookSize"))
            writer.uint32(61).sfixed32(message.abookSize);
        if (message.device != null && Object.hasOwnProperty.call(message, "device"))
            writer.uint32(66).string(message.device);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(74).string(message.firstName);
        if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
            writer.uint32(82).string(message.hash);
        if (message.isPhone != null && Object.hasOwnProperty.call(message, "isPhone"))
            writer.uint32(88).bool(message.isPhone);
        if (message.iso2CountryCode != null && Object.hasOwnProperty.call(message, "iso2CountryCode"))
            writer.uint32(98).string(message.iso2CountryCode);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(106).string(message.lastName);
        if (message.linkAccounts != null && Object.hasOwnProperty.call(message, "linkAccounts"))
            writer.uint32(112).bool(message.linkAccounts);
        if (message.locale != null && Object.hasOwnProperty.call(message, "locale"))
            writer.uint32(122).string(message.locale);
        return writer;
    };

    Contact.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Contact.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Contact();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.email = reader.string();
                    break;
                }
            case 2: {
                    message.PhoneNumber = $root.PhoneNumber.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    if (!(message.SocialNetworkId && message.SocialNetworkId.length))
                        message.SocialNetworkId = [];
                    message.SocialNetworkId.push($root.SocialNetworkId.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.ZippedContactList = $root.ZippedContactList.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.verificationProvider = reader.int32();
                    break;
                }
            case 6: {
                    message.verificationParams = $root.VerificationParams.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.abookSize = reader.sfixed32();
                    break;
                }
            case 8: {
                    message.device = reader.string();
                    break;
                }
            case 9: {
                    message.firstName = reader.string();
                    break;
                }
            case 10: {
                    message.hash = reader.string();
                    break;
                }
            case 11: {
                    message.isPhone = reader.bool();
                    break;
                }
            case 12: {
                    message.iso2CountryCode = reader.string();
                    break;
                }
            case 13: {
                    message.lastName = reader.string();
                    break;
                }
            case 14: {
                    message.linkAccounts = reader.bool();
                    break;
                }
            case 15: {
                    message.locale = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Contact.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Contact.fromObject = function fromObject(object) {
        if (object instanceof $root.Contact)
            return object;
        let message = new $root.Contact();
        if (object.email != null)
            message.email = String(object.email);
        if (object.PhoneNumber != null) {
            if (typeof object.PhoneNumber !== "object")
                throw TypeError(".Contact.PhoneNumber: object expected");
            message.PhoneNumber = $root.PhoneNumber.fromObject(object.PhoneNumber);
        }
        if (object.SocialNetworkId) {
            if (!Array.isArray(object.SocialNetworkId))
                throw TypeError(".Contact.SocialNetworkId: array expected");
            message.SocialNetworkId = [];
            for (let i = 0; i < object.SocialNetworkId.length; ++i) {
                if (typeof object.SocialNetworkId[i] !== "object")
                    throw TypeError(".Contact.SocialNetworkId: object expected");
                message.SocialNetworkId[i] = $root.SocialNetworkId.fromObject(object.SocialNetworkId[i]);
            }
        }
        if (object.ZippedContactList != null) {
            if (typeof object.ZippedContactList !== "object")
                throw TypeError(".Contact.ZippedContactList: object expected");
            message.ZippedContactList = $root.ZippedContactList.fromObject(object.ZippedContactList);
        }
        switch (object.verificationProvider) {
        default:
            if (typeof object.verificationProvider === "number") {
                message.verificationProvider = object.verificationProvider;
                break;
            }
            break;
        case "VERIFICATION_PROVIDER_TANGO_SMS":
        case 1:
            message.verificationProvider = 1;
            break;
        case "VERIFICATION_PROVIDER_TWITTER":
        case 2:
            message.verificationProvider = 2;
            break;
        case "VERIFICATION_PROVIDER_FB_ACCOUNT_KIT":
        case 3:
            message.verificationProvider = 3;
            break;
        }
        if (object.verificationParams != null) {
            if (typeof object.verificationParams !== "object")
                throw TypeError(".Contact.verificationParams: object expected");
            message.verificationParams = $root.VerificationParams.fromObject(object.verificationParams);
        }
        if (object.abookSize != null)
            message.abookSize = object.abookSize | 0;
        if (object.device != null)
            message.device = String(object.device);
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.hash != null)
            message.hash = String(object.hash);
        if (object.isPhone != null)
            message.isPhone = Boolean(object.isPhone);
        if (object.iso2CountryCode != null)
            message.iso2CountryCode = String(object.iso2CountryCode);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.linkAccounts != null)
            message.linkAccounts = Boolean(object.linkAccounts);
        if (object.locale != null)
            message.locale = String(object.locale);
        return message;
    };

    Contact.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.SocialNetworkId = [];
        if (options.defaults) {
            object.email = "";
            object.PhoneNumber = null;
            object.ZippedContactList = null;
            object.verificationProvider = options.enums === String ? "VERIFICATION_PROVIDER_TANGO_SMS" : 1;
            object.verificationParams = null;
            object.abookSize = 0;
            object.device = "";
            object.firstName = "";
            object.hash = "";
            object.isPhone = false;
            object.iso2CountryCode = "";
            object.lastName = "";
            object.linkAccounts = false;
            object.locale = "";
        }
        if (message.email != null && message.hasOwnProperty("email"))
            object.email = message.email;
        if (message.PhoneNumber != null && message.hasOwnProperty("PhoneNumber"))
            object.PhoneNumber = $root.PhoneNumber.toObject(message.PhoneNumber, options);
        if (message.SocialNetworkId && message.SocialNetworkId.length) {
            object.SocialNetworkId = [];
            for (let j = 0; j < message.SocialNetworkId.length; ++j)
                object.SocialNetworkId[j] = $root.SocialNetworkId.toObject(message.SocialNetworkId[j], options);
        }
        if (message.ZippedContactList != null && message.hasOwnProperty("ZippedContactList"))
            object.ZippedContactList = $root.ZippedContactList.toObject(message.ZippedContactList, options);
        if (message.verificationProvider != null && message.hasOwnProperty("verificationProvider"))
            object.verificationProvider = options.enums === String ? $root.VerificationProvider[message.verificationProvider] === undefined ? message.verificationProvider : $root.VerificationProvider[message.verificationProvider] : message.verificationProvider;
        if (message.verificationParams != null && message.hasOwnProperty("verificationParams"))
            object.verificationParams = $root.VerificationParams.toObject(message.verificationParams, options);
        if (message.abookSize != null && message.hasOwnProperty("abookSize"))
            object.abookSize = message.abookSize;
        if (message.device != null && message.hasOwnProperty("device"))
            object.device = message.device;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.hash != null && message.hasOwnProperty("hash"))
            object.hash = message.hash;
        if (message.isPhone != null && message.hasOwnProperty("isPhone"))
            object.isPhone = message.isPhone;
        if (message.iso2CountryCode != null && message.hasOwnProperty("iso2CountryCode"))
            object.iso2CountryCode = message.iso2CountryCode;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.linkAccounts != null && message.hasOwnProperty("linkAccounts"))
            object.linkAccounts = message.linkAccounts;
        if (message.locale != null && message.hasOwnProperty("locale"))
            object.locale = message.locale;
        return object;
    };

    Contact.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Contact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Contact";
    };

    return Contact;
})();

export const CtaStatusType = $root.CtaStatusType = (() => {

    function CtaStatusType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CtaStatusType.prototype.accountStatus = 0;
    CtaStatusType.prototype.accountStatusMessage = "";
    CtaStatusType.prototype.accountStatusTitle = "";

    CtaStatusType.create = function create(properties) {
        return new CtaStatusType(properties);
    };

    CtaStatusType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.accountStatus);
        writer.uint32(18).string(message.accountStatusMessage);
        writer.uint32(26).string(message.accountStatusTitle);
        return writer;
    };

    CtaStatusType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CtaStatusType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CtaStatusType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.accountStatus = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.accountStatusMessage = reader.string();
                    break;
                }
            case 3: {
                    message.accountStatusTitle = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("accountStatus"))
            throw $util.ProtocolError("missing required 'accountStatus'", { instance: message });
        if (!message.hasOwnProperty("accountStatusMessage"))
            throw $util.ProtocolError("missing required 'accountStatusMessage'", { instance: message });
        if (!message.hasOwnProperty("accountStatusTitle"))
            throw $util.ProtocolError("missing required 'accountStatusTitle'", { instance: message });
        return message;
    };

    CtaStatusType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CtaStatusType.fromObject = function fromObject(object) {
        if (object instanceof $root.CtaStatusType)
            return object;
        let message = new $root.CtaStatusType();
        if (object.accountStatus != null)
            message.accountStatus = object.accountStatus | 0;
        if (object.accountStatusMessage != null)
            message.accountStatusMessage = String(object.accountStatusMessage);
        if (object.accountStatusTitle != null)
            message.accountStatusTitle = String(object.accountStatusTitle);
        return message;
    };

    CtaStatusType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.accountStatus = 0;
            object.accountStatusMessage = "";
            object.accountStatusTitle = "";
        }
        if (message.accountStatus != null && message.hasOwnProperty("accountStatus"))
            object.accountStatus = message.accountStatus;
        if (message.accountStatusMessage != null && message.hasOwnProperty("accountStatusMessage"))
            object.accountStatusMessage = message.accountStatusMessage;
        if (message.accountStatusTitle != null && message.hasOwnProperty("accountStatusTitle"))
            object.accountStatusTitle = message.accountStatusTitle;
        return object;
    };

    CtaStatusType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CtaStatusType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CtaStatusType";
    };

    return CtaStatusType;
})();

export const DeviceToken = $root.DeviceToken = (() => {

    function DeviceToken(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    DeviceToken.prototype.content = "";
    DeviceToken.prototype.type = "";
    DeviceToken.prototype.version = "";

    DeviceToken.create = function create(properties) {
        return new DeviceToken(properties);
    };

    DeviceToken.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.content);
        writer.uint32(18).string(message.type);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(26).string(message.version);
        return writer;
    };

    DeviceToken.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    DeviceToken.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeviceToken();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.content = reader.string();
                    break;
                }
            case 2: {
                    message.type = reader.string();
                    break;
                }
            case 3: {
                    message.version = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("content"))
            throw $util.ProtocolError("missing required 'content'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        return message;
    };

    DeviceToken.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    DeviceToken.fromObject = function fromObject(object) {
        if (object instanceof $root.DeviceToken)
            return object;
        let message = new $root.DeviceToken();
        if (object.content != null)
            message.content = String(object.content);
        if (object.type != null)
            message.type = String(object.type);
        if (object.version != null)
            message.version = String(object.version);
        return message;
    };

    DeviceToken.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.content = "";
            object.type = "";
            object.version = "";
        }
        if (message.content != null && message.hasOwnProperty("content"))
            object.content = message.content;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        return object;
    };

    DeviceToken.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    DeviceToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeviceToken";
    };

    return DeviceToken;
})();

export const DeviceTokenList = $root.DeviceTokenList = (() => {

    function DeviceTokenList(properties) {
        this.DeviceToken = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    DeviceTokenList.prototype.DeviceToken = $util.emptyArray;

    DeviceTokenList.create = function create(properties) {
        return new DeviceTokenList(properties);
    };

    DeviceTokenList.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.DeviceToken != null && message.DeviceToken.length)
            for (let i = 0; i < message.DeviceToken.length; ++i)
                $root.DeviceToken.encode(message.DeviceToken[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    DeviceTokenList.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    DeviceTokenList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeviceTokenList();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.DeviceToken && message.DeviceToken.length))
                        message.DeviceToken = [];
                    message.DeviceToken.push($root.DeviceToken.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    DeviceTokenList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    DeviceTokenList.fromObject = function fromObject(object) {
        if (object instanceof $root.DeviceTokenList)
            return object;
        let message = new $root.DeviceTokenList();
        if (object.DeviceToken) {
            if (!Array.isArray(object.DeviceToken))
                throw TypeError(".DeviceTokenList.DeviceToken: array expected");
            message.DeviceToken = [];
            for (let i = 0; i < object.DeviceToken.length; ++i) {
                if (typeof object.DeviceToken[i] !== "object")
                    throw TypeError(".DeviceTokenList.DeviceToken: object expected");
                message.DeviceToken[i] = $root.DeviceToken.fromObject(object.DeviceToken[i]);
            }
        }
        return message;
    };

    DeviceTokenList.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.DeviceToken = [];
        if (message.DeviceToken && message.DeviceToken.length) {
            object.DeviceToken = [];
            for (let j = 0; j < message.DeviceToken.length; ++j)
                object.DeviceToken[j] = $root.DeviceToken.toObject(message.DeviceToken[j], options);
        }
        return object;
    };

    DeviceTokenList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    DeviceTokenList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeviceTokenList";
    };

    return DeviceTokenList;
})();

export const LinkedDevices = $root.LinkedDevices = (() => {

    function LinkedDevices(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    LinkedDevices.prototype.deviceName = "";
    LinkedDevices.prototype.clientOsVersion = "";
    LinkedDevices.prototype.clientVersion = "";
    LinkedDevices.prototype.platform = 0;

    LinkedDevices.create = function create(properties) {
        return new LinkedDevices(properties);
    };

    LinkedDevices.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.deviceName != null && Object.hasOwnProperty.call(message, "deviceName"))
            writer.uint32(10).string(message.deviceName);
        if (message.clientOsVersion != null && Object.hasOwnProperty.call(message, "clientOsVersion"))
            writer.uint32(18).string(message.clientOsVersion);
        if (message.clientVersion != null && Object.hasOwnProperty.call(message, "clientVersion"))
            writer.uint32(26).string(message.clientVersion);
        if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
            writer.uint32(37).sfixed32(message.platform);
        return writer;
    };

    LinkedDevices.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    LinkedDevices.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LinkedDevices();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.deviceName = reader.string();
                    break;
                }
            case 2: {
                    message.clientOsVersion = reader.string();
                    break;
                }
            case 3: {
                    message.clientVersion = reader.string();
                    break;
                }
            case 4: {
                    message.platform = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    LinkedDevices.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    LinkedDevices.fromObject = function fromObject(object) {
        if (object instanceof $root.LinkedDevices)
            return object;
        let message = new $root.LinkedDevices();
        if (object.deviceName != null)
            message.deviceName = String(object.deviceName);
        if (object.clientOsVersion != null)
            message.clientOsVersion = String(object.clientOsVersion);
        if (object.clientVersion != null)
            message.clientVersion = String(object.clientVersion);
        if (object.platform != null)
            message.platform = object.platform | 0;
        return message;
    };

    LinkedDevices.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.deviceName = "";
            object.clientOsVersion = "";
            object.clientVersion = "";
            object.platform = 0;
        }
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            object.deviceName = message.deviceName;
        if (message.clientOsVersion != null && message.hasOwnProperty("clientOsVersion"))
            object.clientOsVersion = message.clientOsVersion;
        if (message.clientVersion != null && message.hasOwnProperty("clientVersion"))
            object.clientVersion = message.clientVersion;
        if (message.platform != null && message.hasOwnProperty("platform"))
            object.platform = message.platform;
        return object;
    };

    LinkedDevices.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    LinkedDevices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LinkedDevices";
    };

    return LinkedDevices;
})();

export const Options = $root.Options = (() => {

    function Options(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Options.prototype.allowAccessAddressBook = 0;
    Options.prototype.storeAddressBook = 0;

    Options.create = function create(properties) {
        return new Options(properties);
    };

    Options.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.allowAccessAddressBook != null && Object.hasOwnProperty.call(message, "allowAccessAddressBook"))
            writer.uint32(13).sfixed32(message.allowAccessAddressBook);
        if (message.storeAddressBook != null && Object.hasOwnProperty.call(message, "storeAddressBook"))
            writer.uint32(21).sfixed32(message.storeAddressBook);
        return writer;
    };

    Options.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Options.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Options();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.allowAccessAddressBook = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.storeAddressBook = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Options.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Options.fromObject = function fromObject(object) {
        if (object instanceof $root.Options)
            return object;
        let message = new $root.Options();
        if (object.allowAccessAddressBook != null)
            message.allowAccessAddressBook = object.allowAccessAddressBook | 0;
        if (object.storeAddressBook != null)
            message.storeAddressBook = object.storeAddressBook | 0;
        return message;
    };

    Options.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.allowAccessAddressBook = 0;
            object.storeAddressBook = 0;
        }
        if (message.allowAccessAddressBook != null && message.hasOwnProperty("allowAccessAddressBook"))
            object.allowAccessAddressBook = message.allowAccessAddressBook;
        if (message.storeAddressBook != null && message.hasOwnProperty("storeAddressBook"))
            object.storeAddressBook = message.storeAddressBook;
        return object;
    };

    Options.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Options.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Options";
    };

    return Options;
})();

export const PhoneNumber = $root.PhoneNumber = (() => {

    function PhoneNumber(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PhoneNumber.prototype.iso2CountryCode = "";
    PhoneNumber.prototype.subscriberNumber = "";

    PhoneNumber.create = function create(properties) {
        return new PhoneNumber(properties);
    };

    PhoneNumber.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.iso2CountryCode != null && Object.hasOwnProperty.call(message, "iso2CountryCode"))
            writer.uint32(10).string(message.iso2CountryCode);
        if (message.subscriberNumber != null && Object.hasOwnProperty.call(message, "subscriberNumber"))
            writer.uint32(18).string(message.subscriberNumber);
        return writer;
    };

    PhoneNumber.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PhoneNumber.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PhoneNumber();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.iso2CountryCode = reader.string();
                    break;
                }
            case 2: {
                    message.subscriberNumber = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PhoneNumber.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PhoneNumber.fromObject = function fromObject(object) {
        if (object instanceof $root.PhoneNumber)
            return object;
        let message = new $root.PhoneNumber();
        if (object.iso2CountryCode != null)
            message.iso2CountryCode = String(object.iso2CountryCode);
        if (object.subscriberNumber != null)
            message.subscriberNumber = String(object.subscriberNumber);
        return message;
    };

    PhoneNumber.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.iso2CountryCode = "";
            object.subscriberNumber = "";
        }
        if (message.iso2CountryCode != null && message.hasOwnProperty("iso2CountryCode"))
            object.iso2CountryCode = message.iso2CountryCode;
        if (message.subscriberNumber != null && message.hasOwnProperty("subscriberNumber"))
            object.subscriberNumber = message.subscriberNumber;
        return object;
    };

    PhoneNumber.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PhoneNumber.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PhoneNumber";
    };

    return PhoneNumber;
})();

export const SocialIdStatusType = $root.SocialIdStatusType = (() => {

    function SocialIdStatusType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SocialIdStatusType.prototype.iso2CountryCode = "";
    SocialIdStatusType.prototype.originalPrimaryNumber = "";
    SocialIdStatusType.prototype.normalizedPrimaryNumber = "";
    SocialIdStatusType.prototype.primaryEmail = "";

    SocialIdStatusType.create = function create(properties) {
        return new SocialIdStatusType(properties);
    };

    SocialIdStatusType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.iso2CountryCode);
        if (message.originalPrimaryNumber != null && Object.hasOwnProperty.call(message, "originalPrimaryNumber"))
            writer.uint32(18).string(message.originalPrimaryNumber);
        if (message.normalizedPrimaryNumber != null && Object.hasOwnProperty.call(message, "normalizedPrimaryNumber"))
            writer.uint32(26).string(message.normalizedPrimaryNumber);
        if (message.primaryEmail != null && Object.hasOwnProperty.call(message, "primaryEmail"))
            writer.uint32(34).string(message.primaryEmail);
        return writer;
    };

    SocialIdStatusType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SocialIdStatusType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SocialIdStatusType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.iso2CountryCode = reader.string();
                    break;
                }
            case 2: {
                    message.originalPrimaryNumber = reader.string();
                    break;
                }
            case 3: {
                    message.normalizedPrimaryNumber = reader.string();
                    break;
                }
            case 4: {
                    message.primaryEmail = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("iso2CountryCode"))
            throw $util.ProtocolError("missing required 'iso2CountryCode'", { instance: message });
        return message;
    };

    SocialIdStatusType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SocialIdStatusType.fromObject = function fromObject(object) {
        if (object instanceof $root.SocialIdStatusType)
            return object;
        let message = new $root.SocialIdStatusType();
        if (object.iso2CountryCode != null)
            message.iso2CountryCode = String(object.iso2CountryCode);
        if (object.originalPrimaryNumber != null)
            message.originalPrimaryNumber = String(object.originalPrimaryNumber);
        if (object.normalizedPrimaryNumber != null)
            message.normalizedPrimaryNumber = String(object.normalizedPrimaryNumber);
        if (object.primaryEmail != null)
            message.primaryEmail = String(object.primaryEmail);
        return message;
    };

    SocialIdStatusType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.iso2CountryCode = "";
            object.originalPrimaryNumber = "";
            object.normalizedPrimaryNumber = "";
            object.primaryEmail = "";
        }
        if (message.iso2CountryCode != null && message.hasOwnProperty("iso2CountryCode"))
            object.iso2CountryCode = message.iso2CountryCode;
        if (message.originalPrimaryNumber != null && message.hasOwnProperty("originalPrimaryNumber"))
            object.originalPrimaryNumber = message.originalPrimaryNumber;
        if (message.normalizedPrimaryNumber != null && message.hasOwnProperty("normalizedPrimaryNumber"))
            object.normalizedPrimaryNumber = message.normalizedPrimaryNumber;
        if (message.primaryEmail != null && message.hasOwnProperty("primaryEmail"))
            object.primaryEmail = message.primaryEmail;
        return object;
    };

    SocialIdStatusType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SocialIdStatusType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SocialIdStatusType";
    };

    return SocialIdStatusType;
})();

export const SocialNetworkId = $root.SocialNetworkId = (() => {

    function SocialNetworkId(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SocialNetworkId.prototype.id = "";
    SocialNetworkId.prototype.socialNetworkName = "";

    SocialNetworkId.create = function create(properties) {
        return new SocialNetworkId(properties);
    };

    SocialNetworkId.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(10).string(message.id);
        if (message.socialNetworkName != null && Object.hasOwnProperty.call(message, "socialNetworkName"))
            writer.uint32(18).string(message.socialNetworkName);
        return writer;
    };

    SocialNetworkId.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SocialNetworkId.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SocialNetworkId();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.socialNetworkName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SocialNetworkId.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SocialNetworkId.fromObject = function fromObject(object) {
        if (object instanceof $root.SocialNetworkId)
            return object;
        let message = new $root.SocialNetworkId();
        if (object.id != null)
            message.id = String(object.id);
        if (object.socialNetworkName != null)
            message.socialNetworkName = String(object.socialNetworkName);
        return message;
    };

    SocialNetworkId.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.socialNetworkName = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.socialNetworkName != null && message.hasOwnProperty("socialNetworkName"))
            object.socialNetworkName = message.socialNetworkName;
        return object;
    };

    SocialNetworkId.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SocialNetworkId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SocialNetworkId";
    };

    return SocialNetworkId;
})();

export const TangoDevice = $root.TangoDevice = (() => {

    function TangoDevice(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TangoDevice.prototype.deviceId = "";
    TangoDevice.prototype.clientOsVersion = "";
    TangoDevice.prototype.clientVersion = "";
    TangoDevice.prototype.platform = 0;

    TangoDevice.create = function create(properties) {
        return new TangoDevice(properties);
    };

    TangoDevice.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
            writer.uint32(10).string(message.deviceId);
        if (message.clientOsVersion != null && Object.hasOwnProperty.call(message, "clientOsVersion"))
            writer.uint32(18).string(message.clientOsVersion);
        if (message.clientVersion != null && Object.hasOwnProperty.call(message, "clientVersion"))
            writer.uint32(26).string(message.clientVersion);
        if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
            writer.uint32(37).sfixed32(message.platform);
        return writer;
    };

    TangoDevice.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TangoDevice.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TangoDevice();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.deviceId = reader.string();
                    break;
                }
            case 2: {
                    message.clientOsVersion = reader.string();
                    break;
                }
            case 3: {
                    message.clientVersion = reader.string();
                    break;
                }
            case 4: {
                    message.platform = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    TangoDevice.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TangoDevice.fromObject = function fromObject(object) {
        if (object instanceof $root.TangoDevice)
            return object;
        let message = new $root.TangoDevice();
        if (object.deviceId != null)
            message.deviceId = String(object.deviceId);
        if (object.clientOsVersion != null)
            message.clientOsVersion = String(object.clientOsVersion);
        if (object.clientVersion != null)
            message.clientVersion = String(object.clientVersion);
        if (object.platform != null)
            message.platform = object.platform | 0;
        return message;
    };

    TangoDevice.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.deviceId = "";
            object.clientOsVersion = "";
            object.clientVersion = "";
            object.platform = 0;
        }
        if (message.deviceId != null && message.hasOwnProperty("deviceId"))
            object.deviceId = message.deviceId;
        if (message.clientOsVersion != null && message.hasOwnProperty("clientOsVersion"))
            object.clientOsVersion = message.clientOsVersion;
        if (message.clientVersion != null && message.hasOwnProperty("clientVersion"))
            object.clientVersion = message.clientVersion;
        if (message.platform != null && message.hasOwnProperty("platform"))
            object.platform = message.platform;
        return object;
    };

    TangoDevice.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TangoDevice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TangoDevice";
    };

    return TangoDevice;
})();

export const TangoRegistrationRequest = $root.TangoRegistrationRequest = (() => {

    function TangoRegistrationRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TangoRegistrationRequest.prototype.Contact = null;
    TangoRegistrationRequest.prototype.TangoDevice = null;
    TangoRegistrationRequest.prototype.DeviceTokenList = null;
    TangoRegistrationRequest.prototype.Options = null;
    TangoRegistrationRequest.prototype.Capabilities = null;
    TangoRegistrationRequest.prototype.applicationId = "";
    TangoRegistrationRequest.prototype.accountId = "";
    TangoRegistrationRequest.prototype.cloudSecret = "";
    TangoRegistrationRequest.prototype.password = "";
    TangoRegistrationRequest.prototype.phonenumberOnly = false;
    TangoRegistrationRequest.prototype.registrationAction = 1;
    TangoRegistrationRequest.prototype.requestAuthtokens = false;
    TangoRegistrationRequest.prototype.swiftPassword = "";
    TangoRegistrationRequest.prototype.username = "";

    TangoRegistrationRequest.create = function create(properties) {
        return new TangoRegistrationRequest(properties);
    };

    TangoRegistrationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.Contact.encode(message.Contact, writer.uint32(10).fork()).ldelim();
        $root.TangoDevice.encode(message.TangoDevice, writer.uint32(18).fork()).ldelim();
        if (message.DeviceTokenList != null && Object.hasOwnProperty.call(message, "DeviceTokenList"))
            $root.DeviceTokenList.encode(message.DeviceTokenList, writer.uint32(26).fork()).ldelim();
        $root.Options.encode(message.Options, writer.uint32(34).fork()).ldelim();
        if (message.Capabilities != null && Object.hasOwnProperty.call(message, "Capabilities"))
            $root.Capabilities.encode(message.Capabilities, writer.uint32(42).fork()).ldelim();
        writer.uint32(50).string(message.applicationId);
        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
            writer.uint32(58).string(message.accountId);
        if (message.cloudSecret != null && Object.hasOwnProperty.call(message, "cloudSecret"))
            writer.uint32(66).string(message.cloudSecret);
        if (message.password != null && Object.hasOwnProperty.call(message, "password"))
            writer.uint32(74).string(message.password);
        if (message.phonenumberOnly != null && Object.hasOwnProperty.call(message, "phonenumberOnly"))
            writer.uint32(80).bool(message.phonenumberOnly);
        if (message.registrationAction != null && Object.hasOwnProperty.call(message, "registrationAction"))
            writer.uint32(88).int32(message.registrationAction);
        if (message.requestAuthtokens != null && Object.hasOwnProperty.call(message, "requestAuthtokens"))
            writer.uint32(96).bool(message.requestAuthtokens);
        if (message.swiftPassword != null && Object.hasOwnProperty.call(message, "swiftPassword"))
            writer.uint32(106).string(message.swiftPassword);
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(114).string(message.username);
        return writer;
    };

    TangoRegistrationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TangoRegistrationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TangoRegistrationRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Contact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.TangoDevice = $root.TangoDevice.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.DeviceTokenList = $root.DeviceTokenList.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.Options = $root.Options.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.Capabilities = $root.Capabilities.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.applicationId = reader.string();
                    break;
                }
            case 7: {
                    message.accountId = reader.string();
                    break;
                }
            case 8: {
                    message.cloudSecret = reader.string();
                    break;
                }
            case 9: {
                    message.password = reader.string();
                    break;
                }
            case 10: {
                    message.phonenumberOnly = reader.bool();
                    break;
                }
            case 11: {
                    message.registrationAction = reader.int32();
                    break;
                }
            case 12: {
                    message.requestAuthtokens = reader.bool();
                    break;
                }
            case 13: {
                    message.swiftPassword = reader.string();
                    break;
                }
            case 14: {
                    message.username = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("Contact"))
            throw $util.ProtocolError("missing required 'Contact'", { instance: message });
        if (!message.hasOwnProperty("TangoDevice"))
            throw $util.ProtocolError("missing required 'TangoDevice'", { instance: message });
        if (!message.hasOwnProperty("Options"))
            throw $util.ProtocolError("missing required 'Options'", { instance: message });
        if (!message.hasOwnProperty("applicationId"))
            throw $util.ProtocolError("missing required 'applicationId'", { instance: message });
        return message;
    };

    TangoRegistrationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TangoRegistrationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.TangoRegistrationRequest)
            return object;
        let message = new $root.TangoRegistrationRequest();
        if (object.Contact != null) {
            if (typeof object.Contact !== "object")
                throw TypeError(".TangoRegistrationRequest.Contact: object expected");
            message.Contact = $root.Contact.fromObject(object.Contact);
        }
        if (object.TangoDevice != null) {
            if (typeof object.TangoDevice !== "object")
                throw TypeError(".TangoRegistrationRequest.TangoDevice: object expected");
            message.TangoDevice = $root.TangoDevice.fromObject(object.TangoDevice);
        }
        if (object.DeviceTokenList != null) {
            if (typeof object.DeviceTokenList !== "object")
                throw TypeError(".TangoRegistrationRequest.DeviceTokenList: object expected");
            message.DeviceTokenList = $root.DeviceTokenList.fromObject(object.DeviceTokenList);
        }
        if (object.Options != null) {
            if (typeof object.Options !== "object")
                throw TypeError(".TangoRegistrationRequest.Options: object expected");
            message.Options = $root.Options.fromObject(object.Options);
        }
        if (object.Capabilities != null) {
            if (typeof object.Capabilities !== "object")
                throw TypeError(".TangoRegistrationRequest.Capabilities: object expected");
            message.Capabilities = $root.Capabilities.fromObject(object.Capabilities);
        }
        if (object.applicationId != null)
            message.applicationId = String(object.applicationId);
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        if (object.cloudSecret != null)
            message.cloudSecret = String(object.cloudSecret);
        if (object.password != null)
            message.password = String(object.password);
        if (object.phonenumberOnly != null)
            message.phonenumberOnly = Boolean(object.phonenumberOnly);
        switch (object.registrationAction) {
        default:
            if (typeof object.registrationAction === "number") {
                message.registrationAction = object.registrationAction;
                break;
            }
            break;
        case "UNDEFINED":
        case 1:
            message.registrationAction = 1;
            break;
        case "REGISTER":
        case 2:
            message.registrationAction = 2;
            break;
        case "UPDATE":
        case 3:
            message.registrationAction = 3;
            break;
        case "VALIDATE":
        case 4:
            message.registrationAction = 4;
            break;
        case "VALIDATE_DEVICE":
        case 5:
            message.registrationAction = 5;
            break;
        case "VALIDATE_LINKING":
        case 6:
            message.registrationAction = 6;
            break;
        case "REGISTER_VALIDATED":
        case 7:
            message.registrationAction = 7;
            break;
        case "PRE_REGISTER":
        case 8:
            message.registrationAction = 8;
            break;
        case "DEVICE_LINKED":
        case 9:
            message.registrationAction = 9;
            break;
        }
        if (object.requestAuthtokens != null)
            message.requestAuthtokens = Boolean(object.requestAuthtokens);
        if (object.swiftPassword != null)
            message.swiftPassword = String(object.swiftPassword);
        if (object.username != null)
            message.username = String(object.username);
        return message;
    };

    TangoRegistrationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.Contact = null;
            object.TangoDevice = null;
            object.DeviceTokenList = null;
            object.Options = null;
            object.Capabilities = null;
            object.applicationId = "";
            object.accountId = "";
            object.cloudSecret = "";
            object.password = "";
            object.phonenumberOnly = false;
            object.registrationAction = options.enums === String ? "UNDEFINED" : 1;
            object.requestAuthtokens = false;
            object.swiftPassword = "";
            object.username = "";
        }
        if (message.Contact != null && message.hasOwnProperty("Contact"))
            object.Contact = $root.Contact.toObject(message.Contact, options);
        if (message.TangoDevice != null && message.hasOwnProperty("TangoDevice"))
            object.TangoDevice = $root.TangoDevice.toObject(message.TangoDevice, options);
        if (message.DeviceTokenList != null && message.hasOwnProperty("DeviceTokenList"))
            object.DeviceTokenList = $root.DeviceTokenList.toObject(message.DeviceTokenList, options);
        if (message.Options != null && message.hasOwnProperty("Options"))
            object.Options = $root.Options.toObject(message.Options, options);
        if (message.Capabilities != null && message.hasOwnProperty("Capabilities"))
            object.Capabilities = $root.Capabilities.toObject(message.Capabilities, options);
        if (message.applicationId != null && message.hasOwnProperty("applicationId"))
            object.applicationId = message.applicationId;
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.cloudSecret != null && message.hasOwnProperty("cloudSecret"))
            object.cloudSecret = message.cloudSecret;
        if (message.password != null && message.hasOwnProperty("password"))
            object.password = message.password;
        if (message.phonenumberOnly != null && message.hasOwnProperty("phonenumberOnly"))
            object.phonenumberOnly = message.phonenumberOnly;
        if (message.registrationAction != null && message.hasOwnProperty("registrationAction"))
            object.registrationAction = options.enums === String ? $root.RegistrationActionReturnType[message.registrationAction] === undefined ? message.registrationAction : $root.RegistrationActionReturnType[message.registrationAction] : message.registrationAction;
        if (message.requestAuthtokens != null && message.hasOwnProperty("requestAuthtokens"))
            object.requestAuthtokens = message.requestAuthtokens;
        if (message.swiftPassword != null && message.hasOwnProperty("swiftPassword"))
            object.swiftPassword = message.swiftPassword;
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        return object;
    };

    TangoRegistrationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TangoRegistrationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TangoRegistrationRequest";
    };

    return TangoRegistrationRequest;
})();

export const TangoRegistrationResponse = $root.TangoRegistrationResponse = (() => {

    function TangoRegistrationResponse(properties) {
        this.ctaStatus = [];
        this.LinkedDevices = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TangoRegistrationResponse.prototype.ctaStatus = $util.emptyArray;
    TangoRegistrationResponse.prototype.socialIdStatus = null;
    TangoRegistrationResponse.prototype.type = "";
    TangoRegistrationResponse.prototype.reason = "";
    TangoRegistrationResponse.prototype.accountId = "";
    TangoRegistrationResponse.prototype.registrationAction = 1;
    TangoRegistrationResponse.prototype.authTokens = "";
    TangoRegistrationResponse.prototype.cloudSecret = "";
    TangoRegistrationResponse.prototype.codeEmailed = false;
    TangoRegistrationResponse.prototype.conflictAccountId = "";
    TangoRegistrationResponse.prototype.LinkedDevices = $util.emptyArray;
    TangoRegistrationResponse.prototype.preferredVerificationProvider = 1;
    TangoRegistrationResponse.prototype.jwtAuthTokens = null;

    TangoRegistrationResponse.create = function create(properties) {
        return new TangoRegistrationResponse(properties);
    };

    TangoRegistrationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ctaStatus != null && message.ctaStatus.length)
            for (let i = 0; i < message.ctaStatus.length; ++i)
                $root.CtaStatusType.encode(message.ctaStatus[i], writer.uint32(10).fork()).ldelim();
        if (message.socialIdStatus != null && Object.hasOwnProperty.call(message, "socialIdStatus"))
            $root.SocialIdStatusType.encode(message.socialIdStatus, writer.uint32(18).fork()).ldelim();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(26).string(message.type);
        if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
            writer.uint32(34).string(message.reason);
        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
            writer.uint32(42).string(message.accountId);
        if (message.registrationAction != null && Object.hasOwnProperty.call(message, "registrationAction"))
            writer.uint32(48).int32(message.registrationAction);
        if (message.authTokens != null && Object.hasOwnProperty.call(message, "authTokens"))
            writer.uint32(58).string(message.authTokens);
        if (message.cloudSecret != null && Object.hasOwnProperty.call(message, "cloudSecret"))
            writer.uint32(66).string(message.cloudSecret);
        if (message.codeEmailed != null && Object.hasOwnProperty.call(message, "codeEmailed"))
            writer.uint32(72).bool(message.codeEmailed);
        if (message.conflictAccountId != null && Object.hasOwnProperty.call(message, "conflictAccountId"))
            writer.uint32(82).string(message.conflictAccountId);
        if (message.LinkedDevices != null && message.LinkedDevices.length)
            for (let i = 0; i < message.LinkedDevices.length; ++i)
                $root.LinkedDevices.encode(message.LinkedDevices[i], writer.uint32(90).fork()).ldelim();
        if (message.preferredVerificationProvider != null && Object.hasOwnProperty.call(message, "preferredVerificationProvider"))
            writer.uint32(96).int32(message.preferredVerificationProvider);
        if (message.jwtAuthTokens != null && Object.hasOwnProperty.call(message, "jwtAuthTokens"))
            $root.JwtAuthTokens.encode(message.jwtAuthTokens, writer.uint32(106).fork()).ldelim();
        return writer;
    };

    TangoRegistrationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TangoRegistrationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TangoRegistrationResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.ctaStatus && message.ctaStatus.length))
                        message.ctaStatus = [];
                    message.ctaStatus.push($root.CtaStatusType.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    message.socialIdStatus = $root.SocialIdStatusType.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.type = reader.string();
                    break;
                }
            case 4: {
                    message.reason = reader.string();
                    break;
                }
            case 5: {
                    message.accountId = reader.string();
                    break;
                }
            case 6: {
                    message.registrationAction = reader.int32();
                    break;
                }
            case 7: {
                    message.authTokens = reader.string();
                    break;
                }
            case 8: {
                    message.cloudSecret = reader.string();
                    break;
                }
            case 9: {
                    message.codeEmailed = reader.bool();
                    break;
                }
            case 10: {
                    message.conflictAccountId = reader.string();
                    break;
                }
            case 11: {
                    if (!(message.LinkedDevices && message.LinkedDevices.length))
                        message.LinkedDevices = [];
                    message.LinkedDevices.push($root.LinkedDevices.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    message.preferredVerificationProvider = reader.int32();
                    break;
                }
            case 13: {
                    message.jwtAuthTokens = $root.JwtAuthTokens.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    TangoRegistrationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TangoRegistrationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.TangoRegistrationResponse)
            return object;
        let message = new $root.TangoRegistrationResponse();
        if (object.ctaStatus) {
            if (!Array.isArray(object.ctaStatus))
                throw TypeError(".TangoRegistrationResponse.ctaStatus: array expected");
            message.ctaStatus = [];
            for (let i = 0; i < object.ctaStatus.length; ++i) {
                if (typeof object.ctaStatus[i] !== "object")
                    throw TypeError(".TangoRegistrationResponse.ctaStatus: object expected");
                message.ctaStatus[i] = $root.CtaStatusType.fromObject(object.ctaStatus[i]);
            }
        }
        if (object.socialIdStatus != null) {
            if (typeof object.socialIdStatus !== "object")
                throw TypeError(".TangoRegistrationResponse.socialIdStatus: object expected");
            message.socialIdStatus = $root.SocialIdStatusType.fromObject(object.socialIdStatus);
        }
        if (object.type != null)
            message.type = String(object.type);
        if (object.reason != null)
            message.reason = String(object.reason);
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        switch (object.registrationAction) {
        default:
            if (typeof object.registrationAction === "number") {
                message.registrationAction = object.registrationAction;
                break;
            }
            break;
        case "UNDEFINED":
        case 1:
            message.registrationAction = 1;
            break;
        case "REGISTER":
        case 2:
            message.registrationAction = 2;
            break;
        case "UPDATE":
        case 3:
            message.registrationAction = 3;
            break;
        case "VALIDATE":
        case 4:
            message.registrationAction = 4;
            break;
        case "VALIDATE_DEVICE":
        case 5:
            message.registrationAction = 5;
            break;
        case "VALIDATE_LINKING":
        case 6:
            message.registrationAction = 6;
            break;
        case "REGISTER_VALIDATED":
        case 7:
            message.registrationAction = 7;
            break;
        case "PRE_REGISTER":
        case 8:
            message.registrationAction = 8;
            break;
        case "DEVICE_LINKED":
        case 9:
            message.registrationAction = 9;
            break;
        }
        if (object.authTokens != null)
            message.authTokens = String(object.authTokens);
        if (object.cloudSecret != null)
            message.cloudSecret = String(object.cloudSecret);
        if (object.codeEmailed != null)
            message.codeEmailed = Boolean(object.codeEmailed);
        if (object.conflictAccountId != null)
            message.conflictAccountId = String(object.conflictAccountId);
        if (object.LinkedDevices) {
            if (!Array.isArray(object.LinkedDevices))
                throw TypeError(".TangoRegistrationResponse.LinkedDevices: array expected");
            message.LinkedDevices = [];
            for (let i = 0; i < object.LinkedDevices.length; ++i) {
                if (typeof object.LinkedDevices[i] !== "object")
                    throw TypeError(".TangoRegistrationResponse.LinkedDevices: object expected");
                message.LinkedDevices[i] = $root.LinkedDevices.fromObject(object.LinkedDevices[i]);
            }
        }
        switch (object.preferredVerificationProvider) {
        default:
            if (typeof object.preferredVerificationProvider === "number") {
                message.preferredVerificationProvider = object.preferredVerificationProvider;
                break;
            }
            break;
        case "VERIFICATION_PROVIDER_TANGO_SMS":
        case 1:
            message.preferredVerificationProvider = 1;
            break;
        case "VERIFICATION_PROVIDER_TWITTER":
        case 2:
            message.preferredVerificationProvider = 2;
            break;
        case "VERIFICATION_PROVIDER_FB_ACCOUNT_KIT":
        case 3:
            message.preferredVerificationProvider = 3;
            break;
        }
        if (object.jwtAuthTokens != null) {
            if (typeof object.jwtAuthTokens !== "object")
                throw TypeError(".TangoRegistrationResponse.jwtAuthTokens: object expected");
            message.jwtAuthTokens = $root.JwtAuthTokens.fromObject(object.jwtAuthTokens);
        }
        return message;
    };

    TangoRegistrationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.ctaStatus = [];
            object.LinkedDevices = [];
        }
        if (options.defaults) {
            object.socialIdStatus = null;
            object.type = "";
            object.reason = "";
            object.accountId = "";
            object.registrationAction = options.enums === String ? "UNDEFINED" : 1;
            object.authTokens = "";
            object.cloudSecret = "";
            object.codeEmailed = false;
            object.conflictAccountId = "";
            object.preferredVerificationProvider = options.enums === String ? "VERIFICATION_PROVIDER_TANGO_SMS" : 1;
            object.jwtAuthTokens = null;
        }
        if (message.ctaStatus && message.ctaStatus.length) {
            object.ctaStatus = [];
            for (let j = 0; j < message.ctaStatus.length; ++j)
                object.ctaStatus[j] = $root.CtaStatusType.toObject(message.ctaStatus[j], options);
        }
        if (message.socialIdStatus != null && message.hasOwnProperty("socialIdStatus"))
            object.socialIdStatus = $root.SocialIdStatusType.toObject(message.socialIdStatus, options);
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.reason != null && message.hasOwnProperty("reason"))
            object.reason = message.reason;
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.registrationAction != null && message.hasOwnProperty("registrationAction"))
            object.registrationAction = options.enums === String ? $root.RegistrationActionReturnType[message.registrationAction] === undefined ? message.registrationAction : $root.RegistrationActionReturnType[message.registrationAction] : message.registrationAction;
        if (message.authTokens != null && message.hasOwnProperty("authTokens"))
            object.authTokens = message.authTokens;
        if (message.cloudSecret != null && message.hasOwnProperty("cloudSecret"))
            object.cloudSecret = message.cloudSecret;
        if (message.codeEmailed != null && message.hasOwnProperty("codeEmailed"))
            object.codeEmailed = message.codeEmailed;
        if (message.conflictAccountId != null && message.hasOwnProperty("conflictAccountId"))
            object.conflictAccountId = message.conflictAccountId;
        if (message.LinkedDevices && message.LinkedDevices.length) {
            object.LinkedDevices = [];
            for (let j = 0; j < message.LinkedDevices.length; ++j)
                object.LinkedDevices[j] = $root.LinkedDevices.toObject(message.LinkedDevices[j], options);
        }
        if (message.preferredVerificationProvider != null && message.hasOwnProperty("preferredVerificationProvider"))
            object.preferredVerificationProvider = options.enums === String ? $root.VerificationProvider[message.preferredVerificationProvider] === undefined ? message.preferredVerificationProvider : $root.VerificationProvider[message.preferredVerificationProvider] : message.preferredVerificationProvider;
        if (message.jwtAuthTokens != null && message.hasOwnProperty("jwtAuthTokens"))
            object.jwtAuthTokens = $root.JwtAuthTokens.toObject(message.jwtAuthTokens, options);
        return object;
    };

    TangoRegistrationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TangoRegistrationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TangoRegistrationResponse";
    };

    return TangoRegistrationResponse;
})();

export const TangoUpdateAccountRequest = $root.TangoUpdateAccountRequest = (() => {

    function TangoUpdateAccountRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TangoUpdateAccountRequest.prototype.registrationRequest = null;
    TangoUpdateAccountRequest.prototype.birthday = "";
    TangoUpdateAccountRequest.prototype.gender = 1;
    TangoUpdateAccountRequest.prototype.backgroundUrl = "";
    TangoUpdateAccountRequest.prototype.pictureUrl = "";
    TangoUpdateAccountRequest.prototype.thumbnailUrl = "";
    TangoUpdateAccountRequest.prototype.status = "";

    TangoUpdateAccountRequest.create = function create(properties) {
        return new TangoUpdateAccountRequest(properties);
    };

    TangoUpdateAccountRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.TangoRegistrationRequest.encode(message.registrationRequest, writer.uint32(10).fork()).ldelim();
        if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
            writer.uint32(18).string(message.birthday);
        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
            writer.uint32(24).int32(message.gender);
        if (message.backgroundUrl != null && Object.hasOwnProperty.call(message, "backgroundUrl"))
            writer.uint32(34).string(message.backgroundUrl);
        if (message.pictureUrl != null && Object.hasOwnProperty.call(message, "pictureUrl"))
            writer.uint32(42).string(message.pictureUrl);
        if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
            writer.uint32(50).string(message.thumbnailUrl);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(58).string(message.status);
        return writer;
    };

    TangoUpdateAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TangoUpdateAccountRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TangoUpdateAccountRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.registrationRequest = $root.TangoRegistrationRequest.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.birthday = reader.string();
                    break;
                }
            case 3: {
                    message.gender = reader.int32();
                    break;
                }
            case 4: {
                    message.backgroundUrl = reader.string();
                    break;
                }
            case 5: {
                    message.pictureUrl = reader.string();
                    break;
                }
            case 6: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            case 7: {
                    message.status = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("registrationRequest"))
            throw $util.ProtocolError("missing required 'registrationRequest'", { instance: message });
        return message;
    };

    TangoUpdateAccountRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TangoUpdateAccountRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.TangoUpdateAccountRequest)
            return object;
        let message = new $root.TangoUpdateAccountRequest();
        if (object.registrationRequest != null) {
            if (typeof object.registrationRequest !== "object")
                throw TypeError(".TangoUpdateAccountRequest.registrationRequest: object expected");
            message.registrationRequest = $root.TangoRegistrationRequest.fromObject(object.registrationRequest);
        }
        if (object.birthday != null)
            message.birthday = String(object.birthday);
        switch (object.gender) {
        default:
            if (typeof object.gender === "number") {
                message.gender = object.gender;
                break;
            }
            break;
        case "NONE":
        case 1:
            message.gender = 1;
            break;
        case "MALE":
        case 2:
            message.gender = 2;
            break;
        case "FEMALE":
        case 3:
            message.gender = 3;
            break;
        case "BOTH":
        case 4:
            message.gender = 4;
            break;
        }
        if (object.backgroundUrl != null)
            message.backgroundUrl = String(object.backgroundUrl);
        if (object.pictureUrl != null)
            message.pictureUrl = String(object.pictureUrl);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        if (object.status != null)
            message.status = String(object.status);
        return message;
    };

    TangoUpdateAccountRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.registrationRequest = null;
            object.birthday = "";
            object.gender = options.enums === String ? "NONE" : 1;
            object.backgroundUrl = "";
            object.pictureUrl = "";
            object.thumbnailUrl = "";
            object.status = "";
        }
        if (message.registrationRequest != null && message.hasOwnProperty("registrationRequest"))
            object.registrationRequest = $root.TangoRegistrationRequest.toObject(message.registrationRequest, options);
        if (message.birthday != null && message.hasOwnProperty("birthday"))
            object.birthday = message.birthday;
        if (message.gender != null && message.hasOwnProperty("gender"))
            object.gender = options.enums === String ? $root.GenderType[message.gender] === undefined ? message.gender : $root.GenderType[message.gender] : message.gender;
        if (message.backgroundUrl != null && message.hasOwnProperty("backgroundUrl"))
            object.backgroundUrl = message.backgroundUrl;
        if (message.pictureUrl != null && message.hasOwnProperty("pictureUrl"))
            object.pictureUrl = message.pictureUrl;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = message.status;
        return object;
    };

    TangoUpdateAccountRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TangoUpdateAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TangoUpdateAccountRequest";
    };

    return TangoUpdateAccountRequest;
})();

export const JwtAuthTokens = $root.JwtAuthTokens = (() => {

    function JwtAuthTokens(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    JwtAuthTokens.prototype.sessionToken = null;
    JwtAuthTokens.prototype.refreshToken = null;

    JwtAuthTokens.create = function create(properties) {
        return new JwtAuthTokens(properties);
    };

    JwtAuthTokens.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sessionToken != null && Object.hasOwnProperty.call(message, "sessionToken"))
            $root.JwtTokenData.encode(message.sessionToken, writer.uint32(10).fork()).ldelim();
        if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
            $root.JwtTokenData.encode(message.refreshToken, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    JwtAuthTokens.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    JwtAuthTokens.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.JwtAuthTokens();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sessionToken = $root.JwtTokenData.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.refreshToken = $root.JwtTokenData.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    JwtAuthTokens.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    JwtAuthTokens.fromObject = function fromObject(object) {
        if (object instanceof $root.JwtAuthTokens)
            return object;
        let message = new $root.JwtAuthTokens();
        if (object.sessionToken != null) {
            if (typeof object.sessionToken !== "object")
                throw TypeError(".JwtAuthTokens.sessionToken: object expected");
            message.sessionToken = $root.JwtTokenData.fromObject(object.sessionToken);
        }
        if (object.refreshToken != null) {
            if (typeof object.refreshToken !== "object")
                throw TypeError(".JwtAuthTokens.refreshToken: object expected");
            message.refreshToken = $root.JwtTokenData.fromObject(object.refreshToken);
        }
        return message;
    };

    JwtAuthTokens.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.sessionToken = null;
            object.refreshToken = null;
        }
        if (message.sessionToken != null && message.hasOwnProperty("sessionToken"))
            object.sessionToken = $root.JwtTokenData.toObject(message.sessionToken, options);
        if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
            object.refreshToken = $root.JwtTokenData.toObject(message.refreshToken, options);
        return object;
    };

    JwtAuthTokens.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    JwtAuthTokens.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/JwtAuthTokens";
    };

    return JwtAuthTokens;
})();

export const JwtTokenData = $root.JwtTokenData = (() => {

    function JwtTokenData(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    JwtTokenData.prototype.token = "";
    JwtTokenData.prototype.ttlSec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    JwtTokenData.create = function create(properties) {
        return new JwtTokenData(properties);
    };

    JwtTokenData.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(10).string(message.token);
        if (message.ttlSec != null && Object.hasOwnProperty.call(message, "ttlSec"))
            writer.uint32(16).int64(message.ttlSec);
        return writer;
    };

    JwtTokenData.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    JwtTokenData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.JwtTokenData();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.token = reader.string();
                    break;
                }
            case 2: {
                    message.ttlSec = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    JwtTokenData.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    JwtTokenData.fromObject = function fromObject(object) {
        if (object instanceof $root.JwtTokenData)
            return object;
        let message = new $root.JwtTokenData();
        if (object.token != null)
            message.token = String(object.token);
        if (object.ttlSec != null)
            if ($util.Long)
                (message.ttlSec = $util.Long.fromValue(object.ttlSec)).unsigned = false;
            else if (typeof object.ttlSec === "string")
                message.ttlSec = parseInt(object.ttlSec, 10);
            else if (typeof object.ttlSec === "number")
                message.ttlSec = object.ttlSec;
            else if (typeof object.ttlSec === "object")
                message.ttlSec = new $util.LongBits(object.ttlSec.low >>> 0, object.ttlSec.high >>> 0).toNumber();
        return message;
    };

    JwtTokenData.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.token = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.ttlSec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.ttlSec = options.longs === String ? "0" : 0;
        }
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.ttlSec != null && message.hasOwnProperty("ttlSec"))
            if (typeof message.ttlSec === "number")
                object.ttlSec = options.longs === String ? String(message.ttlSec) : message.ttlSec;
            else
                object.ttlSec = options.longs === String ? $util.Long.prototype.toString.call(message.ttlSec) : options.longs === Number ? new $util.LongBits(message.ttlSec.low >>> 0, message.ttlSec.high >>> 0).toNumber() : message.ttlSec;
        return object;
    };

    JwtTokenData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    JwtTokenData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/JwtTokenData";
    };

    return JwtTokenData;
})();

export const VerificationParams = $root.VerificationParams = (() => {

    function VerificationParams(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    VerificationParams.prototype.tango = null;
    VerificationParams.prototype.twitter = null;
    VerificationParams.prototype.fbAccountKit = null;

    VerificationParams.create = function create(properties) {
        return new VerificationParams(properties);
    };

    VerificationParams.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tango != null && Object.hasOwnProperty.call(message, "tango"))
            $root.VerificationProviderTangoParams.encode(message.tango, writer.uint32(10).fork()).ldelim();
        if (message.twitter != null && Object.hasOwnProperty.call(message, "twitter"))
            $root.VerificationProviderTwitterParams.encode(message.twitter, writer.uint32(18).fork()).ldelim();
        if (message.fbAccountKit != null && Object.hasOwnProperty.call(message, "fbAccountKit"))
            $root.VerificationProviderFbAccountKitParams.encode(message.fbAccountKit, writer.uint32(26).fork()).ldelim();
        return writer;
    };

    VerificationParams.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    VerificationParams.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VerificationParams();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.tango = $root.VerificationProviderTangoParams.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.twitter = $root.VerificationProviderTwitterParams.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.fbAccountKit = $root.VerificationProviderFbAccountKitParams.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    VerificationParams.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    VerificationParams.fromObject = function fromObject(object) {
        if (object instanceof $root.VerificationParams)
            return object;
        let message = new $root.VerificationParams();
        if (object.tango != null) {
            if (typeof object.tango !== "object")
                throw TypeError(".VerificationParams.tango: object expected");
            message.tango = $root.VerificationProviderTangoParams.fromObject(object.tango);
        }
        if (object.twitter != null) {
            if (typeof object.twitter !== "object")
                throw TypeError(".VerificationParams.twitter: object expected");
            message.twitter = $root.VerificationProviderTwitterParams.fromObject(object.twitter);
        }
        if (object.fbAccountKit != null) {
            if (typeof object.fbAccountKit !== "object")
                throw TypeError(".VerificationParams.fbAccountKit: object expected");
            message.fbAccountKit = $root.VerificationProviderFbAccountKitParams.fromObject(object.fbAccountKit);
        }
        return message;
    };

    VerificationParams.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.tango = null;
            object.twitter = null;
            object.fbAccountKit = null;
        }
        if (message.tango != null && message.hasOwnProperty("tango"))
            object.tango = $root.VerificationProviderTangoParams.toObject(message.tango, options);
        if (message.twitter != null && message.hasOwnProperty("twitter"))
            object.twitter = $root.VerificationProviderTwitterParams.toObject(message.twitter, options);
        if (message.fbAccountKit != null && message.hasOwnProperty("fbAccountKit"))
            object.fbAccountKit = $root.VerificationProviderFbAccountKitParams.toObject(message.fbAccountKit, options);
        return object;
    };

    VerificationParams.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    VerificationParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VerificationParams";
    };

    return VerificationParams;
})();

export const VerificationProviderFbAccountKitParams = $root.VerificationProviderFbAccountKitParams = (() => {

    function VerificationProviderFbAccountKitParams(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    VerificationProviderFbAccountKitParams.prototype.authCode = "";
    VerificationProviderFbAccountKitParams.prototype.accessToken = "";

    VerificationProviderFbAccountKitParams.create = function create(properties) {
        return new VerificationProviderFbAccountKitParams(properties);
    };

    VerificationProviderFbAccountKitParams.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.authCode != null && Object.hasOwnProperty.call(message, "authCode"))
            writer.uint32(10).string(message.authCode);
        if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
            writer.uint32(18).string(message.accessToken);
        return writer;
    };

    VerificationProviderFbAccountKitParams.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    VerificationProviderFbAccountKitParams.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VerificationProviderFbAccountKitParams();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.authCode = reader.string();
                    break;
                }
            case 2: {
                    message.accessToken = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    VerificationProviderFbAccountKitParams.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    VerificationProviderFbAccountKitParams.fromObject = function fromObject(object) {
        if (object instanceof $root.VerificationProviderFbAccountKitParams)
            return object;
        let message = new $root.VerificationProviderFbAccountKitParams();
        if (object.authCode != null)
            message.authCode = String(object.authCode);
        if (object.accessToken != null)
            message.accessToken = String(object.accessToken);
        return message;
    };

    VerificationProviderFbAccountKitParams.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.authCode = "";
            object.accessToken = "";
        }
        if (message.authCode != null && message.hasOwnProperty("authCode"))
            object.authCode = message.authCode;
        if (message.accessToken != null && message.hasOwnProperty("accessToken"))
            object.accessToken = message.accessToken;
        return object;
    };

    VerificationProviderFbAccountKitParams.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    VerificationProviderFbAccountKitParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VerificationProviderFbAccountKitParams";
    };

    return VerificationProviderFbAccountKitParams;
})();

export const VerificationProviderTangoParams = $root.VerificationProviderTangoParams = (() => {

    function VerificationProviderTangoParams(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    VerificationProviderTangoParams.prototype.verificationCode = "";

    VerificationProviderTangoParams.create = function create(properties) {
        return new VerificationProviderTangoParams(properties);
    };

    VerificationProviderTangoParams.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.verificationCode);
        return writer;
    };

    VerificationProviderTangoParams.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    VerificationProviderTangoParams.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VerificationProviderTangoParams();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.verificationCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("verificationCode"))
            throw $util.ProtocolError("missing required 'verificationCode'", { instance: message });
        return message;
    };

    VerificationProviderTangoParams.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    VerificationProviderTangoParams.fromObject = function fromObject(object) {
        if (object instanceof $root.VerificationProviderTangoParams)
            return object;
        let message = new $root.VerificationProviderTangoParams();
        if (object.verificationCode != null)
            message.verificationCode = String(object.verificationCode);
        return message;
    };

    VerificationProviderTangoParams.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.verificationCode = "";
        if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
            object.verificationCode = message.verificationCode;
        return object;
    };

    VerificationProviderTangoParams.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    VerificationProviderTangoParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VerificationProviderTangoParams";
    };

    return VerificationProviderTangoParams;
})();

export const VerificationProviderTwitterParams = $root.VerificationProviderTwitterParams = (() => {

    function VerificationProviderTwitterParams(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    VerificationProviderTwitterParams.prototype.authServiceProvider = "";
    VerificationProviderTwitterParams.prototype.credentialsAuthorization = "";

    VerificationProviderTwitterParams.create = function create(properties) {
        return new VerificationProviderTwitterParams(properties);
    };

    VerificationProviderTwitterParams.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.authServiceProvider);
        writer.uint32(18).string(message.credentialsAuthorization);
        return writer;
    };

    VerificationProviderTwitterParams.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    VerificationProviderTwitterParams.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VerificationProviderTwitterParams();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.authServiceProvider = reader.string();
                    break;
                }
            case 2: {
                    message.credentialsAuthorization = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("authServiceProvider"))
            throw $util.ProtocolError("missing required 'authServiceProvider'", { instance: message });
        if (!message.hasOwnProperty("credentialsAuthorization"))
            throw $util.ProtocolError("missing required 'credentialsAuthorization'", { instance: message });
        return message;
    };

    VerificationProviderTwitterParams.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    VerificationProviderTwitterParams.fromObject = function fromObject(object) {
        if (object instanceof $root.VerificationProviderTwitterParams)
            return object;
        let message = new $root.VerificationProviderTwitterParams();
        if (object.authServiceProvider != null)
            message.authServiceProvider = String(object.authServiceProvider);
        if (object.credentialsAuthorization != null)
            message.credentialsAuthorization = String(object.credentialsAuthorization);
        return message;
    };

    VerificationProviderTwitterParams.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.authServiceProvider = "";
            object.credentialsAuthorization = "";
        }
        if (message.authServiceProvider != null && message.hasOwnProperty("authServiceProvider"))
            object.authServiceProvider = message.authServiceProvider;
        if (message.credentialsAuthorization != null && message.hasOwnProperty("credentialsAuthorization"))
            object.credentialsAuthorization = message.credentialsAuthorization;
        return object;
    };

    VerificationProviderTwitterParams.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    VerificationProviderTwitterParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VerificationProviderTwitterParams";
    };

    return VerificationProviderTwitterParams;
})();

export const ZippedContactList = $root.ZippedContactList = (() => {

    function ZippedContactList(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ZippedContactList.prototype.zippedContent = "";

    ZippedContactList.create = function create(properties) {
        return new ZippedContactList(properties);
    };

    ZippedContactList.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.zippedContent != null && Object.hasOwnProperty.call(message, "zippedContent"))
            writer.uint32(10).string(message.zippedContent);
        return writer;
    };

    ZippedContactList.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ZippedContactList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ZippedContactList();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.zippedContent = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ZippedContactList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ZippedContactList.fromObject = function fromObject(object) {
        if (object instanceof $root.ZippedContactList)
            return object;
        let message = new $root.ZippedContactList();
        if (object.zippedContent != null)
            message.zippedContent = String(object.zippedContent);
        return message;
    };

    ZippedContactList.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.zippedContent = "";
        if (message.zippedContent != null && message.hasOwnProperty("zippedContent"))
            object.zippedContent = message.zippedContent;
        return object;
    };

    ZippedContactList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ZippedContactList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ZippedContactList";
    };

    return ZippedContactList;
})();

export const VerificationProvider = $root.VerificationProvider = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "VERIFICATION_PROVIDER_TANGO_SMS"] = 1;
    values[valuesById[2] = "VERIFICATION_PROVIDER_TWITTER"] = 2;
    values[valuesById[3] = "VERIFICATION_PROVIDER_FB_ACCOUNT_KIT"] = 3;
    return values;
})();

export const GenderType = $root.GenderType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "NONE"] = 1;
    values[valuesById[2] = "MALE"] = 2;
    values[valuesById[3] = "FEMALE"] = 3;
    values[valuesById[4] = "BOTH"] = 4;
    return values;
})();

export const RegistrationActionReturnType = $root.RegistrationActionReturnType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "UNDEFINED"] = 1;
    values[valuesById[2] = "REGISTER"] = 2;
    values[valuesById[3] = "UPDATE"] = 3;
    values[valuesById[4] = "VALIDATE"] = 4;
    values[valuesById[5] = "VALIDATE_DEVICE"] = 5;
    values[valuesById[6] = "VALIDATE_LINKING"] = 6;
    values[valuesById[7] = "REGISTER_VALIDATED"] = 7;
    values[valuesById[8] = "PRE_REGISTER"] = 8;
    values[valuesById[9] = "DEVICE_LINKED"] = 9;
    return values;
})();

export { $root as default };
