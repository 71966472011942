export enum OneClickOfferTypes {
  ONE_CLICK_CALLS = "ONE_CLICK_CALLS",
  ONE_CLICK_IN_STREAM = "ONE_CLICK_IN_STREAM",
  ONE_CLICK_MESSAGES = "ONE_CLICK_MESSAGES",
  ONE_CLICK_PREMIUM_IN_STREAM = "ONE_CLICK_PREMIUM_IN_STREAM",
  ONE_CLICK_PREMIUM_IN_STREAM_FT = "ONE_CLICK_PREMIUM_IN_STREAM_FT",
  ONE_CLICK_PREMIUM_IN_STREAM_REGULAR_PURCHASE = "ONE_CLICK_PREMIUM_IN_STREAM_RP",
  ONE_CLICK_PREMIUM_MESSAGES = "ONE_CLICK_PREMIUM_MESSAGES",
  ONE_CLICK_PROFILE = "ONE_CLICK_PROFILE",
  ONE_CLICK_SOCIAL_GAMES = "ONE_CLICK_SOCIAL_GAMES",
}

export enum OneClickEndResult {
  SUCCESSFUL = 1,
  UNSUCCESSFUL = 0,
}

export enum OneClickEndReason {
  OFFER_NOT_FOUND = "one_click offer was not found",
  PURCHASE_FAILED = "purchase failed",
  PURCHASE_REFUSED = "purchase refuse",
}
