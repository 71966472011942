import { removeEmojiFromString } from "src/utils/emojiUtils";

const isStringWithoutLetters = (str: string) => {
  const strWithoutEmoji = removeEmojiFromString(str);

  if (!strWithoutEmoji) {
    return true;
  }

  return /^[0-9 `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]+$/gi.test(strWithoutEmoji);
};

export default isStringWithoutLetters;
