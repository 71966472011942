import React, { FC, memo } from "react";
import { PostDate } from "legal/components";
import { Headline, Paragraph } from "legal/components/typography";
import { SCREEN_NAME, ScreenViewReporter } from "legal/imports/analytics";
import { FormattedMessageTitle } from "legal/imports/components";
import { legalMessages } from "legal/imports/constants";
import styles from "legal/common/LegalArticle.scss";

/* eslint-disable react/jsx-no-literals */
const DSAInformation: FC = () => (
  <>
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_DSA_STATEMENT} />
    <FormattedMessageTitle {...legalMessages.dsaInformation} />

    <article className={styles.article}>
      <PostDate>Last Modified: 21.02.2023</PostDate>

      <Headline level={1}>
        {legalMessages.dsaInformation.defaultMessage}
      </Headline>

      <Headline level={2}>What is the Digital Services Act?</Headline>
      <Paragraph>
        The Digital Services Act (the <b>&quot;DSA&quot;</b>) is a new EU
        regulation that will enter into full force and effect on February 17th,
        2024. The DSA&#39;s objective is to set new and uniform rules and
        standards for the operation of online platforms, such as Tango.
      </Paragraph>
      <Paragraph>
        Tango has included this DSA statement to provide our users with
        necessary information to meet its obligations under the DSA.
      </Paragraph>

      <Headline level={2}>
        Information Regarding Active Monthly Users in The European Union
      </Headline>
      <Paragraph>
        Tango had conducted a diligent review of its monthly active users in the
        EU, according to the language of Article 24(2) and Recital 77 of the DSA
        and the guidance provided by the European Commission in this matter. Our
        review included only unique visits, and we have made best commercial
        efforts to avoid double-counting of multiple visits by the same user.
      </Paragraph>
      <Paragraph>
        As we have calculated the number of monthly active users in the EU from
        August 17th, 2023 until February 17 th , 2023, our conclusion is that
        during this six-months period the average number of monthly active users
        in the EU is <b>significantly lower</b> than the forty-five million
        (45,000,000) threshold, and Tango is not considered a VLOP under the
        DSA.
      </Paragraph>
      <Paragraph>
        We will continue to monitor the number of active users in the EU in the
        following months and shall update this statement later in 2023, in
        accordance with our obligation under Article 24(2) of the DSA.
      </Paragraph>
    </article>
  </>
);

export default memo(DSAInformation);
