import React, { FC, memo, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MultiName from "chat/components/common/MultiName";
import { groupChatMessages } from "chat/components/common/messages";
import { DisplayName } from "chat/imports/components";
import {
  RootState,
  batchLoadProfiles,
  profilesCacheSelectors,
} from "chat/imports/state";
import { MessageType } from "chat/types";
import { BasicProfile } from "src/types/profile/profile";

interface MultiNameFormattedMessageProps {
  accountIds: string[];
  basicProfile: BasicProfile;
}

const MultiNameFormattedMessage: FC<MultiNameFormattedMessageProps> = ({
  basicProfile,
  accountIds,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      batchLoadProfiles({
        ids: accountIds,
        loadOnlyIfMissing: true,
        params: { basic: true, detail: false, live: false, follow: false },
      })
    );
  }, [accountIds, dispatch]);

  const members = useSelector(
    useCallback(
      (state: RootState) =>
        accountIds
          ?.map((id: string) =>
            profilesCacheSelectors.getBasicProfile(state, id)
          )
          .filter((profile) => profile !== undefined),
      [accountIds]
    ),
    shallowEqual
  );

  if (members.length === 0) {
    return null;
  }

  return (
    <FormattedMessage
      {...groupChatMessages[MessageType.GROUP_MEMBER_JOIN]}
      values={{
        inviterName: <DisplayName short basicProfile={basicProfile} />,
        invitedNames: <MultiName members={members} />,
      }}
    />
  );
};

export default memo(MultiNameFormattedMessage);
