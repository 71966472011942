import { getHttpHttpsReplacementList } from "src/environment";
import { Nullable } from "src/types/common";

// visible for testing
export const replacementWhitelist = [
  "http://cget.tango.me",
  "http://sdata.tango.me",
  "http://surprises.tango.me",
  "http://thumbs.gfycat.com",
  "http://graph.facebook.com",
  "http://resources.tango.me",
];

export const HTTP = "http://";
export const HTTPS = "https://";

export const replacementRegExpWhitelist = [/cloudfront/];

export const ensureHttps = (url: Nullable<string | undefined>) => {
  if (!url) {
    return "";
  }
  if (url.startsWith(HTTPS)) {
    return url;
  }
  for (const item of replacementWhitelist) {
    if (url.startsWith(item)) {
      return url.replace(HTTP, HTTPS);
    }
  }
  for (const [http, https] of getHttpHttpsReplacementList()) {
    if (url.startsWith(http)) {
      return url.replace(http, https);
    }
  }

  if (url.startsWith(HTTP)) {
    const { hostname } = new URL(url);
    for (const item of replacementRegExpWhitelist) {
      if (item.test(hostname)) {
        return url.replace(HTTP, HTTPS);
      }
    }
  }

  return url;
};
