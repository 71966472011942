import { Component } from "react";
import PropTypes from "prop-types";

const acceptedCharacters = /^[0-9]$/;

export default class DigitInput extends Component {
  constructor(props) {
    super(props);
    this._inputs = [];
  }

  _getInput(i) {
    return this._inputs[i];
  }

  componentDidMount() {
    // hack to prevent body from scrolling on input autoFocus on safari
    // https://stackoverflow.com/questions/37287148/ios-safari-chrome-unwanted-scrolling-when-focusing-an-input-inside-the-modal kinda similar issue
    setTimeout(() => {
      this._inputs[0]?.focus();
    }, 0);
  }

  render() {
    let value = this.props.value;
    while (value.length < this.props.length) {
      value += " ";
    }
    value = value.substr(0, this.props.length);
    const props = [];
    for (let i = 0; i < this.props.length; i++) {
      const input = this._getInput(i);
      const getPreviousInput = () => this._getInput(i - 1);
      const getNextInput = () => this._getInput(i + 1);

      props.push({
        ref: (element) => (this._inputs[i] = element),
        value: value[i] === " " ? "" : value[i],
        onFocus: () => {
          window.requestAnimationFrame(() => {
            input && input.setSelectionRange(0, 1);
          });
        },
        onKeyDown: (e) => {
          const previousInput = getPreviousInput();
          const nextInput = getNextInput();
          switch (e.key) {
            case "Backspace":
              e.preventDefault();
              if (value[i] === " " || (input && input.selectionEnd === 0)) {
                if (i <= 0) {
                  return;
                }
                this.props.onChange(
                  `${value.substring(0, i - 1)} ${value.substring(i)}`
                );
                if (previousInput) {
                  previousInput.focus();
                }
              } else {
                this.props.onChange(
                  `${value.substring(0, i)} ${value.substring(i + 1)}`
                );
              }
              break;
            case "ArrowLeft":
              e.preventDefault();
              if (i <= 0) {
                return;
              }
              if (!previousInput) {
                return;
              }
              previousInput.focus();
              window.requestAnimationFrame(() => {
                previousInput.setSelectionRange(0, 1);
              });

              break;
            case "ArrowRight":
              e.preventDefault();
              if (i + 1 >= this.props.length) {
                return;
              }
              if (nextInput) {
                nextInput.focus();
                window.requestAnimationFrame(() => {
                  nextInput.setSelectionRange(0, 1);
                });
              }

              break;
            default:
              if (
                !(e.key.length === 1 && !(e.metaKey || e.altKey || e.ctrlKey))
              ) {
                return;
              }
              e.preventDefault();
              if (!acceptedCharacters.test(e.key)) {
                return;
              }
              this.props.onChange(
                value.substring(0, i) + e.key + value.substring(i + 1)
              );
              if (i + 1 >= this.props.length) {
                return;
              }
              if (!nextInput) {
                return;
              }
              nextInput.focus();
              window.requestAnimationFrame(() => {
                nextInput.setSelectionRange(0, 1);
              });
          }
        },
        onChange: (e) => {
          const v = e.target.value
            .split("")
            .filter((c) => acceptedCharacters.test(c))
            .join("");
          this.props.onChange(
            (value.substring(0, i) + v + value.substring(i + v.length)).substr(
              0,
              this.props.length
            )
          );
          if (i < this.props.length - 1) {
            const nextInput = this._getInput(
              i + v.length < this.props.length
                ? i + v.length
                : this.props.length - 1
            );
            if (nextInput) {
              nextInput.focus();
              window.requestAnimationFrame(() => {
                nextInput.setSelectionRange(0, 1);
              });
            }
          }
        },
      });
    }
    return this.props.children(props);
  }
}

DigitInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
};
