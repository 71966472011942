import { useEffect } from "react";
import { LANDING } from "enums/cashier";
import { emitEvent } from "src/analytics/emit";
import {
  BI_LANDING_FIELD,
  EventNames,
  LANDING_PLACE,
} from "src/analytics/enums";
import { Nullable } from "src/types/common";
import { PersonalOffersViewType } from "src/types/personalOffer";
import { useUnmount } from "src/utils/miniReactUse";

interface CashierEventBIProps {
  place?: Nullable<LANDING_PLACE>;
  trackingId?: Nullable<string>;
  triggerId?: string;
  viewType?: PersonalOffersViewType | typeof LANDING;
}

const useCashierEventBI = ({
  viewType,
  triggerId,
  place,
  trackingId,
}: CashierEventBIProps) => {
  useEffect(() => {
    if (triggerId) {
      emitEvent(EventNames.CASHIER_DISPLAYED, {
        [BI_LANDING_FIELD.TRIGGER_ID]: triggerId,
        [BI_LANDING_FIELD.COMMENT]: viewType,
        [BI_LANDING_FIELD.PLACE]: place,
        [BI_LANDING_FIELD.TRACKING_ID]: trackingId,
      });
    }
  }, [triggerId, trackingId]);

  useUnmount(() => {
    if (triggerId) {
      emitEvent(EventNames.CASHIER_DISMISSED, {
        [BI_LANDING_FIELD.TRIGGER_ID]: triggerId,
        [BI_LANDING_FIELD.COMMENT]: viewType,
        [BI_LANDING_FIELD.PLACE]: place,
        [BI_LANDING_FIELD.TRACKING_ID]: trackingId,
      });
    }
  });
};

export default useCashierEventBI;
