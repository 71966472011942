import { useSelector } from "react-redux";
import { chatSelectors } from "chat/exports/state/selectors";
import { MAX_DISPLAYED_MESSAGES_COUNT } from "src/constants";

export const useWithUnreadMessagesTitle = (title: string) => {
  const unreadMessagesCount = useSelector(chatSelectors.getTotalUnreadCount);

  if (unreadMessagesCount <= 0) {
    return title;
  }

  const displayCount =
    unreadMessagesCount > MAX_DISPLAYED_MESSAGES_COUNT
      ? `${MAX_DISPLAYED_MESSAGES_COUNT}+`
      : unreadMessagesCount;

  return `(${displayCount}) ${title}`;
};
