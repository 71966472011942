import {
  Breakpoints,
  ThemeType,
  VipClassName,
  VipLabel,
  VipName,
} from "src/enums";
import { Nullable } from "src/types/common";
import { ResolutionTypes } from "src/ui/scenes/landingPagePhoenix/enums";
import {
  AssetConfig,
  ImageVariant,
  ResolutionsType,
  VipConfigWithAssetConfig,
} from "state/flows/loadVipConfigs";
import { getRootPathForVipLevel } from "state/flows/utils/assetConfig";
import { VipConfigsState } from "state/tree/vipConfigs";

export interface VipSelectorArgs {
  vipConfigId?: number;
  vipLabel?: VipLabel;
  vipLevel?: number;
  vipName?: VipName;
}

export interface VipImageArgs {
  breakpoint: Breakpoints;
  imageName: keyof AssetConfig["assets"];
  theme: ThemeType;
  vipLabel?: Nullable<VipLabel>;
  vipLevel?: number;
  vipModelId?: number;
}

export const vipClassName = {
  [VipName.NONE]: VipClassName.NONE,
  [VipName.MEMBER]: VipClassName.MEMBER,
  [VipName.ROOKIE]: VipClassName.ROOKIE,
  [VipName.BRONZE]: VipClassName.BRONZE,
  [VipName.SILVER]: VipClassName.SILVER,
  [VipName.GOLD]: VipClassName.GOLD,
  [VipName.ELITE]: VipClassName.ELITE,
  [VipName.ROYAL]: VipClassName.ROYAL,
  [VipName.DIAMOND]: VipClassName.DIAMOND,
  [VipName.BLACK_DIAMOND]: VipClassName.BLACK_DIAMOND,
  [VipName.LEGEND]: VipClassName.LEGEND,
};

export const localVipConfigsSelectors = {
  meta: (state: VipConfigsState) => state.meta,
  getVipConfigs: (state: VipConfigsState) => state.data,
  getVipStyles: (
    state: VipConfigsState,
    toSearch: VipSelectorArgs
  ): AssetConfig["styles"] | Record<string, never> => {
    const config = state.data?.find(
      (vipConfigWithAssets: VipConfigWithAssetConfig) =>
        vipConfigWithAssets.vipLevel === toSearch.vipLevel ||
        vipConfigWithAssets.name === toSearch.vipName ||
        vipConfigWithAssets.label === toSearch.vipLabel ||
        vipConfigWithAssets.id === toSearch.vipConfigId
    );

    if (!config?.assetConfig) {
      return {};
    }

    return config.assetConfig.styles;
  },
  getVipImage: (
    state: VipConfigsState,
    {
      vipModelId,
      imageName,
      vipLevel,
      vipLabel,
      theme,
      breakpoint,
    }: VipImageArgs
  ) => {
    const model = state.data.find(
      (vipConfigWithAssets: VipConfigWithAssetConfig) =>
        vipConfigWithAssets.vipLevel === vipLevel ||
        vipConfigWithAssets.label === vipLabel ||
        vipConfigWithAssets.id === vipModelId
    ) as VipConfigWithAssetConfig;

    if (!model?.bundleZipUrl || !model?.assetConfig) {
      return "";
    }

    const vipLevelRootPath = getRootPathForVipLevel(model.bundleZipUrl);
    const image = model.assetConfig.assets[imageName];
    const resolution = ResolutionTypes[breakpoint] as keyof ResolutionsType;
    const searchForTheme = ({ theme: defaultTheme }: ImageVariant) =>
      defaultTheme === theme;
    const imagePath = image.resolutions.DEFAULT
      ? image.resolutions.DEFAULT.find(searchForTheme)
      : image.resolutions[resolution]?.find(searchForTheme);

    return `${vipLevelRootPath}/${model.label}/${image.rootPath}/${imagePath?.path}`;
  },
  getVipInfoFromConfig: (
    state: VipConfigsState,
    { vipLevel, vipConfigId, vipLabel, vipName }: VipSelectorArgs
  ) => {
    const config = state.data.find(
      ({ vipLevel: level, name, label, id }) =>
        level === vipLevel ||
        name === vipName ||
        label === vipLabel ||
        id === vipConfigId
    ) as VipConfigWithAssetConfig;

    return {
      vipName: config?.name || VipName.NONE,
      vipClassName: config?.name
        ? vipClassName[config?.name]
        : vipClassName[VipName.NONE],
      vipLevel: config?.vipLevel || null,
      vipLabel: config?.label || null,
      weight: config?.weight || 0,
    };
  },
  getVipLevelByName: (state: VipConfigsState, vipName: VipName) =>
    state.data?.find(({ name }) => name === vipName)?.vipLevel,
};
