import type { Dispatch, Store } from "redux";
import tangoAnalytics from "src/core/analytics";
import { EventFields, StorageKeys } from "src/core/analytics/enums";
import {
  AUTHORIZATION_END,
  LOGIN_END,
  LOGOUT_END,
} from "src/features/signin/state/login/actionTypes";
import { userSelectors } from "state/selectors";

interface AccountIdAction {
  type: string;
}

const expectedActions = [
  "persist/REHYDRATE",
  LOGOUT_END,
  AUTHORIZATION_END,
  LOGIN_END,
];

export const BIAccountIdMiddleware =
  (store: Store) => (next: Dispatch) => (action: AccountIdAction) => {
    const result = next(action);
    const newAccountId = userSelectors.getMyAccountId(store.getState());

    if (expectedActions.includes(action.type)) {
      tangoAnalytics.then((analytics) =>
        analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
          [EventFields.ACCOUNT_ID]: newAccountId,
        })
      );
    }

    return result;
  };
