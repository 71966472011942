import { getProxycadorHost } from "chat/imports/environment";

interface UploadedMedia {
  contentType: string;
  mediaId: string;
  thumbnailUrl: string;
  url: string;
}

export const uploadVideo = async (file: File): Promise<UploadedMedia> => {
  const formData = new FormData();
  formData.append("file", file);

  return fetch(`${getProxycadorHost()}/proxycador/api/public/v1/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  }).then((res) => res.json());
};
