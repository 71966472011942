/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AlbumPost,
  PicturePost,
  PostType,
  PostTypeCode,
  VideoPost,
} from "src/types/feed";

export const convertPostTypeCodeToPostType = (typeCode: number): PostType => {
  switch (typeCode) {
    case PostTypeCode.ALBUM:
      return PostType.ALBUM;
    case PostTypeCode.PHOTO:
      return PostType.PICTURE;
    case PostTypeCode.TEXT:
      return PostType.TEXT;
    case PostTypeCode.VIDEO:
      return PostType.VIDEO;
    case PostTypeCode.WEBLINK:
      return PostType.WEBLINK;
    default:
      throw new Error(`Unsupported post type code: ${typeCode}`);
  }
};

const transformOldVideoContent = (content: any): VideoPost["content"] => ({
  postType: PostType.VIDEO,
  caption: content.caption,
  duration: content.duration,
  mediaId: content.mediaId,
  referenceUrl: content.referenceUrl,
  rotation: content.rotation,
  thumbnailUrl: content.thumbnailUrl,
  url: content.url,
  height: content.height,
  width: content.width,
  blurredThumbnailUrl: content.blurredThumbnailUrl,
  blurLevel: content.blurLevel,
});

const transformOldAlbumContent = (content: any): AlbumPost["content"] => ({
  postType: PostType.ALBUM,
  caption: content.caption,
  items: content.items?.map((item: any) => ({
    blurLevel: item.blurLevel,
    blurredPictureUrl: item.blurredPictureUrl,
    blurredThumbnailUrl: item.blurredThumbnailUrl,
    pictureHeight: item.pictureHeight,
    pictureUrl: item.pictureUrl,
    pictureWidth: item.pictureWidth,
    referenceUrl: item.referenceUrl,
    thumbnailHeight: item.thumbnailHeight,
    thumbnailUrl: item.thumbnailUrl,
    thumbnailWidth: item.thumbnailWidth,
  })),
});

const transformOldPictureContent = (content: any): PicturePost["content"] => ({
  postType: PostType.PICTURE,
  attribution: content.attribution,
  blurLevel: content.blurLevel,
  blurredThumbnailUrl: content.blurredThumbnailUrl,
  blurredUrl: content.blurredUrl,
  caption: content.caption,
  hyperlinkCaption: content.hyperlinkCaption,
  hyperlinkUrl: content.hyperlinkUrl,
  referenceUrl: content.referenceUrl,
  thumbnailHeight: content.thumbnailHeight,
  thumbnailUrl: content.thumbnailUrl,
  thumbnailWidth: content.thumbnailWidth,
  tapActionType: content.tapActionType,
  url: content.url,
});

export const transformNewPostContent = (type: number, content: any): any => {
  switch (type) {
    case PostTypeCode.VIDEO:
      return transformOldVideoContent(content);
    case PostTypeCode.ALBUM:
      return transformOldAlbumContent(content);
    case PostTypeCode.PHOTO:
      return transformOldPictureContent(content);
    default:
  }
};
