import { FollowSource } from "src/enums";
import { RealTimeRecommendationsFollowFromType } from "src/features/recommendations/common/enums";

export const mapFollowSourceToFollowFrom = (
  source?: number
): RealTimeRecommendationsFollowFromType => {
  switch (source) {
    case FollowSource.STREAM_MINI_PROFILE:
      return RealTimeRecommendationsFollowFromType.STREAMER_MINI_PROFILE;

    default:
      return RealTimeRecommendationsFollowFromType.STREAM_FOLLOW_BUTTON;
  }
};
