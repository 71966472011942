import { getProxycadorHost } from "chat/imports/environment";
import { enhancedFetch as fetch } from "chat/imports/utils";
import {
  ContentSharedPayloadMessage,
  DetailsResponseMessage,
  ShareRequest,
  ShareResponse,
} from "chat/premiumMessage/types";

export const sendPremiumMessage = (
  body: ShareRequest
): Promise<ShareResponse> =>
  fetch(`${getProxycadorHost()}/premium-message/content/share`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

export const unlockPremiumMessage = (messageId: string) =>
  fetch(`${getProxycadorHost()}/premium-message/content/unlock`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      messageId,
    }),
  });

export const getPremiumMessageDetails = (
  messageIds: ContentSharedPayloadMessage["messageId"][]
): Promise<DetailsResponseMessage> =>
  fetch(`${getProxycadorHost()}/premium-message/content/details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      messageId: messageIds,
    }),
  }).then((res) => res.json());
