import { useEffect, useRef } from "react";
import { getIntercomAppId } from "environment";
import { actionCreators } from "state/tree/intercom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "state/delegate";
import {
  intercomSelectors,
  loginSelectors,
  userSelectors,
} from "state/selectors";
import { getIntercomUserHash } from "api/intercom";
import emptyFunction from "fbjs/lib/emptyFunction";

const selector = (state: RootState) => ({
  accountId: userSelectors.getMyAccountId(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isIntercomPaused: intercomSelectors.getIsPaused(state),
});

export default () => {
  const dispatch = useDispatch();
  const accountIdToUserHashMap = useRef(new Map<string, string>());
  const { accountId, isLoggedIn, isIntercomPaused } = useSelector(
    selector,
    shallowEqual
  );
  useEffect(() => {
    if (!getIntercomAppId() || !isLoggedIn || !accountId || isIntercomPaused) {
      return;
    }

    const boot = (accountId: string, userHash: string) => {
      dispatch(actionCreators.bootIntercom());
      window.Intercom("boot", {
        user_id: accountId,
        user_hash: userHash,
      });
    };

    const shutdown = () => {
      window.Intercom("shutdown");
      dispatch(actionCreators.shutdownIntercom());
    };

    const cachedHash = accountIdToUserHashMap.current.get(accountId);
    if (cachedHash) {
      boot(accountId, cachedHash);
      return () => {
        shutdown();
      };
    }

    let mounted = true;
    getIntercomUserHash()
      .then(({ userHash }) => {
        accountIdToUserHashMap.current.set(accountId, userHash);
        if (mounted) {
          boot(accountId, userHash);
        }
      })
      .catch(emptyFunction);

    return () => {
      mounted = false;
      shutdown();
    };
  }, [accountId, isLoggedIn, isIntercomPaused, dispatch]);
};
