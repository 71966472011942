import { createReducer } from "@reduxjs/toolkit";
import {
  initialFetcherStateMeta,
  addAsyncCasesToBuilderV2,
} from "state/hor/addFetcherActionsToBuilder";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";
import { TaxesState } from "state/types";
import { getTaxes } from "state/actionCreators/taxes";

const initialState = {
  data: 0,
  meta: initialFetcherStateMeta,
};

const reducer = createReducer<TaxesState>(initialState, (builder) => {
  addUserSessionScopeReducer(
    addAsyncCasesToBuilderV2({
      builder,
      action: getTaxes,
      prepareData: (oldTaxes, newTaxes) => newTaxes,
      initialData: initialState.data,
    }),
    () => initialState
  );
});

export default reducer;
