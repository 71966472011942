/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.ChatGiftMessage || ($protobuf.roots.ChatGiftMessage = {});

export const GiftOriginType = $root.GiftOriginType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "GIFT_ORIGIN_PREMIUM_MESSAGE"] = 1;
    values[valuesById[2] = "GIFT_ORIGIN_AI_GIFT"] = 2;
    return values;
})();

export const ChatGiftMessage = $root.ChatGiftMessage = (() => {

    function ChatGiftMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ChatGiftMessage.prototype.giftId = "";
    ChatGiftMessage.prototype.uid = "";
    ChatGiftMessage.prototype.iconUrl = "";
    ChatGiftMessage.prototype.assetBundle = "";
    ChatGiftMessage.prototype.receiverId = "";
    ChatGiftMessage.prototype.lottieAnimationUrl = "";
    ChatGiftMessage.prototype.lottieAnimationZipUrl = "";
    ChatGiftMessage.prototype.senderLink = "";
    ChatGiftMessage.prototype.giftOrigin = 1;

    ChatGiftMessage.create = function create(properties) {
        return new ChatGiftMessage(properties);
    };

    ChatGiftMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.giftId);
        writer.uint32(18).string(message.uid);
        if (message.iconUrl != null && Object.hasOwnProperty.call(message, "iconUrl"))
            writer.uint32(26).string(message.iconUrl);
        if (message.assetBundle != null && Object.hasOwnProperty.call(message, "assetBundle"))
            writer.uint32(34).string(message.assetBundle);
        if (message.receiverId != null && Object.hasOwnProperty.call(message, "receiverId"))
            writer.uint32(42).string(message.receiverId);
        if (message.lottieAnimationUrl != null && Object.hasOwnProperty.call(message, "lottieAnimationUrl"))
            writer.uint32(50).string(message.lottieAnimationUrl);
        if (message.lottieAnimationZipUrl != null && Object.hasOwnProperty.call(message, "lottieAnimationZipUrl"))
            writer.uint32(58).string(message.lottieAnimationZipUrl);
        if (message.senderLink != null && Object.hasOwnProperty.call(message, "senderLink"))
            writer.uint32(66).string(message.senderLink);
        if (message.giftOrigin != null && Object.hasOwnProperty.call(message, "giftOrigin"))
            writer.uint32(72).int32(message.giftOrigin);
        return writer;
    };

    ChatGiftMessage.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ChatGiftMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatGiftMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.giftId = reader.string();
                    break;
                }
            case 2: {
                    message.uid = reader.string();
                    break;
                }
            case 3: {
                    message.iconUrl = reader.string();
                    break;
                }
            case 4: {
                    message.assetBundle = reader.string();
                    break;
                }
            case 5: {
                    message.receiverId = reader.string();
                    break;
                }
            case 6: {
                    message.lottieAnimationUrl = reader.string();
                    break;
                }
            case 7: {
                    message.lottieAnimationZipUrl = reader.string();
                    break;
                }
            case 8: {
                    message.senderLink = reader.string();
                    break;
                }
            case 9: {
                    message.giftOrigin = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("giftId"))
            throw $util.ProtocolError("missing required 'giftId'", { instance: message });
        if (!message.hasOwnProperty("uid"))
            throw $util.ProtocolError("missing required 'uid'", { instance: message });
        return message;
    };

    ChatGiftMessage.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ChatGiftMessage.fromObject = function fromObject(object) {
        if (object instanceof $root.ChatGiftMessage)
            return object;
        let message = new $root.ChatGiftMessage();
        if (object.giftId != null)
            message.giftId = String(object.giftId);
        if (object.uid != null)
            message.uid = String(object.uid);
        if (object.iconUrl != null)
            message.iconUrl = String(object.iconUrl);
        if (object.assetBundle != null)
            message.assetBundle = String(object.assetBundle);
        if (object.receiverId != null)
            message.receiverId = String(object.receiverId);
        if (object.lottieAnimationUrl != null)
            message.lottieAnimationUrl = String(object.lottieAnimationUrl);
        if (object.lottieAnimationZipUrl != null)
            message.lottieAnimationZipUrl = String(object.lottieAnimationZipUrl);
        if (object.senderLink != null)
            message.senderLink = String(object.senderLink);
        switch (object.giftOrigin) {
        default:
            if (typeof object.giftOrigin === "number") {
                message.giftOrigin = object.giftOrigin;
                break;
            }
            break;
        case "GIFT_ORIGIN_PREMIUM_MESSAGE":
        case 1:
            message.giftOrigin = 1;
            break;
        case "GIFT_ORIGIN_AI_GIFT":
        case 2:
            message.giftOrigin = 2;
            break;
        }
        return message;
    };

    ChatGiftMessage.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.giftId = "";
            object.uid = "";
            object.iconUrl = "";
            object.assetBundle = "";
            object.receiverId = "";
            object.lottieAnimationUrl = "";
            object.lottieAnimationZipUrl = "";
            object.senderLink = "";
            object.giftOrigin = options.enums === String ? "GIFT_ORIGIN_PREMIUM_MESSAGE" : 1;
        }
        if (message.giftId != null && message.hasOwnProperty("giftId"))
            object.giftId = message.giftId;
        if (message.uid != null && message.hasOwnProperty("uid"))
            object.uid = message.uid;
        if (message.iconUrl != null && message.hasOwnProperty("iconUrl"))
            object.iconUrl = message.iconUrl;
        if (message.assetBundle != null && message.hasOwnProperty("assetBundle"))
            object.assetBundle = message.assetBundle;
        if (message.receiverId != null && message.hasOwnProperty("receiverId"))
            object.receiverId = message.receiverId;
        if (message.lottieAnimationUrl != null && message.hasOwnProperty("lottieAnimationUrl"))
            object.lottieAnimationUrl = message.lottieAnimationUrl;
        if (message.lottieAnimationZipUrl != null && message.hasOwnProperty("lottieAnimationZipUrl"))
            object.lottieAnimationZipUrl = message.lottieAnimationZipUrl;
        if (message.senderLink != null && message.hasOwnProperty("senderLink"))
            object.senderLink = message.senderLink;
        if (message.giftOrigin != null && message.hasOwnProperty("giftOrigin"))
            object.giftOrigin = options.enums === String ? $root.GiftOriginType[message.giftOrigin] === undefined ? message.giftOrigin : $root.GiftOriginType[message.giftOrigin] : message.giftOrigin;
        return object;
    };

    ChatGiftMessage.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ChatGiftMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ChatGiftMessage";
    };

    return ChatGiftMessage;
})();

export { $root as default };
