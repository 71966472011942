import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { EmojiSpan, LinkifyText } from "chat/imports/components";
import { useResizeObserver } from "chat/imports/hooks";
import { StoredMessage } from "chat/state/reducer";
import styles from "./TranslatedMessage.scss";

interface TranslatedMessageProps {
  locale: string;
  message: StoredMessage;
  messageConfig: MessageConfig;
}

const TranslationPlaceholder = () => (
  <FormattedMessage id="translating" defaultMessage="Translating..." />
);

const TranslatedMessage: FC<TranslatedMessageProps> = ({
  messageConfig,
  message,
  locale,
}) => {
  const translation = message.translation?.[locale];
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const translationPlaceholderRef = useRef<HTMLDivElement>(null);
  const [
    isTranslationPlaceholderAbsolute,
    setIsTranslationPlaceholderAbsolute,
  ] = useState(false);
  const [messageTextWidth, setMessageTextWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerSize = useResizeObserver(textRef);

  const messageValue = useMemo(
    () => (message.isTranslated && translation ? translation : message.body),
    [message.isTranslated, translation, message.body]
  );

  useEffect(() => {
    const updateDimensions = () => {
      if (textRef.current) {
        setContainerHeight(textRef.current.offsetHeight);
      }
    };

    updateDimensions();
    textRef.current?.addEventListener("resize", updateDimensions);
  }, [messageValue, containerSize.height]);

  useEffect(() => {
    const textWidth = textRef.current?.offsetWidth;
    if (!messageTextWidth && textWidth) {
      setMessageTextWidth(textWidth);
    }
  }, [translationPlaceholderRef.current?.offsetWidth]);

  useEffect(() => {
    if (message.isTranslating) {
      const placeholderWidth = translationPlaceholderRef.current?.offsetWidth;

      if (placeholderWidth && messageTextWidth) {
        setIsTranslationPlaceholderAbsolute(
          messageTextWidth > placeholderWidth
        );
      }
    }
  }, [message.body, locale, messageTextWidth, message.isTranslating]);

  return (
    <div
      ref={containerRef}
      className={classnames(styles.root, {
        [styles.inline]: containerHeight && message.isTranslated,
      })}
    >
      <EmojiSpan>
        {message.isTranslating && (
          <>
            <div
              className={classnames(
                styles.translationPlaceholder,
                styles.absolute
              )}
            >
              <TranslationPlaceholder />
            </div>
            <div
              ref={translationPlaceholderRef}
              className={classnames(styles.translationPlaceholder, {
                [styles.absolute]: isTranslationPlaceholderAbsolute,
              })}
            >
              <TranslationPlaceholder />
            </div>
          </>
        )}
        <div
          className={classnames(styles.text, {
            [styles.translating]: message.isTranslating,
            [styles.hidden]: !isTranslationPlaceholderAbsolute,
          })}
          ref={textRef}
        >
          <LinkifyText isMyMessage={messageConfig.isMyMessage}>
            {message.isTranslated && translation ? translation : message.body}
          </LinkifyText>
        </div>
      </EmojiSpan>
    </div>
  );
};

export default TranslatedMessage;
