import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { previewMessages } from "chat/components/common/messages";
import { useGiftFromMessage } from "chat/components/currentConversation/useGiftFromMessage";
import { StoredMessage } from "chat/state/reducer";
import { MessageType } from "chat/types";

interface GiftMessagePreviewTextProps {
  message: StoredMessage;
}

export const GiftMessagePreviewText: FC<GiftMessagePreviewTextProps> = ({
  message,
}) => {
  const gift = useGiftFromMessage(message);

  return gift ? (
    <FormattedMessage
      {...previewMessages.specificGiftWithPrice}
      values={{ giftName: gift.name, giftPrice: gift.priceInCredit }}
    />
  ) : (
    <FormattedMessage {...previewMessages[MessageType.GIFT_IN_CHAT]} />
  );
};
