import React, { FC, createContext } from "react";
import { EventFields } from "chat/imports/analytics";
import { Nullable } from "chat/imports/types";

interface AnalyticsScreenDataContextProviderProps {
  value: Nullable<ScreenData>;
}

interface ScreenData {
  [EventFields.SCREEN_STATE]?: string;
  [EventFields.TANGO_SCREEN]: string;
}

export const AnalyticsScreenDataContext =
  createContext<Nullable<ScreenData>>(null);

export const AnalyticsScreenDataContextProvider: FC<
  AnalyticsScreenDataContextProviderProps
> = ({ children, value }) => (
  <AnalyticsScreenDataContext.Provider value={value}>
    {children}
  </AnalyticsScreenDataContext.Provider>
);
