import React, { FC, memo, useCallback } from "react";
import classnames from "classnames";
import { VoidCallback } from "src/types/common";
import { VideoSource } from "src/types/gift";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import styles from "./GiftVideo.scss";

export interface GiftVideoProps {
  onComplete?: VoidCallback;
  sources: Array<VideoSource>;
}

const GiftVideo: FC<GiftVideoProps> = ({ sources, onComplete }) => {
  const breakpoint = useBreakpoint();

  const handleComplete = useCallback(() => {
    onComplete?.();
  }, [onComplete]);

  if (!sources.length) {
    return null;
  }

  return (
    <video
      autoPlay
      muted
      playsInline
      className={classnames(styles.root, styles[breakpoint])}
      onEnded={handleComplete}
      disablePictureInPicture
    >
      {sources.map((source: VideoSource) => (
        <source key={source.src} src={source.src} type={source.type} />
      ))}
    </video>
  );
};

export default memo(GiftVideo);
