import { useCallback } from "react";
import { useDispatch, batch } from "react-redux";
import { saveSettings } from "state/flows/settings";
import { SELECTED_CURRENCY } from "src/constants";
import { selectCurrency } from "state/actionCreators/currencies";
import { Currency } from "src/enums";

const useChangeCurrency = () => {
  const dispatch = useDispatch();

  return useCallback((currency: Currency) => {
    batch(() => {
      dispatch(
        saveSettings({
          [SELECTED_CURRENCY]: currency.toUpperCase(),
        })
      );
      dispatch(selectCurrency(currency));
    });
  }, []);
};

export default useChangeCurrency;
