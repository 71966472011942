import React, { memo, forwardRef } from "react";
import { CustomContentProps, SnackbarContent } from "notistack";
import styles from "./AuctionToast.scss";

const AuctionToast = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ message, className, style }, ref) => (
    <SnackbarContent ref={ref} className={className} style={style}>
      <div className={styles.root}>{message}</div>
    </SnackbarContent>
  )
);

AuctionToast.displayName = "AuctionToast";

export default memo(AuctionToast);
