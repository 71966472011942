/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.RegistrationV9 || ($protobuf.roots.RegistrationV9 = {});

export const UnlinkSocialIdentifierRequest = $root.UnlinkSocialIdentifierRequest = (() => {

    function UnlinkSocialIdentifierRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UnlinkSocialIdentifierRequest.prototype.socialMediaType = 1;

    UnlinkSocialIdentifierRequest.create = function create(properties) {
        return new UnlinkSocialIdentifierRequest(properties);
    };

    UnlinkSocialIdentifierRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.socialMediaType != null && Object.hasOwnProperty.call(message, "socialMediaType"))
            writer.uint32(8).int32(message.socialMediaType);
        return writer;
    };

    UnlinkSocialIdentifierRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UnlinkSocialIdentifierRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UnlinkSocialIdentifierRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.socialMediaType = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    UnlinkSocialIdentifierRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UnlinkSocialIdentifierRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UnlinkSocialIdentifierRequest)
            return object;
        let message = new $root.UnlinkSocialIdentifierRequest();
        switch (object.socialMediaType) {
        default:
            if (typeof object.socialMediaType === "number") {
                message.socialMediaType = object.socialMediaType;
                break;
            }
            break;
        case "PHONENUMBER":
        case 1:
            message.socialMediaType = 1;
            break;
        case "EMAIL":
        case 2:
            message.socialMediaType = 2;
            break;
        case "FACEBOOK":
        case 3:
            message.socialMediaType = 3;
            break;
        case "GOOGLE":
        case 4:
            message.socialMediaType = 4;
            break;
        case "APPLE":
        case 5:
            message.socialMediaType = 5;
            break;
        case "JOSH":
        case 6:
            message.socialMediaType = 6;
            break;
        case "TWITTER":
        case 7:
            message.socialMediaType = 7;
            break;
        }
        return message;
    };

    UnlinkSocialIdentifierRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.socialMediaType = options.enums === String ? "PHONENUMBER" : 1;
        if (message.socialMediaType != null && message.hasOwnProperty("socialMediaType"))
            object.socialMediaType = options.enums === String ? $root.SocialMediaType[message.socialMediaType] === undefined ? message.socialMediaType : $root.SocialMediaType[message.socialMediaType] : message.socialMediaType;
        return object;
    };

    UnlinkSocialIdentifierRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UnlinkSocialIdentifierRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UnlinkSocialIdentifierRequest";
    };

    return UnlinkSocialIdentifierRequest;
})();

export const UnlinkSocialIdentifierResponse = $root.UnlinkSocialIdentifierResponse = (() => {

    function UnlinkSocialIdentifierResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UnlinkSocialIdentifierResponse.prototype.responseCode = 1;

    UnlinkSocialIdentifierResponse.create = function create(properties) {
        return new UnlinkSocialIdentifierResponse(properties);
    };

    UnlinkSocialIdentifierResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.responseCode != null && Object.hasOwnProperty.call(message, "responseCode"))
            writer.uint32(8).int32(message.responseCode);
        return writer;
    };

    UnlinkSocialIdentifierResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UnlinkSocialIdentifierResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UnlinkSocialIdentifierResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.responseCode = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    UnlinkSocialIdentifierResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UnlinkSocialIdentifierResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UnlinkSocialIdentifierResponse)
            return object;
        let message = new $root.UnlinkSocialIdentifierResponse();
        switch (object.responseCode) {
        default:
            if (typeof object.responseCode === "number") {
                message.responseCode = object.responseCode;
                break;
            }
            break;
        case "ACCEPTED":
        case 1:
            message.responseCode = 1;
            break;
        case "FAILED":
        case 2:
            message.responseCode = 2;
            break;
        case "AT_LEAST_ONE_IDENTIFIER_REQUIRED":
        case 3:
            message.responseCode = 3;
            break;
        }
        return message;
    };

    UnlinkSocialIdentifierResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.responseCode = options.enums === String ? "ACCEPTED" : 1;
        if (message.responseCode != null && message.hasOwnProperty("responseCode"))
            object.responseCode = options.enums === String ? $root.ResponseCode[message.responseCode] === undefined ? message.responseCode : $root.ResponseCode[message.responseCode] : message.responseCode;
        return object;
    };

    UnlinkSocialIdentifierResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UnlinkSocialIdentifierResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UnlinkSocialIdentifierResponse";
    };

    return UnlinkSocialIdentifierResponse;
})();

export const ResponseCode = $root.ResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "ACCEPTED"] = 1;
    values[valuesById[2] = "FAILED"] = 2;
    values[valuesById[3] = "AT_LEAST_ONE_IDENTIFIER_REQUIRED"] = 3;
    return values;
})();

export const SocialMediaType = $root.SocialMediaType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "PHONENUMBER"] = 1;
    values[valuesById[2] = "EMAIL"] = 2;
    values[valuesById[3] = "FACEBOOK"] = 3;
    values[valuesById[4] = "GOOGLE"] = 4;
    values[valuesById[5] = "APPLE"] = 5;
    values[valuesById[6] = "JOSH"] = 6;
    values[valuesById[7] = "TWITTER"] = 7;
    return values;
})();

export { $root as default };
