import { pad } from "chat/utils/pad";
import { parseDuration } from "chat/utils/parseDuration";

export const formatDuration = (durationInMilliseconds: number) => {
  const { hours, minutes, seconds } = parseDuration(durationInMilliseconds);

  let result = "";

  if (hours > 0) {
    result += `${pad(hours)}:`;
  }

  result += `${pad(minutes)}:${pad(seconds)}`;

  return result;
};
