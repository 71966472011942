import type { RealTimeRecommendationsGiftEventParams } from "src/features/recommendations/common/types";
import { RealTimeRecommendationsEventBuilder } from "src/features/recommendations/builders/RealTimeRecommendationsEventBuilder";
import { realTimeRecommendationsQueueService } from "src/features/recommendations/client";
import {
  RealTimeRecommendationsEventType,
  RealTimeRecommendationsParamKey,
} from "src/features/recommendations/common/enums";

export const emitRealTimeRecommendationsGiftEvent = (
  params: RealTimeRecommendationsGiftEventParams
) => {
  const { streamerId, streamId, giftFrom, giftCreditsPrice } = params;

  const eventBuilder = new RealTimeRecommendationsEventBuilder(
    RealTimeRecommendationsEventType.GIFT
  );

  eventBuilder
    .addParam(RealTimeRecommendationsParamKey.STREAMER_ID, {
      stringValue: streamerId,
    })
    .addParam(RealTimeRecommendationsParamKey.GIFT_CREDITS_PRICE, {
      floatValue: giftCreditsPrice,
    })
    .addParam(RealTimeRecommendationsParamKey.GIFT_FROM, {
      stringValue: giftFrom,
    });

  if (streamId) {
    eventBuilder.addParam(RealTimeRecommendationsParamKey.STREAM_ID, {
      stringValue: streamId,
    });
  }

  realTimeRecommendationsQueueService.scheduleEvent(eventBuilder.build());
};
