import React, { FC, useCallback, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import { TOAST_SHOW_DURATION_MILLIS } from "src/constants";
import { ToastWrapperProps } from "src/types/toast";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { useToast } from "ui/hooks/useToast";
import useIsOnBroadcast from "ui/navigation/useIsOnBroadcast";
import useIsOnStream from "ui/navigation/useIsOnStream";
import transition from "./ToastTransition.scss";
import styles from "./ToastWrapper.scss";

const ToastWrapper: FC<ToastWrapperProps> = ({
  children,
  id,
  lifetime = TOAST_SHOW_DURATION_MILLIS,
  type,
  onDestroy,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { remove } = useToast();
  const breakpoint = useBreakpointPrecise();
  const isOnStream = useIsOnStream();
  const isOnBroadcast = useIsOnBroadcast();
  const isDarkTheme = isOnStream || isOnBroadcast;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, lifetime);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [lifetime]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const onRemove = useCallback(() => {
    onDestroy?.();
    remove(id);
  }, [id, remove, onDestroy]);

  return (
    <CSSTransition
      in={isVisible}
      appear
      mountOnEnter
      unmountOnExit
      timeout={300}
      classNames={transition}
      onExited={onRemove}
    >
      <div
        className={classnames(styles.root, styles[breakpoint], {
          [styles.shader]: isDarkTheme,
        })}
        data-testid={`toast-${type}-${id}`}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

ToastWrapper.displayName = "ToastWrapper";

export default ToastWrapper;
