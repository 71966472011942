import { Dispatch } from "redux";
import UAParser from "ua-parser-js";
import { DeviceType } from "src/enums";
import { LanguageIsoCode } from "src/types/common";
import {
  deviceTypeDetected,
  getFingerprint,
  setLocale,
} from "state/actionCreators/deviceInfo";

const calcType = (uaParser: UAParser.UAParserInstance) => {
  const device = uaParser.getDevice();
  const os = uaParser.getOS();
  if (os.name === "Mac OS") {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 2
      ? DeviceType.IPAD
      : DeviceType.DESKTOP;
  }
  if (os.name === "Android") {
    return DeviceType.ANDROID;
  }
  if (os.name === "iOS") {
    return device.type === "tablet" ? DeviceType.IPAD : DeviceType.IOS;
  }
  if (
    ["embedded", "mobile", "tablet", "wearable"].includes(device.type || "")
  ) {
    return DeviceType.MOBILE_OTHER;
  }

  return DeviceType.DESKTOP;
};

export const detectDeviceType =
  (ua = window.navigator.userAgent) =>
  (dispatch: Dispatch) => {
    const uaParser = new UAParser(ua);
    const type = calcType(uaParser);
    const { name, version } = uaParser.getOS();
    dispatch(
      deviceTypeDetected({
        type,
        os: name,
        version,
        browserName: uaParser.getBrowser().name,
      })
    );
  };

export const setDeviceLocale =
  (locale: LanguageIsoCode) => (dispatch: Dispatch) => {
    dispatch(setLocale(locale));
  };

export const calculateDeviceFingerprint = () => getFingerprint();
