import { createSlice } from "@reduxjs/toolkit";
import { loadWelcomeOnboardingOffer } from "src/features/welcomeOnboardingOffer/state/asyncAction";
import { Nullable } from "src/types/common";
import { PersonalOffersConfig } from "src/types/personalOffer";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";

export type WelcomeOnboardingOfferState = AsyncState<
  Nullable<PersonalOffersConfig>,
  string
>;

const welcomeOfferInitialState: WelcomeOnboardingOfferState = {
  data: null,
  meta: initialFetcherStateMeta,
};

const welcomeOnboardingOfferSlice = createSlice({
  name: "welcome-onboarding-offer",
  initialState: welcomeOfferInitialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        builder,
        action: loadWelcomeOnboardingOffer,
        prepareData: (_, newConfig: Nullable<PersonalOffersConfig>) =>
          newConfig,
        initialData: welcomeOfferInitialState.data,
      }),
      () => welcomeOfferInitialState
    );
  },
});

export default welcomeOnboardingOfferSlice.reducer;
