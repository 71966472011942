import {
  VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW,
  VIEWER_SESSION_RESET,
} from "state/actionTypes";

const initialState = true;

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      return true;
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW: {
      return !action.error;
    }
  }
  return state;
};

export const selectors = {
  isNetworkAvailable: (state) => state,
};
