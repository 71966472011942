import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "state/delegate";
import { vipConfigsSelectors } from "state/selectors";
import { VipSelectorArgs } from "state/selectors/vipConfigs";

const useVipInfo = ({
  vipConfigId,
  vipLevel,
  vipLabel,
  vipName,
}: VipSelectorArgs) => {
  const selector = useCallback(
    (state: RootState) =>
      vipConfigsSelectors.getVipInfoFromConfig(state, {
        vipConfigId,
        vipLevel,
        vipLabel,
        vipName,
      }),
    [vipConfigId, vipLevel, vipLabel, vipName]
  );

  return useSelector(selector, shallowEqual);
};

export default useVipInfo;
