import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { chatMessages } from "chat/components/common/messages";
import ImageMessage from "chat/components/currentConversation/ImageMessage";
import { MessageConfig } from "chat/components/currentConversation/Message";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import styles from "./GIFMessage.scss";

interface GIFMessageProps {
  height: number;
  isUploading?: boolean;
  messageConfig: MessageConfig;
  url: string;
  width: number;
}

const GIFMessage: FC<GIFMessageProps> = ({
  height,
  width,
  messageConfig,
  url,
}) => (
  <>
    <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH5} className={styles.icon}>
      <FormattedMessage {...chatMessages.GIF} />
    </Typography>

    <ImageMessage
      height={height}
      messageConfig={messageConfig}
      url={url}
      width={width}
    />
  </>
);

export default GIFMessage;
