import { createSlice } from "@reduxjs/toolkit";
import { fetchProfileSettingsAction } from "src/features/profile/settings/state/fetchProfileSettingsAction";
import { AccountInfoResponse } from "src/features/profile/settings/types";
import { Nullable } from "src/types/common";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import addUserSessionScopeReducer from "state/hor/addUserSessionScopeReducer";

export type ProfileSettingsState = AsyncState<
  Nullable<AccountInfoResponse>,
  string
>;

const profileSettingsInitialState: ProfileSettingsState = {
  data: null,
  meta: initialFetcherStateMeta,
};

const profileSettingsSlice = createSlice({
  name: "profile-settings",
  initialState: profileSettingsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        builder,
        action: fetchProfileSettingsAction,
        prepareData: (_, newConfig: Nullable<AccountInfoResponse>) => newConfig,
        initialData: profileSettingsInitialState.data,
      }),
      () => profileSettingsInitialState
    );
  },
});

export default profileSettingsSlice.reducer;
