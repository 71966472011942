import { emitEvent } from "./emit";
import { EventFields, EventNames } from "@analytics/enums";

/**
 * @param {string} userId
 * @param {string} peerId
 * @param {number} source
 */
export const emitUnfollow = (userId, peerId, source) => {
  emitEvent(EventNames.FOLLOW_UNFOLLOW, {
    [EventFields.SOURCE]: source,
    [EventFields.USER_ID]: userId,
    [EventFields.PEER_ID]: peerId,
    [EventFields.IS_FOLLOW]: 0,
  });
};

/**
 * @param {string} userId
 * @param {string} peerId
 * @param {number} source
 */
export const emitFollow = (userId, peerId, source, tcnnTrackingId) => {
  emitEvent(EventNames.FOLLOW_UNFOLLOW, {
    [EventFields.SOURCE]: source,
    [EventFields.USER_ID]: userId,
    [EventFields.PEER_ID]: peerId,
    [EventFields.IS_FOLLOW]: 1,
    [EventFields.A_TCNN_TRACKING_NUMBER]: tcnnTrackingId,
  });
};
