import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SocialGamesResponseCode } from "src/features/socialGames/common/enums";
import { SocialGamesGameLauncherResponse } from "src/features/socialGames/common/types";
import { resetGame } from "src/features/socialGames/state/actions";
import { Nullable } from "src/types/common";

export interface SocialGamesGameState {
  error: Nullable<string>;
  gameLaunchScript: string;
  loading: boolean;
  responseCode: Nullable<SocialGamesResponseCode>;
}

const initialState: SocialGamesGameState = {
  error: null,
  loading: false,
  responseCode: null,
  gameLaunchScript: "",
};

const gameSlice = createSlice({
  name: "socialGamesGame",
  initialState,
  reducers: {
    loadSocialGamesGameLauncherStart(state) {
      state.loading = true;
      state.responseCode = null;
      state.error = null;
    },
    loadSocialGamesGameLauncherComplete(
      state,
      action: PayloadAction<SocialGamesGameLauncherResponse>
    ) {
      state.loading = false;
      state.responseCode = action.payload.responseCode;
      state.gameLaunchScript = action.payload.launchScript;
    },
    loadSocialGamesGameLauncherFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => builder.addCase(resetGame, () => initialState),
});

export const {
  loadSocialGamesGameLauncherStart,
  loadSocialGamesGameLauncherFailure,
  loadSocialGamesGameLauncherComplete,
} = gameSlice.actions;

export const socialGamesGameReducer = gameSlice.reducer;
