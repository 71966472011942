import React, { memo, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import BackButton from "chat/components/common/BackButton";
import ChatAvatar from "chat/components/common/ChatAvatar";
import {
  EventFields,
  emitOpenProfileEvent,
  useProfileEvent,
} from "chat/imports/analytics";
import { DotsIcon } from "chat/imports/assets";
import {
  Button,
  DisplayName,
  MoreMenu,
  Typography,
} from "chat/imports/components";
import {
  BottomScreenType,
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  MiniProfileEntranceSource,
  ProfileType,
  TANGO_ACCOUNT_ID,
  TYPOGRAPHY_TYPE,
  linkToChat,
  linkToMessageRequest,
} from "chat/imports/constants";
import { useBreakpointPrecise, useProfileById } from "chat/imports/hooks";
import {
  RootState,
  loadUserCurrentStream,
  profilesCacheSelectors,
  showBottomScreen,
  streamsCacheSelectors,
} from "chat/imports/state";
import { AccountInfo } from "chat/imports/types";
import {
  formatDisplayName,
  makeLinkToProfile,
  sharedMessages,
} from "chat/imports/utils";
import { StoredConversation } from "chat/state/reducer";
import { Group } from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import styles from "./ConversationHeader.scss";

interface OneOnOneConversationHeaderContentProps {
  accountInfo: AccountInfo | undefined;
  conversationId: string;
}

const selector = (accountId: string) => (state: RootState) => ({
  stream: streamsCacheSelectors.getStreamByBroadcasterId(state, accountId),
  basicProfile: profilesCacheSelectors.getBasicProfile(state, accountId),
});

const OneOnOneConversationHeaderContent =
  memo<OneOnOneConversationHeaderContentProps>(
    ({ accountInfo, conversationId }) => {
      const intl = useIntl();
      const history = useHistory();
      const dispatch = useDispatch();
      const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;
      const getProfileBIEvent = useProfileEvent(
        MiniProfileEntranceSource.OFFLINE_CHAT,
        ProfileType.PROFILE
      );
      const isMessageRequestRoute = useRouteMatch(linkToMessageRequest);
      const { account_id, first_name, last_name, thumbnail_url } =
        accountInfo || {};

      const accountId = account_id || conversationId;
      const { basicProfile, stream } = useSelector(
        useCallback(selector(accountId), [accountId]),
        shallowEqual
      );

      const { basicProfile: basicProfileHook } = useProfileById(
        accountId,
        true
      );

      const handleClickMenuButton = useCallback(() => {
        dispatch(
          // @ts-ignore TODO:types, Jira ticket: https://tango-me.atlassian.net/browse/WEB-5825
          showBottomScreen({
            screenType: BottomScreenType.USER_PROFILE_SCREEN,
            screenData: {
              accountId,
              isOfflineChat: true,
              accountInfo,
              conversationId,
            },
          })
        );
      }, [accountId, dispatch]);

      useEffect(() => {
        if (!stream && !isDesktop) {
          dispatch(loadUserCurrentStream(accountId));
        }
      }, [stream, isDesktop, dispatch]);

      const handleBackButtonClick = useCallback(() => {
        isMessageRequestRoute
          ? history.push(linkToMessageRequest)
          : history.push(linkToChat);
      }, [history, isMessageRequestRoute]);

      const onClick = useCallback(() => {
        emitOpenProfileEvent(
          getProfileBIEvent(accountId, {
            [EventFields.CHAT_ID]: conversationId,
            [EventFields.FROM_MINI_PROFILE]: 0,
          })
        );
      }, [accountId, getProfileBIEvent]);

      if (!accountId) {
        return null;
      }

      const name = formatDisplayName({
        intl,
        basicProfile,
        firstName: first_name,
        lastName: last_name,
      });

      if (accountId === TANGO_ACCOUNT_ID) {
        return (
          <>
            {!isDesktop && (
              <BackButton
                className={styles.backButton}
                onClick={handleBackButtonClick}
              />
            )}
            <ChatAvatar
              conversationId={conversationId}
              className={styles.avatar}
              pictureUrl={basicProfile?.profileThumbnailUrl}
              name={name}
              accountInfo={accountInfo}
              isSmall={!isDesktop}
              basicProfile={basicProfile}
            />
            <Typography
              type={TYPOGRAPHY_TYPE.HEADLINE3}
              className={styles.name}
            >
              <DisplayName basicProfile={basicProfile} />
            </Typography>
          </>
        );
      }

      return (
        <>
          {!isDesktop && (
            <BackButton
              className={styles.backButton}
              onClick={handleBackButtonClick}
            />
          )}
          <ChatAvatar
            to={makeLinkToProfile(accountId, basicProfile)}
            conversationId={conversationId}
            className={styles.avatar}
            pictureUrl={basicProfile?.profileThumbnailUrl || thumbnail_url}
            name={name}
            onClick={onClick}
            accountInfo={accountInfo}
            isSmall={!isDesktop}
            basicProfile={basicProfile}
          />
          <Typography
            type={TYPOGRAPHY_TYPE.HEADLINE3}
            className={classnames(styles.name, styles.oneOnOneName)}
          >
            <Link
              to={makeLinkToProfile(accountId, basicProfile)}
              className={styles.name}
              onClick={onClick}
            >
              <DisplayName
                basicProfile={basicProfile}
                showVerified
                firstName={first_name}
                lastName={last_name}
              />
            </Link>
          </Typography>
          {/* TODO: Add subtitle for "Last seen recently". Code snippet provided below. */}
          {/* <Typography
            type={
              isDesktop
                ? TYPOGRAPHY_TYPE.PARAGRAPH3
                : TYPOGRAPHY_TYPE.PARAGRAPH5
            }
            className={styles.subtitle}
          >
            <span>Last seen recently</span>
          </Typography> */}
          {isDesktop ? (
            <MoreMenu
              accountId={accountId}
              basicProfile={basicProfileHook}
              className={styles.moreMenu}
              accountInfo={accountInfo}
              conversationId={conversationId}
              isOfflineChat
            />
          ) : (
            <Button
              size={ButtonSize.MEDIUM_32}
              variant={ButtonVariant.ICON_ONLY}
              onClick={handleClickMenuButton}
              className={styles.moreMenu}
            >
              <DotsIcon />
            </Button>
          )}
        </>
      );
    }
  );

OneOnOneConversationHeaderContent.displayName =
  "OneOnOneConversationHeaderContent";

interface GroupConversationHeaderContentProps {
  conversationId: string;
  groupInfo?: Group;
}

const GroupConversationHeaderContent =
  memo<GroupConversationHeaderContentProps>(({ groupInfo, conversationId }) => {
    const intl = useIntl();
    const history = useHistory();
    const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;
    const fallbackMessage = intl.formatMessage(sharedMessages.groupChat);

    const handleBackButtonClick = useCallback(() => {
      history.replace(linkToChat);
    }, [history]);

    return (
      <>
        {!isDesktop && (
          <BackButton
            className={styles.backButton}
            onClick={handleBackButtonClick}
            variant={ButtonVariant.ICON_ONLY}
          />
        )}
        <ChatAvatar
          isGroupAvatar
          className={styles.avatar}
          conversationId={conversationId}
          name={groupInfo?.name || fallbackMessage}
          pictureUrl={groupInfo?.pictureUrl}
          isSmall={!isDesktop}
        />
        <Typography type={TYPOGRAPHY_TYPE.HEADLINE3} className={styles.name}>
          {groupInfo?.name || (
            <FormattedMessage id="group-chat" defaultMessage="Group Chat" />
          )}
        </Typography>
        <Typography
          type={
            isDesktop ? TYPOGRAPHY_TYPE.PARAGRAPH3 : TYPOGRAPHY_TYPE.PARAGRAPH5
          }
          className={styles.subtitle}
          data-testid="members-count"
        >
          {groupInfo?.groupMembersCount ? (
            <FormattedMessage
              id="chat.conversation.header.group-members-count"
              defaultMessage="{number} members"
              values={{ number: groupInfo.groupMembersCount }}
            />
          ) : (
            <FormattedMessage
              id="chat.navigation.title.group.removed"
              defaultMessage="Group is inaccessible"
            />
          )}
        </Typography>
      </>
    );
  });

GroupConversationHeaderContent.displayName = "GroupConversationHeaderContent";

interface ConversationHeaderProps {
  conversation: StoredConversation;
}
const ConversationHeader: React.FC<ConversationHeaderProps> = ({
  conversation,
}) => {
  const breakpoint = useBreakpointPrecise();
  const { group_info, account_info, conversation_id } = conversation;

  const isGroupChat = isGroupChatId(conversation_id);

  return (
    <div
      className={classnames(styles.root, styles[breakpoint])}
      data-testid="conversation-header"
    >
      {group_info || isGroupChat ? (
        <GroupConversationHeaderContent
          groupInfo={group_info}
          conversationId={conversation_id}
        />
      ) : (
        <OneOnOneConversationHeaderContent
          accountInfo={account_info}
          conversationId={conversation_id}
        />
      )}
    </div>
  );
};

export default memo(ConversationHeader);
