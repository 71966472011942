import { useEffect } from "react";

export const toggleSplashScreen = (isShown: boolean) => {
  const splash = document.getElementById("splash");
  if (splash) {
    splash.style.display = isShown ? "block" : "none";
  }
};

export default (isShown: boolean) => {
  useEffect(() => toggleSplashScreen(isShown), [isShown]);
};
