import { createAction } from "@reduxjs/toolkit";
import { ACME_RECEIVED } from "state/actionTypes";
import type {
  TransactionStatusAcme,
  TransactionStatusAnalytics,
} from "state/tree/transactionStatusAnalytics";

export const actions = {
  acmeTransactionResponse: createAction<TransactionStatusAcme>(ACME_RECEIVED),
  addTransaction: createAction<TransactionStatusAnalytics["transactionsData"]>(
    "lwc/transactionStatus/add"
  ),
};
