import React, { forwardRef, memo } from "react";
import { CustomContentProps, SnackbarContent } from "notistack";
import classnames from "classnames";
import { themeLockClassnames } from "ui/Theme";
import { FormattedMessage } from "react-intl";
import sharedMessages from "ui/common/intl/sharedMessages";
import styles from "./LandingDismissToast.scss";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";

const LandingDismissToast = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ message, className, style }, ref) => (
    <SnackbarContent ref={ref} className={className} style={style}>
      <div className={classnames(styles.root, themeLockClassnames.light)}>
        <Typography type={TYPOGRAPHY_TYPE.HEADLINE5} className={styles.title}>
          {message}
        </Typography>
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH4}
          className={styles.subtitle}
        >
          <FormattedMessage {...sharedMessages.enjoyMore} />
        </Typography>
      </div>
    </SnackbarContent>
  )
);

LandingDismissToast.displayName = "LandingDismissToast";

export default memo(LandingDismissToast);
