import Long from "long";
import protobuf from "protobufjs/minimal";

// Disables conversion to Long and makes it convert to plain JS number
protobuf.util.Long = undefined;
protobuf.configure();

export const parseMessageFromBase64 = (base64Value, messageType) => {
  const buffer = protobuf.util.newBuffer(
    protobuf.util.base64.length(base64Value)
  );
  protobuf.util.base64.decode(base64Value, buffer, 0);

  return messageType.decode(buffer);
};

export const encodeMessageToBase64 = (message, messageType) =>
  btoa(String.fromCharCode.apply(null, messageType.encode(message).finish()));

export const convertLongToString = (val) => {
  if (Array.isArray(val)) {
    return val.map((item) => convertLongToString(item));
  } else if (Long.isLong(val)) {
    return val.toString();
  } else if (typeof val === "object" && val !== null) {
    return Object.keys(val).reduce((acc, key) => {
      acc[key] = convertLongToString(val[key]);

      return acc;
    }, {});
  }

  return val;
};
