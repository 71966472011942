import React, { Ref } from "react";
import cn from "classnames";
import Gift from "src/features/gifts/components/gift/Gift";
import { RawGift } from "src/features/gifts/imports";
import styles from "src/features/gifts/components/giftsList/GiftsList.scss";

type GiftsListProps = {
  className?: string;
  gifts: RawGift[];
  onClickGift: (giftId: string) => void;
  selectedGiftId?: string;
  selectedGiftRef?: Ref<HTMLDivElement>;
};

const GiftsList = ({
  gifts,
  onClickGift,
  selectedGiftId,
  selectedGiftRef,
  className,
}: GiftsListProps) => (
  <div
    className={cn(styles.root, className)}
    data-testid="send-media-gifts-list"
  >
    {gifts.map((gift, i) => {
      const isSelected = gift.id === selectedGiftId;

      return (
        <Gift
          ref={isSelected ? selectedGiftRef : null}
          key={`${gift.id + i}`} // uniq key for duplicated gifts
          gift={gift}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => onClickGift(gift.id)}
          isSelected={isSelected}
        />
      );
    })}
  </div>
);

export default GiftsList;
