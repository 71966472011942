import { uniq } from "src/utils/miniLodash";
import {
  FAILED_TO_LOAD_USER_SEARCH,
  LOADED_USER_SEARCH,
  WILL_LOAD_USER_SEARCH,
} from "state/actionTypes";

export interface QueryData {
  hasLoadError: boolean;
  isLoading: boolean;
  result: string[];
}

export interface ResultsHash {
  [query: string]: QueryData;
}

export type UserSearchState = {
  resultsHash: ResultsHash;
};

interface UserSearchAction {
  payload?: string[];
  query?: string;
  type: string;
}

const initialState: UserSearchState = {
  resultsHash: {},
};

export default (state = initialState, action: UserSearchAction) => {
  const { resultsHash } = state;
  const { payload = [], query = "", type } = action;

  switch (type) {
    case LOADED_USER_SEARCH:
      return {
        resultsHash: {
          ...resultsHash,
          [query]: {
            result: uniq([...(resultsHash[query].result || []), ...payload]),
            isLoading: false,
            hasLoadError: false,
          },
        },
      };
    case FAILED_TO_LOAD_USER_SEARCH:
      return {
        resultsHash: {
          ...resultsHash,
          [query]: {
            ...resultsHash[query],
            isLoading: false,
            hasLoadError: true,
          },
        },
      };
    case WILL_LOAD_USER_SEARCH:
      return {
        resultsHash: {
          ...resultsHash,
          [query]: {
            ...resultsHash[query],
            isLoading: true,
            hasLoadError: false,
          },
        },
      };
    default:
      break;
  }

  return state;
};

export const selectors = {
  getKnownQueries: (state: UserSearchState) => state.resultsHash,
  getSearchResults: (state: UserSearchState, query: string) =>
    state.resultsHash[query],
};
