import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import MultiNameFormattedMessage from "chat/components/common/MultiNameFormattedMessage";
import {
  chatMessages,
  groupChatMessages,
} from "chat/components/common/messages";
import { ScreenshotMessageText } from "chat/components/currentConversation/components/ScreenshotMessageText";
import { DisplayName } from "chat/imports/components";
import { userSelectors } from "chat/imports/state";
import { BasicProfile } from "chat/imports/types";
import { StoredMessage } from "chat/state/reducer";
import { MessageType } from "chat/types";

interface SystemChatMessageProps {
  basicProfile: BasicProfile;
  message: StoredMessage;
}

const SystemChatMessage: React.FC<SystemChatMessageProps> = ({
  message,
  basicProfile,
}) => {
  const { type, group_msg_payload, from, body } = message;

  const accountId = useSelector(userSelectors.getMyAccountId);
  const isMyMessage = accountId === message.from;

  switch (type) {
    case MessageType.GROUP_MEMBER_JOIN:
      if (!group_msg_payload?.joined_account_ids) {
        return null;
      }

      return group_msg_payload.joined_account_ids.length === 1 &&
        from === group_msg_payload.joined_account_ids[0] ? (
        <FormattedMessage
          {...groupChatMessages.joinByAcceptingInvite}
          values={{
            inviterName: <DisplayName short basicProfile={basicProfile} />,
          }}
        />
      ) : (
        <MultiNameFormattedMessage
          basicProfile={basicProfile}
          accountIds={group_msg_payload.joined_account_ids}
        />
      );
    case MessageType.GROUP_MEMBER_LEAVE:
      return (
        <FormattedMessage
          {...groupChatMessages[MessageType.GROUP_MEMBER_LEAVE]}
          values={{ name: <DisplayName short basicProfile={basicProfile} /> }}
        />
      );
    case MessageType.GROUP_NAME_CHANGE:
      return (
        <FormattedMessage
          {...groupChatMessages[MessageType.GROUP_NAME_CHANGE]}
          values={{
            groupName: body,
            name: <DisplayName short basicProfile={basicProfile} />,
          }}
        />
      );
    case MessageType.LIKE_MESSAGE:
      return <span>{message.alt}</span>;
    case MessageType.VIDEO_SAVED_INFO_MESSAGE: {
      if (isMyMessage) {
        return <FormattedMessage {...chatMessages.youSavedVideo} />;
      }

      return (
        <FormattedMessage
          {...chatMessages.someoneSavedVideo}
          values={{ name: <DisplayName short basicProfile={basicProfile} /> }}
        />
      );
    }
    case MessageType.PHOTO_SAVED_INFO_MESSAGE: {
      if (isMyMessage) {
        return <FormattedMessage {...chatMessages.youSavedPhoto} />;
      }

      return (
        <FormattedMessage
          {...chatMessages.someoneSavedPhoto}
          values={{ name: <DisplayName short basicProfile={basicProfile} /> }}
        />
      );
    }
    case MessageType.SCREENSHOT_INFO_MESSAGE:
      return from ? <ScreenshotMessageText authorAccountId={from} /> : null;
    default:
      return null;
  }
};

export default memo(SystemChatMessage);
