import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { StreamerPlan } from "api/subscriptionPerStreamer";
import { StreamEvents } from "src/enums";
import { PersonalOffersConfig } from "src/types/personalOffer";
import { BasicProfile } from "src/types/profile/profile";
import actions from "state/actionCreators/subscriptionPerStreamer";
import { VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT } from "state/actionTypes";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import { ShopOffer } from "ui/scenes/landingPage/types";

const subscriptionInitialState = {
  pendingSubscriptionsForStreamer: [],
  animationOverlay: {
    show: false,
    loop: false,
    type: "",
    profile: {},
    accountId: "",
    streamerAccountId: "",
  },
};

export interface SubscriptionState {
  animationOverlay: {
    accountId?: string;
    loop: boolean;
    profile: Partial<BasicProfile>;
    show: boolean;
    streamerAccountId?: string;
    type: string;
  };
}

const subscriptions = createReducer<SubscriptionState>(
  subscriptionInitialState,
  (builder) => {
    builder
      .addCase(actions.showSubscriberAnimation, (state, action) => {
        const { type, profile, streamerAccountId } = action.payload;

        return {
          ...state,
          animationOverlay: {
            show: true,
            loop: true,
            type,
            profile,
            streamerAccountId,
          },
        };
      })
      .addCase(actions.hideSubscriberAnimation, (state) => ({
        ...state,
        animationOverlay: subscriptionInitialState.animationOverlay,
      }))
      .addMatcher(
        (action) => action.type === VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
        (state, action) => {
          const { payload } = action;

          if (!payload.eventIds || payload.eventIds.length < 1) {
            return state;
          }

          const id = payload.eventIds[0];

          if (payload.entities.events[id].type === StreamEvents.SUBSCRIPTION) {
            return {
              ...state,
              animationOverlay: {
                show: true,
                loop: false,
                type: "",
                profile:
                  payload.entities.basicProfile[
                    payload.entities.events[id].accountId
                  ],
                accountId: payload.entities.events[id].accountId,
              },
            };
          }
        }
      );
  }
);

export type StreamerPlansState = AsyncState<
  Partial<Record<string, StreamerPlan[]>>,
  string
>;

const streamerPlansInitialState = {
  data: {},
  meta: initialFetcherStateMeta,
};

const streamerPlans = createReducer<StreamerPlansState>(
  streamerPlansInitialState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.fetchStreamerPlans,
      prepareData: (oldData, newData, meta) => ({
        ...oldData,
        [meta.arg.streamerId]: newData,
      }),
      initialData: streamerPlansInitialState.data,
    });
  }
);

export type CreditOffersState = AsyncState<
  Partial<Record<string, PersonalOffersConfig | ShopOffer[]>>,
  string
>;

const creditOffersInitialState = {
  data: {},
  meta: initialFetcherStateMeta,
};

const creditOffers = createReducer<CreditOffersState>(
  creditOffersInitialState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.fetchCreditOffers,
      prepareData: (oldData, newData, meta) => ({
        ...oldData,
        [meta.arg.userId]: newData,
      }),
      initialData: creditOffersInitialState.data,
    });
  }
);

export type UpgradeOffersState = AsyncState<
  Partial<Record<string, PersonalOffersConfig | ShopOffer[]>>,
  string
>;

const upgradeOffersInitialState = {
  data: {},
  meta: initialFetcherStateMeta,
};

const upgradeOffers = createReducer<UpgradeOffersState>(
  upgradeOffersInitialState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      builder,
      action: actions.fetchUpgradeOffers,
      prepareData: (oldData, newData, meta) => ({
        ...oldData,
        [meta.arg.streamerId]: newData,
      }),
      initialData: upgradeOffersInitialState.data,
    });
  }
);

export interface SubscriptionsPerStreamerState {
  creditOffers: CreditOffersState;
  streamerPlans: StreamerPlansState;
  subscriptions: SubscriptionState;
  upgradeOffers: UpgradeOffersState;
}

export default combineReducers({
  creditOffers,
  streamerPlans,
  upgradeOffers,
  subscriptions,
});
