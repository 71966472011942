import { useState, useEffect, useRef, useLayoutEffect } from "react";
const detectVisibilityState = () => document.visibilityState === "visible";

type Options = { considerFocus: boolean };

const usePageVisibility = (
  { considerFocus = true }: Options = {} as Options
) => {
  const [isVisible, setVisible] = useState(detectVisibilityState);
  useEffect(() => {
    const onVisibilityChange = () => setVisible(detectVisibilityState());
    document.addEventListener("visibilitychange", onVisibilityChange);
    if (!considerFocus) {
      onVisibilityChange();
      return () => {
        document.removeEventListener("visibilitychange", onVisibilityChange);
      };
    }
    const onFocus = () => setVisible(true);
    const onBlur = () => setVisible(false);
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [considerFocus]);

  return isVisible;
};

export default usePageVisibility;

export const usePageVisibilityRef = (params?: Options) => {
  const isVisible = usePageVisibility(params);
  const isVisibleRef = useRef(isVisible);
  useLayoutEffect(() => {
    isVisibleRef.current = isVisible;
  }, [isVisible]);
  return isVisibleRef;
};
