import {
  legalMessages,
  linkToAgencyProgramAgreement,
  linkToBroadcasterAgreement,
  linkToBugBountyProgram,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToDSAInformation,
  linkToFAQ,
  linkToPrivacyPolicy,
  linkToPublisherTermsAndConditions,
  linkToTermsOfUse,
  linkToThirdPartyLicense,
} from "legal/imports/constants";

export const termsOfUse = {
  message: legalMessages.termsOfUse,
  to: linkToTermsOfUse,
  dataTestId: "link-to-terms-of-use",
};

export const privacyPolicy = {
  message: legalMessages.privacyPolicy,
  to: linkToPrivacyPolicy,
  dataTestId: "link-to-privacy-policy",
};

const copyright = {
  message: legalMessages.copyright,
  to: linkToCopyright,
  dataTestId: "link-to-copyright",
};

const thirdPartyLicense = {
  message: legalMessages.thirdPartyLicense,
  to: linkToThirdPartyLicense,
  dataTestId: "link-to-third-party-license",
};

const broadcasterAgreement = {
  message: legalMessages.broadcasterAgreement,
  to: linkToBroadcasterAgreement,
  dataTestId: "link-to-broadcaster-agreement",
};

const communityGuidelines = {
  message: legalMessages.communityGuidelines,
  to: linkToCommunityGuidelines,
  dataTestId: "link-to-community-guidelines",
};

const publisherTermsAndConditions = {
  message: legalMessages.publisherTermsAndConditions,
  to: linkToPublisherTermsAndConditions,
  dataTestId: "link-to-publisher-terms-an-conditions",
};

const bugBountyProgram = {
  message: legalMessages.bugBountyProgram,
  to: linkToBugBountyProgram,
  dataTestId: "link-to-bug-bounty-program",
};

const dsaInformation = {
  message: legalMessages.dsaInformation,
  to: linkToDSAInformation,
  dataTestId: "link-to-dsa-information",
};

const faq = {
  message: legalMessages.faq,
  to: linkToFAQ,
  dataTestId: "link-to-faq",
};

const agencyProgramAgreement = {
  message: legalMessages.agencyProgramAgreement,
  to: linkToAgencyProgramAgreement,
  dataTestId: "link-to-agency-program-agreement",
};

export const navigationConfig = [
  termsOfUse,
  privacyPolicy,
  thirdPartyLicense,
  broadcasterAgreement,
  agencyProgramAgreement,
  communityGuidelines,
  copyright,
  publisherTermsAndConditions,
  bugBountyProgram,
  dsaInformation,
  faq,
];
