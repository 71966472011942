import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emitEvent } from "@analytics/emit";
import { EventNames } from "@analytics/enums";
import { BottomScreenType, DisplayAreas, ModalType } from "src/enums";
import { EMAIL_OPT_IN_PREFERENCES_KEY } from "src/features/promotions/promoBottomSheet/constants";
import { getIsEmailBottomSheetEnabled } from "state/abTests";
import { openBottomSheetModalHandler } from "state/actionCreators/modal";
import { clearTcnnMessage } from "state/actionCreators/tcnn";
import { RootState } from "state/delegate";
import {
  bottomScreenSelectors,
  bottomSheetSelectors,
  modalSelectors,
  settingsSelectors,
} from "state/selectors";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import { BottomSheetType } from "ui/tcnn/types";

const selector = (state: RootState) => ({
  getBottomSheetMessage: (displayArea: DisplayAreas) =>
    bottomSheetSelectors.getBottomSheetByArea(state, displayArea),
  bottomScreenType: bottomScreenSelectors.screenType(state),
  modalStack: modalSelectors.getModalStack(state),
  isEmailBottomSheetEnabled: getIsEmailBottomSheetEnabled(state),
});

const useBottomSheetShow = (displayArea: DisplayAreas) => {
  const dispatch = useDispatch();
  const isMobileLayout = useBreakpointMobileLayout();
  const {
    getBottomSheetMessage,
    modalStack,
    bottomScreenType,
    isEmailBottomSheetEnabled,
  } = useSelector(selector);
  const message = getBottomSheetMessage(displayArea);
  const isMobileStreamModal =
    isMobileLayout && displayArea === DisplayAreas.IN_STREAM;

  const emailOptInValue = useSelector((state) =>
    settingsSelectors.getPreference(state, EMAIL_OPT_IN_PREFERENCES_KEY)
  );

  const clearBottomSheetHandler = () =>
    dispatch(clearTcnnMessage(message?.tracking_id));

  useEffect(() => {
    if (
      message &&
      ![ModalType.BOTTOM_SHEET, ModalType.BUY_COINS].includes(
        modalStack?.[0]
      ) &&
      ![
        BottomScreenType.BOTTOM_SHEET,
        BottomScreenType.GIFTS_DRAWER_BOTTOM_SCREEN,
        BottomScreenType.REFILL_DRAWER_BOTTOM_SCREEN,
      ].includes(bottomScreenType)
    ) {
      emitEvent(EventNames.TCNN_RECEIVED, message);

      const dismiss = () => {
        clearBottomSheetHandler();
      };

      if (
        isEmailBottomSheetEnabled &&
        message?.bottom_sheet_content_type === BottomSheetType.EMAIL &&
        !!emailOptInValue
      ) {
        return;
      }

      dispatch(
        openBottomSheetModalHandler(
          {
            ...message,
            onDismiss: dismiss,
            onOutsideClickDismiss: dismiss,
            isMobileStreamModal,
            isMobileLayout,
            clearBottomSheetHandler,
            withCover: !isMobileStreamModal,
          },
          isMobileLayout
        )
      );
    }
  }, [bottomScreenType, isMobileLayout, message, modalStack, emailOptInValue]);

  return null;
};

export default useBottomSheetShow;
