import { RealTimeRecommendationsContextService } from "src/features/recommendations/services/RealTimeRecommendationsContextService";
import { RealTimeRecommendationsQueueService } from "src/features/recommendations/services/RealTimeRecommendationsQueueService";
import { RealTimeRecommendationsSenderService } from "src/features/recommendations/services/RealTimeRecommendationsSenderService";

export const realTimeRecommendationsContextService =
  new RealTimeRecommendationsContextService();

export const realTimeRecommendationsQueueService =
  new RealTimeRecommendationsQueueService();

export const realTimeRecommendationsSenderService =
  new RealTimeRecommendationsSenderService(
    realTimeRecommendationsQueueService,
    realTimeRecommendationsContextService
  );
