import { SignInScreenState } from "src/features/signin/analytics/enums";
import {
  EventFields,
  SCREEN_NAME,
  emitView,
} from "src/features/signin/imports/analytics";

const emitInvalidPhoneNumber = () => {
  emitView(
    { reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN },
    {
      reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
    },
    undefined,
    {
      [EventFields.SCREEN_STATE]: SignInScreenState.INCORRECT_PHONE,
    }
  );
};

export default emitInvalidPhoneNumber;
