import { createReducer } from "@reduxjs/toolkit";
import { CaptchaType } from "ui/modal/modalViews/captcha/enum";
import { setLockedState } from "state/actionCreators/captcha";
import {
  setCaptchaConfig,
  showCaptcha,
} from "state/actionCreators/networkError";
import { isApiError } from "api/utils/enhancedFetch";
import isLockedByCaptcha from "src/utils/isLockedByCaptcha";

export const persistConfig = {
  whitelist: ["locked"],
};

export type CaptchaState = {
  locked: boolean;
  config: {
    type: CaptchaType;
    [key: string]: string;
  };
};

const initialState = {
  locked: false,
  config: {
    type: CaptchaType.COMMON,
  },
};

const captchaReducer = createReducer<CaptchaState>(initialState, (builder) => {
  builder
    .addCase(setLockedState, (state, action) => {
      state.locked = action.payload;
      if (!state.locked) {
        state.config = { ...initialState.config };
      }
    })
    .addCase(showCaptcha, (state, action) => {
      const error = action.payload;
      if (isApiError(error) && isLockedByCaptcha(error)) {
        state.locked = true;
      }
    })
    .addCase(setCaptchaConfig, (state, action) => {
      state.config = action.payload;
    });
});

export default captchaReducer;
