import { createAction, createReducer } from "@reduxjs/toolkit";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import qs, { ParsedQuery } from "query-string";
import { REPLACE } from "enums/browserHistoryActions";

export interface NavigationState {
  current: string;
  previous: string;
  redirectAfterLogin: {
    isNeeded: boolean;
    redirectPath: string;
  };
  search: ParsedQuery;
}

interface SetRedirectAfterLoginPayload {
  redirectPath: string;
}

export const setRedirectAfterLogin = createAction<SetRedirectAfterLoginPayload>(
  "lwc/navigation/setRedirectAfterLogin"
);

export const clearRedirectAfterLogin = createAction(
  "lwc/navigation/clearRedirectAfterLogin"
);

export default createReducer<NavigationState>(
  {
    previous: "",
    current: "",
    search: {},
    redirectAfterLogin: {
      isNeeded: false,
      redirectPath: "",
    },
  },
  (builder) => {
    builder
      .addCase(LOCATION_CHANGE, (state, action: LocationChangeAction) => {
        const {
          location: { pathname, search },
          action: historyAction,
        } = action.payload;

        if (pathname === state.current) {
          return state;
        }

        if (historyAction !== REPLACE) {
          state.previous = state.current;
        }

        state.current = pathname;
        state.search = qs.parse(search);
      })
      .addCase(setRedirectAfterLogin, (state, action) => {
        const { redirectPath } = action.payload;

        state.redirectAfterLogin.isNeeded = true;
        state.redirectAfterLogin.redirectPath = redirectPath;
      })
      .addCase(clearRedirectAfterLogin, (state) => {
        state.redirectAfterLogin.isNeeded = false;
        state.redirectAfterLogin.redirectPath = "";
      });
  }
);

export const selectors = {
  getPreviousRoute: (state: NavigationState) => state.previous,
  getCurrentRoute: (state: NavigationState) => state.current,
  getCurrentParsedQuery: (state: NavigationState) => state.search,
  getIsRedirectNeeded: (state: NavigationState) =>
    state.redirectAfterLogin.isNeeded,
  getRedirectPath: (state: NavigationState) =>
    state.redirectAfterLogin.redirectPath,
};
