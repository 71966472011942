import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { DatadogTransport } from "src/features/autoLogin/imports/analytics";
import { autoLoginSelectors } from "src/features/autoLogin/imports/state";
import { linkToBasePath } from "src/features/autoLogin/imports/ui";
import { DatadogAutoLoginMessage } from "src/features/autoLogin/types/datadogAutoLoginMessage";

export const AutoLoginSessionMonitor = () => {
  const isBasePageRoute = !!useRouteMatch(linkToBasePath)?.isExact;

  const isAutoLoginSession = useSelector(
    autoLoginSelectors.getIsAutoLoginSession
  );

  const isTrackedOnce = useRef(false);

  useEffect(() => {
    if (!isTrackedOnce.current && isAutoLoginSession && isBasePageRoute) {
      DatadogTransport.warn(DatadogAutoLoginMessage.DETECTED_BASE_PAGE_VISIT);
      isTrackedOnce.current = true;
    }
  }, [isAutoLoginSession, isBasePageRoute]);

  return null;
};
