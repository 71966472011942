import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as ConversationIcon } from "chat/assets/ic_conversation.svg";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import styles from "./ConversationPlaceholder.scss";

const ConversationPlaceholder = () => (
  <Typography
    type={TYPOGRAPHY_TYPE.PARAGRAPH3}
    className={styles.selectConversation}
    as="div"
  >
    <ConversationIcon className={styles.icon} />
    <FormattedMessage
      id="chat.select.conversation"
      defaultMessage="Please select a chat to start messaging"
    />
  </Typography>
);

export default ConversationPlaceholder;
