import {
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_UPDATE,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  GIFT_SENT,
  VIEWER_SESSION_SET_DIRTY_FLAGS,
  VIEWER_SESSION_SET_TOP_GIFTERS_REQUEST_LIMIT,
  VIEWER_SESSION_TOP_GIFTERS_BEGIN_FETCH,
  VIEWER_SESSION_TOP_GIFTERS_END_FETCH,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
} from "state/actionTypes";
import ensureParameter from "state/tree/utils/ensureParameter";
import { getStreamId } from "./commonSelectors";
import { STREAM_TOP_GIFTERS_DEFAULT_LIMIT } from "src/constants";
import { StreamEvents } from "src/enums";

const initialState = {
  requiresUpdate: true,
  fetchInProgress: false,
  accountIds: [],
  giftedAmounts: {},
  limitToRequest: STREAM_TOP_GIFTERS_DEFAULT_LIMIT,
  guestCount: 0,
  viewerAccountIds: [],
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_TOP_GIFTERS_BEGIN_FETCH: {
      return {
        ...state,
        fetchInProgress: true,
      };
    }
    case VIEWER_SESSION_TOP_GIFTERS_END_FETCH: {
      if (action.error) {
        return {
          ...state,
          fetchInProgress: false,
          requiresUpdate: false,
        };
      }
      const {
        entities: { giftedAmounts },
        topGifters: accountIds,
        viewers = [],
        guestCount = 0,
      } = action.payload;
      return {
        ...state,
        fetchInProgress: false,
        requiresUpdate: false,
        accountIds,
        giftedAmounts,
        guestCount,
        viewerAccountIds: viewers,
      };
    }
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT:
    case LIVE_RICH_NOTIFICATION_RECEIVED:
    case VIEWER_SESSION_UPDATE: {
      if (action.error) {
        return state;
      }
      const { entities: { events } = {} } = action.payload;
      const requiresUpdate =
        events &&
        Object.values(events).find(
          (x) =>
            x.type === StreamEvents.GIFT || x.type === StreamEvents.SUBSCRIPTION
        ) !== undefined;
      if (requiresUpdate && requiresUpdate !== state.requiresUpdate) {
        return { ...state, requiresUpdate };
      }
      return state;
    }
    case VIEWER_SESSION_RESET: {
      if (action.payload === getStreamId(context)) {
        return {
          ...state,
          requiresUpdate: true,
        };
      }
      return initialState;
    }
    case GIFT_SENT: {
      return {
        ...state,
        requiresUpdate: true,
      };
    }
    case VIEWER_SESSION_SET_DIRTY_FLAGS: {
      const { gifters: requiresUpdate = state.requiresUpdate } = action.payload;
      return { ...state, requiresUpdate };
    }
    case VIEWER_SESSION_SET_TOP_GIFTERS_REQUEST_LIMIT: {
      const { payload: limit } = action;
      if (limit === state.limitToRequest) {
        return state;
      }
      return {
        ...state,
        limitToRequest: Math.max(STREAM_TOP_GIFTERS_DEFAULT_LIMIT, limit),
        requiresUpdate: state.requiresUpdate || limit > state.limitToRequest,
      };
    }
  }
  return state;
};

export const selectors = {
  getTopGifters: (state) => state.accountIds,
  getGuestsCount: (state) => state.guestCount,
  getViewerAccountIds: (state) => state.viewerAccountIds,
  isLoadingTopGifters: (state) => state.fetchInProgress,
  getGiftedAmount: (state, id) =>
    ensureParameter(id, "id") && (state.giftedAmounts[id] || 0),
  getAllGiftedAmounts: (state) => state.giftedAmounts,
  shouldUpdateTopGifters: (state) => state.requiresUpdate,
  getTopGiftersCountToRequest: (state) => state.limitToRequest,
};
