import React from "react";
import { MessageDescriptor } from "@formatjs/intl";
import {
  BI_LANDING_FIELD,
  EventFields,
  EventNames,
  PURCHASE_SOURCE,
  UI_COMPONENT,
} from "@analytics/enums";
import { PurchaseResponse } from "api/shop/tangoPurchaseTypes";
import { Currency } from "src/enums";
import { Nullable, VoidCallback } from "src/types/common";
import { PaymentProvider, PaymentTarget, PaymentType } from "src/types/payment";
import {
  CheckoutApmOption,
  CheckoutNewCardOption,
  CheckoutOption,
  CheckoutSavedCardOption,
} from "ui/modal/modalViews/buyCoins/checkout/types";
import {
  SafeChargeApmOption,
  SafeChargeNewCardOption,
  SafeChargeOption,
  SafeChargeSavedCardOption,
} from "ui/modal/modalViews/buyCoins/safecharge/types";
import {
  TangoApmOption,
  TangoNewCardOption,
  TangoOption,
  TangoSavedCardOption,
} from "ui/modal/modalViews/buyCoins/tango/state/types";
import { PurchaseFailData } from "ui/scenes/landingPagePhoenix/LandingPage";

export type OptionId = PaymentType | string;
export type Option = CheckoutOption | SafeChargeOption | TangoOption;
export type NewCardOption =
  | CheckoutNewCardOption
  | SafeChargeNewCardOption
  | TangoNewCardOption;
export type SavedCardOption =
  | CheckoutSavedCardOption
  | SafeChargeSavedCardOption
  | TangoSavedCardOption;
export type ApmOption =
  | CheckoutApmOption
  | SafeChargeApmOption
  | TangoApmOption;

export type OptionList<T extends Option = Option> = Record<OptionId, T>;

export enum PaymentTypeTab {
  ALL_PAYMENTS = "allPayments",
  CREDIT_CARD = "creditCard",
  LOCAL_PAYMENTS = "localPayments",
}

export interface AnalyticsParams {
  [BI_LANDING_FIELD.MESSAGE_UUID]?: string;
  [BI_LANDING_FIELD.PRICE_POINT_ID]?: string;
  [BI_LANDING_FIELD.SERVER_OFFER_ID]?: string;
  [BI_LANDING_FIELD.TIME_LOADING]?: number;
  [BI_LANDING_FIELD.TRACKING_ID]?: string;
  [BI_LANDING_FIELD.TRIGGER_ID]?: string;
  [EventFields.CAMPAIGN_ID]?: string;
  [EventFields.PAYMENT_METHOD]?: string;
  [EventFields.PAYMENT_PROVIDER]?: string;
  [EventFields.PERSONAL_OFFER_TYPE]?: string;
  [EventFields.PREVIOUS_UI_COMPONENT]?: UI_COMPONENT;
  [EventFields.PURCHASE_SOURCE]?: PURCHASE_SOURCE;
  [EventFields.SHARE_SOURCE_ID]?: number | string;
  [EventFields.TARGET]?: string;
  [EventFields.UI_COMPONENT]?: UI_COMPONENT;
  [EventFields.USER_ID]?: string;
}

export type PaymentEventFields = Record<
  string,
  Nullable<number | string | undefined>
>;

export type FnEmitPaymentEvent = (
  eventName: BI_LANDING_FIELD | EventNames,
  eventFields: PaymentEventFields
) => void;

export type SetSummaryStateOptions = {
  bonusData?: Nullable<PurchaseResponse["bonusData"]>;
  has3ds?: boolean;
  operationId?: PurchaseResponse["operationId"];
  redirect?: PurchaseResponse["redirect"];
};
export type FnSetSummaryState = (
  newSummaryState:
    | {
        errorMessage?: MessageDescriptor;
        errorType?: string;
        summaryType: string;
      }
    | string,
  options?: SetSummaryStateOptions
) => void;

export type SelectedPaymentOption = Nullable<OptionId>;

export interface GeneralSummaryViewProps {
  analyticsParams: AnalyticsParams;
  campaignId?: string;
  isDark: boolean;
  isDrawer: boolean;
  isTabsAvailable: boolean;
  onBackToOffers?: VoidCallback;
  onlyCrypto: boolean;
  paymentTarget?: PaymentTarget;
  personalOfferId?: string;
  portalId: Nullable<string>;
  preferredCurrency: Nullable<Currency>;
  singlePaymentMethod: Nullable<PaymentType>;
  streamerId: string;
  taxV2: boolean;
}

export interface SummaryViewProps {
  className?: string;
  isMainProvider?: boolean;
  provider: PaymentProvider;
  renderSecondaryProviders?: RenderSecondaryProviders;
}

export type RenderSecondaryProviders = () => React.ReactNode;

export interface RestrictionsPaymentOption {
  currency: Currency;
  max: number;
  min: number;
}

export type FnPaymentError = (errorData: PurchaseFailData) => void;

export enum BuyCoinsModalCloseReason {
  BACK = "back",
  CLOSE = "close",
  SUCCESS = "success",
}
