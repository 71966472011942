import { createReducer } from "@reduxjs/toolkit";
import { BannerConfigsState } from "src/types/banner";
import {
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";
import actions, { getInitialData } from "state/actionCreators/bannerGonfigs";

const initialState = {
  data: getInitialData(),
  meta: initialFetcherStateMeta,
};

export default createReducer<BannerConfigsState>(initialState, (builder) => {
  addAsyncCasesToBuilderV2({
    builder,
    action: actions.fetchBannerConfigs,
    prepareData: (_, newConfigs) => newConfigs,
    initialData: initialState.data,
  });
});
