import { ActionReducerMapBuilder, AsyncThunk } from "@reduxjs/toolkit";
import type { Draft } from "immer";
import { Nullable } from "src/types/common";
import { Dispatch } from "redux";

export type FetcherMetaV2 = {
  resetOnError?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetcherStateMeta<Error = any> = {
  loading: boolean;
  stale: boolean;
  currentRequestId: string | undefined;
  error: Nullable<Error>;
};

export const initialFetcherStateMeta = {
  loading: false,
  currentRequestId: undefined,
  error: null,
  stale: true,
};

export type GetDataType<State> = State extends {
  data: infer Data;
}
  ? Data
  : unknown;

export type GetMetaType<State> = State extends {
  meta: infer Meta extends FetcherStateMeta;
}
  ? Meta
  : never;

export type Data<State> = GetDataType<State>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncState<Data = any, Error = any> = {
  data: Data;
  meta: FetcherStateMeta<Error>;
};

type ActionMeta<ActionArgs, ThunkApi> = {
  arg: ActionArgs;
  requestId: string;
  requestStatus: "fulfilled";
} & ThunkApi;

// redux-toolkit doesn't export this type, so we need to define it here
type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export const generateAsyncSelectors = <
  State extends { data: GetDataType<State>; meta: GetMetaType<State> },
>() => ({
  data: (state: State) => state.data,
  meta: (state: State) => state.meta,
});

export const addAsyncCasesToBuilderV2 = <
  State extends AsyncState,
  Returned,
  ActionArgs extends FetcherMetaV2 | void,
  ThunkApi extends AsyncThunkConfig,
>({
  builder,
  action,
  prepareData,
  initialData,
}: {
  builder: ActionReducerMapBuilder<State>;
  action: AsyncThunk<Returned, ActionArgs, ThunkApi>;
  initialData: Data<State>;
  prepareData: (
    data: Data<State>,
    payload: Returned,
    meta: ActionMeta<ActionArgs, ThunkApi>
  ) => Draft<Data<State>> | Data<State>;
}) =>
  builder
    .addCase(action.pending, (state, action) => {
      const { meta } = state;
      if (!meta.loading) {
        meta.loading = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        meta.currentRequestId = (action as any).meta.requestId;
        meta.error = null;
      }
    })
    .addCase(action.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { meta: actionMeta } = action as any;
      const { meta } = state;
      if (meta.loading && meta.currentRequestId === actionMeta.requestId) {
        meta.loading = false;
        meta.currentRequestId = undefined;
        meta.stale = false;
        state.data = prepareData(state.data, action.payload, actionMeta);
      }
    })
    .addCase(action.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { meta: actionMeta } = action as any;
      const { meta } = state;
      if (meta.currentRequestId === actionMeta.requestId) {
        if (actionMeta.arg?.resetOnError) {
          state.data = initialData;
        }

        meta.loading = false;
        meta.currentRequestId = undefined;
        meta.stale = false;
        meta.error = action.payload;
      }
    });
